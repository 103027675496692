import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { ConvertingDataRoutingModule } from './converting-data.routing.module';
import { CuttingTeamLogEntryComponent } from './cutting-team-logs/cutting-team-log-entry.component';
import { CuttingTeamLogService } from './cutting-team-logs/cutting-team-log.service';
import { PastingTeamLogEntryComponent } from './pasting-team-logs/pasting-team-log-entry.component';
import { PastingTeamLogService } from './pasting-team-logs/pasting-team-log.service';
import { LiningGuillotineLogEntryComponent } from './lining-guillotine-logs/lining-guillotine-log-entry.component';
import { LiningGuillotineLogService } from './lining-guillotine-logs/lining-guillotine-log.service';
import { RotarySlitDivisionLogEntryComponent } from './rotary-slit-division-logs/rotary-slit-division-log-entry.component';
import { RotarySlitDivisionLogService } from './rotary-slit-division-logs/rotary-slit-division-log.service';
import { ConvertingDataSettingsComponent } from './converting-data-settings/converting-data-settings.component';
import { BoardTypeService } from './board-type.service';
import { RotaryTypeService } from './rotary-type.service';
import { ConvertingDataEntryUserService } from './converting-data-entry-user.service';

@NgModule({
    declarations: [
        CuttingTeamLogEntryComponent,
        LiningGuillotineLogEntryComponent,
        PastingTeamLogEntryComponent,
        RotarySlitDivisionLogEntryComponent,
        ConvertingDataSettingsComponent,
    ],
    imports: [SharedModule, ConvertingDataRoutingModule],
})
export class ConvertingDataModule {
    static forRoot(): any {
        return {
            ngModule: ConvertingDataModule,
            providers: [
                BoardTypeService,
                CuttingTeamLogService,
                LiningGuillotineLogService,
                PastingTeamLogService,
                RotarySlitDivisionLogService,
                RotaryTypeService,
                ConvertingDataEntryUserService,
            ],
        };
    }
}
