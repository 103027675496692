import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICuttingTeamLog } from '../interfaces/cutting-team-log';
import { IBoardType } from '../interfaces/board-type';
import { ISalesOrder } from '../interfaces/sales-order';

export interface ICuttingTeamLogDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    boardTypes?: IBoardType[];
}

export class CuttingTeamLogDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];
    boardTypes: IBoardType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private cuttingteamlog?: ICuttingTeamLog, additionalParameters?: ICuttingTeamLogDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CuttingTeamLog';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.boardTypes = additionalParameters && additionalParameters.boardTypes || undefined;

        this.Form = {
            BackTrim: new DynamicField({
                formGroup: this.formGroup,
                label: 'Back Trim',
                name: 'BackTrim',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('BackTrim') && this.cuttingteamlog.BackTrim !== null ? this.cuttingteamlog.BackTrim : false,
            }),
            BdlSk: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdl Sk',
                name: 'BdlSk',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.BdlSk || null,
            }),
            BoardGrade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGrade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('BoardGrade') && this.cuttingteamlog.BoardGrade !== null ? this.cuttingteamlog.BoardGrade.toString() : '',
            }),
            BoardTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Type',
                name: 'BoardTypeId',
                options: this.boardTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.BoardTypeId || null,
            }),
            Break: new DynamicField({
                formGroup: this.formGroup,
                label: 'Break',
                name: 'Break',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Break') && this.cuttingteamlog.Break !== null ? this.cuttingteamlog.Break : false,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.Caliper || null,
            }),
            ChangeBlade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Change Blade',
                name: 'ChangeBlade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('ChangeBlade') && this.cuttingteamlog.ChangeBlade !== null ? this.cuttingteamlog.ChangeBlade : false,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.Count || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('CustomerName') && this.cuttingteamlog.CustomerName !== null ? this.cuttingteamlog.CustomerName.toString() : '',
            }),
            CutCorners: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cut Corners',
                name: 'CutCorners',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('CutCorners') && this.cuttingteamlog.CutCorners !== null ? this.cuttingteamlog.CutCorners : false,
            }),
            Cutter: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cutter',
                name: 'Cutter',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Cutter') && this.cuttingteamlog.Cutter !== null ? this.cuttingteamlog.Cutter.toString() : '',
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.cuttingteamlog && this.cuttingteamlog.Date || null,
            }),
            Finisher: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher',
                name: 'Finisher',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Finisher') && this.cuttingteamlog.Finisher !== null ? this.cuttingteamlog.Finisher.toString() : '',
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Length') && this.cuttingteamlog.Length !== null ? this.cuttingteamlog.Length.toString() : '',
            }),
            MachineNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Machine Number',
                name: 'MachineNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('MachineNumber') && this.cuttingteamlog.MachineNumber !== null ? this.cuttingteamlog.MachineNumber.toString() : '',
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Note') && this.cuttingteamlog.Note !== null ? this.cuttingteamlog.Note.toString() : '',
            }),
            Remarks: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remarks',
                name: 'Remarks',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Remarks') && this.cuttingteamlog.Remarks !== null ? this.cuttingteamlog.Remarks : false,
            }),
            ReportId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report',
                name: 'ReportId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.ReportId || 1,
            }),
            Restack: new DynamicField({
                formGroup: this.formGroup,
                label: 'Restack',
                name: 'Restack',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Restack') && this.cuttingteamlog.Restack !== null ? this.cuttingteamlog.Restack : false,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.SalesOrderId || null,
            }),
            ShiftCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Code',
                name: 'ShiftCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('ShiftCode') && this.cuttingteamlog.ShiftCode !== null ? this.cuttingteamlog.ShiftCode.toString() : '',
            }),
            Skids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skids',
                name: 'Skids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.Skids || null,
            }),
            TiedBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tied Bundles',
                name: 'TiedBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TiedBundles') && this.cuttingteamlog.TiedBundles !== null ? this.cuttingteamlog.TiedBundles : false,
            }),
            TimeOff: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time Off',
                name: 'TimeOff',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TimeOff') && this.cuttingteamlog.TimeOff !== null ? this.cuttingteamlog.TimeOff.toString() : '',
            }),
            TimeOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time On',
                name: 'TimeOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TimeOn') && this.cuttingteamlog.TimeOn !== null ? this.cuttingteamlog.TimeOn.toString() : '',
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Width') && this.cuttingteamlog.Width !== null ? this.cuttingteamlog.Width.toString() : '',
            }),
        };

        this.View = {
            BackTrim: new DynamicLabel(
                'Back Trim',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('BackTrim') && this.cuttingteamlog.BackTrim !== null ? this.cuttingteamlog.BackTrim : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            BdlSk: new DynamicLabel(
                'Bdl Sk',
                this.cuttingteamlog && this.cuttingteamlog.BdlSk || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            BoardGrade: new DynamicLabel(
                'Board Grade',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('BoardGrade') && this.cuttingteamlog.BoardGrade !== null ? this.cuttingteamlog.BoardGrade.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            BoardTypeId: new DynamicLabel(
                'Board Type',
                getMetaItemValue(this.boardTypes, this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('BoardTypeId') && this.cuttingteamlog.BoardTypeId !== null ? this.cuttingteamlog.BoardTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Break: new DynamicLabel(
                'Break',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Break') && this.cuttingteamlog.Break !== null ? this.cuttingteamlog.Break : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Bundles: new DynamicLabel(
                'Bundles',
                this.cuttingteamlog && this.cuttingteamlog.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.cuttingteamlog && this.cuttingteamlog.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            ChangeBlade: new DynamicLabel(
                'Change Blade',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('ChangeBlade') && this.cuttingteamlog.ChangeBlade !== null ? this.cuttingteamlog.ChangeBlade : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.cuttingteamlog && this.cuttingteamlog.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CustomerName: new DynamicLabel(
                'Customer Name',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('CustomerName') && this.cuttingteamlog.CustomerName !== null ? this.cuttingteamlog.CustomerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CutCorners: new DynamicLabel(
                'Cut Corners',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('CutCorners') && this.cuttingteamlog.CutCorners !== null ? this.cuttingteamlog.CutCorners : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Cutter: new DynamicLabel(
                'Cutter',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Cutter') && this.cuttingteamlog.Cutter !== null ? this.cuttingteamlog.Cutter.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Date: new DynamicLabel(
                'Date',
                this.cuttingteamlog && this.cuttingteamlog.Date || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Finisher: new DynamicLabel(
                'Finisher',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Finisher') && this.cuttingteamlog.Finisher !== null ? this.cuttingteamlog.Finisher.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Length') && this.cuttingteamlog.Length !== null ? this.cuttingteamlog.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MachineNumber: new DynamicLabel(
                'Machine Number',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('MachineNumber') && this.cuttingteamlog.MachineNumber !== null ? this.cuttingteamlog.MachineNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Note: new DynamicLabel(
                'Note',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Note') && this.cuttingteamlog.Note !== null ? this.cuttingteamlog.Note.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Remarks: new DynamicLabel(
                'Remarks',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Remarks') && this.cuttingteamlog.Remarks !== null ? this.cuttingteamlog.Remarks : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReportId: new DynamicLabel(
                'Report',
                this.cuttingteamlog && this.cuttingteamlog.ReportId || 1,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Restack: new DynamicLabel(
                'Restack',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Restack') && this.cuttingteamlog.Restack !== null ? this.cuttingteamlog.Restack : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('SalesOrderId') && this.cuttingteamlog.SalesOrderId !== null ? this.cuttingteamlog.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShiftCode: new DynamicLabel(
                'Shift Code',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('ShiftCode') && this.cuttingteamlog.ShiftCode !== null ? this.cuttingteamlog.ShiftCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Skids: new DynamicLabel(
                'Skids',
                this.cuttingteamlog && this.cuttingteamlog.Skids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TiedBundles: new DynamicLabel(
                'Tied Bundles',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TiedBundles') && this.cuttingteamlog.TiedBundles !== null ? this.cuttingteamlog.TiedBundles : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeOff: new DynamicLabel(
                'Time Off',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TimeOff') && this.cuttingteamlog.TimeOff !== null ? this.cuttingteamlog.TimeOff.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeOn: new DynamicLabel(
                'Time On',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('TimeOn') && this.cuttingteamlog.TimeOn !== null ? this.cuttingteamlog.TimeOn.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.cuttingteamlog && this.cuttingteamlog.hasOwnProperty('Width') && this.cuttingteamlog.Width !== null ? this.cuttingteamlog.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
