import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';

import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { FormControl } from '@angular/forms';
import { IShipment } from '../../model/interfaces/shipment';
import { ISkid } from '../../model/interfaces/skid';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';
import { ShippingStatuses } from '../../model/ShippingStatuses';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { forkJoin } from 'rxjs';
import { ShipmentStatusService } from '../shipmentstatus.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Router } from '@angular/router';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { formatDate } from '@angular/common';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import * as moment from 'moment';

@Component({
    selector: 'shipments-list',
    styles: [
        `
            fieldset {
                display: inline-block;
                vertical-align: top;
                padding-top: 4px;
            }
            ,
            .dropdown-menu > li > a {
                padding: 6px 20px;
            }
            .btn-select-shipment {
                background: #4e6365;
                border-color: #4e6365;
            }
            .pagination {
                margin-top: -20px;
                padding-left: 20px;
            }
        `,
    ],
    templateUrl: './shipments-list.component.html',
})
export class ShipmentsListComponent implements OnInit {
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;
    searchControl = new FormControl();
    currentPage = 1;
    dateCreatedStart: Date = null;
    dateCreatedEnd: Date = null;
    shipmentDateStart: Date = null;
    shipmentDateEnd: Date = null;
    openDateStart: Date = null;
    openDateEnd: Date = null;
    closeDateStart: Date = null;
    closeDateEnd: Date = null;
    total = 0;
    skids: ISkid[];
    selectedShipment: IShipment;
    shipments: IShipment[];
    pageSize = 10;
    order = 'Id';
    orderDirection = 'desc';
    shipmentStatusItems: MtSearchFilterItem[];
    selectedStatusItems: any;

    shipmentDetailModal: IModalWrapperApi;
    shipmentDetailModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: true,
        width: 800,
    };

    selectShipmentDateModal: IModalWrapperApi;
    selectShipmentDateModalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        width: 1000,
    };

    @ViewChild('shipmentContextMenu') public shipmentContextMenu: ContextMenuComponent;
    canAddLoad: any;

    constructor(
        private shipmentService: ShipmentService,
        private contextMenuService: ContextMenuService,
        private claimsService: ClaimsService,
        private shipmentStatusService: ShipmentStatusService,
        private router: Router,
        private modalService: ModalService,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,

    ) {}

    bindGrid(): void {
        const searchEntity: IEntitySearchParams = {
            extraParams: this.buildSearch(),
            order: this.order,
            orderDirection: this.orderDirection,
            query: null,
            skip: (this.currentPage - 1) * this.pageSize,
            take: this.pageSize,
        };

        const searchParams = new SearchParams(searchEntity);

        forkJoin(this.shipmentService.get(searchParams), this.shipmentStatusService.getSearchFilterItems()).subscribe((answer) => {
            this.shipments = answer[0].body;
            this.total = +answer[0].headers.get('X-List-Count');
            this.shipmentStatusItems = answer[1];
            this.shipmentStatusItems.forEach((item) => {
                    item.Selected = true;
            });
        });
    }

    ngOnInit(): void {
        // Set default status filters to pending and open shipments
        this.selectedStatusItems = {
            selectedItems: [
                { Id: ShippingStatuses.Unscheduled, Name: 'Unscheduled', Shipments: Array(0) },
                { Id: ShippingStatuses.Pending, Name: 'Pending', Shipments: Array(0) },
                { Id: ShippingStatuses.Open, Name: 'Open', Shipments: Array(0) },
                { Id: ShippingStatuses.Closed, Name: 'Closed', Shipments: Array(0) },
                { Id: ShippingStatuses.Cancelled, Name: 'Cancelled', Shipments: Array(0) },
            ],
        };
        this.bindGrid();
        this.canAddLoad = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);

        this.cdr.detectChanges();
    }

    ngAfterContentInit(): void {
        if (this.searchBar) {
            const nativeElement = this.searchBar.searchInputElement.nativeElement;

            setTimeout(() => {
                this.renderer.selectRootElement(nativeElement).focus();
            }, 0);
        }
    }

    // This handles the case with javascript date, where midnight will count as the day before if no timezone is specified
    // this only returns the formatted date and not the time
    reformatDate(date: Date, format: string): string {
        if (date && format) {
            let dateString = date.toString().replace(/-/g, '/').replace(/T.+/, '');
            return formatDate(dateString, format, 'en-US');
        }
        return '';
    }

    private getDateValueForParams(date: Date): string {
        return moment(date).format('MM-DD-YYYY');
    }

    // six date filters on a page? abstracted
    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        this.addDateParam(this.dateCreatedStart, 'dateCreatedStart', _extraSearchParams);
        this.addDateParam(this.dateCreatedEnd, 'dateCreatedEnd', _extraSearchParams);
        this.addDateParam(this.shipmentDateStart, 'shipmentDateStart', _extraSearchParams);
        this.addDateParam(this.shipmentDateEnd, 'shipmentDateEnd', _extraSearchParams);
        this.addDateParam(this.openDateStart, 'openDateStart', _extraSearchParams);
        this.addDateParam(this.openDateEnd, 'openDateEnd', _extraSearchParams);
        this.addDateParam(this.closeDateStart, 'closeDateStart', _extraSearchParams);
        this.addDateParam(this.closeDateEnd, 'closeDateEnd', _extraSearchParams);
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: this.selectedStatusItems.selectedItems.map((item) => item.Id),
            }),
        );
        return _extraSearchParams;
    }

    // Add a date filter to the search
    private addDateParam(date: Date, name: string, _extraSearchParams: ExtraSearchParams[]): void {
        if (date) {
            let param = new ExtraSearchParams({
                name: name,
                value: this.getDateValueForParams(date),
            });
            _extraSearchParams.push(param);
        }
    }

    // when any of the date filters change, we update their start and end values and rebind
    dateSelectionChanged(value: ISearchFilterDaterangeValue, start: string, end: string): void {
        this[start] = value.startDate;
        this[end] = value.endDate;
        this.getShipments();
    }

    // When the status selection collection changes
    statusSelectionChanged(value: any): void {
        this.selectedStatusItems = value;
        this.getShipments();
    }

    getShipments(query = ''): void {
        const search = query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.shipmentService.get(searchparams).subscribe((answer) => {
            this.shipments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.getShipments(query);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getShipments();
    }

    showShipmentDetails(shipment: IShipment): void {
        this.selectedShipment = shipment;
        this.shipmentDetailModal.show();
    }

    isValidShipmentId(shipmentId: number): boolean {
        let shipment = this.shipments.find((s) => s.Id === shipmentId);
        return shipment.StatusId !== ShippingStatuses.Open && shipment.StatusId !== ShippingStatuses.Closed;
    }

    setShipmentDate(): void {
        this.selectShipmentDateModal.show();
    }

    onShipmentDateUpdated(isUpdated: boolean): void {
        this.selectShipmentDateModal.close();
        if (isUpdated) {
            this.getShipments();
        }
    }

    closeLoadSkidDialog(): void {
        this.shipmentDetailModal.close();
    }

    openContextMenu($event: MouseEvent, item: any): void {
        this.contextMenuService.show.next({
            contextMenu: this.shipmentContextMenu,
            event: $event,
            item: item,
        });
    }

    editLoad(item: any): void {
        this.router.navigate([`/shipments/edit/${item.Id}`]);
    }

    cancelLoad(item: any): void {
        if (this.checkIfLoadCanBeCancelled(item)) {
            this.modalService
                .showModal({
                    cancelButtonColor: '#d33',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, cancel it!',
                    showCancelButton: true,
                    text: `Confirm cancellation of load #${item.Id}`,
                    title: 'Are you sure?',
                    type: 'warning',
                })
                .subscribe((result) => {
                    if (result.value) {
                        this.shipmentService.cancelShipment(item.Id).subscribe((success) => {
                            this.bindGrid();
                            this.modalService.showModal({ title: 'Cancelled!', text: 'The load has been cancelled.', type: 'success' }).subscribe();
                        });
                    }
                });
        }
    }

    checkIfLoadCanBeCancelled(item: any): boolean {
        if (item.Skids.length > 0) {
            this.fireInvalidLoadCancelSwal('This load contains skids and cannot be cancelled.');
            return false;
        }
        if (item.StatusId === ShippingStatuses.Open) {
            this.fireInvalidLoadCancelSwal('This is an active load and cannot be cancelled.');
            return false;
        }
        return true;
    }

    fireInvalidLoadCancelSwal(text: string): void {
        this.modalService.showModal({ title: 'Cannot cancel this load.', text: text, type: 'error' }).subscribe();
    }

    getNumberOfSkids(shipment: IShipment): number {
        return shipment.ShipmentDetails.map((sd) => sd.NumberOfSkids).reduce((a, b) => a + b, 0);
    }
}
