import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';

import { ContactService } from './contact.service';
import { ContactRoutingModule } from './contact-routing.module';
import { ContactsComponent } from './contact-list/contacts.component';
import { ContactHeaderComponent } from './contact-header/contact-header.component';

@NgModule({
    declarations: [ContactsComponent, ContactHeaderComponent],
    imports: [SharedModule, ContactRoutingModule],
})
export class ContactModule {
    static forRoot(): any {
        return {
            ngModule: ContactModule,
            providers: [ContactService],
        };
    }
}
