import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ISectionHeading } from '../model/interfaces/section-heading';

@Injectable()
export class SectionHeadingService extends MetaItemService<ISectionHeading> {
    constructor(public http: HttpClient) {
        super('SectionHeadingService', 'Heading', 'HeadingIds', '/sectionheadings', http);
    }
}
