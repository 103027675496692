import { ISharedEntityConfig } from '@mt-ng2/shared-entities-module';

import { CustomerShippingSharedEntitiesConfig } from '../../model/shared-entities/customer-shipping-address.shared-entities-config';
import { CustomerShippingAddressService } from './customer-shipping-address.service';
import { ContactsSharedEntitiesConfig } from '../../model/shared-entities/contacts.shared-entities-config';
import { CustomerContactsService } from './customer-contacts.service';

export enum CustomerSharedEntities {
    ShippingAddress,
    Contacts,
}

export function getCustomerSharedEntityConfig(sharedEntity: CustomerSharedEntities): ISharedEntityConfig {
    switch (sharedEntity) {
        case CustomerSharedEntities.ShippingAddress:
            return {
                config: CustomerShippingSharedEntitiesConfig,
                entityIdParam: 'Id',
                path: 'shippingaddresses',
                service: CustomerShippingAddressService,
                sharedEntity: CustomerSharedEntities.ShippingAddress,
            };
        case CustomerSharedEntities.Contacts:
            return {
                config: ContactsSharedEntitiesConfig,
                entityIdParam: 'contactId',
                path: 'contacts',
                service: CustomerContactsService,
                sharedEntity: CustomerSharedEntities.Contacts,
            };
        default:
            return null;
    }
}
