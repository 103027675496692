import { ISkid } from '@model/interfaces/skid';

// Interfaces and classes with the same name are merged in TypeScript.
// This allows the Skid class to implement the ISkid interface without re-declaring all members.
// tslint:disable-next-line:interface-name
export interface Skid extends ISkid {}
export class Skid {
    constructor(skid: ISkid) {
        Object.assign(this, skid);
    }

    getNetWeight(): number {
        return this.ActualWeight - (this.TareWeightBottom + this.TareWeightTop);
    }

    getBundlesByWeight(): number {
        const poundsPerBundle = this.SalesOrder?.OrderInfo?.OrderCustomerSpecification?.IsFortyPoundBundle ? 40 : 50;
        return Math.floor(this.getNetWeight() / poundsPerBundle * 10) / 10;
    }

    getScaleSheets(): number {
        return Math.floor(this.getBundlesByWeight() * this.Count);
    }
}
