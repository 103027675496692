import { ClaimTypes } from '../model/ClaimTypes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { TestStackComponent } from './test-stack/test-stack.component';
import { TestStackResolver } from '@common/resolvers/test-stack.resolver';

class TestScanRoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.MillOperations_PackagingLines) {}
}

const orderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: TestStackComponent,
        data: new TestScanRoleGuard('Test Stack'),
        path: 'test-stack/ai',
        pathMatch: 'full',
        resolve: { testStacks: TestStackResolver },
    },
    {
        canActivate: [AuthGuard],
        component: TestStackComponent,
        data: new TestScanRoleGuard('Test Stack'),
        path: 'test-stack/drj',
        pathMatch: 'full',
        resolve: { testStacks: TestStackResolver },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(orderRoutes)],
})
export class TestScanRoutingModule {}
