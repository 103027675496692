<div *ngIf="formCreated">
    <form class="miles-form modern-css m-b-md padded" [formGroup]="form" (ngSubmit)="formSubmitted()">
        <h4>Skid Weight &amp; Sheet Count Processing</h4>
        <div class="row row-col-align-middle">
            <div class="col-xs-4">
                <dynamic-field
                    [field]="abstractSkidControls.BacktenderBarcode"
                    [ngClass]="'input-yellow'"
                    (controlCreated)="backtenderBarcodeControlCreated($event)"
                ></dynamic-field>
            </div>
            <div class="col-xs-4"><b>Order #:</b> {{ getFormattedOrderNumber(skid?.SalesOrder) }}</div>
        </div>
        <div class="row row-col-align-middle m-b-sm mt-0">
            <div class="col-xs-8">
                <button class="btn btn-default btn-sm" type="button" (click)="noBarcodeTag()">No Barcode Tag</button>
            </div>
        </div>
        <div class="m-b-sm">
            <span *ngIf="skid">
                Skid {{ skid?.SkidNumber }} of {{ skid?.SalesOrder?.ManufacturingOrderDetail?.NumberOfSkids }}
            </span>
            <span *ngIf="!skid">&nbsp;</span>
        </div>
        <div class="row">
            <div class="col-md-12">
                <fieldset class="m-b-md">
                    <legend>Customer</legend>
                    <div class="ib-container">
                        <div class="v-align-top">
                            <b>Ship to:</b>
                        </div>
                        <div class="m-l-lg">
                            <div>
                                {{ skid?.SalesOrder?.OrderInfo?.Customer?.CompanyName }}
                            </div>
                            <div>
                                {{ skid?.SalesOrder?.OrderInfo?.Customer?.CustomerName }}
                            </div>
                            <div *ngIf="customerShippingAddress">
                                <div>
                                    {{ customerShippingAddress?.ShipToName }}
                                </div>
                                <div>
                                    {{ customerShippingAddress?.Address?.Address1 }}
                                </div>
                                <div *ngIf="customerShippingAddress?.Address?.Address2">
                                    {{ customerShippingAddress?.Address?.Address2 }}
                                </div>
                                <div>
                                    {{ customerShippingAddress?.Address?.City }},
                                    {{ customerShippingAddress?.Address?.StateCode || customerShippingAddress?.Address?.Province }}
                                    {{ customerShippingAddress?.Address?.Zip }}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3"><b>Size:</b> {{ skid?.Width }} x {{ skid?.Length }}</div>
            <div class="col-md-2"><b>Caliper:</b> {{ skid?.Caliper }}</div>
            <div class="col-md-2"><b>Count:</b> {{ skid?.Count }}</div>
            <div class="col-md-2"><b>Piles:</b> {{ skid?.Piles }}</div>
            <div class="col-md-2"><b>Ply:</b> {{ skid?.Ply }}</div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <fieldset class="m-b-md">
                    <legend>Tare Weight</legend>
                    <dynamic-field [field]="abstractSkidControls.TareWeightBottom" [form]="form" [ngClass]="'input-yellow'"></dynamic-field>
                    <dynamic-field [field]="abstractSkidControls.TareWeightTop" [form]="form" [ngClass]="'input-yellow'"></dynamic-field>
                    <hr />
                    <div class="text-center font-weight-bold">
                        <input
                            *ngIf="skid"
                            type="number"
                            class="form-control"
                            style="text-align: center"
                            [(ngModel)]="skid.ActualWeight"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                </fieldset>
                <button type="button" class="btn btn-secondary btn-block font-weight-bold" (click)="weigh()" [disabled]="isWeighing">
                    {{ isWeighing ? 'WEIGHING...' : 'SCALE' }}
                </button>
            </div>
            <div class="col-md-4">
                <label>Scan Sheets: </label>
                <input *ngIf="skid" type="number" class="form-control" [(ngModel)]="skid.ActualCount" [ngModelOptions]="{ standalone: true }" />
                <br />
                <label>Net Weight: </label> {{ netWeight }}
            </div>
            <div class="col-md-3">
                <label>Scale Sheets: </label> {{ skid?.TargetCount }}
                <br />
                <label>Target Weight: </label> {{ skid?.TargetWeight }}
                <br />
                <span class="small font-italic">Scale Sheets and Target Weight are estimates based on count only</span>
            </div>
        </div>
        <div class="row m-t-lg">
            <div class="col-md-6 col-md-offset-3">
                <div class="row">
                    <div class="col-xs-6">
                        <button
                            type="submit"
                            [disabled]="!form.valid"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-success btn-block font-weight-bold"
                        >
                            Process Skid
                        </button>
                    </div>
                    <div class="col-xs-6">
                        <button type="button" Class="btn btn-default btn-block" (click)="cancelClick()">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="pull-right">
                    <label class="d-ib">Copies:</label>
                    <input
                        class="form-control d-ib input-yellow"
                        type="number"
                        [(ngModel)]="copies"
                        [ngModelOptions]="{ standalone: true }"
                        style="width: 65px"
                    />
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </form>
</div>


<modal-wrapper title="Add Skid" [options]="noBarcodeModalOptions" customClass="swal-wide" [autoShow]="false" (ready)="noBarcodeModal = $event">
    <div class="m-t-sm">
        <no-barcode-skid [validateOrderQty]="true" (onAddSkid)="onNoBarcodeAddSkid($event)" (onCancel)="closeNoBarcodeModal()"></no-barcode-skid>
    </div>
</modal-wrapper>
