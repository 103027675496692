import { CustomerPaymentTermService } from './customerpaymentterm.service';
import { CustomerCreditStatusService } from './customercreditstatus.service';
import { CustomerShipOnTypeService } from './customershipontype.service';

import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';

import { CustomerBasicInfoComponent } from './customer-basic-info/customer-basic-info.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';

import { CustomerService } from './customer.service';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerShippingAddressService } from './shared-entities/customer-shipping-address.service';
import { CustomerContactsService } from './shared-entities/customer-contacts.service';
import { ContactTypeService } from './contacttype.service';
import { CustomerPricingComponent } from './customer-pricing/customer-pricing.component';
import { CustomerPricingItemHistoryComponent } from './customer-pricing/history/customer-pricing-item-history.component';
import { CustomerShippingAddressAddComponent } from './customer-shipping-address-add/customer-shipping-address-add.component';
import { ReceiptFormatComponent } from './receipt-format/receipt-format.component';
import { ReceiptSizeTotalService } from './receipt-size-total.service';

import { MtPhoneControlModule } from '@mt-ng2/phone-control';
import { CustomPackagingAddComponent } from './custom-packaging/custom-packaging-add/custom-packaging-add.component';
import { CustomPackagingBasicInfoComponent } from './custom-packaging/custom-packaging-basic-info/custom-packaging-basic-info.component';
import { CustomPackagingsComponent } from './custom-packaging/custom-packaging-list/custom-packagings.component';
import { CustomPackagingDetailComponent } from './custom-packaging/custom-packaging-detail/custom-packaging-detail.component';
import { CustomerShippingAddressActionsComponent } from './customer-shipping-address-actions/customer-shipping-address-actions.component';

@NgModule({
    declarations: [
        CustomersComponent,
        CustomerHeaderComponent,
        CustomerDetailComponent,
        CustomerBasicInfoComponent,
        CustomerSettingsComponent,
        CustomerAddComponent,
        CustomerPricingComponent,
        CustomerPricingItemHistoryComponent,
        CustomerShippingAddressAddComponent,
        ReceiptFormatComponent,
        CustomPackagingAddComponent,
        CustomPackagingBasicInfoComponent,
        CustomPackagingDetailComponent,
        CustomPackagingsComponent,
        CustomerShippingAddressActionsComponent,
    ],
    exports: [CustomerShippingAddressAddComponent],
    imports: [SharedModule, CustomerRoutingModule, MtPhoneControlModule],
})
export class CustomerModule {
    static forRoot(): any {
        return {
            ngModule: CustomerModule,
            providers: [
                CustomerService,
                CustomerContactsService,
                ContactTypeService,
                CustomerShippingAddressService,
                CustomerPaymentTermService,
                CustomerCreditStatusService,
                CustomerShipOnTypeService,
                ReceiptSizeTotalService,
            ],
        };
    }
}
