import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPastingTeamLog } from '@model/interfaces/pasting-team-log';
import { BulkByDateService } from '@common/services/bulk-by-date.service';
import { Observable } from 'rxjs';

export const emptyPastingTeamLog: IPastingTeamLog = {
    BdlSk: null,
    BoardGrade: '',
    BoardTypeId: null,
    Break: false,
    Bundles: null,
    Caliper: null,
    Catcher: '',
    Count: null,
    CustomerName: '',
    Date: null,
    Feeder: '',
    Id: 0,
    Length: '',
    MachineNumber: '',
    Note: '',
    Ply: null,
    Pressman: '',
    Remarks: false,
    ReportId: 1,
    SalesOrderId: 0,
    ShiftCode: '',
    Skids: null,
    TimeOff: null,
    TimeOn: null,
    Width: '',
};

@Injectable()
export class PastingTeamLogService extends BulkByDateService<IPastingTeamLog> {
    constructor(public http: HttpClient) {
        super('/pasting-team-logs', http);
    }

    getEmptyPastingTeamLog(): IPastingTeamLog {
        return { ...emptyPastingTeamLog };
    }

    deleteAllByDate(date: Date): Observable<void> {
        const params = new HttpParams().set('date', date.toISOString().split('T')[0]);
        return this.http.delete<void>('/pasting-team-logs/', { params });
    }
}
