import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { TestStackService } from '../../test-scans/test-stack.service';
import { ITestStack } from '@model/interfaces/test-stack';

@Injectable({ providedIn: 'root' })
export class TestStackResolver implements Resolve<ITestStack[]> {
    constructor(private testStackService: TestStackService) {}

    resolve(): Observable<ITestStack[]> | ITestStack[] {
        return this.testStackService.getAll();
    }
}
