import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';

import { IOrderInfo } from '../model/interfaces/order-info';
import { Observable } from 'rxjs';

@Injectable()
export class OrderInfoService extends BaseService<IOrderInfo> {
    private emptyOrderInfo: IOrderInfo = {
        CustomerId: null,
        CustomerOrderSpecificationId: 0,
        CustomerPurchaseOrderNumber: null,
        CustomerSalesPersonId: null,
        CustomerShippingAddressId: null,
        CustomerTerms: null,
        Id: 0,
        IsCreditCardPurchase: null,
        IsOurMill: false,
        Notes: null,
        OrderDate: new Date(),
        OrderPriceTypeId: null,
        OrderTakenBy: null,
        PricePer: null,
        TradeDiscountPercentage: null,
    };

    constructor(public http: HttpClient) {
        super('/salesorders/OrderInfo', http);
    }

    getEmptyOrderInfo(): IOrderInfo {
        return { ...this.emptyOrderInfo };
    }

    getNewmanMarkingsById(id: number): Observable<boolean> {
        return this.http.get<boolean>(`/trim-sheets/newman-markings/${id}`);
    }
}
