<div>
    <form [formGroup]="form" (submit)="save()">
        <table class="table-padded" style="width:100%; table-layout: fixed;">
            <thead>
                <tr>
                    <th class="order-num-col">Order #</th>
                    <th>PO #</th>
                    <th class="order-num-col">Order #</th>
                    <th>PO #</th>
                </tr>
            </thead>
            <tbody formArrayName="updatedPos">
                <ng-container *ngFor="let control of updatedPos.controls; let i = index; let even = even">
                    <tr *ngIf="even">
                        <td class="order-num-col">{{orderPoMapping[i].orderNumber}}</td>
                        <td class="border-right"><input class="form-control" [formControl]="updatedPos.controls[i]"></td>
                        <td class="order-num-col"><span *ngIf="orderPoMapping[i+1]">{{orderPoMapping[i+1].orderNumber}}</span></td>
                        <td><span *ngIf="updatedPos.controls[i+1]"><input class="form-control" [formControl]="updatedPos.controls[i+1]"></span></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <br />
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </form>
</div>