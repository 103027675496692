import { IUser } from '../interfaces/user';
import { UserDynamicControls } from '@model/form-controls/user.form-controls';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';

export class UserDynamicControlsPartial extends UserDynamicControls {
    constructor(
        private userPartial?: IUser,
    ) {
        super(userPartial);

        this.Form.IsSalesPerson.labelHtml = '<label>Sales Person</label>';
        (<DynamicLabel>this.View.IsSalesPerson).label = 'Sales Person';
    }
}
