import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'open-existing-shipment',
    templateUrl: './open-existing-shipment.component.html',
})
export class OpenExistingShipmentComponent {
    shipmentId: number;

    @Output('onShipmentOpened') onShipmentOpened: EventEmitter<number> = new EventEmitter<number>();
    @Output('onCancel') onCancel: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('shipmentIdInput', { read: ElementRef }) shipmentIdInput: ElementRef;

    constructor(private shipmentService: ShipmentService, private notificationsService: NotificationsService) {}

    ngAfterViewInit(): void {
        this.shipmentIdInput.nativeElement.focus();
    }

    formSubmitted(): void {
        if (this.shipmentId) {
            this.shipmentService.openShipment(this.shipmentId).subscribe((answer) => {
                this.notificationsService.success(`Load #${this.shipmentId} successfully opened.`);
                this.onShipmentOpened.emit(this.shipmentId);
                this.shipmentId = null;
            });
        }
    }

    cancel(): void {
        this.onCancel.emit();
    }
}
