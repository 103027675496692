<div *ngIf="formCreated">
    <div>
        <a routerLink="/customers" class="btn btn-default">Customer List</a>
        <a routerLink="/customers/{{ customerId }}" class="btn btn-default">{{ customer.CompanyName }} Info</a>
    </div>
    <br />
    <form [formGroup]="customerPricingForm" (ngSubmit)="savePricingItems()">
        <div class="miles-card padded" formArrayName="pricingItems">
            <h4>Customer Pricing Information</h4>
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th><b>Item</b></th>
                        <th><b>Price</b></th>
                        <th><b>Date Effective</b></th>
                        <th><b>Notes</b></th>
                        <th><b>History</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of abstractControls; let i = index">
                        <td>{{ item.customerPricingItemPartial.BoardGrade.Name }}</td>
                        <td>
                            <dynamic-field
                                *ngIf="canEditCustomerPricing"
                                [field]="item.Form.Price"
                                [form]="getPricingItems(i)"
                                [overrideForm]="true"
                            ></dynamic-field>
                            <span *ngIf="!canEditCustomerPricing">{{ item.customerPricingItemPartial.Price | currency:'USD':'symbol':'1.4-4'}}</span>
                        </td>
                        <td>
                            <div class="pull-left">
                                <dynamic-field
                                    *ngIf="canEditCustomerPricing"
                                    [field]="item.Form.DateEffective"
                                    [form]="getPricingItems(i)"
                                    [overrideForm]="true"
                                ></dynamic-field>
                                <span *ngIf="!canEditCustomerPricing">{{ item.customerPricingItemPartial.DateEffective | date }}</span>
                            </div>
                            <div *ngIf="canEditCustomerPricing" class="pull-left">
                                <button type="button" class="btn btn-default btn-xs m-t-xs" (click)="setToday(i)">Today</button>
                            </div>
                        </td>
                        <td>
                            <dynamic-field
                                *ngIf="canEditCustomerPricing"
                                [field]="item.Form.Notes"
                                [form]="getPricingItems(i)"
                                [overrideForm]="true"
                            ></dynamic-field>
                            <span *ngIf="!canEditCustomerPricing">{{ item.customerPricingItemPartial.Notes}}</span>
                        </td>
                        <td>
                            <a
                                *ngIf="item.customerPricingItemPartial.Id"
                                routerLink="/customers/{{ customerId }}/pricing/{{ item.customerPricingItemPartial.BoardGrade.Id }}"
                                >History</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
            <button *ngIf="canEditCustomerPricing" class="btn btn-flat btn-success" type="submit">Save</button>
        </div>
    </form>
</div>
