import { FormDatePickerComponent } from '@mt-ng2/dynamic-form';
import { Component, OnInit } from '@angular/core';
import { DaysOfTheWeek } from '@mt-ng2/search-filter-daterange-control-config';

@Component({
    selector: 'app-custom-date-component',
    styles: [
        `
            .selected-date {
                color: white;
                background-color: #337ab7;
            }
            .date-picker-day-view {
                text-align: center;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                border-radius: 0.25rem;

                &.outside {
                    opacity: 0.5;
                }
            }
            .fa-calendar {
                margin-left: 8px;
            }
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .selected-date-label {
                min-width: 170px;
            }
            .date-picker-styles {
                min-width: 170px;
            }
        `,
    ],
    templateUrl: './custom-date-component.html',
})
export class CustomDateComponent extends FormDatePickerComponent implements OnInit {
    ngOnInit(): void {
        super.ngOnInit();
        this.firstDayOfTheWeek = DaysOfTheWeek.Sunday;
    }
}
