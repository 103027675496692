import { Component, OnInit } from '@angular/core';
import { PackagingLineOverrideActualInputComponent } from './override-actual-input.component';

@Component({
    selector: 'scale-override',
    styles: [`
        .btn-override {
            padding: 3px;
            min-width: 66px;
        }
    `],
    templateUrl: './override-actual-input.component.html',
})

export class ScaleOverrideComponent extends PackagingLineOverrideActualInputComponent {
    constructor() {
        super();
    }

    getActualValue(): number {
        if (this.override) {
            return this.override;
        }
        if (!this.skid.ActualWeight) {
            return null;
        }

        return this.skid.ActualWeight;
    }

    getTargetValue(): number {
        return this.skid.TargetWeight;
    }
}
