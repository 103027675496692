import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { ShipmentService } from '../shipments/shipment.service';
import { delay, map, switchMap } from 'rxjs/operators';
import { isNullOrWhitespace } from '@mt-ng2/common-functions';

export enum SortDirection {
    Asc,
    Desc,
}

export class CustomValidators {
    /**
     * @description
     * Validator that requires a FormArray to contain at least one control with a value.
     * The FormArray can be an array of FormGroups or FormControls. If the array is FormGroups,
     * then the `controlName` parameter must be supplied.
     *
     * The validator exists only as a function and not as a directive.
     *
     * @param controlName The name of the control in the parent FormGroup. Can be omitted if the
     * FormArray is a list of FormControls.
     *
     * @returns An error map with the `noValue` property set to TRUE if the validation check
     * fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     */
    static AtLeastOneValue(controlName?: string): ValidatorFn {
        const error = { noValue: true };
        return (array: FormArray): ValidationErrors | null => {
            const hasValues = array.controls.some((ac) => {
                if (isNullOrWhitespace(controlName)) {
                    return ac.value !== null;
                } else {
                    return ac.get(controlName).value !== null;
                }
            });
            return hasValues ? null : error;
        };
    }

    /**
     * @description
     * Validator that requires a FormArray to be sorted.
     * The FormArray can be an array of FormGroups or FormControls. If the array is FormGroups,
     * then the `controlName` parameter must be supplied.
     *
     * The validator exists only as a function and not as a directive.
     *
     * @param array The array to compare against.
     * @param controlName The name of the control in the FormGroup. Can be omitted if the
     * FormArray is a list of FormControls.
     * @param allowDuplicates Indicates the same value can appear multiple times. Default: FALSE.
     * @param sort The direction the values should be sorted by. Default: Asc.
     *
     * @returns An error map with the `nonSequential` property set to TRUE if the validation check
     * fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     */
    static FollowsSequence(array: FormArray, controlName?: string, allowDuplicates = false, sort = SortDirection.Asc): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const compare = (a, b) => !a || !b || (allowDuplicates && a === b) || (sort === SortDirection.Desc ? a > b : a < b);
            const i = array.controls.indexOf(control);
            if (!control.get(controlName).value || i < 0) {
                return null;
            }
            const prev = i === 0 ? null : array.controls[i - 1];
            let sequential;
            if (isNullOrWhitespace(controlName)) {
                sequential = compare(prev.value, control.value);
            } else {
                sequential = compare(prev?.get(controlName).value, control.get(controlName).value);
            }
            return sequential ? null : { nonSequential: true };
        };
    }

    /**
     * @description
     * Validator that requires a FormGroup to contain a value for every field if one or more fields have a value.
     * Disabled controls are ignored.
     *
     * The validator exists only as a function and not as a directive.
     *
     * @returns An error map with the `missingFields` property set to TRUE if the validation check
     * fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     */
    static AllOrNone(group: FormGroup): ValidationErrors | null {
        const keys = Object.keys(group.value);
        const emptyFields = keys.filter((k) => group.get(k).value === null || group.get(k).value === undefined);
        return emptyFields.length && emptyFields.length !== keys.length ? { missingFields: true } : null;
    }

    /**
     * @description
     * Validator that requires a FormControl value to not appear elsewhere in the given FormArray.
     * The FormArray can be an array of FormGroups or FormControls. If the array is FormGroups,
     * then the controlName parameter must be supplied.
     *
     * The validator exists only as a function and not as a directive.
     *
     * @param array The FormArray to check for matching values
     * @param controlName The name of the control in the FormGroup. Can be omitted if the
     * FormArray is a list of FormControls.
     *
     * @returns An error map with the `notUnique` property set to TRUE if the validation check
     * fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     */
    static NoDuplicateControlValues(array: FormArray, controlName?: string): ValidatorFn {
        const error = { notUnique: true };
        return (control: FormGroup | FormControl): ValidationErrors | null => {
            if (isNullOrWhitespace(controlName) && !control.value || !control.get(controlName).value) {
                return null;
            }
            let hasDuplicates = array.controls.some((ac) => {
                if (isNullOrWhitespace(controlName)) {
                    return ac !== control && ac.value === control.value;
                } else {
                    return ac !== control && ac.get(controlName).value === control.get(controlName).value;
                }
            });
            return hasDuplicates ? error : null;
        };
    }

    /**
     * @description
     * Validator for Shipment Ids that confirms the Id entered exists in the database.
     * The validator exists only as a function and not as a directive.
     *
     * @returns An error map with the `notExists` property set to TRUE if the validation
     * check fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     */
    static ShipmentExists(shipmentService: ShipmentService): AsyncValidatorFn {
        const error = { notExists: true };
        return (control: FormControl): Observable<ValidationErrors | null> => {
            const shipmentId = control.value;
            if (!shipmentId) {
                return of(null);
            }
            return of(shipmentId).pipe(
                // Angular will cancel existing subscriptions when it runs a validity check.
                // As long as the cancel happens within the 500ms, the network call won't be fired.
                delay(500),
                switchMap((shipmentId: number) => shipmentService.shipmentExists(shipmentId)),
                map((exists) => (exists ? null : error)),
            );
        };
    }

    /**
     * @description
     * Validator for Take From Order component that confirms that the
     * sales order corresponding to `takeFromSalesOrderId` has at least as many skids
     * as the user is reqeusting to take.
     *
     * @returns An error map with the `notEnoughSkids` property set to TRUE if the validation fails,
     * otherwise `null`.
     */
    static OrderHasEnoughSkidsToTakeRequestedQuantity(shipmentService: ShipmentService, giveToSalesOrderId: number): AsyncValidatorFn {
        const error = { notEnoughSkids: true };
        return (group: FormGroup): Observable<ValidationErrors | null> => {
            const takeFromSalesOrderId = group.get('TakeFromSalesOrderId').value;
            const requestedQuantity = group.get('Quantity').value;
            if (!takeFromSalesOrderId || !requestedQuantity) {
                return of(null);
            }

            return of([giveToSalesOrderId, takeFromSalesOrderId]).pipe(
                delay(500),
                switchMap(() => {
                    return forkJoin([
                        shipmentService.getAvailableSkidCount(giveToSalesOrderId, takeFromSalesOrderId),
                        shipmentService.getSkidsShipped(takeFromSalesOrderId),
                    ]);
                }),
                map(([numSkids, numSkidsShipped]) => {
                    if (numSkids < requestedQuantity) {
                        const msg = `There are only ${numSkids} skids available to take from this order.`
                            + (numSkidsShipped
                                    ? `The rest (${numSkidsShipped}) are associated with an open load.`
                                    : '');

                        return {
                            ...error,
                            message: msg,
                        };
                    }
                    return null;
                }),
            );
        };
    }

    /**
     * @description
     * Validator for StockItem PoundsBerBundle that confirms that the stock item has
     * one of the valid options for pounds per bundle selected.
     *
     * @returns An error map with the `invalidPoundsPerBundleValue` property set to TRUE if the validation fails,
     * otherwise `null`.
     */
    static ValidPoundsPerBundleValue(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        const valueIsValid = value === 40 || value === 50;
        return valueIsValid ? null : { invalidPoundsPerBundleValue: true };
    }
}
