<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && customerId > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Receipt Format
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>

    <div class="row m-t-md">
        <div class="col-xs-6">
            <h5>Details:</h5>
            <div class="font-weight-bold">
                <i
                    class="fa fa-fw"
                    [ngClass]="{ 'fa-check text-success': receiptFormat?.ShowSkidIds, 'fa-remove text-danger': !receiptFormat?.ShowSkidIds }"
                ></i>
                Skid ID
                <br />
                <i
                    class="fa fa-fw"
                    [ngClass]="{ 'fa-check text-success': receiptFormat?.ShowPieces, 'fa-remove text-danger': !receiptFormat?.ShowPieces }"
                ></i>
                Pieces
                <br />
                <i
                    class="fa fa-fw"
                    [ngClass]="{ 'fa-check text-success': receiptFormat?.ShowBundles, 'fa-remove text-danger': !receiptFormat?.ShowBundles }"
                ></i>
                Bundles
                <br />
                <i
                    class="fa fa-fw"
                    [ngClass]="{ 'fa-check text-success': receiptFormat?.ShowWeight, 'fa-remove text-danger': !receiptFormat?.ShowWeight }"
                ></i>
                Weight
            </div>
        </div>
        <div class="col-xs-6">
            <h5>Size Totals:</h5>
            <div class="font-weight-bold">{{ receiptFormat?.ReceiptSizeTotal?.Name }}</div>
        </div>
    </div>

    <hr />

    <div class="font-weight-bold">
        <i
            class="fa fa-fw"
            [ngClass]="{ 'fa-check text-success': receiptFormat?.OmitNewmanHeader, 'fa-remove text-danger': !receiptFormat?.OmitNewmanHeader }"
        ></i>
        Omit Newman Header
    </div>
</div>
<div *ngIf="formCreated && isEditing">
    <form class="miles-form miles-card padded" [formGroup]="receiptFormatForm" (ngSubmit)="formSubmitted()">
        <h4>Receipt Format</h4>

        <div class="row m-t-md">
            <div class="col-xs-6">
                <h5>Details:</h5>
                <dynamic-field [field]="abstractReceiptFormatControls.ShowSkidIds" [form]="receiptFormatForm"></dynamic-field>
                <dynamic-field [field]="abstractReceiptFormatControls.ShowPieces" [form]="receiptFormatForm"></dynamic-field>
                <dynamic-field [field]="abstractReceiptFormatControls.ShowBundles" [form]="receiptFormatForm"></dynamic-field>
                <dynamic-field [field]="abstractReceiptFormatControls.ShowWeight" [form]="receiptFormatForm"></dynamic-field>
            </div>
            <div class="col-xs-6">
                <h5>Size Totals:</h5>
                <dynamic-field [field]="abstractReceiptFormatControls.ReceiptSizeTotalId" [form]="receiptFormatForm"></dynamic-field>
            </div>
        </div>

        <hr />

        <div class="m-b-sm">
            <dynamic-field [field]="abstractReceiptFormatControls.OmitNewmanHeader" [form]="receiptFormatForm"></dynamic-field>
        </div>

        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </form>
</div>
