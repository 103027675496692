import { ChangeDetectorRef, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { TagSkidTypeService } from '../tagskidtype.service';
import { OrderCustomerSpecsService } from '../order-customer-specs.service';
import { OrderCustomerSpecificationDynamicControlsPartial } from '../../model/partials/order-customer-specification.form-controls';
import { IOrderCustomerSpecificationPartial } from '../../model/partials/order-customer-specification';
import { IOrderInfo } from '../../model/interfaces/order-info';
import { OrderInfoService } from '../order-info.service';
import { BandingInstructionService } from '../banding-instruction.service';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../../customers/customer.service';
import { finalize, tap, flatMap } from 'rxjs/operators';
import { SalesOrderService } from '../sales-order.service';
import { ISalesOrder } from '../../model/interfaces/sales-order';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { OrderStatusIds } from '@model/OrderStatuses';
import { IBandingInstruction } from '@model/interfaces/banding-instruction';
import { ITagSkidType } from '@model/interfaces/tag-skid-type';

@Component({
    selector: 'order-customer-specs',
    templateUrl: './order-customer-specs.component.html',
})
export class OrderCustomerSpecsComponent implements OnInit {
    @Input('orderInfoId') orderInfoId: number;
    @Input('salesOrderId') salesOrderId: number;
    @Input('customerId') customerId: number;

    @Output('onCancel') onCancel = new EventEmitter<any>();
    @Output('onSave') onSave = new EventEmitter<number>();
    @Output('onError') onError = new EventEmitter<any>();

    abstractOrderCustomerSpecificationControls: any;
    orderCustomerSpecsForm: FormGroup;
    orderInfo: IOrderInfo;
    customer: ICustomer;
    customerOrderSpecification: IOrderCustomerSpecificationPartial;
    doubleClickIsDisabled = false;
    formCreated = false;
    salesOrder: ISalesOrder;
    canEditSpecsDetailsInPending: boolean;
    canEditOrderAnyStatus: boolean;
    bandingInstructions: IBandingInstruction[];
    tagSkidTypes: ITagSkidType[];

    get formIsLocked(): boolean {
        if (this.customerId) {
            return false;
        }

        let orderStatusId = this.salesOrder ? this.salesOrder.OrderStatusId : OrderStatusIds.Open;
        if (this.salesOrder.OrderStatusId === OrderStatusIds.PendingReview && this.canEditSpecsDetailsInPending) {
            return false;
        }
        return !this.canEditOrderAnyStatus && (orderStatusId === OrderStatusIds.PendingReview ||
            orderStatusId === OrderStatusIds.Approved ||
            orderStatusId === OrderStatusIds.CreditHold);
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private tagSkidTypeService: TagSkidTypeService,
        private orderInfoService: OrderInfoService,
        private orderCustomerSpecsService: OrderCustomerSpecsService,
        private bandingInstructionService: BandingInstructionService,
        private customerService: CustomerService,
        private salesOrderService: SalesOrderService,
        private claimsService: ClaimsService,
    ) { }

    ngOnInit(): void {
        this.canEditOrderAnyStatus = this.claimsService.hasClaim(ClaimTypes.Orders_CanEditOrdersAnyStatus, [ClaimValues.FullAccess]);
        this.canEditSpecsDetailsInPending = this.claimsService.hasClaim(ClaimTypes.Orders_CanEditSpecsAndPriceBreakdownInPending, [ClaimValues.FullAccess]);

        if (this.orderInfoId || this.customerId) {
            let apiCalls = [
                this.tagSkidTypeService.getItems(),
                this.bandingInstructionService.getItems(),
                this.bindParentInfo(),
            ];
            if (this.salesOrderId) {
                apiCalls.push(this.salesOrderService.getById(this.salesOrderId));
            }
            forkJoin(apiCalls).subscribe(
                ([tagSkidTypes, bandingInstructions, bindParentInfo, ...rest]) => {
                    this.tagSkidTypes = tagSkidTypes as ITagSkidType[];
                    this.bandingInstructions = bandingInstructions as IBandingInstruction[];

                    if (this.salesOrderId) {
                        this.salesOrder = rest[0] as ISalesOrder;
                    }

                    this.createForm();
                },
            );
        }
    }

    createForm(): void {
        if (this.orderInfo) {
            this.formatPriceBreakdown();
        }
        this.getControls();
        this.orderCustomerSpecsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        if (this.salesOrder && this.salesOrder.ManufacturingOrderDetail && this.salesOrder.ManufacturingOrderDetail.IsDressStock) {
            (<FormGroup>this.orderCustomerSpecsForm.controls.OrderCustomerSpecification).controls.IsFortyPoundBundle.setValue(true);
            (<FormGroup>this.orderCustomerSpecsForm.controls.OrderCustomerSpecification).controls.IsFortyPoundBundle.disable();
        }
        this.setFieldVisibility();
    }

    private setFieldVisibility(): void {
        if (this.formIsLocked) {
            this.orderCustomerSpecsForm.disable();
        }
    }

    // TODO: JJG MOVE THIS TO OBJECT CONSTRUCTOR
    formatPriceBreakdown(): void {
        const orderPriceSummary = this.orderInfo.CustomerOrderPriceInfo;

        if (orderPriceSummary && this.customerOrderSpecification) {
            this.customerOrderSpecification.PriceBreakdown =
                (orderPriceSummary.BasePrice ? orderPriceSummary.BasePrice.toFixed(2) + ' BASE\n' : '') +
                (orderPriceSummary.Paste ? orderPriceSummary.Paste.toFixed(2) + ' PASTE\n' : '') +
                (orderPriceSummary.Cut ? orderPriceSummary.Cut.toFixed(2) + ' CUT\n' : '') +
                (orderPriceSummary.BackTrim ? orderPriceSummary.BackTrim.toFixed(2) + ' BACKTRIM\n' : '') +
                (orderPriceSummary.AdditionalCut ? orderPriceSummary.AdditionalCut.toFixed(2) + ' ADDITIONAL CUT\n' : '') +
                (orderPriceSummary.BustInHalf ? orderPriceSummary.BustInHalf.toFixed(2) + ' BUST IN HALF\n' : '') +
                (orderPriceSummary.CornerCut ?
                    (orderPriceSummary.CornerCutType && orderPriceSummary.CornerCutType.Name ?
                        orderPriceSummary.CornerCut.toFixed(2) + ' ' + orderPriceSummary.CornerCutType.Name + ' CORNER\n' :
                        orderPriceSummary.CornerCut.toFixed(2) + ' CORNER CUT\n') :
                    '')
                 +
                (orderPriceSummary.BundleTied ? orderPriceSummary.BundleTied.toFixed(2) + ' BUNDLE TIED\n' : '') +
                (orderPriceSummary.Flip ? orderPriceSummary.Flip.toFixed(2) + ' FLIP\n' : '') +
                (orderPriceSummary.Waste ? orderPriceSummary.Waste.toFixed(2) + ' WASTE\n' : '') +
                (orderPriceSummary.Frt ? orderPriceSummary.Frt.toFixed(2) + ' FRT\n' : '') +
                (orderPriceSummary.Qty ? orderPriceSummary.Qty.toFixed(2) + ' QTY\n' : '') +
                (orderPriceSummary.BasisWeight ? orderPriceSummary.BasisWeight.toFixed(2) + ' BASIS WEIGHT\n' : '') +
                (orderPriceSummary.Stain ? orderPriceSummary.Stain.toFixed(2) + ' STAIN\n' : '') +
                (orderPriceSummary.MarkUp ? orderPriceSummary.MarkUp.toFixed(2) + ' MARK UP\n' : '') +
                (orderPriceSummary.SkidCharge ? orderPriceSummary.SkidCharge.toFixed(2) + ' SKID CHARGE\n' : '') +
                (orderPriceSummary.Commission ? orderPriceSummary.Commission.toFixed(2) + ' COMMISSION\n' : '') +
                (orderPriceSummary.RateOne
                    ? orderPriceSummary.RateOne.toFixed(2) + (orderPriceSummary.OtherOne ? ` ${orderPriceSummary.OtherOne}\n` : ' RATE(1)\n')
                    : '') +
                (orderPriceSummary.RateTwo
                    ? orderPriceSummary.RateTwo.toFixed(2) + (orderPriceSummary.OtherTwo ? ` ${orderPriceSummary.OtherTwo}\n` : ' RATE(2)\n')
                    : '') +
                '============== \n' +
                (
                    orderPriceSummary.BasePrice +
                    (orderPriceSummary.Paste ? orderPriceSummary.Paste : 0) +
                    (orderPriceSummary.Cut ? orderPriceSummary.Cut : 0) +
                    (orderPriceSummary.BackTrim ? orderPriceSummary.BackTrim : 0) +
                    (orderPriceSummary.AdditionalCut ? orderPriceSummary.AdditionalCut : 0) +
                    (orderPriceSummary.BustInHalf ? orderPriceSummary.BustInHalf : 0) +
                    (orderPriceSummary.CornerCut ? orderPriceSummary.CornerCut : 0) +
                    (orderPriceSummary.BundleTied ? orderPriceSummary.BundleTied : 0) +
                    (orderPriceSummary.Flip ? orderPriceSummary.Flip : 0) +
                    (orderPriceSummary.Waste ? orderPriceSummary.Waste : 0) +
                    (orderPriceSummary.Frt ? orderPriceSummary.Frt : 0) +
                    (orderPriceSummary.Qty ? orderPriceSummary.Qty : 0) +
                    (orderPriceSummary.BasisWeight ? orderPriceSummary.BasisWeight : 0) +
                    (orderPriceSummary.Stain ? orderPriceSummary.Stain : 0) +
                    (orderPriceSummary.MarkUp ? orderPriceSummary.MarkUp : 0) +
                    (orderPriceSummary.SkidCharge ? orderPriceSummary.SkidCharge : 0) +
                    (orderPriceSummary.Commission ? orderPriceSummary.Commission : 0) +
                    (orderPriceSummary.RateOne ? orderPriceSummary.RateOne : 0) +
                    (orderPriceSummary.RateTwo ? orderPriceSummary.RateTwo : 0)
                ).toFixed(2) +
                ' PER ' +
                orderPriceSummary.PricingChargePerType.Name.toUpperCase();
        }
    }

    bindParentInfo(): any {
        let parentGetFn: Observable<any>;

        if (this.orderInfoId) {
            parentGetFn = this.orderInfoService.getById(this.orderInfoId).pipe(tap((answer) => (this.orderInfo = answer)));
        } else if (this.customerId) {
            parentGetFn = this.customerService.getById(this.customerId).pipe(tap((answer) => (this.customer = answer)));
        }

        return parentGetFn.pipe(flatMap(() => this.getCustomerOrderSpecificationById()));
    }

    getCustomerOrderSpecificationById(): any {
        let id = 0;
        if (this.orderInfo && this.orderInfo.CustomerOrderSpecificationId) {
            id = this.orderInfo.CustomerOrderSpecificationId;
        } else if (this.customer && this.customer.OrderCustomerSpecificationId) {
            id = this.customer.OrderCustomerSpecificationId;
        }

        return this.orderCustomerSpecsService.getById(id).pipe(
            tap((answer) => {
                if (answer === null) {
                    this.customerOrderSpecification = this.orderCustomerSpecsService.getEmptyOrderCustomerSpecifications();
                } else {
                    this.customerOrderSpecification = answer;
                }
            }),
        );
    }

    getControls(): void {
        this.abstractOrderCustomerSpecificationControls = new OrderCustomerSpecificationDynamicControlsPartial(
            this.customerOrderSpecification,
            {
                bandingInstructions: this.bandingInstructions,
                tagSkidTypes: this.tagSkidTypes,
            },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            OrderCustomerSpecification: this.fb.group({}),
        });
    }

    formSubmitted(navToPriceBreakdown = false): void {
        if (this.orderCustomerSpecsForm.valid) {
            Object.assign(this.customerOrderSpecification, this.orderCustomerSpecsForm.value.OrderCustomerSpecification);
            if (this.customerOrderSpecification.Id === 0) {
                let createFn: Observable<number>;

                if (this.orderInfoId) {
                    createFn = this.orderCustomerSpecsService.createOrderInfoSpec(this.orderInfoId, this.customerOrderSpecification);
                } else if (this.customerId) {
                    createFn = this.orderCustomerSpecsService.createOrderSpec(this.customerId, this.customerOrderSpecification);
                }

                createFn.pipe(finalize(() => this.enableDoubleClick())).subscribe((answer) => {
                    this.customerOrderSpecification.Id = answer;
                    if (this.orderInfo) {
                        this.orderInfo.CustomerOrderSpecificationId = answer;
                    }
                    if (this.customer) {
                        this.customer.OrderCustomerSpecificationId = answer;
                    }
                    this.success(answer);
                    if (navToPriceBreakdown) {
                        this.router.navigate([`price-info/${this.orderInfo.CustomerOrderPriceInfoId || 0}`], { relativeTo: this.route });
                    }
                });
            } else {
                this.orderCustomerSpecsService
                    .update(this.customerOrderSpecification)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.success(this.customerOrderSpecification.Id);
                        if (navToPriceBreakdown) {
                            this.router.navigate([`price-info/${this.orderInfo.CustomerOrderPriceInfoId || 0}`], { relativeTo: this.route });
                        }
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.orderCustomerSpecsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    navigateToCustomerOrderPricing(): void {
        if (this.orderInfo && this.formIsLocked) {
            this.router.navigate([`price-info/${this.orderInfo.CustomerOrderPriceInfoId || 0}`], { relativeTo: this.route });
        } else {
            this.formSubmitted(true);
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onCancel.emit();
    }

    error(): void {
        this.onError.emit();
    }

    success(id: number): void {
        this.onSave.emit(id);
    }

    receiptPartInfoBlur(): void {
        let receiptPartInfo = (<FormGroup>this.orderCustomerSpecsForm.controls.OrderCustomerSpecification).controls.ReceiptPartInfo;
        let customerProductId = (<FormGroup>this.orderCustomerSpecsForm.controls.OrderCustomerSpecification).controls.CustomerProductId;
        let value = receiptPartInfo.value;
        customerProductId.setValue(value, { emitEvent: true, emitModelToViewChange: true});
    }
}
