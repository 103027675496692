import { ClaimTypes } from '../model/ClaimTypes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { CuttingTeamLogEntryComponent } from './cutting-team-logs/cutting-team-log-entry.component';
import { PastingTeamLogEntryComponent } from './pasting-team-logs/pasting-team-log-entry.component';
import { LiningGuillotineLogEntryComponent } from './lining-guillotine-logs/lining-guillotine-log-entry.component';
import { RotarySlitDivisionLogEntryComponent } from './rotary-slit-division-logs/rotary-slit-division-log-entry.component';
import { ConvertingDataSettingsComponent } from './converting-data-settings/converting-data-settings.component';

class CuttingTeamLogRoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.ConvertingData_CuttingTeamLogs) {}
}

class PastingTeamLogRoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.ConvertingData_PastingTeamLogs) {}
}

class LiningGuillotineLogRoleGuard implements IRoleGuarded {
    constructor(
        public title: string,
        public claimValues = [ClaimValues.FullAccess],
        public claimType = ClaimTypes.ConvertingData_LiningGuillotineLogs,
    ) {}
}

class RotarySlitDivisionLogRoleGuard implements IRoleGuarded {
    constructor(
        public title: string,
        public claimValues = [ClaimValues.FullAccess],
        public claimType = ClaimTypes.ConvertingData_RotarySlitDivisionLogs,
    ) {}
}

const convertingDataRoleGuard = {
    claimType: ClaimTypes.ConvertingData,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
    title: 'Converting Data Settings',
};

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CuttingTeamLogEntryComponent,
        data: new CuttingTeamLogRoleGuard('Cutting Team Report'),
        path: 'converting-data/cutting',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PastingTeamLogEntryComponent,
        data: new PastingTeamLogRoleGuard('Pasting Team Report'),
        path: 'converting-data/pasting',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: LiningGuillotineLogEntryComponent,
        data: new LiningGuillotineLogRoleGuard('Lining Guillotine Report'),
        path: 'converting-data/lining-guillotine',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: RotarySlitDivisionLogEntryComponent,
        data: new RotarySlitDivisionLogRoleGuard('Rotary Slit Division Report'),
        path: 'converting-data/rotary-slit-division',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ConvertingDataSettingsComponent,
        data: convertingDataRoleGuard,
        path: 'converting-data/settings',
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class ConvertingDataRoutingModule {}
