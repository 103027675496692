import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPastingTeamLog } from '../interfaces/pasting-team-log';
import { IBoardType } from '../interfaces/board-type';
import { ISalesOrder } from '../interfaces/sales-order';

export interface IPastingTeamLogDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    boardTypes?: IBoardType[];
}

export class PastingTeamLogDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];
    boardTypes: IBoardType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private pastingteamlog?: IPastingTeamLog, additionalParameters?: IPastingTeamLogDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PastingTeamLog';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.boardTypes = additionalParameters && additionalParameters.boardTypes || undefined;

        this.Form = {
            BdlSk: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdl Sk',
                name: 'BdlSk',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.BdlSk || null,
            }),
            BoardGrade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGrade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('BoardGrade') && this.pastingteamlog.BoardGrade !== null ? this.pastingteamlog.BoardGrade.toString() : '',
            }),
            BoardTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Type',
                name: 'BoardTypeId',
                options: this.boardTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.BoardTypeId || null,
            }),
            Break: new DynamicField({
                formGroup: this.formGroup,
                label: 'Break',
                name: 'Break',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Break') && this.pastingteamlog.Break !== null ? this.pastingteamlog.Break : false,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.Caliper || null,
            }),
            Catcher: new DynamicField({
                formGroup: this.formGroup,
                label: 'Catcher',
                name: 'Catcher',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Catcher') && this.pastingteamlog.Catcher !== null ? this.pastingteamlog.Catcher.toString() : '',
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.Count || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('CustomerName') && this.pastingteamlog.CustomerName !== null ? this.pastingteamlog.CustomerName.toString() : '',
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.pastingteamlog && this.pastingteamlog.Date || null,
            }),
            Feeder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Feeder',
                name: 'Feeder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Feeder') && this.pastingteamlog.Feeder !== null ? this.pastingteamlog.Feeder.toString() : '',
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Length') && this.pastingteamlog.Length !== null ? this.pastingteamlog.Length.toString() : '',
            }),
            MachineNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Machine Number',
                name: 'MachineNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('MachineNumber') && this.pastingteamlog.MachineNumber !== null ? this.pastingteamlog.MachineNumber.toString() : '',
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Note') && this.pastingteamlog.Note !== null ? this.pastingteamlog.Note.toString() : '',
            }),
            Ply: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ply',
                name: 'Ply',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.Ply || null,
            }),
            Pressman: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pressman',
                name: 'Pressman',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Pressman') && this.pastingteamlog.Pressman !== null ? this.pastingteamlog.Pressman.toString() : '',
            }),
            Remarks: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remarks',
                name: 'Remarks',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Remarks') && this.pastingteamlog.Remarks !== null ? this.pastingteamlog.Remarks : false,
            }),
            ReportId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report',
                name: 'ReportId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.ReportId || 1,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.SalesOrderId || null,
            }),
            ShiftCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Code',
                name: 'ShiftCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('ShiftCode') && this.pastingteamlog.ShiftCode !== null ? this.pastingteamlog.ShiftCode.toString() : '',
            }),
            Skids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skids',
                name: 'Skids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.Skids || null,
            }),
            TimeOff: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time Off',
                name: 'TimeOff',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('TimeOff') && this.pastingteamlog.TimeOff !== null ? this.pastingteamlog.TimeOff.toString() : '',
            }),
            TimeOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time On',
                name: 'TimeOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('TimeOn') && this.pastingteamlog.TimeOn !== null ? this.pastingteamlog.TimeOn.toString() : '',
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Width') && this.pastingteamlog.Width !== null ? this.pastingteamlog.Width.toString() : '',
            }),
        };

        this.View = {
            BdlSk: new DynamicLabel(
                'Bdl Sk',
                this.pastingteamlog && this.pastingteamlog.BdlSk || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            BoardGrade: new DynamicLabel(
                'Board Grade',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('BoardGrade') && this.pastingteamlog.BoardGrade !== null ? this.pastingteamlog.BoardGrade.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            BoardTypeId: new DynamicLabel(
                'Board Type',
                getMetaItemValue(this.boardTypes, this.pastingteamlog && this.pastingteamlog.hasOwnProperty('BoardTypeId') && this.pastingteamlog.BoardTypeId !== null ? this.pastingteamlog.BoardTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Break: new DynamicLabel(
                'Break',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Break') && this.pastingteamlog.Break !== null ? this.pastingteamlog.Break : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Bundles: new DynamicLabel(
                'Bundles',
                this.pastingteamlog && this.pastingteamlog.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.pastingteamlog && this.pastingteamlog.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Catcher: new DynamicLabel(
                'Catcher',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Catcher') && this.pastingteamlog.Catcher !== null ? this.pastingteamlog.Catcher.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.pastingteamlog && this.pastingteamlog.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CustomerName: new DynamicLabel(
                'Customer Name',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('CustomerName') && this.pastingteamlog.CustomerName !== null ? this.pastingteamlog.CustomerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Date: new DynamicLabel(
                'Date',
                this.pastingteamlog && this.pastingteamlog.Date || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Feeder: new DynamicLabel(
                'Feeder',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Feeder') && this.pastingteamlog.Feeder !== null ? this.pastingteamlog.Feeder.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Length') && this.pastingteamlog.Length !== null ? this.pastingteamlog.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MachineNumber: new DynamicLabel(
                'Machine Number',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('MachineNumber') && this.pastingteamlog.MachineNumber !== null ? this.pastingteamlog.MachineNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Note: new DynamicLabel(
                'Note',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Note') && this.pastingteamlog.Note !== null ? this.pastingteamlog.Note.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ply: new DynamicLabel(
                'Ply',
                this.pastingteamlog && this.pastingteamlog.Ply || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Pressman: new DynamicLabel(
                'Pressman',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Pressman') && this.pastingteamlog.Pressman !== null ? this.pastingteamlog.Pressman.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Remarks: new DynamicLabel(
                'Remarks',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Remarks') && this.pastingteamlog.Remarks !== null ? this.pastingteamlog.Remarks : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReportId: new DynamicLabel(
                'Report',
                this.pastingteamlog && this.pastingteamlog.ReportId || 1,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.pastingteamlog && this.pastingteamlog.hasOwnProperty('SalesOrderId') && this.pastingteamlog.SalesOrderId !== null ? this.pastingteamlog.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShiftCode: new DynamicLabel(
                'Shift Code',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('ShiftCode') && this.pastingteamlog.ShiftCode !== null ? this.pastingteamlog.ShiftCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Skids: new DynamicLabel(
                'Skids',
                this.pastingteamlog && this.pastingteamlog.Skids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TimeOff: new DynamicLabel(
                'Time Off',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('TimeOff') && this.pastingteamlog.TimeOff !== null ? this.pastingteamlog.TimeOff.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeOn: new DynamicLabel(
                'Time On',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('TimeOn') && this.pastingteamlog.TimeOn !== null ? this.pastingteamlog.TimeOn.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.pastingteamlog && this.pastingteamlog.hasOwnProperty('Width') && this.pastingteamlog.Width !== null ? this.pastingteamlog.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
