import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';

import { IUser } from '../../model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { AuthEntityService } from '../../auth-entity/auth-entity.service'; // added
import { IUserRole } from '../../model/interfaces/user-role'; // added
import { IAuthUser } from '../../model/interfaces/auth-user'; // added
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { ICreateUserPayload } from '../../model/interfaces/custom/create-user-payload';
import { AuthService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    additionalConfigs: any[] = [];
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    constructor(
        private userService: UserService,
        public notificationsService: NotificationsService,
        private authEntitiyService: AuthEntityService,
        public router: Router,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        if (this.isNewUser()) {
            this.authEntitiyService
                .getAllRoles() // added
                .subscribe((answer) => {
                    this.roles = answer.body;
                    this.setConfig();
                });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        let pwConfigControls: string[] = ['SendResetEmail', 'Password', 'ConfirmPassword'];
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(this.user.AuthUser, null, pwConfigControls);
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(this.user.AuthUser, this.roles);
        return [pwConfig, roleConfig];
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser()) {
            this.config = this.formFactory.getForCreate(this.additionalConfigs);
            this.isEditing = true;
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            this.router.navigate(['/users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form): void {
        let passwordMatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordMatch = false;
            }
        }
        if (form.valid && passwordMatch) {
            this.formFactory.assignFormValues(this.user, form.value.User);
            if (this.isNewUser()) {
                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.SendResetEmail || false,
                    User: this.user,
                    Username: form.value.AuthUser.Username,
                    UserRoleId: form.value.AuthUser.RoleId,
                };
                // handle new user save
                this.userService
                    .createUser(data)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        this.router.navigate(['/users/' + answer]);
                        this.userService.emitChange(this.user);
                        this.success();
                    });
            } else {
                // handle existing user save
                this.userService
                    .updateVersion(this.user)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        (this.user.Version = answer),
                            (this.isEditing = false),
                            this.success(),
                            this.userService.emitChange(this.user),
                            this.setConfig();
                    });
            }
        } else {
            if (!passwordMatch) {
                this.error('Passwords do not match');
                this.enableDoubleClick();
            } else {
                common.markAllFormFieldsAsTouched(form);
                this.error();
                this.enableDoubleClick();
            }
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('User Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
