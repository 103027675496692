<div class="row">
    <div class="col-md-7">
        <h2>Custom Packagings</h2>
    </div>
</div>

<entity-list [entities]="customPackagings" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getCustomPackagings()" (onItemSelected)="customPackagingSelected($event)"
    [entityListConfig]="entityListConfig">
</entity-list>

<div *ngIf="canAddCustomPackaging" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['add']">
        <span class="fa fa-plus"></span>
    </a>
</div>