import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { StockItemDynamicControls, IStockItemDynamicControlsParameters } from '../form-controls/stock-item.form-controls';
import { IStockItem } from '../interfaces/stock-item';
import { OverrideNumericFunctionsEmptyWhenZero, OverrideNumericFunctionsEmptyWhenZeroAndTruncate } from '@common/common-functions.form-controls';
import { CustomValidators } from '@common/custom-validators';

export class StockItemDynamicControlsPartial extends StockItemDynamicControls {
    constructor(stockitemPartial?: IStockItem, additionalParameters?: IStockItemDynamicControlsParameters) {
        super(stockitemPartial, additionalParameters);

        (<DynamicField>this.Form.CaliperMin).labelHtml = '<label>Caliper (Min)</label>';
        (<DynamicField>this.Form.CaliperMin).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.CaliperMin).validators.max = 0.999;

        (<DynamicField>this.Form.CaliperMax).labelHtml = '<label>Caliper (Max)</label>';
        (<DynamicField>this.Form.CaliperMax).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.CaliperMax).validators.max = 0.999;

        (<DynamicField>this.Form.TareWeight).labelHtml = '<label>Tare</label>';
        (<DynamicField>this.Form.NetWeight).labelHtml = '<label>Net Wgt</label>';
        (<DynamicField>this.Form.TopColorId).labelHtml = '<label>Top</label>';
        (<DynamicField>this.Form.BottomColorId).labelHtml = '<label>Bottom</label>';
        (<DynamicField>this.Form.DefectTypeId).labelHtml = '<label>Defect</label>';
        (<DynamicField>this.Form.StockItemTypeId).labelHtml = '<label>Type</label>';
        (<DynamicField>this.Form.IsPerfect).labelHtml = '<label>Perfect Stock</label>';
        (<DynamicField>this.Form.StockIdNumber).labelHtml = '<label>Stock ID</label>';
        (<DynamicField>this.Form.DateCreated).labelHtml = '<label>Date</label>';
        (<DynamicField>this.Form.IsAvailable).labelHtml = '<label>Add Stock to Available Inventory</label>';

        (<DynamicField>this.Form.BoardGradeId).type.inputType = SelectInputTypes.Dropdown;

        this.Form.Sides = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sides',
            name: 'Sides',
            options: [
                { Id: 0, Name: '0' },
                { Id: 1, Name: '1' },
                { Id: 2, Name: '2' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            }),
            validation: null,
            validators: { required: true },
            value: null,
        });

        this.Form.PoundsPerBundle = new DynamicField({
            formGroup: this.formGroup,
            label: 'Pounds Per Bundle',
            name: 'PoundsPerBundle',
            options: [
                { Id: 50, Name: '50 lb bundles' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            }),
            validation: [noZeroRequiredValidator, CustomValidators.ValidPoundsPerBundleValue],
            validators: { required: true },
            value: null,
        });

        // Force fields to be empty instead of showing '0' when value is null.

        OverrideNumericFunctionsEmptyWhenZero([this.Form.TareWeight, this.Form.NetWeight, this.Form.CaliperMin, this.Form.CaliperMax]);
        OverrideNumericFunctionsEmptyWhenZeroAndTruncate([this.Form.Bundles]);

        (<DynamicField>this.Form.LotNumber).validation = [ Validators.required, Validators.minLength(6), Validators.maxLength(6) ];
        (<DynamicField>this.Form.LotNumber).validators = { 'required': true, 'minlength': 6, 'maxlength': 6 };
    }
}
