import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PackagingLineOverrideActualInputComponent } from './override-actual-input.component';

@Component({
    selector: 'scanner-override',
    styles: [`
        .btn-override {
            padding: 3px;
            min-width: 66px;
        }
        .btn-bdls {
            background-color: #248af3;
            color: #fff;
        }
    `],
    templateUrl: './scanner-override.component.html',
})

export class ScannerOverrideComponent extends PackagingLineOverrideActualInputComponent {
    @Input() bundlesLabel: string;
    @Input() isOverridingBundles = false;
    @Output() onOverrideBundles: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
        super();
    }

    getActualValue(): number {
        if (this.override) {
            return this.override;
        }
        if (!this.skid.SkidPiles?.length) {
            return this.skid.ActualCount ? this.skid.ActualCount : null;
        }

        let total = 0;
        this.skid.SkidPiles.forEach((item) => (total += item.Count));
        return total;
    }

    getTargetValue(): number {
        return this.skid.TargetCount;
    }

    getActualBundles(): number {
        return this.override ? this.override : this.skid.ActualBundles;
    }

    toggleBundlesInput(): void {
        this.override = 0;
        this.isOverridingBundles = !this.isOverridingBundles;
    }

    saveOverride(): void {
        this.toggleOverride();

        if (this.isOverridingBundles) {
            this.onOverrideBundles.emit(this.override);
        } else {
            this.onOverride.emit(this.override);
        }
    }

    clearOverride(): void {
        this.override = 0;

        if (this.isOverridingBundles) {
            this.onOverrideBundles.emit(this.override);
        } else {
            this.onOverride.emit(this.override);
        }
    }
}
