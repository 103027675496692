import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ISkid } from '../interfaces/skid';
import { IBoardGrade } from '../interfaces/board-grade';
import { IFinish } from '../interfaces/finish';
import { IMillShiftPersonnel } from '../interfaces/mill-shift-personnel';
import { IOrderDetailLine } from '../interfaces/order-detail-line';
import { ISalesOrder } from '../interfaces/sales-order';
import { IScale } from '../interfaces/scale';
import { IShipment } from '../interfaces/shipment';
import { ITrimSheetDetail } from '../interfaces/trim-sheet-detail';

export interface ISkidDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    orderDetailLines?: IOrderDetailLine[];
    trimSheetDetails?: ITrimSheetDetail[];
    scales?: IScale[];
    boardGrades?: IBoardGrade[];
    finishes?: IFinish[];
    millShiftPersonnels?: IMillShiftPersonnel[];
    shipments?: IShipment[];
}

// tslint:disable:object-literal-sort-keys
export class SkidDynamicControls {

    formGroup: string;
    salesOrders?: ISalesOrder[];
    orderDetailLines?: IOrderDetailLine[];
    trimSheetDetails?: ITrimSheetDetail[];
    scales?: IScale[];
    boardGrades?: IBoardGrade[];
    finishes?: IFinish[];
    millShiftPersonnels?: IMillShiftPersonnel[];
    shipments?: IShipment[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private skid?: ISkid, additionalParameters?: ISkidDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Skid';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.orderDetailLines = additionalParameters && additionalParameters.orderDetailLines || undefined;
        this.trimSheetDetails = additionalParameters && additionalParameters.trimSheetDetails || undefined;
        this.scales = additionalParameters && additionalParameters.scales || undefined;
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;
        this.finishes = additionalParameters && additionalParameters.finishes || undefined;
        this.millShiftPersonnels = additionalParameters && additionalParameters.millShiftPersonnels || undefined;
        this.shipments = additionalParameters && additionalParameters.shipments || undefined;

        this.Form = {
            ActualBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Actual Bundles',
                name: 'ActualBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.ActualBundles || null,
            }),
            ActualCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Actual Count',
                name: 'ActualCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.ActualCount || null,
            }),
            ActualWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Actual Weight',
                name: 'ActualWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.ActualWeight || null,
            }),
            AvgCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Avg Count',
                name: 'AvgCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.AvgCount || null,
            }),
            BacktenderBarcode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Backtender Barcode',
                name: 'BacktenderBarcode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.skid && this.skid.hasOwnProperty('BacktenderBarcode') && this.skid.BacktenderBarcode != null ? this.skid.BacktenderBarcode.toString() : '',
            }),
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.BoardGradeId || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.Caliper || null,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.Count || null,
            }),
            DateProcessed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Processed',
                name: 'DateProcessed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.DateProcessed || null,
            }),
            FinalBarcode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Final Barcode',
                name: 'FinalBarcode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.skid && this.skid.hasOwnProperty('FinalBarcode') && this.skid.FinalBarcode != null ? this.skid.FinalBarcode.toString() : '',
            }),
            FinishCustom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish Custom',
                name: 'FinishCustom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.skid && this.skid.hasOwnProperty('FinishCustom') && this.skid.FinishCustom != null ? this.skid.FinishCustom.toString() : '',
            }),
            FinishId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish',
                name: 'FinishId',
                options: this.finishes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.FinishId || null,
            }),
            Heavies: new DynamicField({
                formGroup: this.formGroup,
                label: 'Heavies',
                name: 'Heavies',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.Heavies || null,
            }),
            IsConverting: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Converting',
                name: 'IsConverting',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.hasOwnProperty('IsConverting') && this.skid.IsConverting != null ? this.skid.IsConverting : false,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.skid && this.skid.hasOwnProperty('Length') && this.skid.Length != null ? this.skid.Length.toString() : '',
            }),
            MillShiftPersonnelId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Shift Personnel',
                name: 'MillShiftPersonnelId',
                options: this.millShiftPersonnels,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.skid && this.skid.MillShiftPersonnelId || null,
            }),
            OrderDetailLineId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Detail Line',
                name: 'OrderDetailLineId',
                options: this.orderDetailLines,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.OrderDetailLineId || null,
            }),
            Piles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Piles',
                name: 'Piles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.Piles || null,
            }),
            Ply: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ply',
                name: 'Ply',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.Ply || null,
            }),
            ProcessedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processed By',
                name: 'ProcessedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.ProcessedBy || null,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.skid && this.skid.SalesOrderId || null,
            }),
            ScaleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scale',
                name: 'ScaleId',
                options: this.scales,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.skid && this.skid.ScaleId || null,
            }),
            Sequence: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sequence',
                name: 'Sequence',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(4) ],
                validators: { 'required': true, 'maxlength': 4 },
                value: this.skid && this.skid.hasOwnProperty('Sequence') && this.skid.Sequence != null ? this.skid.Sequence.toString() : '',
            }),
            ShipmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment',
                name: 'ShipmentId',
                options: this.shipments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.ShipmentId || null,
            }),
            ShipmentLoadDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment Load Date',
                name: 'ShipmentLoadDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.ShipmentLoadDate || null,
            }),
            SkidHeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skid Height',
                name: 'SkidHeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.SkidHeight || null,
            }),
            SkidNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skid Number',
                name: 'SkidNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.SkidNumber || null,
            }),
            TareWeightBottom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tare Weight Bottom',
                name: 'TareWeightBottom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.TareWeightBottom || null,
            }),
            TareWeightTop: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tare Weight Top',
                name: 'TareWeightTop',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.TareWeightTop || null,
            }),
            TargetCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Target Count',
                name: 'TargetCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.TargetCount || null,
            }),
            TargetWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Target Weight',
                name: 'TargetWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.skid && this.skid.TargetWeight || null,
            }),
            TrimSheetDetailId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trim Sheet Detail',
                name: 'TrimSheetDetailId',
                options: this.trimSheetDetails,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.skid && this.skid.TrimSheetDetailId || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.skid && this.skid.hasOwnProperty('Width') && this.skid.Width != null ? this.skid.Width.toString() : '',
            }),
        };

        this.View = {
            ActualBundles: new DynamicLabel({
                label: 'Actual Bundles',
                value: this.skid && this.skid.ActualBundles || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ActualCount: new DynamicLabel({
                label: 'Actual Count',
                value: this.skid && this.skid.ActualCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ActualWeight: new DynamicLabel({
                label: 'Actual Weight',
                value: this.skid && this.skid.ActualWeight || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            AvgCount: new DynamicLabel({
                label: 'Avg Count',
                value: this.skid && this.skid.AvgCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            BacktenderBarcode: new DynamicLabel({
                label: 'Backtender Barcode',
                value: this.skid && this.skid.hasOwnProperty('BacktenderBarcode') && this.skid.BacktenderBarcode != null ? this.skid.BacktenderBarcode.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BoardGradeId: new DynamicLabel({
                label: 'Board Grade',
                value: getMetaItemValue(this.boardGrades as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('BoardGradeId') ? this.skid.BoardGradeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Caliper: new DynamicLabel({
                label: 'Caliper',
                value: this.skid && this.skid.Caliper || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            }),
            Count: new DynamicLabel({
                label: 'Count',
                value: this.skid && this.skid.Count || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            DateProcessed: new DynamicLabel({
                label: 'Date Processed',
                value: this.skid && this.skid.DateProcessed || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            FinalBarcode: new DynamicLabel({
                label: 'Final Barcode',
                value: this.skid && this.skid.hasOwnProperty('FinalBarcode') && this.skid.FinalBarcode != null ? this.skid.FinalBarcode.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FinishCustom: new DynamicLabel({
                label: 'Finish Custom',
                value: this.skid && this.skid.hasOwnProperty('FinishCustom') && this.skid.FinishCustom != null ? this.skid.FinishCustom.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FinishId: new DynamicLabel({
                label: 'Finish',
                value: getMetaItemValue(this.finishes as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('FinishId') ? this.skid.FinishId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Heavies: new DynamicLabel({
                label: 'Heavies',
                value: this.skid && this.skid.Heavies || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            IsConverting: new DynamicLabel({
                label: 'Is Converting',
                value: this.skid && this.skid.hasOwnProperty('IsConverting') && this.skid.IsConverting != null ? this.skid.IsConverting : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Length: new DynamicLabel({
                label: 'Length',
                value: this.skid && this.skid.hasOwnProperty('Length') && this.skid.Length != null ? this.skid.Length.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MillShiftPersonnelId: new DynamicLabel({
                label: 'Mill Shift Personnel',
                value: getMetaItemValue(this.millShiftPersonnels as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('MillShiftPersonnelId') ? this.skid.MillShiftPersonnelId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OrderDetailLineId: new DynamicLabel({
                label: 'Order Detail Line',
                value: getMetaItemValue(this.orderDetailLines as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('OrderDetailLineId') ? this.skid.OrderDetailLineId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Piles: new DynamicLabel({
                label: 'Piles',
                value: this.skid && this.skid.Piles || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            Ply: new DynamicLabel({
                label: 'Ply',
                value: this.skid && this.skid.Ply || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ProcessedBy: new DynamicLabel({
                label: 'Processed By',
                value: this.skid && this.skid.ProcessedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SalesOrderId: new DynamicLabel({
                label: 'Sales Order',
                value: getMetaItemValue(this.salesOrders as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('SalesOrderId') ? this.skid.SalesOrderId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ScaleId: new DynamicLabel({
                label: 'Scale',
                value: getMetaItemValue(this.scales as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('ScaleId') ? this.skid.ScaleId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Sequence: new DynamicLabel({
                label: 'Sequence',
                value: this.skid && this.skid.hasOwnProperty('Sequence') && this.skid.Sequence != null ? this.skid.Sequence.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShipmentId: new DynamicLabel({
                label: 'Shipment',
                value: getMetaItemValue(this.shipments as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('ShipmentId') ? this.skid.ShipmentId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShipmentLoadDate: new DynamicLabel({
                label: 'Shipment Load Date',
                value: this.skid && this.skid.ShipmentLoadDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            SkidHeight: new DynamicLabel({
                label: 'Skid Height',
                value: this.skid && this.skid.SkidHeight || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            SkidNumber: new DynamicLabel({
                label: 'Skid Number',
                value: this.skid && this.skid.SkidNumber || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            TareWeightBottom: new DynamicLabel({
                label: 'Tare Weight Bottom',
                value: this.skid && this.skid.TareWeightBottom || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TareWeightTop: new DynamicLabel({
                label: 'Tare Weight Top',
                value: this.skid && this.skid.TareWeightTop || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TargetCount: new DynamicLabel({
                label: 'Target Count',
                value: this.skid && this.skid.TargetCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TargetWeight: new DynamicLabel({
                label: 'Target Weight',
                value: this.skid && this.skid.TargetWeight || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TrimSheetDetailId: new DynamicLabel({
                label: 'Trim Sheet Detail',
                value: getMetaItemValue(this.trimSheetDetails as unknown as IMetaItem[], this.skid && this.skid.hasOwnProperty('TrimSheetDetailId') ? this.skid.TrimSheetDetailId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Width: new DynamicLabel({
                label: 'Width',
                value: this.skid && this.skid.hasOwnProperty('Width') && this.skid.Width != null ? this.skid.Width.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
/* tslint:enable */
