import { OrderInfoDynamicControls } from '../form-controls/order-info.form-controls';
import { ICustomer } from '../interfaces/customer';
import { IOrderInfo } from '../interfaces/order-info';

export class OrderInfoDynamicControlsPartial extends OrderInfoDynamicControls {
    constructor(
        private orderInfoPartial?: IOrderInfo,
        private formGroupPartial = 'OrderInfo',
        private customersPartial?: ICustomer[],
    ) {
        super(orderInfoPartial, {
            customers: customersPartial,
            formGroup: formGroupPartial,
        });

        this.Form.CustomerShipVia.label = 'Shipped Via';
        this.Form.CustomerShipVia.labelHtml = '<label>Shipped Via</label>';
        this.Form.CustomerTerms.label = 'Customer Financial Terms';
        this.Form.CustomerTerms.labelHtml = '<label>Customer Financial Terms</label>';
        this.Form.IsCreditCardPurchase.label = 'Credit Card Purchase';
        this.Form.IsCreditCardPurchase.labelHtml = '<label>Credit Card Purchase</label>';
        this.Form.CustomerPurchaseOrderNumber.label = 'Customer Purchase Order #';
        this.Form.CustomerPurchaseOrderNumber.labelHtml = '<label>Customer Purchase Order #</label>';

        this.Form.RunWeekOf.labelHtml = '<label>When</label>';
        if (this.orderInfoPartial.Id === 0) {
            this.Form.RunWeekOf.value = 'Run Week Of';
        }
    }
}
