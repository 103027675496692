import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IContact } from '../interfaces/contact';
import { IContactType } from '../interfaces/contact-type';
import { IContactWithPhoneDetail } from '@model/interfaces/custom/contact-with-phone-detail';
import { IContactPhone } from '@model/interfaces/contact-phone';

export interface IContactWithPhoneDetailDynamicControlsParameters {
    formGroup?: string;
    types?: IContactType[];
    contactPhones?: IContactPhone[];
}

export class ContactWithPhoneDetailDynamicControls {

    formGroup: string;
    types: IContactType[];
    contactPhones?: IContactPhone[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private contactWithPhone?: IContactWithPhoneDetail, additionalParameters?: IContactWithPhoneDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ContactWithPhoneDetail';
        this.types = additionalParameters && additionalParameters.types || undefined;
        this.contactPhones = additionalParameters && additionalParameters.contactPhones || undefined;

        this.Form = {
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50), Validators.email ],
                validators: { 'maxlength': 50, 'email': true },
                value: this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Email') && this.contactWithPhone.Email !== null ? this.contactWithPhone.Email.toString() : '',
            }),
            Extension: new DynamicField({
                formGroup: this.formGroup,
                label: 'Extension (Phone)',
                name: 'Extension',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.getPrimaryExtension(),
                }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contactWithPhone && this.contactWithPhone.hasOwnProperty('FirstName') && this.contactWithPhone.FirstName !== null ? this.contactWithPhone.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contactWithPhone && this.contactWithPhone.hasOwnProperty('LastName') && this.contactWithPhone.LastName !== null ? this.contactWithPhone.LastName.toString() : '',
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Notes') && this.contactWithPhone.Notes !== null ? this.contactWithPhone.Notes.toString() : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone (Primary)',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Phone,
                    scale: null,
                }),
                validation: [  Validators.minLength(10) ],
                validators: { 'minlength': 10 },
                value: this.getPrimaryPhone(),
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Title') && this.contactWithPhone.Title !== null ? this.contactWithPhone.Title.toString() : '',
            }),
            TypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'TypeId',
                options: this.types,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.contactWithPhone && this.contactWithPhone.TypeId || null,
            }),

        };

        this.View = {
            Email: new DynamicLabel(
                'Email',
                this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Email') && this.contactWithPhone.Email !== null ? this.contactWithPhone.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Extension: new DynamicLabel(
                'Extension',
                this.getPrimaryExtension(),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.contactWithPhone && this.contactWithPhone.hasOwnProperty('FirstName') && this.contactWithPhone.FirstName !== null ? this.contactWithPhone.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.contactWithPhone && this.contactWithPhone.hasOwnProperty('LastName') && this.contactWithPhone.LastName !== null ? this.contactWithPhone.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Notes') && this.contactWithPhone.Notes !== null ? this.contactWithPhone.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Phone: new DynamicLabel(
                'Phone',
                this.getPrimaryPhone(),
                    new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Phone,
                    scale: null,
                }),
            ),
            Title: new DynamicLabel(
                'Title',
                this.contactWithPhone && this.contactWithPhone.hasOwnProperty('Title') && this.contactWithPhone.Title !== null ? this.contactWithPhone.Title.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TypeId: new DynamicLabel(
                'Type',
                getMetaItemValue(this.types, this.contactWithPhone && this.contactWithPhone.hasOwnProperty('TypeId') && this.contactWithPhone.TypeId !== null ? this.contactWithPhone.TypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }

    private getPrimaryPhone(): string {
        if (this.contactPhones && this.contactPhones.length > 0) {
            const primaryPhone = this.contactPhones.find((phone) => phone.IsPrimary);
            return primaryPhone ? primaryPhone.Phone : '';
        }
        return '';
    }

    private getPrimaryExtension(): string {
        if (this.contactPhones && this.contactPhones.length > 0) {
            const primaryPhone = this.contactPhones.find((phone) => phone.IsPrimary);
            return primaryPhone ? primaryPhone.Extension : '';
        }
        return '';
    }
}
