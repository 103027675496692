import { IOrderDetailLine } from '../interfaces/order-detail-line';
import { OrderDetailLineDynamicControls, IOrderDetailLineDynamicControlsParameters } from '../form-controls/order-detail-line.form-controls';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { IFractionsDynamicFieldOptions } from '@common/services/fractions.service';
import { RunnerDirectionTypes } from '@model/enums/runner-direction-types.enum';

export class OrderDetailLineDynamicControlsPartial extends OrderDetailLineDynamicControls {
    constructor(orderDetailLine?: IOrderDetailLine, additionalParameters?: IOrderDetailLineDynamicControlsParameters) {
        super(orderDetailLine, additionalParameters);

        this.Form.Length.component = FractionsDynamicFieldComponent;
        this.Form.Width.component = FractionsDynamicFieldComponent;
        this.Form.Height.component = FractionsDynamicFieldComponent;
        const heightOptions: IFractionsDynamicFieldOptions = { maxFactor: 4 };
        const lengthOptions: IFractionsDynamicFieldOptions = { maxFactor: 32 };
        const widthOptions: IFractionsDynamicFieldOptions = { maxFactor: 32 };
        (<DynamicField>this.Form.Height).customOptions = heightOptions;
        (<DynamicField>this.Form.Width).customOptions = widthOptions;
        (<DynamicField>this.Form.Length).customOptions = lengthOptions;

        this.Form.IsPaster.labelHtml = '<label>Paster</label>';
        this.Form.IsPaster.label = 'Paster';
        this.Form.IsConverting.label = 'Converting';
        this.Form.IsCutter.labelHtml = '<label>Cutter</label>';
        this.Form.IsCutter.label = 'Cutter';
        this.Form.BdlSk.labelHtml = '<label>Bdl/Sk</label>';
        this.Form.Quantity.labelHtml = '<label>Qty</label>';
        this.Form.Caliper.validation.push(Validators.max(0.999));
        this.Form.Caliper.validators.max = 0.999;
        this.Form.Basis.validation.push(Validators.max(99999.9));
        this.Form.Basis.validators.max = 99999.9;
        this.Form.Ply.validation.push(Validators.max(999));
        this.Form.Ply.validators.max = 999;
        this.Form.Count.validation.push(Validators.max(999999.9));
        this.Form.Count.validators.max = 999999.9;
        this.Form.NumberOfSkids.label = 'Skids';
        this.Form.NumberOfSkids.labelHtml = '<label>Skids</label>';
        this.Form.RuleNumber.label = 'Rule #';
        this.Form.RuleNumber.labelHtml = '<label>Rule #</label>';

        this.Form.Piles = new DynamicField({
            formGroup: this.formGroup,
            label: 'Piles',
            name: 'Piles',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
            }),
            value: orderDetailLine && orderDetailLine.hasOwnProperty('Piles') && orderDetailLine.Piles !== null ? orderDetailLine.Piles : 1,
        });

        this.Form.PileStainWhiteSide = new DynamicField({
            formGroup: this.formGroup,
            label: 'Pile Stain White Side',
            name: 'PileStainWhiteSide',
            options: [
                { Id: null, Name: 'N/A' },
                { Id: true, Name: 'Pile Up' },
                { Id: false, Name: 'Pile Down' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.OldStyleRadioButtonList,
            }),
            value: orderDetailLine && orderDetailLine.hasOwnProperty('PileStainWhiteSide') ? orderDetailLine.PileStainWhiteSide : null,
        });
        this.Form.PileStainWhiteSide.hideLabel = true;

        this.Form.RunnerDirectionTypeId  = new DynamicField({
            formGroup: this.formGroup,
            label: 'Runner Direction Type',
            name: 'RunnerDirectionTypeId',
            options: [
                { Id: RunnerDirectionTypes.ShortWay, Name: 'Short Way' },
                { Id: RunnerDirectionTypes.LongWay, Name: 'Long Way' },
                { Id: RunnerDirectionTypes.Junk, Name: 'Junk' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.OldStyleRadioButtonList,
            }),
            value: orderDetailLine && orderDetailLine.hasOwnProperty('RunnerDirectionTypeId') ? orderDetailLine.RunnerDirectionTypeId : null,
        });
    }
}
