import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable, forkJoin, Subscription, of, throwError } from 'rxjs';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { common } from '@mt-ng2/common-functions';
import { format } from '@mt-ng2/format-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PrintService } from '@common/services/print.service';

import { CustomerService } from '../../customers/customer.service';
import { SalesOrderService } from '../sales-order.service';
import { OrderStatusService } from '../order-status.service';
import { BoardGradeService } from '../board-grade.service';
import { LiningService } from '../lining.service';
import { FinishService } from '../finish.service';
import { CustomerShipOnTypeService } from '../../customers/customershipontype.service';
import { GeneralLedgerService } from '../general-ledger.service';
import { OrderPriceTypeService } from '../order-pricing-types.service';
import { UnitOfMeasuresService } from '../unit-of-measures.service';
import { OrderCustomerSpecsService } from '../order-customer-specs.service';
import { OrderInfoService } from '../order-info.service';

import { ICustomerPartial } from '@model/partials/customer';
import { IOrderInfo } from '@model/interfaces/order-info';
import { OrderInfoDynamicControlsPartial } from '@model/partials/manufacturing-order-info.form-controls';
import { IManufacturingOrderDetail } from '@model/interfaces/manufacturing-order-detail';
import { ManufacturingOrderDetailDynamicControlsPartial } from '@model/partials/manufacturing-order-detail.form-controls';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { IOrderCustomerSpecification } from '@model/interfaces/order-customer-specification';
import { Router, ActivatedRoute } from '@angular/router';
import { ClaimsService, ClaimValues, AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { SalesOrderDynamicControlsPartial } from '@model/partials/sales-order.form-controls';
import { FractionsService } from '@common/services/fractions.service';
import { OrderStatusIds, OrderStatusNames } from '@model/OrderStatuses';
import { OrderTypeIds, OrderTypeNames } from '@model/OrderTypes';

import { PrintFormsOptions } from '@model/dto/PrintFormsOptions';
import { UserService } from '../../users/user.service';
import { IUser } from '@model/interfaces/user';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';
import { IFormattedSalesOrder } from '@model/interfaces/custom/formatted-sales-order';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { IBoardGrade } from '@model/interfaces/board-grade';
import { ILining } from '@model/interfaces/lining';
import { IFinish } from '@model/interfaces/finish';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { CustomerCreditStatuses } from '@model/enums/customer-credit-statuses.enum';
import { ICreditAuthorizationStatus } from '@model/interfaces/credit-authorization-status';
import { CreditAuthStatusService } from '../credit-auth-status.service';
import { CreditAuthorizationStatuses } from '@model/enums/credit-authorization-statuses.enum';
import { IOrderDetailLine } from '@model/interfaces/order-detail-line';
import { CustomerOrderPriceInfoService } from '../customerorderpriceinfo.service';
import { OrderCustomerSpecificationDynamicControlsPartial } from '@model/partials/order-customer-specification.form-controls';
import { IOrderCustomerSpecificationPartial } from '@model/partials/order-customer-specification';
import { millComplaintPathSlugs } from '@routes/mill-complaint-paths';
import { RunnerDirectionTypeIds } from '@model/RunnerDirectionTypes';
import { ICommissionSalesPerson } from '@model/interfaces/commission-sales-person';
import { CommissionSalesPersonService } from '../commission-sales-person.service';
import { UnitOfMeasures } from '@model/enums/unit-of-measures.enum';
import { IBandingInstruction } from '@model/interfaces/banding-instruction';
import { BandingInstructionService } from '../banding-instruction.service';

const DRESS_STOCK_BOARD_GRADES = ['STOCK', 'STOCK CHIP', 'STOCK STAIN', 'STOCK WHITE', 'DRESS STOCK'];

@Component({
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [
                // :leave is alias to '* => void'
                animate(200, style({ opacity: 0 })),
            ]),
        ]),
    ],
    selector: 'manufacturing-order-add',
    styles: [
        `
            table {
                margin-bottom: 0;
            }
            td {
                border: none !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            td:first-child {
                padding-left: 0 !important;
            }
            td:last-child {
                padding-right: 0 !important;
            }
            .horizontalSpacing {
                min-width: 94px !important;
            }
            tr {
                cursor: default !important;
            }
            .custom-prop {
                margin-top: -10px;
            }
            hr {
                margin-top: 7px !important;
                margin-bottom: 7px !important;
            }
            .form-control {
                padding: 4px 8px !important;
            }
            .space-for-validation {
                margin-bottom: 11px !important;
            }
            .col-md-12 {
                padding-right: 10px !important;
                padding-left: 10px !important;
            }
            .dynamic-field-container {
                display: inline-block;
                margin-right: 10px;
            }
        `,
    ],
    templateUrl: './manufacturing-order-add.component.html',
})
export class ManufacturingOrderAddComponent implements OnInit, OnDestroy {
    // inputs
    @Input('customerId') customerId: number;
    @Input('customerShippingAddressId') customerShippingAddressId: number;

    printModal: IModalWrapperApi;
    printModalOptions: IModalOptions = {
        confirmButtonText: 'Print',
        focusCancel: true,
        showCancelButton: true,
    };

    selectedCreditAuthStatus: number;
    creditAuthModal: IModalWrapperApi;
    creditAuthModalOptions: IModalOptions = {
        confirmButtonText: 'Authorize',
        showCancelButton: true,
        showConfirmButton: true,
    };

    // abstract controls
    abstractOrderControls: any;
    abstractOrderInfoControls: any;
    abstractOrderDetailControls: any;
    abstractOrderPricingControls: any;
    abstractOrderCustomerSpecificationControls: any;

    printFormsOptions: PrintFormsOptions;

    customer: ICustomerPartial;
    customerOrderSpecification: IOrderCustomerSpecificationPartial;
    customerSalesPerson: IUser;
    salesOrder: IFormattedSalesOrder;
    customerShippingAddress: ICustomerShippingAddress;
    addressString: string;
    orderForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    converting = false;
    canSetOrderStatus = false;
    salesOrderId: number;
    salesOrderPayload: any;
    salesPersonItems: IUser[];
    formattedCaliper: any;
    formattedBasis: any;
    currentUser: ILoggedIn;
    isCustomLining: boolean;
    isCustomFinish: boolean;
    canPrintOrders: boolean;
    canAccessMillComplaints: boolean;
    canEditOrderAnyStatus: boolean;
    isEditing: boolean;
    showCustomerInfo = true;
    isConsignment: any;
    canArchive: any;
    isDressStock = true;
    isCommission = false;
    customerIsCreditHold: boolean;
    canProvideCreditAuthorization: boolean;
    priceBreakdownThreshold = 0;

    generalLedgerItems: IGeneralLedgerCode[];
    boardGradeItems: IBoardGrade[];
    liningItems: ILining[];
    finishItems: IFinish[];
    OrderStatusIds = OrderStatusIds;
    customerShippingAddresses: ICustomerShippingAddress[];
    creditAuthStatuses: ICreditAuthorizationStatus[];
    commissionSalesPersons: ICommissionSalesPerson[];

    bandingInstructions: IBandingInstruction[];

    formSubscriptions = new Subscription();

    get saveIfAvailable$(): Observable<void> {
        return this.canSave ? this.saveCall() : of(null);
    }

    get canSave(): boolean {
        return !this.formIsLocked;
    }

    get formIsLocked(): boolean {
        return this.salesOrderIsNotOpen && !this.isEditing;
    }

    get salesOrderIsNotOpen(): boolean {
        return (
            this.salesOrder &&
            (this.salesOrder.OrderStatusId === OrderStatusIds.PendingReview ||
                this.salesOrder.OrderStatusId === OrderStatusIds.Approved ||
                this.salesOrder.OrderStatusId === OrderStatusIds.CreditHold)
        );
    }

    get specsDetailsIsLocked(): boolean {
        let orderStatusId = this.salesOrder ? this.salesOrder.OrderStatusId : OrderStatusIds.Open;
        if (orderStatusId === OrderStatusIds.Open || orderStatusId === OrderStatusIds.PendingReview) {
            return false;
        }
        return this.formIsLocked;
    }

    get authorized(): boolean {
        return (
            this.salesOrder.CreditAuthStatusId === CreditAuthorizationStatuses.Order ||
            this.salesOrder.CreditAuthStatusId === CreditAuthorizationStatuses.Both
        );
    }

    emptyOrderInfo: IOrderInfo;
    private emptyOrderDetail: IManufacturingOrderDetail;
    private emptyOrderCustomerSpecifications: IOrderCustomerSpecification;

    constructor(
        private customerService: CustomerService,
        private orderCustomerSpecsService: OrderCustomerSpecsService,
        private orderStatusService: OrderStatusService,
        private notificationsService: NotificationsService,
        private boardGradeService: BoardGradeService,
        private liningService: LiningService,
        private finishService: FinishService,
        private shipOnTypeService: CustomerShipOnTypeService,
        private generalLedgerService: GeneralLedgerService,
        private orderPriceTypeService: OrderPriceTypeService,
        private unitOfMeasureService: UnitOfMeasuresService,
        private salesOrderService: SalesOrderService,
        private orderInfoService: OrderInfoService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private fractionsService: FractionsService,
        private userService: UserService,
        private modalService: ModalService,
        private creditAuthStatusService: CreditAuthStatusService,
        private customerOrderPriceInfoService: CustomerOrderPriceInfoService,
        private commissionSalesPersonService: CommissionSalesPersonService,
        private bandingInstructionService: BandingInstructionService,
    ) {}

    ngOnInit(): void {
        this.emptyOrderInfo = this.orderInfoService.getEmptyOrderInfo();
        this.emptyOrderDetail = this.salesOrderService.getEmptyOrderDetail();
        this.currentUser = this.authService.currentUser.getValue();
        this.canPrintOrders = this.claimsService.hasClaim(ClaimTypes.Orders_CanPrintOrders, [ClaimValues.FullAccess]);
        this.canAccessMillComplaints = this.claimsService.hasClaim(ClaimTypes.MillComplaints, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.canArchive = this.claimsService.hasClaim(ClaimTypes.Orders_CanArchiveOrders, [ClaimValues.FullAccess]);
        this.canEditOrderAnyStatus = this.claimsService.hasClaim(ClaimTypes.Orders_CanEditOrdersAnyStatus, [ClaimValues.FullAccess]);
        this.canProvideCreditAuthorization = this.claimsService.hasClaim(ClaimTypes.Orders_CanProvideCreditAuthorization, [ClaimValues.FullAccess]);
        this.printFormsOptions = new PrintFormsOptions();

        this.emptyOrderCustomerSpecifications = this.orderCustomerSpecsService.getEmptyOrderCustomerSpecifications();
        const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
        if (orderId) {
            this.salesOrderId = +orderId;
            if (this.claimsService.hasClaim(ClaimTypes.SetOrderStatus, [ClaimValues.FullAccess])) {
                this.canSetOrderStatus = true;
            }
        }

        forkJoin({
            bandingInstructions: this.bandingInstructionService.getItems(),
            boardGradeItems: this.boardGradeService.getItems(),
            commissionSalesPersons: this.commissionSalesPersonService.getItems(),
            creditAuthStatuses: this.creditAuthStatusService.getItems(),
            finishItems: this.finishService.getItems(),
            generalLedgerItems: this.generalLedgerService.getItems(),
            liningItems: this.liningService.getItems(),
            orderPriceTypes: this.orderPriceTypeService.getItems(),
            orderStatuses: this.orderStatusService.getItems(),
            salesOrderOrCustomer: this.salesOrderId ? this.getSalesOrderById(this.salesOrderId) : this.getCustomerById(this.customerId),
            shipOnTypes: this.shipOnTypeService.getItems(),
            unitsOfMeasure: this.unitOfMeasureService.getItems(),
        }).subscribe((answer) => {
            this.commissionSalesPersons = answer.commissionSalesPersons;
            this.salesOrderId ? this.mapAvailableSalesPeople(this.salesOrderId) : this.mapAvailableSalesPeople(0);
            this.generalLedgerItems = answer.generalLedgerItems;
            this.boardGradeItems = answer.boardGradeItems;
            this.liningItems = answer.liningItems;
            this.finishItems = answer.finishItems;
            this.creditAuthStatuses = answer.creditAuthStatuses;
            this.bandingInstructions = answer.bandingInstructions;
            this.bandingInstructions.unshift({ Id: null, Name: ''}); // First item needs to be 'NULL'
        });
    }

    setDefaultPrintFormOptions(): void {
        const orderStatusId = this.orderForm.controls.OrderStatusId.value;
        const isConverting = this.orderForm.controls.ManufacturingOrderDetail.get('IsConverting')?.value;
        const isLiningDept = this.orderForm.controls.ManufacturingOrderDetail.get('IsLiningDept')?.value;

        if (orderStatusId  === OrderStatusIds.PendingReview || orderStatusId === OrderStatusIds.Approved) {

            this.printFormsOptions.AcknowledgementCopy = true;
            this.printFormsOptions.PapermakersCopy = true;
            this.printFormsOptions.ShippersCopy = true;

            if (orderStatusId === OrderStatusIds.Approved) {
                if (isConverting) {
                    this.printFormsOptions.PastingCuttingCopy = true;
                }
                if (isLiningDept) {
                    this.printFormsOptions.LiningCopy = true;
                }
            }
        }
    }

    getCustomerOrderSpecificationById(): void {
        let orderSpecification = null;
        if (this.salesOrder && this.salesOrder.OrderInfo && this.salesOrder.OrderInfo.CustomerOrderSpecificationId) {
            orderSpecification = this.salesOrder.OrderInfo.OrderCustomerSpecification;
        } else if (this.customer && this.customer.OrderCustomerSpecificationId) {
            orderSpecification = this.customer.OrderCustomerSpecification;
        }

        this.customerOrderSpecification = orderSpecification;
    }

    ngOnDestroy(): void {
        this.formSubscriptions.unsubscribe();
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    mapAvailableSalesPeople(orderId: number): void {
        this.userService.getAvailableSalesPersonsForOrder(orderId).subscribe((response) => {
            this.salesPersonItems = response.body;
            if (!this.salesOrderId) {
                this.customerSalesPerson = this.salesPersonItems.find((sp) => {
                    return this.currentUser.Id === sp.Id;
                });
            } else {
                this.customerSalesPerson = this.salesPersonItems.find((sp) => {
                    return this.salesOrder.OrderInfo.CustomerSalesPersonId === sp.Id;
                });
            }
            this.createForm();
            this.setDefaultPrintFormOptions();
        });
    }

    getCustomerById(customerId: number): Observable<ICustomerPartial> {
        return this.customerService.getById(customerId).pipe(
            tap((answer) => {
                this.customer = answer;
                const primaryCustomerAddress = this.customer.CustomerAddresses.find((address) => address.IsPrimary).Address;
                this.addressString = primaryCustomerAddress ? format.addressVertical(primaryCustomerAddress) : '';
                this.customerShippingAddresses = this.customer.CustomerShippingAddresses;
                this.customerShippingAddress = this.customer.CustomerShippingAddresses.find(
                    (shippingAddress) => shippingAddress.Id === this.customerShippingAddressId,
                );
                this.emptyOrderInfo.CustomerTerms = this.customer.CustomerPaymentTerm.Name;
                this.emptyOrderDetail.ShipOnTypeId = this.customer.ShipOnTypeId;
                this.emptyOrderDetail.IsShortWay = this.customer.RunnerDirectionTypeId === RunnerDirectionTypeIds.ShortWay;
            }),
        );
    }

    getSalesOrderById(salesOrderId: number): Observable<ISalesOrder> {
        return this.salesOrderService.getById(salesOrderId).pipe(
            tap((answer) => {
                this.salesOrder = answer as IFormattedSalesOrder;
                this.customer = this.salesOrder.OrderInfo.Customer;
                this.customerShippingAddresses = this.customer.CustomerShippingAddresses;
                this.customerShippingAddressId = this.salesOrder.OrderInfo.CustomerShippingAddressId;
                const primaryCustomerAddress = this.customer.CustomerAddresses.find((address) => address.IsPrimary).Address;
                this.addressString = primaryCustomerAddress ? format.addressVertical(primaryCustomerAddress) : '';
                this.customerIsCreditHold = this.customer.CreditStatusId === CustomerCreditStatuses.CreditHold;

                this.isCustomLining = !!this.salesOrder.ManufacturingOrderDetail.LiningCustom;
                this.isCustomFinish = !!this.salesOrder.ManufacturingOrderDetail.FinishCustom;
                this.isConsignment = this.salesOrder.ManufacturingOrderDetail.IsConsignment;
                this.getCustomerOrderSpecificationById();
            }),
        );
    }

    createForm(): void {
        this.getControls();

        this.orderForm = this.assignFormGroups();
        this.addFormSubscriptions();
        // Default to the customer's shipping preference
        if (this.salesOrder && this.salesOrder.OrderInfo) {
            this.abstractOrderInfoControls.CustomerShipVia.value = this.salesOrder.OrderInfo.CustomerShipVia;
        } else {
            this.abstractOrderInfoControls.CustomerShipVia.value = this.customer.ShipVia;
        }
        if (this.salesOrder && this.salesOrder.OrderDetailLines.length) {
            this.converting = true;
        }
        if (this.salesOrder && this.salesOrder.ManufacturingOrderDetail.Caliper) {
            this.formattedCaliper = parseFloat(this.salesOrder.ManufacturingOrderDetail.Caliper.toString()).toFixed(3);
        }
        if (this.salesOrder && this.salesOrder.ManufacturingOrderDetail.Basis) {
            this.formattedBasis = parseFloat(this.salesOrder.ManufacturingOrderDetail.Basis.toString()).toFixed(1);
        }
        this.formCreated = true;
        this.isEditing = !this.salesOrderIsNotOpen;
        this.cdr.detectChanges();
        if (!this.salesOrderId) {
            // Default piles to 1
            (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.Piles.setValue(1);
            // Default Ship on type to customer setting
            (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.ShipOnTypeId.setValue(this.customer.ShipOnTypeId);
        }
        this.setFieldVisibility();

        (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.BoardGradeId.mtFocus();
    }

    calculateBundlesPerSkid(): void {
        // Timeout ensures form values are set before checking them
        setTimeout(() => {
            if (this.orderForm.value.ManufacturingOrderDetail.Quantity && this.orderForm.value.ManufacturingOrderDetail.NumberOfSkids && this.orderForm.value.ManufacturingOrderDetail.Count) {
                const unitOfMeasure = this.orderForm.value.ManufacturingOrderDetail.UnitOfMeasureId;
                let qty = unitOfMeasure === UnitOfMeasures.P
                            ? this.orderForm.value.ManufacturingOrderDetail.Quantity / this.orderForm.value.ManufacturingOrderDetail.Count
                            : this.orderForm.value.ManufacturingOrderDetail.Quantity;

                let bdlPerSkid = qty / this.orderForm.value.ManufacturingOrderDetail.NumberOfSkids;
                bdlPerSkid = this.fractionsService.roundToNearestFraction(bdlPerSkid, null, 10);
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.BdlSk.setValue(bdlPerSkid);
                // divide quantity by # of skids, round to the nearest tenth, and reassign the value to the control
            }
        });
    }

    calculateHeight(): void {
        // Timeout ensures form values are set before checking them
        setTimeout(() => {
            if (
                this.orderForm.value.ManufacturingOrderDetail.Quantity &&
                this.formattedCaliper &&
                this.orderForm.value.ManufacturingOrderDetail.Count &&
                this.orderForm.value.ManufacturingOrderDetail.NumberOfSkids
            ) {
                let height: any =
                    (this.orderForm.value.ManufacturingOrderDetail.Quantity *
                        this.formattedCaliper *
                        this.orderForm.value.ManufacturingOrderDetail.Count) /
                    this.orderForm.value.ManufacturingOrderDetail.Piles /
                    this.orderForm.value.ManufacturingOrderDetail.NumberOfSkids;
                height = height === Infinity ? '' : height;
                height = this.fractionsService.roundToNearestFraction(height, 4);
                height = this.fractionsService.FormatAsFraction(height.toString());
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.Height.setValue(height);
                // multiple quantity, caliper, and count, then divide by # of piles.
                // round to the nearest quarter inch, format the decimal as a fraction, and reassign the value to the control
            }
        });
    }

    calculateRuleNumber(): void {
        // Timeout ensures form values are set before checking them
        setTimeout(() => {
            if (this.orderForm.value.ManufacturingOrderDetail.Count && this.formattedCaliper) {
                let rule: any = Math.round(60 / this.orderForm.value.ManufacturingOrderDetail.Count / (this.formattedCaliper * 1));
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.RuleNumber.setValue(rule);
            }
        });
    }

    getControls(): void {
        let isCreate = this.salesOrder === undefined;
        this.abstractOrderControls = new SalesOrderDynamicControlsPartial(
            isCreate ? null : this.salesOrder,
            'SalesOrder',
            null,
            null,
            this.orderStatusService.items,
            null,
        ).Form;
        this.abstractOrderInfoControls = new OrderInfoDynamicControlsPartial(
            isCreate ? this.emptyOrderInfo : this.salesOrder.OrderInfo,
            this.salesPersonItems,
            this.orderPriceTypeService.items,
            [{ Id: null, FirstName: '', LastName: '' } as any].concat(this.commissionSalesPersons),
        ).Form;
        this.abstractOrderDetailControls = new ManufacturingOrderDetailDynamicControlsPartial(
            isCreate ? this.emptyOrderDetail : this.salesOrder.ManufacturingOrderDetail,
            [{ Id: null, Name: 'None' } as IBoardGrade].concat(this.boardGradeItems),
            [{ Id: null, Name: 'None' }].concat(this.liningItems),
            null,
            null,
            [{ Id: null, Name: 'None' }].concat(this.finishItems),
            this.shipOnTypeService.items,
            this.generalLedgerItems
                .sort((gl1, gl2) => gl1.Code.localeCompare(gl2.Code, undefined, { numeric: true }))
                .map((gl) => {
                    return { ...gl, Name: `${gl.Code} - ${gl.Name}` };
                }),
            this.unitOfMeasureService.items,
        ).Form;

        this.abstractOrderCustomerSpecificationControls = new OrderCustomerSpecificationDynamicControlsPartial(
            this.customerOrderSpecification ? this.customerOrderSpecification : this.orderCustomerSpecsService.getEmptyOrderCustomerSpecifications(),
            { bandingInstructions: this.bandingInstructions },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ManufacturingOrderDetail: this.fb.group({}),
            OrderCustomerSpecification: this.fb.group({}),
            OrderDetailLines: this.fb.array([]),
            OrderInfo: this.fb.group({}),
            OrderPricing: this.fb.group({}),
        });
    }

    onDressStockChange(isDressStock: boolean): void {
        this.isDressStock = isDressStock;
        (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.Count.mtSetRequired(!this.isDressStock);
    }

    save(): void {
        this.setDefaultPrintFormOptions();
        this.saveCall().subscribe();
    }

    saveCall(): Observable<void> {
        let observable;

        if (!this.isDressStock && !this.formattedCaliper) {
            observable = throwError(new Error('Caliper is required on non Dress Stock Orders'));
        } else if (this.orderForm.invalid) {
            observable = throwError(new Error('Order Save Failed'));
        } else {
            let orderFormValue = this.orderForm.getRawValue();
            this.salesOrderPayload = orderFormValue;
            // The Customer Info may be hidden, so explicitly assign form values
            this.salesOrderPayload.ManufacturingOrderDetail.IsConsignment = this.isConsignment;
            let detailsAreValid = true; // TODO LAS: Why does the FormArray value/validation not sync up with the groups within the array?
            const orderDetailLines = <FormArray>this.orderForm.controls.OrderDetailLines;
            this.salesOrderPayload.OrderDetailLines = [];
            if (this.converting) {
                orderDetailLines.controls.forEach((orderDetailLine: FormGroup) => {
                    if (orderDetailLine.invalid) {
                        detailsAreValid = false;
                        return;
                    }
                    this.salesOrderPayload.OrderDetailLines.push(orderDetailLine.getRawValue());
                });
            }
            if (!detailsAreValid) {
                observable = throwError(new Error('Order Save Failed'));
            } else {
                this.salesOrderPayload.OrderInfo.Customer = null;
                this.salesOrderPayload.OrderInfo.CustomerSalesPerson = null;

                if (this.isCustomLining) {
                    this.salesOrderPayload.ManufacturingOrderDetail.Lining = null;
                    this.salesOrderPayload.ManufacturingOrderDetail.LiningId = null;
                } else {
                    this.salesOrderPayload.ManufacturingOrderDetail.LiningCustom = null;
                }

                if (this.isCustomFinish) {
                    this.salesOrderPayload.ManufacturingOrderDetail.Finish = null;
                    this.salesOrderPayload.ManufacturingOrderDetail.FinishId = null;
                } else {
                    this.salesOrderPayload.ManufacturingOrderDetail.FinishCustom = null;
                }

                if (!this.isCommission) {
                    this.salesOrderPayload.OrderInfo.CommissionSalesPersonId = null;
                }

                if (this.salesOrder) {
                    observable = this.updateSalesOrder();
                } else {
                    observable = this.createSalesOrder();
                }
            }
        }

        return observable.pipe(
            tap({
                error: (error) => {
                    common.markAllFormFieldsAsTouched(this.orderForm);
                    common.markAllFormFieldsAsTouched(this.orderForm.controls.OrderDetailLines as FormArray);
                    this.notificationsService.error(error.message);
                },
                next: () => {
                    this.notificationsService.success('Order Saved Successfully');
                },
            }),
            switchMap(() => {
                let val: IOrderCustomerSpecificationPartial = this.orderForm.value.OrderCustomerSpecification;

                return this.orderCustomerSpecsService.updateOrderCustomerSpecs(this.salesOrderId, val);
            }),
            finalize(() => {
                this.isEditing = !this.salesOrderIsNotOpen;
                this.enableDoubleClick();
                this.setFieldVisibility();
            }),
        );
    }

    updateSalesOrder(): Observable<void> {
        this.salesOrder.ManufacturingOrderDetail.ShipOnType = null;
        this.salesOrder.ManufacturingOrderDetail.ConvertingPalletInfo = null;
        this.salesOrder.ManufacturingOrderDetail.FinalPalletInfo = null;
        this.salesOrder.OrderInfo.OrderPriceType = null;
        this.salesOrder.OrderInfo.CustomerShippingAddressId = this.customerShippingAddressId;
        this.salesOrder.ManufacturingOrderDetail.Caliper = this.formattedCaliper != null ? this.formattedCaliper * 1 : null;
        this.salesOrder.ManufacturingOrderDetail.Basis = this.formattedBasis * 1;
        Object.assign(this.salesOrder.ManufacturingOrderDetail, this.salesOrderPayload.ManufacturingOrderDetail);
        Object.assign(this.salesOrder.OrderInfo, this.salesOrderPayload.OrderInfo);
        this.salesOrder.OrderDetailLines = this.salesOrderPayload.OrderDetailLines;
        this.salesOrder.OrderStatusId = this.salesOrderPayload.OrderStatusId;
        this.salesOrder.OrderStatus = null;
        this.salesOrder.Skids = null;
        this.salesOrder.TrimSheetDetails = null;
        this.salesOrder.OrderDetailLines.forEach((odl) => odl.ConvertingSkids = null);

        return this.salesOrderService.updateWithForeignKeys(this.salesOrder).pipe(
            switchMap((salesOrderId: number) => (this.converting ? this.salesOrderService.getOrderDetailLines(salesOrderId) : of([]))),
            tap((orderDetailLines: IOrderDetailLine[]) => {
                if (this.converting) {
                    this.salesOrder.OrderDetailLines = orderDetailLines;
                }
            }),
        );
    }

    setFieldVisibility(): void {
        if (this.formIsLocked) {
            this.orderForm.disable();
        } else {
            this.orderForm.enable();
        }

        this.toggleOrderStatusField(this.canSetOrderStatus);
    }

    toggleOrderStatusField(enabled: boolean): void {
        let field = this.orderForm.controls.OrderStatusId;
        if (enabled && !(this.salesOrder.OrderStatusId === OrderStatusIds.Approved)) {
            field?.enable();
        } else {
            field?.disable();
        }
    }

    createSalesOrder(): Observable<number> {
        this.salesOrderPayload.OrderInfo.OrderTakenBy = this.currentUser.Id;
        this.salesOrderPayload.OrderInfo.CustomerSalesPersonId = this.customerSalesPerson.Id;
        this.salesOrderPayload.OrderInfo.CustomerId = this.customerId;
        this.salesOrderPayload.OrderInfo.CustomerShippingAddressId = this.customerShippingAddressId;
        this.salesOrderPayload.OrderInfo.CustomerOrderSpecificationId = null;
        this.salesOrderPayload.OrderInfo.OrderCustomerSpecification = null;
        this.salesOrderPayload.ManufacturingOrderDetail.Caliper = this.formattedCaliper != null ? this.formattedCaliper * 1 : null;
        this.salesOrderPayload.ManufacturingOrderDetail.Basis = this.formattedBasis * 1;
        this.salesOrderPayload.OrderStatus = {
            Id: OrderStatusIds.Open,
            Name: OrderStatusNames.Open,
        };
        this.salesOrderPayload.OrderStatusId = 1;
        this.salesOrderPayload.OrderType = {
            Id: OrderTypeIds.Manufacturing,
            Name: OrderTypeNames.Manufacturing,
        };
        this.salesOrderPayload.OrderTypeId = 1;
        this.salesOrderPayload.OrderInfoId = 0;
        this.salesOrderPayload.ManufacturingOrderDetailId = 0;
        return this.salesOrderService.create(this.salesOrderPayload).pipe(
            tap((newId) => {
                this.salesOrderId = newId as number;
                this.router.navigate(['salesorders-manufacturing', newId]);
            }),
        );
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    toggleConverting(): void {
        if (this.converting) {
            // if converting was deselected, clear the array
            let orderDetailArray = <FormArray>this.orderForm.get('OrderDetailLines');
            orderDetailArray.controls = [];
        }
        this.converting = !this.converting;
    }

    toggleCustomerInfo(): void {
        this.showCustomerInfo = !this.showCustomerInfo;
    }

    toggleCustomLining(): void {
        this.isCustomLining = !this.isCustomLining;
        this.setLiningFieldFocus();
    }

    setLiningFieldFocus(): void {
        setTimeout(() => {
            if (this.isCustomLining) {
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningCustom.mtFocus();
            } else {
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningId.mtFocus();
            }
        }, 0);
    }

    toggleCustomFinish(): void {
        this.isCustomFinish = !this.isCustomFinish;
        this.setFinishFieldFocus();
    }

    setFinishFieldFocus(): void {
        setTimeout(() => {
            if (this.isCustomFinish) {
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.FinishCustom.mtFocus();
            } else {
                (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.FinishId.mtFocus();
            }
        }, 0);
    }

    navigateToOrderCustomerSpecs(): void {
        this.saveIfAvailable$.subscribe(() => {
            this.router.navigate(['specs-info', this.salesOrder.OrderInfoId || 0], { relativeTo: this.activatedRoute });
        });
    }

    navigateToPalletInfo(): void {
        this.saveIfAvailable$.subscribe(() => {
            this.router.navigate(['pallet-info', this.salesOrder.ManufacturingOrderDetailId || 0], { relativeTo: this.activatedRoute });
        });
    }

    navigateToShippingStatus(): void {
        this.saveIfAvailable$.subscribe(() => {
            this.router.navigate(['shipping-status'], { relativeTo: this.activatedRoute });
        });
    }

    printForms(): void {
        if (this.salesOrder.OrderStatusId === OrderStatusIds.CreditHold) {
            this.printFormsOptions.IsOnCreditHold = true;
        }

        this.saveIfAvailable$
            .pipe(
                switchMap(() => this.salesOrderService.printForms(this.salesOrderId, this.printFormsOptions)),

                finalize(() => (this.printFormsOptions = new PrintFormsOptions())), // clear selections
            )
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
        this.printModal.close();
    }

    confirmPrintAndLock(): void {
        const messageText = this.isMissingPriceBreakdownInformation()
            ? 'The Price Breakdown has no information. Do you want to continue printing and locking this order?'
            : 'Are you sure you want to print and lock this order?';

        this.modalService
            .showModal({
                focusCancel: false,
                focusConfirm: false,
                showCancelButton: true,
                showCloseButton: true,
                text: messageText,
                title: 'Confirm',
                type: 'warning',
            })
            .subscribe((answer) => {
                if (answer.value) {
                    this.printAndLock();
                }
            });
    }

    isMissingPriceBreakdownInformation(): boolean {
        let priceInfo = this.salesOrder?.OrderInfo?.CustomerOrderPriceInfo;
        let pricing = 0;

        if (priceInfo) {
            pricing = Object.keys(priceInfo).reduce((sum, k) => {
                let fieldValue = priceInfo[k] ?? 0;
                if (
                    typeof fieldValue === 'string' ||
                    k === 'OrderInfoes' ||
                    k === 'PricingChargePerTypeId' ||
                    k === 'Id' ||
                    k === 'EnergySurchargePerTonRate' ||
                    k === 'PricingChargePerTypeId' ||
                    k === 'BasisWeight'
                ) {
                    return sum;
                } else {
                    return sum + Number(fieldValue);
                }
            }, 0);
        }

        return pricing <= this.priceBreakdownThreshold;
    }

    printAndLock(): void {
        let targetStatus = OrderStatusIds.PendingReview;
        let printFormOptions = new PrintFormsOptions();
        printFormOptions.IsPrintAndLock = true;

        if (this.customer.CreditStatusId === CustomerCreditStatuses.CreditHold && !this.authorized) {
            targetStatus = OrderStatusIds.CreditHold;
            printFormOptions.IsOnCreditHold = true;
        }

        if (this.orderForm.controls.OrderStatusId.value === OrderStatusIds.Approved) {
            targetStatus = OrderStatusIds.Approved;
        }

        this.saveIfAvailable$
            .pipe(
                switchMap(() => this.salesOrderService.updatePartial({ OrderStatusId: targetStatus }, this.salesOrderId)),
                switchMap(() => this.salesOrderService.printForms(this.salesOrderId, printFormOptions)),
                finalize(() => {
                    this.isEditing = !this.salesOrderIsNotOpen;
                    this.setFieldVisibility();
                }),
            )
            .subscribe((pdf) => {
                this.salesOrder.OrderStatusId = targetStatus;
                this.orderForm.controls.OrderStatusId.setValue(this.salesOrder.OrderStatusId);
                PrintService.printPdf(pdf);
            });
    }

    cancelClick(): void {
        this.router.navigate(['/salesorders-manufacturing']);
    }

    toggleSalesOrderArchived(): void {
        const setArchived = !this.salesOrder.Archived;
        const archiveText = this.salesOrder.Archived ? 'Un-Archive' : 'Archive';
        this.modalService
            .showModal({
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                confirmButtonText: `Yes, ${archiveText.toLowerCase()} it!`,
                showCancelButton: true,
                text: `${archiveText} this Sales Order?`,
                title: 'Are you sure?',
                type: 'warning',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.salesOrderService.toggleSalesOrderArchived(this.salesOrder.Id, setArchived).subscribe(() => {
                        this.notificationsService.success(`Sales Order ${archiveText}d Successfully`);
                        this.salesOrder.Archived = setArchived;
                        if (setArchived) {
                            void this.router.navigate([`/salesorders-manufacturing`]);
                        }
                    });
                }
            });
    }

    copyOrder(): void {
        this.saveIfAvailable$.pipe(switchMap(() => this.salesOrderService.copy(this.salesOrder.Id))).subscribe((answer) => {
            this.notificationsService.success('Order Copied Successfully');
            window.open('/#/salesorders-manufacturing/' + answer, '_blank');
        });
    }

    parseCaliperFormatting(event: any): void {
        if (event.target.value) {
            this.abstractOrderDetailControls.Caliper.value = event.target.value * 1;
            this.formattedCaliper = parseFloat(event.target.value).toFixed(3);
            this.calculateHeight();
            this.calculateRuleNumber();
        } else {
            this.formattedCaliper = null;
        }
    }

    parseBasisFormatting(event: any): void {
        this.abstractOrderDetailControls.Basis.value = event.target.value * 1;
        this.formattedBasis = parseFloat(event.target.value).toFixed(1);
    }

    onBoardGradeChange(boardGradeId: number): void {
        if (this.formCreated) {
            this.autofillIsDressStock(boardGradeId);
        }
    }

    private autofillIsDressStock(boardGradeId: number): void {
        let isDressStockCtrl = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.IsDressStock;
        let boardGradeName = this.boardGradeItems
            .find((bg) => bg.Id === boardGradeId)
            ?.Name.toLocaleUpperCase()
            .trim();
        // Board Grades are user managed, values could technically change so we are using string matching on the name
        if (boardGradeName && DRESS_STOCK_BOARD_GRADES.some((v) => v === boardGradeName)) {
            isDressStockCtrl.setValue(true);
        } else {
            isDressStockCtrl.setValue(false);
        }
    }

    clearBoardGrade(): void {
        let gradeId = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.BoardGradeId;
        gradeId.setValue(null);
    }

    clearLining(): void {
        if (!this.isCustomLining) {
            let liningId = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningId;
            liningId.setValue(null);
        } else {
            let liningCustom = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningCustom;
            liningCustom.setValue(null);
        }
    }

    disableLining(): void {
        if (!this.isCustomLining) {
            let liningId = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningId;
            liningId.disable();
        } else {
            let liningCustom = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningCustom;
            liningCustom.disable();
        }
    }

    enableLining(): void {
        if (!this.isCustomLining) {
            let liningId = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningId;
            liningId.enable();
        } else {
            let liningCustom = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.LiningCustom;
            liningCustom.enable();
        }
    }

    clearFinish(): void {
        if (!this.isCustomFinish) {
            let finishId = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.FinishId;
            finishId.setValue(null);
        } else {
            let finishCustom = (<FormGroup>this.orderForm.controls.ManufacturingOrderDetail).controls.FinishCustom;
            finishCustom.setValue(null);
        }
    }

    showPrintModal(): void {
        this.setDefaultPrintFormOptions();
        this.printModal.show();
    }

    closePrintModal(): void {
        this.printModal.close();
    }

    navigateToMillComplaints(): void {
        void this.router.navigate([millComplaintPathSlugs.root], { relativeTo: this.activatedRoute });
    }

    toggleCommission(isCommission: boolean): void {
        this.isCommission = isCommission;
        if (!isCommission && this.abstractOrderInfoControls) {
            this.abstractOrderInfoControls.CommissionSalesPersonId.value = null;
        }
    }

    addFormSubscriptions(): void {
        this.formSubscriptions.add(
            this.orderForm.controls.OrderDetailLines.valueChanges.subscribe((value) => {
                if ((this.converting && value.length > 0) || this.salesOrder?.OrderDetailLines.length > 0) {
                    (this.orderForm.controls.ManufacturingOrderDetail as FormGroup).controls.IsConverting.setValue(true);
                    (this.orderForm.controls.ManufacturingOrderDetail as FormGroup).controls.IsConverting.disable();
                } else {
                    (this.orderForm.controls.ManufacturingOrderDetail as FormGroup).controls.IsConverting.setValue(false);
                    this.formIsLocked
                        ? (this.orderForm.controls.ManufacturingOrderDetail as FormGroup).controls.IsConverting.disable()
                        : (this.orderForm.controls.ManufacturingOrderDetail as FormGroup).controls.IsConverting.enable();
                }
            }),
        );
    }

    showCreditAuthModal(): void {
        if (this.creditAuthModal) {
            this.selectedCreditAuthStatus = this.salesOrder.CreditAuthStatusId;
            this.creditAuthModal.show();
        }
    }

    closeCreditAuthModal(): void {
        if (this.creditAuthModal) {
            this.creditAuthModal.close();
        }
    }

    saveCreditAuth(): void {
        const date = new Date();
        let targetStatus =
            this.selectedCreditAuthStatus === CreditAuthorizationStatuses.Order || this.selectedCreditAuthStatus === CreditAuthorizationStatuses.Both
                ? OrderStatusIds.PendingReview
                : this.salesOrder.OrderStatusId;

        if (this.orderForm.controls.OrderStatusId.value === OrderStatusIds.Approved) {
            targetStatus = OrderStatusIds.Approved;
        }

        this.salesOrderService
            .updatePartial(
                { OrderStatusId: targetStatus, CreditAuthStatusId: this.selectedCreditAuthStatus, CreditAuthDate: date },
                this.salesOrderId,
            )
            .subscribe(() => {
                this.notificationsService.success('Credit Authorization updated successfully.');
                this.salesOrder.CreditAuthStatusId = this.selectedCreditAuthStatus;
                this.salesOrder.CreditAuthDate = date;
                this.salesOrder.OrderStatusId = targetStatus;
                this.orderForm.controls.OrderStatusId.setValue(this.salesOrder.OrderStatusId);
            });
    }

    edit(): void {
        this.isEditing = true;
        this.setFieldVisibility();
    }
}
