import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IReceiptFormat } from '../interfaces/receipt-format';
import { ICustomer } from '../interfaces/customer';
import { IReceiptSizeTotal } from '../interfaces/receipt-size-total';

export interface IReceiptFormatDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    receiptSizeTotals?: IReceiptSizeTotal[];
}

export class ReceiptFormatDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    receiptSizeTotals: IReceiptSizeTotal[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private receiptformat?: IReceiptFormat, additionalParameters?: IReceiptFormatDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ReceiptFormat';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.receiptSizeTotals = additionalParameters && additionalParameters.receiptSizeTotals || undefined;

        this.Form = {
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receiptformat && this.receiptformat.CustomerId || null,
            }),
            OmitNewmanHeader: new DynamicField({
                formGroup: this.formGroup,
                label: 'Omit Newman Header',
                name: 'OmitNewmanHeader',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptformat && this.receiptformat.hasOwnProperty('OmitNewmanHeader') && this.receiptformat.OmitNewmanHeader !== null ? this.receiptformat.OmitNewmanHeader : false,
            }),
            ReceiptSizeTotalId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Size Total',
                name: 'ReceiptSizeTotalId',
                options: this.receiptSizeTotals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receiptformat && this.receiptformat.ReceiptSizeTotalId || null,
            }),
            ShowBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Bundles',
                name: 'ShowBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptformat && this.receiptformat.hasOwnProperty('ShowBundles') && this.receiptformat.ShowBundles !== null ? this.receiptformat.ShowBundles : false,
            }),
            ShowPieces: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Pieces',
                name: 'ShowPieces',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptformat && this.receiptformat.hasOwnProperty('ShowPieces') && this.receiptformat.ShowPieces !== null ? this.receiptformat.ShowPieces : false,
            }),
            ShowSkidIds: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Skid Ids',
                name: 'ShowSkidIds',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptformat && this.receiptformat.hasOwnProperty('ShowSkidIds') && this.receiptformat.ShowSkidIds !== null ? this.receiptformat.ShowSkidIds : true,
            }),
            ShowWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Weight',
                name: 'ShowWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptformat && this.receiptformat.hasOwnProperty('ShowWeight') && this.receiptformat.ShowWeight !== null ? this.receiptformat.ShowWeight : false,
            }),
        };

        this.View = {
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.receiptformat && this.receiptformat.hasOwnProperty('CustomerId') && this.receiptformat.CustomerId !== null ? this.receiptformat.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            OmitNewmanHeader: new DynamicLabel(
                'Omit Newman Header',
                this.receiptformat && this.receiptformat.hasOwnProperty('OmitNewmanHeader') && this.receiptformat.OmitNewmanHeader !== null ? this.receiptformat.OmitNewmanHeader : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReceiptSizeTotalId: new DynamicLabel(
                'Receipt Size Total',
                getMetaItemValue(this.receiptSizeTotals, this.receiptformat && this.receiptformat.hasOwnProperty('ReceiptSizeTotalId') && this.receiptformat.ReceiptSizeTotalId !== null ? this.receiptformat.ReceiptSizeTotalId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowBundles: new DynamicLabel(
                'Show Bundles',
                this.receiptformat && this.receiptformat.hasOwnProperty('ShowBundles') && this.receiptformat.ShowBundles !== null ? this.receiptformat.ShowBundles : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowPieces: new DynamicLabel(
                'Show Pieces',
                this.receiptformat && this.receiptformat.hasOwnProperty('ShowPieces') && this.receiptformat.ShowPieces !== null ? this.receiptformat.ShowPieces : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowSkidIds: new DynamicLabel(
                'Show Skid Ids',
                this.receiptformat && this.receiptformat.hasOwnProperty('ShowSkidIds') && this.receiptformat.ShowSkidIds !== null ? this.receiptformat.ShowSkidIds : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowWeight: new DynamicLabel(
                'Show Weight',
                this.receiptformat && this.receiptformat.hasOwnProperty('ShowWeight') && this.receiptformat.ShowWeight !== null ? this.receiptformat.ShowWeight : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
