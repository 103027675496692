import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ICuttingTeamLog } from '@model/interfaces/cutting-team-log';
import { BulkByDateService } from '@common/services/bulk-by-date.service';
import { Observable } from 'rxjs';

export const emptyCuttingTeamLog: ICuttingTeamLog = {
    BackTrim: false,
    BdlSk: null,
    BoardGrade: '',
    BoardTypeId: null,
    Break: false,
    Bundles: null,
    Caliper: null,
    ChangeBlade: false,
    Count: null,
    CustomerName: '',
    CutCorners: false,
    Cutter: '',
    Date: null,
    Finisher: '',
    Id: 0,
    Length: '',
    MachineNumber: '',
    Note: '',
    Remarks: false,
    ReportId: 1,
    Restack: false,
    SalesOrderId: 0,
    ShiftCode: '',
    Skids: null,
    TiedBundles: false,
    TimeOff: null,
    TimeOn: null,
    Width: '',
};

@Injectable()
export class CuttingTeamLogService extends BulkByDateService<ICuttingTeamLog> {
    constructor(public http: HttpClient) {
        super('/cutting-team-logs', http);
    }

    getEmptyCuttingTeamLog(): ICuttingTeamLog {
        return { ...emptyCuttingTeamLog };
    }

    deleteAllByDate(date: Date): Observable<void> {
        const params = new HttpParams().set('date', date.toISOString().split('T')[0]);
        return this.http.delete<void>('/cutting-team-logs/', { params });
    }
}
