import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { ITestScan } from '@model/interfaces/test-scan';
import { ITestScanDetail } from '@model/interfaces/test-scan-detail';
import { ITestStack } from '@model/interfaces/test-stack';

export const emptyTestScan: ITestScan = {
    DateCompleted: new Date(),
    EmployeeName: null,
    Id: 0,
    ScannerId: 0,
    TestScanDetails: null,
    UserId: 0,
};

export const emptyTestScanDetail: ITestScanDetail = {
    Actual: null,
    Caliper: null,
    Description: '',
    Id: 0,
    Ply: null,
    SkidHeight: null,
    Target: null,
    TestScanId: 0,
};

@Injectable()
export class TestScanService extends BaseService<ITestScan> {
    constructor(public http: HttpClient) {
        super('/test-scans', http);
    }

    getEmptyTestScan(): ITestScan {
        return { ...emptyTestScan };
    }

    getEmptyTestScanDetail(): ITestScanDetail {
        return { ...emptyTestScanDetail };
    }

    getTestScanDetailFor(ts: ITestStack): ITestScanDetail {
        const testScanDetail = this.getEmptyTestScanDetail();
        testScanDetail.Caliper = ts.Caliper;
        testScanDetail.Description = ts.Name;
        testScanDetail.Ply = ts.Ply;
        testScanDetail.SkidHeight = ts.SkidHeight;
        testScanDetail.Target = ts.Target;
        return testScanDetail;
    }
}
