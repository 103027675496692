import {
    OrderCustomerSpecificationDynamicControls,
    IOrderCustomerSpecificationDynamicControlsParameters,
} from '../form-controls/order-customer-specification.form-controls';
import { IOrderCustomerSpecificationPartial } from './order-customer-specification';
import { IShipOnType } from '../interfaces/ship-on-type';
import { ITagSkidType } from '../interfaces/tag-skid-type';
import {
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicField,
    DynamicLabel,
    IDynamicFieldType,
    InputTypes,
    IDynamicField,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { TagSkidTypes } from '../TagSkidTypes';

export class OrderCustomerSpecificationDynamicControlsPartial extends OrderCustomerSpecificationDynamicControls {
    constructor(
        private orderCustomerSpecificationPartial?: IOrderCustomerSpecificationPartial,
        private additionalParametersPartial?: IOrderCustomerSpecificationDynamicControlsParameters,
    ) {
        super(orderCustomerSpecificationPartial, additionalParametersPartial);
        this.Form.TagSkidTypeId.hideLabel = true;
        this.Form.BandingInstructionId.hideLabel = true;
        (<IDynamicField>this.Form.BandingInstructionId).type.inputType = SelectInputTypes.Dropdown;
        (<IDynamicField>this.Form.BandingInstructionId).labelHtml = '<label>Banding</label>';
        this.Form.IsFortyPoundBundle.hideLabel = true;

        this.Form.IsPileUp.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.RadioButtonList,
        });
        this.Form.IsPileUp.options = [
            {
                Id: null,
                Name: 'N/A',
            },
            {
                Id: true,
                Name: 'Pile UP',
            },
            {
                Id: false,
                Name: 'Pile DOWN',
            },
        ];
        this.Form.IsPileUp.hideLabel = true;
        if (this.orderCustomerSpecificationPartial && this.orderCustomerSpecificationPartial.IsPileUp !== undefined) {
            this.Form.IsPileUp.value = this.orderCustomerSpecificationPartial.IsPileUp;
        } else {
            this.Form.IsPileUp.value = null;
        }
        if (this.orderCustomerSpecificationPartial && this.orderCustomerSpecificationPartial.TagSkidTypeId) {
            this.Form.TagSkidTypeId.value = this.orderCustomerSpecificationPartial.TagSkidTypeId;
        } else {
            this.Form.TagSkidTypeId.value = TagSkidTypes.Tag_1;
        }
        this.Form.PriceBreakdown = new DynamicField({
            disabled: true,
            formGroup: this.formGroup,
            label: 'Price Breakdown',
            name: 'PriceBreakdown',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            }),
            validation: [Validators.maxLength(1000)],
            validators: { maxlength: 1000 },
            value:
                this.orderCustomerSpecificationPartial &&
                    this.orderCustomerSpecificationPartial.hasOwnProperty('PriceBreakdown') &&
                    this.orderCustomerSpecificationPartial.PriceBreakdown !== null
                    ? this.orderCustomerSpecificationPartial.PriceBreakdown.toString()
                    : '',
        });
        this.Form.IsPileUp.label = 'Pile Up';
        this.Form.IsPileUp.labelHtml = '<label>Pile Up</label>';
        this.Form.IsTiedBundles.labelHtml = '<label>Tied Bundles</label>';
        this.Form.IsSpecialLabels.labelHtml = '<label>Special Labels</label>';
        this.Form.IsScanCounts.labelHtml = '<label>Scan Counts</label>';
        this.Form.IsCornerProtectors.labelHtml = '<label>Corner Protectors</label>';
        this.Form.IsNoNewmanMarkings.labelHtml = '<label>No Newman Markings</label>';
        this.Form.IsLtl.labelHtml = '<label>Stretch Wrap</label>';
        this.Form.IsFortyPoundBundle.labelHtml = '<label>Forty Pound Bundle</label>';
        this.Form.IsFortyPoundBundle.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        this.Form.IsFortyPoundBundle.options = [
            {
                Id: false,
                Name: 'Fifty Pound Bundle',
            },
            {
                Id: true,
                Name: 'Forty Pound Bundle',
            },
        ];
        this.Form.IsSpecialLabels.label = 'Special Labels';
        this.Form.IsTiedBundles.label = 'Tied Bundles';

        this.Form.CustomerProductId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Customer Code',
            name: 'CustomerProductId',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            value:
                orderCustomerSpecificationPartial && orderCustomerSpecificationPartial.hasOwnProperty('CustomerProductId')
                    ? orderCustomerSpecificationPartial.CustomerProductId
                    : null,
        } as IDynamicField);
    }
}
