import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EntityListConfig, IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDynamicConfig } from '@mt-ng2/dynamic-form';
import { BaseService, IEntity } from '@mt-ng2/base-service';

import { IGenericClass } from '@common/interfaces/generic-class';

import { EMPTY, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { IEntitySlugs } from '@model/interfaces/custom/entity-slugs';
import { ModalService } from '@mt-ng2/modal-module';

export interface IEntityListCardOptions<TData extends IEntity> {
    entitySlugs: IEntitySlugs;
    entityFormGroup: string;
    emptyItem: TData;
    service: BaseService<TData>;
    canEdit: boolean;
    canAdd: boolean;
    entityListConfig: EntityListConfig;
    dynamicConfig: IGenericClass<IDynamicConfig<TData>>;
    additionalConfigOptions: unknown[];
    parentId: number;
    maxToDisplay?: number;
    order?: string;
    orderDirection?: string;
}

@Component({
    selector: 'app-entity-list-card',
    templateUrl: './entity-list-card.component.html',
})
export class EntityListCardComponent<TData extends IEntity> implements OnInit {
    @Input() entityListCardOptions: IEntityListCardOptions<TData>;

    selectedItem: TData;
    items: TData[];
    total: number;

    isEditing = false;
    isHovered = false;
    doubleClickIsDisabled = false;

    formFactory: IDynamicConfig<TData>;
    config: any = { formObject: [], viewOnly: [] };
    selectedItemCopy: any;

    get isNewItem(): boolean {
        return this.selectedItem && this.selectedItem.Id ? false : true;
    }
    get showTotalCount(): boolean {
        return this.items && this.total > this.items.length;
    }
    get entityName(): string {
        return this.entityListCardOptions.entitySlugs.entityName;
    }
    get service(): BaseService<TData> {
        return this.entityListCardOptions.service;
    }
    get entityListConfig(): EntityListConfig {
        return this.entityListCardOptions.entityListConfig;
    }

    get additionalConfigOptions(): unknown[] {
        return this.entityListCardOptions.additionalConfigOptions;
    }

    constructor(
        public notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.getItems();
    }

    getItemsCall(): Observable<HttpResponse<TData[]>> {
        const searchEntity: IEntitySearchParams = {
            extraParams: [
                new ExtraSearchParams({
                    name: 'ParentId',
                    value: this.entityListCardOptions.parentId.toString(),
                }),
            ],
            order: this.entityListCardOptions.order ?? 'Id',
            orderDirection: this.entityListCardOptions.orderDirection ?? 'desc',
            query: '',
            skip: 0,
            take: this.entityListCardOptions.maxToDisplay ?? 3,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.service.get(searchparams);
    }

    getItems(): void {
        this.getItemsCall().subscribe((answer) => {
            this.items = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    itemSelected(event: IItemSelectedEvent): void {
        this.selectedItem = event.entity;
        this.setConfig();
        this.isEditing = true;
    }

    seeAll(): void {
        this.router.navigate([this.entityListCardOptions.entitySlugs.root], { relativeTo: this.route });
    }

    addItem(): void {
        this.selectedItem = { ...this.entityListCardOptions.emptyItem };
        this.setConfig();
        this.isEditing = true;
    }

    cancel(): void {
        this.selectedItem = null;
        this.isEditing = false;
        this.getItems();
    }

    setConfig(): void {
        this.formFactory = new this.entityListCardOptions.dynamicConfig(this.selectedItem, ...this.additionalConfigOptions);

        if (this.isNewItem) {
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.selectedItem, form.value[this.entityListCardOptions.entityFormGroup]);
            this.saveItem();
        } else {
            this.notificationsService.error('Save failed. Please check the form and try again.');
            markAllFormFieldsAsTouched(form);
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    saveItem(): void {
        let saveFn: Observable<any>;
        if (this.entityListCardOptions.entityFormGroup === 'CustomPackaging') {
            saveFn = this.saveItemForCustomPackaging();
        } else {
            saveFn = this.isNewItem ? this.service.create(this.selectedItem) : this.service.update(this.selectedItem);
        }

        this.handleSaveFn(saveFn);
    }

    private saveItemForCustomPackaging(): Observable<any> {
        const saveFn = this.isNewItem
            ? this.service.create(this.selectedItem)
            : this.showUpdatePopup().pipe(
                  switchMap((isConfirmed) => {
                      if (isConfirmed) {
                          return this.service.update(this.selectedItem);
                      } else {
                          return EMPTY;
                      }
                  }),
              );

        return saveFn;
    }

    private handleSaveFn(saveFn: Observable<any>): void {
        saveFn.pipe(finalize(() => (this.doubleClickIsDisabled = false))).subscribe(() => {
            this.success();
        });
    }

    private success(): void {
        this.notificationsService.success(`${this.entityName} saved successfully.`);
        this.selectedItem = null;
        this.isEditing = false;
        this.getItems();
    }

    private showUpdatePopup(): Observable<boolean> {
        return this.modalService
            .showModal({
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                showConfirmButton: true,
                text: 'Are you sure you want to update this Custom Packaging? Doing so will update all existing orders associated with this record',
                title: 'Update Custom Packaging',
            })
            .pipe(map((res) => res.isConfirmed));
    }
}
