import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IManufacturingOrderDetail } from '../interfaces/manufacturing-order-detail';
import { IBoardGrade } from '../interfaces/board-grade';
import { IPalletInfo } from '../interfaces/pallet-info';
import { IFinish } from '../interfaces/finish';
import { IGeneralLedgerCode } from '../interfaces/general-ledger-code';
import { ILining } from '../interfaces/lining';
import { IShipOnType } from '../interfaces/ship-on-type';
import { IUnitOfMeasure } from '../interfaces/unit-of-measure';

export interface IManufacturingOrderDetailDynamicControlsParameters {
    formGroup?: string;
    boardGrades?: IBoardGrade[];
    linings?: ILining[];
    finishes?: IFinish[];
    generalLedgerCodes?: IGeneralLedgerCode[];
    unitOfMeasures?: IUnitOfMeasure[];
    shipOnTypes?: IShipOnType[];
    finalPalletInfos?: IPalletInfo[];
    convertingPalletInfos?: IPalletInfo[];
}

export class ManufacturingOrderDetailDynamicControls {

    formGroup: string;
    boardGrades: IBoardGrade[];
    linings: ILining[];
    finishes: IFinish[];
    generalLedgerCodes: IGeneralLedgerCode[];
    unitOfMeasures: IUnitOfMeasure[];
    shipOnTypes: IShipOnType[];
    finalPalletInfos: IPalletInfo[];
    convertingPalletInfos: IPalletInfo[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private manufacturingorderdetail?: IManufacturingOrderDetail, additionalParameters?: IManufacturingOrderDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ManufacturingOrderDetail';
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;
        this.linings = additionalParameters && additionalParameters.linings || undefined;
        this.finishes = additionalParameters && additionalParameters.finishes || undefined;
        this.generalLedgerCodes = additionalParameters && additionalParameters.generalLedgerCodes || undefined;
        this.unitOfMeasures = additionalParameters && additionalParameters.unitOfMeasures || undefined;
        this.shipOnTypes = additionalParameters && additionalParameters.shipOnTypes || undefined;
        this.finalPalletInfos = additionalParameters && additionalParameters.finalPalletInfos || undefined;
        this.convertingPalletInfos = additionalParameters && additionalParameters.convertingPalletInfos || undefined;

        this.Form = {
            Basis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis',
                name: 'Basis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.Basis || null,
            }),
            BdlSk: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdl Sk',
                name: 'BdlSk',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.BdlSk || null,
            }),
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.BoardGradeId || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.Caliper || null,
            }),
            ConvertingPalletInfoId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Converting Pallet Info',
                name: 'ConvertingPalletInfoId',
                options: this.convertingPalletInfos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.ConvertingPalletInfoId || null,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.Count || null,
            }),
            FinalPalletInfoId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Final Pallet Info',
                name: 'FinalPalletInfoId',
                options: this.finalPalletInfos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.FinalPalletInfoId || null,
            }),
            FinishCustom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish Custom',
                name: 'FinishCustom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('FinishCustom') && this.manufacturingorderdetail.FinishCustom !== null ? this.manufacturingorderdetail.FinishCustom.toString() : '',
            }),
            FinishId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finish',
                name: 'FinishId',
                options: this.finishes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.FinishId || null,
            }),
            GeneralLedgerCodeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'General Ledger Code',
                name: 'GeneralLedgerCodeId',
                options: this.generalLedgerCodes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.GeneralLedgerCodeId || null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Height') && this.manufacturingorderdetail.Height !== null ? this.manufacturingorderdetail.Height.toString() : '',
            }),
            IsBridgeView: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Bridge View',
                name: 'IsBridgeView',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsBridgeView') && this.manufacturingorderdetail.IsBridgeView !== null ? this.manufacturingorderdetail.IsBridgeView : false,
            }),
            IsCommission: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Commission',
                name: 'IsCommission',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsCommission') && this.manufacturingorderdetail.IsCommission !== null ? this.manufacturingorderdetail.IsCommission : false,
            }),
            IsConsignment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Consignment',
                name: 'IsConsignment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsConsignment') && this.manufacturingorderdetail.IsConsignment !== null ? this.manufacturingorderdetail.IsConsignment : false,
            }),
            IsConverting: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Converting',
                name: 'IsConverting',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsConverting') && this.manufacturingorderdetail.IsConverting !== null ? this.manufacturingorderdetail.IsConverting : false,
            }),
            IsDressStock: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Dress Stock',
                name: 'IsDressStock',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsDressStock') && this.manufacturingorderdetail.IsDressStock !== null ? this.manufacturingorderdetail.IsDressStock : false,
            }),
            IsFscCertified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Fsc Certified',
                name: 'IsFscCertified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsFscCertified') && this.manufacturingorderdetail.IsFscCertified !== null ? this.manufacturingorderdetail.IsFscCertified : false,
            }),
            IsLiningDept: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Lining Dept',
                name: 'IsLiningDept',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsLiningDept') && this.manufacturingorderdetail.IsLiningDept !== null ? this.manufacturingorderdetail.IsLiningDept : false,
            }),
            IsShortWay: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Short Way',
                name: 'IsShortWay',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsShortWay') && this.manufacturingorderdetail.IsShortWay !== null ? this.manufacturingorderdetail.IsShortWay : true,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Length') && this.manufacturingorderdetail.Length !== null ? this.manufacturingorderdetail.Length.toString() : '',
            }),
            LiningCustom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Lining Custom',
                name: 'LiningCustom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('LiningCustom') && this.manufacturingorderdetail.LiningCustom !== null ? this.manufacturingorderdetail.LiningCustom.toString() : '',
            }),
            LiningId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Lining',
                name: 'LiningId',
                options: this.linings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.LiningId || null,
            }),
            NumberOfSkids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Skids',
                name: 'NumberOfSkids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.NumberOfSkids || null,
            }),
            Piles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Piles',
                name: 'Piles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.Piles || null,
            }),
            Quantity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quantity',
                name: 'Quantity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.Quantity || null,
            }),
            RuleNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rule Number',
                name: 'RuleNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.RuleNumber || null,
            }),
            ShipOnTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship On Type',
                name: 'ShipOnTypeId',
                options: this.shipOnTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.ShipOnTypeId || null,
            }),
            UnitOfMeasureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unit Of Measure',
                name: 'UnitOfMeasureId',
                options: this.unitOfMeasures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.UnitOfMeasureId || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Width') && this.manufacturingorderdetail.Width !== null ? this.manufacturingorderdetail.Width.toString() : '',
            }),
        };

        this.View = {
            Basis: new DynamicLabel(
                'Basis',
                this.manufacturingorderdetail && this.manufacturingorderdetail.Basis || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            BdlSk: new DynamicLabel(
                'Bdl Sk',
                this.manufacturingorderdetail && this.manufacturingorderdetail.BdlSk || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            BoardGradeId: new DynamicLabel(
                'Board Grade',
                getMetaItemValue(this.boardGrades, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('BoardGradeId') && this.manufacturingorderdetail.BoardGradeId !== null ? this.manufacturingorderdetail.BoardGradeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.manufacturingorderdetail && this.manufacturingorderdetail.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            ConvertingPalletInfoId: new DynamicLabel(
                'Converting Pallet Info',
                getMetaItemValue(this.convertingPalletInfos, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('ConvertingPalletInfoId') && this.manufacturingorderdetail.ConvertingPalletInfoId !== null ? this.manufacturingorderdetail.ConvertingPalletInfoId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.manufacturingorderdetail && this.manufacturingorderdetail.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            FinalPalletInfoId: new DynamicLabel(
                'Final Pallet Info',
                getMetaItemValue(this.finalPalletInfos, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('FinalPalletInfoId') && this.manufacturingorderdetail.FinalPalletInfoId !== null ? this.manufacturingorderdetail.FinalPalletInfoId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            FinishCustom: new DynamicLabel(
                'Finish Custom',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('FinishCustom') && this.manufacturingorderdetail.FinishCustom !== null ? this.manufacturingorderdetail.FinishCustom.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FinishId: new DynamicLabel(
                'Finish',
                getMetaItemValue(this.finishes, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('FinishId') && this.manufacturingorderdetail.FinishId !== null ? this.manufacturingorderdetail.FinishId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneralLedgerCodeId: new DynamicLabel(
                'General Ledger Code',
                getMetaItemValue(this.generalLedgerCodes, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('GeneralLedgerCodeId') && this.manufacturingorderdetail.GeneralLedgerCodeId !== null ? this.manufacturingorderdetail.GeneralLedgerCodeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Height') && this.manufacturingorderdetail.Height !== null ? this.manufacturingorderdetail.Height.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsBridgeView: new DynamicLabel(
                'Is Bridge View',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsBridgeView') && this.manufacturingorderdetail.IsBridgeView !== null ? this.manufacturingorderdetail.IsBridgeView : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsCommission: new DynamicLabel(
                'Is Commission',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsCommission') && this.manufacturingorderdetail.IsCommission !== null ? this.manufacturingorderdetail.IsCommission : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsConsignment: new DynamicLabel(
                'Is Consignment',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsConsignment') && this.manufacturingorderdetail.IsConsignment !== null ? this.manufacturingorderdetail.IsConsignment : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsConverting: new DynamicLabel(
                'Is Converting',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsConverting') && this.manufacturingorderdetail.IsConverting !== null ? this.manufacturingorderdetail.IsConverting : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsDressStock: new DynamicLabel(
                'Is Dress Stock',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsDressStock') && this.manufacturingorderdetail.IsDressStock !== null ? this.manufacturingorderdetail.IsDressStock : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsFscCertified: new DynamicLabel(
                'Is Fsc Certified',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsFscCertified') && this.manufacturingorderdetail.IsFscCertified !== null ? this.manufacturingorderdetail.IsFscCertified : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsLiningDept: new DynamicLabel(
                'Is Lining Dept',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsLiningDept') && this.manufacturingorderdetail.IsLiningDept !== null ? this.manufacturingorderdetail.IsLiningDept : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsShortWay: new DynamicLabel(
                'Is Short Way',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('IsShortWay') && this.manufacturingorderdetail.IsShortWay !== null ? this.manufacturingorderdetail.IsShortWay : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Length') && this.manufacturingorderdetail.Length !== null ? this.manufacturingorderdetail.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LiningCustom: new DynamicLabel(
                'Lining Custom',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('LiningCustom') && this.manufacturingorderdetail.LiningCustom !== null ? this.manufacturingorderdetail.LiningCustom.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LiningId: new DynamicLabel(
                'Lining',
                getMetaItemValue(this.linings, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('LiningId') && this.manufacturingorderdetail.LiningId !== null ? this.manufacturingorderdetail.LiningId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfSkids: new DynamicLabel(
                'Number Of Skids',
                this.manufacturingorderdetail && this.manufacturingorderdetail.NumberOfSkids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Piles: new DynamicLabel(
                'Piles',
                this.manufacturingorderdetail && this.manufacturingorderdetail.Piles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Quantity: new DynamicLabel(
                'Quantity',
                this.manufacturingorderdetail && this.manufacturingorderdetail.Quantity || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RuleNumber: new DynamicLabel(
                'Rule Number',
                this.manufacturingorderdetail && this.manufacturingorderdetail.RuleNumber || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ShipOnTypeId: new DynamicLabel(
                'Ship On Type',
                getMetaItemValue(this.shipOnTypes, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('ShipOnTypeId') && this.manufacturingorderdetail.ShipOnTypeId !== null ? this.manufacturingorderdetail.ShipOnTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            UnitOfMeasureId: new DynamicLabel(
                'Unit Of Measure',
                getMetaItemValue(this.unitOfMeasures, this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('UnitOfMeasureId') && this.manufacturingorderdetail.UnitOfMeasureId !== null ? this.manufacturingorderdetail.UnitOfMeasureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.manufacturingorderdetail && this.manufacturingorderdetail.hasOwnProperty('Width') && this.manufacturingorderdetail.Width !== null ? this.manufacturingorderdetail.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
