<div class="row">
    <div class="col-md-7">
        <h2>Sales Orders</h2>
    </div>
</div>
<div>
    <mt-search-bar #searchBar *ngIf="!isManufacturingOrder" (onSearch)="search($event)"></mt-search-bar>
    <div class="row" *ngIf="isManufacturingOrder">
        <div class="col-md-2">
            <label>Search</label>
            <mt-search-bar #searchBar (onSearch)="search($event)"></mt-search-bar>
        </div>
        <div class="col-md-2">
            <label>Ship To Name</label>
            <mt-search-bar (onSearch)="searchOnShipToName($event)"></mt-search-bar>
        </div>
        <div class="col-md-2">
            <label>Width</label>
            <mt-search-bar (onSearch)="searchOnWidth($event)"></mt-search-bar>
        </div>
        <div class="col-md-2">
            <label>Length</label>
            <mt-search-bar (onSearch)="searchOnLength($event)"></mt-search-bar>
        </div>
        <div class="col-md-2">
            <label>Caliper (min)</label>
            <input
                class="form-control"
                type="number"
                step="0.01"
                min="0"
                max="1"
                [(ngModel)]="minCaliperQuery"
                (ngModelChange)="filterSelectionChanged()"
            />
        </div>
        <div class="col-md-2">
            <label>Caliper (max)</label>
            <input
                class="form-control"
                type="number"
                step="0.01"
                min="0"
                max="1"
                [(ngModel)]="maxCaliperQuery"
                (ngModelChange)="filterSelectionChanged()"
            />
        </div>
    </div>
    <mt-search-filter-select [items]="orderType" entity="Order Type" (onSelectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
    <mt-search-filter-select [items]="status" entity="Status" (onSelectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="isManufacturingOrder"
        [items]="boardGrades"
        entity="Board Grade"
        (onSelectionChanged)="filterSelectionChanged()"
    ></mt-search-filter-select>
    <mt-search-filter-select
        *ngIf="isManufacturingOrder"
        [items]="linings"
        entity="Lining"
        (onSelectionChanged)="filterSelectionChanged()"
    ></mt-search-filter-select>
    <mt-search-filter-daterange entity="Order Date" (onSelectionChanged)="orderDateSelectionChanged($event)"></mt-search-filter-daterange>
    <label *ngIf="isManufacturingOrder" class="font-weight-normal m-l-sm"
        ><input type="checkbox" [(ngModel)]="includeLining" (change)="filterSelectionChanged()" /> Lining</label
    >
    <label *ngIf="isManufacturingOrder" class="font-weight-normal m-l-sm"
        ><input type="checkbox" [(ngModel)]="includeConverting" (change)="filterSelectionChanged()" /> Converting</label
    >
    <label *ngIf="isManufacturingOrder" class="font-weight-normal m-l-sm"
        ><input type="checkbox" [(ngModel)]="includeBridgeView" (change)="filterSelectionChanged()" /> Bridge</label
    >
    <label *ngIf="isManufacturingOrder" class="font-weight-normal m-l-sm"
        ><input type="checkbox" [(ngModel)]="includeFSC" (change)="filterSelectionChanged()" /> FSC</label
    >
    <label *ngIf="canViewArchivedOrders" class="font-weight-normal m-l-sm">
        <input type="checkbox" [(ngModel)]="includeArchived" (change)="getOrders()" />
        Include Archived
    </label>
</div>

<entity-list
    [entities]="salesOrders"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="ordersPerPage"
    (onPageChanged)="getOrders()"
    (onItemSelected)="orderSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    
    pagingHotkeys
    [pagingHotkeysOptions]="pagingHotkeyOptions"
>
</entity-list>

<div class="fab-wrap-b-r" *ngIf="orderTypeId">
    <a *ngIf="orderTypeId == OrderTypeIds.Manufacturing" class="btn btn-primary btn-fab-lg" [routerLink]="['/salesorders-manufacturing/add']">
        <span class="fa fa-plus"></span>
    </a>
    <a *ngIf="orderTypeId == OrderTypeIds.Stock" class="btn btn-primary btn-fab-lg" [routerLink]="['/salesorders-stock/add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
