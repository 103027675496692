<form [formGroup]="shipmentDateForm" (ngSubmit)="updateShipmentDate()">
    <div>
        <input type="date" formControlName="ShipmentDate" [min]="today" />
    </div>
    <br />
    <fieldset formArrayName="Shipments">
        <legend>Load Numbers</legend>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 input-container" *ngFor="let shipment of shipments.controls; let i = index">
                    <div class="input-group" [formGroup]="shipment">
                        <span class="input-group-addon">{{ i + 1 | number: '2.0' }}</span>
                        <input type="number" class="form-control" formControlName="Id" (ngModelChange)="updateValidation(shipments)" />
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default no-shadow m-r-0"
                                (click)="addComments(shipment.controls.Comments, overlay)">
                                <i *ngIf="!shipment.value.Comments" class="fa fa-comment"></i>
                                <i *ngIf="shipment.value.Comments" class="fa fa-commenting"></i>
                            </button>
                        </span>
                    </div>
                    <div class="error-container">
                        <span *ngIf="shipment.errors?.notUnique" class="error">Duplicate.</span>
                        <span *ngIf="shipment.controls.Id.errors?.notExists" class="error">Invalid #.</span>
                    </div>
                </div>
            </div>
            <div class="fab-wrap">
                <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addInputs()">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
    </fieldset>
    <br />
    <br />
    <div class="m-b-md">
        <button type="button" class="btn m-r-0" [ngClass]="insertLoad ? 'btn-success' : 'btn-default'"
            (click)="insert()">Insert</button>
        <button type="button" class="btn m-r-0" [ngClass]="removeLoad ? 'btn-success' : 'btn-default'"
            (click)="remove()">Remove</button>
        <button type="button" class="btn btn-default m-r-0" (click)="clearAll()">Clear All</button>
    </div>
    <div *ngIf="insertLoad || removeLoad" class="insert-container m-b-md">
        <div class="inline-block">
            <div *ngIf="insertLoad" class="input-group" [formGroup]="insertGroup">
                <span class="input-group-addon position-input"><input type="number" formControlName="Position" /></span>
                <input type="number" class="form-control" formControlName="Id" />
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default no-shadow m-r-0"
                        (click)="addComments(this.insertGroup.controls.Comments, overlay)">
                        <i *ngIf="!insertGroup.value.Comments" class="fa fa-comment"></i>
                        <i *ngIf="insertGroup.value.Comments" class="fa fa-commenting"></i>
                    </button>
                    <button type="button" class="btn btn-success no-shadow m-r-0" (click)="saveInsert()">
                        <i class="fa fa-check"></i></button>
                    <button type="button" class="btn btn-danger no-shadow m-r-0" (click)="closeInsert()">
                        <i class="fa fa-times"></i></button>
                </span>
            </div>
            <div *ngIf="removeLoad" class="input-group">
                <input type="number" class="form-control" min="1" decimalField [scale]="0" [(ngModel)]="removePosition"
                    [ngModelOptions]="{standalone: true}" />
                <span class="input-group-btn">
                    <button type="button" class="btn btn-success no-shadow m-r-0" (click)="saveRemove()">
                        <i class="fa fa-check"></i></button>
                    <button type="button" class="btn btn-danger no-shadow m-r-0" (click)="closeRemove()">
                        <i class="fa fa-times"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div>
        <button type="submit" class="btn btn-success" [disabled]="shipmentDateForm.invalid && (shipmentDateForm.dirty || shipmentDateForm.touched)">Save</button>
        <button type="button" class="btn btn-default m-r-0" (click)="cancelClick()">Cancel</button>
    </div>
</form>

<dialog #overlay class="overlay">
    <div *ngIf="shipmentForComments">
        <div class="input-group">
            <input type="text" class="form-control input-lg" [formControl]="shipmentForComments.control" />
            <span class="input-group-btn">
                <button type="button" class="btn btn-lg btn-success no-shadow m-r-0" (click)="saveComment()">
                    <i class="fa fa-check"></i></button>
                <button type="button" class="btn btn-lg btn-danger no-shadow m-r-0" (click)="cancelComment()">
                    <i class="fa fa-times"></i></button>
            </span>
        </div>
    </div>
</dialog>