import { DynamicField, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';

import { ShipmentDynamicControls, IShipmentDynamicControlsParameters } from '../form-controls/shipment.form-controls';
import { IShipment } from '../interfaces/shipment';

export class ShipmentDynamicControlsPartial extends ShipmentDynamicControls {
    constructor(pastingteamlogPartial?: IShipment, additionalParameters?: IShipmentDynamicControlsParameters) {
        super(pastingteamlogPartial, additionalParameters);

        (<DynamicField>this.Form.ShipmentDate).labelHtml = '<label>Load for Date</label>';
        (<DynamicField>this.Form.ShipmentDate).type.inputType = InputTypes.DateInput;

        (<DynamicField>this.Form.IsPickup).labelHtml = '<label>&nbsp;Pickup</label>';
        (<DynamicField>this.Form.StraightTruck).labelHtml = '<label>&nbsp;Straight Truck</label>';

    }
}
