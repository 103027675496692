import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { MillShiftPersonnelDynamicControls, IMillShiftPersonnelDynamicControlsParameters } from '../form-controls/mill-shift-personnel.form-controls';
import { IMillShiftPersonnel } from '../interfaces/mill-shift-personnel';
import { IMillShift } from '../interfaces/mill-shift';

const NUMBER_OF_BANDERS = 4;
const NUMBER_OF_FINISHES = 5;

export class MillShiftPersonnelDynamicControlsPartial extends MillShiftPersonnelDynamicControls {
    constructor(millshiftpersonnelPartial?: IMillShiftPersonnel, additionalParameters?: IMillShiftPersonnelDynamicControlsParameters) {
        super(millshiftpersonnelPartial, additionalParameters);

        this.Form.Scanner.setRequired(true);
        this.Form.Bander1.setRequired(true);
        this.Form.Bander1.labelHtml = '<label>Banders</label>';
        this.Form.Bander2.labelHtml = '<label>&nbsp;</label>';
        this.Form.Bander3.labelHtml = '<label>&nbsp;</label>';
        this.Form.Bander4.labelHtml = '<label>&nbsp;</label>';

        (<DynamicField>this.Form.TourBoss).validators = { maxlength: 8, required: true };
        (<DynamicField>this.Form.TourBoss).validation = [Validators.required, Validators.maxLength(8)];

        (<DynamicField>this.Form.Backtender).validators = { minlength: 2, maxlength: 2, required: true };
        (<DynamicField>this.Form.Backtender).validation = [Validators.required, Validators.minLength(2), Validators.maxLength(2)];

        (<DynamicField>this.Form.Scanner).validators = { minlength: 2, maxlength: 2, required: true };
        (<DynamicField>this.Form.Scanner).validation = [Validators.required, Validators.minLength(2), Validators.maxLength(2)];

        (<DynamicField>this.Form.Bander1).validators = { minlength: 2, maxlength: 2, required: true };
        (<DynamicField>this.Form.Bander1).validation = [Validators.required, Validators.minLength(2), Validators.maxLength(2)];

        for (let i = 2; i <= NUMBER_OF_BANDERS; i++) {
            const banderControl = this.Form[`Bander${i}`];
            if (banderControl) {
                (<DynamicField>banderControl).validators = { minlength: 2 , maxlength: 2};
                (<DynamicField>banderControl).validation = [Validators.minLength(2), Validators.maxLength(2)];
            }
        }

        for (let i = 1; i <= NUMBER_OF_FINISHES; i++) {
            const finisherControl = this.Form[`Finisher${i}`];
            if (finisherControl) {
                (<DynamicField>finisherControl).validators = { minlength: 2 , maxlength: 2};
                (<DynamicField>finisherControl).validation = [Validators.minLength(2), Validators.maxLength(2)];
            }
        }
    }
}
