<h2>Shipment Settings</h2>
<div>
    <div class="row">
        <div class="col-md-6">
            <meta-item-managed-list [service]="haulerService" [dynamicForm]="haulerDynamicForm" [canAdd]="canAdd"
                [canRemove]="canRemove"></meta-item-managed-list>
        </div>
    </div>
</div>
<br />
<a routerLink="/shipments" class="btn btn-default btn-flat">Close</a>