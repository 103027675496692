import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { ISalesOrder } from '../../model/interfaces/sales-order';

export class SalesOrdersEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Number',
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        if (saleOrder.OrderInfo && saleOrder.OrderInfo.Customer) {
                            return saleOrder.OrderInfo.Customer.CustomerName;
                        } else {
                            return '';
                        }
                    },
                    name: 'Customer',
                    sort: {
                        sortProperty: 'OrderInfo.Customer.CustomerName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        return saleOrder.OrderInfo.CustomerShippingAddress.ShipToName;
                    },
                    name: 'Ship To Name',
                    sort: {
                        sortProperty: 'OrderInfo.CustomerShippingAddress.ShipToName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        if (saleOrder.ManufacturingOrderDetail && saleOrder.ManufacturingOrderDetail.BoardGrade) {
                            return saleOrder.ManufacturingOrderDetail.BoardGrade.Name;
                        }
                        return '';
                    },
                    name: 'Boardgrade',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        if (saleOrder.ManufacturingOrderDetail && saleOrder.ManufacturingOrderDetail.Lining) {
                            return saleOrder.ManufacturingOrderDetail.Lining.Name;
                        } else if (saleOrder.ManufacturingOrderDetail && saleOrder.ManufacturingOrderDetail.LiningCustom) {
                            return saleOrder.ManufacturingOrderDetail.LiningCustom;
                        }
                        return '';
                    },
                    name: 'Lining',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        if (saleOrder.ManufacturingOrderDetail) {
                            return `${saleOrder.ManufacturingOrderDetail.Width} x ${saleOrder.ManufacturingOrderDetail.Length}`;
                        }
                        return '';
                    },
                    name: 'Width x Length',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (saleOrder: ISalesOrder): any {
                        if (saleOrder.ManufacturingOrderDetail && saleOrder.ManufacturingOrderDetail.Caliper) {
                            return saleOrder.ManufacturingOrderDetail.Caliper.toFixed(3);
                        }
                        return '';
                    },
                    name: 'Caliper',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['OrderStatus', 'Name'],
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessorFunction: function (salesOrder: ISalesOrder): any {
                        if (salesOrder.OrderInfo) {
                            return salesOrder.OrderInfo.OrderDate;
                        } else {
                            return '';
                        }
                    },
                    name: 'Order Date',
                    pipes: ['date'],
                    sort: {
                        sortProperty: 'OrderInfo.OrderDate',
                    },
                }),
            ],
            rowClass: (salesOrder: ISalesOrder) => (salesOrder.Archived ? 'archived' : ''),
        };
        super(listConfig);
    }
}
