import { OnInit, Component } from '@angular/core';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { SectionHeadingService } from '../sectionheading.service';
import { GroupConfigurationService } from '../groupconfiguration.service';
import { SectionHeadingDynamicControls } from '@model/form-controls/section-heading.form-controls';
import { GroupConfigurationDynamicControls } from '@model/form-controls/group-configuration.form-controls';
import { TestStackService } from '../../test-scans/test-stack.service';
import { TestStackDynamicControlsPartial } from '@model/partials/test-stack-partial.form-controls';

@Component({
    selector: 'app-trim-sheet-settings',
    templateUrl: './trim-sheet-settings.component.html',
})
export class TrimSheetSettingsComponent implements OnInit {
    sectionHeadingDynamicForm = new SectionHeadingDynamicControls().Form;
    groupConfigurationDynamicForm = new GroupConfigurationDynamicControls().Form;
    testStackDynamicForm = new TestStackDynamicControlsPartial().Form;

    constructor(
        public sectionHeadingService: SectionHeadingService,
        public groupConfigurationService: GroupConfigurationService,
        public testStackService: TestStackService,
    ) {}

    ngOnInit(): void {
        (<DynamicField>this.sectionHeadingDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.groupConfigurationDynamicForm.Name).type.inputType = InputTypes.Textbox;
    }
}
