import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-customer-specs',
    templateUrl: './customer-specs.component.html',
})
export class CustomerSpecsComponent implements OnInit {
    customerId: number;

    constructor(private route: ActivatedRoute, private router: Router, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.customerId = +this.route.snapshot.params.customerId;
    }

    cancel(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Order Customer Specifications save failed');
    }

    success(): void {
        this.notificationsService.success('Order Customer Specifications saved successfully');
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
