import { SelectInputTypes } from '@mt-ng2/dynamic-form';
import { ShipmentStopDynamicControls, IShipmentStopDynamicControlsParameters } from '../form-controls/shipment-stop.form-controls';
import { IShipmentStop } from '../interfaces/shipment-stop';
import { ShippingAddressPipe } from '@common/pipes/shipping-address.pipe';

export class ShipmentStopDynamicControlsPartial extends ShipmentStopDynamicControls {
    shippingAddressPipe = new ShippingAddressPipe();

    constructor(shipmentstopPartial?: IShipmentStop, additionalParameters?: IShipmentStopDynamicControlsParameters) {
        super(shipmentstopPartial, additionalParameters);

        this.Form.CustomerShippingAddressId.type.inputType = SelectInputTypes.Dropdown;
        this.Form.CustomerShippingAddressId.options = additionalParameters?.customerShippingAddresses?.map((a) => ({
            Id: a.Id,
            Name: this.shippingAddressPipe.transform(a),
        }));
    }
}
