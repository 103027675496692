import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';

import {
    GeneralLedgerCodeDynamicControls,
    IGeneralLedgerCodeDynamicControlsParameters,
} from '@model/form-controls/general-ledger-code.form-controls';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { Validators } from '@angular/forms';

export function noSeparatorConversionFunction(value: number, type: NumericControlTypes, scale: number): number {
    return value;
}

export function noSeparatorDisplayFunction(value: number, type: NumericControlTypes, scale: number): string {
    return value !== null ? `${value}` : '';
}

export class GeneralLedgerCodeDynamicControlsPartial extends GeneralLedgerCodeDynamicControls {
    constructor(generalledgercodePartial?: IGeneralLedgerCode, additionalParameters?: IGeneralLedgerCodeDynamicControlsParameters) {
        super(generalledgercodePartial, additionalParameters);

        const numericTypeNoCommaSeparator = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Integer,
            numericFunctions: {
                convertFromDisplayToForm: noSeparatorConversionFunction,
                convertFromFormToDisplay: noSeparatorConversionFunction,
                numberFormatDisplay: noSeparatorDisplayFunction,
            },
            scale: 0,
        });

        (<DynamicField>this.Form.Name).label = 'Tons & Pricing Name';
        (<DynamicField>this.Form.TonsAndPricing).type = numericTypeNoCommaSeparator;

        (<DynamicField>this.Form.GlName).label = 'GL Name';
        (<DynamicField>this.Form.GlName).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.QbItemName).label = 'QB Inventory Name';
        (<DynamicField>this.Form.QbItemName).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.IsStock).label = 'Stock';

        /*********************************************************
         * As confusing as the code below is, the customer
         * did in fact request this. Task Number is #3558384
         * for more information.
         *********************************************************/
        (<DynamicField>this.Form.Code).label = 'Tons & Pricing Code';
        (<DynamicField>this.Form.TonsAndPricing).label = 'GL Code';
    }
}
