import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'month-select',
    template: `
        <select [ngModel]="model" (ngModelChange)="change($event)" class="form-control" [required]="required">
            <option *ngIf="showEmpty"></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
        </select>
    `,
})
export class MonthSelectComponent {
    @Input() showEmpty: boolean;
    @Input() required: boolean;
    @Input() model: number;
    @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
        // set defaults
        this.showEmpty = true;
        this.required = false;
    }

    change(newValue: number): void {
        this.model = newValue;
        this.modelChange.emit(newValue);
    }
}
