import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IReceipt } from '../interfaces/receipt';
import { IPaymentNote } from '../interfaces/payment-note';
import { IShipment } from '../interfaces/shipment';
import { IShipmentStop } from '../interfaces/shipment-stop';

export interface IReceiptDynamicControlsParameters {
    formGroup?: string;
    shipments?: IShipment[];
    stops?: IShipmentStop[];
    paymentNotes?: IPaymentNote[];
}

export class ReceiptDynamicControls {

    formGroup: string;
    shipments: IShipment[];
    stops: IShipmentStop[];
    paymentNotes: IPaymentNote[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private receipt?: IReceipt, additionalParameters?: IReceiptDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Receipt';
        this.shipments = additionalParameters && additionalParameters.shipments || undefined;
        this.stops = additionalParameters && additionalParameters.stops || undefined;
        this.paymentNotes = additionalParameters && additionalParameters.paymentNotes || undefined;

        this.Form = {
            AdditionalFeesDeductions1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions1',
                name: 'AdditionalFeesDeductions1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.AdditionalFeesDeductions1 || null,
            }),
            AdditionalFeesDeductions2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions2',
                name: 'AdditionalFeesDeductions2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.AdditionalFeesDeductions2 || null,
            }),
            AdditionalFeesDeductionsGlCode1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions Gl Code1',
                name: 'AdditionalFeesDeductionsGlCode1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsGlCode1') && this.receipt.AdditionalFeesDeductionsGlCode1 !== null ? this.receipt.AdditionalFeesDeductionsGlCode1.toString() : '',
            }),
            AdditionalFeesDeductionsGlCode2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions Gl Code2',
                name: 'AdditionalFeesDeductionsGlCode2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsGlCode2') && this.receipt.AdditionalFeesDeductionsGlCode2 !== null ? this.receipt.AdditionalFeesDeductionsGlCode2.toString() : '',
            }),
            AdditionalFeesDeductionsName1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions Name1',
                name: 'AdditionalFeesDeductionsName1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsName1') && this.receipt.AdditionalFeesDeductionsName1 !== null ? this.receipt.AdditionalFeesDeductionsName1.toString() : '',
            }),
            AdditionalFeesDeductionsName2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fees Deductions Name2',
                name: 'AdditionalFeesDeductionsName2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsName2') && this.receipt.AdditionalFeesDeductionsName2 !== null ? this.receipt.AdditionalFeesDeductionsName2.toString() : '',
            }),
            CreatedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created Date',
                name: 'CreatedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.CreatedDate || null,
            }),
            DatePrinted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Printed',
                name: 'DatePrinted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.DatePrinted || null,
            }),
            DateSentUtc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Sent Utc',
                name: 'DateSentUtc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.DateSentUtc || null,
            }),
            EnergySurchargePerTon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Energy Surcharge Per Ton',
                name: 'EnergySurchargePerTon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.EnergySurchargePerTon || null,
            }),
            HasAdditionalFeesDeductions1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Additional Fees Deductions1',
                name: 'HasAdditionalFeesDeductions1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('HasAdditionalFeesDeductions1') && this.receipt.HasAdditionalFeesDeductions1 !== null ? this.receipt.HasAdditionalFeesDeductions1 : false,
            }),
            HasAdditionalFeesDeductions2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Additional Fees Deductions2',
                name: 'HasAdditionalFeesDeductions2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('HasAdditionalFeesDeductions2') && this.receipt.HasAdditionalFeesDeductions2 !== null ? this.receipt.HasAdditionalFeesDeductions2 : false,
            }),
            HasEnergySurchargePerTon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Energy Surcharge Per Ton',
                name: 'HasEnergySurchargePerTon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('HasEnergySurchargePerTon') && this.receipt.HasEnergySurchargePerTon !== null ? this.receipt.HasEnergySurchargePerTon : false,
            }),
            HasTradeDiscountPercent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Trade Discount Percent',
                name: 'HasTradeDiscountPercent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('HasTradeDiscountPercent') && this.receipt.HasTradeDiscountPercent !== null ? this.receipt.HasTradeDiscountPercent : false,
            }),
            HasTruckingAllowancePerTon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Trucking Allowance Per Ton',
                name: 'HasTruckingAllowancePerTon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('HasTruckingAllowancePerTon') && this.receipt.HasTruckingAllowancePerTon !== null ? this.receipt.HasTruckingAllowancePerTon : false,
            }),
            Invoiced: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoiced',
                name: 'Invoiced',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('Invoiced') && this.receipt.Invoiced !== null ? this.receipt.Invoiced : false,
            }),
            InvoicedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoiced Date',
                name: 'InvoicedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.InvoicedDate || null,
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.receipt && this.receipt.hasOwnProperty('Note') && this.receipt.Note !== null ? this.receipt.Note.toString() : '',
            }),
            Number: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number',
                name: 'Number',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.receipt && this.receipt.hasOwnProperty('Number') && this.receipt.Number !== null ? this.receipt.Number.toString() : '',
            }),
            PaymentNoteId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Payment Note',
                name: 'PaymentNoteId',
                options: this.paymentNotes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.PaymentNoteId || null,
            }),
            SentToQuickbooks: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sent To Quickbooks',
                name: 'SentToQuickbooks',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.hasOwnProperty('SentToQuickbooks') && this.receipt.SentToQuickbooks !== null ? this.receipt.SentToQuickbooks : false,
            }),
            SentToQuickbooksBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sent To Quickbooks By',
                name: 'SentToQuickbooksBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.SentToQuickbooksBy || null,
            }),
            SentToQuickbooksDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sent To Quickbooks Date',
                name: 'SentToQuickbooksDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.SentToQuickbooksDate || null,
            }),
            ShipmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment',
                name: 'ShipmentId',
                options: this.shipments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receipt && this.receipt.ShipmentId || null,
            }),
            Stop: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stop',
                name: 'Stop',
                options: this.stops,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receipt && this.receipt.Stop || null,
            }),
            TradeDiscountPercent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trade Discount Percent',
                name: 'TradeDiscountPercent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.TradeDiscountPercent || null,
            }),
            TruckingAllowancePerTon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trucking Allowance Per Ton',
                name: 'TruckingAllowancePerTon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.receipt && this.receipt.TruckingAllowancePerTon || null,
            }),
        };

        this.View = {
            AdditionalFeesDeductions1: new DynamicLabel(
                'Additional Fees Deductions1',
                this.receipt && this.receipt.AdditionalFeesDeductions1 || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            AdditionalFeesDeductions2: new DynamicLabel(
                'Additional Fees Deductions2',
                this.receipt && this.receipt.AdditionalFeesDeductions2 || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            AdditionalFeesDeductionsGlCode1: new DynamicLabel(
                'Additional Fees Deductions Gl Code1',
                this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsGlCode1') && this.receipt.AdditionalFeesDeductionsGlCode1 !== null ? this.receipt.AdditionalFeesDeductionsGlCode1.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            AdditionalFeesDeductionsGlCode2: new DynamicLabel(
                'Additional Fees Deductions Gl Code2',
                this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsGlCode2') && this.receipt.AdditionalFeesDeductionsGlCode2 !== null ? this.receipt.AdditionalFeesDeductionsGlCode2.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            AdditionalFeesDeductionsName1: new DynamicLabel(
                'Additional Fees Deductions Name1',
                this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsName1') && this.receipt.AdditionalFeesDeductionsName1 !== null ? this.receipt.AdditionalFeesDeductionsName1.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            AdditionalFeesDeductionsName2: new DynamicLabel(
                'Additional Fees Deductions Name2',
                this.receipt && this.receipt.hasOwnProperty('AdditionalFeesDeductionsName2') && this.receipt.AdditionalFeesDeductionsName2 !== null ? this.receipt.AdditionalFeesDeductionsName2.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CreatedDate: new DynamicLabel(
                'Created Date',
                this.receipt && this.receipt.CreatedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DatePrinted: new DynamicLabel(
                'Date Printed',
                this.receipt && this.receipt.DatePrinted || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateSentUtc: new DynamicLabel(
                'Date Sent Utc',
                this.receipt && this.receipt.DateSentUtc || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EnergySurchargePerTon: new DynamicLabel(
                'Energy Surcharge Per Ton',
                this.receipt && this.receipt.EnergySurchargePerTon || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            HasAdditionalFeesDeductions1: new DynamicLabel(
                'Has Additional Fees Deductions1',
                this.receipt && this.receipt.hasOwnProperty('HasAdditionalFeesDeductions1') && this.receipt.HasAdditionalFeesDeductions1 !== null ? this.receipt.HasAdditionalFeesDeductions1 : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasAdditionalFeesDeductions2: new DynamicLabel(
                'Has Additional Fees Deductions2',
                this.receipt && this.receipt.hasOwnProperty('HasAdditionalFeesDeductions2') && this.receipt.HasAdditionalFeesDeductions2 !== null ? this.receipt.HasAdditionalFeesDeductions2 : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasEnergySurchargePerTon: new DynamicLabel(
                'Has Energy Surcharge Per Ton',
                this.receipt && this.receipt.hasOwnProperty('HasEnergySurchargePerTon') && this.receipt.HasEnergySurchargePerTon !== null ? this.receipt.HasEnergySurchargePerTon : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasTradeDiscountPercent: new DynamicLabel(
                'Has Trade Discount Percent',
                this.receipt && this.receipt.hasOwnProperty('HasTradeDiscountPercent') && this.receipt.HasTradeDiscountPercent !== null ? this.receipt.HasTradeDiscountPercent : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasTruckingAllowancePerTon: new DynamicLabel(
                'Has Trucking Allowance Per Ton',
                this.receipt && this.receipt.hasOwnProperty('HasTruckingAllowancePerTon') && this.receipt.HasTruckingAllowancePerTon !== null ? this.receipt.HasTruckingAllowancePerTon : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Invoiced: new DynamicLabel(
                'Invoiced',
                this.receipt && this.receipt.hasOwnProperty('Invoiced') && this.receipt.Invoiced !== null ? this.receipt.Invoiced : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoicedDate: new DynamicLabel(
                'Invoiced Date',
                this.receipt && this.receipt.InvoicedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Note: new DynamicLabel(
                'Note',
                this.receipt && this.receipt.hasOwnProperty('Note') && this.receipt.Note !== null ? this.receipt.Note.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Number: new DynamicLabel(
                'Number',
                this.receipt && this.receipt.hasOwnProperty('Number') && this.receipt.Number !== null ? this.receipt.Number.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaymentNoteId: new DynamicLabel(
                'Payment Note',
                getMetaItemValue(this.paymentNotes, this.receipt && this.receipt.hasOwnProperty('PaymentNoteId') && this.receipt.PaymentNoteId !== null ? this.receipt.PaymentNoteId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SentToQuickbooks: new DynamicLabel(
                'Sent To Quickbooks',
                this.receipt && this.receipt.hasOwnProperty('SentToQuickbooks') && this.receipt.SentToQuickbooks !== null ? this.receipt.SentToQuickbooks : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SentToQuickbooksBy: new DynamicLabel(
                'Sent To Quickbooks By',
                this.receipt && this.receipt.SentToQuickbooksBy || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SentToQuickbooksDate: new DynamicLabel(
                'Sent To Quickbooks Date',
                this.receipt && this.receipt.SentToQuickbooksDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ShipmentId: new DynamicLabel(
                'Shipment',
                getMetaItemValue(this.shipments, this.receipt && this.receipt.hasOwnProperty('ShipmentId') && this.receipt.ShipmentId !== null ? this.receipt.ShipmentId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Stop: new DynamicLabel(
                'Stop',
                getMetaItemValue(this.stops, this.receipt && this.receipt.hasOwnProperty('Stop') && this.receipt.Stop !== null ? this.receipt.Stop : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TradeDiscountPercent: new DynamicLabel(
                'Trade Discount Percent',
                this.receipt && this.receipt.TradeDiscountPercent || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            TruckingAllowancePerTon: new DynamicLabel(
                'Trucking Allowance Per Ton',
                this.receipt && this.receipt.TruckingAllowancePerTon || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
        };

    }
}
