import { Component, OnInit } from '@angular/core';
import { Scales } from '../../model/Scales';

@Component({
    selector: 'app-rotary-slit',
    templateUrl: './rotary-slit.component.html',
})
export class RotarySlitComponent implements OnInit {
    scaleId = Scales.RotarySlit;

    constructor() {}

    ngOnInit(): void {}
}
