import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { common } from '@mt-ng2/common-functions';
import { IAddressContainer } from '@mt-ng2/dynamic-form';

import { CustomerPaymentTermService } from '../customerpaymentterm.service';
import { CustomerCreditStatusService } from '../customercreditstatus.service';
import { CustomerShipOnTypeService } from '../customershipontype.service';
import { ContactTypeService } from '../contacttype.service';
import { CustomerService } from '../customer.service';

import { CustomerDynamicControlsPartial } from '../../model/partials/customer.form-controls';
import { ContactPhoneDynamicControls } from '../../model/form-controls/contact-phone.form-controls';
import { CustomerPhoneDynamicControls } from '../../model/form-controls/customer-phone.form-controls';
import { ContactDynamicControls } from '../../model/form-controls/contact.form-controls';

import { IContactPhone } from '../../model/interfaces/contact-phone';
import { ICustomerPhone } from '../../model/interfaces/customer-phone';
import { IContact } from '../../model/interfaces/contact';
import { UserService } from '../../users/user.service';
import { IUser } from '../../model/interfaces/user';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ICustomerPaymentTerm } from '@model/interfaces/customer-payment-term';
import { RunnerDirectionTypeService } from '../../sales-orders/runnerdirectiontype.service';

@Component({
    selector: 'customer-add',
    styleUrls: ['./customer-add.component.less'],
    templateUrl: './customer-add.component.html',
})
export class CustomerAddComponent implements OnInit {
    doubleClickIsDisabled = false;
    formLoaded = false;
    addCustomerForm: FormGroup;
    salesPersonItems: any;
    customerPaymentTermItems: ICustomerPaymentTerm[];

    abstractCustomerControls: any;
    abstractContactControls: any;
    abstractContactPhoneControls: any;
    abstractCustomerPhoneControls: any;

    emptyAddressContainer: IAddressContainer = {
        Address: {
            Address1: '',
            Address2: '',
            City: '',
            CountryCode: 'US',
            Id: 0,
            Province: '',
            StateCode: 'AL',
            Zip: '',
        },
        AddressId: 0,
        IsPrimary: true,
    };

    private emptyContact: IContact = {
        Email: '',
        FirstName: '',
        Id: 0,
        LastName: '',
        Notes: '',
        Title: '',
        TypeId: 1,
    };

    private emptyContactPhone: IContactPhone = {
        ContactId: 0,
        Extension: '',
        IsPrimary: true,
        Phone: '',
        PhoneTypeId: 1,
    };

    private emptyCustomerPhone: ICustomerPhone = {
        CustomerId: 0,
        Extension: '',
        IsPrimary: true,
        Phone: '',
        PhoneTypeId: 1,
    };

    constructor(
        private customerPaymentTermService: CustomerPaymentTermService,
        private customerCreditStatusService: CustomerCreditStatusService,
        private customerShipOnTypeService: CustomerShipOnTypeService,
        private customerContactTypeService: ContactTypeService,
        private customerService: CustomerService,
        public notificationsService: NotificationsService,
        public router: Router,
        public formBuilder: FormBuilder,
        private userService: UserService,
        private runnerDirectionTypeService: RunnerDirectionTypeService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.customerPaymentTermService.getItems(),
            this.userService.getUserSalesPersons(),
            this.customerCreditStatusService.getItems(),
            this.customerShipOnTypeService.getItems(),
            this.customerContactTypeService.getItems(),
            this.runnerDirectionTypeService.getItems(),
        ]).subscribe(([customerPaymentTermItems, userSalesPersonItems]: [ICustomerPaymentTerm[], any]) => {
            this.customerPaymentTermItems = customerPaymentTermItems;
            this.salesPersonItems = userSalesPersonItems;
            this.createForm();
        });
    }

    phoneTypeClass(phoneTypeId: number): string {
        switch (phoneTypeId) {
            case 1:
                return 'fa-home';
            case 2:
                return 'fa-phone';
            case 3:
                return 'fa-mobile';
            case 4:
                return 'fa-fax';
            default:
                return '';
        }
    }

    getIconForCustomerPhone(): string {
        return this.phoneTypeClass(this.addCustomerForm.value.CustomerPhone.PhoneTypeId);
    }

    getIconForContactPhone(): string {
        return this.phoneTypeClass(this.addCustomerForm.value.ContactPhone.PhoneTypeId);
    }

    setCustomerPhoneType(phoneTypeId: number): void {
        this.addCustomerForm.patchValue({
            CustomerPhone: {
                PhoneTypeId: phoneTypeId,
            },
        });
    }

    setContactPhoneType(phoneTypeId: number): void {
        this.addCustomerForm.patchValue({
            ContactPhone: {
                PhoneTypeId: phoneTypeId,
            },
        });
    }

    createForm(): void {
        this.createAbstractControls();
        this.addCustomerForm = this.formBuilder.group({
            Contact: this.formBuilder.group({}),
            ContactPhone: this.formBuilder.group({
                ContactId: 0,
                Extension: '',
                IsPrimary: true,
                Phone: [''],
                PhoneTypeId: 2,
            }),
            Customer: this.formBuilder.group({}),
            CustomerAddress: this.formBuilder.group({}),
            CustomerPhone: this.formBuilder.group({
                CustomerId: 0,
                Extension: '',
                IsPrimary: true,
                Phone: ['', Validators.required],
                PhoneTypeId: 2,
            }),
        });
        this.formLoaded = true;
    }

    createAbstractControls(): void {
        const emptyCustomer = this.customerService.getEmptyCustomer();
        this.abstractCustomerControls = new CustomerDynamicControlsPartial(
            emptyCustomer,
            this.customerCreditStatusService.items,
            this.customerPaymentTermItems,
            this.mapSalesPersonItems(this.salesPersonItems),
            this.customerShipOnTypeService.items,
            this.runnerDirectionTypeService.items,
        );
        this.abstractContactControls = new ContactDynamicControls(this.emptyContact, {
            formGroup: 'Contact',
            types: this.customerContactTypeService.items,
        });
        this.abstractContactPhoneControls = new ContactPhoneDynamicControls(this.emptyContactPhone);
        this.abstractCustomerPhoneControls = new CustomerPhoneDynamicControls(this.emptyCustomerPhone);
        this.abstractCustomerControls = this.abstractCustomerControls.Form;
        this.abstractContactControls = this.abstractContactControls.Form;
        this.abstractCustomerPhoneControls = this.abstractCustomerPhoneControls.Form;
        this.abstractContactPhoneControls = this.abstractContactPhoneControls.Form;
    }

    mapSalesPersonItems(salespersons: IUser[]): any {
        return salespersons.map((sp) => {
            return {
                Id: sp.Id,
                Name: sp.FirstName + ' ' + sp.LastName,
            };
        });
    }

    formSubmitted(): void {
        if (this.addCustomerForm.valid) {
            this.customerService
                .createCustomerPayload(this.getCreateCustomerPayload())
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.router.navigate(['/customers/' + answer]);
                    this.success();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.addCustomerForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    phoneHasError(): boolean {
        const control = this.addCustomerForm.controls.CustomerPhone.get('Phone');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    contactPhoneHasError(): boolean {
        const control = this.addCustomerForm.controls.ContactPhone.get('Phone');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    getCreateCustomerPayload(): any {
        let value = this.addCustomerForm.value;
        return {
            Address: value.CustomerAddress,
            CompanyName: value.Customer.CompanyName,
            ContactPhone: value.ContactPhone.Phone ? value.ContactPhone : null,
            CreditStatusId: value.Customer.CreditStatusId,
            CustomerName: value.Customer.CustomerName,
            CustomerPhone: value.CustomerPhone,
            CustomerSpecifications: value.Customer.CustomerSpecifications,
            Email: value.Contact.Email,
            FirstName: value.Contact.FirstName,
            InvoiceEmail: value.Customer.InvoiceEmail,
            InvoiceName: value.Customer.InvoiceName,
            InvoiceNote: value.Customer.InvoiceNote,
            IsAuthorizedForCreditCard: value.Customer.IsAuthorizedForCreditCard,
            LastName: value.Contact.LastName,
            Notes: value.Contact.Notes,
            PaymentTermId: value.Customer.PaymentTermId,
            PrimarySalesPersonId: value.Customer.PrimarySalesPersonId,
            SecondarySalesPersonId: value.Customer.SecondarySalesPersonId,
            ShipOnTypeId: value.Customer.ShipOnTypeId,
            ShipVia: value.Customer.ShipVia,
            Title: value.Contact.Title,
            TypeId: value.Contact.TypeId,
        };
    }

    defaultInvoiceName(): void {
        let name = this.addCustomerForm.value.CustomerName;
        if (!this.addCustomerForm.value.InvoiceName) {
            this.addCustomerForm.patchValue({
                InvoiceName: name,
            });
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['/customers']);
    }

    error(): void {
        this.notificationsService.error('Customer Save Failed');
    }

    success(): void {
        this.notificationsService.success('Customer Saved Successfully');
    }
}
