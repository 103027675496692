import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { GroupConfigurationService } from '../groupconfiguration.service';
import { TrimViewDynamicControlsPartial } from '@model/partials/trim-sheet-trim-view-partial.form-controls.';
import { FractionsService } from '@common/services/fractions.service';
import { ITrimSheet } from '@model/interfaces/trim-sheet';
import { TrimSheetService } from '../trim-sheet.service';
import { IFormattedSalesOrder } from '@model/interfaces/custom/formatted-sales-order';
import { IGroupConfiguration } from '@model/interfaces/group-configuration';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { orderPathSlugs } from '@routes/order-paths';
import { DatePipe } from '@angular/common';
import { IRunningTrimSheetPayload } from '@model/interfaces/custom/running-trim-sheet-payload';
import { BacktenderService } from '../backtender.service';

@Component({
    selector: 'trim-view',
    styles: [
        `
            .trim-view-header {
                background-color: #ffff60;
                border: 2px solid black;
                border-radius: 25px;
                padding: 15px;
                color: maroon;
                font-weight: bold;
                font-size: xx-large;
                text-align: center;
                margin: auto;
            }
            .read-only {
                background-color: blue !important;
                color: white !important;
            }
            .group-configuration {
                color: green;
                font-weight: bold;
                font-size: large;
                text-align: center;
                position: relative;
                margin: auto;
                margin-top: 10px;
            }
            th,
            td {
                text-align: center;
            }
            .buttons {
                background-color: red;
                border: 1px solid black;
                border-radius: 5px;
            }
            .buttons button {
                margin: 15px;
            }
        `,
    ],
    templateUrl: './trim-view.component.html',
})
export class TrimViewComponent implements OnInit {
    trimViewForm: FormGroup;
    abstractTrimSheetControls: any;
    formCreated = false;
    trimSheet: ITrimSheet;
    counter: number;
    groupConfigurationItems: IGroupConfiguration[];
    title: string;
    readOnly: boolean;
    pendingTrimSheet: ITrimSheet;
    isViewOtherSetups: boolean;

    printModal: IModalWrapperApi;
    modalOptions: IModalOptions = {
        showCancelButton: false,
        showConfirmButton: false,
        width: 800,
    };

    get setupId(): number {
        return +this.trimViewForm?.value.TrimSheet.SetupId;
    }

    constructor(
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private groupConfigurationService: GroupConfigurationService,
        private fractionsService: FractionsService,
        private trimSheetService: TrimSheetService,
        private modalService: ModalService,
        private router: Router,
        private backtenderService: BacktenderService,
    ) {}

    ngOnInit(): void {
        if (this.router.url === `/${orderPathSlugs.currentProd}`) {
            this.readOnly = true;
            this.title = 'Current Production Backtender';
        } else {
            this.readOnly = false;
            this.title = 'Backtender';
        }
        forkJoin([this.trimSheetService.getRunningSetup(), this.groupConfigurationService.getItems()]).subscribe(
            ([trimSheet, groupConfigurationItems]) => {
                this.groupConfigurationItems = groupConfigurationItems;
                this.createForm();
                setTimeout(() => this.handleRunningTrimSheetPayload(trimSheet));
            },
        );
    }

    createForm(): void {
        this.abstractTrimSheetControls = new TrimViewDynamicControlsPartial(this.trimSheet, {
            formGroup: 'TrimSheet',
            groupConfigurations: this.groupConfigurationItems,
        });
        this.trimViewForm = this.fb.group({
            TrimSheet: this.fb.group({}),
        });
        if (this.readOnly) {
            this.trimViewForm.disable();
        }
    }

    calculateCounter(): void {
        let counter = 0;
        this.trimSheet?.TrimSheetDetails.forEach((tsd) => {
            if (tsd.OrderDetailLine && tsd.OrderDetailLine.Width && tsd.Sheets) {
                let decimalString = this.fractionsService.FormatAsDecimal(tsd.OrderDetailLine.Width);
                counter += tsd.Sheets * parseFloat(decimalString);
            }
        });
        this.counter = counter;
    }

    getTrimSheetBySetupId(): void {
        const setupId = this.setupId;

        if (!setupId || isNaN(setupId) || (this.trimSheet && this.trimSheet.SetupId === setupId)) {
            return;
        }

        this.startTargetSetup(setupId);
    }

    setupTrimSheet(): void {
        let trimGroup = <FormGroup>this.trimViewForm.controls.TrimSheet;
        if (this.pendingTrimSheet) {
            this.trimSheet = this.pendingTrimSheet;
            trimGroup.patchValue(this.trimSheet);
            this.pendingTrimSheet = null;
            this.calculateCounter();
        } else {
            this.trimSheet = null;
            trimGroup.reset();
            this.calculateCounter();
        }
    }

    private handleRunningTrimSheetPayload(runningTrimSheet: IRunningTrimSheetPayload): void {
        if (runningTrimSheet.HasError) {
            this.modalService
                .showModal({
                    text: runningTrimSheet.Message,
                    title: 'Error',
                })
                .subscribe();
        } else {
            this.notificationsService.success(runningTrimSheet.Message);
        }

        this.pendingTrimSheet = runningTrimSheet.TrimSheet;
        this.setupTrimSheet();
    }

    startNextSetup(): void {
        this.backtenderService.startNextSetup(this.trimSheet.SetupId).subscribe(this.handleRunningTrimSheetPayload.bind(this));
    }

    startTargetSetup(targetSetupId: number): void {
        this.backtenderService.startTargetSetup(this.trimSheet?.SetupId, targetSetupId).subscribe(this.handleRunningTrimSheetPayload.bind(this));
    }

    returnToCurrentSetup(): void {
        this.trimSheetService.getRunningSetup().subscribe(this.handleRunningTrimSheetPayload.bind(this));
    }

    chooseFromList(startSetup: boolean): void {
        this.trimSheetService.getPending().subscribe((trimSheets: ITrimSheet[]) => {
            let mappedOptions = {};
            trimSheets.forEach((sheet, i) => {
                let datepipe: DatePipe = new DatePipe('en-US');
                let date = new Date(sheet.WeekOf);
                let dateString = datepipe.transform(date.toUTCString(), 'yyyy/MM/dd', '+0000');
                mappedOptions[i] = 'SetupId: ' + sheet.SetupId + ', WeekOf: ' + datepipe.transform(dateString, 'EE, MMMM d, y');
            });
            this.modalService
                .showModal({
                    input: 'select',
                    inputOptions: mappedOptions,
                    inputPlaceholder: 'Select a Setup',
                    title: 'Choose Setup from List',
                })
                .subscribe((result) => {
                    // result.value represents the index of the selected set up
                    if (result.value) {
                        const selectedTrimSheet = trimSheets[result.value];
                        if (startSetup) {
                            this.startTargetSetup(selectedTrimSheet.SetupId);
                        } else {
                            this.pendingTrimSheet = selectedTrimSheet;
                            this.setupTrimSheet();
                        }
                    }
                });
        });
    }

    showPrintTags(): void {
        if (this.printModal) {
            this.printModal.show();
        }
    }

    closePrintTags(): void {
        this.printModal.close();
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    navigateToTrimSetupPreview(): void {
        window.open(`#/trim-setup-forecast`);
    }
}
