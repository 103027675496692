import { PalletInfoDynamicControls, IPalletInfoDynamicControlsParameters } from '../form-controls/pallet-info.form-controls';
import { IPalletInfo } from '../interfaces/pallet-info';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { IFractionsDynamicFieldOptions } from '@common/services/fractions.service';

export class PalletInfoDynamicControlsPartial extends PalletInfoDynamicControls {
    constructor(palletinfo?: IPalletInfo, extraParams?: IPalletInfoDynamicControlsParameters) {
        super(palletinfo, extraParams);

        const lengthOptions: IFractionsDynamicFieldOptions = { maxFactor: 64 };
        const widthOptions: IFractionsDynamicFieldOptions = { maxFactor: 64 };
        this.Form.PalletSizeLength.component = FractionsDynamicFieldComponent;
        this.Form.PalletSizeWidth.component = FractionsDynamicFieldComponent;
        this.Form.BoardSizeLength.component = FractionsDynamicFieldComponent;
        this.Form.BoardSizeWidth.component = FractionsDynamicFieldComponent;
        this.Form.CutSizeLength.component = FractionsDynamicFieldComponent;
        this.Form.CutSizeWidth.component = FractionsDynamicFieldComponent;
        this.Form.PalletSizeLength.customOptions = lengthOptions;
        this.Form.PalletSizeWidth.customOptions = widthOptions;
        this.Form.BoardSizeLength.customOptions = lengthOptions;
        this.Form.BoardSizeWidth.customOptions = widthOptions;
        this.Form.CutSizeLength.customOptions = lengthOptions;
        this.Form.CutSizeWidth.customOptions = widthOptions;

        this.Form.PalletSizeLength.hideLabel = true;
        this.Form.PalletSizeWidth.hideLabel = true;
        this.Form.BoardSizeLength.hideLabel = true;
        this.Form.BoardSizeWidth.hideLabel = true;
        this.Form.CutSizeLength.hideLabel = true;
        this.Form.CutSizeWidth.hideLabel = true;
        this.Form.PalletSizeLength.insideBoxValidation = true;
        this.Form.PalletSizeWidth.insideBoxValidation = true;
        this.Form.BoardSizeLength.insideBoxValidation = true;
        this.Form.BoardSizeWidth.insideBoxValidation = true;
        this.Form.CutSizeLength.insideBoxValidation = true;
        this.Form.CutSizeWidth.insideBoxValidation = true;

        this.Form.IsExactWidth.labelHtml = '<label>Exact Width</label>';
        this.Form.IsExactLength.labelHtml = '<label>Exact Length</label>';
    }
}
