import { IBoardGrade } from '@model/interfaces/board-grade';
import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { CustomPackagingDynamicControlsPartial } from '@model/partials/custom-packaging-partial.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

export class CustomPackagingDynamicConfig<T extends ICustomPackaging> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private customPackaging: T, private boardGrades?: IBoardGrade[], private configControls?: string[]) {
        super();
        boardGrades.unshift({Id: null, Name: ''} as IBoardGrade); // Push an empty item onto the array
        const dynamicControls = new CustomPackagingDynamicControlsPartial(this.customPackaging, {boardGrades: this.boardGrades});
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Width',
                'Length',
                'Height',
                'Caliper',
                'BoardGradeId',
                'MustCount',
                'Plus',
                'Minus',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
