<div>
    <label>Target {{ label }}: </label>
    <span>{{ getTargetValue() }}</span>
</div>
<div>
    <label>Actual {{ label }}:</label>
    <span *ngIf="!isOverridingActualValue"
        >{{ getActualValue() }} <i *ngIf="override"><b> (O)</b></i></span
    >
    <input *ngIf="isOverridingActualValue" type="number" [(ngModel)]="override" [ngModelOptions]="{ standalone: true }" />
</div>
<div>
    <button *ngIf="!isOverridingActualValue" class="btn btn-danger btn-override" (click)="toggleOverride()" [disabled]="disabled">Override</button>
    <button *ngIf="override && !isOverridingActualValue" class="btn btn-default btn-override" (click)="$event.preventDefault(); clearOverride()">
        Clear
    </button>
    <button *ngIf="isOverridingActualValue" [disabled]="!override" class="btn btn-success btn-override" (click)="saveOverride()">Save</button>
    <button *ngIf="isOverridingActualValue" class="btn btn-default btn-override" (click)="clearOverride(); toggleOverride()">Cancel</button>
</div>
