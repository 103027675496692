import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IExpandableObject } from '../expandable-object';
import { Validators } from '@angular/forms';

export interface ISalesOrderTrimSheetDynamicControlsPartialParameters {
    formGroup?: string;
}

export interface ISalesOrderTrimSheet {
    OrderNumber: string;
}

export class SalesOrderTrimSheetDynamicControlsPartial {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private salesorder?: ISalesOrderTrimSheet, additionalParameters?: ISalesOrderTrimSheetDynamicControlsPartialParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'SalesOrder';

        this.Form = {
            OrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Number',
                name: 'OrderNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                }),
                validation: [Validators.required],
                validators: {},
                value: (this.salesorder && this.salesorder.OrderNumber) || null,
            }),
        };

        this.View = {
            OrderNumber: new DynamicLabel({
                label: 'Order Number',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                }),
                value: (this.salesorder && this.salesorder.OrderNumber) || null,
            }),
        };
    }
}
