import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { FractionsService, IFractionsDynamicFieldOptions } from '@common/services/fractions.service';

@Directive({
    providers: [NgModel],
    selector: '[fractionField]',
})
export class FractionFieldDirective {
    @Input() fractionField: IFractionsDynamicFieldOptions;

    get control(): FormControl {
        return this.ngControl.control as FormControl;
    }

    constructor(private inputElement: ElementRef, private ngControl: NgControl, private fractionsService: FractionsService) {}

    @HostListener('focus')
    onFocus(): void {
        this.convertMeasurementToDecimal();
        this.inputElement?.nativeElement?.select();
    }

    @HostListener('blur')
    onBlur(): void {
        this.convertMeasurementToFraction();
    }

    convertMeasurementToFraction(): void {
        const control = this.control;
        let formValue: number;
        if (this.fractionsService.isValidFraction(control.value)) {
            formValue = +this.fractionsService.FormatAsDecimal(control.value);
        } else {
            formValue = +control.value;
        }

        if (!isNaN(formValue)) {
            control.setValue(
                this.fractionsService.FormatAsFraction('' + this.fractionsService.roundToNearestFraction(formValue, this.fractionField?.maxFactor)),
            );
        } else {
            control.setValue(0);
        }
    }

    convertMeasurementToDecimal(): void {
        const control = this.control;
        const value: string = control.value;
        if (value) {
            control.setValue(this.fractionsService.FormatAsDecimal(value));
        }
    }
}
