import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PrintService } from '../../common/services/print.service';
import { ReceiptService } from '../receipt.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-tons-and-pricing',
    templateUrl: './tons-and-pricing.component.html',
})
export class TonsAndPricingComponent implements OnInit {
    month: number;
    year: number;

    fromYear: number;
    toYear: number;

    doubleClickIsDisabled = false;

    constructor(private receiptService: ReceiptService) {
        let today = new Date();
        this.toYear = today.getFullYear();
        this.fromYear = this.toYear - 10;

        this.month = today.getMonth() + 1;
        this.year = this.toYear;
    }

    ngOnInit(): void {}

    printSummary(): void {
        this.receiptService
            .getTonsAndPricingSummaryReport(this.month, this.year)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }

    printDetail(): void {
        this.receiptService
            .getTonsAndPricingDetailReport(this.month, this.year)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
