import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { IDailyRunEntry } from '@model/interfaces/daily-run-entry';
import { IDailyRunSalesOrder } from '@model/interfaces/daily-run-sales-order';
import { IDailyRunLostTime } from '@model/interfaces/daily-run-lost-time';

export const emptyDailyRunEntry: IDailyRunEntry = {
    Backtender: '',
    Banders: '',
    Breaks: 0,
    Finishers: '',
    Hoppers: 0,
    Id: 0,
    RunDate: new Date(),
    Setups: 0,
    ShiftCode: '',
    SuperStock: 0,
    ThirdHandValues: '',
    TourBoss: '',
};

export const emptyDailyRunSalesOrder: IDailyRunSalesOrder = {
    Basis: 0,
    BoardGrade: '',
    Bundles: 0,
    Caliper: 0,
    CustomerName: '',
    DailyRunEntryId: 0,
    Detail: '',
    Id: 0,
    IsFortyPoundBundle: false,
    IsRunSheetStock: false,
    SalesOrderId: 0,
};

export const emptyDailyRunLostTime: IDailyRunLostTime = {
    DailyRunEntryId: 0,
    Down: '',
    Id: 0,
    Reason: '',
    Up: '',
};

@Injectable({
    providedIn: 'root',
})
export class DailyRunEntryService extends BaseService<IDailyRunEntry> {

    constructor(public http: HttpClient) {
        super('/daily-run-entries', http);
    }

    getEmptydailyRunEntry(): IDailyRunEntry {
        return { ...emptyDailyRunEntry };
    }

    getEmptyDailyRunSalesOrder(): IDailyRunSalesOrder {
        return {...emptyDailyRunSalesOrder};
    }

    getEmptyDailyRunLostTime(): IDailyRunLostTime {
        return {...emptyDailyRunLostTime};
    }

    saveForm(dailyRunEntry: IDailyRunEntry):
    Observable<number> {
        return this.http.put<number>(`/daily-run-entries/save-form`, dailyRunEntry);
    }

    getByRunDateAndShift(runDate: Date, shift: string): Observable<IDailyRunEntry> {
        const params = new HttpParams()
            .set('runDate', runDate.toISOString())
            .set('shift', shift);
        return this.http.get<IDailyRunEntry>(`/daily-run-entries/by-run-date-and-shift`, { params });
    }

    deleteDailyRun(runDate: Date, shift: string): Observable<void> {
        const params = new HttpParams()
            .set('runDate', runDate.toISOString())
            .set('shift', shift);

        return this.http.delete<void>(`/daily-run-entries/delete`, { params });
    }

}
