import { Component, OnInit } from '@angular/core';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../customer.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { IBoardGrade } from '../../model/interfaces/board-grade';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CustomerPricingItemDynamicControlsPartial } from '../../model/partials/customer-pricing-item.form-controls';
import { ICustomerPricingItem } from '../../model/interfaces/customer-pricing-item';
import { common } from '@mt-ng2/common-functions';
import { tap } from 'rxjs/operators';
import { BoardGradeService } from '../../sales-orders/board-grade.service';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'customer-pricing',
    templateUrl: './customer-pricing.component.html',
})
export class CustomerPricingComponent implements OnInit {
    customer: ICustomer;
    customerId: number;
    boardGrades: IBoardGrade[];
    customerPricingForm: FormGroup;
    abstractControls: CustomerPricingItemDynamicControlsPartial[] = [];
    formCreated = false;
    canEditCustomerPricing: boolean;

    sortCustomerPricingItemsByDate = function (a: ICustomerPricingItem, b: ICustomerPricingItem): number {
        return new Date(a.DateEffective) < new Date(b.DateEffective) ? 1 : -1; // sorting customer pricing items by Id to get the most recent record
    };

    getPricingItems(i: number): FormGroup {
        return (this.customerPricingForm.controls.pricingItems as FormArray).controls[i] as FormGroup;
    }

    constructor(
        private fb: FormBuilder,
        private customerService: CustomerService,
        private boardGradeService: BoardGradeService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        // get current id from route
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId');
        this.canEditCustomerPricing = this.claimsService.hasClaim(ClaimTypes.Customers_CanEditCustomerPricing, [ClaimValues.FullAccess]);

        forkJoin(this.getCustomerById(this.customerId), this.getBoardGrades()).subscribe(() => {
            this.createForm();
        });
    }

    getCustomerById(id: number): Observable<ICustomer> {
        return this.customerService.getById(id).pipe(
            tap((answer) => {
                this.customer = answer;
            }),
        );
    }

    getBoardGrades(): Observable<IBoardGrade[]> {
        return this.boardGradeService.getAll().pipe(
            tap((answer) => {
                this.boardGrades = answer;
            }),
        );
    }

    createForm(): void {
        this.customerPricingForm = this.fb.group({});
        const pricingItemGroups = this.boardGrades.map((bg) => {
            let customerPricingItem: ICustomerPricingItem;
            if (!this.customer.CustomerPricingItems.find((cpi) => cpi.BoardGrade.Id === bg.Id)) {
                customerPricingItem = {
                    BoardGrade: bg,
                    BoardGradeId: bg.Id,
                    Customer: this.customer,
                    CustomerId: this.customerId,
                    DateEffective: null,
                    Id: 0,
                    Notes: null,
                    Price: null,
                };
            } else {
                customerPricingItem = this.customer.CustomerPricingItems.sort(this.sortCustomerPricingItemsByDate).find(
                    (cpi) => cpi.BoardGrade.Id === bg.Id,
                );
            }
            this.addAbstractFormControl(customerPricingItem);
            return this.fb.group(customerPricingItem);
        });
        const pricingArray = this.fb.array(pricingItemGroups);
        this.customerPricingForm.setControl('pricingItems', pricingArray);
        this.formCreated = true;
    }

    addAbstractFormControl(customerPricingItem: ICustomerPricingItem): void {
        const formControl = new CustomerPricingItemDynamicControlsPartial(customerPricingItem);
        this.abstractControls.push(formControl);
    }

    setToday(i: number): void {
        let pricingItemGroups = <FormArray>this.customerPricingForm.get('pricingItems');
        let today = new Date();
        today.setHours(0, 0, 0);
        pricingItemGroups.controls[i].patchValue({ DateEffective: today });
    }

    savePricingItems(): void {
        const customerPricingItemsToSave: ICustomerPricingItem[] = [];
        const pricingData = <FormArray>this.customerPricingForm.get('pricingItems');
        let formInvalid = false;
        pricingData.controls.forEach((control: FormGroup) => {
            if (control.dirty) {
                if (control.valid) {
                    customerPricingItemsToSave.push(control.value);
                } else {
                    common.markAllFormFieldsAsTouched(control);
                    this.notificationsService.error('Customer Pricing Information Save Failed');
                    formInvalid = true;
                }
            } else if (control.valid) {
                customerPricingItemsToSave.push(control.value);
            }
        });
        if (customerPricingItemsToSave.length && !formInvalid) {
            this.customerService.updateCustomerPricingItems(customerPricingItemsToSave).subscribe((answer) => {
                this.notificationsService.success('Customer Pricing Information Saved Successfully');
                this.assignNewIdsToFormControl(answer);
            });
        } else if (!formInvalid) {
            this.notificationsService.error('Nothing to Save');
        }
    }

    assignNewIdsToFormControl(listOfIds: any[]): void {
        const pricingItemArray = <FormArray>this.customerPricingForm.get('pricingItems');
        listOfIds.forEach((IdObject: any) => {
            const boardGradeGroup = pricingItemArray.controls.find((group) => group.value.BoardGradeId === IdObject.BoardGradeId);
            boardGradeGroup.patchValue({ Id: IdObject.Id });
            this.abstractControls.find((ac) => ac.customerPricingItemPartial.BoardGradeId === IdObject.BoardGradeId).customerPricingItemPartial.Id =
                IdObject.Id;
        });
    }
}
