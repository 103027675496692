import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ScannersService {
    constructor(public http: HttpClient) {}

    getSheetCount(scannerId: number, refNum: string, caliper: number, height: number, ply: number): Observable<number> {
        let params = new HttpParams();
        params = params.append('refNum', refNum);
        params = params.append('caliper', caliper.toString());
        params = params.append('height', height.toString());
        params = params.append('ply', ply.toString());
        return this.http.get<number>(`/scanners/${scannerId}/scan`, { params: params });
    }
}
