import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgModel } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { debounceTime, finalize, switchMap } from 'rxjs/operators';
import { DEFAULT_POUNDS_PER_BUNDLE, ReceiptService } from '../receipt.service';
import { IReceipt } from '@model/interfaces/receipt';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { ICustomer } from '@model/interfaces/customer';
import { ReceiptDetailsEntityListConfig } from './receipt-details.entity-list-config';
import { IExpandableObject } from '@model/expandable-object';
import { ReceiptDynamicControlsPartial } from '@model/partials/receipt-partial.form-controls';
import { LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { Router } from '@angular/router';
import { ModalService } from '@mt-ng2/modal-module';
import { PrintService } from '@common/services/print.service';
import { IReceiptDetail } from '@model/interfaces/receipt-detail';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { OrderTypeIds } from '@model/OrderTypes';

const POUNDS_PER_TON = 2000;

@Component({
    selector: 'billing-sheet-adjustments',
    styleUrls: ['./billing-sheet-adjustments.component.less'],
    templateUrl: 'billing-sheet-adjustments.component.html',
})
export class BillingSheetAdjustments implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('receiptNumberInput') receiptNumberInput: NgModel;

    receiptNumber: string;
    receiptDetailsEntityListConfig = new ReceiptDetailsEntityListConfig(this.receiptService);
    form: FormGroup;
    formControls: IExpandableObject;
    isPrinting: boolean;

    receipt: IReceipt;
    receiptDetails: IReceiptDetail[];
    customer: ICustomer;
    shippingAddress: ICustomerShippingAddress;
    netWeight: number;
    grossTotalInvoice: number;
    netTotalInvoice: number;
    tradeDiscount: number;
    truckingAllowance: number;
    energySurcharge: number;
    additionalFeeDeduction1: number;
    additionalFeeDeduction2: number;

    generalLedgerCodes: IGeneralLedgerCode[];
    tonsAndPricingName1 = '';
    tonsAndPricingName2 = '';

    $subscriptions = new Subscription();
    $formSubscription: Subscription;

    constructor(
        private receiptService: ReceiptService,
        private notificationsService: NotificationsService,
        private router: Router,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.receiptService.getGeneralLedgerCodes()
            .subscribe((generalLedgerCodes) => {
                this.generalLedgerCodes = generalLedgerCodes;
            });
    }

    ngAfterViewInit(): void {
        this.$subscriptions.add(
            this.receiptNumberInput.valueChanges.pipe(debounceTime(600)).subscribe((value) => {
                this.clearReceipt();
                this.loadReceipt(value);
            }),
        );
    }

    ngOnDestroy(): void {
        this.$subscriptions.unsubscribe();
        if (this.$formSubscription) {
            this.$formSubscription.unsubscribe();
        }
    }

    clearForm(): void {
        if (this.$formSubscription) {
            this.$formSubscription.unsubscribe();
            this.form = null;
        }
    }

    clearReceipt(): void {
        this.clearForm();
        this.receipt = null;
        this.receiptDetails = null;
        this.customer = null;
        this.shippingAddress = null;
        this.netWeight = null;
        this.grossTotalInvoice = null;
        this.netTotalInvoice = null;
        this.tradeDiscount = null;
        this.truckingAllowance = null;
        this.energySurcharge = null;
        this.additionalFeeDeduction1 = null;
        this.additionalFeeDeduction2 = null;
    }

    loadReceipt(receiptNumber: string, isReload = false): void {
        if (receiptNumber) {
            forkJoin([this.receiptService.getByReceiptNumber(receiptNumber), this.receiptService.getDetailsByReceiptNumber(receiptNumber)]).subscribe(
                ([receipt, receiptDetails]) => {
                    if (receipt) {
                        this.receipt = receipt;
                        this.receiptDetails = receiptDetails;
                        this.customer = receipt.ShipmentStop.CustomerShippingAddress.Customer;
                        this.shippingAddress = receipt.ShipmentStop.CustomerShippingAddress;
                        this.netWeight = this.receipt.ReceiptDetails.reduce((sum, rd) => sum + rd.Weight, 0);
                        this.grossTotalInvoice = this.getGrossTotalInvoice();
                        if (!isReload) {
                            this.setFormControls();
                        }
                    } else {
                        this.notificationsService.error('Receipt not found');
                    }
                },
            );
        }
    }

    setFormControls(): void {
        const form = new FormGroup({ Receipt: new FormGroup({}) });
        this.$formSubscription = form.valueChanges.subscribe((value) => this.formValueChanges(value.Receipt));

        const controls = new ReceiptDynamicControlsPartial(this.receipt).Form;
        controls.TradeDiscountPercent.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.TradeDiscountPercent.insideBoxValidation = true;
        controls.TruckingAllowancePerTon.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.TruckingAllowancePerTon.insideBoxValidation = true;
        controls.EnergySurchargePerTon.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.EnergySurchargePerTon.insideBoxValidation = true;
        controls.AdditionalFeesDeductions1.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductions1.insideBoxValidation = true;
        controls.AdditionalFeesDeductionsName1.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductionsName1.insideBoxValidation = true;
        controls.AdditionalFeesDeductionsGlCode1.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductionsGlCode1.insideBoxValidation = true;
        controls.AdditionalFeesDeductions2.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductions2.insideBoxValidation = true;
        controls.AdditionalFeesDeductionsName2.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductionsName2.insideBoxValidation = true;
        controls.AdditionalFeesDeductionsGlCode2.labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        controls.AdditionalFeesDeductionsGlCode2.insideBoxValidation = true;

        this.formControls = controls;
        setTimeout(() => (this.form = form));
    }

    updateTotals(): void {
        let values = this.form.value.Receipt as IReceipt;
        let total = this.grossTotalInvoice;

        if (values.HasTradeDiscountPercent) {
            total += this.tradeDiscount;
        }
        if (values.HasTruckingAllowancePerTon) {
            total += this.truckingAllowance;
        }
        if (values.HasEnergySurchargePerTon) {
            total += this.energySurcharge;
        }
        if (values.HasAdditionalFeesDeductions1) {
            total += this.additionalFeeDeduction1;
        }
        if (values.HasAdditionalFeesDeductions2) {
            total += this.additionalFeeDeduction2;
        }

        this.netTotalInvoice = total;
    }

    formValueChanges(value: IReceipt): void {
        const round = (value, places = 2) => Math.round((value + Number.EPSILON) * Math.pow(10, places)) / Math.pow(10, places);
        this.tradeDiscount = -round((value.TradeDiscountPercent * this.grossTotalInvoice) / 100);
        this.truckingAllowance = -round((value.TruckingAllowancePerTon * this.netWeight) / POUNDS_PER_TON);
        this.energySurcharge = round((value.EnergySurchargePerTon * this.netWeight) / POUNDS_PER_TON);
        this.additionalFeeDeduction1 = value.AdditionalFeesDeductions1;
        this.additionalFeeDeduction2 = value.AdditionalFeesDeductions2;
        this.updateTotals();

        this.showTonsAndPricingNames(value);
    }

    private showTonsAndPricingNames(receipt: IReceipt): void {
        if (receipt.AdditionalFeesDeductionsGlCode1) {
            this.tonsAndPricingName1 = this.getTonsAndPricingName(receipt.AdditionalFeesDeductionsGlCode1);
        } else {
            this.tonsAndPricingName1 = '';
        }

        if (receipt.AdditionalFeesDeductionsGlCode2) {
            this.tonsAndPricingName2 = this.getTonsAndPricingName(receipt.AdditionalFeesDeductionsGlCode2);
        } else {
            this.tonsAndPricingName2 = '';
        }
    }

    private getTonsAndPricingName(glCode: string): string {
        return this.generalLedgerCodes
                ?.find((glc) => glc.Code === glCode)
                ?.Name
                ?? 'No Such General Ledger Code';
    }

    getGrossTotalInvoice(): number {
        return this.receiptDetails.reduce((total, rd) => {
            if (rd.SalesOrder != null && rd.SalesOrder.OrderTypeId === OrderTypeIds.Stock) {
                if (rd.StockOrderLine != null && rd.StockOrderLine.StockItem != null) {
                    const units = this.receiptService.getStockQuantity(rd);
                    const pricePer = rd.PricePer ?? 0;
                    const grossTotal = +(units * pricePer).toFixed(2);

                    return total + grossTotal;
                }
            } else {
                const units = rd.Units;
                const pricePer = rd.PricePer ?? 0;
                const grossTotal = units * pricePer;
                return total + grossTotal;
            }
            return total;
        }, 0);
    }

    print(): void {
        this.modalService
            .showModal({
                showCancelButton: true,
                title: `Are you sure you want to process this invoice?`,
                type: 'warning',
            })
            .subscribe((result) => {
                if (!result.value) {
                    setTimeout(() => (this.isPrinting = false));
                    return;
                }

                this.saveAndPrint();
            });
    }

    saveAndPrint(): void {
        Object.assign(this.receipt, this.form.value.Receipt);

        this.receiptService
            .update(this.receipt)
            .pipe(
                switchMap(() => this.receiptService.printReceipts([this.receipt.Id], true)),
                finalize(() => (this.isPrinting = false)),
            )
            .subscribe((pdfBase64) => {
                this.notificationsService.success(`Invoice successfully processed.`);
                PrintService.printPdf(pdfBase64);
                this.receiptNumberInput.control.setValue('', { emitEvent: false });
                this.clearReceipt();
            });
    }

    exit(): void {
        void this.router.navigate(['receipts', 'invoicing']);
    }
}
