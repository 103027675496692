<div *ngIf="!InputValues.length">
    <div class="row col-md-12" *ngFor="let group of OrderDetailLines.controls; let i = index">
        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateBundlesPerSkid(i); calculateHeight(i)"
                                [field]="abstractOrderDetailControls.Form.Quantity"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="checkIfCutter(i)"
                                [field]="abstractOrderDetailControls.Form.Width"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="checkIfCutter(i)"
                                [field]="abstractOrderDetailControls.Form.Length"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <span class="control-label"
                                ><label>Basis</label><span class="errortext ng-star-inserted" style="font-weight: 100"> *</span></span
                            >
                            <input
                                [disabled]="formIsLocked"
                                class="form-control"
                                type="number"
                                step="0.1"
                                [(ngModel)]="formattedBasis[i]"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="parseBasisFormatting($event, i)"
                            />
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateHeight(i)"
                                [field]="abstractOrderDetailControls.Form.Count"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <span class="control-label"
                                ><label>Caliper</label><span class="errortext ng-star-inserted" style="font-weight: 100"> *</span></span
                            >
                            <input
                                [disabled]="formIsLocked"
                                class="form-control"
                                type="number"
                                step="0.001"
                                [(ngModel)]="formattedCalipers[i]"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="parseCaliperFormatting($event, i);checkIfPaster(i)"
                            />
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.RuleNumber"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.Ply"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateHeight(i)"
                                [field]="abstractOrderDetailControls.Form.Piles"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateBundlesPerSkid(i); calculateHeight(i)"
                                [field]="abstractOrderDetailControls.Form.NumberOfSkids"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.BdlSk"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.Height"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.IsCutter"
                            ></dynamic-field>
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.IsPaster"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.PileStainWhiteSide"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [overrideForm]="true"
                                [form]="OrderDetailLines.controls[i]"
                                [field]="abstractOrderDetailControls.Form.RunnerDirectionTypeId"
                            ></dynamic-field>
                        </td>
                        <td class="removeButton nopadding">
                            <button type="button" *ngIf="!formIsLocked" class="btn btn-primary btn-fab-md" (click)="deleteOrderDetailLine(i)">
                                <i class="fa fa-fw fa-minus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row col-md-12 fab-wrap">
        <button
            type="button"
            *ngIf="OrderDetailLines.length < 4 && !formIsLocked"
            class="btn btn-primary btn-fab-md btn-fab-center"
            (click)="addRow()"
        >
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
<div *ngIf="InputValues.length">
    <div class="row col-md-12" *ngFor="let line of abstractOrderDetailControlsFromValues; let i = index">
        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateBundlesPerSkid(i); calculateHeight(i)"
                                [overrideForm]="true"
                                [field]="line.Form.Quantity"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="checkIfCutter(i)"
                                [overrideForm]="true"
                                [field]="line.Form.Width"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="checkIfCutter(i)"
                                [overrideForm]="true"
                                [field]="line.Form.Length"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <span class="control-label"
                                ><label>Basis</label><span class="errortext ng-star-inserted" style="font-weight: 100"> *</span></span
                            >
                            <input
                                [disabled]="formIsLocked"
                                class="form-control"
                                type="number"
                                step="0.1"
                                [(ngModel)]="formattedBasis[i]"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="parseBasisFormatting($event, i)"
                            />
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateHeight(i)"
                                [overrideForm]="true"
                                [field]="line.Form.Count"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <span class="control-label"
                                ><label>Caliper</label><span class="errortext ng-star-inserted" style="font-weight: 100"> *</span></span
                            >
                            <input
                                [disabled]="formIsLocked"
                                class="form-control"
                                type="number"
                                step="0.001"
                                [(ngModel)]="formattedCalipers[i]"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="parseCaliperFormatting($event, i);checkIfPaster(i)"
                            />
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.RuleNumber"></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.Ply"></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateHeight(i)"
                                [overrideForm]="true"
                                [field]="line.Form.Piles"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                (blur)="calculateBundlesPerSkid(i)"
                                [overrideForm]="true"
                                [field]="line.Form.NumberOfSkids"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.BdlSk"></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                [overrideForm]="true"
                                [field]="line.Form.Height"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.IsCutter"></dynamic-field>
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.IsPaster"></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field
                                [form]="OrderDetailLines.controls[i]"
                                [overrideForm]="true"
                                [field]="line.Form.PileStainWhiteSide"
                            ></dynamic-field>
                        </td>
                        <td class="horizontalSpacing">
                            <dynamic-field [form]="OrderDetailLines.controls[i]" [overrideForm]="true" [field]="line.Form.RunnerDirectionTypeId"></dynamic-field>
                        </td>
                        <td class="removeButton nopadding">
                            <button type="button" *ngIf="!formIsLocked" class="btn btn-primary btn-fab-md" (click)="deleteOrderDetailLine(i)">
                                <i class="fa fa-fw fa-minus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div>
        <div class="row col-md-12 fab-wrap">
            <button
                type="button"
                *ngIf="OrderDetailLines.length < 4 && !formIsLocked"
                class="btn btn-primary btn-fab-md btn-fab-center"
                (click)="addRow()"
            >
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
</div>
