<div class="row">
    <div class="col-md-7">
        <h2>Load Planning</h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)" #searchBar></mt-search-bar>
    <mt-search-filter-daterange entity="Date Created"
        (onSelectionChanged)="dateSelectionChanged($event, 'dateCreatedStart', 'dateCreatedEnd')"></mt-search-filter-daterange>
    <mt-search-filter-daterange entity="Shipment Date"
        (onSelectionChanged)="dateSelectionChanged($event, 'shipmentDateStart', 'shipmentDateEnd')"></mt-search-filter-daterange>
    <mt-search-filter-daterange entity="Open Date"
        (onSelectionChanged)="dateSelectionChanged($event, 'openDateStart', 'openDateEnd')"></mt-search-filter-daterange>
    <mt-search-filter-daterange entity="Close Date"
        (onSelectionChanged)="dateSelectionChanged($event, 'closeDateStart', 'closeDateEnd')"></mt-search-filter-daterange>
    <mt-search-filter-select *ngIf="shipmentStatusItems" [items]="shipmentStatusItems" entity="Status"
        (onSelectionChanged)="statusSelectionChanged($event)"></mt-search-filter-select>
</div>

<table class="table table-hover table-striped m-b-0">
    <thead>
        <tr class="no-pointer">
            <th>Load Id</th>
            <th>Note</th>
            <th class="text-center">Pickup</th>
            <th>Date Created</th>
            <th>Shipment Date</th>
            <th>Opened Date</th>
            <th>Closed Date</th>
            <th>Stops</th>
            <th>Skids</th>
            <th>Status</th>
            <th class="text-center">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let shipment of shipments">
            <td (click)="showShipmentDetails(shipment)">{{ shipment.Id }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ shipment.Note }}</td>
            <td (click)="showShipmentDetails(shipment)" class="text-center">
                <i class="fa fa-fw" [ngClass]="{ 'fa-check': shipment.IsPickup }"></i>
            </td>
            <td (click)="showShipmentDetails(shipment)">{{ reformatDate(shipment.DateCreated, 'MM/dd/yyyy') }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ reformatDate(shipment.ShipmentDate, 'MM/dd/yyyy') }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ shipment.OpenedDate | date: 'MM/dd/yyyy hh:mm aa' }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ shipment.ClosedDate | date: 'MM/dd/yyyy hh:mm aa' }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ shipment.ShipmentStops?.length }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ getNumberOfSkids(shipment) }}</td>
            <td (click)="showShipmentDetails(shipment)">{{ shipment.ShipmentStatus?.Name }}</td>
            <td class="text-center">
                <i class="fa fa-fw fa-lg fa-cog pointer" [contextMenu]="shipmentContextMenu"
                    (click)="openContextMenu($event, shipment); $event.preventDefault(); $event.stopPropagation()"></i>
            </td>
        </tr>
        <tr *ngIf="!shipments || !shipments.length">
            <td colspan="11">
                <h3 class="text-center">No data to display.</h3>
            </td>
        </tr>
    </tbody>
</table>

<br />

<div class="row">
    <div class="col-md-9 pagination">
        <ngb-pagination [collectionSize]="total" [pageSize]="pageSize" [(page)]="currentPage" (pageChange)="bindGrid()"
            *ngIf="shipments && shipments.length"></ngb-pagination>
    </div>
    <div class="col-md-3">
        <button class="btn btn-primary btn-select-shipment" (click)="setShipmentDate()">
            Add Loads for Date
        </button>
    </div>
</div>

<shipment-schedule></shipment-schedule>

<div *ngIf="canAddLoad" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/shipments', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>

<context-menu #shipmentContextMenu>
    <ng-template contextMenuItem (execute)="editLoad($event.item)"> Edit Load </ng-template>
    <ng-template contextMenuItem (execute)="cancelLoad($event.item)"> Cancel Load </ng-template>
</context-menu>

<modal-wrapper [options]="shipmentDetailModalOptions" title="Load Details" [autoShow]="false"
    (ready)="shipmentDetailModal = $event">
    <shipment-skid [shipment]="selectedShipment"></shipment-skid>
</modal-wrapper>

<modal-wrapper [options]="selectShipmentDateModalOptions" title="Select Shipment Date" [autoShow]="false"
    (ready)="selectShipmentDateModal = $event">
    <select-shipment-date (onShipmentDateUpdated)="onShipmentDateUpdated($event)"></select-shipment-date>
</modal-wrapper>