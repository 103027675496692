import {
    IEntityListConfig,
    IEntityListColumn,
    EntityListColumn,
    EntityListConfig,
    IEntityListSelectColumn,
    SelectTypes,
} from '@mt-ng2/entity-list-module';
import { Injectable } from '@angular/core';
import { IQuickbooksQueueReceipt } from '../receipt.service';

@Injectable()
export class QuickbooksQueueEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['ReceiptNumber'],
                    name: 'Number',
                }),
                new EntityListColumn({
                    accessors: ['InvoicedDate'],
                    name: 'Invoiced Date',
                    pipes: ['date:\'MM/dd/yyyy\''],
                }),
                new EntityListColumn({
                    accessorFunction: function (item: IQuickbooksQueueReceipt): string {
                        return item.OrderNumbers.join(', ');
                    },
                    name: 'Order Numbers',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            select: <IEntityListSelectColumn>{
                type: SelectTypes.Multi,
                uniqueId: 'ReceiptId',
                width: 40,
            },
        };
        super(listConfig);
    }
}
