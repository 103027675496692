export enum RunnerDirectionTypeNames {
    ShortWay = 'Short Way',
    LongWay = 'Long Way',
    FourWayEntry = '4 Way Entry',
    Junk = 'Junk',
}

export enum RunnerDirectionTypeIds {
    ShortWay = 1,
    LongWay = 2,
    FourWayEntry = 3,
    Junk = 4,
}
