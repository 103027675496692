import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ScalesService {
    constructor(public http: HttpClient) {}

    getWeight(scaleId: number): Observable<number> {
        return this.http.get<number>(`/scales/${scaleId}/weight`);
    }
}
