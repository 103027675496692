import { ClaimTypes } from '../model/ClaimTypes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { PackagingLineScannerComponent } from './packaging-line-scanner/packaging-line-scanner.component';
import { StockSkidProcessingComponent } from './stock-processing/stock-skid-processing.component';
import { CuttingComponent } from './cutting/cutting.component';
import { PastingComponent } from './pasting/pasting.component';
import { LiningComponent } from './lining/lining.component';
import { RotarySlitComponent } from './rotary-slit/rotary-slit.component';
import { SkidsComponent } from './skid-list/skids.component';
import { PrintTagComponent } from './print-tag/print-tag.component';
import { CreateSkidComponent } from './create/create.component';
import { PrintBacktenderComponent } from './print-backtender/print-backtender.component';

const skidEntityConfig: any = {
    backtenderBarcodeParam: 'backtenderBarcode',
    claimType: ClaimTypes.MillOperations_PackagingLines,
    claimValues: [ClaimValues.FullAccess],
};

class RoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimType, public claimValues) {}
}

const orderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CreateSkidComponent,
        data: new RoleGuard('Skid Data Maintenance', ClaimTypes.Shipments_SkidDataMaintenance, [ClaimValues.FullAccess]),
        path: 'skids/create',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: 'packaging-line/ai',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: `packaging-line/ai/:${skidEntityConfig.backtenderBarcodeParam}`,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: 'packaging-line/drj',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: `packaging-line/drj/:${skidEntityConfig.backtenderBarcodeParam}`,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: 'packaging-line/dry-end',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PackagingLineScannerComponent,
        data: new RoleGuard('Packaging Line Scanner', ClaimTypes.MillOperations_PackagingLines, [ClaimValues.FullAccess]),
        path: `packaging-line/dry-end/:${skidEntityConfig.backtenderBarcodeParam}`,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: StockSkidProcessingComponent,
        data: new RoleGuard('Stock Skid Processing', ClaimTypes.MillOperations_StockSkids, [ClaimValues.FullAccess]),
        path: 'stock-skid-processing',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: CuttingComponent,
        data: new RoleGuard('Cutting', ClaimTypes.MillOperations_ConvertingLines, [ClaimValues.FullAccess]),
        path: 'cutting',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PastingComponent,
        data: new RoleGuard('Pasting', ClaimTypes.MillOperations_ConvertingLines, [ClaimValues.FullAccess]),
        path: 'pasting',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: LiningComponent,
        data: new RoleGuard('Lining', ClaimTypes.MillOperations_ConvertingLines, [ClaimValues.FullAccess]),
        path: 'lining',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: RotarySlitComponent,
        data: new RoleGuard('Rotary Slit', ClaimTypes.MillOperations_ConvertingLines, [ClaimValues.FullAccess]),
        path: 'rotary-slit',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: SkidsComponent,
        data: new RoleGuard('Processed Skids', ClaimTypes.Shipments_ProcessedSkids, [ClaimValues.FullAccess]),
        path: 'processed',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: SkidsComponent,
        data: new RoleGuard('Processed Skids', ClaimTypes.Shipments_ProcessedSkids, [ClaimValues.FullAccess]),
        path: 'processed/:salesOrderId',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PrintTagComponent,
        data: new RoleGuard('Print Tag', ClaimTypes.Shipments_PrintTag, [ClaimValues.FullAccess]),
        path: 'print-tag',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PrintBacktenderComponent,
        data: new RoleGuard('Print Backtender', ClaimTypes.MillOperations_PrintBacktenderTag, [ClaimValues.FullAccess]),
        path: 'print-backtender-tag',
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(orderRoutes)],
})
export class SkidRoutingModule {}
