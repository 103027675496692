import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TrailerDynamicControls } from '../../model/form-controls/trailer.form-controls';
import { TrailerService } from '../trailer.service';
import { ITrailer } from '../../model/interfaces/trailer';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'trailer-info',
    templateUrl: './trailer-info.component.html',
})
export class TrailerInfoComponent implements OnInit {
    // abstract controls
    trailerId: number;
    trailer: ITrailer;
    abstractTrailerControls: any;
    isAdding: boolean;

    trailerInfoForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private trailerService: TrailerService,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.trailerId = +this.activatedRoute.snapshot.paramMap.get('trailerId');
        this.trailerId = isNaN(this.trailerId) ? 0 : this.trailerId;
        this.isAdding = this.trailerId === 0;

        if (this.isAdding) {
            this.createForm();
        } else {
            forkJoin(this.trailerService.getById(this.trailerId)).subscribe((answer) => {
                this.trailer = answer[0];
                this.createForm();
            });
        }
    }

    createForm(): void {
        this.getControls();
        this.trailerInfoForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractTrailerControls = new TrailerDynamicControls(this.trailer, {
            formGroup: 'Trailer',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Trailer: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.trailerInfoForm.valid) {
            let trailer = this.trailerInfoForm.getRawValue().Trailer as ITrailer;
            trailer.Id = this.trailerId;

            if (this.isAdding) {
                this.trailerService
                    .create(trailer)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.isAdding = false;
                        this.trailerId = answer;
                        trailer.Id = answer;
                        this.notificationsService.success('Trailer created successfully.');
                    });
            } else {
                this.trailerService
                    .update(trailer)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.notificationsService.success('Trailer updated successfully.');
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.trailerInfoForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('TrailerInfo saved successfully.');
    }
}
