export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    UserRoles = 4,
    Orders = 5,
    SetOrderStatus = 6,
    MillOperations_TrimSheets = 7,
    MillOperations = 8,
    ConvertingData_CuttingTeamLogs = 9,
    ConvertingData_PastingTeamLogs = 10,
    ConvertingData_LiningGuillotineLogs = 11,
    ConvertingData_LiningParryLinerLogs = 12,
    ConvertingData_RotarySlitDivisionLogs = 13,
    Shipments = 14,
    Receipts = 15,
    Orders_CanPrintOrders = 16,
    MillComplaints = 17,
    MillOperations_ConvertingLines = 18,
    MillOperations_StockSkids = 19,
    Orders_CanArchiveOrders = 20,
    Orders_CanEditOrdersAnyStatus = 21,
    Orders_CanEditSpecsAndPriceBreakdownInPending = 22,
    MillOperations_Backtender = 23,
    ConvertingData = 24,
    Orders_CanProvideCreditAuthorization = 25,
    Customers_CanEditCustomerContacts = 26,
    Customers_CanEditCustomerPricing = 27,
    MillOperations_PackagingLines = 28,
    MillCorpInvoice = 29,
    Orders_CanViewArchivedOrders = 30,
    MillOperations_CanAddStockToAvailableInventory = 31,
    Receipts_CanProcessInvoice = 32,
    Receipts_CanReprintInvoice = 33,
    MillOperations_PrintBacktenderTag = 34,
    Orders_CanAccessAvailableInventory = 35,
    Orders_CanAccessAvailableAndUnavailableInventory = 36,
    ViewCurrentProduction = 37,
    Shipments_SkidDataMaintenance = 38,
    Shipments_LoadPlanning = 39,
    Shipments_LoadFulfillment = 40,
    Shipments_ProcessedSkids = 41,
    Shipments_PrintTag = 42,
    Shipments_ShippingSettings = 43,
    DailyRunEntries = 44,
}
