import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMillCorpCustomer } from '@model/interfaces/mill-corp-customer';

import { MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class MillCorpCustomerService extends MetaItemService<IMillCorpCustomer> {
    constructor(public http: HttpClient) {
        super('MillCorpCustomerService', 'Mill Corp Customer', 'MillCorpCustomerId', '/mill-corp/customers', http);
    }
}
