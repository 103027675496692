import { Component, Input, OnInit } from '@angular/core';
import { FileItem } from 'ng2-file-upload';

import { UserService } from '../user.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-user-photo',
    templateUrl: './user-photo.component.html',
})
export class UserPhotoComponent implements OnInit {
    @Input() user: any;
    @Input() canEdit: any;

    isEditing: boolean;
    isHovered = false;
    canSave: boolean;
    errorMessage: string;
    imagePath: string;
    file: FileItem;
    croppedFile: any;

    constructor(private notificationsService: NotificationsService, private userService: UserService) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.isEditing = false;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    afterFileCropped(croppedFile: any): void {
        this.croppedFile = croppedFile;
    }

    savePhoto(): any {
        if (this.croppedFile) {
            this.userService.savePhoto(this.user.Id, this.croppedFile).subscribe((answer) => {
                this.user.Image = answer.Image;
                this.user.Version = answer.Version;
                this.isEditing = false;
                this.notificationsService.success('User Photo Saved Successfully');
            });
        }
    }

    afterPhotoAdded(file: FileItem): void {
        this.canSave = true;
        this.file = file;
    }

    deletePhoto(): void {
        this.userService.deletePhoto(this.user.Id).subscribe((answer) => {
            this.user.Image = null;
            this.isEditing = false;
            this.notificationsService.success('User Photo Deleted Successfully');
        });
    }
}
