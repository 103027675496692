import { DynamicField, DynamicLabel, LabelPositions } from '@mt-ng2/dynamic-form';
import { ReceiptFormatDynamicControls, IReceiptFormatDynamicControlsParameters } from '../form-controls/receipt-format.form-controls';
import { IReceiptFormat } from '../interfaces/receipt-format';

export class ReceiptFormatDynamicControlsPartial extends ReceiptFormatDynamicControls {
    constructor(receiptFormat?: IReceiptFormat, additionalParameters?: IReceiptFormatDynamicControlsParameters) {
        super(receiptFormat, additionalParameters);

        (<DynamicField>this.Form.ShowSkidIds).label = 'Skid ID';
        (<DynamicField>this.Form.ShowPieces).label = 'Pieces';
        (<DynamicField>this.Form.ShowBundles).label = 'Bundles';
        (<DynamicField>this.Form.ShowWeight).label = 'Weight';

        (<DynamicField>this.Form.ReceiptSizeTotalId).labelPosition.position = LabelPositions.Hidden;
        (<DynamicField>this.Form.ReceiptSizeTotalId).setRequired(true);
    }
}
