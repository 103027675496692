import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { PrintFormsOptions } from '@model/dto/PrintFormsOptions';

@Injectable()
export class MillComplaintService extends BaseService<IMillComplaint> {
    private emptyMillComplaint: IMillComplaint = {
        ComplaintDate: new Date(),
        ComplaintRecorded: false,
        ComplaintReportedBy: '',
        ComplaintTakenBy: null,
        CreditIssued: '',
        DescriptionOfProblem: '',
        Id: 0,
        InvoiceDate: null,
        InvoiceNumber: null,
        IsBoardKept: false,
        IsBoardRemade: false,
        IsBoardReturned: false,
        IsCreditIssued: false,
        IssueCreditMemo: false,
        Other: '',
        QuantityAffected: 0,
        QuantityAffectedTypeId: null,
        Resolution: '',
        SalesOrderId: null,
    };

    constructor(public http: HttpClient) {
        super('/mill-complaints', http);
    }

    getEmptyMillComplaint(): IMillComplaint {
        return { ...this.emptyMillComplaint };
    }

    printComplaint(millComplaintId: number, printFormsOptions: PrintFormsOptions): any {
        return this.http.post('/mill-complaints/' + millComplaintId + '/print', printFormsOptions);
    }
}
