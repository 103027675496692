<div class="row">
    <div class="col-sm-4 nowrap">Load: {{ shipment?.Id }}</div>
    <div class="col-sm-4 nowrap">Skids: {{ skids?.length + stockOrderLines?.length }}</div>
    <div class="col-sm-4 nowrap">Load Weight: {{ shipmentWeight }}</div>
</div>
<br />
<div class="row">
    <div class="col-sm-4 nowrap text-center">Hauled By: {{ shipment?.Hauler?.Name }}</div>
    <div class="col-sm-4 nowrap text-center"></div>
    <div class="col-sm-4 nowrap text-center">Trailer: {{ shipment?.Trailer?.Number }}</div>
</div>
<br />
<div class="row">
    <div class="col-sm-4 nowrap text-center">Opened: {{ shipment?.OpenedDate | date : 'short' }}</div>
    <div class="col-sm-4 nowrap text-center"></div>
    <div class="col-sm-4 nowrap text-center">Closed: {{ shipment?.ClosedDate | date : 'short' }}</div>
</div>
<br />
<table class="table table-hover table-striped m-b-0 text-left">
    <thead>
        <tr class="no-pointer">
            <th>Skid</th>
            <th>Order</th>
            <th>Size</th>
            <th class="text-center">Bundles</th>
            <th *ngIf="canRemoveSkids"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let skid of skids">
            <td class="nowrap">{{ skid.SkidNumber }}</td>
            <td class="nowrap">{{ getFormattedOrderNumber(skid.SalesOrder) }}</td>
            <td class="nowrap">{{ skid.Width + ' x ' + skid.Length }}</td>
            <td class="nowrap text-center">{{ getBundlesCount(skid) }}</td>
            <td *ngIf="canRemoveSkids"><button class="btn btn-default" (click)="removeSkidFromLoad(skid)">Remove</button></td>
        </tr>
        <tr *ngIf="!skids || !skids.length">
            <td colspan="5">
                <h3 class="text-center">No skids to display.</h3>
            </td>
        </tr>
    </tbody>
    <thead>
        <tr class="no-pointer">
            <th>Stock Id Number</th>
            <th>Order</th>
            <th>Size</th>
            <th class="text-center">Bundles</th>
            <th *ngIf="canRemoveSkids"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of stockOrderLines">
            <td class="nowrap">{{ item.StockItem.StockIdNumber }}</td>
            <td class="nowrap">{{ getFormattedOrderNumber(item.OrderInfoes[0].SalesOrders[0]) }}</td>
            <td class="nowrap">{{ item.StockItem.Width + ' x ' + item.StockItem.Length }}</td>
            <td class="nowrap text-center">{{ getTruncatedBundles(item.StockItem.Bundles) }}</td>
            <td *ngIf="canRemoveSkids"><button class="btn btn-default" (click)="removeStockOrderLineFromLoad(item)">Remove</button></td>
        </tr>
        <tr *ngIf="!stockOrderLines || !stockOrderLines.length">
            <td colspan="5">
                <h3 class="text-center">No stock orders to display.</h3>
            </td>
        </tr>
    </tbody>
</table>
