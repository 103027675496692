import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PricingChargePerTypeService } from '../pricingchargepertype.service';
import { CustomerOrderPriceInfoService } from '../customerorderpriceinfo.service';
import { CustomerOrderPriceInfoDynamicControlsPartial } from '@model/partials/customer-order-price-info.form-controls';
import { ICustomerOrderPriceInfo } from '@model/interfaces/customer-order-price-info';
import { IOrderInfo } from '@model/interfaces/order-info';
import { OrderInfoService } from '../order-info.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../sales-order.service';
import { finalize, tap, flatMap } from 'rxjs/operators';
import { OrderStatusIds } from '@model/OrderStatuses';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { IFormattedSalesOrder } from '@model/interfaces/custom/formatted-sales-order';
import { CornerCutTypesService } from '../cornercuttypes.service';
import { ICornerCutType } from '@model/interfaces/corner-cut-type';

@Component({
    selector: 'price-breakdown',
    templateUrl: './price-breakdown.component.html',
})
export class PriceBreakdownComponent implements OnInit {
    // abstract controls
    abstractCustomerOrderPriceInfoControls: any;
    cornercutTypes: ICornerCutType[] = [];

    priceBreakdownForm: FormGroup;
    salesOrderId: number;
    salesOrder: IFormattedSalesOrder;
    customerOrderPriceInfo: ICustomerOrderPriceInfo;
    orderInfoId: number;
    orderInfo: IOrderInfo;
    orderCustomerSpecId: number;
    doubleClickIsDisabled = false;
    formCreated = false;
    DEFAULT_ENERGY_SURCHARGE_RATE = 0;
    canEditSpecsDetailsInPending: boolean;
    canEditOrderAnyStatus: boolean;

    get formIsLocked(): boolean {
        let orderStatusId = this.salesOrder ? this.salesOrder.OrderStatusId : OrderStatusIds.Open;
        if (this.salesOrder.OrderStatusId === OrderStatusIds.PendingReview && this.canEditSpecsDetailsInPending) {
            return false;
        }
        return !this.canEditOrderAnyStatus && (orderStatusId === OrderStatusIds.PendingReview ||
            orderStatusId === OrderStatusIds.Approved ||
            orderStatusId === OrderStatusIds.CreditHold);
    }

    pricing = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private pricingChargePerTypeService: PricingChargePerTypeService,
        private customerOrderPriceInfoService: CustomerOrderPriceInfoService,
        private orderInfoService: OrderInfoService,
        private salesOrderService: SalesOrderService,
        private claimsService: ClaimsService,
        private cornerCutTypeService: CornerCutTypesService,
    ) { }

    ngOnInit(): void {
        this.canEditOrderAnyStatus = this.claimsService.hasClaim(ClaimTypes.Orders_CanEditOrdersAnyStatus, [ClaimValues.FullAccess]);
        this.canEditSpecsDetailsInPending = this.claimsService.hasClaim(ClaimTypes.Orders_CanEditSpecsAndPriceBreakdownInPending,
            [ClaimValues.FullAccess]);
        this.orderCustomerSpecId = +this.route.snapshot.params.orderCustomerSpecId;
        this.salesOrderId = +this.route.snapshot.params.salesOrderId;
        this.orderInfoId = +this.route.snapshot.params.orderInfoId;
        forkJoin([this.pricingChargePerTypeService.getItems(), this.getOrderInfoById(), this.getSalesOrderInfoById(), this.cornerCutTypeService.getItems()]).subscribe((answer) => {
            const [, , , cornerCutTypes] = answer;
            this.cornercutTypes = cornerCutTypes as ICornerCutType[];
            this.createForm();
            this.setInitialFocus();
            this.updatePricing(this.priceBreakdownForm.value.CustomerOrderPriceInfo);
        });
    }

    private setInitialFocus(): void {
        setTimeout(() => (<FormGroup>this.priceBreakdownForm.controls.CustomerOrderPriceInfo).controls.BasePrice.mtFocus());
    }

    updatePricing(formValue): void {
        this.pricing = Object.keys(formValue).reduce((sum, k) => {
            let controlValue = formValue[k] ?? 0;
            if (typeof controlValue === 'string' || k === 'PricingChargePerTypeId') {
                return sum;
            } else {
                return sum + controlValue;
            }
        }, 0);
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    getOrderInfoById(): any {
        return this.orderInfoService
            .getById(this.orderInfoId)
            .pipe(
                tap((answer) => {
                    this.orderInfo = answer;
                }),
            )
            .pipe(flatMap(() => this.getCustomerOrderPriceInfoById()));
    }

    getSalesOrderInfoById(): Observable<ISalesOrder> {
        return this.salesOrderService.getById(this.salesOrderId).pipe(
            tap((answer) => {
                this.salesOrder = answer as IFormattedSalesOrder;
            }),
        );
    }

    getCustomerOrderPriceInfoById(): any {
        if (!this.orderInfo || this.orderInfo.CustomerOrderPriceInfoId === null || this.orderInfo.CustomerOrderPriceInfoId === 0) {
            return from('not found'); // Have to return something here so it hits the answer in the forkjoin
        }
        return this.customerOrderPriceInfoService.getById(this.orderInfo.CustomerOrderPriceInfoId).pipe(
            tap((answer) => {
                this.customerOrderPriceInfo = answer;
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.priceBreakdownForm = this.assignFormGroups();

        this.formCreated = true;
        this.cdr.detectChanges();
        this.priceBreakdownForm.valueChanges.subscribe((v) => {
            this.updatePricing(v.CustomerOrderPriceInfo);
        });
        if (this.orderCustomerSpecId === 0) {
            this.setDefaultEnergySurchargeValue();
        }
        this.setFieldVisibility();
    }

    setDefaultEnergySurchargeValue(): void {
        (<FormGroup>this.priceBreakdownForm.controls.CustomerOrderPriceInfo).controls.EnergySurchargePerTonRate.setValue(this.DEFAULT_ENERGY_SURCHARGE_RATE);
    }

    private setFieldVisibility(): void {
        if (this.formIsLocked) {
            this.priceBreakdownForm.disable();
        }
    }

    getControls(): void {
        this.abstractCustomerOrderPriceInfoControls = new CustomerOrderPriceInfoDynamicControlsPartial(
            this.customerOrderPriceInfo ? this.customerOrderPriceInfo : null,
            'CustomerOrderPriceInfo',
            this.pricingChargePerTypeService.items.sort((a, b) => (a.Id < b.Id ? -1 : 1)),
            this.cornercutTypes,
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            CustomerOrderPriceInfo: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        const cornerCut = this.priceBreakdownForm.getRawValue().CustomerOrderPriceInfo.CornerCut;
        const cornercutType = this.priceBreakdownForm.getRawValue().CustomerOrderPriceInfo.CornerCutTypeId;
        if (cornerCut && !cornercutType) {
            this.priceBreakdownForm.get('CustomerOrderPriceInfo.CornerCutTypeId').mtSetRequired(true);
            this.priceBreakdownForm.get('CustomerOrderPriceInfo.CornerCutTypeId').updateValueAndValidity();
        }
        if (this.priceBreakdownForm.valid) {
            this.customerOrderPriceInfo = this.priceBreakdownForm.getRawValue().CustomerOrderPriceInfo;
            this.customerOrderPriceInfo.Id = this.orderInfo.CustomerOrderPriceInfoId || 0;
            if (this.customerOrderPriceInfo.Id === 0) {
                this.customerOrderPriceInfoService
                    .createCustomerOrderPriceInfo(this.orderInfoId, this.customerOrderPriceInfo)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.success();
                    });
            } else {
                this.customerOrderPriceInfoService
                    .update(this.customerOrderPriceInfo)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.success();
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.priceBreakdownForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Pricing Breakdown Save Failed');
    }

    success(): void {
        this.notificationsService.success('Pricing Breakdown Saved Successfully');
        this.router.navigate(['../../'], { relativeTo: this.route });
    }
}
