import { IAddressContainer, IAddress } from '@mt-ng2/dynamic-form';
import { CustomerShippingAddressDynamicControls } from '../form-controls/customer-shipping-address.form-controls';
import { Validators } from '@angular/forms';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';

import { ICustomerShippingAddress } from '../interfaces/customer-shipping-address';
import { formatAddress } from '@mt-ng2/format-functions';

export class CustomerShippingAddressDynamicControlsPartial extends CustomerShippingAddressDynamicControls {
    constructor(private customershippingaddresspartial: ICustomerShippingAddress, private formGroupPartial: string) {
        super(customershippingaddresspartial, {
            formGroup: formGroupPartial,
        });
        this.Form.Address = new DynamicField({
            formGroup: formGroupPartial,
            label: 'Address',
            name: 'Address',
            type: new DynamicFieldType({
                allowInternationalAddresses: true,
                fieldType: DynamicFieldTypes.MtAddress,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: (this.customershippingaddresspartial && this.containerizeMe()) || null,
        });

        this.Form.ShipToCode.validation = [Validators.required, Validators.maxLength(9)];
        this.Form.ShipToCode.validators = { required: true, maxlength: 9 };
        this.Form.ShipToCode.disabled = true;

        this.View.Address = new DynamicLabel(
            'Address',
            (this.customershippingaddresspartial && this.getIndividualAddressTitle(this.customershippingaddresspartial.Address)) || '',
            new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }),
        );
    }

    containerizeMe(): IAddressContainer {
        return {
            Address: this.customershippingaddresspartial.Address,
            AddressId: this.customershippingaddresspartial.AddressId,
            IsPrimary: this.customershippingaddresspartial.IsPrimary,
        };
    }

    getIndividualAddressTitle(address: IAddress): string {
        return formatAddress(address);
    }
}
