<div class="row">
    <div class="col-md-7">
        <h2>
            <route-back-button></route-back-button>
            Order Shipping Status
        </h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form class="miles-form padded" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-md-4">
                    <label for="salesOrderId">Sales Order Number</label>
                    <input type="text" id="salesOrderId" name="salesOrderId" [(ngModel)]="salesOrderId" class="form-control" />
                </div>
                <div class="col-md-8 m-t-md">
                    <div class="row">
                        <div class="col-xs-2">
                            <a
                                routerLink="/processed/{{ salesOrderId }}"
                                *ngIf="salesOrderId && salesOrder && salesOrderId == salesOrder.Id"
                                >View Details</a
                            >
                            <a routerLink="/processed" *ngIf="!salesOrderId || !salesOrder || salesOrderId != salesOrder.Id"
                                >View Details</a
                            >
                        </div>
                        <div class="col-xs-10">
                            Show Processed Skids
                            <span *ngIf="salesOrderId && salesOrder && salesOrderId == salesOrder.Id">for Order {{ salesOrderId }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-2" *ngIf="salesOrderId && salesOrder && salesOrderId == salesOrder.Id">
                            <a (click)="navigateToViewProduction()" 
                                >View Production</a
                            >
                            <a routerLink="/salesorders-manufacturing" *ngIf="!salesOrderId || !salesOrder || salesOrderId != salesOrder.Id"
                                >View Production</a
                            >
                        </div>
                        <div class="col-xs-10">
                            View Production
                            <span *ngIf="salesOrderId && salesOrder && salesOrderId == salesOrder.Id">for Order {{ salesOrderId }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-t-sm" *ngIf="salesOrder">
                <div class="col-md-12">
                    <h4>Skids</h4>
                    <div class="row">
                        <div class="col-md-4"><b>Ordered</b>: {{ ordered }}</div>
                        <div class="col-md-4">
                            <b>Produced</b>: <a (click)="printProcessedSkids(); $event.stopPropagation()">{{ produced }}</a>
                        </div>
                        <div class="col-md-4">
                            <b>In Converting</b>: <a (click)="printConvertingSkids(); $event.stopPropagation()">{{ converting }}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4"><b>Shipped</b>: {{ shipped }}</div>
                        <div class="col-md-4"><b>Taken From</b>:
                            <a (click)="printSkidsTaken()"> {{ skidsTaken }}</a>
                    </div>
                    </div>
                    <div class="row">
                        <!-- If getBalance() returns 0, it's falsey and will use 'ALL SKIDS SHIPPED' -->
                        <div class="col-md-4">
                            <b>Balance</b>:
                            {{ getBalance() || (printedReceiptsExist ? 'ALL SKIDS SHIPPED' : 0) }}
                        </div>
                    </div>
                    <div class="row m-t-md">
                        <div class="col-md-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Date Shipped</th>
                                        <th>Total Gross Weight</th>
                                        <th>Total Net Weight</th>
                                        <th>Billed Pieces</th>
                                        <th>Load #</th>
                                        <th>Bdls</th>
                                        <th># Skids</th>
                                        <th>Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let shipment of shipments" (click)="showShipmentDetails(shipment)">
                                        <td>{{ getShipmentDate(shipment) | date : 'MM/dd/yyyy' }}</td>
                                        <td>{{ getTotalGrossWeight(shipment) }}</td>
                                        <td>{{ getTotalNetWeight(shipment) }}</td>
                                        <td>{{ getBilledPieces(shipment) | number : '1.0-4' }}</td>
                                        <td>
                                            <a (click)="printShipment(shipment.Id); $event.stopPropagation()">{{ shipment.Id }}</a>
                                        </td>
                                        <td>{{ getShipmentBundleCount(shipment) | number : '1.2-2' }}</td>
                                        <td>{{ shipment.Skids?.length || 0 }}</td>
                                        <td>
                                            <ng-container *ngFor="let receipt of getReceipts(shipment); let i = index">
                                                <ng-container *ngIf="i > 0">,</ng-container>
                                                <a [routerLink]="['/receipts', receipt.Id, 'detail']" (click)="$event.stopPropagation()">{{
                                                    receipt.Number
                                                }}</a>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<modal-wrapper [options]="modalOptions" [autoShow]="false" title="Load Details" (ready)="shipmentDetailModal = $event">
    <shipment-skid [shipment]="selectedShipment"></shipment-skid>
</modal-wrapper>
