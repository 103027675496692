import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@mt-ng2/auth-module';
import { dailyRunEntriesPaths, dailyRunEntriesRoleGuard } from '@routes/daily-run-paths';
import { DailyRunEntryListComponent } from './daily-run-entry-list/daily-run-entry-list.component';
import { DailyRunEntriesComponent } from './daily-run-entries/daily-run-entries.component';

const dailyRunRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DailyRunEntryListComponent,
        data: dailyRunEntriesRoleGuard,
        path: `${dailyRunEntriesPaths.dailyRunEntries}`,
    },
    {
        canActivate: [AuthGuard],
        component: DailyRunEntriesComponent,
        data: dailyRunEntriesRoleGuard,
        path: `${dailyRunEntriesPaths.dailyRunEntries}/${dailyRunEntriesPaths.add}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(dailyRunRoutes)],
})
export class DailyRunEntryRoutingModule {}
