import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOrderInfo } from '../interfaces/order-info';
import { ICommissionSalesPerson } from '../interfaces/commission-sales-person';
import { ICustomer } from '../interfaces/customer';
import { ICustomerOrderPriceInfo } from '../interfaces/customer-order-price-info';
import { IUser } from '../interfaces/user';
import { ICustomerShippingAddress } from '../interfaces/customer-shipping-address';
import { IOrderPriceType } from '../interfaces/order-price-type';

export interface IOrderInfoDynamicControlsParameters {
    formGroup?: string;
    customerSalesPeople?: IUser[];
    customerOrderPriceInfos?: ICustomerOrderPriceInfo[];
    customers?: ICustomer[];
    customerShippingAddresses?: ICustomerShippingAddress[];
    customerOrderSpecifications?: ICustomer[];
    orderPriceTypes?: IOrderPriceType[];
    orderTakenBies?: IUser[];
    commissionSalesPeople?: ICommissionSalesPerson[];
}

export class OrderInfoDynamicControls {

    formGroup: string;
    customerSalesPeople: IUser[];
    customerOrderPriceInfos: ICustomerOrderPriceInfo[];
    customers: ICustomer[];
    customerShippingAddresses: ICustomerShippingAddress[];
    customerOrderSpecifications: ICustomer[];
    orderPriceTypes: IOrderPriceType[];
    orderTakenBies: IUser[];
    commissionSalesPeople: ICommissionSalesPerson[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private orderinfo?: IOrderInfo, additionalParameters?: IOrderInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OrderInfo';
        this.customerSalesPeople = additionalParameters && additionalParameters.customerSalesPeople || undefined;
        this.customerOrderPriceInfos = additionalParameters && additionalParameters.customerOrderPriceInfos || undefined;
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.customerShippingAddresses = additionalParameters && additionalParameters.customerShippingAddresses || undefined;
        this.customerOrderSpecifications = additionalParameters && additionalParameters.customerOrderSpecifications || undefined;
        this.orderPriceTypes = additionalParameters && additionalParameters.orderPriceTypes || undefined;
        this.orderTakenBies = additionalParameters && additionalParameters.orderTakenBies || undefined;
        this.commissionSalesPeople = additionalParameters && additionalParameters.commissionSalesPeople || undefined;

        this.Form = {
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.Bundles || null,
            }),
            CommissionSalesPersonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Commission Sales Person',
                name: 'CommissionSalesPersonId',
                options: this.commissionSalesPeople,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.CommissionSalesPersonId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.orderinfo && this.orderinfo.CustomerId || null,
            }),
            CustomerOrderPriceInfoId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Order Price Info',
                name: 'CustomerOrderPriceInfoId',
                options: this.customerOrderPriceInfos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.CustomerOrderPriceInfoId || null,
            }),
            CustomerOrderSpecificationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Order Specification',
                name: 'CustomerOrderSpecificationId',
                options: this.customerOrderSpecifications,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.CustomerOrderSpecificationId || null,
            }),
            CustomerPurchaseOrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Purchase Order Number',
                name: 'CustomerPurchaseOrderNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('CustomerPurchaseOrderNumber') && this.orderinfo.CustomerPurchaseOrderNumber !== null ? this.orderinfo.CustomerPurchaseOrderNumber.toString() : '',
            }),
            CustomerSalesPersonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Sales Person',
                name: 'CustomerSalesPersonId',
                options: this.customerSalesPeople,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.CustomerSalesPersonId || null,
            }),
            CustomerShippingAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Shipping Address',
                name: 'CustomerShippingAddressId',
                options: this.customerShippingAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.orderinfo && this.orderinfo.CustomerShippingAddressId || null,
            }),
            CustomerShipVia: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Ship Via',
                name: 'CustomerShipVia',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('CustomerShipVia') && this.orderinfo.CustomerShipVia !== null ? this.orderinfo.CustomerShipVia.toString() : '',
            }),
            CustomerTerms: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Terms',
                name: 'CustomerTerms',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('CustomerTerms') && this.orderinfo.CustomerTerms !== null ? this.orderinfo.CustomerTerms.toString() : '',
            }),
            FreightNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Freight Notes',
                name: 'FreightNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('FreightNotes') && this.orderinfo.FreightNotes !== null ? this.orderinfo.FreightNotes.toString() : '',
            }),
            IsCreditCardPurchase: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Credit Card Purchase',
                name: 'IsCreditCardPurchase',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('IsCreditCardPurchase') && this.orderinfo.IsCreditCardPurchase !== null ? this.orderinfo.IsCreditCardPurchase : false,
            }),
            IsOurMill: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Our Mill',
                name: 'IsOurMill',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('IsOurMill') && this.orderinfo.IsOurMill !== null ? this.orderinfo.IsOurMill : false,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('Notes') && this.orderinfo.Notes !== null ? this.orderinfo.Notes.toString() : '',
            }),
            NumberOfSkids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Skids',
                name: 'NumberOfSkids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.NumberOfSkids || null,
            }),
            OrderDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Date',
                name: 'OrderDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderinfo && this.orderinfo.OrderDate || null,
            }),
            OrderPriceTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Price Type',
                name: 'OrderPriceTypeId',
                options: this.orderPriceTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.OrderPriceTypeId || null,
            }),
            OrderTakenBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Taken By',
                name: 'OrderTakenBy',
                options: this.orderTakenBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.OrderTakenBy || null,
            }),
            PricePer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Price Per',
                name: 'PricePer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.PricePer || null,
            }),
            RunWeekOf: new DynamicField({
                formGroup: this.formGroup,
                label: 'Run Week Of',
                name: 'RunWeekOf',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('RunWeekOf') && this.orderinfo.RunWeekOf !== null ? this.orderinfo.RunWeekOf.toString() : '',
            }),
            SpecialInstructions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Special Instructions',
                name: 'SpecialInstructions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.orderinfo && this.orderinfo.hasOwnProperty('SpecialInstructions') && this.orderinfo.SpecialInstructions !== null ? this.orderinfo.SpecialInstructions.toString() : '',
            }),
            TradeDiscountPercentage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trade Discount Percentage',
                name: 'TradeDiscountPercentage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderinfo && this.orderinfo.TradeDiscountPercentage || null,
            }),
        };

        this.View = {
            Bundles: new DynamicLabel(
                'Bundles',
                this.orderinfo && this.orderinfo.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            CommissionSalesPersonId: new DynamicLabel(
                'Commission Sales Person',
                getMetaItemValue(this.commissionSalesPeople, this.orderinfo && this.orderinfo.hasOwnProperty('CommissionSalesPersonId') && this.orderinfo.CommissionSalesPersonId !== null ? this.orderinfo.CommissionSalesPersonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.orderinfo && this.orderinfo.hasOwnProperty('CustomerId') && this.orderinfo.CustomerId !== null ? this.orderinfo.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerOrderPriceInfoId: new DynamicLabel(
                'Customer Order Price Info',
                getMetaItemValue(this.customerOrderPriceInfos, this.orderinfo && this.orderinfo.hasOwnProperty('CustomerOrderPriceInfoId') && this.orderinfo.CustomerOrderPriceInfoId !== null ? this.orderinfo.CustomerOrderPriceInfoId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerOrderSpecificationId: new DynamicLabel(
                'Customer Order Specification',
                getMetaItemValue(this.customerOrderSpecifications, this.orderinfo && this.orderinfo.hasOwnProperty('CustomerOrderSpecificationId') && this.orderinfo.CustomerOrderSpecificationId !== null ? this.orderinfo.CustomerOrderSpecificationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerPurchaseOrderNumber: new DynamicLabel(
                'Customer Purchase Order Number',
                this.orderinfo && this.orderinfo.hasOwnProperty('CustomerPurchaseOrderNumber') && this.orderinfo.CustomerPurchaseOrderNumber !== null ? this.orderinfo.CustomerPurchaseOrderNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerSalesPersonId: new DynamicLabel(
                'Customer Sales Person',
                getMetaItemValue(this.customerSalesPeople, this.orderinfo && this.orderinfo.hasOwnProperty('CustomerSalesPersonId') && this.orderinfo.CustomerSalesPersonId !== null ? this.orderinfo.CustomerSalesPersonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerShippingAddressId: new DynamicLabel(
                'Customer Shipping Address',
                getMetaItemValue(this.customerShippingAddresses, this.orderinfo && this.orderinfo.hasOwnProperty('CustomerShippingAddressId') && this.orderinfo.CustomerShippingAddressId !== null ? this.orderinfo.CustomerShippingAddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerShipVia: new DynamicLabel(
                'Customer Ship Via',
                this.orderinfo && this.orderinfo.hasOwnProperty('CustomerShipVia') && this.orderinfo.CustomerShipVia !== null ? this.orderinfo.CustomerShipVia.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerTerms: new DynamicLabel(
                'Customer Terms',
                this.orderinfo && this.orderinfo.hasOwnProperty('CustomerTerms') && this.orderinfo.CustomerTerms !== null ? this.orderinfo.CustomerTerms.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FreightNotes: new DynamicLabel(
                'Freight Notes',
                this.orderinfo && this.orderinfo.hasOwnProperty('FreightNotes') && this.orderinfo.FreightNotes !== null ? this.orderinfo.FreightNotes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsCreditCardPurchase: new DynamicLabel(
                'Is Credit Card Purchase',
                this.orderinfo && this.orderinfo.hasOwnProperty('IsCreditCardPurchase') && this.orderinfo.IsCreditCardPurchase !== null ? this.orderinfo.IsCreditCardPurchase : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsOurMill: new DynamicLabel(
                'Is Our Mill',
                this.orderinfo && this.orderinfo.hasOwnProperty('IsOurMill') && this.orderinfo.IsOurMill !== null ? this.orderinfo.IsOurMill : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.orderinfo && this.orderinfo.hasOwnProperty('Notes') && this.orderinfo.Notes !== null ? this.orderinfo.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfSkids: new DynamicLabel(
                'Number Of Skids',
                this.orderinfo && this.orderinfo.NumberOfSkids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OrderDate: new DynamicLabel(
                'Order Date',
                this.orderinfo && this.orderinfo.OrderDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            OrderPriceTypeId: new DynamicLabel(
                'Order Price Type',
                getMetaItemValue(this.orderPriceTypes, this.orderinfo && this.orderinfo.hasOwnProperty('OrderPriceTypeId') && this.orderinfo.OrderPriceTypeId !== null ? this.orderinfo.OrderPriceTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            OrderTakenBy: new DynamicLabel(
                'Order Taken By',
                getMetaItemValue(this.orderTakenBies, this.orderinfo && this.orderinfo.hasOwnProperty('OrderTakenBy') && this.orderinfo.OrderTakenBy !== null ? this.orderinfo.OrderTakenBy : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PricePer: new DynamicLabel(
                'Price Per',
                this.orderinfo && this.orderinfo.PricePer || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            RunWeekOf: new DynamicLabel(
                'Run Week Of',
                this.orderinfo && this.orderinfo.hasOwnProperty('RunWeekOf') && this.orderinfo.RunWeekOf !== null ? this.orderinfo.RunWeekOf.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SpecialInstructions: new DynamicLabel(
                'Special Instructions',
                this.orderinfo && this.orderinfo.hasOwnProperty('SpecialInstructions') && this.orderinfo.SpecialInstructions !== null ? this.orderinfo.SpecialInstructions.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TradeDiscountPercentage: new DynamicLabel(
                'Trade Discount Percentage',
                this.orderinfo && this.orderinfo.TradeDiscountPercentage || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
        };

    }
}
