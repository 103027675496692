import { AuthUserDynamicControls } from '../form-controls/auth-user.form-controls';
import { IAuthUser } from '../interfaces/auth-user';
import { IUserRole } from '../interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { LoginConfigOverride } from '../../common/configs/login.config';
import { LoginConfig } from '@mt-ng2/login-module';

export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, roles?: IUserRole[]) {
        super(extAuthUser, { formGroup: 'AuthUser', roles: roles });
        const loginConfig = new LoginConfig(LoginConfigOverride);
        let passwordRegexPattern = loginConfig.passwordPattern;
        let failedPatternMessage = 'Valid passwords must be at least 8 characters, have a capital letter and number.';
        // TODO: JJG This is a little hacky to get around the constructor object intellisense.
        // Since I am adding dynamic properties not on the model I need to do this to get around
        // the TS compiler complaining.
        if (!roles) {
            if (!this.extAuthUser || this.extAuthUser.Id === 0) {
                let sendEmail = new DynamicField({
                    formGroup: 'AuthUser',
                    label: 'Send Password Reset Email',
                    name: 'SendResetEmail',
                    type: new DynamicFieldType({
                        fieldType: DynamicFieldTypes.Checkbox,
                    }),
                    value: '',
                });
                this.Form.SendResetEmail = sendEmail;
            }

            this.Form.ConfirmPassword = new DynamicField({
                failedPatternMessage: failedPatternMessage,
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                labelHtml: `<label>Confirm Password</label>`,
                name: 'ConfirmPassword',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
                validators: {
                    pattern: passwordRegexPattern,
                    required: true,
                },
                value: '',
            });

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassword',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            });

            if (this.Form.ConfirmPassword) {
                this.Form.ConfirmPassword.failedPatternMessage = failedPatternMessage;
            }
            (<DynamicField>this.Form.Password).validation.push(Validators.pattern(passwordRegexPattern));
            (<DynamicField>this.Form.Password).validators.pattern = passwordRegexPattern;
            (<DynamicField>this.Form.Password).failedPatternMessage = failedPatternMessage;
        }
    }
}
