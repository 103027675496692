import { Component, OnInit, Input } from '@angular/core';
import { PrintService } from '../../common/services/print.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { TrimSheetService } from '../trim-sheet.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'trim-sheet-print',
    styles: [
        `
            .miles-card {
                margin-bottom: 90px;
            }
            .datepicker {
                margin-top: -18px;
            }
        `,
    ],
    templateUrl: './trim-sheet-print.component.html',
})
export class TrimSheetPrintComponent implements OnInit {
    @Input() trimSheetId: number;
    @Input() defaultDate: Date;
    dateInputField: IDynamicField;
    dateInputValue: Date;

    doubleClickIsDisabled = false;
    formCreated = false;

    constructor(private trimSheetService: TrimSheetService, private router: Router) {}

    ngOnInit(): void {
        this.dateInputValue = this.defaultDate
                                ? new Date(this.defaultDate)
                                : new Date();
        this.createForm(this.defaultDate ?? new Date());
    }

    createForm(defaultDate: Date): void {
        this.dateInputField = new DynamicField({
            formGroup: 'TrimSheetPrint',
            hideLabel: true,
            label: 'Date',
            name: 'Date',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            } as IDynamicFieldType),
            value: defaultDate,
        } as IDynamicField);

        this.formCreated = true;
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    print(): void {
        this.trimSheetService
            .getTrimSheetPdf(this.dateInputValue)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }

    edit(): void {
        this.router.navigate(['/trim-list'], {
            queryParams: {
                date: this.dateInputValue.toISOString().split('T')[0],
                id: this.trimSheetId,
            },
        });
    }

    printPalletSkidOrderSheet(): void {
        this.trimSheetService
            .getPalletSkidOrderSheet(this.dateInputValue)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }
}
