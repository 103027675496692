import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IMillComplaintCategory } from '@model/interfaces/mill-complaint-category';

@Injectable()
export class MillComplaintCategoryService extends MetaItemService<IMillComplaintCategory> {
    constructor(public http: HttpClient) {
        super('MillComplaintCategoryService', 'Mill Complaint Category', 'MillComplaintCategoryIds', '/salesorders/mill-complaint-categories', http);
    }
}
