import { ScrollableTypeAheadComponent } from '@common/components/scrollable-type-ahead/scrollable-type-ahead.component';
import { BackhaulInfoDynamicControls, IBackhaulInfoDynamicControlsParameters } from '@model/form-controls/backhaul-info.form-controls';
import { IBackhaulInfo } from '@model/interfaces/backhaul-info';
import { DynamicField, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class BackhaulInfoDynamicControlsPartial extends BackhaulInfoDynamicControls {
    constructor(backhaulinfo?: IBackhaulInfo, additionalParameters?: IBackhaulInfoDynamicControlsParameters) {
        super(backhaulinfo, additionalParameters);

        (<DynamicField>this.Form.MillCorpCustomerId).component = ScrollableTypeAheadComponent;
    }
}
