import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMillShift } from '../model/interfaces/mill-shift';

@Injectable()
export class MillShiftService extends StaticMetaItemService<IMillShift> {
    constructor(public http: HttpClient) {
        super('MillShiftService', 'Shift', 'ShiftIds', '/mill-shifts', http);
    }
}
