import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';

import { StockItemsService } from '../../sales-orders/stockitem.service';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { StockItemTypesService } from '../../sales-orders/stock-item-types.service';
import { StockColorsService } from '../../sales-orders/stock-colors.service';
import { DefectTypesService } from '../../sales-orders/defect-types.service';
import { FinishService } from '../../sales-orders/finish.service';
import { BoardGradeService } from '../../sales-orders/board-grade.service';
import { FractionsService } from '@common/services/fractions.service';

import { StockItemDynamicControlsPartial } from '@model/partials/stock-item-partial.form-controls';
import { IStockItem } from '@model/interfaces/stock-item';
import { PrintService } from '@common/services/print.service';
import { finalize } from 'rxjs/operators';
import { Scales } from '@model/Scales';
import { ScalesService } from '../../scales/scales.service';
import { WarehouseLocationService } from '../../sales-orders/warehouse-location.service';
import { IStockItemType } from '@model/interfaces/stock-item-type';
import { IStockColor } from '@model/interfaces/stock-color';
import { IDefectType } from '@model/interfaces/defect-type';
import { IFinish } from '@model/interfaces/finish';
import { IBoardGrade } from '@model/interfaces/board-grade';
import { IWarehouseLocation } from '@model/interfaces/warehouse-location';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'stock-skid-processing',
    styles: [
        `
            #skid-belongs-to-order-msg {
                margin-top: -1.5%;
            }
        `,
    ],
    templateUrl: './stock-skid-processing.component.html',
})
export class StockSkidProcessingComponent implements OnInit {
    abstractStockItemControls: any;

    stockSkidProcessingForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    stockItem: IStockItem = null;

    today = new Date();
    readonly DEFAULT_POUNDS_PER_BUNDLE = 50;

    scaleId = Scales.Stock;
    isWeighing = false;

    stockItemTypeItems: IStockItemType[];
    stockColorItems: IStockColor[];
    defectTypeItems: IDefectType[];
    finishItems: IFinish[];
    boardGradeItems: IBoardGrade[];
    warehouseLocationItems: IWarehouseLocation[];
    loadingStock: boolean;

    canMarkAsAvailableBasedOnClaims = false;
    stockItemBelongsToOrder = false;

    calculatingBundles = false;
    calculatingNetWeight = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private stockItemsService: StockItemsService,
        private notificationsService: NotificationsService,
        private stockItemTypeService: StockItemTypesService,
        private stockColorService: StockColorsService,
        private defectTypeService: DefectTypesService,
        private finishService: FinishService,
        private boardGradeService: BoardGradeService,
        private fractionsService: FractionsService,
        private warehouseLocationService: WarehouseLocationService,
        private scalesService: ScalesService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.stockItemTypeService.getItems(),
            this.stockColorService.getItems(),
            this.defectTypeService.getItems(),
            this.finishService.getItems(),
            this.boardGradeService.getItems(),
            this.warehouseLocationService.getItems(),
        ]).subscribe(([stockItemTypeItems, stockColorItems, defectTypeItems, finishItems, boardGradeItems, warehouseLocationItems]) => {
            this.stockItemTypeItems = stockItemTypeItems;
            this.stockColorItems = stockColorItems;
            this.defectTypeItems = defectTypeItems;
            this.finishItems = finishItems;
            this.boardGradeItems = boardGradeItems;
            this.warehouseLocationItems = warehouseLocationItems;
            this.canMarkAsAvailableBasedOnClaims = this.claimsService.hasClaim(ClaimTypes.MillOperations_CanAddStockToAvailableInventory, [
                ClaimValues.FullAccess,
            ]);
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.stockSkidProcessingForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();

        this.resetForm();
    }

    resetForm(): void {
        const stockId = this.route.snapshot.queryParamMap.get('stockId');

        this.stockSkidProcessingForm.reset();
        this.stockItemBelongsToOrder = false;

        if (stockId) {
            this.getFormControls().StockIdNumber.setValue(stockId);
            this.findStockByNumber();
        } else {
            // only have stock id enabled for them to scan a barcode
            this.stockSkidProcessingForm.disable();
            this.getFormControls().StockIdNumber.mtSetRequired(true);
            this.getFormControls().StockIdNumber.enable();
            this.getFormControls().StockIdNumber.mtFocus();
        }
    }

    getControls(): void {
        // Add NONE option for top and bottom colors
        this.stockColorItems.push({
            Id: null,
            Name: 'NONE',
        });
        this.abstractStockItemControls = new StockItemDynamicControlsPartial(this.stockItem, {
            boardGrades: this.boardGradeService.getAbbreviatedItems(this.boardGradeItems),
            bottomColors: this.stockColorItems,
            defectTypes: this.defectTypeItems,
            finishes: this.finishItems,
            formGroup: 'StockItem',
            stockItemTypes: this.stockItemTypeItems,
            topColors: this.stockColorItems,
            warehouseLocations: this.warehouseLocationItems,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            StockItem: this.fb.group({
                StockItemId: [''],
            }),
        });
    }

    findStockByNumber(stockIdNum?: string): void {
        let stockIdNumber = this.getFormValues().StockIdNumber || stockIdNum;
        if (!stockIdNumber) {
            return;
        }

        this.loadingStock = true;

        forkJoin([
            this.stockItemsService.getByStockIdNumber(stockIdNumber),
            this.stockItemsService.getStockItemBelongsToStockOrder(stockIdNumber),
        ]).subscribe(([stockItem, belongsToOrder]) => {
            if (stockItem) {
                this.stockItem = stockItem;
                this.stockItemBelongsToOrder = belongsToOrder;
                this.initStockItem();
            } else {
                this.notificationsService.warning('Stock ID not found');
                this.getFormControls().StockIdNumber.mtFocus();
                this.loadingStock = false;
            }
        });
    }

    initStockItem(): void {
        this.stockSkidProcessingForm.enable();
        this.stockSkidProcessingForm.patchValue({ StockItem: this.stockItem });
        let formControls = this.getFormControls();

        formControls.StockIdNumber.disable();
        if (this.stockItemBelongsToOrder) {
            formControls.IsAvailable?.disable();
        }
        formControls.Name.mtFocus();
        this.loadingStock = false;

        formControls.LotNumber.mtSetRequired(true);
    }

    formSubmitted(): void {
        this.save(false);
    }

    printAndSave(): void {
        this.save(true);
    }

    save(print: boolean): void {
        if (this.stockSkidProcessingForm.valid) {
            let formValues = this.getFormValues();
            // keep the existing fields from the scanned stock item that aren't on this form
            Object.assign(this.stockItem, formValues);
            this.stockItem.Processed = true;
            this.stockItem.IsPerfect = !!this.stockItem.IsPerfect;
            this.stockItem.IsAvailable = !!this.stockItem.IsAvailable;

            let apiPromise: Observable<any> = null;
            if (this.stockItem.Id) {
                apiPromise = this.stockItemsService.update(this.stockItem);
            } else {
                apiPromise = this.stockItemsService.create(this.stockItem);
            }

            apiPromise
                .pipe(
                    finalize(() => {
                        this.enableDoubleClick();
                    }),
                )
                .subscribe((answer) => {
                    if (!this.stockItem.Id) {
                        this.stockItem.Id = answer;
                    }
                    this.notificationsService.success('Stock skid saved successfully');
                    if (print) {
                        this.printFinalTag();
                    }
                    this.getStockById();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.stockSkidProcessingForm);
            this.enableDoubleClick();
        }
    }

    printFinalTag(): void {
        this.stockItemsService
            .getFinalTagPdf(this.stockItem.Id)
            .subscribe((pdf) => {
                PrintService.printPdf(pdf);
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        if (this.route.snapshot.queryParamMap.keys) {
            this.router.navigate([], { relativeTo: this.route }).then(() => this.resetForm());
        } else {
            this.resetForm();
        }
    }

    getFormValues(): IStockItem {
        return <IStockItem>this.stockSkidProcessingForm.getRawValue().StockItem;
    }

    getFormControls(): any {
        return (this.stockSkidProcessingForm.controls.StockItem as FormGroup).controls;
    }

    weigh(): void {
        this.isWeighing = true;
        this.scalesService
            .getWeight(this.scaleId)
            .pipe(finalize(() => (this.isWeighing = false)))
            .subscribe((weight) => {
                let formControls = this.getFormControls();
                let tareWeight = formControls.TareWeight.value || 0;
                formControls.NetWeight.setValue(Math.round(weight) - tareWeight);
            });
    }

    convertMeasurementToFraction(controlName: string): void {
        let formControls = this.getFormControls();
        formControls[controlName].setValue(this.fractionsService.FormatAsFraction(formControls[controlName].value));
    }

    convertMeasurementToDecimal(controlName: string): void {
        let formControls = this.getFormControls();
        formControls[controlName].setValue(this.fractionsService.FormatAsDecimal(formControls[controlName].value));
    }

    noTag(): void {
        this.stockItem = this.stockItemsService.getEmptyStockItem();
        this.stockItem.Qty = 0;
        this.stockItem.Notes = '';
        this.stockItem.DateCreated = new Date();

        this.stockSkidProcessingForm.reset(this.stockItem);
        this.stockSkidProcessingForm.enable();
        this.getFormControls().PoundsPerBundle.setValue(this.DEFAULT_POUNDS_PER_BUNDLE);
        this.getFormControls().StockIdNumber.mtSetRequired(false);
        this.getFormControls().StockIdNumber.disable();
        this.getFormControls().Name.mtFocus();

        this.getFormControls().Sides.setValue(0);

        this.getFormControls().LotNumber.mtSetRequired(false);
    }

    updateBundlesAndNetWeight(): void {
        // For both the functions below, after the calculation is complete, the flag is reset to false to prevent potential recursion issue since these methods are called on valueChanges.
        this.calculateBundles();
        this.calculateNetWeight();
    }

    calculateBundles(): void {
        if (this.calculatingBundles) {
            return;
        }

        this.calculatingBundles = true;

        let netWeight = this.getFormValues().NetWeight;
        let poundsPerBundle = this.getFormValues().PoundsPerBundle;

        if (this.loadingStock || !netWeight || !poundsPerBundle) {
            this.calculatingBundles = false;
            return;
        }

        let bundles = netWeight / (poundsPerBundle + Number.EPSILON); // truncation handled in form control - see partial
        this.getFormControls().Bundles.setValue(bundles);

        this.calculatingBundles = false;
    }

    calculateNetWeight(): void {
        if (this.calculatingNetWeight) {
            return;
        }

        this.calculatingNetWeight = true;

        let bundles = this.getFormValues().Bundles;
        let poundsPerBundle = this.getFormValues().PoundsPerBundle;

        if (this.loadingStock || !bundles || !poundsPerBundle) {
            this.calculatingNetWeight = false;
            return;
        }

        let netWeight = +(bundles * poundsPerBundle).toFixed(0);
        this.getFormControls().NetWeight.setValue(netWeight);

        this.calculatingNetWeight = false;
    }

    private getStockById(): void {
        let stockId = this.stockItem?.Id;
        if (stockId) {
            this.stockItemsService
                .getById(stockId)
                .subscribe((stockItem) => {
                    this.findStockByNumber(stockItem.StockIdNumber);
                });
        }
    }
}
