<div *ngIf="formCreated" class="col-md-12 col-lg-8 col-xl-8 col-xs-12">
    <form class="miles-form padded row" [formGroup]="orderCustomerSpecsForm" (ngSubmit)="formSubmitted()">
        <div class="row col-md-12">
            <h4>Order Customer Specification</h4>
            <div class="col-md-7">
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.ReceiptPartInfo"
                    [form]="orderCustomerSpecsForm"
                    (blur)="receiptPartInfoBlur()">
                </dynamic-field>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.TagSkidTypeId" [form]="orderCustomerSpecsForm"></dynamic-field>
                <dynamic-field
                    [field]="abstractOrderCustomerSpecificationControls.IsFortyPoundBundle"
                    [form]="orderCustomerSpecsForm"
                ></dynamic-field>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.IsSpecialLabels" [form]="orderCustomerSpecsForm"></dynamic-field>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.IsTiedBundles" [form]="orderCustomerSpecsForm"></dynamic-field>
            </div>
            <div class="col-md-offset-1 col-md-4">
                <dynamic-field
                    [field]="abstractOrderCustomerSpecificationControls.IsNoNewmanMarkings"
                    [form]="orderCustomerSpecsForm"
                ></dynamic-field>
                <dynamic-field
                    [field]="abstractOrderCustomerSpecificationControls.IsCornerProtectors"
                    [form]="orderCustomerSpecsForm"
                ></dynamic-field>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.IsLtl" [form]="orderCustomerSpecsForm"></dynamic-field>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.IsScanCounts" [form]="orderCustomerSpecsForm"></dynamic-field>
            </div>
            <div class="col-md-7">
                <ng-container *ngIf="this.customerId">
                    <dynamic-field [field]="abstractOrderCustomerSpecificationControls.BandingInstructionId" [form]="orderCustomerSpecsForm"></dynamic-field>
                    <br />
                </ng-container>
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.CustomerProductId" [form]="orderCustomerSpecsForm"></dynamic-field>
                <dynamic-field
                    [field]="abstractOrderCustomerSpecificationControls.SpecialInstructions"
                    [form]="orderCustomerSpecsForm"
                ></dynamic-field>
                <dynamic-field
                    [field]="abstractOrderCustomerSpecificationControls.CustomerSpecifications"
                    [form]="orderCustomerSpecsForm"
                ></dynamic-field>
            </div>
            <div class="col-md-7" *ngIf="orderInfoId">
                <div>
                    <dynamic-field
                        [field]="abstractOrderCustomerSpecificationControls.PriceBreakdown"
                        [form]="orderCustomerSpecsForm"
                    ></dynamic-field>
                </div>
            </div>
            <div class="col-md-offset-1 col-md-4" *ngIf="orderInfoId">
                <button type="button" Class="btn btn-flat btn-default" (click)="navigateToCustomerOrderPricing()">Price Breakdown</button>
            </div>
            <div class="col-md-7">
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.OrderNotes" [form]="orderCustomerSpecsForm"></dynamic-field>
            </div>
            <div *ngIf="this.customerId" class="col-md-7">
                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.InvoiceNotes" [form]="orderCustomerSpecsForm"></dynamic-field>
            </div>
            <div class="col-md-12" *ngIf="!formIsLocked">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save & Close
                </button>
            </div>
        </div>
    </form>
</div>
