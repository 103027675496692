import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';

import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { MtAlertsModule } from '@mt-ng2/alerts-module';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtPreventDoubleClickButtonModule } from '@mt-ng2/disable-double-click';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { EntityListModuleConfigToken, IEntityListModuleConfig } from '@mt-ng2/entity-list-module-config';
export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { AuthEntityService } from '../auth-entity/auth-entity.service';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { HorizontalViewComponent } from './components/horizontal-view.component';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MonthSelectComponent } from './components/month-select.component';
import { YearSelectComponent } from './components/year-select.component.1';
import { CustomDateComponent } from './components/custom-date.component';
import { dynamicFormModuleConfig } from './configs/dynamic-form-module.config';
import { CustomMtManagedListComponent } from './components/custom-mt-managed-list.component';
import { CustomMetaItemManagedListComponent } from './components/custom-meta-item-managed-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';
import { TypeAheadModuleConfigToken } from '@mt-ng2/type-ahead-control-config';
import { typeAheadModuleConfig } from './configs/type-ahead-module-config';
import { ModalModule } from '@mt-ng2/modal-module';
import { FractionsDynamicFieldComponent } from './components/fractions-dynamic-field.component';
import { EntityListCardComponent } from './components/entity-list-card/entity-list-card.component';
import { FractionFieldDirective } from './directives/fractions-field.directive';
import { ShippingAddressPipe } from './pipes/shipping-address.pipe';
import { DecimalFieldDirective } from './directives/decimal-field.directive';
import { ScrollableTypeAheadComponent } from './components/scrollable-type-ahead/scrollable-type-ahead.component';
import { PagingHotkeysDirective } from './directives/paging-hotkeys.directive';

@NgModule({
    declarations: [
        HorizontalViewComponent,
        MonthSelectComponent,
        YearSelectComponent,
        CustomDateComponent,
        CustomMtManagedListComponent,
        CustomMetaItemManagedListComponent,
        FractionsDynamicFieldComponent,
        EntityListCardComponent,
        FractionFieldDirective,
        ShippingAddressPipe,
        DecimalFieldDirective,
        ScrollableTypeAheadComponent,
        PagingHotkeysDirective,
    ],
    entryComponents: [CustomDateComponent],
    exports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        MtPhotoControlModule,
        NgxMaskModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtPreventDoubleClickButtonModule,
        EntityListModule,
        MtAlertsModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        HorizontalViewComponent,
        MonthSelectComponent,
        YearSelectComponent,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        CustomMetaItemManagedListComponent,
        CustomMtManagedListComponent,
        ModalModule,
        EntityListCardComponent,
        FractionFieldDirective,
        ShippingAddressPipe,
        DecimalFieldDirective,
        ScrollableTypeAheadComponent,
        PagingHotkeysDirective,
    ],
    imports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        MtPhotoControlModule,
        EntityComponentsAddressesModule,
        MtAlertsModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtPreventDoubleClickButtonModule,
        EntityListModule,
        MtSearchBarControlModule,
        MtSearchFilterCheckboxModule,
        SharedEntitiesModule,
        BackButtonModule,
        DragDropModule,
        TypeAheadModule,
        ModalModule,
    ],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                CommonService,
                AuthEntityService,
                { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
                { provide: TypeAheadModuleConfigToken, useValue: typeAheadModuleConfig },
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
            ],
        };
    }
}
