import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ISkid } from '../../model/interfaces/skid';
import { PrintService } from '../../common/services/print.service';
import { SkidService } from '../skid.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { BacktenderService } from '../../trim-sheets/backtender.service';

@Component({
    selector: 'print-backtender',
    templateUrl: './print-backtender.component.html',
})
export class PrintBacktenderComponent {
    tagNumber: string;
    orderId: string;

    @ViewChild('finalBarcodeInput', { read: ElementRef }) finalBarcodeInput: ElementRef;

    constructor(private notificationsService: NotificationsService, private skidService: SkidService, private backtenderService: BacktenderService) {}

    ngAfterViewInit(): void {
        this.finalBarcodeInput.nativeElement.focus();
    }

    formSubmitted(): void {
        if (this.orderId) {
            this.backtenderService.getByOrderId(this.orderId, this.tagNumber).subscribe((answer) => {
                if (answer) {
                    const tagsToPrint = answer.map((item) => item.TagPrintParams);
                    this.backtenderService
                        .getTrimSheetBacktenderTagPdfs(tagsToPrint)
                        .pipe(finalize(() => {}))
                        .subscribe((pdf) => {
                            if (pdf != null) {
                                PrintService.printPdf(pdf);
                            } else {
                                this.notificationsService.error('No tags to print');
                            }
                        });
                } else {
                    this.notificationsService.warning('Skid not found');
                    this.finalBarcodeInput.nativeElement.focus();
                }
            });
        }
    }
}
