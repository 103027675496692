import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IContact } from '../interfaces/contact';
import { IContactType } from '../interfaces/contact-type';

export interface IContactDynamicControlsParameters {
    formGroup?: string;
    types?: IContactType[];
}

export class ContactDynamicControls {

    formGroup: string;
    types: IContactType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private contact?: IContact, additionalParameters?: IContactDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Contact';
        this.types = additionalParameters && additionalParameters.types || undefined;

        this.Form = {
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50), Validators.email ],
                validators: { 'maxlength': 50, 'email': true },
                value: this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.contact && this.contact.hasOwnProperty('Notes') && this.contact.Notes !== null ? this.contact.Notes.toString() : '',
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
            }),
            TypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'TypeId',
                options: this.types,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.contact && this.contact.TypeId || null,
            }),
        };

        this.View = {
            Email: new DynamicLabel(
                'Email',
                this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.contact && this.contact.hasOwnProperty('Notes') && this.contact.Notes !== null ? this.contact.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Title: new DynamicLabel(
                'Title',
                this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TypeId: new DynamicLabel(
                'Type',
                getMetaItemValue(this.types, this.contact && this.contact.hasOwnProperty('TypeId') && this.contact.TypeId !== null ? this.contact.TypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
