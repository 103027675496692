<div class="row">
    <div class="col-md-7">
        <h2>
            Quickbooks Queue
            <span *ngIf="total > 0" class="label label-info label-as-badge qb-label" title="{{ total }} invoices waiting to be processed">{{
                total
            }}</span>
        </h2>
    </div>
</div>
<div class="row">
    <div class="col-md-8">
        <entity-list
            #receiptList
            [entities]="receipts"
            [total]="total"
            [(currentPage)]="currentPage"
            (onPageChanged)="getReceipts()"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
            [emptyMessage]="'All caught up!  No invoices to process.'"
        >
        </entity-list>
        <button
            *ngIf="totalSelected() > 0"
            (click)="processInvoices()"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            class="btn btn-success"
        >
            <i class="fa fa-fw fa-check-circle-o"></i>
            Process Invoices
        </button>
    </div>
    <div class="col-md-4">
        <released-invoices-print></released-invoices-print>
    </div>
</div>
