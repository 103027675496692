import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { MillCorpInvoiceDynamicControls, IMillCorpInvoiceDynamicControlsParameters } from '../form-controls/mill-corp-invoice.form-controls';
import { IMillCorpInvoice } from '../interfaces/mill-corp-invoice';

export class MillCorpInvoiceDynamicControlsPartial extends MillCorpInvoiceDynamicControls {

    constructor(millcorpinvoicePartial?: IMillCorpInvoice, additionalParameters?: IMillCorpInvoiceDynamicControlsParameters) {
        super(millcorpinvoicePartial, additionalParameters);

        (<DynamicField>this.Form.DriversName).labelHtml = '<label>Driver\'s Name</label>';

        (<DynamicField>this.Form.MiscPickUpValue).labelHtml = '<label>Misc Pick Up</label>';
        (<DynamicField>this.Form.MiscPickUpDescription).labelHtml = '<label>Description</label>';

        (<DynamicField>this.Form.PalletQty).labelHtml = '<label>Number of Pallets</label';
        (<DynamicField>this.Form.PalletRate).labelHtml = '<label>Rate</label';

        (<DynamicField>this.Form.StopQty).labelHtml = '<label>Number of Stops</label';
        (<DynamicField>this.Form.StopRate).labelHtml = '<label>Rate</label';

        (<DynamicField>this.Form.TopQty).labelHtml = '<label>Number of Tops</label';
        (<DynamicField>this.Form.TopRate).labelHtml = '<label>Rate</label';

        (<DynamicField>this.Form.WaitingQty).labelHtml = '<label>Waiting Hours</label';
        (<DynamicField>this.Form.WaitingRate).labelHtml = '<label>Rate</label';

        (<DynamicField>this.Form.WastePaperValue).labelHtml = '<label>Waste Paper</label>';

        (<DynamicField>this.Form.DateOfDelivery).type.inputType = InputTypes.DateInput;
    }
}
