import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IGroupConfiguration } from '../model/interfaces/group-configuration';

@Injectable()
export class GroupConfigurationService extends MetaItemService<IGroupConfiguration> {
    constructor(public http: HttpClient) {
        super('GroupConfigurationService', 'Configuration', 'ConfigurationIds', '/groupconfigurations', http);
    }
}
