import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IManufacturingOrderDetail } from '../model/interfaces/manufacturing-order-detail';
import { BaseService } from '@mt-ng2/base-service';

@Injectable()
export class ManufacturingOrderDetailService extends BaseService<IManufacturingOrderDetail> {
    constructor(public http: HttpClient) {
        super('/salesorders/ManufacturingOrderDetail', http);
    }
}
