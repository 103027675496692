import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../customer.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Output, EventEmitter, Component } from '@angular/core';

@Component({
    template: '',
})
export class CustomersEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    @Output('onDataChange') onDataChange = new EventEmitter<any>();

    constructor(private customerService: CustomerService, private notificationsService: NotificationsService) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    accessors: ['CustomerName'],
                    name: 'Customer Name',
                }),
                new EntityListColumn({
                    accessors: ['CustomerPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                }),
                new EntityListColumn({
                    accessors: ['CustomerAddresses'],
                    name: 'Address',
                    pipes: ['primary', 'address'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (customer: ICustomer) => {
                        return customer.Archived ? 'Unarchive' : 'Archive';
                    },
                    linkFunction: (customer: ICustomer) => {
                        this.customerService.setArchived(customer.Id, customer.Version, !customer.Archived).subscribe((newVersion) => {
                            customer.Version = newVersion;
                            customer.Archived = !customer.Archived;

                            this.notificationsService.success(`Customer successfully ${customer.Archived ? '' : 'un'}archived.`);

                            this.onDataChange.emit();
                        });
                    },
                    name: '',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            rowClass: (customer: ICustomer) => {
                return customer.Archived ? 'archived' : '';
            },
        });
    }
}
