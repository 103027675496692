import { Component, Input, OnInit } from '@angular/core';
import { MetaItemManagedListComponent } from '@mt-ng2/managed-list-control';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'custom-meta-item-managed-list',
    template: `
        <custom-mt-managed-list
            [componentTitle]="title"
            [items]="items"
            [sortable]="sortable"
            [sortPropertyName]="sortPropertyName"
            [duplicateCheck]="duplicateCheck"
            [duplicateCheckFields]="duplicateCheckFields"
            [dynamicForm]="dynamicForm"
            [fields]="fields"
            [canAdd]="canAdd"
            [canRemove]="canRemove"
            [customColWidths]="customColWidths"
            (onSave)="saveItems($event)"
        ></custom-mt-managed-list>
    `,
})
export class CustomMetaItemManagedListComponent extends MetaItemManagedListComponent {
    @Input() customColWidths: number[] = [];

    constructor(private notificationService: NotificationsService) {
        super(notificationService);
    }
}
