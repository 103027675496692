import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IDailyRunLostTime } from '../interfaces/daily-run-lost-time';
import { IDailyRunEntry } from '../interfaces/daily-run-entry';

export interface IDailyRunLostTimeDynamicControlsParameters {
    formGroup?: string;
    dailyRunEntries?: IDailyRunEntry[];
}

export class DailyRunLostTimeDynamicControls {

    formGroup: string;
    dailyRunEntries?: IDailyRunEntry[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dailyrunlosttime?: IDailyRunLostTime, additionalParameters?: IDailyRunLostTimeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DailyRunLostTime';
        this.dailyRunEntries = additionalParameters && additionalParameters.dailyRunEntries || undefined;

        this.Form = {
            DailyRunEntryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Daily Run Entry',
                name: 'DailyRunEntryId',
                options: this.dailyRunEntries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.dailyrunlosttime && this.dailyrunlosttime.DailyRunEntryId || null,
            }),
            Down: new DynamicField({
                formGroup: this.formGroup,
                label: 'Down',
                name: 'Down',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Down') && this.dailyrunlosttime.Down != null ? this.dailyrunlosttime.Down.toString() : '',
            }),
            Reason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reason',
                name: 'Reason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Reason') && this.dailyrunlosttime.Reason != null ? this.dailyrunlosttime.Reason.toString() : '',
            }),
            Up: new DynamicField({
                formGroup: this.formGroup,
                label: 'Up',
                name: 'Up',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Up') && this.dailyrunlosttime.Up != null ? this.dailyrunlosttime.Up.toString() : '',
            }),
        };

        this.View = {
            DailyRunEntryId: new DynamicLabel({
                label: 'Daily Run Entry',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.dailyRunEntries as unknown as IMetaItem[], this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('DailyRunEntryId') ? this.dailyrunlosttime.DailyRunEntryId : null) ?? '',
            }),
            Down: new DynamicLabel({
                label: 'Down',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Down') && this.dailyrunlosttime.Down != null ? this.dailyrunlosttime.Down.toString() : '',
            }),
            Reason: new DynamicLabel({
                label: 'Reason',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Reason') && this.dailyrunlosttime.Reason != null ? this.dailyrunlosttime.Reason.toString() : '',
            }),
            Up: new DynamicLabel({
                label: 'Up',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunlosttime && this.dailyrunlosttime.hasOwnProperty('Up') && this.dailyrunlosttime.Up != null ? this.dailyrunlosttime.Up.toString() : '',
            }),
        };

    }
}
