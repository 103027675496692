import { OrderInfoDynamicControls } from '../form-controls/order-info.form-controls';
import { IOrderInfo } from '../interfaces/order-info';
import { DynamicFieldType, DynamicFieldTypes, NumericInputTypes, DynamicField, SelectInputTypes, LabelPositions } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { IOrderPriceType } from '../interfaces/order-price-type';
import { IUser } from '../interfaces/user';
import { OverrideNumericFunctionsEmptyWhenZero } from '../../common/common-functions.form-controls';
import { ICommissionSalesPerson } from '@model/interfaces/commission-sales-person';

export class OrderInfoDynamicControlsPartial extends OrderInfoDynamicControls {
    constructor(
        private orderInfoPartial: IOrderInfo,
        private salesPeoplePartial: IUser[],
        private orderPricingTypesPartial: IOrderPriceType[],
        private commissionSalesPeoplePartial: ICommissionSalesPerson[],
    ) {
        super(orderInfoPartial, {
            commissionSalesPeople: commissionSalesPeoplePartial.map(
                (sp) => ({ Id: sp.Id, Name: sp.Id ? `${sp.FirstName} ${sp.LastName}` : '' } as any),
            ),
            customerSalesPeople: salesPeoplePartial.map((sp) => ({ Id: sp.Id, Name: `${sp.FirstName} ${sp.LastName}` } as any)),
            formGroup: 'OrderInfo',
            orderPriceTypes: orderPricingTypesPartial,
        });
        this.Form.TradeDiscountPercentage.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Percentage,
            scale: 2,
        });
        this.Form.CustomerSalesPersonId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });
        this.Form.CommissionSalesPersonId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.TypeAhead,
            scale: 1,
        });
        this.Form.PricePer.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            scale: 2,
        });
        (<DynamicField>this.Form.CommissionSalesPersonId).labelPosition.position = LabelPositions.Hidden;
        (this.Form.OrderPriceTypeId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Order Price Type',
            name: 'OrderPriceTypeId',
            options: this.orderPriceTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                this.orderInfoPartial && this.orderInfoPartial.hasOwnProperty('OrderPriceTypeId') && this.orderInfoPartial.OrderPriceTypeId !== null
                    ? this.orderInfoPartial.OrderPriceTypeId
                    : null,
        })),
            (this.Form.CustomerShipVia.labelHtml = '<label>Via</label>');
        this.Form.CustomerPurchaseOrderNumber.labelHtml = '<label>Customer Purchase Order #</label>';
        this.Form.TradeDiscountPercentage.validation.push(Validators.max(99.99));
        this.Form.TradeDiscountPercentage.validators.max = 99.99;
        this.Form.IsOurMill.labelHtml = '<label>FOB OUR MILL</label>';
        this.Form.IsCreditCardPurchase.label = 'Credit Card Purchase';
        this.Form.RunWeekOf.labelHtml = '<label>When</label>';
        this.Form.FreightNotes.labelHtml = '<label></label>';

        // Default RunWeekOf and OrderPriceType on new orders
        if (this.orderInfoPartial.Id === 0) {
            this.Form.RunWeekOf.value = 'Run Week Of';
            this.Form.OrderPriceTypeId.value = OrderPriceTypes.M;
        }

        // Force fields to be empty instead of showing '0' when value is null.
        OverrideNumericFunctionsEmptyWhenZero([this.Form.TradeDiscountPercentage, this.Form.PricePer]);

        // Limit characters to 93 to prevent text overlap in sales order form
        this.Form.CustomerShipVia.validation = [ Validators.maxLength(93) ];
        this.Form.CustomerShipVia.validators.maxlength = 93;
    }
}

enum OrderPriceTypes {
    M = 1,
    B = 2,
    P = 3,
    S = 4,
    R = 5,
}
