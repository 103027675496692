<h2>Trim Sheet Settings</h2>
<div>
    <div class="row">
        <div class="col-md-6">
            <meta-item-managed-list
                [service]="sectionHeadingService"
                [dynamicForm]="sectionHeadingDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <custom-meta-item-managed-list
                [service]="testStackService"
                [dynamicForm]="testStackDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
                [customColWidths]="[0,4]"
            ></custom-meta-item-managed-list>
        </div>

        <div class="col-md-6">
            <meta-item-managed-list
                [service]="groupConfigurationService"
                [dynamicForm]="groupConfigurationDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
        </div>
    </div>
</div>
<br />
<a routerLink="./trim-configurations" class="btn btn-default btn-flat">Close</a>
