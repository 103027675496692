import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@common/custom-validators';
import { IShipmentDetail } from '@model/interfaces/shipment-detail';
import { SalesOrderService } from '../../sales-orders/sales-order.service';

@Component({
    selector: 'take-from-order',
    styles: [
        `
            .error {
                border: 1px solid red;
            }

            .error-text-container {
                font-size: 11px;
                vertical-align: top;
                color: red;
            }

            .warning {
                font-size: 11px;
                vertical-align: top;
                color: #e68a00;
            }

            .order-and-quantity-row {
                margin: 2% 0 2% 0;
                padding: 2% 0 2% 0;
            }
        `,
    ],
    templateUrl: 'take-from-order.component.html',
})
export class TakeFromOrderComponent implements OnInit {
    _shipmentDetail: IShipmentDetail;
    @Input()
    set shipmentDetail(shipmentDetail: IShipmentDetail) {
        this._shipmentDetail = shipmentDetail;
        // can't set anything up when component is first rendered if there is no selected orderId in the parent component yet
        if (!shipmentDetail) {
            return;
        }

        this.ordersAndQuantities.clear();
        this.populateOrdersAndQuantities();
        if (shipmentDetail.TakeFromDetails) {
            this.ordersAndQuantities.patchValue(shipmentDetail.TakeFromDetails);
            this.ordersAndQuantities.controls
                .forEach((control, idx) => this.checkDifferenceInSize(control, idx));
        }
    }
    get shipmentDetail(): IShipmentDetail {
        return this._shipmentDetail;
    }

    @Output() onGetAvailableSkids: EventEmitter<FormArray> = new EventEmitter<FormArray>();

    ordersForm: FormGroup;
    defaultNumberOfInputs = 5;

    differInSizeErrors: boolean[] = [];

    get ordersAndQuantities(): FormArray {
        return this.ordersForm?.controls?.OrdersAndQuantities as FormArray;
    }

    constructor(
        private shipmentService: ShipmentService,
        private salesOrderService: SalesOrderService,
        private notificationsService: NotificationsService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.ordersForm = this.fb.group({
            OrdersAndQuantities: this.fb.array([]),
        });
    }

    populateOrdersAndQuantities(): void {
        const count = this.shipmentDetail.TakeFromDetails?.length || this.defaultNumberOfInputs;

        for (let i = 0; i < count; i++) {
            this.ordersAndQuantities.push(this.getEmptyTakeFromOrderLine());
            this.differInSizeErrors.push(false);
        }
    }

    addInput(): void {
        this.ordersAndQuantities.push(this.getEmptyTakeFromOrderLine());
        this.differInSizeErrors.push(false);
    }

    clear(index: number): void {
        this.ordersAndQuantities.controls[index].reset({ Id: 0 });
        this.differInSizeErrors[index] = false;
        this.updateValidation(this.ordersAndQuantities);
    }

    formSubmitted(): void {
        if (!this.ordersForm.valid) {
            this.notificationsService.error('Form is invalid');
            return;
        }

        const toReturn = new FormArray([]);
        this.ordersAndQuantities.controls.forEach((c: FormGroup) => {
            const v = c.getRawValue();
            if (v.TakeFromSalesOrderId > 0 && v.Quantity > 0) {
                const takeFrom = this.fb.group({
                    Id: this.fb.control(0),
                    Quantity: this.fb.control(null),
                    TakeFromSalesOrderId: this.fb.control(null),
                });
                takeFrom.patchValue(v);
                toReturn.push(takeFrom);
            }
        });

        this.onGetAvailableSkids.emit(toReturn);
    }

    getEmptyTakeFromOrderLine(): FormGroup {
        return this.fb.group(
            {
                Id: this.fb.control({ value: 0, disabled: true }),
                Quantity: this.fb.control(null, [Validators.min(1)]),
                TakeFromSalesOrderId: this.fb.control(null),
            },
            {
                asyncValidators: [
                    CustomValidators.OrderHasEnoughSkidsToTakeRequestedQuantity(this.shipmentService, this.shipmentDetail.SalesOrderId),
                ],
                validators: [CustomValidators.AllOrNone, CustomValidators.NoDuplicateControlValues(this.ordersAndQuantities, 'TakeFromSalesOrderId')],
            },
        );
    }

    updateValidation(formArray: FormArray): void {
        formArray.controls.forEach((c) => c.updateValueAndValidity());
    }

    checkDifferenceInSize(fg: any, idx: number): void {
        const takeFromSalesOrderId
            = (<FormControl>fg.controls.TakeFromSalesOrderId).value;
        const giveToSalesOrderId = this.shipmentDetail?.SalesOrderId;

        if (takeFromSalesOrderId && giveToSalesOrderId) {
            this.salesOrderService
                .areDifferentInSize(takeFromSalesOrderId, giveToSalesOrderId)
                .subscribe((areDifferent) => {
                    this.differInSizeErrors[idx] = areDifferent;
                });
        }
    }
}
