import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SkidService } from '../../skids/skid.service';
import { finalize } from 'rxjs/operators';
import { StockItemsService } from '../../sales-orders/stockitem.service';

@Component({
    selector: 'load-skid',
    templateUrl: './load-skid.component.html',
})
export class LoadSkidComponent {
    skidNumber: string;
    doubleClickIsDisabled = false;
    isStockItem = false;

    @Input('shipmentId') shipmentId: number;
    @Output('onSkidLoaded') onSkidLoaded: EventEmitter<number> = new EventEmitter<number>();
    @Output('onCancel') onCancel: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('skidNumberInput', { read: ElementRef }) skidNumberInput: ElementRef;

    constructor(private skidService: SkidService, private shipmentService: ShipmentService, private notificationsService: NotificationsService, private stockItemService: StockItemsService) {}

    ngAfterViewInit(): void {
        this.skidNumberInput.nativeElement.focus();
    }

    formSubmitted(): void {
        this.doubleClickIsDisabled = true;
        if (this.isStockItem === false) {
            this.skidService.getByFinalBarcode(this.skidNumber).subscribe((skid) => {
                if (!skid) {
                    this.notificationsService.error('Skid not found.');
                    this.enableDoubleClick();
                    return;
                }

                this.shipmentService
                    .addSkidToShipment(this.shipmentId, skid.Id)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.notificationsService.success(`Skid successfully added to load #${this.shipmentId}.`);
                        this.skidNumber = '';
                        this.skidNumberInput.nativeElement.focus();
                        this.onSkidLoaded.emit(this.shipmentId);
                    });
            });
        } else {
            this.stockItemService.getByStockIdNumber(this.skidNumber).subscribe((stockItem) => {
                if (!stockItem) {
                    this.notificationsService.error('Stock item not found.');
                    this.enableDoubleClick();
                    return;
                }

                this.shipmentService
                    .addStockItemToShipment(this.shipmentId, stockItem.Id)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.notificationsService.success(`Skid successfully added to load #${this.shipmentId}.`);
                        this.skidNumber = '';
                        this.skidNumberInput.nativeElement.focus();
                        this.onSkidLoaded.emit(this.shipmentId);
                    });
            });
        }
    }

    cancel(): void {
        this.onCancel.emit();
    }

    toggleTagType(): void {
        this.isStockItem = !this.isStockItem;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
