import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IBoardGrade } from '../model/interfaces/board-grade';
import { Observable } from 'rxjs';
import { ICommissionSalesPerson } from '@model/interfaces/commission-sales-person';

@Injectable()
export class CommissionSalesPersonService extends MetaItemService<ICommissionSalesPerson> {
    constructor(public http: HttpClient) {
        super('CommissionSalesPersonService', 'Commission Sales Person', 'CommissionSalesPersonIds', '/salesorders/commission-sales-persons', http);
    }
}
