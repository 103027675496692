<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button></route-back-button>
                Sales Order
                <span *ngIf="salesOrder">#{{ getFormattedOrderNumber(salesOrder) }}</span>
                <span *ngIf="salesOrder?.Archived" class="text-danger">*ARCHIVED*</span>
            </h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-12">
    <form class="miles-form padded row" [formGroup]="orderForm" (ngSubmit)="save()">
        <h4>Sales Order Info</h4>
        <div class="row">
            <div class="col-md-4">
                <dynamic-field [field]="abstractOrderInfoControls.OrderDate" [form]="orderForm"></dynamic-field>
                <button type="button" class="btn btn-flat" (click)="toggleCustomerInfo()">
                    <span>{{ showCustomerInfo ? 'Hide' : 'Show' }}</span>
                    Customer Info
                </button>
            </div>
            <div class="col-md-3">
                <div>
                    <p>
                        <label>Order Entered By:</label><br />
                        <span *ngIf="customerSalesPerson"> {{ customerSalesPerson?.FirstName }} {{ customerSalesPerson?.LastName }} </span>
                    </p>
                </div>
            </div>
            <div class="col-md-3 m-t-xs">
                <button
                    *ngIf="customerIsCreditHold && canProvideCreditAuthorization"
                    type="button"
                    class="btn btn-default m-t-md"
                    (click)="showCreditAuthModal()"
                >
                    Credit Authorization
                </button>
                <button
                    *ngIf="formIsLocked && canEditOrderAnyStatus"
                    type="button"
                    class="btn btn-default m-t-md pull-right"
                    (click)="edit()"
                >
                    Edit Order
                </button>
            </div>
            <div class="col-md-2">
                <dynamic-field [field]="abstractOrderControls.OrderStatusId" [form]="orderForm" [overrideForm]="true"></dynamic-field>
            </div>
        </div>
        <div *ngIf="showCustomerInfo" [@fadeInOut]>
            <hr />
            <div class="row">
                <div class="col-md-3" style="font-size: 24px">
                    <span>
                        <b>Customer:</b>
                    </span>
                    <br />
                    <span>{{ customer.CompanyName }}</span>
                    <br />
                    <span [innerHTML]="addressString"></span>
                </div>
                <div class="col-md-6 col-md-offset-3" style="border-width: 0px 0px 0px 1px; border-style: solid; border-color: #d2d6de">
                    <div class="col-sm-9 input-group input-group-lg">
                        <div *ngIf="customerShippingAddresses">
                            <label for="select-address">
                                <h3>Select a Shipping Address:</h3>
                            </label>
                            <select
                                [(ngModel)]="customerShippingAddressId"
                                id="select-address"
                                class="form-control"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="formIsLocked"
                            >
                                <option
                                    *ngFor="let address of customerShippingAddresses"
                                    [ngValue]="address.Id"
                                    [selected]="address.IsPrimary ? true : null"
                                    [disabled]="address.Archived"
                                >
                                    <span>{{ address | shippingAddress : 'name' : 'primary' : 'archived' }}</span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <label>Consignment:</label
                    ><input type="checkbox" [(ngModel)]="isConsignment" [ngModelOptions]="{ standalone: true }" [disabled]="formIsLocked" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-xs-4">
                <dynamic-field
                    [form]="orderForm"
                    [field]="abstractOrderDetailControls.BoardGradeId"
                    (valueChanges)="onBoardGradeChange($event)"
                ></dynamic-field>
                <div class="custom-prop">
                    <button type="button" class="btn btn-default" (click)="clearBoardGrade()" [disabled]="formIsLocked" tabindex="-1">Clear</button>
                </div>
            </div>
            <div class="col-xs-4">
                <dynamic-field *ngIf="!isCustomLining" [form]="orderForm" [field]="abstractOrderDetailControls.LiningId"></dynamic-field>
                <dynamic-field *ngIf="isCustomLining" [form]="orderForm" [field]="abstractOrderDetailControls.LiningCustom"></dynamic-field>
                <div class="custom-prop">
                    <button
                        type="button"
                        class="btn btn-default"
                        (click)="clearLining()"
                        [disabled]="formIsLocked"
                        tabindex="-1"
                    >
                        Clear
                    </button>
                    <a href *ngIf="!formIsLocked" class="small" (click)="toggleCustomLining(); $event.preventDefault()" tabindex="-1"
                        >{{ isCustomLining ? 'Normal' : 'Custom' }} Lining?</a
                    >
                </div>
            </div>
            <div class="col-xs-4">
                <dynamic-field *ngIf="!isCustomFinish" [form]="orderForm" [field]="abstractOrderDetailControls.FinishId"></dynamic-field>
                <dynamic-field *ngIf="isCustomFinish" [form]="orderForm" [field]="abstractOrderDetailControls.FinishCustom"></dynamic-field>
                <div class="custom-prop">
                    <button type="button" class="btn btn-default" (click)="clearFinish()" [disabled]="formIsLocked" tabindex="-1">Clear</button>
                    <a href *ngIf="!formIsLocked" class="small" (click)="toggleCustomFinish(); $event.preventDefault()" tabindex="-1"
                        >{{ isCustomFinish ? 'Normal' : 'Custom' }} Finish?</a
                    >
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <hr />
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="horizontalSpacing">
                                <dynamic-field
                                    [form]="orderForm"
                                    (blur)="calculateBundlesPerSkid(); calculateHeight()"
                                    [field]="abstractOrderDetailControls.Quantity"
                                ></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.UnitOfMeasureId" (blur)="calculateBundlesPerSkid()"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.Width"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.Length"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <span class="control-label"><label>Basis</label></span>
                                <input
                                    class="form-control caliper-input"
                                    type="number"
                                    step="0.1"
                                    [(ngModel)]="formattedBasis"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="parseBasisFormatting($event)"
                                    [disabled]="formIsLocked"
                                />
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field
                                    [form]="orderForm"
                                    (blur)="calculateHeight(); calculateRuleNumber()"
                                    [field]="abstractOrderDetailControls.Count"
                                ></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <span class="control-label"
                                    ><label>Caliper</label
                                    ><span *ngIf="!isDressStock" class="errortext ng-star-inserted" style="font-weight: 100"> *</span></span
                                >
                                <input
                                    class="form-control caliper-input"
                                    type="number"
                                    step="0.001"
                                    [(ngModel)]="formattedCaliper"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="parseCaliperFormatting($event)"
                                    [disabled]="formIsLocked"
                                />
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.RuleNumber"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field
                                    [form]="orderForm"
                                    (blur)="calculateBundlesPerSkid(); calculateHeight()"
                                    [field]="abstractOrderDetailControls.NumberOfSkids"
                                ></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field
                                    [form]="orderForm"
                                    (blur)="calculateHeight()"
                                    [field]="abstractOrderDetailControls.Piles"
                                ></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.BdlSk"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.Height"></dynamic-field>
                            </td>
                            <td style="min-width: 110px;">
                                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.BandingInstructionId" [form]="orderForm"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [field]="abstractOrderCustomerSpecificationControls.IsPileUp" [form]="orderForm"></dynamic-field>
                            </td>
                            <td class="horizontalSpacing">
                                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.ShipOnTypeId"></dynamic-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button type="button" *ngIf="!formIsLocked" class="btn btn-flat" (click)="toggleConverting()">
                {{ converting ? 'Hide' : 'Show' }} Converting
            </button>
        </div>
        <div *ngIf="converting" formArrayName="OrderDetailLines" [@fadeInOut] class="row col-md-12">
            <h4>Converting Lines</h4>
            <order-detail-lines
                *ngIf="salesOrder"
                [salesOrderId]="salesOrderId"
                [OrderDetailLines]="orderForm.controls.OrderDetailLines"
                [ManufacturingOrderDetail]="orderForm.controls.ManufacturingOrderDetail"
                [values]="salesOrder.OrderDetailLines"
                [salesOrderStatusId]="salesOrder.OrderStatusId"
                [formIsLocked]="formIsLocked"
                [orderCaliper]="formattedCaliper"
            ></order-detail-lines>
            <order-detail-lines
                *ngIf="!salesOrder"
                [salesOrderId]="salesOrderId"
                [OrderDetailLines]="orderForm.controls.OrderDetailLines"
                [ManufacturingOrderDetail]="orderForm.controls.ManufacturingOrderDetail"
                [values]="[]"
                [salesOrderStatusId]="OrderStatusIds.Open"
                [formIsLocked]="formIsLocked"
                [orderCaliper]="formattedCaliper"
            ></order-detail-lines>
        </div>
        <div class="row col-md-12">
            <hr />
            <div class="col-md-6">
                <newman-horizontal
                    [parentForm]="orderForm"
                    [dynamicForm]="abstractOrderInfoControls"
                    [fields]="['TradeDiscountPercentage', 'IsCreditCardPurchase', 'CustomerTerms']"
                ></newman-horizontal>
                <hr />
                <div class="row">
                    <div class="col-xs-3">
                        <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.PricePer"></dynamic-field>
                    </div>
                    <div class="col-xs-3">
                        <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.OrderPriceTypeId"></dynamic-field>
                    </div>
                    <div class="col-xs-3">
                        <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.IsOurMill"></dynamic-field>
                        <dynamic-field
                            [form]="orderForm"
                            [field]="abstractOrderDetailControls.IsCommission"
                            (valueChanges)="toggleCommission($event)"
                        ></dynamic-field>
                        <div *ngIf="isCommission">
                            <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.CommissionSalesPersonId"></dynamic-field>
                        </div>
                    </div>
                    <div class="col-xs-3">
                        <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.FreightNotes"></dynamic-field>
                    </div>
                </div>
                <hr />
                <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.GeneralLedgerCodeId"></dynamic-field>
            </div>
            <div class="col-md-6" style="border-width: 0px 0px 0px 1px; border-style: solid; border-color: #d2d6de">
                <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.CustomerShipVia"></dynamic-field>
                <hr />
                <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.RunWeekOf"></dynamic-field>
                <hr />
                <div class="dynamic-field-container">
                    <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.IsConverting"></dynamic-field>
                </div>
                <div class="dynamic-field-container">
                    <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.IsLiningDept"></dynamic-field>
                </div>
                <div class="dynamic-field-container">
                    <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.IsBridgeView"></dynamic-field>
                </div>
                <div class="dynamic-field-container">
                    <dynamic-field [form]="orderForm" [field]="abstractOrderDetailControls.IsFscCertified"></dynamic-field>
                </div>
                <div class="dynamic-field-container">
                    <dynamic-field
                        [form]="orderForm"
                        [field]="abstractOrderDetailControls.IsDressStock"
                        (valueChanges)="onDressStockChange($event)"
                    ></dynamic-field>
                </div>
                <hr />
                <div class="col-md-6">
                    <div class="row form-group">
                        <div class="col-xs-12">
                            <button
                                type="button"
                                [disabled]="!(salesOrder && customer)"
                                class="btn btn-flat"
                                (click)="navigateToOrderCustomerSpecs()"
                            >
                                Specs / Details
                            </button>
                            <button type="button" [disabled]="!(salesOrder && customer)" class="btn btn-flat" (click)="navigateToPalletInfo()">
                                Pallet Info
                            </button>
                            <button type="button" [disabled]="!(salesOrder && customer)" class="btn btn-flat" (click)="navigateToShippingStatus()">
                                Shipping Status
                            </button>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-xs-12">
                            <button
                                type="button"
                                *ngIf="canPrintOrders"
                                (click)="showPrintModal()"
                                [disabled]="!(salesOrder && customer)"
                                class="btn btn-flat"
                            >
                                <i class="fa fa-print"></i>
                                &nbsp;Print Forms
                            </button>

                            <button
                                type="button"
                                (click)="confirmPrintAndLock()"
                                [disabled]="!(salesOrder && customer && !formIsLocked)"
                                class="btn btn-flat"
                            >
                                <i class="fa fa-lock"></i>
                                &nbsp;Print & Lock
                            </button>

                            <button
                                type="button"
                                style="margin-top: 10px"
                                *ngIf="canAccessMillComplaints"
                                (click)="navigateToMillComplaints()"
                                [disabled]="!(salesOrder && customer)"
                                class="btn btn-flat"
                            >
                                <i class="fa fa-book"></i>
                                &nbsp;Mill Complaints
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <dynamic-field [form]="orderForm" [field]="abstractOrderInfoControls.CustomerPurchaseOrderNumber"></dynamic-field>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <button
                *ngIf="canSave"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                class="btn btn-flat btn-success"
                type="submit"
            >
                Save
            </button>
            <button class="btn btn-flat btn-default" (click)="cancelClick()" type="button">Cancel</button>
            <button
                *ngIf="salesOrder && canArchive"
                type="button"
                class="btn btn-flat"
                [ngClass]="salesOrder.Archived ? 'btn-success' : 'btn-danger'"
                (click)="toggleSalesOrderArchived()"
            >
                {{ salesOrder.Archived ? 'Un-Archive' : 'Archive' }}
            </button>
            <button *ngIf="salesOrder" class="btn btn-flat btn-default" (click)="copyOrder()" type="button">Copy Order</button>
        </div>
    </form>
</div>

<modal-wrapper
    *ngIf="salesOrder"
    [options]="printModalOptions"
    [autoShow]="false"
    title="Printed Forms"
    (ready)="printModal = $event"
    (okClick)="printForms()"
    (cancelClick)="closePrintModal()"
>
    <div class="text-left">
        <h5>Which form(s) would you like to print?</h5>
        <input id="acknowledgementCopy" type="checkbox" [(ngModel)]="printFormsOptions.AcknowledgementCopy" />
        <label for="acknowledgementCopy" class="font-weight-normal small">Acknowledgement Copy</label>
        <br />
        <input id="officeCopy" type="checkbox" [(ngModel)]="printFormsOptions.OfficeCopy" />
        <label for="officeCopy" class="font-weight-normal small">Office Copy</label>
        <br />
        <input id="papermakersCopy" type="checkbox" [(ngModel)]="printFormsOptions.PapermakersCopy" />
        <label for="papermakersCopy" class="font-weight-normal small">Papermaker's Copy</label>
        <br />
        <input id="shippersCopy" type="checkbox" [(ngModel)]="printFormsOptions.ShippersCopy" />
        <label for="shippersCopy" class="font-weight-normal small">Shipper's Copy</label>
        <br />
        <input id="pastingCuttingCopy" type="checkbox" [(ngModel)]="printFormsOptions.PastingCuttingCopy" />
        <label for="pastingCuttingCopy" class="font-weight-normal small">Pasting/Cutting Copy</label>
        <br />
        <input id="liningCopy" type="checkbox" [(ngModel)]="printFormsOptions.LiningCopy" />
        <label for="liningCopy" class="font-weight-normal small">Lining Copy</label>
        <ng-container *ngIf="salesOrderIsNotOpen">
            <br />
            <input id="salesOrderForm" type="checkbox" [(ngModel)]="printFormsOptions.IsPrintAndLock" />
            <label for="salesOrderForm" class="font-weight-normal small">Sales Order Form</label>
        </ng-container>
    </div>
</modal-wrapper>

<modal-wrapper
    *ngIf="salesOrder && creditAuthStatuses?.length"
    [options]="creditAuthModalOptions"
    [autoShow]="false"
    (ready)="creditAuthModal = $event"
    title="Credit Authorization"
    (okClick)="saveCreditAuth()"
    (cancelClick)="closeCreditAuthModal()"
>
    <div class="text-left">
        <h4>Authorization Date: {{ salesOrder.CreditAuthDate | date : 'short' }}</h4>
        <div class="m-t-md form-inline text-center">
            <label *ngFor="let status of creditAuthStatuses" class="radio-inline p-x-md">
                <input type="radio" [value]="status.Id" [(ngModel)]="selectedCreditAuthStatus" /> {{ status.Name }}
            </label>
        </div>
    </div>
</modal-wrapper>
