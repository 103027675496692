import { DynamicField, SelectInputTypes, InputTypes, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

import { TrimSheetDynamicControls, ITrimSheetDynamicControlsParameters } from '../form-controls/trim-sheet.form-controls';
import { ITrimSheet } from '../interfaces/trim-sheet';

export class TrimSheetDynamicControlsPartial extends TrimSheetDynamicControls {
    constructor(trimsheetPartial?: ITrimSheet, additionalParameters?: ITrimSheetDynamicControlsParameters) {
        super(trimsheetPartial, additionalParameters);

        (<DynamicField>this.Form.SectionHeadingId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.GroupConfigurationId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.SetupId).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.SetupId).type.fieldType = DynamicFieldTypes.Input;
        (<DynamicField>this.Form.WeekOf).type.inputType = InputTypes.DateInput;
        (<DynamicField>this.Form.Comment).type.inputType = InputTypes.Textarea;
    }
}
