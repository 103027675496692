import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ICreditAuthorizationStatus } from '@model/interfaces/credit-authorization-status';

@Injectable({
    providedIn: 'root',
})
export class CreditAuthStatusService extends StaticMetaItemService<ICreditAuthorizationStatus> {
    constructor(public http: HttpClient) {
        super('CreditAuthStatusService', 'Status', 'StatusIds', '/credit-auth-statuses', http);
    }
}
