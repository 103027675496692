import { ICustomer } from '../model/interfaces/customer';
import { ICustomerPaymentTerm } from '../model/interfaces/customer-payment-term';
import { ICustomerCreditStatus } from '../model/interfaces/customer-credit-status';
import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { CustomerDynamicControlsPartial } from '../model/partials/customer.form-controls';
import { IUser } from '../model/interfaces/user';
import { IShipOnType } from '../model/interfaces/ship-on-type';
import { IRunnerDirectionType } from '@model/interfaces/runner-direction-type';

export class CustomerDynamicConfig<T extends ICustomer> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private customer: T,
        private paymentterms: ICustomerPaymentTerm[],
        private creditstatuses: ICustomerCreditStatus[],
        private salespeople: IUser[],
        private shipontypes: IShipOnType[],
        private runnerDirectionTypes: IRunnerDirectionType[],
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new CustomerDynamicControlsPartial(
            this.customer,
            this.creditstatuses,
            this.paymentterms,
            this.salespeople,
            this.shipontypes,
            this.runnerDirectionTypes,
        );
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Code',
                'PrimarySalesPersonId',
                'SecondarySalesPersonId',
                'CompanyName',
                'CustomerName',
                'InvoiceName',
                'InvoiceEmail',
                'InvoiceNote',
                'PaymentTermId',
                'CreditStatusId',
                'IsAuthorizedForCreditCard',
                'ApexShippingManifest',
                'ShipVia',
                'ShipOnTypeId',
                'InchesBetweenRunners',
                'RunnerDirectionTypeId',
                'RunnerHeight',
                'SpecialNotes',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
