import { DynamicFieldType, DynamicFieldTypes, DynamicField, NumericInputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { CustomerOrderPriceInfoDynamicControls } from '../form-controls/customer-order-price-info.form-controls';
import { ICustomerOrderPriceInfo } from '../interfaces/customer-order-price-info';
import { ISalesOrder } from '../interfaces/sales-order';
import { IPricingChargePerType } from '../interfaces/pricing-charge-per-type';
import { Validators } from '@angular/forms';
import { PricingChargePerTypes } from '../PricingChargePerTypes';
import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { formatNumber } from '@angular/common';
import { ICornerCutType } from '@model/interfaces/corner-cut-type';

export function currencyDisplayFunction(value: number, type: NumericControlTypes, scale: number): string {
    return value !== null ? `${formatNumber(value, 'en-us', `${1}.${scale}-${scale}`)}` : '';
}

export class CustomerOrderPriceInfoDynamicControlsPartial extends CustomerOrderPriceInfoDynamicControls {
    constructor(
        customerOrderPricePartial?: ICustomerOrderPriceInfo,
        formGroupPartail?: string,
        pricingchargepertypesPartial?: IPricingChargePerType[],
        cornercutTypesPartial?: ICornerCutType[],
    ) {
        super(customerOrderPricePartial, {
            cornerCutTypes: cornercutTypesPartial,
            formGroup: formGroupPartail,
            pricingChargePerTypes: pricingchargepertypesPartial,
        });

        const currencyTypeWithPrecision = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            numericFunctions: {
                numberFormatDisplay: currencyDisplayFunction,
            },
            scale: 2,
        });

        this.Form.PricingChargePerTypeId.label = 'ALL CHARGES PER';
        this.Form.PricingChargePerTypeId.labelHtml = '<label>ALL CHARGES PER</label>';
        this.Form.PricingChargePerTypeId.value =
            customerOrderPricePartial && customerOrderPricePartial.PricingChargePerTypeId
                ? customerOrderPricePartial.PricingChargePerTypeId
                : PricingChargePerTypes.Ton;

        this.Form.Notes.label = 'Additional Pricing Notes';
        this.Form.Notes.labelHtml = '<label>Additional Pricing Notes</label>';

        this.Form.OtherOne.label = 'Other (1)';
        this.Form.OtherOne.labelHtml = '<label>Other (1)</label>';
        this.Form.OtherTwo.label = 'Other (2)';
        this.Form.OtherTwo.labelHtml = '<label>Other (2)</label>';
        this.Form.RateOne.label = 'Rate (1)';
        this.Form.RateOne.labelHtml = '<label>Rate (1)</label>';
        this.Form.RateTwo.label = 'Rate (2)';
        this.Form.RateTwo.labelHtml = '<label>Rate (2)</label>';

        this.Form.EnergySurchargePerTonName.value = 'Energy Surcharge';
        this.Form.EnergySurchargePerTonName.disabled = true;
        this.Form.EnergySurchargePerTonName.label = 'Energy Surcharge (Per Ton)';
        this.Form.EnergySurchargePerTonName.labelHtml = '<label>Energy Surcharge (Per Ton)</label>';
        this.Form.EnergySurchargePerTonRate.label = 'Rate Per Ton';
        this.Form.EnergySurchargePerTonRate.labelHtml = '<label>Rate Per Ton</label>';

        this.Form.CommissionPercent.label = 'Commission %';
        this.Form.CommissionPercent.labelHtml = '<label>Commission %</label>';

        this.Form.Frt.label = 'Frt (Freight)';
        this.Form.Frt.labelHtml = '<label>Frt (Freight)</label>';

        this.Form.BasePrice.type = currencyTypeWithPrecision;
        this.Form.Paste.type = currencyTypeWithPrecision;
        this.Form.Cut.type = currencyTypeWithPrecision;
        this.Form.BackTrim.type = currencyTypeWithPrecision;
        this.Form.AdditionalCut.type = currencyTypeWithPrecision;
        this.Form.BundleTied.type = currencyTypeWithPrecision;
        this.Form.BustInHalf.type = currencyTypeWithPrecision;
        this.Form.CornerCut.type = currencyTypeWithPrecision;
        this.Form.Flip.type = currencyTypeWithPrecision;
        this.Form.Waste.type = currencyTypeWithPrecision;
        this.Form.Frt.type = currencyTypeWithPrecision;
        this.Form.Qty.type = currencyTypeWithPrecision;
        this.Form.Stain.type = currencyTypeWithPrecision;
        this.Form.SkidCharge.type = currencyTypeWithPrecision;
        this.Form.RateOne.type = currencyTypeWithPrecision;
        this.Form.RateTwo.type = currencyTypeWithPrecision;
        this.Form.Commission.type = currencyTypeWithPrecision;
        this.Form.BasisWeight.type = currencyTypeWithPrecision;
        this.Form.MarkUp.type = currencyTypeWithPrecision;
        this.Form.EnergySurchargePerTonRate.type = currencyTypeWithPrecision;
        this.Form.RotarySlit.type = currencyTypeWithPrecision;
        this.Form.CornerCutTypeId.type.inputType = SelectInputTypes.Dropdown;

    }
}
