import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IRotarySlitDivisionLog } from '../interfaces/rotary-slit-division-log';
import { IRotaryType } from '../interfaces/rotary-type';
import { ISalesOrder } from '../interfaces/sales-order';

export interface IRotarySlitDivisionLogDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    rotaryTypes?: IRotaryType[];
}

export class RotarySlitDivisionLogDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];
    rotaryTypes: IRotaryType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private rotaryslitdivisionlog?: IRotarySlitDivisionLog, additionalParameters?: IRotarySlitDivisionLogDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'RotarySlitDivisionLog';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.rotaryTypes = additionalParameters && additionalParameters.rotaryTypes || undefined;

        this.Form = {
            Break: new DynamicField({
                formGroup: this.formGroup,
                label: 'Break',
                name: 'Break',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Break') && this.rotaryslitdivisionlog.Break !== null ? this.rotaryslitdivisionlog.Break : false,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Caliper || null,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Count || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('CustomerName') && this.rotaryslitdivisionlog.CustomerName !== null ? this.rotaryslitdivisionlog.CustomerName.toString() : '',
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Date || null,
            }),
            LastSkid: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Sk',
                name: 'LastSkid',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.LastSkid || null,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Length') && this.rotaryslitdivisionlog.Length !== null ? this.rotaryslitdivisionlog.Length.toString() : '',
            }),
            MinBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Min Bundles',
                name: 'MinBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.MinBundles || null,
            }),
            Operator1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Operator1',
                name: 'Operator1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Operator1') && this.rotaryslitdivisionlog.Operator1 !== null ? this.rotaryslitdivisionlog.Operator1.toString() : '',
            }),
            Operator2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Operator2',
                name: 'Operator2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Operator2') && this.rotaryslitdivisionlog.Operator2 !== null ? this.rotaryslitdivisionlog.Operator2.toString() : '',
            }),
            Remarks: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remarks',
                name: 'Remarks',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Remarks') && this.rotaryslitdivisionlog.Remarks !== null ? this.rotaryslitdivisionlog.Remarks : false,
            }),
            ReportId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report',
                name: 'ReportId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.ReportId || 1,
            }),
            RotaryTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rotary Type',
                name: 'RotaryTypeId',
                options: this.rotaryTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.RotaryTypeId || null,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.SalesOrderId || null,
            }),
            SetupTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Setup Time',
                name: 'SetupTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('SetupTime') && this.rotaryslitdivisionlog.SetupTime !== null ? this.rotaryslitdivisionlog.SetupTime.toString() : '',
            }),
            SheetsPerSkid: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sheets Per Sk',
                name: 'SheetsPerSkid',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.SheetsPerSkid || null,
            }),
            ShiftCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Code',
                name: 'ShiftCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('ShiftCode') && this.rotaryslitdivisionlog.ShiftCode !== null ? this.rotaryslitdivisionlog.ShiftCode.toString() : '',
            }),
            Skids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skids',
                name: 'Skids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Skids || null,
            }),
            TimeOff: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time Off',
                name: 'TimeOff',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('TimeOff') && this.rotaryslitdivisionlog.TimeOff !== null ? this.rotaryslitdivisionlog.TimeOff.toString() : '',
            }),
            TimeOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time On',
                name: 'TimeOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('TimeOn') && this.rotaryslitdivisionlog.TimeOn !== null ? this.rotaryslitdivisionlog.TimeOn.toString() : '',
            }),
            TotalSheets: new DynamicField({
                formGroup: this.formGroup,
                label: 'Total Sheets',
                name: 'TotalSheets',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.TotalSheets || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Width') && this.rotaryslitdivisionlog.Width !== null ? this.rotaryslitdivisionlog.Width.toString() : '',
            }),
        };

        this.View = {
            Break: new DynamicLabel(
                'Break',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Break') && this.rotaryslitdivisionlog.Break !== null ? this.rotaryslitdivisionlog.Break : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Bundles: new DynamicLabel(
                'Bundles',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CustomerName: new DynamicLabel(
                'Customer Name',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('CustomerName') && this.rotaryslitdivisionlog.CustomerName !== null ? this.rotaryslitdivisionlog.CustomerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Date: new DynamicLabel(
                'Date',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Date || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            LastSkid: new DynamicLabel(
                'Last Sk',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.LastSkid || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Length') && this.rotaryslitdivisionlog.Length !== null ? this.rotaryslitdivisionlog.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MinBundles: new DynamicLabel(
                'Min Bundles',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.MinBundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            Operator1: new DynamicLabel(
                'Operator1',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Operator1') && this.rotaryslitdivisionlog.Operator1 !== null ? this.rotaryslitdivisionlog.Operator1.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Operator2: new DynamicLabel(
                'Operator2',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Operator2') && this.rotaryslitdivisionlog.Operator2 !== null ? this.rotaryslitdivisionlog.Operator2.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Remarks: new DynamicLabel(
                'Remarks',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Remarks') && this.rotaryslitdivisionlog.Remarks !== null ? this.rotaryslitdivisionlog.Remarks : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReportId: new DynamicLabel(
                'Report',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.ReportId || 1,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RotaryTypeId: new DynamicLabel(
                'Rotary Type',
                getMetaItemValue(this.rotaryTypes, this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('RotaryTypeId') && this.rotaryslitdivisionlog.RotaryTypeId !== null ? this.rotaryslitdivisionlog.RotaryTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('SalesOrderId') && this.rotaryslitdivisionlog.SalesOrderId !== null ? this.rotaryslitdivisionlog.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SetupTime: new DynamicLabel(
                'Setup Time',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('SetupTime') && this.rotaryslitdivisionlog.SetupTime !== null ? this.rotaryslitdivisionlog.SetupTime.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SheetsPerSkid: new DynamicLabel(
                'Sheets Per Sk',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.SheetsPerSkid || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ShiftCode: new DynamicLabel(
                'Shift Code',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('ShiftCode') && this.rotaryslitdivisionlog.ShiftCode !== null ? this.rotaryslitdivisionlog.ShiftCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Skids: new DynamicLabel(
                'Skids',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.Skids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TimeOff: new DynamicLabel(
                'Time Off',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('TimeOff') && this.rotaryslitdivisionlog.TimeOff !== null ? this.rotaryslitdivisionlog.TimeOff.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeOn: new DynamicLabel(
                'Time On',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('TimeOn') && this.rotaryslitdivisionlog.TimeOn !== null ? this.rotaryslitdivisionlog.TimeOn.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TotalSheets: new DynamicLabel(
                'Total Sheets',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.TotalSheets || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.rotaryslitdivisionlog && this.rotaryslitdivisionlog.hasOwnProperty('Width') && this.rotaryslitdivisionlog.Width !== null ? this.rotaryslitdivisionlog.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
