import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { IShipment } from '../../model/interfaces/shipment';
import { ShipmentService, IShipmentBillOfLadingOptions } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PrintService } from '../../common/services/print.service';
import { PaymentNoteService } from '../payment-note.service';
import { Observable, forkJoin } from 'rxjs';
import { IPaymentNote } from '../../model/interfaces/payment-note';
import { PaymentNote } from '../../model/PaymentNotes';
import { finalize } from 'rxjs/operators';
import { ReceiptService } from '../../receipts/receipt.service';
import { IReceipt } from '@model/interfaces/receipt';

@Component({
    selector: 'bill-of-lading',
    templateUrl: './bill-of-lading.component.html',
})
export class BillOfLadingComponent implements OnInit {
    @Input('shipment') shipment: IShipment;
    @Output('canceled') canceled: EventEmitter<any> = new EventEmitter<any>();

    options: IShipmentBillOfLadingOptions;
    printNewmanAddress = false;
    selectedStops: number[];
    paymentNotes: IPaymentNote[];
    doubleClickIsDisabled: false;

    @ViewChild('customAddress', { read: ElementRef }) customAddressInput: ElementRef;

    constructor(
        private shipmentService: ShipmentService,
        private notificationsService: NotificationsService,
        private paymentNoteService: PaymentNoteService,
        private receiptService: ReceiptService,
    ) {}

    ngOnInit(): void {
        this.clearForm();
        this.options.PrintTotalSkids = true;
        this.options.IncludeLoadSheetSummary = true;

        this.options.Stops = this.shipment.ShipmentStops?.map((s) => s.Stop) ?? [];
        if (this.options.Stops.length > 0) {
            this.options.PaymentNoteId = this.shipment.Receipts.find((r) => r.Stop === this.options.Stops[0]).PaymentNoteId ?? PaymentNote.NA;
        }
        forkJoin([this.paymentNoteService.getItems()]).subscribe(([paymentNotes]) => {
            this.paymentNotes = paymentNotes;
        });
        this.initMainStopValue(this.options, this.shipment);
    }

    stopClicked(stop: number, checked: boolean): void {
        if (checked) {
            this.options.Stops.push(stop);
        } else {
            this.options.Stops.splice(this.options.Stops.indexOf(stop), 1);
        }
    }

    printNewmanAddressChanged(checked: boolean): void {
        this.options.CustomAddress = '';
        if (!checked) {
            setTimeout(() => {
                this.customAddressInput.nativeElement.focus();
            });
        } else {
            this.options.CustomAddress = null;
        }
    }

    getShippersNumber(): string {
        if (!this.shipment) {
            return null;
        }

        return this.shipment.Receipts.map((r) => {
            if (r.Stop === 1) {
                return r.Number;
            } else {
                return r.Number.substr(r.Number.length - 2);
            }
        }).join('-');
    }

    getTotalSkids(): number {
        return this.shipment.Receipts.reduce((total, r) => total + r.ReceiptDetails.reduce((sum, rd) => sum + rd.NumberOfSkids, 0), 0);
    }

    getNetWeight(): number {
        let skidNetWeight = 0;
        if (this.shipment.Skids.length) {
            skidNetWeight =  this.shipment.Skids.map((skid) => skid.ActualWeight - skid.TareWeightBottom - skid.TareWeightTop).reduce((prev, curr) => prev + curr);
        }

        let stockNetWeight = 0;

        let receipts = this.shipment.Receipts.reduce((a, b) => a.concat(b), []);
        let stockItems = receipts.map((r) => r.ReceiptDetails).reduce((a, b) => a.concat(b), []).filter((rd) => rd.StockOrderLine != null).map((rd) => rd.StockOrderLine);
        if (stockItems.length === 1) {
            stockNetWeight = stockItems[0].StockItem.NetWeight;
        } else if (stockItems.length > 0) {

            stockNetWeight = stockItems.reduce((prev, curr) => prev + curr.StockItem.NetWeight, 0);
        }

        return skidNetWeight + stockNetWeight;

    }

    clearForm(): void {
        this.options = this.shipmentService.getEmptyShipmentBillOfLadingOptions();
        this.options.ShipmentId = this.shipment.Id;
        this.options.Stops = [];
        this.options.IsApproximateWeight = true;
        this.options.PaymentNoteId = PaymentNote.NA;
        this.options.NumberOfSkids = this.getTotalSkids();
        this.options.Weight = this.getNetWeight();
        this.options.ShippersNumber = this.getShippersNumber();

        this.printNewmanAddress = true;
    }

    isValid(): boolean {
        if (!this.options.Stops.length) {
            this.notificationsService.warning('You must include at least one stop.');
            return false;
        }

        if (isNaN(+this.options.MainStop) || this.options.Stops.indexOf(+this.options.MainStop) === -1) {
            this.notificationsService.warning('Address Block is not valid.');
            return false;
        }

        return true;
    }

    formSubmitted(): void {
        if (this.isValid()) {
            const receiptIdsToUpdate: number[] = this.shipment.Receipts.filter((r) => this.options.Stops.includes(r.Stop)).map((r) => r.Id);
            this.receiptService.updatePaymentNoteOnRangeOfReceipts(this.options.PaymentNoteId, receiptIdsToUpdate).subscribe(() => {
                this.shipmentService
                    .getBillOfLadingPdf(this.options)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        PrintService.printPdf(answer);
                    });
            });
        } else {
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancel(): void {
        this.clearForm();
        this.canceled.emit();
    }

    initMainStopValue(options: IShipmentBillOfLadingOptions, shipment: IShipment): void {
        if (!options || !shipment || !shipment.ShipmentStops || shipment.ShipmentStops.length <= 0) {
            return;
        }
        options.MainStop = 1;
    }
}
