import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthGuard, AuthService } from '@mt-ng2/auth-module';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { UserRoleService } from '../../user-roles/user-role.service';
import { appPaths } from '@routes/app-paths.library';

@Injectable({
    providedIn: 'root',
})
export class RouteToLandingPageGuard extends AuthGuard implements CanActivate {
    constructor(injector: Injector, public authService: AuthService, private userRoleService: UserRoleService, public router: Router) {
        super(injector);
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        let currentUserAuthId = this.authService.currentUser.getValue().AuthId;
        return super
            .canActivate(route)
            .pipe(
                switchMap((response: boolean | UrlTree) => {
                    if (typeof response === 'boolean' && response) {
                        return this.userRoleService.getLandingPageByAuthId(currentUserAuthId);
                    }
                    return of(response);
                }),
                map((answer: boolean | UrlTree | string) => {
                    if (answer === null || typeof answer === 'string') {
                        let landingPageUrl = (answer as string) ?? '';
                        return landingPageUrl.length > 0 ? this.router.parseUrl(landingPageUrl) : this.router.parseUrl(appPaths.myProfile);
                    }
                    return answer as boolean | UrlTree;
                }),
            );
    }
}
