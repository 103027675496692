import {
    ISharedEntitiesComponentConfig,
    ISharedEntityListConfig,
    IAdditionalSharedEntityConfig,
    AdditionalSharedEntityTypes,
    SharedEntitiesComponentConfig,
} from '@mt-ng2/shared-entities-module';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IEntityListConfig, IEntityListColumn, EntityListDeleteColumn, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

import { entityListModuleConfig } from '../../common/shared.module';
import { ICustomerShippingAddress } from '../interfaces/customer-shipping-address';
import { CustomerShippingAddressDynamicControlsPartial } from '../partials/customer-shipping-address.form-controls';

import { formatAddress } from '@mt-ng2/format-functions';
import { CustomerShippingAddressActionsComponent } from '../../customers/customer-shipping-address-actions/customer-shipping-address-actions.component';

export class CustomerShippingSharedEntitiesConfig extends SharedEntitiesComponentConfig<ICustomerShippingAddress>
    implements ISharedEntitiesComponentConfig<ICustomerShippingAddress> {
    SharedEntityListConfig: ISharedEntityListConfig;
    constructor() {
        super(
            {
                Address: {
                    Address1: '',
                    Address2: '',
                    City: '',
                    CountryCode: 'US',
                    Id: 0,
                    Province: '',
                    StateCode: 'AL',
                    Zip: '',
                },
                AddressId: 0,
                Archived: false,
                CustomerCode: '',
                CustomerId: 0,
                Id: 0,
                IsPrimary: false,
                ShipToCode: '',
                ShipToName: '',
            },
            'Shipping Address',
            'Shipping Addresses',
            {
                EntityListConfig: new CustomerShippingSharedEntityListConfig(),
                FilterServices: [],
            },
            entityListModuleConfig.itemsPerPage,
        );
    }

    getFormValues(shippingAddress: ICustomerShippingAddress, formValue: any): ICustomerShippingAddress {
        // TODO: MOVE THIS TO A PARTIAL FOR TEMPLATING REASONS
        const originalId = shippingAddress.Id;
        new CustomerShippingDynamicConfig<ICustomerShippingAddress>(shippingAddress).assignFormValues(
            shippingAddress,
            this.decontainerize(formValue.CustomerShippingAddress),
        );
        shippingAddress.Id = originalId;
        return shippingAddress;
    }

    // TODO: MOVE THIS TO A PARTIAL FOR TEMPLATING REASONS
    decontainerize(customerShippingAddress: any): ICustomerShippingAddress {
        const address = {
            Address1: customerShippingAddress.Address1,
            Address2: customerShippingAddress.Address2,
            City: customerShippingAddress.City,
            CountryCode: customerShippingAddress.CountryCode,
            Id: customerShippingAddress.AddressId,
            Province: customerShippingAddress.Province,
            StateCode: customerShippingAddress.StateCode,
            Zip: customerShippingAddress.Zip,
        };
        customerShippingAddress.Address = address;
        return customerShippingAddress;
    }

    // this returns Observable, in case it needs to grab things for drop down lists
    // before the return
    getDynamicFormConfig(shippingAddress: ICustomerShippingAddress): any {
        let formFactory = new CustomerShippingDynamicConfig<ICustomerShippingAddress>(shippingAddress);
        if (shippingAddress.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (shippingAddress: ICustomerShippingAddress) =>
        `${shippingAddress.ShipToName} - ${formatAddress(shippingAddress.Address)}${
            shippingAddress.IsPrimary
                ? '<i class="fa fa-fw fa-star pull-right ng-star-inserted" title="Primary Address" style="margin-top: 3px;"></i>'
                : ''
        }`
}

class CustomerShippingDynamicConfig<T extends ICustomerShippingAddress> extends DynamicConfig<T> implements IDynamicConfig<T> {
    configControls = ['ShipToCode', 'ShipToName', 'Address'];

    constructor(private shippingAddress: T) {
        super();

        const dynamicControls = new CustomerShippingAddressDynamicControlsPartial(this.shippingAddress, 'CustomerShippingAddress');

        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}

class CustomerShippingSharedEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['ShipToName'],
                    name: 'Ship To Name',
                }),
                new EntityListColumn({
                    accessors: ['ShipToCode'],
                    name: 'Ship To Code',
                }),
                new EntityListColumn({
                    accessors: [],
                    name: 'Address',
                    pipes: ['address'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: CustomerShippingAddressActionsComponent,
                    name: 'Archive Address',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            rowClass: (address: ICustomerShippingAddress): string => {
                return address.Archived ? 'archived' : '';
            },
        };
        super(listConfig);
    }
}
