import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';
import { DailyRunEntryListComponent } from './daily-run-entry-list/daily-run-entry-list.component';
import { DailyRunEntryRoutingModule } from './daily-run-entry-routing.module';
import { DailyRunEntriesComponent } from './daily-run-entries/daily-run-entries.component';

@NgModule({
    declarations: [DailyRunEntryListComponent, DailyRunEntriesComponent],
    imports: [SharedModule, DailyRunEntryRoutingModule],
})
export class DailyRunEntryModule {
    static forRoot(): any {
        return {
            ngModule: DailyRunEntryModule,
            providers: [],
        };
    }
}
