import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TrimSheetService, ITrimSetupForecast } from '../trim-sheet.service';
import { IVwTrimSetupForecast } from '@model/interfaces/vw-trim-setup-forecast';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ActivatedRoute } from '@angular/router';

interface IFilters {
    SetupId: number;
    OrderNumber: string;
}

@Component({
    selector: 'trim-setup-forecast',
    styles: [
        `
            .table td:first-child,
            table th:first-child {
                padding-left: 10px;
            }
        `,
    ],
    templateUrl: './trim-setup-forecast.component.html',
})
export class TrimSetupForecastComponent implements OnInit {
    trimSetupForecasts: IVwTrimSetupForecast[];
    total = 0;
    currentPage = 1;
    pageSize = 10;
    filters: IFilters;
    setupId: number;

    @ViewChild('setupId', { read: ElementRef }) setupIdInput: ElementRef;

    constructor(private trimSheetService: TrimSheetService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.clearFilters();
        if (this.setupId) {
            this.filters.SetupId = this.setupId;
        }
        this.bindGrid();
    }

    ngAfterViewInit(): void {
        this.setupIdInput.nativeElement.focus();

    }

    bindGrid(): void {
        const searchEntity: IEntitySearchParams = {
            extraParams: null,
            order: null,
            orderDirection: 'descending',
            query: null,
            skip: (this.currentPage - 1) * this.pageSize,
            take: this.pageSize,
        };

        const searchParams = new SearchParams(searchEntity);

        this.trimSheetService.getTrimSetupForecast(this.filters.SetupId, this.filters.OrderNumber, searchParams).subscribe((answer) => {
            this.trimSetupForecasts = answer.body;
            this.total = +answer.headers.get('X-List-Count');

        });
    }

    clearFilters(): void {
        this.filters = {
            OrderNumber: null,
            SetupId: null,
        };
    }

    getRelativeTime(hours: number): string {
        let hour = Math.floor(hours);
        let minutes = Math.round((hours * 60.0) % 60);
        return (hour ? `${hour} hour${hour === 1 ? '' : 's'} ` : '') + `${minutes} minute${minutes === 1 ? '' : 's'}`;
    }
}
