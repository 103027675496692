import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IFinish } from '../model/interfaces/finish';

@Injectable()
export class FinishService extends MetaItemService<IFinish> {
    constructor(public http: HttpClient) {
        super('FinishService', 'Finish', 'FinishIds', '/salesorders/finishes', http);
    }
}
