<div class="row">
    <div class="col-md-7">
        <h2>Customers</h2>
    </div>
</div>
<div>
    <mt-search-bar #searchBar (onSearch)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="paymentterms" entity="PaymentTerm" (onSelectionChanged)="getCustomers()"></mt-search-filter-select>
    <mt-search-filter-select [items]="creditstatuses" entity="Credit Status" (onSelectionChanged)="getCustomers()"></mt-search-filter-select>
    <mt-search-filter-select [items]="salespeople" entity="Sales Person" (onSelectionChanged)="getCustomers()"></mt-search-filter-select>
    <mt-search-filter-select [items]="shipontypes" entity="Ship On Type" (onSelectionChanged)="getCustomers()"></mt-search-filter-select>

    <label class="font-weight-normal m-l-sm">
        <input type="checkbox" [(ngModel)]="includeArchived" (change)="getSalesPersonFilterItems();getCustomers();" />
        Include Archived
    </label>
</div>

<entity-list
    [entities]="customers"
    entityLink="/customers"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getCustomers()"
    (onItemSelected)="customerSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>

<div *ngIf="canAddCustomer" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/customers', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
