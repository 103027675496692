import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@common/custom-validators';
import { switchMap } from 'rxjs/operators';
import { LoadReportTypes } from '@model/enums/load-report-types.enum';
import { PrintService } from '@common/services/print.service';
import { formatDate } from '@angular/common';
import { ILoadForDate } from '@model/interfaces/custom/update-loads-for-date-payload';
import { Subscription } from 'rxjs';

@Component({
    selector: 'select-shipment-date',
    styleUrls: ['./select-shipment-date.component.less'],
    templateUrl: './select-shipment-date.component.html',
})
export class SelectShipmentDateComponent implements OnInit, OnDestroy {
    @Output('onShipmentDateUpdated') onShipmentDateUpdated = new EventEmitter<boolean>();

    today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    defaultNumberOfInputs = 12;
    inputsToAdd = 4;
    insertLoad = false;
    removeLoad = false;

    shipmentDateForm: FormGroup;
    insertGroup: FormGroup;
    shipmentsForDate: ILoadForDate[];
    shipmentForComments: { control: FormControl; originalValue: string };
    commentsModal: HTMLDialogElement;
    removePosition: number;

    subscriptions = new Subscription();

    get shipments(): FormArray {
        return this.shipmentDateForm.controls.Shipments as FormArray;
    }

    constructor(private shipmentService: ShipmentService, private notificationsService: NotificationsService, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.shipmentDateForm = this.fb.group({
            ShipmentDate: this.fb.control(this.today, Validators.required),
            Shipments: this.fb.array([], CustomValidators.AtLeastOneValue('Id')),
        });
        const multiplier = Math.ceil(this.defaultNumberOfInputs / this.inputsToAdd);
        this.addInputs(multiplier);
        this.getShipmentsForDate(this.today);
        this.subscriptions.add(this.shipmentDateForm.controls.ShipmentDate.valueChanges.subscribe((value) => this.getShipmentsForDate(value)));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    getShipmentsForDate(date: string): void {
        this.shipments.controls.splice(this.defaultNumberOfInputs);
        this.shipmentService.getShipmentsForDate(date).subscribe((shipmentsForDate: ILoadForDate[]) => {
            this.shipmentsForDate = shipmentsForDate;
            if (shipmentsForDate.length > this.shipments.length) {
                const multiplier = Math.ceil((shipmentsForDate.length - this.shipments.length) / this.inputsToAdd);
                this.addInputs(multiplier);
            }
            this.shipments.reset(this.shipmentsForDate);
        });
    }

    resetForm(): void {
        this.shipments.controls.splice(this.defaultNumberOfInputs);
        this.shipmentDateForm.reset({ ShipmentDate: this.today });
        this.insertLoad = false;
        this.insertGroup = null;
        this.removeLoad = false;
        this.removePosition = null;
    }

    updateShipmentDate(): void {
        if (this.shipmentDateForm.valid || this.shipmentDateForm.pristine) {
            const shipments = this.shipmentDateForm.value.Shipments.filter((shipment) => shipment.Id > 0);
            const shipmentDate = this.shipmentDateForm.value.ShipmentDate;
            this.shipmentService
                .updateShipmentsForDate({
                    Shipments: shipments,
                    ShippingDate: shipmentDate,
                })
                .pipe(
                    switchMap(() => this.shipmentService.getShipmentReports(shipmentDate, [LoadReportTypes.LoadList, LoadReportTypes.LoadSchedule])),
                )
                .subscribe((pdf) => {
                    this.notificationsService.success('Updated Shipment Date');
                    PrintService.printPdf(pdf);
                    this.resetForm();
                    this.onShipmentDateUpdated.emit(true);
                });
        }
    }

    getDefaultShipmentsFormGroup(initialValue?: ILoadForDate): FormGroup {
        return this.fb.group(
            {
                Comments: this.fb.control(initialValue?.Comments ?? '', Validators.maxLength(500)),
                Id: this.fb.control(initialValue?.Id ?? null, [Validators.min(1)], [CustomValidators.ShipmentExists(this.shipmentService)]),
            },
            { validators: [CustomValidators.NoDuplicateControlValues(this.shipments, 'Id')] },
        );
    }

    addInputs(multiplier = 1): void {
        for (let i = 0; i < multiplier * this.inputsToAdd; i++) {
            this.shipments.push(this.getDefaultShipmentsFormGroup());
        }
    }

    addComments(control: AbstractControl, modal: HTMLDialogElement): void {
        this.shipmentForComments = {
            control: control as FormControl,
            originalValue: control.value,
        };
        this.commentsModal = modal;
        modal.showModal();
    }

    saveComment(): void {
        this.commentsModal.close();
        this.shipmentForComments = null;
    }

    cancelComment(): void {
        const { control, originalValue } = { ...this.shipmentForComments };
        control.setValue(originalValue);
        this.shipmentForComments = null;
        this.commentsModal.close();
    }

    insert(): void {
        if (this.insertLoad) {
            this.closeInsert();
        } else {
            this.insertGroup = this.fb.group({
                Comments: this.fb.control('', Validators.maxLength(500)),
                Id: this.fb.control(null, [Validators.min(1)], [CustomValidators.ShipmentExists(this.shipmentService)]),
                Position: this.fb.control(1, Validators.min(1)),
            });
            this.insertLoad = true;
        }
    }

    saveInsert(): void {
        const toInsert = this.insertGroup.value;
        const newFormGroup = this.getDefaultShipmentsFormGroup({ Id: toInsert.Id, Comments: toInsert.Comments });
        this.shipments.insert(toInsert.Position - 1, newFormGroup);
        this.shipmentDateForm.markAsDirty();
        this.closeInsert();
    }

    closeInsert(): void {
        this.insertLoad = false;
        this.insertGroup = null;
    }

    remove(): void {
        if (this.removeLoad) {
            this.closeRemove();
        } else {
            this.removeLoad = true;
        }
    }

    saveRemove(): void {
        this.shipments.removeAt(this.removePosition - 1);
        this.updateValidation(this.shipments);
        this.shipmentDateForm.markAsDirty();
        this.closeRemove();
    }

    closeRemove(): void {
        this.removeLoad = false;
        this.removePosition = null;
    }

    clearAll(): void {
        this.shipments.reset();
        this.shipments.controls.splice(this.defaultNumberOfInputs);
    }

    cancelClick(): void {
        this.resetForm();
        this.onShipmentDateUpdated.emit(false);
    }

    updateValidation(formArray: FormArray): void {
        formArray.controls.forEach((c) => c.updateValueAndValidity());
    }
}
