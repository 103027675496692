import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IRunningTrimSheetPayload } from '@model/interfaces/custom/running-trim-sheet-payload';
import { IStartTrimSheet } from '@model/interfaces/custom/start-trim-sheet';
import { TagPrintParams } from '@model/dto/TagPrintParams';
import { TrimDetailWithPrintParams } from '@model/dto/trim-detail-with-print-params';
import { IBacktenderBarcode } from '@model/interfaces/backtender-barcode';

@Injectable()
export class BacktenderService {
    constructor(private http: HttpClient) {}

    getDetailsWithPrintParamsFor(setupId: number): Observable<TrimDetailWithPrintParams[]> {
        return this.http.get<TrimDetailWithPrintParams[]>(`/backtender/${setupId}/details-with-print-params`);
    }

    getTrimSheetTagPdfs(printParams: TagPrintParams[]): any {
        return this.http.put('/backtender/tags-pdf', printParams);
    }

    startNextSetup(setupId: number): Observable<IRunningTrimSheetPayload> {
        const body: IStartTrimSheet = { CurrentSetupId: setupId };
        return this.http.put<IRunningTrimSheetPayload>('/backtender/start-next-setup', body);
    }

    startTargetSetup(setupId: number, targetSetupId: number): Observable<IRunningTrimSheetPayload> {
        const body: IStartTrimSheet = { CurrentSetupId: setupId, TargetSetupId: targetSetupId };
        return this.http.put<IRunningTrimSheetPayload>('/backtender/start-target-setup', body);
    }

    getByOrderId(orderId: string, tagNumber: string): Observable<TrimDetailWithPrintParams[]> {
        let params = new HttpParams();
        params = params.append('tagNumber', tagNumber);
        params = params.append('orderId', orderId);
        return this.http.get<TrimDetailWithPrintParams[]>(`/skids/backtender-tags`, { params: params });
    }

    getTrimSheetBacktenderTagPdfs(printParams: TagPrintParams[]): any {
        return this.http.put('/skids/tags-pdf', printParams);
    }

    getBacktenderBarcodeForSkid(orderDetailLineId: number, skidNumber: number): Observable<IBacktenderBarcode> {
        return this.http.get<IBacktenderBarcode>(`/backtender/backtender-barcode/${orderDetailLineId}/${skidNumber}`);
    }
}
