import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, ICreateOptions } from '@mt-ng2/base-service';
import { ISkid } from '../model/interfaces/skid';

export const emptySkid: ISkid = {
    ActualCount: null,
    ActualWeight: null,
    BacktenderBarcode: null,
    Caliper: null,
    Count: null,
    DateProcessed: null,
    FinalBarcode: null,
    Id: 0,
    IsConverting: false,
    Length: null,
    MillShiftPersonnelId: 0,
    OrderDetailLineId: null,
    Piles: null,
    Ply: null,
    ProcessedBy: null,
    SalesOrderId: 0,
    ScaleId: null,
    Sequence: null,
    SkidHeight: null,
    SkidNumber: null,
    TareWeightBottom: null,
    TareWeightTop: null,
    TargetCount: null,
    TargetWeight: null,
    Width: null,
};

@Injectable()
export class SkidService extends BaseService<ISkid> {
    constructor(public http: HttpClient) {
        super('/skids', http);
    }

    create(skid: ISkid, options?: ICreateOptions, pilesRequired = false, finalizeSkid = true): Observable<number> {
        let params = new HttpParams();

        pilesRequired = pilesRequired !== undefined && pilesRequired !== null ? pilesRequired : false;
        params = params.append('pilesRequired', pilesRequired.toString());

        finalizeSkid = finalizeSkid !== undefined && finalizeSkid !== null ? finalizeSkid : true;
        params = params.append('finalizeSkid', finalizeSkid.toString());

        return this.http.post<number>('/skids', skid, { params: params });
    }

    update(skid: ISkid, validatePiles = false, finalizeSkid = true): Observable<any> {
        let params = new HttpParams();
        params = params.append('validatePiles', validatePiles.toString());

        finalizeSkid = finalizeSkid !== undefined && finalizeSkid !== null ? finalizeSkid : true;
        params = params.append('finalizeSkid', finalizeSkid.toString());

        return this.http.put<any>('/skids/' + skid.Id, skid, { params: params });
    }

    updateNoPiles(skid: ISkid, finalizeSkid = true): Observable<any> {
        let params = new HttpParams();

        finalizeSkid = finalizeSkid !== undefined && finalizeSkid !== null ? finalizeSkid : true;
        params = params.append('finalizeSkid', finalizeSkid.toString());

        return this.http.put<any>('/skids/nopiles', skid, { params: params });
    }

    getByBacktenderBarcode(backtenderBarcode: string): Observable<ISkid> {
        let params = new HttpParams();
        params = params.append('backtenderBarcode', backtenderBarcode);
        return this.http.get<ISkid>('/skids/backtender', { params: params });
    }

    getByFinalBarcode(finalBarcode: string): Observable<ISkid> {
        let params = new HttpParams();
        params = params.append('finalBarcode', finalBarcode);
        return this.http.get<ISkid>('/skids/final', { params: params });
    }

    buildFromOrderDetails(salesOrderId: number, orderDetailLineId?: number, skidNumber?: number, validateOrderQty = false): Observable<ISkid> {
        let params = new HttpParams();
        params = params.append('salesOrderId', salesOrderId.toString());
        if (orderDetailLineId) {
            params = params.append('orderDetailLineId', orderDetailLineId.toString());
        }
        if (skidNumber) {
            params = params.append('skidNumber', skidNumber.toString());
        }

        params = params.append('validateOrderQty', validateOrderQty.toString());
        return this.http.get<ISkid>('/skids/build', { params: params });
    }

    getFinalTag(skidId: number): Observable<any> {
        return this.http.get<ISkid>('/skids/' + skidId + '/final-tag');
    }

    reassignSkid(skidId: number, newSalesOrderId: number, reassignedToShipmentId: number): Observable<any> {
        return this.http.put<any>('/skids/' + skidId + '/salesorders/' + newSalesOrderId + '/shipment/' + reassignedToShipmentId, null);
    }

    getEmptySkid(): ISkid {
        return { ...emptySkid };
    }

    /**
     * Calculates the number of bundles on a Skid
     * @param skid
     * @returns the bundle count for a Skid
     */
    getBundles(skid: ISkid): number {
        return skid.ActualCount / skid.Count;
    }
}
