import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IMillComplaint } from '@model/interfaces/mill-complaint';

import { MillComplaintService } from '../services/mill-complaint.service';

@Component({
    templateUrl: './mill-complaint-add.component.html',
})
export class MillComplaintAddComponent implements OnInit {
    millComplaint: IMillComplaint;
    canEdit = true;

    constructor(private millcomplaintService: MillComplaintService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const salesOrderId = +this.route.snapshot.parent.paramMap.get('orderId');
        this.millComplaint = this.millcomplaintService.getEmptyMillComplaint();
        this.millComplaint.SalesOrderId = salesOrderId;
    }
}
