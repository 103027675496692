import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { ISalesOrder } from '../../model/interfaces/sales-order';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { SkidService } from '../skid.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'no-barcode-skid',
    templateUrl: './no-barcode-skid.component.html',
})
export class NoBarcodeSkidComponent implements OnInit {
    doubleClickIsDisabled = false;
    formCreated = false;

    lastSearchedOrderNumber = '';
    salesOrder: ISalesOrder;
    detailLines: any[];
    selectedDetail: any;

    @ViewChild('orderNumberElem', { read: ElementRef }) orderNumberElem: ElementRef;

    @Input() orderNumber: string;
    @Input() validateOrderQty = false;
    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAddSkid: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private notificationsService: NotificationsService,
        private salesOrderService: SalesOrderService,
        private skidService: SkidService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        if (this.orderNumber) {
            this.loadOrder();
        }
    }

    ngAfterViewInit(): void {
        if (!this.orderNumber) {
            this.orderNumberElem.nativeElement.focus();
            this.orderNumberElem.nativeElement.select();
        }
    }

    addSkid(processExisting = false): void {
        if (this.selectedDetail == null) {
            this.notificationsService.warning('Please select a size');
            this.enableDoubleClick();
            return;
        }

        let orderDetailLineId = 0;
        if (this.selectedDetail.IsConverting) {
            orderDetailLineId = this.selectedDetail.Id;
        }

        if (processExisting) {
            this.modalService
                .showModal({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    input: 'number',
                    inputLabel: 'Skid Number',
                    showCancelButton: false,
                })
                .subscribe((result) => {
                    if (result.value) {
                        this.buildSkid(orderDetailLineId, +result.value);
                    } else {
                        this.notificationsService.warning('You must enter a skid number when adding an existing skid.');
                        this.enableDoubleClick();
                        return;
                    }
                });
        } else {
            this.buildSkid(orderDetailLineId);
        }
    }

    private buildSkid(orderDetailLineId, skidNumber: number = null): void {
        this.skidService
            .buildFromOrderDetails(this.salesOrder.Id, orderDetailLineId, skidNumber, this.validateOrderQty)
            .pipe(
                finalize(() => {
                    this.enableDoubleClick();
                }),
            )
            .subscribe(
                (answer) => {
                    this.onAddSkid.emit(answer);
                },
                (error) => {
                    if (error.error && error.error.Message) {
                        this.notificationsService.error(error.error.Message);
                    }
                },
            );
    }

    cancelClick(): void {
        this.onCancel.emit();
    }

    loadOrder(): void {
        if (!this.orderNumber) {
            this.notificationsService.warning('Please enter an order number');
            return;
        }

        this.selectedDetail = null;

        this.salesOrderService.getByOrderNumber(this.orderNumber, false).subscribe((answer) => {
            this.lastSearchedOrderNumber = this.orderNumber;
            this.salesOrder = answer;

            if (this.salesOrder === null) {
                this.orderNumberElem.nativeElement.select();
            } else {
                this.detailLines = [];

                if (this.salesOrder.OrderDetailLines && this.salesOrder.OrderDetailLines.length) {
                    // only show the converting lines
                    this.salesOrder.OrderDetailLines.forEach((value) => {
                        this.detailLines.push({
                            Caliper: value.Caliper,
                            Id: value.Id,
                            IsConverting: true,
                            Length: value.Length,
                            NumberOfSkids: value.NumberOfSkids,
                            Width: value.Width,
                        });
                    });
                } else {
                    // just show the main order line
                    this.detailLines.push({
                        Caliper: this.salesOrder.ManufacturingOrderDetail.Caliper,
                        Id: this.salesOrder.ManufacturingOrderDetail.Id,
                        IsConverting: false,
                        Length: this.salesOrder.ManufacturingOrderDetail.Length,
                        NumberOfSkids: this.salesOrder.ManufacturingOrderDetail.NumberOfSkids,
                        Width: this.salesOrder.ManufacturingOrderDetail.Width,
                    });
                }

                // auto select if there is only one detail to choose from
                if (this.detailLines.length === 1) {
                    this.selectedDetail = this.detailLines[0];
                }
            }
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
