<h2>Converting Data Settings</h2>
<div>
    <div class="row">
        <div class="col-md-6">
            <meta-item-managed-list 
                [service]="convertingDataEntryUserService" 
                [dynamicForm]="dynamicForm" 
                [canAdd]="canAdd"
                [canRemove]="canRemove">
            </meta-item-managed-list>
        </div>
    </div>
</div>