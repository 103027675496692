import { Validators } from '@angular/forms';

import { DynamicField } from '@mt-ng2/dynamic-form';

import { OverrideNumericFunctionsEmptyWhenZero } from '@common/common-functions.form-controls';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { CustomPackagingDynamicControls, ICustomPackagingDynamicControlsParameters } from '../form-controls/custom-packaging.form-controls';
import { ICustomPackaging } from '../interfaces/custom-packaging';

export class CustomPackagingDynamicControlsPartial extends CustomPackagingDynamicControls {
    constructor(customPackagingPartial?: ICustomPackaging, additionalParameters?: ICustomPackagingDynamicControlsParameters) {
        super(customPackagingPartial, additionalParameters);

        (<DynamicField>this.Form.Height).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Length).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Width).component = FractionsDynamicFieldComponent as any;

        (<DynamicField>this.Form.Caliper).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.Caliper).validators.max = 0.999;

        // Force fields to be empty instead of showing '0' when value is null.
        OverrideNumericFunctionsEmptyWhenZero([this.Form.Caliper, this.Form.MustCount, this.Form.Plus, this.Form.Minus]);
    }
}
