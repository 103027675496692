import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IBandingInstruction } from '../model/interfaces/banding-instruction';

@Injectable()
export class BandingInstructionService extends MetaItemService<IBandingInstruction> {
    constructor(public http: HttpClient) {
        super('BandingInstructionService', 'Banding Instruction', 'BandingInstructionIds', '/salesorders/banding-instructions', http);
    }
}
