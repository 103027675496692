import { Component, OnInit, ViewChild } from '@angular/core';

import { IColumnSortedEvent, SortDirection, EntityListComponent } from '@mt-ng2/entity-list-module';

import { QuickbooksQueueEntityListConfig } from './quickbooks-queue-entity-list-config';
import { ReceiptService, IQuickbooksQueueReceipt } from '../receipt.service';
import { entityListModuleConfig } from '../../common/shared.module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileService } from '../../common/services/file.service';
import { PrintService } from '../../common/services/print.service';
import { finalize, flatMap } from 'rxjs/operators';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-quickbooks-queue',
    styles: [
        `
            .qb-label {
                position: relative;
                right: 7px;
                bottom: 12px;
                padding: 3px 7px;
                font-size: 9pt;
                cursor: default;
            }
        `,
    ],
    templateUrl: './quickbooks-queue.component.html',
})
export class QuickbooksQueueComponent implements OnInit {
    receipts: IQuickbooksQueueReceipt[];
    currentPage = 1;
    total: number;

    entityListConfig = new QuickbooksQueueEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    doubleClickIsDisabled = false;

    @ViewChild('receiptList') private receiptList: EntityListComponent;

    constructor(private receiptService: ReceiptService, private notificationsService: NotificationsService, private modalService: ModalService) {}

    ngOnInit(): void {
        this.getReceipts();
    }

    getReceipts(): void {
        this.doubleClickIsDisabled = false;

        this.receiptService
            .getQuickbooksQueue(
                (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
                entityListModuleConfig.itemsPerPage,
                this.order,
                this.orderDirection,
            )
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                this.receipts = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getReceipts();
    }

    clearSelection(): void {
        this.receiptList.selectedEntities = [];
    }

    getSelectedReceipts(): IQuickbooksQueueReceipt[] {
        return <IQuickbooksQueueReceipt[]>(<any>this.receiptList?.selectedEntities);
    }

    totalSelected(): number {
        return this.getSelectedReceipts()?.length;
    }

    processInvoices(): void {
        if (this.totalSelected() === 0) {
            this.notificationsService.error('You must select at least one invoice.');
            this.enableDoubleClick();
            return;
        }

        this.modalService
            .showModal({
                html: `
                <p><b>${this.totalSelected()}</b> invoice(s) will be processed.</p>
                <p>A Quickbooks IIF file will be downloaded.</p>
                <p>The Released Invoices report will be printed.</p>
            `,
                showCancelButton: true,
                title: `Are you sure you want to process these invoices?`,
                type: 'warning',
            })
            .subscribe((result) => {
                if (!result.value) {
                    this.enableDoubleClick();
                    return;
                }

                let receiptIds = this.getSelectedReceipts().map((item) => item.ReceiptId);
                let iif = null;
                this.receiptService
                    .generateIifFile(receiptIds)
                    .pipe(
                        flatMap((answer) => {
                            iif = answer;
                            return this.receiptService.processToQuickbooks(receiptIds);
                        }),
                    )
                    .pipe(flatMap((answer) => this.receiptService.generateReleasedInvoicesReport(receiptIds)))
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((answer) => {
                        this.clearSelection();
                        this.getReceipts();
                        this.notificationsService.success('Successfully processed invoices.');
                        PrintService.printPdf(answer);
                        FileService.save(iif, 'IIF.iif');
                    });
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
