import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { entityListModuleConfig } from '../../common/shared.module';
import { IContact } from '../../model/interfaces/contact';
import { ContactsEntityListConfig } from './contacts.entity-list-config';
import { ContactService } from '../contact.service';
import { ContactTypeService } from '../../customers/contacttype.service';
import { MetaItemListDefinition } from '@mt-ng2/base-service';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
})
export class ContactsComponent implements OnInit {
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;
    revoked = false;
    query: string;
    contacts: IContact[];
    currentPage = 1;
    total: number;
    typefilter: MetaItemListDefinition = null;

    entityListConfig = new ContactsEntityListConfig(this.navigateToCustomer.bind(this));
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private contactService: ContactService, private contactTypeService: ContactTypeService, private router: Router, private cdr: ChangeDetectorRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.contactTypeService.MetaItemListServiceInitilizer.getItems().subscribe((answer) => {
            this.typefilter = answer;
            this.getContacts();
        });
        this.cdr.detectChanges();
    }

    ngAfterContentInit(): void {
        if (this.searchBar) {
            const nativeElement = this.searchBar.searchInputElement.nativeElement;

            setTimeout(() => {
                this.renderer.selectRootElement(nativeElement).focus();
            }, 0);
        }
    }

    getContacts(): void {
        const search = this.query;
        const extrasearchparams: ExtraSearchParams[] = [];
        extrasearchparams.push(this.typefilter.toExtraSearchParams());

        const searchEntity: IEntitySearchParams = {
            extraParams: extrasearchparams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.contactService.get(searchparams).subscribe((answer) => {
            this.contacts = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getContacts();
    }

    revoke(): void {
        this.currentPage = 1;
        this.getContacts();
    }

    contactSelected(event: IItemSelectedEvent): void {
        let contact = <IContact>event.entity;
        this.router.navigate(['/contacts', contact.Id]);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getContacts();
    }

    navigateToCustomer(contact: IContact): void {
        this.router.navigate(['/customers', contact.Customers[0].Id]);
    }
}
