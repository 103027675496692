import { Component, OnInit } from '@angular/core';
import { Scales } from '../../model/Scales';

@Component({
    selector: 'app-cutting',
    templateUrl: './cutting.component.html',
})
export class CuttingComponent implements OnInit {
    scaleId = Scales.Cutting;

    constructor() {}

    ngOnInit(): void {}
}
