<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Stock Skid Processing</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-12 col-lg-9">
    <form class="miles-form modern-css p-md m-b-md row" [formGroup]="stockSkidProcessingForm" (ngSubmit)="formSubmitted()">
        <div class="row row-col-align-middle">
            <div class="col-xs-2">
                <button type="button" class="btn btn-default" (click)="noTag()">No Tag</button>
            </div>
            <div class="col-xs-4">
                <dynamic-field
                    [field]="abstractStockItemControls.StockIdNumber"
                    [form]="stockSkidProcessingForm"
                    (blur)="findStockByNumber()"
                ></dynamic-field>
            </div>
            <div class="col-xs-6">
                <dynamic-field [field]="abstractStockItemControls.IsPerfect" [form]="stockSkidProcessingForm"></dynamic-field>
                <dynamic-field
                    *ngIf="canMarkAsAvailableBasedOnClaims"
                    [field]="abstractStockItemControls.IsAvailable"
                    [form]="stockSkidProcessingForm"
                ></dynamic-field>
            </div>
        </div>
        <div class="row row-col-align-middle">
            <div class="col-xs-4">
                <dynamic-field
                    [field]="abstractStockItemControls.LotNumber"
                    [form]="stockSkidProcessingForm">
                </dynamic-field>
            </div>
        </div>
        <div
            id="skid-belongs-to-order-msg"
            *ngIf="stockItemBelongsToOrder && canMarkAsAvailableBasedOnClaims"
            class="row row-col-align-middle m-b-sm"
        >
            <div class="col-xs-6"></div>
            <div class="col-xs-6">
                <em>This skid belongs to an order and connot be removed from Available Inventory</em>
            </div>
        </div>
        <div class="row row-col-align-middle">
            <div class="col-xs-7">
                <dynamic-field [field]="abstractStockItemControls.Name" [form]="stockSkidProcessingForm"></dynamic-field>
            </div>
            <div class="col-xs-5">
                {{ stockItem ? (stockItem.DateCreated | date : 'MM/dd/yyyy') : '' }}
                <!-- <dynamic-field [field]="abstractStockItemControls.DateCreated" [form]="stockSkidProcessingForm"></dynamic-field> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <fieldset class="m-b-md">
                    <legend>Details:</legend>
                    <dynamic-field [field]="abstractStockItemControls.BoardGradeId" [form]="stockSkidProcessingForm"></dynamic-field>
                    <dynamic-field [field]="abstractStockItemControls.FinishId" [form]="stockSkidProcessingForm"></dynamic-field>
                    <dynamic-field
                        [field]="abstractStockItemControls.Width"
                        [form]="stockSkidProcessingForm"
                        (blur)="convertMeasurementToFraction('Width')"
                        (focus)="convertMeasurementToDecimal('Width')"
                    >
                    </dynamic-field>
                    <dynamic-field
                        [field]="abstractStockItemControls.Length"
                        [form]="stockSkidProcessingForm"
                        (blur)="convertMeasurementToFraction('Length')"
                        (focus)="convertMeasurementToDecimal('Length')"
                    >
                    </dynamic-field>
                    <dynamic-field [field]="abstractStockItemControls.CaliperMin" [form]="stockSkidProcessingForm"> </dynamic-field>
                    <dynamic-field [field]="abstractStockItemControls.CaliperMax" [form]="stockSkidProcessingForm"> </dynamic-field>
                </fieldset>
                <fieldset class="m-b-md">
                    <legend>Quantity:</legend>
                    <div class="row">
                        <div class="col-xs-4">
                            <dynamic-field [field]="abstractStockItemControls.TareWeight" [form]="stockSkidProcessingForm"></dynamic-field>
                        </div>
                        <div class="col-xs-4">
                            <dynamic-field
                                [field]="abstractStockItemControls.NetWeight"
                                [form]="stockSkidProcessingForm"
                                (valueChanges)="calculateBundles()"
                            ></dynamic-field>
                        </div>
                        <div class="col-xs-4">
                            <label>&nbsp;</label>
                            <br />
                            <button type="button" class="btn btn-secondary btn-block font-weight-bold" (click)="weigh()" [disabled]="isWeighing">
                                {{ isWeighing ? 'WEIGHING...' : 'SCALE' }}
                            </button>
                        </div>
                    </div>
                    <dynamic-field [field]="abstractStockItemControls.Bundles" [form]="stockSkidProcessingForm" (valueChanges)="calculateNetWeight()"></dynamic-field>
                    <dynamic-field
                        [field]="abstractStockItemControls.PoundsPerBundle"
                        [form]="stockSkidProcessingForm"
                        (valueChanges)="updateBundlesAndNetWeight()"
                    ></dynamic-field>
                </fieldset>
            </div>
            <div class="col-md-6">
                <fieldset class="m-b-md">
                    <legend>Color:</legend>
                    <dynamic-field [field]="abstractStockItemControls.Sides" [form]="stockSkidProcessingForm"></dynamic-field>
                    <dynamic-field [field]="abstractStockItemControls.TopColorId" [form]="stockSkidProcessingForm"></dynamic-field>
                    <dynamic-field [field]="abstractStockItemControls.BottomColorId" [form]="stockSkidProcessingForm"></dynamic-field>
                </fieldset>
                <dynamic-field [field]="abstractStockItemControls.DefectTypeId" [form]="stockSkidProcessingForm"></dynamic-field>
                <dynamic-field [field]="abstractStockItemControls.StockItemTypeId" [form]="stockSkidProcessingForm"></dynamic-field>
                <dynamic-field [field]="abstractStockItemControls.WarehouseLocationId" [form]="stockSkidProcessingForm"></dynamic-field>

                <div class="row m-t-lg">
                    <div class="col-xs-3">
                        <button
                            type="submit"
                            [disabled]="!stockSkidProcessingForm.valid"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-success btn-block font-weight-bold"
                        >
                            Save
                        </button>
                    </div>
                    <div class="col-xs-3">
                        <button type="button" Class="btn btn-success btn-block font-weight-bold" (click)="printAndSave()">Print & Save</button>
                    </div>
                    <div class="col-xs-6">
                        <button type="button" Class="btn btn-default btn-block" (click)="cancelClick()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
