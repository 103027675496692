import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IOrderPriceType } from '../model/interfaces/order-price-type';

@Injectable()
export class OrderPriceTypeService extends StaticMetaItemService<IOrderPriceType> {
    constructor(public http: HttpClient) {
        super('OrderPriceTypeService', 'Price Type', 'PriceTypeIds', '/salesorders/orderpricetypes', http);
    }
}
