import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableInventoryListConfig } from './stock-inventory-config';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IStockItem } from '../../model/interfaces/stock-item';
import { entityListModuleConfig } from '../../common/shared.module';
import { StockItemsService } from '../stockitem.service';
import { ISalesOrder } from '../../model/interfaces/sales-order';
import { SalesOrderService } from '../sales-order.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderInfoDynamicControlsPartial } from '../../model/partials/stock-order-info.form-controls';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-stock-inventory',
    templateUrl: './stock-inventory.component.html',
})
export class StockInventoryComponent implements OnInit {
    stockItems: IStockItem[];
    currentPage = 1;
    total: number;
    entityListConfig = new AvailableInventoryListConfig();
    order = '';
    orderDirection = '';
    perfectStockOnly = false;
    stockOrderInventoryForm: FormGroup;
    formCreated = false;
    abstractOrderInfoControls: any;
    lotSearch = '';
    widthSearch = '';
    sizeSearch = '';
    caliperSearch = '';

    salesOrderId: number;
    salesOrder: ISalesOrder;
    stockOrderLines: any[] = [];
    originalLines: any[];
    generalLedgerCodes: IGeneralLedgerCode[];
    totalPrice: number;

    canAccessOnlyAvailable: boolean;
    canAccessAvailableAndUnavailable: boolean;

    availabilites: MtSearchFilterItem[] = [];
    selectedAvailability: number[];

    locationSearch = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private stockItemsService: StockItemsService,
        private salesOrderService: SalesOrderService,
        private notificationsService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canAccessOnlyAvailable = this.claimsService.hasClaim(ClaimTypes.Orders_CanAccessAvailableInventory, [ClaimValues.FullAccess]);
        this.canAccessAvailableAndUnavailable = this.claimsService.hasClaim(ClaimTypes.Orders_CanAccessAvailableAndUnavailableInventory, [ClaimValues.FullAccess]);

        this.setAvailabilityFilter();
        this.getInventory();
    }

    setAvailabilityFilter(): void {
        // default selected availability
        if (this.canAccessAvailableAndUnavailable) {
            this.selectedAvailability = [0];
        } else {
            this.selectedAvailability = [1];
        }

        this.availabilites = [
            {
                Item: { Name: 'Available', Value: 1 },
                Selected: false,
            },
            {
                Item: { Name: 'Unavailable', Value: 0 },
                Selected: this.canAccessAvailableAndUnavailable,
            }];

    }

    filterAvailable(event): void {
        this.selectedAvailability = event.selectedItems.map((item) => item.Value);
        this.getInventory();
    }

    searchLocation(event): void {
        this.locationSearch = event;
        this.getInventory();
    }

    filterPerfect(event): void {
        this.perfectStockOnly = event.value;
        this.getInventory();
    }

    searchLot(event): void {
        this.lotSearch = event;
        this.getInventory();
    }

    searchWidth(event): void {
        this.widthSearch = event;
        this.getInventory();
    }

    searchSize(event): void {
        this.sizeSearch = event;
        this.getInventory();
    }

    searchCaliperMax(event): void {
        this.caliperSearch = event;
        this.getInventory();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInventory();
    }

    getInventory(query = ''): void {
        const search = query;
        const _extraSearchParams: ExtraSearchParams[] = [];
        const perfectParam = new ExtraSearchParams({
            name: 'IsPerfect',
            value: this.perfectStockOnly.toString(),
        });
        _extraSearchParams.push(perfectParam);

        const processedParam = new ExtraSearchParams({
            name: 'Processed',
            value: 'true',
        });
        _extraSearchParams.push(processedParam);

        const lotParam = new ExtraSearchParams({
            name: 'Lot',
            value: this.lotSearch,
        });
        _extraSearchParams.push(lotParam);

        const widthParam = new ExtraSearchParams({
            name: 'Width',
            value: this.widthSearch,
        });
        _extraSearchParams.push(widthParam);

        const sizeParam = new ExtraSearchParams({
            name: 'Size',
            value: this.sizeSearch,
        });
        _extraSearchParams.push(sizeParam);

        const caliperParam = new ExtraSearchParams({
            name: 'Caliper',
            value: this.caliperSearch,
        });
        _extraSearchParams.push(caliperParam);

        const availableParam = new ExtraSearchParams({
            name: 'Availability',
            valueArray: this.selectedAvailability,
        });
        _extraSearchParams.push(availableParam);

        const hasAcceptableLocation = new ExtraSearchParams({
            name: 'HasAcceptableLocation',
            value: 'true',
        });
        _extraSearchParams.push(hasAcceptableLocation);

        const locationParam = new ExtraSearchParams({
            name: 'Location',
            value: this.locationSearch,
        });
        _extraSearchParams.push(locationParam);

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.stockItemsService.get(searchparams).subscribe((answer) => {
            this.stockItems = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }
}
