<div class="row m-y-sm">
    <div class="col-xs-6 col-xs-offset-3">
        <form (ngSubmit)="loadDetails()" autocomplete="off">
            <p class="text-center">Enter the Setup:</p>
            <div class="input-group">
                <input type="number" name="setupId" [(ngModel)]="setupId" class="form-control" />
                <span class="input-group-btn">
                    <button type="submit" class="btn btn-info"><i class="fa fa-fw fa-search"></i></button>
                </span>
            </div>
        </form>
    </div>
</div>

<hr />

<div class="row">
    <div class="col-md-2 p-t-sm">
        <b>Order</b>
    </div>
    <div class="col-md-2 p-t-sm">
        <b>Size</b>
    </div>
    <div class="col-md-2 p-t-sm">
        <b>Caliper</b>
    </div>
    <div class="col-md-2 p-t-sm">
        <b># Skids</b>
    </div>
    <div class="col-md-2 p-t-sm">
        <b># of Tags</b>
    </div>
    <div class="col-md-1 p-t-sm"></div>
</div>
<div *ngFor="let item of trimDetailWithPrintParams; let i = index">
    <div class="row p-t-sm" *ngIf="item.TagPrintParams.IsNoNewmanMarkings">
        <div class="col-sm-auto justify-content-center">
            <div class="newman-markings-warning">
                <p>
                    THIS ORDER "{{ item.TrimSheetDetail.SalesOrderId }}" REQUIRES NO NEWMAN MARKINGS - CUT OFF THE TOP SECTION OF THE LABEL ONCE IT
                    PRINTS
                </p>
            </div>
        </div>
    </div>
    <div class="row p-t-sm">
        <div class="col-md-2 p-t-sm">
            {{ item.TrimSheetDetail.SalesOrderId }}
        </div>
        <div class="col-md-2 p-t-sm">{{ item.TrimSheetDetail.Width }} x {{ item.TrimSheetDetail.Length }}</div>
        <div class="col-md-2 p-t-sm">
            {{ item.TrimSheetDetail.Caliper }}
        </div>
        <div class="col-md-2 p-t-sm">
            {{ item.TagPrintParams.NumberOfSkids }}
        </div>
        <div class="col-md-2 p-t-sm">
            <input class="form-control" type="number" [(ngModel)]="item.TagPrintParams.NumberOfTags" />
        </div>
        <div class="col-md-1">
            <button type="button" class="btn btn-flat btn-default" (click)="printTags(false, i)">Print</button>
        </div>
    </div>
</div>
<div class="text-center m-t-lg">
    <button
        class="btn btn-success p-x-lg"
        (click)="printTags()"
        [disabled]="trimDetailWithPrintParams.length === 0"
        mt-doubleClickDisabled
        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
    >
        Print All
    </button>
    <button class="btn btn-default" (click)="cancelClick()">Cancel</button>
</div>
