import { StateMileageDynamicControls, IStateMileageDynamicControlsParameters } from '../form-controls/state-mileage.form-controls';
import { IStateMileage } from '../interfaces/state-mileage';
import { DynamicField, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { IState } from '@model/interfaces/state';

export interface IStateMileageDynamicControlsParametersPartial extends IStateMileageDynamicControlsParameters {
    states?: IState[];
}

export class StateMileageDynamicControlsPartial extends StateMileageDynamicControls {
    constructor(statemileagePartial?: IStateMileage, additionalParameters?: IStateMileageDynamicControlsParametersPartial) {
        super(statemileagePartial, additionalParameters);

        (<DynamicField>this.Form.Statecode).labelHtml = '<label>State</label>';
        (<DynamicField>this.Form.Statecode).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.Statecode).options = additionalParameters.states.map((s) => ({ Id: s.StateCode, Name: s.Name }));
    }
}
