import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomerOrderPriceInfo } from '../interfaces/customer-order-price-info';
import { ICornerCutType } from '../interfaces/corner-cut-type';
import { IPricingChargePerType } from '../interfaces/pricing-charge-per-type';

export interface ICustomerOrderPriceInfoDynamicControlsParameters {
    formGroup?: string;
    cornerCuts?: ICornerCutType[];
    pricingChargePerTypes?: IPricingChargePerType[];
    cuts?: ICornerCutType[];
    cornerCutTypes?: ICornerCutType[];
}

export class CustomerOrderPriceInfoDynamicControls {

    formGroup: string;
    cornerCuts?: ICornerCutType[];
    pricingChargePerTypes?: IPricingChargePerType[];
    cuts?: ICornerCutType[];
    cornerCutTypes?: ICornerCutType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerorderpriceinfo?: ICustomerOrderPriceInfo, additionalParameters?: ICustomerOrderPriceInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerOrderPriceInfo';
        this.cornerCuts = additionalParameters && additionalParameters.cornerCuts || undefined;
        this.pricingChargePerTypes = additionalParameters && additionalParameters.pricingChargePerTypes || undefined;
        this.cuts = additionalParameters && additionalParameters.cuts || undefined;
        this.cornerCutTypes = additionalParameters && additionalParameters.cornerCutTypes || undefined;

        this.Form = {
            AdditionalCut: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Cut',
                name: 'AdditionalCut',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.AdditionalCut || null,
            }),
            BackTrim: new DynamicField({
                formGroup: this.formGroup,
                label: 'Back Trim',
                name: 'BackTrim',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BackTrim || null,
            }),
            BasePrice: new DynamicField({
                formGroup: this.formGroup,
                label: 'Base Price',
                name: 'BasePrice',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BasePrice || null,
            }),
            BasisWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis Weight',
                name: 'BasisWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BasisWeight || null,
            }),
            BundleTied: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundle Tied',
                name: 'BundleTied',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BundleTied || null,
            }),
            BustInHalf: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bust In Half',
                name: 'BustInHalf',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BustInHalf || null,
            }),
            Commission: new DynamicField({
                formGroup: this.formGroup,
                label: 'Commission',
                name: 'Commission',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Commission || null,
            }),
            CommissionPercent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Commission Percent',
                name: 'CommissionPercent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('CommissionPercent') && this.customerorderpriceinfo.CommissionPercent != null ? this.customerorderpriceinfo.CommissionPercent.toString() : '',
            }),
            CornerCut: new DynamicField({
                formGroup: this.formGroup,
                label: 'Corner Cut',
                name: 'CornerCut',
                options: this.cornerCuts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.CornerCut || null,
            }),
            CornerCutTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Corner Cut Type',
                name: 'CornerCutTypeId',
                options: this.cornerCutTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.CornerCutTypeId || null,
            }),
            Cut: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cut',
                name: 'Cut',
                options: this.cuts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Cut || null,
            }),
            EnergySurchargePerTonName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Energy Surcharge Per Ton Name',
                name: 'EnergySurchargePerTonName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('EnergySurchargePerTonName') && this.customerorderpriceinfo.EnergySurchargePerTonName != null ? this.customerorderpriceinfo.EnergySurchargePerTonName.toString() : '',
            }),
            EnergySurchargePerTonRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Energy Surcharge Per Ton Rate',
                name: 'EnergySurchargePerTonRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.EnergySurchargePerTonRate || null,
            }),
            Flip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Flip',
                name: 'Flip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Flip || null,
            }),
            Frt: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frt',
                name: 'Frt',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Frt || null,
            }),
            MarkUp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mark Up',
                name: 'MarkUp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.MarkUp || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('Notes') && this.customerorderpriceinfo.Notes != null ? this.customerorderpriceinfo.Notes.toString() : '',
            }),
            OtherOne: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other One',
                name: 'OtherOne',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('OtherOne') && this.customerorderpriceinfo.OtherOne != null ? this.customerorderpriceinfo.OtherOne.toString() : '',
            }),
            OtherTwo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Two',
                name: 'OtherTwo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('OtherTwo') && this.customerorderpriceinfo.OtherTwo != null ? this.customerorderpriceinfo.OtherTwo.toString() : '',
            }),
            Paste: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paste',
                name: 'Paste',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Paste || null,
            }),
            Percent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Percent',
                name: 'Percent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('Percent') && this.customerorderpriceinfo.Percent != null ? this.customerorderpriceinfo.Percent.toString() : '',
            }),
            PricingChargePerTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pricing Charge Per Type',
                name: 'PricingChargePerTypeId',
                options: this.pricingChargePerTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.PricingChargePerTypeId || null,
            }),
            Qty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qty',
                name: 'Qty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Qty || null,
            }),
            RateOne: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rate One',
                name: 'RateOne',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RateOne || null,
            }),
            RateTwo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rate Two',
                name: 'RateTwo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RateTwo || null,
            }),
            RotarySlit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rotary Slit',
                name: 'RotarySlit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RotarySlit || null,
            }),
            SkidCharge: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skid Charge',
                name: 'SkidCharge',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.SkidCharge || null,
            }),
            Stain: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stain',
                name: 'Stain',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Stain || null,
            }),
            Waste: new DynamicField({
                formGroup: this.formGroup,
                label: 'Waste',
                name: 'Waste',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Waste || null,
            }),
        };

        this.View = {
            AdditionalCut: new DynamicLabel({
                label: 'Additional Cut',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.AdditionalCut || null,
            }),
            BackTrim: new DynamicLabel({
                label: 'Back Trim',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BackTrim || null,
            }),
            BasePrice: new DynamicLabel({
                label: 'Base Price',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BasePrice || null,
            }),
            BasisWeight: new DynamicLabel({
                label: 'Basis Weight',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BasisWeight || null,
            }),
            BundleTied: new DynamicLabel({
                label: 'Bundle Tied',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BundleTied || null,
            }),
            BustInHalf: new DynamicLabel({
                label: 'Bust In Half',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.BustInHalf || null,
            }),
            Commission: new DynamicLabel({
                label: 'Commission',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Commission || null,
            }),
            CommissionPercent: new DynamicLabel({
                label: 'Commission Percent',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('CommissionPercent') && this.customerorderpriceinfo.CommissionPercent != null ? this.customerorderpriceinfo.CommissionPercent.toString() : '',
            }),
            CornerCut: new DynamicLabel({
                label: 'Corner Cut',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.cornerCuts as unknown as IMetaItem[], this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('CornerCut') ? this.customerorderpriceinfo.CornerCut : null) ?? '',
            }),
            CornerCutTypeId: new DynamicLabel({
                label: 'Corner Cut Type',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.cornerCutTypes as unknown as IMetaItem[], this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('CornerCutTypeId') ? this.customerorderpriceinfo.CornerCutTypeId : null) ?? '',
            }),
            Cut: new DynamicLabel({
                label: 'Cut',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.cuts as unknown as IMetaItem[], this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('Cut') ? this.customerorderpriceinfo.Cut : null) ?? '',
            }),
            EnergySurchargePerTonName: new DynamicLabel({
                label: 'Energy Surcharge Per Ton Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('EnergySurchargePerTonName') && this.customerorderpriceinfo.EnergySurchargePerTonName != null ? this.customerorderpriceinfo.EnergySurchargePerTonName.toString() : '',
            }),
            EnergySurchargePerTonRate: new DynamicLabel({
                label: 'Energy Surcharge Per Ton Rate',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.EnergySurchargePerTonRate || null,
            }),
            Flip: new DynamicLabel({
                label: 'Flip',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Flip || null,
            }),
            Frt: new DynamicLabel({
                label: 'Frt',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Frt || null,
            }),
            MarkUp: new DynamicLabel({
                label: 'Mark Up',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.MarkUp || null,
            }),
            Notes: new DynamicLabel({
                label: 'Notes',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('Notes') && this.customerorderpriceinfo.Notes != null ? this.customerorderpriceinfo.Notes.toString() : '',
            }),
            OtherOne: new DynamicLabel({
                label: 'Other One',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('OtherOne') && this.customerorderpriceinfo.OtherOne != null ? this.customerorderpriceinfo.OtherOne.toString() : '',
            }),
            OtherTwo: new DynamicLabel({
                label: 'Other Two',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('OtherTwo') && this.customerorderpriceinfo.OtherTwo != null ? this.customerorderpriceinfo.OtherTwo.toString() : '',
            }),
            Paste: new DynamicLabel({
                label: 'Paste',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Paste || null,
            }),
            Percent: new DynamicLabel({
                label: 'Percent',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('Percent') && this.customerorderpriceinfo.Percent != null ? this.customerorderpriceinfo.Percent.toString() : '',
            }),
            PricingChargePerTypeId: new DynamicLabel({
                label: 'Pricing Charge Per Type',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.pricingChargePerTypes as unknown as IMetaItem[], this.customerorderpriceinfo && this.customerorderpriceinfo.hasOwnProperty('PricingChargePerTypeId') ? this.customerorderpriceinfo.PricingChargePerTypeId : null) ?? '',
            }),
            Qty: new DynamicLabel({
                label: 'Qty',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Qty || null,
            }),
            RateOne: new DynamicLabel({
                label: 'Rate One',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RateOne || null,
            }),
            RateTwo: new DynamicLabel({
                label: 'Rate Two',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RateTwo || null,
            }),
            RotarySlit: new DynamicLabel({
                label: 'Rotary Slit',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.RotarySlit || null,
            }),
            SkidCharge: new DynamicLabel({
                label: 'Skid Charge',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.SkidCharge || null,
            }),
            Stain: new DynamicLabel({
                label: 'Stain',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Stain || null,
            }),
            Waste: new DynamicLabel({
                label: 'Waste',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                value: this.customerorderpriceinfo && this.customerorderpriceinfo.Waste || null,
            }),
        };

    }
}
