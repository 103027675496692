import {
    IEntityListConfig,
    EntityListConfig,
    EntityListColumn,
    IEntityListComponentMembers,
    IEntityListDynamicCellComponent,
    IEntity,
} from '@mt-ng2/entity-list-module';
import { ISkid } from '@model/interfaces/skid';
import { Skid } from '@model/classes/skid';
import { Output, Component, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { PrintService } from '../../common/services/print.service';
import { SkidService } from '../skid.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    template: '',
})
export class SkidsEntityListConfig extends EntityListConfig {
    @Output('onSkidRemoved') onSkidRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(private skidService: SkidService, private contextMenuService: ContextMenuService) {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    accessors: ['SalesOrder', 'Id'],
                    name: 'Order',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: ISkid): string =>
                        `${skid.SkidNumber} / ${
                            (skid.SalesOrder && skid.SalesOrder.ManufacturingOrderDetail && skid.SalesOrder.ManufacturingOrderDetail.NumberOfSkids)
                        }`,
                    name: 'Skid Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: ISkid): string =>
                        `${
                            (skid.SalesOrder &&
                                skid.SalesOrder.OrderInfo &&
                                skid.SalesOrder.OrderInfo.Customer &&
                                skid.SalesOrder.OrderInfo.Customer.CompanyName) ||
                            (skid &&
                                skid.SalesOrder &&
                                skid.SalesOrder.OrderInfo &&
                                skid.SalesOrder.OrderInfo.Customer &&
                                skid.SalesOrder.OrderInfo.Customer.CustomerName)
                        }`,
                    name: 'Customer',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: ISkid): string => {
                        if (skid.Shipment && skid.Shipment.Receipts && skid.Shipment.Receipts.length > 0) {
                            const receipt = skid.Shipment.Receipts.find((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === skid.SalesOrderId));
                            if (receipt) {
                                return receipt.DatePrinted as unknown as string;
                            }
                        }
                        if (skid.Shipment) {
                            return skid.Shipment.ShipmentDate as unknown as string;
                        }
                        return '';
                    },
                    name: 'Ship Date',
                    pipes: ['date'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: ISkid): string => `${skid.Width} x ${skid.Length}`,
                    name: 'Size',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: ISkid): string => `${skid.Caliper.toFixed(3)}`,
                    name: 'Caliper',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: Skid): string => `${skid.getScaleSheets()} - ${skid.ActualCount}`,
                    name: 'Scale - Scan',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (skid: Skid): string => `${skid.TareWeightTop} - ${skid.TareWeightBottom}<br>${skid.getNetWeight()}`,
                    bindToInnerHtml: true,
                    name: 'Tare / Net',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: SkidActionsComponent,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });
    }
}

@Component({
    styles: [
        `
            td.text-center {
                border: none;
                border-bottom: none !important;
            }
        `,
    ],
    template: `
        <td class="text-center">
            <i *ngIf="canEdit" class="fa fa-fw fa-lg fa-cog pointer" [contextMenu]="skidContextMenu" (click)="openContextMenu($event, item)"></i>
        </td>
        <context-menu #skidContextMenu>
            <ng-template contextMenuItem (execute)="reprintFinalSkidTag($event.item)"> Reprint Final Tag </ng-template>
            <ng-template contextMenuItem (execute)="rescanOnAIPackagingLine($event.item)"> Rescan (A.I.) </ng-template>
            <ng-template contextMenuItem (execute)="rescanOnDrJPackagingLine($event.item)"> Rescan (Dr. J) </ng-template>
            <ng-template contextMenuItem (execute)="deleteSkid($event.item)"> Delete Skid </ng-template>
        </context-menu>
    `,
})
export class SkidActionsComponent implements IEntityListDynamicCellComponent, OnInit {
    @ViewChild('skidContextMenu') public skidContextMenu: ContextMenuComponent;
    item: ISkid;
    entityListComponentMembers: IEntityListComponentMembers;
    canEdit: boolean;

    constructor(
        private skidService: SkidService,
        private contextMenuService: ContextMenuService,
        private router: Router,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private claimsService: ClaimsService,
    ) {}

    set entity(value: IEntity) {
        this.item = value as ISkid;
    }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);
    }

    reprintFinalSkidTag(item: any): void {
        this.skidService.getFinalTag(item.Id).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    rescanOnAIPackagingLine(item: any): void {
        this.router.navigate(['/packaging-line/ai/' + item.BacktenderBarcode]);
    }

    rescanOnDrJPackagingLine(item: any): void {
        this.router.navigate(['/packaging-line/drj/' + item.BacktenderBarcode]);
    }

    deleteSkid(item: any): void {
        this.modalService
            .showModal({
                showCancelButton: true,
                text: 'Are you sure you want to delete this skid?',
                title: 'Confirm Skid Removal',
                type: 'warning',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.skidService.delete(item.Id).subscribe(
                        (answer) => {
                            this.notificationsService.success('Skid removed successfully.');
                            (<SkidsEntityListConfig>this.entityListComponentMembers.entityListConfig).onSkidRemoved.emit(true);
                        },
                        (error) => {
                            this.notificationsService.error('An error has occurred. Please ensure this skid is not associated to any loads.');
                        },
                    );
                }
            });
    }

    openContextMenu($event: MouseEvent, item: any): void {
        if (this.canEdit) {
            this.contextMenuService.show.next({
                contextMenu: this.skidContextMenu,
                event: $event,
                item: item,
            });
            $event.preventDefault();
            $event.stopPropagation();
        }
    }
}
