<h2>
    Bill of Lading <span class="small">(Load #{{ shipment.Id }})</span>
</h2>
<div class="row">
    <div class="col-lg-8">
        <form class="miles-card miles-form padded" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-sm-5">
                    <label>
                        <input
                            type="checkbox"
                            name="printNewmanAddress"
                            [(ngModel)]="printNewmanAddress"
                            (change)="printNewmanAddressChanged($event.target.checked)"
                        />
                        Print Newman Address
                    </label>
                    <br />
                    <textarea
                        #customAddress
                        name="customAddress"
                        [disabled]="printNewmanAddress"
                        [(ngModel)]="options.CustomAddress"
                        rows="3"
                        class="form-control"
                    ></textarea>
                </div>
                <div class="col-sm-7">
                    <div class="form-group">
                        <label>Shipper's No</label>
                        <input type="text" name="shippersNumber" [(ngModel)]="options.ShippersNumber" class="form-control" />
                    </div>
                    <table class="cursor-auto">
                        <tr>
                            <td class="p-r-md p-b-sm">
                                <label>
                                    <input type="checkbox" name="printTotalSkids" [(ngModel)]="options.PrintTotalSkids" />
                                    Print Total Skids
                                </label>
                            </td>
                            <td><input type="text" class="form-control" name="numberOfSkids" [(ngModel)]="options.NumberOfSkids" /></td>
                        </tr>
                        <tr>
                            <td>
                                <label>Total Net Weight</label>
                            </td>
                            <td><input type="text" class="form-control" name="weight" [(ngModel)]="options.Weight" /></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label>
                                    <input
                                        type="radio"
                                        name="weightType"
                                        [checked]="options.IsApproximateWeight"
                                        (change)="options.IsApproximateWeight = $event.target.checked"
                                    />
                                    Approx.
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="weightType"
                                        [checked]="!options.IsApproximateWeight"
                                        (change)="options.IsApproximateWeight = !$event.target.checked"
                                    />
                                    Actual
                                </label>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <fieldset>
                        <legend>Ship To:</legend>
                        <div class="form-inline m-b-sm">
                            <span class="medium">Address Block:</span>
                            <input
                                type="text"
                                name="mainStop"
                                [(ngModel)]="options.MainStop"
                                class="form-control"
                                maxlength="2"
                                style="width: 50px;"
                            />
                            <span class="small">(Enter the line number from below)</span>
                        </div>
                        <div *ngFor="let stop of shipment?.ShipmentStops">
                            <label>
                                <input type="checkbox" name="{{ 'stop' + stop.Stop }}" (change)="stopClicked(stop.Stop, $event.target.checked)" checked/>
                                <span class="font-weight-normal">{{ stop.Stop }}.</span>
                                {{ stop.ShipmentDetails[0]?.SalesOrder?.OrderInfo?.Customer?.CustomerName }}
                                |
                                <span class="font-italic">{{
                                    stop.CustomerShippingAddress.Address?.City +
                                        ', ' +
                                        stop.CustomerShippingAddress.Address?.StateCode +
                                        ' ' +
                                        stop.CustomerShippingAddress.Address?.Zip
                                }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div class="col-sm-4">
                    <label class="m-0">Payment Note:</label>
                    <div *ngFor="let item of paymentNotes">
                        <label class="font-weight-normal m-0">
                            <input type="radio" name="paymentNote" [value]="item.Id" [(ngModel)]="options.PaymentNoteId" />
                            {{ item.Name }}
                        </label>
                    </div>
                    <div class="form-group m-t-sm">
                        <label>Comments</label>
                        <textarea name="comments" [(ngModel)]="options.Comments" class="form-control" rows="5"></textarea>
                    </div>
                    <label>
                        <input type="checkbox" name="printLoadSheetSummary" [(ngModel)]="options.IncludeLoadSheetSummary" />
                        Print Load Sheet Summary
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4">
                    <button type="button" class="btn btn-default" (click)="cancel()"><i class="fa fa-fw fa-arrow-left"></i> Return</button>
                </div>
                <div class="col-xs-4">
                    <button type="submit" class="btn btn-success btn-block" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
                        Print
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
