import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

import { HttpClient } from '@angular/common/http';
import { IOrderCustomerSpecificationPartial } from '../model/partials/order-customer-specification';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class OrderCustomerSpecsService extends BaseService<IOrderCustomerSpecificationPartial> {

    private emptyOrderCustomerSpecifications: IOrderCustomerSpecificationPartial = {
        BandingInstructionId: null,
        CustomerSpecifications: '',
        Id: 0,
        IsCornerProtectors: false,
        IsFortyPoundBundle: false,
        IsLtl: false,
        IsNoNewmanMarkings: false,
        IsScanCounts: false,
        IsSpecialLabels: false,
        IsTiedBundles: false,
        OrderNotes: '',
        PriceBreakdown: '',
        ReceiptPartInfo: '',
        TagSkidTypeId: 0,

    };

    constructor(public http: HttpClient) {
        super('/salesorders/OrderCustomerSpecification', http);
    }

    getEmptyOrderCustomerSpecifications(): IOrderCustomerSpecificationPartial {
        return { ...this.emptyOrderCustomerSpecifications };
    }

    createOrderInfoSpec(orderInfoId: number, orderCustomerSpecification: IOrderCustomerSpecificationPartial): Observable<number> {
        return this.http.post<number>(`/salesorders/OrderCustomerSpecification/OrderInfo/${orderInfoId}`, orderCustomerSpecification);
    }

    createOrderSpec(customerId: number, orderCustomerSpecification: IOrderCustomerSpecificationPartial): Observable<number> {
        return this.http.post<number>(`/salesorders/OrderCustomerSpecification/customers/${customerId}`, orderCustomerSpecification);
    }

    getBySalesOrderId(salesOrderId: number): Observable<IOrderCustomerSpecificationPartial> {
        return this.http.get<IOrderCustomerSpecificationPartial>(`/salesorders/OrderCustomerSpecification/getbysalesorderid/${salesOrderId}`);
    }

    updatePileUp(orderCustomerSpecification: IOrderCustomerSpecificationPartial, salesOrderId: number): Observable<number> {
        return this.http.post<number>(`/salesorders/OrderCustomerSpecification/pile-up/${salesOrderId}`, orderCustomerSpecification);
    }

    updateOrderCustomerSpecs(salesOrderId: number, specs: IOrderCustomerSpecificationPartial): Observable<void> {
        return this.http.post<void>(`/salesorders/OrderCustomerSpecification/update-specs/${salesOrderId}`, specs);
    }

}
