<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button [urlSegmentsToIgnore]="['specs-info', 'price-info']"></route-back-button>
                Price Breakdown
            </h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-10">
    <form class="miles-form padded row" [formGroup]="priceBreakdownForm" (ngSubmit)="formSubmitted()">
        <div class="row col-md-12">
            <div class="col-md-5">
                <label>
                    <b>Order No:</b>
                </label>
                {{ getFormattedOrderNumber(salesOrder) }}
                <br />
                <label> <b>Pricing:</b> {{ pricing | currency }} </label>
                <br />
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.BasePrice" [form]="priceBreakdownForm"></dynamic-field>
            </div>
            <div class="col-md-7" style="border: 1px solid #d2d6de; border-radius: 10px">
                <b>{{ salesOrder.OrderInfo.Customer.CustomerName }}</b>
                <br />
                <b>Cal:</b> {{ salesOrder.ManufacturingOrderDetail.Caliper | number : '1.3-3' }} <b>Cnt:</b>
                {{ salesOrder.ManufacturingOrderDetail.Count }}
                <br />
                <b>Grade:</b> {{ salesOrder.ManufacturingOrderDetail.BoardGrade ? salesOrder.ManufacturingOrderDetail.BoardGrade.Name : '' }}
            </div>
        </div>
        <div class="row col-md-12">
            <div class="col-md-4" style="border: 1px solid #d2d6de; border-radius: 10px">
                <h4>Converting Charges</h4>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Paste" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Cut" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.BackTrim" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.AdditionalCut" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.BustInHalf" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.CornerCut" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.CornerCutTypeId" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.RotarySlit" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.BundleTied" [form]="priceBreakdownForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Flip" [form]="priceBreakdownForm"></dynamic-field>
            </div>
            <div class="col-md-8" style="border: 1px solid #d2d6de; border-radius: 10px">
                <h4>Production/Other Charges</h4>
                <div class="row">
                    <div class="col-md-5">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Waste" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Frt" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Qty" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Stain" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.SkidCharge" [form]="priceBreakdownForm"></dynamic-field>
                    </div>

                    <div class="col-md-7">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.Percent" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.BasisWeight" [form]="priceBreakdownForm"></dynamic-field>
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.MarkUp" [form]="priceBreakdownForm"></dynamic-field>
                        <div class="row">
                            <div class="col-md-5">
                                <dynamic-field
                                    [field]="abstractCustomerOrderPriceInfoControls.Commission"
                                    [form]="priceBreakdownForm"
                                ></dynamic-field>
                            </div>
                            <div style="margin-top: 30px; padding: 0" class="col-md-1 text-left pull-left">/Ton</div>
                            <div class="col-md-4">
                                <dynamic-field
                                    [field]="abstractCustomerOrderPriceInfoControls.CommissionPercent"
                                    [form]="priceBreakdownForm"
                                ></dynamic-field>
                            </div>
                            <div style="margin-top: 30px; padding: 0" class="col-md-2 text-left pull-left">% Ton</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.OtherOne" [form]="priceBreakdownForm"></dynamic-field>
                    </div>
                    <div class="col-md-5">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.RateOne" [form]="priceBreakdownForm"></dynamic-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-7">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.OtherTwo" [form]="priceBreakdownForm"></dynamic-field>
                    </div>
                    <div class="col-md-5">
                        <dynamic-field [field]="abstractCustomerOrderPriceInfoControls.RateTwo" [form]="priceBreakdownForm"></dynamic-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-7">
                        <dynamic-field
                            [field]="abstractCustomerOrderPriceInfoControls.EnergySurchargePerTonName"
                            [form]="priceBreakdownForm"
                        ></dynamic-field>
                    </div>
                    <div class="col-md-5">
                        <dynamic-field
                            [field]="abstractCustomerOrderPriceInfoControls.EnergySurchargePerTonRate"
                            [form]="priceBreakdownForm"
                        ></dynamic-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <newman-horizontal
                [fields]="['Notes', 'PricingChargePerTypeId']"
                [dynamicForm]="abstractCustomerOrderPriceInfoControls"
                [parentForm]="priceBreakdownForm"
            ></newman-horizontal>
            <button
                *ngIf="!formIsLocked"
                type="submit"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success"
            >
                Save & Close
            </button>
        </div>
    </form>
</div>
