import { MetaItemService } from '@mt-ng2/base-service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ICustomerPaymentTerm } from '../model/interfaces/customer-payment-term';
import { SearchParams } from '@mt-ng2/common-classes';
import { Subject } from 'rxjs';

@Injectable()
export class CustomerPaymentTermService extends MetaItemService<ICustomerPaymentTerm> {
    constructor(public http: HttpClient) {
        super('CustomerPaymentTermService', 'Payment Term', 'PaymentTermIds', '/customerpaymentterms', http);
    }
}
