import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IPaymentNote } from '../model/interfaces/payment-note';

@Injectable()
export class PaymentNoteService extends StaticMetaItemService<IPaymentNote> {
    constructor(public http: HttpClient) {
        super('PaymentNoteService', 'Note', 'NoteIds', '/payment-notes', http);
    }
}
