import { NgModule } from '@angular/core';

import { MillComplaintAddComponent } from './mill-complaint-add/mill-complaint-add.component';
import { MillComplaintBasicInfoComponent } from './mill-complaint-basic-info/mill-complaint-basic-info.component';
import { MillComplaintDetailComponent } from './mill-complaint-detail/mill-complaint-detail.component';
import { MillComplaintsComponent } from './mill-complaint-list/mill-complaints.component';
import { MillComplaintHeaderComponent } from './mill-complaint-header/mill-complaint-header.component';
import { SharedModule } from '@common/shared.module';
import { QuantityAffectedTypeService } from './services/quantity-affected-type.service';

@NgModule({
    declarations: [
        MillComplaintsComponent,
        MillComplaintHeaderComponent,
        MillComplaintAddComponent,
        MillComplaintDetailComponent,
        MillComplaintBasicInfoComponent,
    ],
    imports: [SharedModule],
    providers: [QuantityAffectedTypeService],
})
export class MillComplaintModule {}
