import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

import { CustomerService } from '../customer.service';
import { CustomerPaymentTermService } from '../customerpaymentterm.service';
import { CustomerCreditStatusService } from '../customercreditstatus.service';
import { CustomerShipOnTypeService } from '../customershipontype.service';
import { ICustomer } from '../../model/interfaces/customer';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { CustomersEntityListConfig } from './customers.entity-list-config';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../../users/user.service';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
})
export class CustomersComponent implements OnInit {
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    searchControl = new FormControl();
    customers: ICustomer[];
    currentPage = 1;
    total: number;
    paymentterms: MtSearchFilterItem[] = [];
    creditstatuses: MtSearchFilterItem[] = [];
    salespeople: MtSearchFilterItem[] = [];
    shipontypes: MtSearchFilterItem[] = [];
    canAddCustomer = false;
    includeArchived = false;

    entityListConfig: CustomersEntityListConfig;
    order: string;
    orderDirection: string;

    constructor(
        private customerService: CustomerService,
        private customerPaymentTermService: CustomerPaymentTermService,
        private customerCreditStatusService: CustomerCreditStatusService,
        private userService: UserService,
        private customerShipOnTypeService: CustomerShipOnTypeService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
    ) {}

    ngAfterContentInit(): void {
        if (this.searchBar) {
            const nativeElement = this.searchBar.searchInputElement.nativeElement;

            setTimeout(() => {
                this.renderer.selectRootElement(nativeElement).focus();
            }, 0);
        }
    }

    ngOnInit(): void {
        this.entityListConfig = new CustomersEntityListConfig(this.customerService, this.notificationsService);
        this.entityListConfig.onDataChange.subscribe(() => {
            // create a copy of the customers array to force change detection
            this.customers = this.customers.slice();
        });

        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();

        this.canAddCustomer = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.getCustomers();

        this.customerPaymentTermService.getSearchFilterItems().subscribe((answer) => (this.paymentterms = answer));

        this.customerCreditStatusService.getSearchFilterItems().subscribe((answer) => (this.creditstatuses = answer));

        this.userService.getSalesPersonSearchFilterItems(this.includeArchived).subscribe((answer) => (this.salespeople = answer));

        this.customerShipOnTypeService.getSearchFilterItems().subscribe((answer) => (this.shipontypes = answer));
        this.cdr.detectChanges();

    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedPaymentTermIds: number[] = this.getSelectedFilters(this.paymentterms);
        const selectedCreditStatusIds: number[] = this.getSelectedFilters(this.creditstatuses);
        const selectedSalesPersonIds: number[] = this.getSelectedFilters(this.salespeople);
        const selectedShipOnTypeIds: number[] = this.getSelectedFilters(this.shipontypes);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PaymentTermIds',
                valueArray: selectedPaymentTermIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CreditStatusIds',
                valueArray: selectedCreditStatusIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SalesPersonIds',
                valueArray: selectedSalesPersonIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShipOnTypeIds',
                valueArray: selectedShipOnTypeIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: this.includeArchived.toString(),
            }),
        );

        return _extraSearchParams;
    }

    getCustomers(query = ''): void {
        const search = query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);

        this.customerService.get(searchparams).subscribe((answer) => {
            this.customers = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.getCustomers(query);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCustomers();
    }

    customerSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/customers', event.entity.Id]);
    }

    getSalesPersonFilterItems(): void {
        this.userService.getSalesPersonSearchFilterItems(this.includeArchived).subscribe((answer) => {
            answer.forEach((updated) => updated.Selected = this.salespeople.find((current) => current.Item.Id === updated.Item.Id)?.Selected ?? false);
            this.salespeople = answer;
        });
    }
}
