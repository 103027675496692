import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TestScanService } from '../test-scan.service';

import { Scanners } from '@model/Scanners';
import { ITestScan } from '@model/interfaces/test-scan.d';
import { ITestScanDetail } from '@model/interfaces/test-scan-detail.d';

import { TestScanDynamicControls } from '@model/form-controls/test-scan.form-controls';
import { finalize } from 'rxjs/operators';
import { IExpandableObject } from '@model/expandable-object';
import { ScannersService } from '../../scanners/scanners.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'test-stack',
    templateUrl: './test-stack.component.html',
})
export class TestStackComponent implements OnInit {
    testScan: ITestScan;
    testScanForm: FormGroup;
    abstractTestScanControls: IExpandableObject;

    testStacks: any;
    doubleClickIsDisabled = false;
    scannerId: number;
    scannerName: string;

    readonly errorThreshold = 0.2;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private testScanService: TestScanService,
        private scannersService: ScannersService,
    ) {
        this.testStacks = this.route.snapshot.data.testStacks;
    }

    ngOnInit(): void {
        this.getScannerFromRoute();

        this.testScan = this.testScanService.getEmptyTestScan();
        this.testScan.TestScanDetails = this.testStacks.map((ts) => this.testScanService.getTestScanDetailFor(ts));

        this.abstractTestScanControls = new TestScanDynamicControls().Form;
        this.testScanForm = this.fb.group({
            TestScan: this.fb.group({}),
        });
    }

    getScannerRoutePart(): string {
        return this.route.snapshot.url[this.route.snapshot.url.length - 1].toString();
    }

    getScannerFromRoute(): void {
        if (this.getScannerRoutePart() === 'drj') {
            this.scannerId = Scanners.DrJ;
            this.scannerName = 'Dr. J';
        } else {
            this.scannerId = Scanners.AI;
            this.scannerName = 'A.I.';
        }
    }

    scan(detail: ITestScanDetail): void {
        this.scannersService.getSheetCount(this.scannerId, detail.Description, detail.Caliper, detail.SkidHeight, detail.Ply).subscribe({
            error: (errorResponse: HttpErrorResponse) => {
                // formatting the error to only show up to the first breakpoint
                let response = errorResponse.error.toString().split(/\r\n/);
                this.notificationsService.error(response[0], 'Error occurred retrieving sheet count', { timeOut: 7500 });
            },
            next: (actual) => {
                detail.Actual = actual;
            },
        });
    }

    getErrorPercent(detail: ITestScanDetail): number {
        if (detail.Actual == null) {
            return null;
        } else {
            return detail.Actual / detail.Target - 1;
        }
    }

    errorPercentOkay(detail: ITestScanDetail): boolean {
        return Math.abs(this.getErrorPercent(detail)) <= this.errorThreshold;
    }

    allTestsScanned(): boolean {
        let allTestsScanned: boolean = !this.testScan.TestScanDetails.some((item) => {
            return item.Actual == null;
        });

        if (!allTestsScanned) {
            this.notificationsService.warning('You must scan all tests before marking it complete.');
        }

        return allTestsScanned;
    }

    formSubmitted(): void {
        if (!this.testScanForm.valid) {
            common.markAllFormFieldsAsTouched(this.testScanForm);
            this.error('Please check required fields.');
            this.enableDoubleClick();
            return;
        }
        if (!this.allTestsScanned()) {
            this.enableDoubleClick();
            return;
        }

        this.testScan.EmployeeName = this.testScanForm.value.TestScan.EmployeeName;
        this.testScan.ScannerId = this.scannerId;
        this.testScanService
            .createWithFks(this.testScan)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe({
                error: () => this.error('Save Failed'),
                next: () => {
                    this.notificationsService.success('Scan completed successfully');
                    this.cancelClick();
                },
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    cancelClick(): void {
        this.router.navigate(['/packaging-line/', this.getScannerRoutePart()]);
    }

    error(message: string): void {
        this.notificationsService.error(message);
    }
}
