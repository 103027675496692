<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button></route-back-button>
                Trim Sheet Configurations
            </h2>
        </div>
    </div>
</div>
<div class="row">
    <div *ngIf="formCreated" class="col-md-12">
        <form class="miles-form padded" [formGroup]="trimSheetAddForm" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-md-4" id="header-col">
                    <h4 id="header">Configure Trim Sheet <span *ngIf="trimSheet?.Archived" class="text-danger">*ARCHIVED*</span></h4>
                </div>
                <div class="col-md-6 col-trim-nav">
                    <div>
                        <button type="button" class="btn btn-default" (click)="navigateToPreviousSetupOfPriorDay()">
                            <i class="fa fa-fw fa-angle-double-left"></i>
                        </button>
                        <button type="button" class="btn btn-default" (click)="navigateToPreviousSetup()">
                            <i class="fa fa-fw fa-angle-left"></i>
                        </button>
                        <button type="button" class="btn btn-default" (click)="navigateToNextSetup()">
                            <i class="fa fa-fw fa-angle-right"></i>
                        </button>
                        <button type="button" class="btn btn-default" (click)="navigateToLatestSetup()">
                            <i class="fa fa-fw fa-angle-double-right"></i>
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="btn btn-default btn-insert"
                            [disabled]="!trimSheet || isInsertingSetup"
                            (click)="getInsertTrimSetupId(true)"
                        >
                            Insert Before
                        </button>
                        <button
                            type="button"
                            class="btn btn-default btn-insert"
                            [disabled]="!trimSheet || isInsertingSetup"
                            (click)="getInsertTrimSetupId(false)"
                        >
                            Insert After
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            class="btn btn-default btn-insert"
                            (click)="getNextSetup()"
                        >
                            Create New
                        </button>
                    </div>
                </div>
                <div *ngIf="formCreated" class="col-md-2">
                    <dynamic-field [field]="abstractTrimSheetControls.SetupId" [form]="trimSheetAddForm"></dynamic-field>
                </div>
                <div class="col-md-3">
                    <dynamic-field [field]="abstractTrimSheetControls.WeekOf" [form]="trimSheetAddForm"></dynamic-field>
                </div>
                <div class="col-md-3">
                    <dynamic-field [field]="abstractTrimSheetControls.SectionHeadingId" [form]="trimSheetAddForm"> </dynamic-field>
                    <button class="btn btn-default" (click)="$event.preventDefault(); clearDropdownValue('SectionHeadingId')">Clear</button>
                </div>
                <div class="col-md-3">
                    <dynamic-field [field]="abstractTrimSheetControls.GroupConfigurationId" [form]="trimSheetAddForm"> </dynamic-field>
                    <button class="btn btn-default" (click)="$event.preventDefault(); clearDropdownValue('GroupConfigurationId')">Clear</button>
                </div>
                <div class="col-md-3">
                    <dynamic-field [field]="abstractTrimSheetControls.Comment" [form]="trimSheetAddForm"> </dynamic-field>
                    <button class="btn btn-default" (click)="$event.preventDefault(); clearDropdownValue('Comment')">Clear</button>
                </div>
            </div>
            <hr class="m-t-sm" />
            <div id="trim-detail-container">
                <div *ngFor="let item of orderDetailLineFormArray.controls; let i = index">
                    <div class="row row-trim-details">
                        <div class="col-md-2">
                            <newman-horizontal
                                [fields]="['OrderNumber']"
                                [parentForm]="salesOrderFormArray.controls[i]"
                                [dynamicForm]="abstractSalesOrderControls"
                                [overrideForm]="true"
                                (onControlCreated)="orderNumberControlCreated($event, i)"
                            ></newman-horizontal>
                        </div>
                        <div class="col-md-1">
                            <dynamic-field
                                [field]="abstractTrimSheetDetailControls.Sheets"
                                [form]="trimSheetDetailFormArray.controls[i]"
                                [overrideForm]="true"
                                (blur)="calculateCounter()"
                            ></dynamic-field>
                        </div>
                        <div class="col-md-3">
                            <dynamic-field
                                [field]="abstractTrimSheetDetailControls.Note"
                                [form]="trimSheetDetailFormArray.controls[i]"
                                [overrideForm]="true"
                            ></dynamic-field>
                            <div class="col-with-clear">
                                <td *ngIf="!overrideWithStain[i]" class="horizontal-spacing field" style="display: inline-block">
                                    <dynamic-field
                                        [field]="abstractTrimSheetDetailControls.StockItemTypeId"
                                        [form]="trimSheetDetailFormArray.controls[i]"
                                        [overrideForm]="true"
                                    ></dynamic-field>
                                </td>
                                <br />
                                <button class="btn btn-default" (click)="$event.preventDefault(); clearDropdownValueAtIndex('StockItemTypeId', i)">
                                    Clear
                                </button>
                            </div>
                            <div class="col-with-clear">
                                <td class="horizontal-spacing field" id="select-board-grade" style="display: inline-block">
                                    <dynamic-field
                                        [field]="abstractTrimSheetDetailControls.BoardGradeId"
                                        [form]="trimSheetDetailFormArray.controls[i]"
                                        [overrideForm]="true"
                                    ></dynamic-field>
                                </td>
                                <br />
                                <button class="btn btn-default" (click)="$event.preventDefault(); clearDropdownValueAtIndex('BoardGradeId', i)">
                                    Clear
                                </button>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <dynamic-field
                                [field]="abstractTrimSheetDetailControls.Bundles"
                                (valueChanges)="getRemainingBundlesCount(i)"
                                [form]="trimSheetDetailFormArray.controls[i]"
                                [overrideForm]="true"
                            ></dynamic-field>
                            <div>
                                <span *ngIf="originalBundleCounts.length > i" class="label label-default">
                                    {{ remainingBundleCounts[i] }} remaining of {{ originalBundleCounts[i] }} ordered.
                                </span>
                            </div>
                            <button class="btn btn-secondary" (click)="populateCalculatedBundles(i); calculateCounter(); $event.preventDefault()" [disabled]="isExistingLine(i)">
                                Calculate
                            </button>
                        </div>
                        <div class="col-md-4">
                            <td class="horizontal-spacing field">
                                <dynamic-field
                                    [field]="abstractOrderDetailLineControls.Width"
                                    [form]="orderDetailLineFormArray.controls[i]"
                                    [overrideForm]="true"
                                    (blur)="calculateCounter()"
                                ></dynamic-field>
                            </td>
                            <td class="horizontal-spacing field">
                                <dynamic-field
                                    [field]="abstractOrderDetailLineControls.Length"
                                    [form]="orderDetailLineFormArray.controls[i]"
                                    [overrideForm]="true"
                                ></dynamic-field>
                            </td>
                            <td class="horizontalSpacing field">
                                <span class="control-label"
                                    ><label>Caliper</label>
                                    <span
                                        *ngIf="dressStockSalesOrderIds[i] !== trimSheetDetailSalesOrderIds[i]"
                                        class="errortext ng-star-inserted"
                                        style="font-weight: 100"
                                    >
                                        *</span
                                    ></span
                                >
                                <input
                                    class="form-control caliper-input"
                                    type="number"
                                    step="0.001"
                                    [(ngModel)]="formattedCalipers[i]"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="setCaliperValue(+$event.target.value, i)"
                                />
                            </td>
                            <td class="horizontal-spacing field">
                                <dynamic-field
                                    [field]="abstractOrderDetailLineControls.Basis"
                                    [form]="orderDetailLineFormArray.controls[i]"
                                    [overrideForm]="true"
                                ></dynamic-field>
                            </td>
                            <td class="horizontal-spacing">
                                <dynamic-field
                                    [field]="abstractOrderDetailLineControls.Count"
                                    [form]="orderDetailLineFormArray.controls[i]"
                                    [overrideForm]="true"
                                ></dynamic-field>
                            </td>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <newman-horizontal
                                [fields]="['IsPileUp']"
                                [parentForm]="orderCustomerSpecsFormArray.controls[i]"
                                [overrideForm]="true"
                                [dynamicForm]="abstractOrderCustomerSpecsControls"
                                [hideLabel]="true"
                            ></newman-horizontal>
                        </div>
                         <div class="col-md-1">
                            <dynamic-field
                                [field]="abstractTrimSheetDetailControls.IsTiedBundles"
                                [form]="trimSheetDetailFormArray.controls[i]"
                                [overrideForm]="true"
                            ></dynamic-field>
                        </div>
                        <div class="col-md-2">
                             <newman-horizontal
                                [fields]="['IsCutter', 'IsPaster']"
                                [parentForm]="orderDetailLineFormArray.controls[i]"
                                [overrideForm]="true"
                                [dynamicForm]="abstractOrderDetailLineControls"
                            ></newman-horizontal>
                        </div>
                        <div class="col-md-2">
                            <dynamic-field
                                [field]="abstractOrderDetailLineControls.NumberOfSkids"
                                [form]="orderDetailLineFormArray.controls[i]"
                                [overrideForm]="true"
                            ></dynamic-field>
                        </div>
                        <div class="col-md-2">
                            <dynamic-field
                                [field]="abstractTrimSheetDetailControls.ShipOnTypeId"
                                [form]="trimSheetDetailFormArray.controls[i]"
                                [overrideForm]="true"
                            ></dynamic-field>
                        </div>
                    </div>
                    <div class="row btn-rmv-line">
                        <div class="col-md-3 removeButton nopadding">
                            <button type="button" class="btn btn-primary btn-fab-md" (click)="removeLine(i)">
                                <i class="fa fa-fw fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <br />
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="fab-wrap">
                        <button
                            type="button"
                            *ngIf="orderDetailLineFormArray.controls.length < 4"
                            class="btn btn-primary btn-fab-md btn-fab-center"
                            (click)="addNewLine()"
                        >
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>
                    <button
                        type="submit"
                        [disabled]="counter > 115"
                        mt-doubleClickDisabled
                        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        Class="btn btn-flat btn-success"
                    >
                        Save
                    </button>
                    <button type="button" *ngIf="id" class="btn btn-flat" [ngClass]="trimSheet.Archived ? 'btn-success' : 'btn-danger'" (click)="toggleArchived()">
                        {{ trimSheet.Archived ? 'Un-Archive' : 'Archive' }}
                    </button>
                    <button type="button" (click)="showSearchModal()" class="btn btn-flat">
                        <i class="fa fa-search"></i>
                        &nbsp;Search For Setup
                    </button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                    <div class="pull-right" *ngIf="formCreated">
                        Counter:
                        <h4 class="d-i b-none">
                            <span class="label label-default">{{ counter }}</span>
                        </h4>
                        <br />
                        <label *ngIf="counter > 115" style="color: red"
                            >Counter Cannot exceed 115. <br />Please adjust your Bundles.</label
                        >
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<br />
<div class="row" *ngIf="formCreated && printTrimSheetDateReady">
    <div class="col-md-4">
        <trim-sheet-print [trimSheetId]="id" [defaultDate]="trimSheet && trimSheet.WeekOf || nextSetupWeekOf"></trim-sheet-print>
    </div>
</div>

<modal-wrapper
    [options]="searchModalOptions"
    title="Search for Setup"
    [autoShow]="false"
    (ready)="searchModal = $event"
    (okClick)="searchForSetup()"
    (cancelClick)="closeSearchModal()"
>
    <div class="text-left">
        <h5>Enter Search:</h5>
        <div class="form-group">
            <label for="setupIdNumber" class="font-weight-normal small">Setup ID Number</label>
            <input id="setupIdNumber" type="number" class="form-control" [(ngModel)]="setupSearchParams.SetupId" />
            <br />
            <span>Run Week Of</span><br />
            <label for="setupRunWeekOfStart" class="font-weight-normal small">Start</label>
            <input id="setupRunWeekOfStart" type="date" class="form-control" [(ngModel)]="setupSearchParams.RunWeekOfStart" />
            <br />
            <label for="setupRunWeekOfEnd" class="font-weight-normal small">End</label>
            <input id="setupRunWeekOfEnd" type="date" class="form-control" [(ngModel)]="setupSearchParams.RunWeekOfEnd" />
            <br />
            <label for="setupOrderNumber" class="font-weight-normal small">Order Number</label>
            <input id="setupOrderNumber" type="number" class="form-control" [(ngModel)]="setupSearchParams.OrderId" />
            <br />
            <label for="setupCaliper" class="font-weight-normal small">Caliper</label>
            <input id="setupCaliper" type="number" class="form-control" [(ngModel)]="setupSearchParams.Caliper" />
            <br />
            <label for="setupLength" class="font-weight-normal small">Length</label>
            <input id="setupLength" type="number" class="form-control" [(ngModel)]="setupSearchParams.Length" />
            <br />
            <label for="setupWidth" class="font-weight-normal small">Width</label>
            <input id="setupWidth" type="number" class="form-control" [(ngModel)]="setupSearchParams.Width" />
            <br />
            <label for="setupIncludeArchived" class="font-weight-normal small">
                <input id="setupIncludeArchived" type="checkbox" [(ngModel)]="setupSearchParams.IncludeArchived" />
                Include Archived
            </label>
            <br />
        </div>
    </div>
</modal-wrapper>

<modal-wrapper
    [options]="selectSetupModalOptions"
    title="Choose Setup"
    [autoShow]="false"
    (ready)="selectSetupModal = $event"
    (cancelClick)="closeSelectSetupModal()"
>
    <div class="text-left">
        <ul class="list-group">
            <li *ngFor="let setup of availableSetups; let i = index" class="list-group-item" (click)="selectSetup(setup)">
                Setup Id: {{ setup.SetupId }}
            </li>
        </ul>
    </div>
</modal-wrapper>
