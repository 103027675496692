<div>
    <form (ngSubmit)="loadOrder()" autocomplete="off">
        <div class="row">
            <div class="col-xs-12">
                <label>Enter the order number:</label>
                <br />
                <div class="input-group">
                    <input #orderNumberElem type="text" name="orderNumber" [(ngModel)]="orderNumber" class="form-control" maxlength="50" />
                    <span class="input-group-btn">
                        <button type="submit" class="btn btn-info"><i class="fa fa-fw fa-search"></i></button>
                    </span>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="salesOrder" class="panel panel-default p-md m-t-md">
        <div class="m-b-sm"><label>Customer Name: </label> {{ salesOrder.OrderInfo.Customer.CustomerName }}</div>
        <table class="table text-center">
            <tr>
                <th></th>
                <th></th>
                <th>Size:</th>
                <th>Caliper:</th>
                <th># Skids in Order:</th>
            </tr>
            <tr
                *ngFor="let detail of detailLines; let i = index"
                (click)="selectedDetail = detail"
                [ngClass]="{ 'bg-success': detail === selectedDetail }"
            >
                <td><i class="fa fa-fw" [ngClass]="{ 'fa-check': detail === selectedDetail }"></i></td>
                <td>Size {{ i + 1 }}:</td>
                <td>{{ detail.Width }} x {{ detail.Length }}</td>
                <td>{{ detail.Caliper }}</td>
                <td>{{ detail.NumberOfSkids }}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="salesOrder === null" class="panel panel-default p-lg m-t-md text-center">
        <em
            ><i
                >No results found for order number <span class="font-weight-bold">{{ lastSearchedOrderNumber }}</span></i
            ></em
        >
    </div>
    <div class="m-t-lg text-center">
        <button
            *ngIf="selectedDetail"
            type="button"
            (click)="addSkid()"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            class="swal2-confirm swal2-styled"
        >
            Process New Skid
        </button>
        <button
            *ngIf="selectedDetail"
            type="button"
            (click)="addSkid(true)"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            class="swal2-confirm swal2-styled"
        >
            Process Existing Skid
        </button>
        <button type="button" (click)="cancelClick()" class="swal2-cancel swal2-styled">Cancel</button>
    </div>
</div>
