import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IMillCorpInvoice } from '../interfaces/mill-corp-invoice';
import { IShipment } from '../interfaces/shipment';

export interface IMillCorpInvoiceDynamicControlsParameters {
    formGroup?: string;
    shipments?: IShipment[];
}

// tslint:disable:object-literal-sort-keys
export class MillCorpInvoiceDynamicControls {

    formGroup: string;
    shipments?: IShipment[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private millcorpinvoice?: IMillCorpInvoice, additionalParameters?: IMillCorpInvoiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'MillCorpInvoice';
        this.shipments = additionalParameters && additionalParameters.shipments || undefined;

        this.Form = {
            DateOfDelivery: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Delivery',
                name: 'DateOfDelivery',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.DateOfDelivery || null,
            }),
            DriversName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Drivers Name',
                name: 'DriversName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('DriversName') && this.millcorpinvoice.DriversName != null ? this.millcorpinvoice.DriversName.toString() : '',
            }),
            Freight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Freight',
                name: 'Freight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.Freight || null,
            }),
            Gallons: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gallons',
                name: 'Gallons',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.Gallons || null,
            }),
            MileageIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mileage In',
                name: 'MileageIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.MileageIn || null,
            }),
            MileageOut: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mileage Out',
                name: 'MileageOut',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.MileageOut || null,
            }),
            MillCorpLoadNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Corp Load Number',
                name: 'MillCorpLoadNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(6) ],
                validators: { 'maxlength': 6 },
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('MillCorpLoadNumber') && this.millcorpinvoice.MillCorpLoadNumber != null ? this.millcorpinvoice.MillCorpLoadNumber.toString() : '',
            }),
            MiscPickUpDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Misc Pick Up Description',
                name: 'MiscPickUpDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('MiscPickUpDescription') && this.millcorpinvoice.MiscPickUpDescription != null ? this.millcorpinvoice.MiscPickUpDescription.toString() : '',
            }),
            MiscPickUpValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Misc Pick Up Value',
                name: 'MiscPickUpValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.MiscPickUpValue || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('Notes') && this.millcorpinvoice.Notes != null ? this.millcorpinvoice.Notes.toString() : '',
            }),
            PalletQty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pallet Qty',
                name: 'PalletQty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.PalletQty || null,
            }),
            PalletRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pallet Rate',
                name: 'PalletRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.PalletRate || null,
            }),
            ShipmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment',
                name: 'ShipmentId',
                options: this.shipments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.ShipmentId || null,
            }),
            StopQty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stop Qty',
                name: 'StopQty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.StopQty || 0,
            }),
            StopRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stop Rate',
                name: 'StopRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.StopRate || null,
            }),
            TopQty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Top Qty',
                name: 'TopQty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.TopQty || null,
            }),
            TopRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Top Rate',
                name: 'TopRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.TopRate || null,
            }),
            TractorNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tractor Number',
                name: 'TractorNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('TractorNumber') && this.millcorpinvoice.TractorNumber != null ? this.millcorpinvoice.TractorNumber.toString() : '',
            }),
            WaitingQty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Waiting Qty',
                name: 'WaitingQty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.WaitingQty || null,
            }),
            WaitingRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Waiting Rate',
                name: 'WaitingRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.WaitingRate || null,
            }),
            WastePaperValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Waste Paper Value',
                name: 'WastePaperValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcorpinvoice && this.millcorpinvoice.WastePaperValue || null,
            }),
        };

        this.View = {
            DateOfDelivery: new DynamicLabel({
                label: 'Date Of Delivery',
                value: this.millcorpinvoice && this.millcorpinvoice.DateOfDelivery || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DriversName: new DynamicLabel({
                label: 'Drivers Name',
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('DriversName') && this.millcorpinvoice.DriversName != null ? this.millcorpinvoice.DriversName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Freight: new DynamicLabel({
                label: 'Freight',
                value: this.millcorpinvoice && this.millcorpinvoice.Freight || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            Gallons: new DynamicLabel({
                label: 'Gallons',
                value: this.millcorpinvoice && this.millcorpinvoice.Gallons || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            }),
            MileageIn: new DynamicLabel({
                label: 'Mileage In',
                value: this.millcorpinvoice && this.millcorpinvoice.MileageIn || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            MileageOut: new DynamicLabel({
                label: 'Mileage Out',
                value: this.millcorpinvoice && this.millcorpinvoice.MileageOut || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            MillCorpLoadNumber: new DynamicLabel({
                label: 'Mill Corp Load Number',
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('MillCorpLoadNumber') && this.millcorpinvoice.MillCorpLoadNumber != null ? this.millcorpinvoice.MillCorpLoadNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiscPickUpDescription: new DynamicLabel({
                label: 'Misc Pick Up Description',
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('MiscPickUpDescription') && this.millcorpinvoice.MiscPickUpDescription != null ? this.millcorpinvoice.MiscPickUpDescription.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiscPickUpValue: new DynamicLabel({
                label: 'Misc Pick Up Value',
                value: this.millcorpinvoice && this.millcorpinvoice.MiscPickUpValue || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            Notes: new DynamicLabel({
                label: 'Notes',
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('Notes') && this.millcorpinvoice.Notes != null ? this.millcorpinvoice.Notes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PalletQty: new DynamicLabel({
                label: 'Pallet Qty',
                value: this.millcorpinvoice && this.millcorpinvoice.PalletQty || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            PalletRate: new DynamicLabel({
                label: 'Pallet Rate',
                value: this.millcorpinvoice && this.millcorpinvoice.PalletRate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ShipmentId: new DynamicLabel({
                label: 'Shipment',
                value: getMetaItemValue(this.shipments as unknown as IMetaItem[], this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('ShipmentId') ? this.millcorpinvoice.ShipmentId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StopQty: new DynamicLabel({
                label: 'Stop Qty',
                value: this.millcorpinvoice && this.millcorpinvoice.StopQty || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            StopRate: new DynamicLabel({
                label: 'Stop Rate',
                value: this.millcorpinvoice && this.millcorpinvoice.StopRate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            TopQty: new DynamicLabel({
                label: 'Top Qty',
                value: this.millcorpinvoice && this.millcorpinvoice.TopQty || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            TopRate: new DynamicLabel({
                label: 'Top Rate',
                value: this.millcorpinvoice && this.millcorpinvoice.TopRate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            TractorNumber: new DynamicLabel({
                label: 'Tractor Number',
                value: this.millcorpinvoice && this.millcorpinvoice.hasOwnProperty('TractorNumber') && this.millcorpinvoice.TractorNumber != null ? this.millcorpinvoice.TractorNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            WaitingQty: new DynamicLabel({
                label: 'Waiting Qty',
                value: this.millcorpinvoice && this.millcorpinvoice.WaitingQty || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            WaitingRate: new DynamicLabel({
                label: 'Waiting Rate',
                value: this.millcorpinvoice && this.millcorpinvoice.WaitingRate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            WastePaperValue: new DynamicLabel({
                label: 'Waste Paper Value',
                value: this.millcorpinvoice && this.millcorpinvoice.WastePaperValue || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
        };

    }
}
