<h1>Stock Inventory</h1>



<div class="row">

    <div class="col-md-2">
        <label>Lot Number:</label>
        <mt-search-bar (onSearch)="searchLot($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Width:</label>
        <mt-search-bar (onSearch)="searchWidth($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Size:</label>
        <mt-search-bar (onSearch)="searchSize($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Caliper:</label>
        <mt-search-bar (onSearch)="searchCaliperMax($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Location:</label>
        <mt-search-bar (onSearch)="searchLocation($event)"></mt-search-bar>
    </div>

</div>
<mt-search-filter-checkbox text="Perfect Stock Only" (onValueChanged)="filterPerfect($event)"></mt-search-filter-checkbox>
<mt-search-filter-select 
    *ngIf="canAccessAvailableAndUnavailable"
    [items]="availabilites" 
    entity="Availability" 
    (onSelectionChanged)="filterAvailable($event)">
</mt-search-filter-select>

<entity-list
    [entities]="stockItems"
    [selectedEntities]="stockOrderLines"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getInventory()"
    [entityListConfig]="entityListConfig"
    (onColumnSorted)="columnSorted($event)"
>
</entity-list>
