import { NgModule } from '@angular/core';

import { ContextMenuModule } from 'ngx-contextmenu';
import { SharedModule } from '../common/shared.module';

import { ReceiptsRoutingModule } from './receipts-routing.module';
import { ReceiptService } from './receipt.service';
import { ReceiptDetailsComponent } from './receipt-details/receipt-details.component';
import { ReceiptPrintComponent } from './receipt-print/receipt-print.component';
import { TonsAndPricingComponent } from './tons-and-pricing/tons-and-pricing.component';
import { QuickbooksQueueComponent } from './quickbooks-queue/quickbooks-queue.component';
import { ReleasedInvoicesPrintComponent } from './released-invoices-print-component/released-invoices-print.component';
import { CustomerModule } from '../customers/customer.module';
import { BillingSheetAdjustments } from './billing-sheet-adjustments/billing-sheet-adjustments.component';

@NgModule({
    declarations: [
        ReceiptDetailsComponent,
        ReceiptPrintComponent,
        TonsAndPricingComponent,
        QuickbooksQueueComponent,
        ReleasedInvoicesPrintComponent,
        BillingSheetAdjustments,
    ],
    imports: [ContextMenuModule, SharedModule, ReceiptsRoutingModule, CustomerModule],
})
export class ReceiptsModule {
    static forRoot(): any {
        return {
            ngModule: ReceiptsModule,
            providers: [ReceiptService],
        };
    }
}
