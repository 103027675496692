import { DynamicField, DynamicFieldTypes, InputTypes, FormDateTimeInputComponent, FormDateInputComponent, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IDynamicFormModuleConfig } from '@mt-ng2/dynamic-form-config';

import { CommonService } from '../services/common.service';
import { CustomDateComponent } from '../components/custom-date.component';

export function customFormComponentFunction(dynamicField: DynamicField): any {
    if (dynamicField.type.fieldType === DynamicFieldTypes.Input && dynamicField.type.inputType === InputTypes.Datepicker) {
        return CustomDateComponent;
    }
}

export const dynamicFormModuleConfig: IDynamicFormModuleConfig = {
    commonService: CommonService,
    customFormComponentFunction: customFormComponentFunction,
};
