import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderService } from '../../sales-orders/sales-order.service';

export interface IOrderPoMapping {
    orderNumber: number;
    poNumber: string;
}

@Component({
    selector: 'update-po-numbers',
    styles: [
        `
            .border-right {
                border-right: 1px solid black;
            }
            .order-num-col {
                text-align: center;
            }
            .table-padded thead tr th,
            .table-padded tbody tr td {
                padding: 8px 8px 8px 0;
            }
        `,
    ],
    templateUrl: 'update-po-numbers.component.html',
})
export class UpdatePoNumbersComponent {
    @Input('orderPoMapping') orderPoMapping: IOrderPoMapping[];
    @Output('onSave') onSave = new EventEmitter<IOrderPoMapping[]>();
    @Output('onCancel') onCancel = new EventEmitter<any>();

    form: FormGroup = new FormGroup({updatedPos: new FormArray([])});

    get updatedPos(): FormArray {
        return this.form.controls.updatedPos as FormArray;
    }

    constructor(private fb: FormBuilder, private salesOrderService: SalesOrderService, private notificationsService: NotificationsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.orderPoMapping) {
            this.updateFormArray();
        }
    }

    updateFormArray(): void {
        this.updatedPos.clear();
        this.orderPoMapping.forEach((sd) => {
            this.updatedPos.push(this.fb.control(sd.poNumber));
        });
    }

    save(): void {
        if (this.form.dirty) {
            let updatedShipmentDetails = this.orderPoMapping
                .map((usd, i) => ({ orderNumber: usd.orderNumber, poNumber: this.form.value.updatedPos[i] }))
                .filter((usd, i) => usd.poNumber !== this.orderPoMapping[i].poNumber);
            this.salesOrderService.updatePoNumbers(updatedShipmentDetails).subscribe(
                () => {
                    this.onSave.emit(updatedShipmentDetails);
                },
                () => {
                    this.notificationsService.error('Error updating PO numbers.');
                },
            );
        }
    }

    cancel(): void {
        this.updateFormArray();
        this.onCancel.emit();
    }
}
