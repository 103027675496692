import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IBoardGrade } from '../model/interfaces/board-grade';
import { Observable } from 'rxjs';

@Injectable()
export class BoardGradeService extends MetaItemService<IBoardGrade> {
    constructor(public http: HttpClient) {
        super('BoardGradeService', 'Board Grade', 'BoardGradeIds', '/salesorders/boardgrades', http);
    }

    getAbbreviatedItems(items: IBoardGrade[]): any {
        return items.map((item) => {
            return {
                Id: item.Id,
                Name: item.Abbreviation + ' - ' + item.TrimName,
            };
        });
    }
}
