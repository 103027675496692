import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SectionHeadingService } from '../sectionheading.service';
import { GroupConfigurationService } from '../groupconfiguration.service';
import { TrimSheetService } from '../trim-sheet.service';
import { TrimSheetDynamicControlsPartial } from '@model/partials/trim-sheet-partial.form-controls';
import { SalesOrderTrimSheetDynamicControlsPartial } from '@model/partials/sales-order-trim-sheet.form-controls';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { OrderDetailLineTrimSheetDynamicControlsPartial } from '@model/partials/order-detail-line-trim-sheet.form-controls';
import { TrimSheetDetailDynamicControlsPartial } from '@model/partials/trim-sheet-detail-partial.form-controls';
import { ITrimSheet } from '@model/interfaces/trim-sheet';
import { OrderCustomerSpecificationDynamicControlsPartial } from '@model/partials/order-customer-specification.form-controls';
import { CustomerShipOnTypeService } from '../../customers/customershipontype.service';
import { ITrimSheetDetail } from '@model/interfaces/trim-sheet-detail';
import { FractionsService } from '@common/services/fractions.service';
import { IManufacturingOrderDetail } from '@model/interfaces/manufacturing-order-detail';
import { OrderTypeIds } from '@model/OrderTypes';
import { finalize } from 'rxjs/operators';
import { StockItemTypesService } from '../../sales-orders/stock-item-types.service';
import { ISetupSearchParams } from '@model/interfaces/custom/setup-search-params';
import { OrderStatusIds } from '@model/OrderStatuses';
import { IShipOnType } from '@model/interfaces/ship-on-type';
import { ISectionHeading } from '@model/interfaces/section-heading';
import { IGroupConfiguration } from '@model/interfaces/group-configuration';
import { IStockItemType } from '@model/interfaces/stock-item-type';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { BoardGradeService } from '../../sales-orders/board-grade.service';
import { IBoardGrade } from '@model/interfaces/board-grade';
import { DateService } from '@common/services/date.service';
import { ITrimSetupConfiguration } from '@model/dto/trim-setup-configuration';

@Component({
    selector: 'trim-sheet-add',
    styles: [
        `
            #header-col {
                padding-left: 0;
            }
            #header {
                margin-left: 20px;
                max-width: 500px;
            }
            .abstract-detail-controls input {
                max-width: 200px;
            }
            :host >>> .table {
                margin-bottom: 0px;
            }
            .field {
                padding-right: 8px;
            }
            .row-trim-details {
                margin-bottom: 10px;
            }
            .col-with-clear {
                max-width: 170px;
                display: inline-block;
            }
            .col-board-grade {
                margin-left: 20px;
            }
            .col-trim-nav {
                margin-top: 25px;
                display: flex;
                justify-content: space-around;
            }
            .btn-insert {
                min-width: 150px;
            }
            .btn-rmv-line {
                margin-left: 28rem;
            }
        `,
    ],
    templateUrl: './trim-sheet-add.component.html',
})
export class TrimSheetAddComponent implements OnInit, OnDestroy {
    selectSetupModal: any;
    selectSetupModalOptions: IModalOptions = {
        focusCancel: true,
        showCancelButton: true,
        showConfirmButton: false,
    };

    searchModal: IModalWrapperApi;
    searchModalOptions: IModalOptions = {
        confirmButtonText: 'Search',
        focusCancel: true,
        showCancelButton: true,
    };

    id: number;
    trimSheet: ITrimSheet;
    isInitialLoad = true;

    // abstract controls
    abstractTrimSheetControls: any;
    abstractTrimSheetDetailControls: any;
    abstractSalesOrderControls: any;
    abstractOrderDetailLineControls: any;
    abstractOrderCustomerSpecsControls: any;

    orderCustomerSpecsFormArray: FormArray;
    // CAUTION: I'M USING THIS TO LOOP FOR THE ARRAY.  IF YOU CHANGE THIS IT MAY HAVE CASCADING CONSEQUENCES
    orderDetailLineFormArray: FormArray;
    salesOrderFormArray: FormArray;
    trimSheetDetailFormArray: FormArray;
    trimSheetDetailSalesOrderIds: number[] = [];

    trimSheetAddForm: any;
    doubleClickIsDisabled = false;
    formCreated = false;

    counter: number;
    weekOf: Date;
    formattedCalipers: string[] = [];
    overrideWithStain: boolean[] = [];

    setupSearchParams: ISetupSearchParams = {
        Caliper: null,
        IncludeArchived: false,
        Length: null,
        OrderId: null,
        RunWeekOfEnd: null,
        RunWeekOfStart: null,
        SetupId: null,
        Width: null,
    };

    availableSetups: ITrimSheet[];
    originalBundleCounts: number[] = [0];
    existingConfigBundleCounts: number[] = [0];
    remainingBundleCounts: number[] = [0];
    existingConfigSkidCounts: number[] = [0];
    dressStockSalesOrderIds: number[] = [];
    groupConfigurationItems: IGroupConfiguration[];
    stockItemTypeItems: IStockItemType[];
    sectionHeadingItems: ISectionHeading[];
    shipOnTypeItems: IShipOnType[];
    boardGradeItems: IBoardGrade[];

    isInsertingSetup = false;

    // to ensure the print sheet component gets a default date based on trim sheet
    printTrimSheetDateReady = false;
    nextSetupWeekOf: Date;

    $orderNumberSubscriptions: Subscription[] = [];
    $otherSubscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private sectionHeadingService: SectionHeadingService,
        private groupConfigurationService: GroupConfigurationService,
        private salesOrderService: SalesOrderService,
        private trimSheetService: TrimSheetService,
        private fractionsService: FractionsService,
        private stockItemTypeService: StockItemTypesService,
        private shipOnTypeService: CustomerShipOnTypeService,
        private modalService: ModalService,
        private boardGradeService: BoardGradeService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.sectionHeadingService.getItems(),
            this.groupConfigurationService.getItems(),
            this.shipOnTypeService.getItems(),
            this.stockItemTypeService.getItems(),
            this.boardGradeService.getItems(),
        ]).subscribe(([sectionHeadingItems, groupConfigurationItems, shipOnTypeItems, stockItemTypeItems, boardGradeItems]) => {
            this.sectionHeadingItems = sectionHeadingItems;
            this.groupConfigurationItems = groupConfigurationItems;
            this.shipOnTypeItems = shipOnTypeItems;
            this.stockItemTypeItems = stockItemTypeItems;
            this.boardGradeItems = boardGradeItems;
            // Use the Trim Name as the dropdown option
            this.boardGradeItems.map((bgi) => {
                bgi.Name = bgi.TrimName;
            });
            this.stockItemTypeItems.unshift({ Id: null, Name: 'N/A' });
            this.shipOnTypeItems.unshift({ Id: null, Name: 'N/A' });

            this.$otherSubscriptions.add(
                this.route.params.subscribe(() => {
                    this.formCreated = false;
                    this.id = +this.route.snapshot.paramMap.get('trimSheetId');

                    if (this.id) {
                        this.trimSheetService.getById(this.id).subscribe((trimsheet) => {
                            this.trimSheet = trimsheet;
                            this.createForm(this.trimSheet.TrimSheetDetails.length);
                            this.printTrimSheetDateReady = true; // can get from trimSheet WeekOf
                            this.populateExistingTrimSheetDetails();
                            this.calculateCounter();
                        });
                    } else {
                        this.createForm();
                        this.trimSheetService.getNextSetupId().subscribe((setupConfig) => {
                            (<FormGroup>this.trimSheetAddForm.controls.TrimSheet).patchValue(setupConfig);
                            this.nextSetupWeekOf = setupConfig.WeekOf;
                            this.printTrimSheetDateReady = true; // can get from nextSetupWeekOf
                        });
                        this.calculateCounter();
                    }
                }),
            );
        });
    }

    ngOnDestroy(): void {
        this.$orderNumberSubscriptions.forEach((sub) => sub.unsubscribe());
        this.$otherSubscriptions.unsubscribe();
    }

    private resetCounts(): void {
        this.originalBundleCounts = [0];
        this.existingConfigBundleCounts = [0];
        this.remainingBundleCounts = [0];
        this.existingConfigSkidCounts = [0];
    }

    populateExistingTrimSheetDetails(): void {
        this.resetCounts();
        this.trimSheetDetailSalesOrderIds = [];
        this.dressStockSalesOrderIds = [];
        for (let index = 0; index < this.trimSheet.TrimSheetDetails.length; index++) {
            let soGroup = <FormGroup>this.salesOrderFormArray.controls[index];
            soGroup.controls.OrderNumber.setValue(this.trimSheet.TrimSheetDetails[index].SalesOrderId, { emitEvent: false });
            this.trimSheetDetailSalesOrderIds.push(this.trimSheet.TrimSheetDetails[index].SalesOrderId);
            if (this.trimSheet.TrimSheetDetails[index].SalesOrder) {
                if (this.trimSheet.TrimSheetDetails[index].OrderDetailLine) {
                    this.originalBundleCounts[index] = this.trimSheet.TrimSheetDetails[index].OrderDetailLine.Quantity;
                } else {
                    this.originalBundleCounts[index] = this.trimSheet.TrimSheetDetails[index].SalesOrder.ManufacturingOrderDetail.Quantity;
                }
                this.populateExistingConfigCounts(this.trimSheet.TrimSheetDetails[index].SalesOrder, index);
                this.getRemainingBundlesCount(index, true, this.trimSheet.TrimSheetDetails[index].Bundles);
            } else {
                this.originalBundleCounts[index] = this.trimSheet.TrimSheetDetails[index].Bundles;
            }
            this.autoFillSalesOrderDetailsFromTrimSheetDetail(this.trimSheet.TrimSheetDetails[index], index);
            this.autoFillManufacturingDetailsFromTrimSheetDetail(this.trimSheet.TrimSheetDetails[index], index);
            this.autoFillCustomerSpecsFromTrimSheetDetail(this.trimSheet.TrimSheetDetails[index], index);
            this.autoFillTrimSheetDetailsFromTrimSheetDetail(this.trimSheet.TrimSheetDetails[index], index);
        }
        this.autoFillTrimSheet(this.trimSheet);
    }

    createForm(trimSheetDetailCount = 0): void {
        this.getControls();
        this.trimSheetAddForm = this.assignFormGroups(trimSheetDetailCount);
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractTrimSheetControls = new TrimSheetDynamicControlsPartial(null, {
            formGroup: 'TrimSheet',
            groupConfigurations: this.groupConfigurationItems,
            sectionHeadings: this.sectionHeadingItems,
        }).Form;
        this.abstractTrimSheetDetailControls = new TrimSheetDetailDynamicControlsPartial(null, {
            boardGrades: this.boardGradeItems,
            formGroup: 'TrimSheetDetail',
            orderDetailLines: null,
            shipOnTypes: this.shipOnTypeItems,
            stockItemTypes: this.stockItemTypeItems,
            trimSheets: null,
        }).Form;
        this.abstractSalesOrderControls = new SalesOrderTrimSheetDynamicControlsPartial(null, {
            formGroup: 'SalesOrder',
        }).Form;
        this.abstractOrderDetailLineControls = new OrderDetailLineTrimSheetDynamicControlsPartial(null, {
            formGroup: 'OrderDetailLine',
        }).Form;
        this.abstractOrderCustomerSpecsControls = new OrderCustomerSpecificationDynamicControlsPartial(null, {
            formGroup: 'OrderCustomerSpecs',
        }).Form;
    }

    removeLine(index: number): void {
        this.orderCustomerSpecsFormArray.removeAt(index);
        this.orderDetailLineFormArray.removeAt(index);
        this.salesOrderFormArray.removeAt(index);
        this.trimSheetDetailFormArray.removeAt(index);
        this.trimSheetDetailSalesOrderIds.splice(index, 1);
        this.$orderNumberSubscriptions[index].unsubscribe();
        this.$orderNumberSubscriptions.splice(index, 1);
    }

    addNewLine(): void {
        if (this.trimSheetDetailFormArray.controls.length < 4) {
            let orderSpecsFormGroup = this.fb.group({});
            this.orderCustomerSpecsFormArray.push(orderSpecsFormGroup);
            let orderDetailLinesFormGroup = this.fb.group({});
            this.orderDetailLineFormArray.push(orderDetailLinesFormGroup);
            let salesOrderFormGroup = this.fb.group({});
            this.salesOrderFormArray.push(salesOrderFormGroup);
            let trimSheetDetailFormGroup = this.fb.group({});
            this.trimSheetDetailFormArray.push(trimSheetDetailFormGroup);
            this.trimSheetDetailSalesOrderIds.push(null);
        }
    }

    getOrderDetailLine(index: number): FormGroup {
        return <FormGroup>this.orderDetailLineFormArray.controls[index];
    }

    assignFormGroups(trimSheetDetailCount: number): FormGroup {
        const formGroup = this.fb.group({
            TrimSheet: this.fb.group({
                SetupId: '',
                WeekOf: '',
            }),
        });
        this.orderCustomerSpecsFormArray = this.fb.array([this.fb.group({})]);
        formGroup.addControl('OrderCustomerSpecs', this.orderCustomerSpecsFormArray);
        this.orderDetailLineFormArray = this.fb.array([this.fb.group({})]);
        formGroup.addControl('OrderDetailLine', this.orderDetailLineFormArray);
        this.salesOrderFormArray = this.fb.array([this.fb.group({})]);
        formGroup.addControl('SalesOrder', this.salesOrderFormArray);
        this.trimSheetDetailFormArray = this.fb.array([this.fb.group({})]);
        formGroup.addControl('TrimSheetDetail', this.trimSheetDetailFormArray);
        // Add lines for each existing trim sheet detail
        if (trimSheetDetailCount) {
            for (let index = 0; index < trimSheetDetailCount - 1; index++) {
                this.addNewLine();
            }
        }
        return formGroup;
    }

    formSubmitted(): void {
        // makes sure the stock orders are set correctly before checking validity of form
        for (let i = 0; i < this.salesOrderFormArray.controls.length; i++) {
            if (!this.salesOrderFormArray.value[i].OrderNumber) {
                this.setFieldsForStockOrder(i);
            }
        }
        if (this.trimSheetAddForm.valid && this.isValidCaliperInputs()) {
            // save logic here
            this.assignTrimSheetPayload();
            // Need to update this isValid logic if they are editing an existing config
            if (this.id) {
                this.trimSheetService
                    .updateWithFks(this.trimSheet)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(() => {
                        this.success();
                    });
            } else {
                this.trimSheetService
                    .create(this.trimSheet)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((id: number) => {
                        this.success();
                        this.navigateToSetup(id);
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.trimSheetAddForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    isValidCaliperInputs(): boolean {
        for (let i = 0; i < this.orderDetailLineFormArray.controls.length; i++) {
            if (
                this.dressStockSalesOrderIds[i] !== this.trimSheetDetailSalesOrderIds[i] &&
                this.formattedCalipers[i] === null &&
                !(this.trimSheetDetailSalesOrderIds[i] === null || this.trimSheetDetailSalesOrderIds[i] === 0)
            ) {
                this.notificationsService.error(`Caliper is required on non Dress Stock Orders. Please set a Caliper for
                Order #${this.trimSheetDetailSalesOrderIds[i]}`);
                return false;
            }
        }
        return true;
    }

    private resetForm(): void {
        this.id = null;
        this.trimSheet = null;
        this.isInsertingSetup = false;
        this.weekOf = this.trimSheet ? this.trimSheet.WeekOf : null;
        this.formattedCalipers = [];
        this.overrideWithStain[0] = false;
        this.resetCounts();
        this.trimSheetAddForm.reset();
    }

    getNextSetup(): void {
        this.resetForm();

        if (+this.route.snapshot.paramMap.get('trimSheetId')) {
            this.router.navigate(['../'], { relativeTo: this.route });
        } else {
            this.trimSheetService.getNextSetupId().subscribe((setupConfig) => {
                (<FormGroup>this.trimSheetAddForm.controls.TrimSheet).patchValue(setupConfig);
            });
        }
    }

    getInsertTrimSetupId(isBackwardsNav: boolean): void {
        this.trimSheetService.getInsertSetupConfig(this.trimSheet.SetupId, isBackwardsNav).subscribe((setupConfig) => {
            if (setupConfig) {
                this.insertTrimSetup(setupConfig);
            } else {
                this.notificationsService.error('No available slots to insert Trim Setup');
            }
        });
    }

    private insertTrimSetup(setupConfig: ITrimSetupConfiguration): void {
        this.resetForm();
        this.isInsertingSetup = true;
        (<FormGroup>this.trimSheetAddForm.controls.TrimSheet).patchValue(setupConfig);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Trim Sheet Save Failed');
    }

    success(): void {
        this.notificationsService.success('Trim Sheet Saved Successfully');
    }

    populateCalculatedBundles(targetIndex: number): void {
        if (this.orderDetailLineFormArray.controls.length === 1) {
            return;
        }
        const fromIndex = targetIndex + (targetIndex === 0 ? 1 : -1);
        const fromBundles = this.trimSheetDetailFormArray.value[fromIndex].Bundles;
        const fromSheets = this.trimSheetDetailFormArray.value[fromIndex].Sheets;
        const fromWidth = +this.fractionsService.FormatAsDecimal(this.orderDetailLineFormArray.value[fromIndex].Width);
        const targetSheets = this.trimSheetDetailFormArray.value[targetIndex].Sheets;
        const targetWidth = +this.fractionsService.FormatAsDecimal(this.orderDetailLineFormArray.value[targetIndex].Width);
        if (!fromWidth || !fromSheets || !fromBundles) {
            this.notificationsService.error(`Line ${fromIndex + 1} requires Sheets, Bundles, and Width to calculate.`);
            return;
        }
        if (!targetWidth || !targetSheets) {
            this.notificationsService.error(`Line ${targetIndex + 1} requires Sheets and Width to calculate bundles.`);
            return;
        }
        let targetBundles = Math.round((fromBundles * targetSheets * targetWidth) / (fromSheets * fromWidth));
        (<FormGroup>this.trimSheetDetailFormArray.controls[targetIndex]).controls.Bundles.setValue(targetBundles);
    }

    orderNumberControlCreated(event: FormControl, index: number): void {
        // We need to be able to track the CURRENT index of the subscription. In the event that a
        // previous row is deleted, we are unsubscribing and removing it from the subscription array.
        const subscription = new Subscription();
        subscription.add(
            event.valueChanges.subscribe((value) => {
                // The const allows us to find the current index if a previous row gets removed.
                let i = this.$orderNumberSubscriptions.findIndex((sub) => sub === subscription);
                this.getDetailsByOrderNumber(value, i);
                this.toggleBoardGradeDropdown(value, i);
            }),
        );
        this.$orderNumberSubscriptions[index] = subscription;
    }

    getDetailsByOrderNumber(orderNumber, index: number): void {
        if (orderNumber) {
            this.salesOrderService.getByOrderNumber(orderNumber, true).subscribe((salesOrder: ISalesOrder) => {
                let group = <FormGroup>this.salesOrderFormArray.controls[index];
                if (salesOrder) {
                    if (salesOrder.ManufacturingOrderDetail) {
                        this.setFieldsForSalesOrder(index, salesOrder);
                    }
                    if (salesOrder.OrderStatusId === OrderStatusIds.CreditHold) {
                        this.trimSheetDetailSalesOrderIds[index] = null;
                        this.originalBundleCounts[index] = 0;
                        this.existingConfigBundleCounts[index] = 0;
                        this.unFillManufacturingOrderDetails(index);
                        this.unFillCustomerOrderSpecs(index);
                        this.unFillSalesOrder(index);
                        this.notificationsService.error('Order is on credit hold. Credit hold orders may not be used in Trim Configs.');
                        return;
                    }

                    if (salesOrder.ManufacturingOrderDetail) {
                        this.trimSheetDetailSalesOrderIds[index] = salesOrder.Id;
                        if (salesOrder.ManufacturingOrderDetail.IsDressStock) {
                            this.dressStockSalesOrderIds[index] = salesOrder.Id;
                        } else {
                            this.dressStockSalesOrderIds[index] = null;
                        }
                        let autofillSkids: boolean = !this.trimSheetDetailFormArray.value[index].Id;
                        if (salesOrder.OrderDetailLines && salesOrder.OrderDetailLines.length) {
                            this.processOrderDetailsByLength(salesOrder, autofillSkids, index);
                        } else if (salesOrder.OrderTypeId === OrderTypeIds.Manufacturing && salesOrder.ManufacturingOrderDetail) {
                            this.populateExistingConfigCounts(salesOrder, index);
                            this.autoFillManufacturingDetails(salesOrder.ManufacturingOrderDetail, autofillSkids, index);
                            this.autoFillOrderCustomerSpecs(salesOrder, index);
                        }
                    } else {
                        this.trimSheetDetailSalesOrderIds[index] = null;

                        group.controls.OrderNumber.setErrors({ incorrect: true });
                        this.notificationsService.error('Order Number not found, belongs to a stock order, or is fully in use');
                    }
                } else {
                    group.controls.OrderNumber.setErrors({ incorrect: true });
                    this.notificationsService.error('Order Number not found.');
                }
            });
        } else {
            this.setFieldsForStockOrder(index);
        }
    }

    populateExistingConfigCounts(salesOrder: ISalesOrder, index: number): void {
        let soNumber = this.trimSheetDetailSalesOrderIds[index];
        let odlId = (this.orderDetailLineFormArray.controls[index] as FormGroup).controls.Id?.value;

        let bundles = 0;
        let bundlesInUse = 0;
        let skids = 0;
        let skidsInUse = 0;

        salesOrder.TrimSheetDetails?.forEach((currentValue) => {
            if (currentValue.TrimSheetId === this.id || (currentValue.OrderDetailLineId && currentValue.OrderDetailLineId !== odlId)) {
                return;
            }
            bundles += currentValue.Bundles;
            skids += currentValue.NumberOfSkids;
        });

        this.orderDetailLineFormArray.controls.forEach((odl: FormGroup, i) => {
            let tsd = this.trimSheetDetailFormArray.controls[i] as FormGroup;
            let lineSoNum = this.trimSheetDetailSalesOrderIds[i];
            let lineDlId = odl.controls.Id?.value;
            if (i < index && soNumber > 0 && soNumber === lineSoNum && (!lineDlId || lineDlId === odlId)) {
                skidsInUse += odl.controls.NumberOfSkids.value;
                bundlesInUse += tsd.controls.Bundles.value;
            }
        });

        this.existingConfigBundleCounts[index] = bundles + bundlesInUse;
        this.existingConfigSkidCounts[index] = skids + skidsInUse;
    }

    getRemainingBundlesCount(index: number, autoFill = false, bundleCount = 0): void {
        this.remainingBundleCounts[index] = this.originalBundleCounts[index];
        this.remainingBundleCounts[index] -= this.existingConfigBundleCounts[index];
        if (autoFill) {
            this.remainingBundleCounts[index] -= bundleCount;
        } else {
            setTimeout(() => (this.remainingBundleCounts[index] -= this.trimSheetDetailFormArray.value[index].Bundles));
        }
    }

    setFieldsForSalesOrder(index: number, salesOrder: ISalesOrder): void {
        let soGroup = <FormGroup>this.salesOrderFormArray.controls[index];
        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];

        if (!salesOrder.ManufacturingOrderDetail.IsDressStock) {
            soGroup.controls.OrderNumber.mtSetRequired(true);
            odlGroup.controls.Basis.mtSetRequired(true);
            odlGroup.controls.Count.mtSetRequired(true);
        } else {
            soGroup.controls.OrderNumber.mtSetRequired(true);
            odlGroup.controls.Basis.mtSetRequired(false);
            odlGroup.controls.Count.mtSetRequired(false);
        }

        soGroup.updateValueAndValidity();
        odlGroup.updateValueAndValidity();
    }

    setFieldsForStockOrder(index: number): void {
        let soGroup = <FormGroup>this.salesOrderFormArray.controls[index];
        soGroup.controls.OrderNumber.mtSetRequired(false);
        soGroup.controls.OrderNumber.setValue(null, { emitEvent: false });

        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];
        odlGroup.controls.Basis.mtSetRequired(false);
        odlGroup.controls.Count.mtSetRequired(false);

        soGroup.updateValueAndValidity();
        odlGroup.updateValueAndValidity();

        this.trimSheetDetailSalesOrderIds[index] = null;
    }

    processOrderDetailsByLength(salesOrder: ISalesOrder, autofillSkids: boolean, index: number): void {
        if (salesOrder.OrderDetailLines.length === 1) {
            this.autoFillOrderDetails(salesOrder, 0, autofillSkids, index);
            this.autoFillOrderCustomerSpecs(salesOrder, index, 0);
        } else {
            // if there is more than one order detail line, open a popup where the user selects the one they want
            let mappedOptions = {};
            salesOrder.OrderDetailLines.forEach((line, i) => {
                mappedOptions[i] = 'Width: ' + line.Width + ', Length: ' + line.Length + ', Height: ' + line.Height + ' etc.';
            });
            this.modalService
                .showModal({
                    input: 'select',
                    inputOptions: mappedOptions,
                    inputPlaceholder: 'Select a converting line',
                    text: `Order #${salesOrder.Id} has multiple converting lines, please select one:`,
                    title: 'Select a converting line',
                    type: 'warning',
                })
                .subscribe((result) => {
                    // result.value represents the index of the selected orderdetail line
                    if (salesOrder.OrderDetailLines[result.value]) {
                        this.autoFillOrderDetails(salesOrder, result.value, autofillSkids, index);
                        this.autoFillOrderCustomerSpecs(salesOrder, index, result.value);
                    }
                });
        }
    }

    autoFillTrimSheet(trimsheet: ITrimSheet): void {
        this.trimSheetAddForm.controls.TrimSheet.controls.SetupId.setValue(trimsheet.SetupId);
        this.trimSheetAddForm.controls.TrimSheet.controls.WeekOf.setValue(new Date(trimsheet.WeekOf.toString()));
        this.trimSheetAddForm.controls.TrimSheet.controls.SectionHeadingId.setValue(trimsheet.SectionHeadingId);
        this.trimSheetAddForm.controls.TrimSheet.controls.GroupConfigurationId.setValue(trimsheet.GroupConfigurationId);
        this.trimSheetAddForm.controls.TrimSheet.controls.Comment.setValue(trimsheet.Comment);
    }

    autoFillManufacturingDetails(manufacturingDetail: IManufacturingOrderDetail, autofillSkids: boolean, index: number): void {
        this.originalBundleCounts[index] = manufacturingDetail.Quantity;

        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];
        let tsdGroup = <FormGroup>this.trimSheetDetailFormArray.controls[index];
        if (!odlGroup.controls.Id) {
            odlGroup.addControl('Id', this.fb.control(0));
        } else {
            odlGroup.controls.Id.setValue(0);
        }
        odlGroup.controls.Width.setValue(manufacturingDetail.Width);
        odlGroup.controls.Length.setValue(manufacturingDetail.Length);
        this.setCaliperValue(manufacturingDetail.Caliper, index);
        odlGroup.controls.Basis.setValue(manufacturingDetail.Basis);
        odlGroup.controls.Count.setValue(manufacturingDetail.Count);
        if (autofillSkids) {
            odlGroup.controls.NumberOfSkids.setValue(manufacturingDetail.NumberOfSkids - this.existingConfigSkidCounts[index]);
        }
        this.setBundleValue(manufacturingDetail.Quantity, index);
        tsdGroup.controls.ShipOnTypeId.setValue(manufacturingDetail.ShipOnTypeId);
    }

    unFillManufacturingOrderDetails(index: number): void {
        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];
        odlGroup.controls.Width.setValue(null);
        odlGroup.controls.Length.setValue(null);
        this.formattedCalipers[index] = null;
        odlGroup.controls.Basis.setValue(null);
        odlGroup.controls.Count.setValue(null);
        odlGroup.controls.IsCutter.setValue(null);
        odlGroup.controls.IsPaster.setValue(null);
        odlGroup.controls.Id?.setValue(null);
        odlGroup.controls.NumberOfSkids.setValue(null);
        this.unsetBundleValue(index);
    }

    autoFillOrderDetails(salesOrder: ISalesOrder, odlIndex: number, autofillSkids: boolean, index: number): void {
        let orderDetailLine = salesOrder.OrderDetailLines[odlIndex];
        this.originalBundleCounts[index] = orderDetailLine.Quantity;

        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];

        if (!odlGroup.controls.Id) {
            odlGroup.addControl('Id', this.fb.control(orderDetailLine.Id));
        } else {
            odlGroup.controls.Id.setValue(orderDetailLine.Id);
        }

        this.populateExistingConfigCounts(salesOrder, index);

        odlGroup.controls.Width.setValue(orderDetailLine.Width);
        odlGroup.controls.Length.setValue(orderDetailLine.Length);
        odlGroup.controls.Basis.setValue(orderDetailLine.Basis);
        odlGroup.controls.Count.setValue(orderDetailLine.Count);
        odlGroup.controls.IsCutter.setValue(orderDetailLine.IsCutter);
        odlGroup.controls.IsPaster.setValue(orderDetailLine.IsPaster);
        autofillSkids
            ? odlGroup.controls.NumberOfSkids.setValue(orderDetailLine.NumberOfSkids - this.existingConfigSkidCounts[index])
            : odlGroup.controls.NumberOfSkids.setValue(0);
        this.setCaliperValue(orderDetailLine.Caliper, index);
        this.setBundleValue(orderDetailLine.Quantity, index);
    }

    autoFillOrderCustomerSpecs(salesOrder: ISalesOrder, index: number, detailLineIndex: number = null): void {
        let ocsGroup = <FormGroup>this.orderCustomerSpecsFormArray.controls[index];
        let orderCustomerSpecs = salesOrder.OrderInfo.OrderCustomerSpecification;
        if (detailLineIndex != null) {
            ocsGroup.controls.IsPileUp.setValue(salesOrder.OrderDetailLines[detailLineIndex].PileStainWhiteSide);
        } else if (orderCustomerSpecs) {
            ocsGroup.controls.IsPileUp.setValue(orderCustomerSpecs.IsPileUp);
        }
        if (!ocsGroup.controls.Id) {
            ocsGroup.addControl('Id', this.fb.control(orderCustomerSpecs ? orderCustomerSpecs.Id : 0));
        } else {
            ocsGroup.controls.Id.setValue(orderCustomerSpecs ? orderCustomerSpecs.Id : 0);
        }
    }

    unFillCustomerOrderSpecs(index: number): void {
        let ocsGroup = <FormGroup>this.orderCustomerSpecsFormArray.controls[index];
        ocsGroup.controls.IsPileUp.setValue(null);
    }

    unFillSalesOrder(index: number): void {
        let soGroup = <FormGroup>this.salesOrderFormArray.controls[index];
        soGroup.controls.OrderNumber.setValue(null);
    }

    autoFillSalesOrderDetailsFromTrimSheetDetail(trimSheetDetail: ITrimSheetDetail, index: number): void {
        let odlGroup = <FormGroup>this.salesOrderFormArray.controls[index];
        if (!odlGroup.controls.Id) {
            odlGroup.addControl('Id', this.fb.control(0));
        } else {
            odlGroup.controls.Id.setValue(0);
        }
        odlGroup.controls.OrderNumber.setValue(trimSheetDetail.SalesOrderId, { emitEvent: false });
    }

    autoFillManufacturingDetailsFromTrimSheetDetail(trimSheetDetail: ITrimSheetDetail, index: number): void {
        let odlGroup = <FormGroup>this.orderDetailLineFormArray.controls[index];
        if (!odlGroup.controls.Id) {
            odlGroup.addControl('Id', this.fb.control(trimSheetDetail.OrderDetailLineId));
        } else {
            odlGroup.controls.Id.setValue(trimSheetDetail.OrderDetailLineId);
        }
        odlGroup.controls.Width.setValue(trimSheetDetail.Width);
        odlGroup.controls.Length.setValue(trimSheetDetail.Length);
        this.setCaliperValue(trimSheetDetail.Caliper, index);
        odlGroup.controls.Basis.setValue(trimSheetDetail.Basis);
        odlGroup.controls.Count.setValue(trimSheetDetail.Count);
        odlGroup.controls.IsCutter.setValue(trimSheetDetail.IsCutter);
        odlGroup.controls.IsPaster.setValue(trimSheetDetail.IsPaster);
        odlGroup.controls.NumberOfSkids.setValue(trimSheetDetail.NumberOfSkids);
    }

    autoFillTrimSheetDetailsFromTrimSheetDetail(trimSheetDetail: ITrimSheetDetail, index: number): void {
        let ocsGroup = <FormGroup>this.trimSheetDetailFormArray.controls[index];
        if (trimSheetDetail) {
            ocsGroup.controls.Sheets.setValue(trimSheetDetail.Sheets);
            ocsGroup.controls.Note.setValue(trimSheetDetail.Note);
            ocsGroup.controls.StockItemTypeId.setValue(trimSheetDetail.StockItemTypeId);
            if (ocsGroup.controls.BoardGradeId) {
                ocsGroup.controls.BoardGradeId.setValue(trimSheetDetail.BoardGradeId);
            }
            ocsGroup.controls.ShipOnTypeId.setValue(trimSheetDetail.ShipOnTypeId);
            ocsGroup.controls.Bundles.setValue(trimSheetDetail.Bundles, { emitEvent: false });
            ocsGroup.controls.IsTiedBundles.setValue(trimSheetDetail.IsTiedBundles);
        }
        if (!ocsGroup.controls.Id) {
            ocsGroup.addControl('Id', this.fb.control(trimSheetDetail ? trimSheetDetail.Id : 0));
        } else {
            ocsGroup.controls.Id.setValue(trimSheetDetail ? trimSheetDetail.Id : 0);
        }
    }

    autoFillCustomerSpecsFromTrimSheetDetail(trimSheetDetail: ITrimSheetDetail, index: number): void {
        let ocsGroup = <FormGroup>this.orderCustomerSpecsFormArray.controls[index];
        if (trimSheetDetail) {
            ocsGroup.controls.IsPileUp.setValue(trimSheetDetail.IsPileUp);
        }
        if (!ocsGroup.controls.Id) {
            ocsGroup.addControl('Id', this.fb.control(trimSheetDetail ? trimSheetDetail.Id : 0));
        } else {
            ocsGroup.controls.Id.setValue(trimSheetDetail ? trimSheetDetail.Id : 0);
        }
    }

    assignTrimSheetPayload(): void {
        this.trimSheet = {
            Archived: false,
            Comment: this.trimSheetAddForm.value.TrimSheet.Comment,
            DateCreated: this.trimSheet ? this.trimSheet.DateCreated : null,
            GroupConfigurationId: this.trimSheetAddForm.value.TrimSheet.GroupConfigurationId,
            Id: this.trimSheet ? this.trimSheet.Id : 0,
            SectionHeadingId: this.trimSheetAddForm.value.TrimSheet.SectionHeadingId,
            SetupId: this.trimSheetAddForm.value.TrimSheet.SetupId,
            WeekOf: this.trimSheetAddForm.value.TrimSheet.WeekOf,
        };
        this.trimSheet.TrimSheetDetails = [];
        this.trimSheet.TrimSheetDetails = this.assignTrimSheetDetails();
    }

    assignTrimSheetDetails(): ITrimSheetDetail[] {
        let details: ITrimSheetDetail[] = [];
        for (let i = 0; i < this.orderDetailLineFormArray.controls.length; i++) {
            let detail: ITrimSheetDetail = {
                Basis: this.orderDetailLineFormArray.value[i].Basis,
                BoardGradeId: this.trimSheetDetailFormArray.value[i].BoardGradeId,
                Bundles: this.trimSheetDetailFormArray.value[i].Bundles,
                Caliper: this.formattedCalipers[i] ? Number.parseFloat(this.formattedCalipers[i]) : 0,
                Count: this.orderDetailLineFormArray.value[i].Count,
                Id: this.trimSheetDetailFormArray.value[i].Id ? this.trimSheetDetailFormArray.value[i].Id : 0,
                IsCutter: this.orderDetailLineFormArray.value[i].IsCutter || false,
                IsPaster: this.orderDetailLineFormArray.value[i].IsPaster || false,
                IsPileUp: this.orderCustomerSpecsFormArray.value[i].IsPileUp,
                IsTiedBundles: this.trimSheetDetailFormArray.value[i].IsTiedBundles || false,
                Length: this.orderDetailLineFormArray.value[i].Length,
                Note: this.trimSheetDetailFormArray.value[i].Note,
                NumberOfSkids: this.orderDetailLineFormArray.value[i].NumberOfSkids,
                OrderDetailLine: null,
                OrderDetailLineId: this.orderDetailLineFormArray.value[i].Id || null,
                SalesOrderId: this.trimSheetDetailSalesOrderIds[i],
                Sheets: this.trimSheetDetailFormArray.value[i].Sheets,
                ShipOnTypeId: this.trimSheetDetailFormArray.value[i].ShipOnTypeId,
                StainColor: this.trimSheetDetailFormArray.value[i].StainColor,
                StockItemTypeId: this.trimSheetDetailFormArray.value[i].StockItemTypeId,
                TrimSheetId: this.id ? this.id : 0,
                Width: this.orderDetailLineFormArray.value[i].Width,
            };

            if (!this.salesOrderFormArray.value[i].OrderNumber) {
                detail.OrderDetailLineId = null;
            }
            if (!detail.Note) {
                detail.Note = '';
            }
            this.trimSheet.TrimSheetDetails.push(detail);

            details.push(detail);
        }
        return details;
    }

    calculateCounter(): void {
        // Use timeout to give the form time to finish updating with it's current value
        setTimeout(() => {
            let counter = 0;
            for (let i = 0; i < this.orderDetailLineFormArray.controls.length; i++) {
                const orderDetailLine = this.orderDetailLineFormArray.value[i];
                const trimSheetDetail = this.trimSheetDetailFormArray.value[i];
                if (orderDetailLine.Width && trimSheetDetail.Sheets) {
                    let decimalString = this.fractionsService.FormatAsDecimal(orderDetailLine.Width);
                    counter += trimSheetDetail.Sheets * parseFloat(decimalString);
                }
            }
            this.counter = counter;
        });
    }

    setBundleValue(value: number, index: number): void {
        let tsdGroup = <FormGroup>this.trimSheetDetailFormArray.controls[index];
        tsdGroup.controls.Bundles.setValue(value - this.existingConfigBundleCounts[index]);
        tsdGroup.controls.Bundles.setValidators([Validators.required]);
    }

    unsetBundleValue(index: number): void {
        let tsdGroup = <FormGroup>this.trimSheetDetailFormArray.controls[index];
        tsdGroup.controls.Bundles.setValue(null);
        this.originalBundleCounts[index] = 0;
    }

    setCaliperValue(value: number, index: number): void {
        if (value) {
            this.abstractOrderDetailLineControls.Caliper.value = value;
            this.formattedCalipers[index] = value.toFixed(3);
        } else {
            this.formattedCalipers[index] = null;
        }
    }

    toggleStainOverride(index: number): void {
        this.trimSheetDetailFormArray.value[index].StainColor = null;
        this.trimSheetDetailFormArray.value[index].StockItemTypeId = null;
        this.overrideWithStain[index] = !this.overrideWithStain[index];
    }

    toggleBoardGradeDropdown(orderNumber: any, index: number): void {
        let boardGradeId = (<FormGroup>this.trimSheetDetailFormArray.controls[index]).controls.BoardGradeId;
        if (orderNumber) {
            this.trimSheetDetailFormArray.value[index].BoardGradeId = null;
            boardGradeId.disable();
        } else {
            boardGradeId.enable();
        }
    }

    searchForSetup(): void {
        // Format LxW as fractions for db comparison
        if (this.setupSearchParams.Width) {
            this.setupSearchParams.Width = this.fractionsService.FormatAsFraction(this.setupSearchParams.Width);
        }
        if (this.setupSearchParams.Length) {
            this.setupSearchParams.Length = this.fractionsService.FormatAsFraction(this.setupSearchParams.Length);
        }
        this.trimSheetService.getSetupBySearchParams(this.setupSearchParams).subscribe((trimsheets) => {
            if (trimsheets.length > 1) {
                this.availableSetups = trimsheets;
                this.openSetupSelectionSwal();
            } else if (trimsheets.length === 1) {
                this.navigateToSetup(trimsheets[0].Id);
            } else {
                this.modalService
                    .showModal({
                        text: 'No Trim Sheet found with these options',
                        title: 'Whoops!',
                        type: 'error',
                    })
                    .subscribe();
            }
        });
    }

    clearDropdownValue(propertyName: string): void {
        this.trimSheetAddForm.controls.TrimSheet.controls[propertyName].setValue(null);
    }

    clearDropdownValueAtIndex(propertyName: string, index: number): void {
        (<FormGroup>this.trimSheetDetailFormArray.controls[index]).controls[propertyName].setValue(null);
    }

    openSetupSelectionSwal(): void {
        this.selectSetupModal.show();
    }

    closeSelectSetupModal(): void {
        this.selectSetupModal.close();
    }

    selectSetup(setup: ITrimSheet): void {
        this.closeSelectSetupModal();
        this.navigateToSetup(setup.Id);
    }

    navigateToSetup(setupId: number): void {
        this.router.navigate(['trim-configurations/' + setupId]);
    }

    navigateToPreviousSetupOfPriorDay(): void {
        let date = this.trimSheet ? DateService.convertUTCStringToLocalDate(this.trimSheet.WeekOf).toDateString() : new Date().toDateString();
        this.trimSheetService.getFirstTrimSheet(date).subscribe((setup) => {
            if (setup) {
                this.navigateToSetup(setup.Id);
            } else {
                this.notificationsService.error('No previous Setups found');
            }
        });
    }

    navigateToPreviousSetup(): void {
        this.trimSheetService.getPreviousSetup(this.trimSheet?.SetupId || 0).subscribe((setup) => {
            if (setup) {
                this.navigateToSetup(setup.Id);
            } else {
                this.notificationsService.error('No previous Setups found');
            }
        });
    }

    navigateToNextSetup(): void {
        let setupId = this.trimSheet?.SetupId ?? 0;

        if (setupId === 0) {
            this.notificationsService.error('No next Setups found');
        } else {
            this.trimSheetService.getNextSetup(setupId).subscribe((setup) => {
                if (setup) {
                    this.navigateToSetup(setup.Id);
                } else {
                    this.notificationsService.error('No next Setups found');
                }
            });
        }
    }

    navigateToLatestSetup(): void {
        this.trimSheetService.getLastTrimSheet().subscribe((setup) => {
            this.navigateToSetup(setup.Id);
        });
    }

    showSearchModal(): void {
        this.searchModal.show();
    }

    closeSearchModal(): void {
        this.searchModal.close();
    }

    toggleArchived(): void {
        const setArchived = !this.trimSheet.Archived;
        const archiveText = this.trimSheet.Archived ? 'Un-Archive' : 'Archive';
        this.modalService
            .showModal({
                allowOutsideClick: true,
                showCancelButton: true,
                text: `Are you sure you would like to ${archiveText.toLocaleLowerCase()} this setup?`,
                title: 'Are you sure?',
                type: 'warning',
            })
            .subscribe((res) => {
                if (res.isConfirmed) {
                    forkJoin({
                        a: this.trimSheetService.updatePartial({ Archived: setArchived }, this.id),
                        b: setArchived ? this.trimSheetService.deleteBacktenderBarcodes(this.trimSheet.Id) : this.trimSheetService.createBacktenderBarcodes(this.trimSheet.Id),
                    })
                    .subscribe(() => {
                        this.notificationsService.success(`Successfully ${archiveText}d Trim Setup`);
                        this.trimSheet.Archived = setArchived;
                        if (setArchived) {
                            this.navigateToPreviousSetup();
                        }
                    });
                }
            });
    }

    isExistingLine(index: number): boolean {
        return this.trimSheetDetailFormArray.value[index].Id ? true : false;
    }
}
