import { DynamicField, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

// Force fields to be empty instead of showing '0' when value is null.
export function OverrideNumericFunctionsEmptyWhenZero(fields: DynamicField[]): void {
    fields.forEach((field) => {
        if (field.type.fieldType === DynamicFieldTypes.Numeric) {
            field.type.numericFunctions = {
                numberFormatDisplay: (value, type, scale) => (typeof value === 'number' ? value.toFixed(scale) : null),
            };
        }
    });
}

export function OverrideNumericFunctionsEmptyWhenZeroAndTruncate(fields: any): void {
    fields.forEach((field) => {
        if (field.type.fieldType === DynamicFieldTypes.Numeric) {
            field.type.numericFunctions = {
                numberFormatDisplay: (value, type, scale) => (typeof value === 'number' ? truncateToOneDecimal(value) : null),
            };
        }
    });
}

export function truncateToOneDecimal(value: number): number {
    return Math.trunc(value * 10) / 10;
}
