import { IEntitySlugs } from '@model/interfaces/custom/entity-slugs';

export const customPackagingPathSlugs: IEntitySlugs = {
    add: 'add',
    entityIdParam: 'customPackagingId',
    entityName: 'Custom Packaging',
    root: 'custom-packaging',
};

export const customPackagingPaths = {
    customPackaging: customPackagingPathSlugs.root,
    customPackagingAdd: `${customPackagingPathSlugs.root}/${customPackagingPathSlugs.add}`,
    customPackagingInfo: `${customPackagingPathSlugs.root}/:${customPackagingPathSlugs.entityIdParam}`,
};
