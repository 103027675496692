import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class TrailersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Number',
                    sort: {
                        disableSort: false,
                    },
                }),
                new EntityListColumn({
                    accessors: ['LightWeight'],
                    name: 'Light Weight',
                    sort: {
                        disableSort: false,
                    },
                }),
                new EntityListColumn({
                    accessors: ['HeavyWeight'],
                    name: 'Heavy Weight',
                    sort: {
                        disableSort: false,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Length'],
                    name: 'Length',
                    sort: {
                        disableSort: false,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
