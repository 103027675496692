import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ILining } from '../model/interfaces/lining';

@Injectable()
export class LiningService extends MetaItemService<ILining> {
    constructor(public http: HttpClient) {
        super('LiningService', 'Lining', 'LiningIds', '/salesorders/linings', http);
    }
}
