import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';

@Injectable()
export class PrintService {
    public static async printPdf(pdfBase64, noOfCopies = 1): Promise<void> {
        let pdfBlob = await this.addCopiesToPDF(pdfBase64, noOfCopies);

        if (window.navigator.msSaveOrOpenBlob) {
            // IE
            window.navigator.msSaveOrOpenBlob(pdfBlob);
        }
    }

    private static async addCopiesToPDF(base64String, numberOfCopies): Promise<any> {
        const binaryString = atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const pdfDoc = await PDFDocument.load(bytes);

        const [existingPage] = await pdfDoc.copyPages(pdfDoc, [0]);

        for (let i = 0; i < numberOfCopies - 1; i++) {
          pdfDoc.addPage(existingPage);
        }

        const pdfBytes = await pdfDoc.save();

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        let wdw = window.open(pdfBlobUrl, 'Print', 'width=1020,height=760');
        if (!wdw || wdw.closed || typeof wdw.closed === 'undefined') {
            alert('Popup blocked. Please enable popups for this site.');
            // POPUP BLOCKED
        }
        wdw.document.close(); // necessary for IE >= 10
        wdw.focus(); // necessary for IE >= 10*/
        wdw.onload = function (): void {
            wdw.print();
        };
        wdw.onafterprint = function (): void {
            wdw.close(); // for some reason this doesn't want to work
        };

        return new Blob([pdfBytes], { type: 'application/pdf' });
      }
}
