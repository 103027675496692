import { ClaimTypes } from '@model/ClaimTypes';
import { IEntitySlugs } from '@model/interfaces/custom/entity-slugs';
import { ClaimValues } from '@mt-ng2/auth-module';

export const millComplaintPathSlugs: IEntitySlugs = {
    add: 'add',
    entityIdParam: 'millComplaintId',
    entityName: 'Mill Complaint',
    root: 'mill-complaints',
};

export const millComplaintPaths = {
    millComplaints: millComplaintPathSlugs.root,
    millComplaintsAdd: `${millComplaintPathSlugs.root}/${millComplaintPathSlugs.add}`,
    millComplaintsInfo: `${millComplaintPathSlugs.root}/:${millComplaintPathSlugs.entityIdParam}`,
};

export const millComplainsAddRoleGuardRoleGuard = {
    claimType: ClaimTypes.MillComplaints,
    claimValues: [ClaimValues.FullAccess],
};

export const millComplainsRoleGuardRoleGuard = {
    claimType: ClaimTypes.MillComplaints,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
};
