import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { SkidService } from '../../skids/skid.service';
import { ISkid } from '../../model/interfaces/skid';
import { ISalesOrder } from '../../model/interfaces/sales-order';
import { PrintService } from '../../common/services/print.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'reassign-skid',
    styles: [
        `
            table td {
                padding-bottom: 2px;
            }
            table td:first-child {
                font-weight: bold;
                padding-right: 20px;
            }
            .warning {
                font-size: 11px;
                color: #e68a00;
            }
        `,
    ],
    templateUrl: './reassign-skid.component.html',
})
export class ReassignSkidComponent implements OnInit {
    skidNumber: string;
    skid: ISkid;
    order: ISalesOrder;
    printNewSkidTag: boolean;
    orderNumber: any;
    doubleClickIsDisabled = false;
    differInSize = false;

    @Input() salesOrderIds: number[];
    @Input() shipmentId: number;
    @Output('onSkidReassigned') onSkidReassigned: EventEmitter<number> = new EventEmitter<number>();
    @Output('onCancel') onCancel: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('skidNumberInput', { read: ElementRef }) skidNumberInput: ElementRef;
    @ViewChild('orderNumberInput', { read: ElementRef }) orderNumberInput: ElementRef;

    constructor(private salesOrderService: SalesOrderService, private skidService: SkidService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.loadOrder();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.salesOrderIds.firstChange && changes.salesOrderIds && changes.salesOrderIds.currentValue) {
            this.orderNumber = this.salesOrderIds[0];
            this.loadOrder();
        }
    }

    ngAfterViewInit(): void {
        this.skidNumberInput.nativeElement.focus();
    }

    loadSkid(): void {
        if (!this.skidNumber) {
            return;
        }

        this.skidService.getByFinalBarcode(this.skidNumber).subscribe((skid) => {
            if (!skid) {
                this.notificationsService.error('Skid not found.');
                this.skidNumberInput.nativeElement.focus();
                this.skidNumberInput.nativeElement.select();
                return;
            }

            this.skid = skid;

            this.checkDifferenceInSize();
        });
    }

    loadOrder(): void {
        if (!this.orderNumber) {
            return;
        }

        this.salesOrderService.getByOrderNumber(this.orderNumber, false).subscribe((order) => {
            if (!order) {
                this.notificationsService.error('Order not found.');
                return;
            }

            this.order = order;
        });
    }

    formSubmitted(): void {
        this.skidService
            .reassignSkid(this.skid.Id, this.order.Id, this.shipmentId)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                this.onSkidReassigned.emit(this.skid.Id);

                if (this.printNewSkidTag) {
                    this.skidService.getFinalTag(this.skid.Id).subscribe((pdf) => {
                        PrintService.printPdf(pdf);
                    });
                }

                this.notificationsService.success('Skid reassigned successfully.');
            });
    }

    cancel(): void {
        this.onCancel.emit();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    checkDifferenceInSize(): void {
        if (!this.orderNumber || !this.skid?.SalesOrderId) {
            return;
        }

        this.salesOrderService
            .areDifferentInSize(this.skid.SalesOrderId, this.orderNumber)
            .subscribe((differInSize) => {
                this.differInSize = differInSize;
            });
    }
}
