import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IUnitOfMeasure } from '../model/interfaces/unit-of-measure';

@Injectable()
export class UnitOfMeasuresService extends StaticMetaItemService<IUnitOfMeasure> {
    constructor(public http: HttpClient) {
        super('UnitOfMeasuresService', 'Unit of Measure', 'UnitOfMeasureIds', '/salesorders/unitofmeasures', http);
    }
}
