<div class="row">
    <div class="col-md-7">
        <h2>
            <route-back-button></route-back-button>
            Stock Sales Selector Screen
        </h2>
    </div>
</div>
<h4>Available Inventory:</h4>
<div class="row">
    <div class="col-md-2">
        <label>Lot Number:</label>
        <mt-search-bar (onSearch)="searchLot($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Width:</label>
        <mt-search-bar (onSearch)="searchWidth($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Size:</label>
        <mt-search-bar (onSearch)="searchSize($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <label>Caliper:</label>
        <mt-search-bar (onSearch)="searchCaliperMax($event)"></mt-search-bar>
    </div>
</div>
<mt-search-filter-checkbox text="Perfect Stock Only" (onValueChanged)="filterPerfect($event)"></mt-search-filter-checkbox>
<entity-list
    [entities]="stockItems"
    [selectedEntities]="stockOrderLines"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getInventory()"
    [entityListConfig]="entityListConfig"
    (onColumnSorted)="columnSorted($event)"
    (onSelectionChanged)="selectionChanged($event)"
>
</entity-list>
<div *ngIf="stockOrderLines.length">
    <h4>Items in this Order:</h4>
    <table class="table">
        <thead>
            <tr>
                <th>Stock Id</th>
                <th>Lot #</th>
                <th>Name</th>
                <th>Qty</th>
                <th>Width</th>
                <th>Length</th>
                <th>Caliper</th>
                <th>Type</th>
                <th>Color <br />(Top/Bottom)</th>
                <th>Location</th>
                <th>Price</th>
                <th>General Ledger Code</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let line of stockOrderLines">
                <td>{{ line.StockItem ? line.StockItem.StockIdNumber : line.StockIdNumber }}</td>
                <td>{{ line.StockItem ? line.StockItem.LotNumber : line.LotNumber }}</td>
                <td>{{ line.StockItem ? line.StockItem.Name : line.Name }}</td>
                <td>{{ line.StockItem ? getTruncatedBundles(line.StockItem.Bundles) : getTruncatedBundles(line.Bundles) }}</td>
                <td>{{ line.StockItem ? line.StockItem.Width : line.Width }}</td>
                <td>{{ line.StockItem ? line.StockItem.Length : line.Length }}</td>
                <td>{{ line.StockItem ? line.StockItem.CaliperMax : line.CaliperMax }}</td>
                <td>{{ line.StockItem ? line.StockItem.BoardGrade?.Abbreviation : line.BoardGrade?.Abbreviation }}</td>
                <td>{{ formatStockItemColor(line) }}</td>
                <td>{{ line.StockItem ? line.StockItem.WarehouseLocation?.Name : line.WarehouseLocation?.Name }}</td>
                <td>
                    <input type="number" class="form-control" [(ngModel)]="line.UnitPrice" (ngModelChange)="updateTotalPrice()" />
                </td>
                <td>
                    <select [(ngModel)]="line.GeneralLedgerCodeId" name="generalLedgerCodeId" class="form-control">
                        <option></option>
                        <option *ngFor="let item of generalLedgerCodes" [ngValue]="item.Id">{{ item.Code }} - {{ item.Name }}</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row">
    <div class="col-md-offset-3 col-md-3" *ngIf="formCreated"><strong>Total Price: </strong> {{ totalPrice | currency }}</div>
    <div class="col-md-offset-3 col-md-3 dynamic-field-label">
        <label>Bundles This Order:</label>
        <input type="text" disabled value="{{ this.getTotalBundles() }}" />
    </div>
    <div class="col-md-offset-9 col-md-3 dynamic-field-label">
        <label># Skids This Order:</label>
        <input type="text" disabled value="{{ stockOrderLines.length }}" />
    </div>
</div>
<div class="row col-md-12">
    <button class="btn btn-flat btn-success" [disabled]="stockOrderLines.length === 0" (click)="save()">Save & Close</button>
</div>
<br />
