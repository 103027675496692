export enum Scales {
    PackagingLine_DrJ = 1,
    Cutting = 2,
    Pasting = 3,
    Lining = 4,
    RotarySlit = 5,
    Shipping = 6,
    Stock = 7,
    PackagingLine_AI = 8,
    PackagingLine_DryEnd = 9,
}
