import { Pipe, PipeTransform } from '@angular/core';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { formatAddress, formatAddressVertical } from '@mt-ng2/format-functions';

@Pipe({
    name: 'shippingAddress',
})
export class ShippingAddressPipe implements PipeTransform {
    break = ' ';
    showName = false;
    showCode = false;
    showPrimary = false;
    multiline = false;
    showArchivedText = false;

    transform(value: ICustomerShippingAddress, ...args: any[]): string {
        if (args.length) { this.parseArgs(args); }

        return `
            ${this.showName ? value.ShipToName : ''}${this.showName ? this.multiline ? this.break : ' - ' : ''}
            ${this.multiline ? formatAddressVertical(value.Address) : formatAddress(value.Address)}
            ${this.showCode ? this.break + '(' + value.ShipToCode + ')' : ''}
            ${this.showPrimary && value.IsPrimary ? this.break + '- Primary' : ''}
            ${this.showArchivedText && value.Archived ? `${this.break}- (Archived)` : ''}
        `;
    }

    parseArgs(args: any[]): void {
        args.forEach((a) => {
            switch (a.toLocaleLowerCase()) {
                case 'name':
                    this.showName = true;
                    break;
                case 'code':
                    this.showCode = true;
                    break;
                case 'primary':
                    this.showPrimary = true;
                    break;
                case 'multiline':
                    this.multiline = true;
                    this.break = '<br>';
                    break;
                case 'archived':
                    this.showArchivedText = true;
                    break;
                default:
            }
        });
    }
}
