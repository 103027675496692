import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOrderCustomerSpecification } from '../interfaces/order-customer-specification';
import { IBandingInstruction } from '../interfaces/banding-instruction';
import { ITagSkidType } from '../interfaces/tag-skid-type';

export interface IOrderCustomerSpecificationDynamicControlsParameters {
    formGroup?: string;
    tagSkidTypes?: ITagSkidType[];
    bandingInstructions?: IBandingInstruction[];
}

export class OrderCustomerSpecificationDynamicControls {

    formGroup: string;
    tagSkidTypes: ITagSkidType[];
    bandingInstructions: IBandingInstruction[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private ordercustomerspecification?: IOrderCustomerSpecification, additionalParameters?: IOrderCustomerSpecificationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OrderCustomerSpecification';
        this.tagSkidTypes = additionalParameters && additionalParameters.tagSkidTypes || undefined;
        this.bandingInstructions = additionalParameters && additionalParameters.bandingInstructions || undefined;

        this.Form = {
            BandingInstructionId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Banding Instruction',
                name: 'BandingInstructionId',
                options: this.bandingInstructions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.BandingInstructionId || null,
            }),
            CustomerProductId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Product',
                name: 'CustomerProductId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('CustomerProductId') && this.ordercustomerspecification.CustomerProductId !== null ? this.ordercustomerspecification.CustomerProductId.toString() : '',
            }),
            CustomerSpecifications: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Specifications',
                name: 'CustomerSpecifications',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('CustomerSpecifications') && this.ordercustomerspecification.CustomerSpecifications !== null ? this.ordercustomerspecification.CustomerSpecifications.toString() : '',
            }),
            InvoiceNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Notes',
                name: 'InvoiceNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('InvoiceNotes') && this.ordercustomerspecification.InvoiceNotes !== null ? this.ordercustomerspecification.InvoiceNotes.toString() : '',
            }),
            IsCornerProtectors: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Corner Protectors',
                name: 'IsCornerProtectors',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsCornerProtectors') && this.ordercustomerspecification.IsCornerProtectors !== null ? this.ordercustomerspecification.IsCornerProtectors : false,
            }),
            IsFortyPoundBundle: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Forty Pound Bundle',
                name: 'IsFortyPoundBundle',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsFortyPoundBundle') && this.ordercustomerspecification.IsFortyPoundBundle !== null ? this.ordercustomerspecification.IsFortyPoundBundle : false,
            }),
            IsLtl: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Ltl',
                name: 'IsLtl',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsLtl') && this.ordercustomerspecification.IsLtl !== null ? this.ordercustomerspecification.IsLtl : false,
            }),
            IsNoNewmanMarkings: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is No Newman Markings',
                name: 'IsNoNewmanMarkings',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsNoNewmanMarkings') && this.ordercustomerspecification.IsNoNewmanMarkings !== null ? this.ordercustomerspecification.IsNoNewmanMarkings : false,
            }),
            IsPileUp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Pile Up',
                name: 'IsPileUp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsPileUp') && this.ordercustomerspecification.IsPileUp !== null ? this.ordercustomerspecification.IsPileUp : false,
            }),
            IsScanCounts: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Scan Counts',
                name: 'IsScanCounts',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsScanCounts') && this.ordercustomerspecification.IsScanCounts !== null ? this.ordercustomerspecification.IsScanCounts : false,
            }),
            IsSpecialLabels: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Special Labels',
                name: 'IsSpecialLabels',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsSpecialLabels') && this.ordercustomerspecification.IsSpecialLabels !== null ? this.ordercustomerspecification.IsSpecialLabels : false,
            }),
            IsTiedBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Tied Bundles',
                name: 'IsTiedBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsTiedBundles') && this.ordercustomerspecification.IsTiedBundles !== null ? this.ordercustomerspecification.IsTiedBundles : false,
            }),
            OrderNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Notes',
                name: 'OrderNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('OrderNotes') && this.ordercustomerspecification.OrderNotes !== null ? this.ordercustomerspecification.OrderNotes.toString() : '',
            }),
            ReceiptPartInfo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Part Info',
                name: 'ReceiptPartInfo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('ReceiptPartInfo') && this.ordercustomerspecification.ReceiptPartInfo !== null ? this.ordercustomerspecification.ReceiptPartInfo.toString() : '',
            }),
            SpecialInstructions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Special Instructions ( will appear on Final Tag )',
                name: 'SpecialInstructions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('SpecialInstructions') && this.ordercustomerspecification.SpecialInstructions !== null ? this.ordercustomerspecification.SpecialInstructions.toString() : '',
            }),
            TagSkidTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tag Skid Type',
                name: 'TagSkidTypeId',
                options: this.tagSkidTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.ordercustomerspecification && this.ordercustomerspecification.TagSkidTypeId || null,
            }),
        };

        this.View = {
            BandingInstructionId: new DynamicLabel(
                'Banding Instruction',
                getMetaItemValue(this.bandingInstructions, this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('BandingInstructionId') && this.ordercustomerspecification.BandingInstructionId !== null ? this.ordercustomerspecification.BandingInstructionId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerProductId: new DynamicLabel(
                'Customer Product',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('CustomerProductId') && this.ordercustomerspecification.CustomerProductId !== null ? this.ordercustomerspecification.CustomerProductId.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerSpecifications: new DynamicLabel(
                'Customer Specifications',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('CustomerSpecifications') && this.ordercustomerspecification.CustomerSpecifications !== null ? this.ordercustomerspecification.CustomerSpecifications.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoiceNotes: new DynamicLabel(
                'Invoice Notes',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('InvoiceNotes') && this.ordercustomerspecification.InvoiceNotes !== null ? this.ordercustomerspecification.InvoiceNotes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsCornerProtectors: new DynamicLabel(
                'Is Corner Protectors',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsCornerProtectors') && this.ordercustomerspecification.IsCornerProtectors !== null ? this.ordercustomerspecification.IsCornerProtectors : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsFortyPoundBundle: new DynamicLabel(
                'Is Forty Pound Bundle',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsFortyPoundBundle') && this.ordercustomerspecification.IsFortyPoundBundle !== null ? this.ordercustomerspecification.IsFortyPoundBundle : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsLtl: new DynamicLabel(
                'Is Ltl',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsLtl') && this.ordercustomerspecification.IsLtl !== null ? this.ordercustomerspecification.IsLtl : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsNoNewmanMarkings: new DynamicLabel(
                'Is No Newman Markings',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsNoNewmanMarkings') && this.ordercustomerspecification.IsNoNewmanMarkings !== null ? this.ordercustomerspecification.IsNoNewmanMarkings : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPileUp: new DynamicLabel(
                'Is Pile Up',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsPileUp') && this.ordercustomerspecification.IsPileUp !== null ? this.ordercustomerspecification.IsPileUp : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsScanCounts: new DynamicLabel(
                'Is Scan Counts',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsScanCounts') && this.ordercustomerspecification.IsScanCounts !== null ? this.ordercustomerspecification.IsScanCounts : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsSpecialLabels: new DynamicLabel(
                'Is Special Labels',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsSpecialLabels') && this.ordercustomerspecification.IsSpecialLabels !== null ? this.ordercustomerspecification.IsSpecialLabels : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsTiedBundles: new DynamicLabel(
                'Is Tied Bundles',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('IsTiedBundles') && this.ordercustomerspecification.IsTiedBundles !== null ? this.ordercustomerspecification.IsTiedBundles : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OrderNotes: new DynamicLabel(
                'Order Notes',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('OrderNotes') && this.ordercustomerspecification.OrderNotes !== null ? this.ordercustomerspecification.OrderNotes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReceiptPartInfo: new DynamicLabel(
                'Receipt Part Info',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('ReceiptPartInfo') && this.ordercustomerspecification.ReceiptPartInfo !== null ? this.ordercustomerspecification.ReceiptPartInfo.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SpecialInstructions: new DynamicLabel(
                'Special Instructions',
                this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('SpecialInstructions') && this.ordercustomerspecification.SpecialInstructions !== null ? this.ordercustomerspecification.SpecialInstructions.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TagSkidTypeId: new DynamicLabel(
                'Tag Skid Type',
                getMetaItemValue(this.tagSkidTypes, this.ordercustomerspecification && this.ordercustomerspecification.hasOwnProperty('TagSkidTypeId') && this.ordercustomerspecification.TagSkidTypeId !== null ? this.ordercustomerspecification.TagSkidTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
