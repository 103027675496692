<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button [urlSegmentsToIgnore]="['specs-info']"></route-back-button>
                Order Customer Specs
            </h2>
        </div>
    </div>
</div>
<order-customer-specs [customerId]="customerId" (onSave)="success()" (onCancel)="cancel()" (onError)="error()"></order-customer-specs>
