<h1 class="col">Enter the receipt details for {{ customerName }}</h1>
<div class="subtitle col">
    <div class="subtitle-item">
        <h2>Order: {{ receiptDetail.SalesOrderId }}</h2>
    </div>
    <div class="subtitle-item">
        <h2>Size: {{ receiptDetail.Width }} x {{ receiptDetail.Length }}</h2>
    </div>
    <div class="subtitle-item">
        <h2>{{ receiptDetail.NumberOfSkids }} Skids</h2>
    </div>
</div>
<textarea class="form-control col"  id="textOverwrite" rows="4" cols="50" [defaultValue]="overwriteTextDefault()" (blur)="submitChanges()"></textarea>
<br/>
<button type="button" class="btn btn-default" (click)="submitChanges()">Close</button>
