import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IShipment } from '../interfaces/shipment';
import { IHauler } from '../interfaces/hauler';
import { IShipmentStatus } from '../interfaces/shipment-status';
import { ITrailer } from '../interfaces/trailer';

export interface IShipmentDynamicControlsParameters {
    formGroup?: string;
    statuses?: IShipmentStatus[];
    trailers?: ITrailer[];
    haulers?: IHauler[];
}

export class ShipmentDynamicControls {

    formGroup: string;
    statuses: IShipmentStatus[];
    trailers: ITrailer[];
    haulers: IHauler[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private shipment?: IShipment, additionalParameters?: IShipmentDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Shipment';
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.trailers = additionalParameters && additionalParameters.trailers || undefined;
        this.haulers = additionalParameters && additionalParameters.haulers || undefined;

        this.Form = {
            AllocatedToDloc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Allocated To Dloc',
                name: 'AllocatedToDloc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.hasOwnProperty('AllocatedToDloc') && this.shipment.AllocatedToDloc !== null ? this.shipment.AllocatedToDloc : false,
            }),
            ClosedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Closed Date',
                name: 'ClosedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.ClosedDate || null,
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.shipment && this.shipment.hasOwnProperty('Comments') && this.shipment.Comments !== null ? this.shipment.Comments.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.DateCreated || null,
            }),
            HaulerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hauler',
                name: 'HaulerId',
                options: this.haulers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.HaulerId || null,
            }),
            IsPickup: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Pickup',
                name: 'IsPickup',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.hasOwnProperty('IsPickup') && this.shipment.IsPickup !== null ? this.shipment.IsPickup : false,
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.shipment && this.shipment.hasOwnProperty('Note') && this.shipment.Note !== null ? this.shipment.Note.toString() : '',
            }),
            OpenedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Opened Date',
                name: 'OpenedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.OpenedDate || null,
            }),
            ReceiptLoadDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Load Date',
                name: 'ReceiptLoadDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.ReceiptLoadDate || null,
            }),
            Sequence: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sequence',
                name: 'Sequence',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.Sequence || null,
            }),
            ShipmentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment Date',
                name: 'ShipmentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.ShipmentDate || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.shipment && this.shipment.StatusId || null,
            }),
            StraightTruck: new DynamicField({
                formGroup: this.formGroup,
                label: 'Straight Truck',
                name: 'StraightTruck',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.hasOwnProperty('StraightTruck') && this.shipment.StraightTruck !== null ? this.shipment.StraightTruck : false,
            }),
            TrailerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer',
                name: 'TrailerId',
                options: this.trailers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipment && this.shipment.TrailerId || null,
            }),
        };

        this.View = {
            AllocatedToDloc: new DynamicLabel(
                'Allocated To Dloc',
                this.shipment && this.shipment.hasOwnProperty('AllocatedToDloc') && this.shipment.AllocatedToDloc !== null ? this.shipment.AllocatedToDloc : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ClosedDate: new DynamicLabel(
                'Closed Date',
                this.shipment && this.shipment.ClosedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Comments: new DynamicLabel(
                'Comments',
                this.shipment && this.shipment.hasOwnProperty('Comments') && this.shipment.Comments !== null ? this.shipment.Comments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.shipment && this.shipment.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HaulerId: new DynamicLabel(
                'Hauler',
                getMetaItemValue(this.haulers, this.shipment && this.shipment.hasOwnProperty('HaulerId') && this.shipment.HaulerId !== null ? this.shipment.HaulerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPickup: new DynamicLabel(
                'Is Pickup',
                this.shipment && this.shipment.hasOwnProperty('IsPickup') && this.shipment.IsPickup !== null ? this.shipment.IsPickup : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Note: new DynamicLabel(
                'Note',
                this.shipment && this.shipment.hasOwnProperty('Note') && this.shipment.Note !== null ? this.shipment.Note.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OpenedDate: new DynamicLabel(
                'Opened Date',
                this.shipment && this.shipment.OpenedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ReceiptLoadDate: new DynamicLabel(
                'Receipt Load Date',
                this.shipment && this.shipment.ReceiptLoadDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Sequence: new DynamicLabel(
                'Sequence',
                this.shipment && this.shipment.Sequence || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ShipmentDate: new DynamicLabel(
                'Shipment Date',
                this.shipment && this.shipment.ShipmentDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StatusId: new DynamicLabel(
                'Status',
                getMetaItemValue(this.statuses, this.shipment && this.shipment.hasOwnProperty('StatusId') && this.shipment.StatusId !== null ? this.shipment.StatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            StraightTruck: new DynamicLabel(
                'Straight Truck',
                this.shipment && this.shipment.hasOwnProperty('StraightTruck') && this.shipment.StraightTruck !== null ? this.shipment.StraightTruck : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            TrailerId: new DynamicLabel(
                'Trailer',
                getMetaItemValue(this.trailers, this.shipment && this.shipment.hasOwnProperty('TrailerId') && this.shipment.TrailerId !== null ? this.shipment.TrailerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
