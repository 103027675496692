import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReceiptDetail } from '@model/interfaces/receipt-detail';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from '@common/custom-validators';
import { ShipmentService } from '../../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { truncateToOneDecimal } from '@common/common-functions.form-controls';

@Component({
    selector: 'app-stock-receipt-details',
    templateUrl: './stock-receipt-details.component.html',
})
export class StockReceiptDetailsComponent implements OnInit {
    @Input() printModal: IModalWrapperApi;
    @Input() salesOrder: ISalesOrder;
    @Input() receiptDetailsParam: IReceiptDetail[];
    @Input() shipmentId: number;

    receiptDetailsForm: FormGroup;
    get receiptDetails(): FormArray {
        return this.receiptDetailsForm.controls.receiptDetails as FormArray;
    }

    // Variables for Pagination
    get receiptDetailsForPaging(): FormArray {
        return this.receiptDetailsForm.controls.receiptDetailsForPaging as FormArray;
    }
    total = 0;
    currentPage = 1;
    itemsPerPage = 10;

    constructor(private shipmentService: ShipmentService, private notificationsService: NotificationsService, private fb: FormBuilder) {}

    ngOnInit(): void {
        if (this.salesOrder && this.shipmentId) {
            this.receiptDetailsForm = this.fb.group({
                receiptDetails: this.fb.array([], CustomValidators.AtLeastOneValue('Id')),
                receiptDetailsForPaging: this.fb.array([], CustomValidators.AtLeastOneValue('Id')),
            });
            this.addInputs();
            this.total = this.receiptDetails.length;
            this.pageReceiptDetails();
        }
    }

    ngOnChanges(changes: any): void {
        if (changes.salesOrder && changes.salesOrder.currentValue && !changes.salesOrder.firstChange) {
            this.ngOnInit();
        }
    }

    addInputs(): void {
        for (let i = 0; i < this.receiptDetailsParam.length; i++) {
            let receiptDetail = this.receiptDetailsParam[i];
            this.receiptDetails.push(
                this.fb.group({
                    Bundles: this.fb.control(truncateToOneDecimal(receiptDetail.Bundles)),
                    Caliper: this.fb.control(receiptDetail.Caliper),
                    CustomerOrderNumber: this.fb.control({ value: this.salesOrder.OrderInfo.CustomerPurchaseOrderNumber, disabled: true }),
                    Grade: this.fb.control(receiptDetail.Description),
                    Id: this.fb.control(receiptDetail.Id),
                    Length: this.fb.control(receiptDetail.Length),
                    LotNumber: this.fb.control(receiptDetail.StockOrderLine.StockItem.LotNumber),
                    Price: this.fb.control(receiptDetail.PricePer),
                    Row: this.fb.control(i + 1),
                    SalesOrderNumber: this.fb.control({ value: this.salesOrder.Id, disabled: true }),
                    ShipmentId: this.fb.control(this.shipmentId),
                    StockItemId: this.fb.control(receiptDetail.StockOrderLine.StockItem.Id),
                    Weight: this.fb.control(receiptDetail.Weight),
                    Width: this.fb.control(receiptDetail.Width),
                }),
            );
        }
    }

    pageReceiptDetails(): void {
        this.receiptDetailsForPaging.clear();
        let filteredControls = this.receiptDetails.controls.filter((group: AbstractControl) => {
            let row = group.get('Row').value;
            let start = (this.currentPage - 1) * this.itemsPerPage;
            let end = this.currentPage * this.itemsPerPage;
            return row > start && row <= end;
        });

        for (let i = 0; i < filteredControls.length; i++) {
            this.receiptDetailsForPaging.push(filteredControls[i]);
        }
    }

    removeDetailLine(index: number): void {
        let id = this.receiptDetailsForPaging.controls[index].get('Id').value;
        let i = this.receiptDetails.controls.findIndex((group: AbstractControl) => {
            let receiptDetailId = group.get('Id').value;
            return receiptDetailId === id;
        });
        this.receiptDetails.removeAt(i);
        this.pageReceiptDetails();
    }

    closeReceiptDetailsModal(): void {
        this.printModal.close();
    }
    cancelChanges(): void {
        this.closeReceiptDetailsModal();
    }

    submitChanges(): void {
        let receiptDetailsForSave = this.receiptDetails.getRawValue();
        this.shipmentService.updateStockReceipts({ ReceiptDetails: receiptDetailsForSave }).subscribe(() => {
            this.notificationsService.success('Updated Receipt Details');
        });
        this.closeReceiptDetailsModal();
    }

    truncateBundles(index: number): void {
        const bundlesControl = this.receiptDetails.controls[index].get('Bundles');
        const value = bundlesControl.value;
        bundlesControl.setValue(truncateToOneDecimal(value));
    }
}
