<div *ngIf="!isBillOfLading">
    <h2>Receipt Entry</h2>
    <div class="row">
        <div class="col-lg-8">
            <div class="miles-card miles-form padded">
                <div class="row">
                    <div class="col-xs-6">
                        <label for="shipmentId">Enter the load #: </label>
                        <div class="input-group">
                            <input
                                type="number"
                                [disabled]="shipment"
                                #shipmentIdInput
                                name="shipmentId"
                                (blur)="loadShipment(shipmentReceiptOptions.ShipmentId)"
                                [(ngModel)]="shipmentReceiptOptions.ShipmentId"
                                class="form-control"
                                min="0"
                                required
                            />
                            <span class="input-group-btn">
                                <button class="btn btn-default btn-nohover btn-flat" (click)="clearForm()" type="button">
                                    <i class="fa"></i>
                                    <i class="fa fa-remove"></i>
                                    <i class="fa"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="receiptDetailFormControls" class="col-xs-6">
                        <label>Load Date: </label>
                        <br />
                        <input type="date" class="form-control" [(ngModel)]="currentLoadDate" [ngModelOptions]="{ standalone: true }" />
                    </div>
                </div>
                <div *ngIf="receiptDetailFormControls">
                    <form (ngSubmit)="formSubmitted()">
                        <div class="row"></div>
                        <div class="m-t-sm">
                            <div class="row">
                                <div class="col-xs-3">
                                    <label># Orders/Sizes: </label>
                                    <br />
                                    <input type="text" class="form-control" value="{{ getTotalOrders() }}" disabled />
                                </div>
                                <div class="col-xs-6 col-xs-offset-3">
                                    <label>Hauled By: </label>
                                    <select class="form-control" (change)="haulerChanged($event.target.value)" [value]="shipment?.Hauler?.Id">
                                        <option value="null"></option>
                                        <option *ngFor="let hauler of haulers" [value]="hauler.Id">{{ hauler.Name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row m-t-sm">
                                <div class="col-xs-6">
                                    <label>Order</label>
                                    <select
                                        class="form-control"
                                        [(ngModel)]="this.selectedOrderId"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="orderChanged($event.target.value)"
                                    >
                                        <option [value]="detail.SalesOrderId" *ngFor="let detail of shipment?.ShipmentDetails">
                                            {{
                                                'Order #' +
                                                    getFormattedOrderNumber(detail.SalesOrder) +
                                                    getOrderDimensions(detail.SalesOrder) +
                                                    ' | ' +
                                                    detail.SalesOrder?.OrderInfo?.CustomerShippingAddress?.ShipToName
                                            }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-xs-3">
                                    <dynamic-field [field]="receiptDetailFormControls.TrailerNumber" [form]="receiptDetailForm"></dynamic-field>
                                </div>
                                <div class="col-xs-3">
                                    <dynamic-field [field]="receiptDetailFormControls.TrailerLightWeight" [form]="receiptDetailForm"></dynamic-field>
                                </div>
                            </div>
                            <div *ngIf="stockOrderSelected" class="row">
                                <div class="col-1 col-xs-offset-5">
                                    <button
                                        type="button"
                                        class="btn btn-default text-end"
                                        (click)="showStockReceiptDetailsButton()"
                                        [disabled]="!shipment"
                                    >
                                        Stock Items
                                    </button>
                                </div>
                            </div>

                            <div class="row m-t-sm">
                                <div class="col-xs-6">
                                    <label>Customer</label>
                                    <input type="text" value="{{ selectedOrder?.OrderInfo.Customer.CustomerName }}" class="form-control" disabled />
                                    <br />
                                    <div *ngIf="shipmentStopFormControls && receiptShipmentStop">
                                        <select [(ngModel)]="customerShippingAddressId" [ngModelOptions]="{ standalone: true }" class="form-control">
                                            <option *ngFor="let option of shipmentStopFormControls.CustomerShippingAddressId.options" [ngValue]="option.Id" [disabled]="option.Archived">
                                                {{ option.Name }}
                                            </option>
                                        </select>
                                    </div>
                                    <br />
                                    <dynamic-field [field]="receiptDetailFormControls.Description" [form]="receiptDetailForm"></dynamic-field>
                                    <dynamic-field
                                        [field]="receiptDetailFormControls.CustomerPurchaseOrderNumber"
                                        [form]="receiptDetailForm"
                                    ></dynamic-field>
                                </div>
                                <div class="col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <label># Skids</label>
                                            <input type="text" value="{{ noSkids }}" class="form-control" disabled />
                                        </div>
                                        <div class="col-xs-6">
                                            <dynamic-field
                                                [field]="receiptDetailFormControls.TrailerHeavyWeight"
                                                [form]="receiptDetailForm"
                                            ></dynamic-field>
                                        </div>
                                    </div>
                                    <fieldset class="m-t-sm">
                                        <legend>Receipt Format</legend>
                                        <div class="row">
                                            <div class="col-xs-6">
                                                <h5>Details:</h5>
                                                <dynamic-field
                                                    [field]="receiptDetailFormControls.ShowSkidIds"
                                                    [form]="receiptDetailForm"
                                                ></dynamic-field>
                                                <dynamic-field
                                                    [field]="receiptDetailFormControls.ShowPieces"
                                                    [form]="receiptDetailForm"
                                                ></dynamic-field>
                                                <dynamic-field
                                                    [field]="receiptDetailFormControls.ShowBundles"
                                                    [form]="receiptDetailForm"
                                                ></dynamic-field>
                                                <dynamic-field
                                                    [field]="receiptDetailFormControls.ShowWeight"
                                                    [form]="receiptDetailForm"
                                                ></dynamic-field>
                                            </div>
                                            <div class="col-xs-6">
                                                <div>
                                                    <h5>Size Totals:</h5>
                                                    <dynamic-field
                                                        class="bold-labels"
                                                        [field]="receiptDetailFormControls.ReceiptSizeTotalId"
                                                        [form]="receiptDetailForm"
                                                    ></dynamic-field>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="row m-t-sm">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="receipt-detail-fields" [hidden]="stockOrderSelected">
                                            <div class="col-xs-3">
                                                <dynamic-field [field]="receiptDetailFormControls.Width" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-3">
                                                <dynamic-field [field]="receiptDetailFormControls.Length" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-2">
                                                <dynamic-field [field]="receiptDetailFormControls.Count" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-2">
                                                <dynamic-field [field]="receiptDetailFormControls.Basis" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-2">
                                                <dynamic-field [field]="receiptDetailFormControls.Caliper" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-8"></div>
                                        <div class="col-md-2">
                                            <button
                                                type="button"
                                                class="btn btn-default text-end"
                                                (click)="showReceiptDetailsModal()"
                                                [disabled]="!shipment"
                                            >
                                                Receipt Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="receipt-detail-fields" [hidden]="stockOrderSelected">
                                            <div class="col-xs-3">
                                                <dynamic-field [field]="receiptDetailFormControls.Weight" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-3">
                                                <dynamic-field [field]="receiptDetailFormControls.Pieces" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-3">
                                                <dynamic-field [field]="receiptDetailFormControls.Bundles" [form]="receiptDetailForm"></dynamic-field>
                                            </div>
                                            <div class="col-xs-3">
                                                <dynamic-field
                                                    [field]="receiptDetailFormControls.NumberOfSkids"
                                                    [form]="receiptDetailForm"
                                                ></dynamic-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-sm">
                                <div class="col-xs-12">
                                    <dynamic-field [field]="receiptDetailFormControls.ShipOnTypeId" [form]="receiptDetailForm"></dynamic-field>
                                </div>
                                <div class="col-xs-12">
                                    <dynamic-field [field]="receiptDetailFormControls.PrintPartInfo" [form]="receiptDetailForm"></dynamic-field>
                                </div>
                                <div *ngIf="receiptDetailForm.value.ReceiptDetail.PrintPartInfo" class="col-xs-12">
                                    <input type="text" name="receiptPartInfo" [(ngModel)]="receiptPartInfo" />
                                </div>
                            </div>
                            <br />
                            <button class="btn btn-success" type="button" (click)="updateReceiptDetail()">Save Receipt</button>
                            <hr />
                            <ng-template ngFor let-stop let-i="index" [ngForOf]="shipment?.ShipmentStops">
                                <div class="medium">
                                    <span>Stop {{ stop.Stop }}:</span>
                                    <span>{{ stop.ShipmentDetails[0]?.SalesOrder?.OrderInfo?.Customer?.CustomerName + ' |' }}</span>
                                    <span class="font-italic">
                                        {{
                                            stop.CustomerShippingAddress.ShipToName +
                                                ' ' +
                                                stop.CustomerShippingAddress.Address?.City +
                                                ', ' +
                                                stop.CustomerShippingAddress.Address?.StateCode +
                                                ' ' +
                                                stop.CustomerShippingAddress.Address?.Zip
                                        }}
                                    </span>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="{{ 'individualReceipts_' + i }}"
                                            [checked]="getStopOptionsForStopNumber(stop.Stop).PrintIndividualReceipts"
                                            (change)="printIndividualReceiptsChanged(stop.Stop, $event.target.checked)"
                                        />
                                        Individual Receipts
                                    </label>
                                </div>
                                <ng-container *ngFor="let receipt of stop.Receipts">
                                    <div class="row row-col-align-bottom">
                                        <div class="col-sm-5">
                                            <div class="m-l-md">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="{{ 'printReceipt_' + i }}"
                                                        [checked]="getReceiptOptionsForReceipt(stop.Stop, receipt.Id).Print"
                                                        (change)="printReceiptChanged(stop.Stop, receipt.Id, $event.target.checked)"
                                                    />
                                                    Print Receipt
                                                </label>
                                            </div>
                                            <div class="m-l-md">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name="{{ 'omitHeader_' + i }}"
                                                        [checked]="getReceiptOptionsForReceipt(stop.Stop, receipt.Id).OmitNewmanHeaderOnCustomerCopy"
                                                        (change)="omitNewmanHeaderChanged(stop.Stop, receipt.Id, $event.target.checked)"
                                                    />
                                                    Omit Newman Header on Customer Copy
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <label>Net Weight</label>
                                            <input type="text" [value]="getActualWeightForStop(stop.Stop)" class="form-control" disabled />
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Assigned Receipt #</label>
                                            <input type="text" [value]="receipt.Number" class="form-control" disabled />
                                        </div>
                                        <div class="col-sm-2">
                                            <button
                                                type="button"
                                                class="btn"
                                                (click)="voidInvoiceClicked(receipt.Id)"
                                                [disabled]="!receipt.Number || doubleClickIsDisabled"
                                                *ngIf="canVoidInvoices"
                                            >
                                                Void Invoice
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <hr />
                            </ng-template>
                            <div class="row" *ngIf="shipment">
                                <div class="col-xs-4 col-xs-offset-4">
                                    <button
                                        type="submit"
                                        class="btn btn-success btn-block"
                                        mt-doubleClickDisabled
                                        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                                    >
                                        Print
                                    </button>
                                </div>
                                <div class="col-xs-4">
                                    <div class="pull-right">
                                        <button type="button" class="btn btn-default" (click)="isBillOfLading = true">
                                            Next <i class="fa fa-fw fa-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<bill-of-lading *ngIf="isBillOfLading" [shipment]="shipment" (canceled)="returnToReceiptEntry()"></bill-of-lading>
<modal-wrapper *ngIf="receiptDetail" [options]="modalOptions" title="" [autoShow]="false" (ready)="printModal = $event">
    <app-shipment-receipt-details
        [receiptDetail]="receiptDetail"
        [customerName]="selectedOrder?.OrderInfo.Customer.CustomerName"
        [shipmentReceiptOptions]="shipmentReceiptOptions"
        [printModal]="printModal"
        [currentSkids]="currentSkids"
        [(newReceiptDetailsText)]="newReceiptDetailsText"
    >
    </app-shipment-receipt-details>
</modal-wrapper>

<modal-wrapper *ngIf="stockOrderSelected" [options]="stockModalOptions" title="" [autoShow]="false" (ready)="stockReceiptEntryModal = $event">
    <app-stock-receipt-details
        [salesOrder]="selectedOrder"
        [receiptDetailsParam]="stockOrderReceiptDetails"
        [printModal]="stockReceiptEntryModal"
        [shipmentId]="this.shipment.Id"
    >
    </app-stock-receipt-details>
</modal-wrapper>
