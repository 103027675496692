import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { CustomerContactsService } from '../../customers/shared-entities/customer-contacts.service';

@Component({
    selector: 'app-contact-header',
    templateUrl: './contact-header.component.html',
})
export class ContactHeaderComponent implements OnInit {
    header: string;
    routeSubscription: Subscription;
    basicInfoSubscription: Subscription;

    constructor(private contactsService: CustomerContactsService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('contactId');
        this.contactsService.getContactById(id).subscribe((contact) => {
            this.header = `Customer: ${contact.Customers[0].CustomerName}`;
        });
    }
}
