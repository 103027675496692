import { Component, OnInit } from '@angular/core';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

import { LiningDynamicControls } from '../../model/form-controls/lining.form-controls';
import { FinishDynamicControls } from '../../model/form-controls/finish.form-controls';
import { StockColorDynamicControls } from '../../model/form-controls/stock-color.form-controls';
import { DefectTypeDynamicControls } from '../../model/form-controls/defect-type.form-controls';
import { StockItemTypeDynamicControls } from '../../model/form-controls/stock-item-type.form-controls';

import { BoardGradeService } from '../board-grade.service';
import { FinishService } from '../finish.service';
import { GeneralLedgerService } from '../general-ledger.service';
import { LiningService } from '../lining.service';
import { StockColorsService } from '../stock-colors.service';
import { StockItemTypesService } from '../stock-item-types.service';
import { DefectTypesService } from '../defect-types.service';
import { BoardGradeDynamicControlsPartial } from '../../model/partials/board-grade.form-controls';
import { WarehouseLocationService } from '../warehouse-location.service';
import { WarehouseLocationDynamicControls } from '../../model/form-controls/warehouse-location.form-controls';
import { GeneralLedgerCodeDynamicControlsPartial } from '@model/partials/general-ledger-code.form-controls';
import { BandingInstructionService } from '../banding-instruction.service';
import { BandingInstructionDynamicControls } from '@model/form-controls/banding-instruction.form-controls';
import { CommissionSalesPersonDynamicControls } from '@model/form-controls/commission-sales-person.form-controls';
import { CommissionSalesPersonService } from '../commission-sales-person.service';
import { MillComplaintCategoryDynamicControls } from '@model/form-controls/mill-complaint-category.form-controls';
import { MillComplaintCategoryService } from '../mill-complaint-category.service';

@Component({
    selector: 'app-order-settings',
    templateUrl: './sales-orders-settings.component.html',
})
export class SalesOrdersSettingsComponent implements OnInit {
    boardGradeDynamicForm = new BoardGradeDynamicControlsPartial(null).Form;
    liningDynamicForm = new LiningDynamicControls().Form;
    finishDynamicForm = new FinishDynamicControls().Form;
    generalLedgerCodeDynamicForm = new GeneralLedgerCodeDynamicControlsPartial().Form;
    generalLedgerCodeTitle = 'General Ledger Codes / Tons & Pricing';
    stockColorsDynamicForm = new StockColorDynamicControls().Form;
    stockItemTypesDynamicForm = new StockItemTypeDynamicControls().Form;
    defectTypesDynamicForm = new DefectTypeDynamicControls().Form;
    warehouseLocationsTitle = 'Warehouse Locations';
    warehouseLocationsDynamicForm = new WarehouseLocationDynamicControls().Form;
    bandingInstructionsDynamicForm = new BandingInstructionDynamicControls().Form;
    commissionSalesPersonDynamicForm = new CommissionSalesPersonDynamicControls().Form;
    millComplaintCategoryDynamicForm = new MillComplaintCategoryDynamicControls().Form;

    constructor(
        public boardGradeService: BoardGradeService,
        public finishService: FinishService,
        public generalLedgerService: GeneralLedgerService,
        public liningService: LiningService,
        public stockColorsService: StockColorsService,
        public stockItemTypesService: StockItemTypesService,
        public defectTypesService: DefectTypesService,
        public warehouseLocationsService: WarehouseLocationService,
        public bandingInstructionService: BandingInstructionService,
        public commissionSalesPersonService: CommissionSalesPersonService,
        public millComplaintCategoryService: MillComplaintCategoryService,
    ) {}

    ngOnInit(): void {
        (<DynamicField>this.boardGradeDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.boardGradeDynamicForm.QbInventoryName).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.boardGradeDynamicForm.TrimName).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.finishDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.generalLedgerCodeDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.liningDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.stockColorsDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.stockItemTypesDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.defectTypesDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.warehouseLocationsDynamicForm.Name).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.commissionSalesPersonDynamicForm.FirstName).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.commissionSalesPersonDynamicForm.LastName).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.millComplaintCategoryDynamicForm.Name).type.inputType = InputTypes.Textbox;
    }
}
