<div *ngIf="formCreated">
    <form [formGroup]="millShiftPersonnelForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-xs-12">
                <dynamic-field [field]="abstractMillShiftPersonnelControls.TourBoss" [form]="millShiftPersonnelForm"></dynamic-field>
                <dynamic-field [field]="abstractMillShiftPersonnelControls.Backtender" [form]="millShiftPersonnelForm"></dynamic-field>
                <!-- <dynamic-field [field]="abstractMillShiftPersonnelControls.Scanner" [form]="millShiftPersonnelForm"></dynamic-field> -->
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 font-small">
                <newman-horizontal
                    [fields]="['Finisher1', 'Finisher2', 'Finisher3', 'Finisher4', 'Finisher5']"
                    [dynamicForm]="abstractMillShiftPersonnelControls"
                    [parentForm]="millShiftPersonnelForm"
                ></newman-horizontal>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 font-small">
                <newman-horizontal
                    [fields]="['Scanner', 'Bander1', 'Bander2', 'Bander3', 'Bander4']"
                    [dynamicForm]="abstractMillShiftPersonnelControls"
                    [parentForm]="millShiftPersonnelForm"
                ></newman-horizontal>
            </div>
        </div>
        <hr class="m-t-xs" />
        <div class="pull-left text-muted">
            {{ currentTime | date: 'M/dd/yyyy hh:mm:ss aaa' }}
        </div>
        <div class="pull-right"><label>Shift:&nbsp;&nbsp;</label>{{ millShift.Code }}</div>
        <div class="clearfix"></div>
        <hr class="m-t-sm" />
        <div class="m-t-sm text-center">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" class="swal2-confirm swal2-styled">
                Continue
            </button>
        </div>
    </form>
</div>
