import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';

import { SkidService } from '../skid.service';
import { Skid } from '@model/classes/skid';
import { entityListModuleConfig } from '@common/shared.module';
import { SkidsEntityListConfig } from './skids.entity-list-config';
import { ContextMenuService } from 'ngx-contextmenu';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-skids',
    templateUrl: './skids.component.html',
})
export class SkidsComponent implements OnInit {
    searchControl = new FormControl();
    skids: Skid[];
    salesOrderId: string;
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new SkidsEntityListConfig(this.skidService, this.contextMenuService);
    canEdit: boolean;

    constructor(
        private skidService: SkidService,
        private activatedRoute: ActivatedRoute,
        private contextMenuService: ContextMenuService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);
        this.entityListConfig.onSkidRemoved.subscribe((isSkidRemoved) => {
            this.getSkids();
        });
        const salesOrderId = this.activatedRoute.snapshot.paramMap.get('salesOrderId');
        if (salesOrderId) {
            this.salesOrderId = salesOrderId;
        }
        this.getSkids();
    }

    getSkids(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.salesOrderId) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'SalesOrderId',
                    value: this.salesOrderId,
                }),
            );
        }

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'dateProcessed',
            orderDirection: 'desc',
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.skidService.get(searchparams).subscribe((answer) => {
            this.skids = answer.body.map((skid) => new Skid(skid));
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        if (this.canEdit) {
            this.query = query;
            this.currentPage = 1;
            this.getSkids();
        }
    }
}
