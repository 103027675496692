<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Pasting Team Report {{currentReportId > 0 ? "#" + currentReportId : ""}}</h2>
        </div>
    </div>
</div>
<div *ngIf="pastingTeamLogEntryForm">
    <form class="miles-form p-md form-sm" style="border: 3px solid #ffff43;" [formGroup]="pastingTeamLogEntryForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-xs-4">
                <dynamic-field [field]="abstractPastingTeamLogControls.Date" [form]="pastingTeamLogEntryForm" (controlCreated)="dateControlCreated($event)"></dynamic-field>
            </div>
            <div class="col-xs-1">
                <dynamic-field [field]="abstractPastingTeamLogControls.ShiftCode" [form]="pastingTeamLogEntryForm"></dynamic-field>
            </div>
            <div class="col-xs-2 col-xs-offset-2">
                <dynamic-field [field]="abstractPastingTeamLogControls.MachineNumber" [form]="pastingTeamLogEntryForm"></dynamic-field>
            </div>
            <div class="col-xs-2" *ngIf="maxReportId > 1">
                <div class="pull-right">
                    <button type="button" class="btn btn-default" (click)="navigateToFirstReportOfTheDay()">
                        <i class="fa fa-fw fa-angle-double-left"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToPreviousReport()">
                        <i class="fa fa-fw fa-angle-left"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToNextReport()">
                        <i class="fa fa-fw fa-angle-right"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToLatestReportOfTheDay()">
                        <i class="fa fa-fw fa-angle-double-right"></i>
                    </button>
                </div>
            </div>
            <div [ngClass]="maxReportId > 1 ? 'col-xs-1' : 'col-xs-1 col-xs-offset-2'">
                <button
                    type="button"
                    class="btn btn-default btn-insert"
                    (click)="getNewReport()"
                    [disabled]="!maxReportId"
                >
                    Create New
                </button>
            </div>
        </div>
        <div *ngIf="!loadingLogs" class="row">
            <div class="col-xs-4">
                <dynamic-field [field]="abstractPastingTeamLogControls.Feeder" [form]="pastingTeamLogEntryForm"></dynamic-field>
            </div>
            <div class="col-xs-4">
                <dynamic-field [field]="abstractPastingTeamLogControls.Catcher" [form]="pastingTeamLogEntryForm"></dynamic-field>
            </div>
            <div class="col-xs-4">
                <dynamic-field [field]="abstractPastingTeamLogControls.Pressman" [form]="pastingTeamLogEntryForm"></dynamic-field>
            </div>
        </div>
        <hr />
        <div *ngFor="let group of pastingTeamLogFormArray.controls; let i = index">
            <div (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = null">
                <div style="position: absolute; right: 40px; z-index: 1;" *ngIf="hoveredIndex === i">
                    <i class="fa fa-fw fa-lg fa-close pointer" style="position: relative; top: -6px;" (click)="removeDetail(i)"></i>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="row">
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [(field)]="abstractPastingTeamLogControls.SalesOrderId"
                                    [form]="group"
                                    (valueChanges)="pullSalesOrderValues(i)"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.CustomerName"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Bundles"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Width"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Length"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Ply"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Caliper"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.BoardGrade"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Skids"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.BdlSk"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Count"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-2">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.TimeOn"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="row row-col-align-middle">
                            <div class="col-xs-4">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Note"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.BoardTypeId"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1 col-xs-offset-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Remarks"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.Break"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-2">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractPastingTeamLogControls.TimeOff"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addDetail()">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
        <div class="row m-t-lg">
            <div class="col-xs-3 col-xs-offset-3">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-success btn-block">
                    Save
                </button>
            </div>
            <div class="col-xs-3">
                <button type="button" Class="btn btn-default btn-block" (click)="cancelClick()">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>
