import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { IShipment } from '@model/interfaces/shipment';
import { Observable } from 'rxjs';
import { ShipmentService } from '../../shipments/shipment.service';

@Injectable({ providedIn: 'root' })
export class ShipmentResolver implements Resolve<IShipment> {
    constructor(private shipmentService: ShipmentService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IShipment>|IShipment {
        if (route.url[0].path === 'add') {
            return this.shipmentService.getEmptyShipment();
        } else {
            const id = +route.paramMap.get('shipmentId');
            return this.shipmentService.getById(id);
        }
    }
}
