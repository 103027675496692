<div>
    <div class="row">
        <div class="col-md-7">
            <h2>{{title}}</h2>
        </div>
    </div>
</div>
<div *ngIf="trimViewForm" class="row">
    <div class="col-md-12">
        <form class="miles-form p-md" [formGroup]="trimViewForm">
            <div class="row">
                <div class="col-md-2">
                    <dynamic-field id="WeekOf" [field]="abstractTrimSheetControls.Form.WeekOf" [form]="trimViewForm"></dynamic-field>
                    <label>{{ abstractTrimSheetControls.View.StartTime.label }}:</label>
                    <br />
                    <label *ngIf="trimSheet && trimSheet.StartTime">{{ trimSheet.StartTime | date: 'shortTime' }}</label>
                </div>
                <div class="col-md-6 col-md-offset-1">
                    <div *ngIf="trimSheet && trimSheet.StartTime">
                        <div class="trim-view-header">Currently Running on the Machine</div>
                        <div class="group-configuration" *ngIf="trimSheet && trimSheet.GroupConfiguration">
                            {{ trimSheet.GroupConfiguration.Name }}
                        </div>
                    </div>
                    <div *ngIf="trimSheet && !trimSheet.StartTime">
                        <div class="trim-view-header read-only">Read Only View</div>
                    </div>
                </div>
                <div class="col-md-2 col-md-offset-1">
                    <dynamic-field
                        [field]="abstractTrimSheetControls.Form.SetupId"
                        [form]="trimViewForm"
                        (blur)="getTrimSheetBySetupId()"

                    ></dynamic-field>
                    <label>Machine Trim:</label>
                    <br />
                    <input class="form-control" type="text" disabled [value]="counter" />
                </div>
            </div>
            <div class="row m-t-md">
                <div class="col-md-12">
                    <table class="table table-striped cursor-auto">
                        <thead>
                            <tr>
                                <th># of Bundles</th>
                                <th># of Sheets</th>
                                <th>Size</th>
                                <th>Basis</th>
                                <th>Count</th>
                                <th>Caliper</th>
                                <th>Order #</th>
                                <th>Pile Down</th>
                                <th>Tie On</th>
                                <th>To Cutter</th>
                                <th>Pasted</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let detail of trimSheet?.TrimSheetDetails">
                                <td>{{ detail.Bundles }}</td>
                                <td>{{ detail.Sheets }}</td>
                                <td>{{ detail.Width }} x {{ detail.Length }}</td>
                                <td>{{ detail.Basis }}</td>
                                <td>{{ detail.Count }}</td>
                                <td>{{ detail.Caliper }}</td>
                                <td>{{ getFormattedOrderNumber(detail.SalesOrder) }}</td>
                                <td>{{ detail.IsPileUp === false ? 'x' : '' }}</td>
                                <td></td>
                                <td>{{ detail.IsCutter ? 'x' : '' }}</td>
                                <td>{{ detail.IsPaster ? 'x' : '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr class="m-y-lg" />
                </div>
            </div>
            <div>
                <div class="pull-left m-t-md">
                    <button type="button" Class="btn btn-default p-md" (click)="showPrintTags()" [disabled]="readOnly">Tags</button>
                </div>
                <div class="pull-left p-l-md">
                    <fieldset *ngIf="trimSheet && trimSheet.StartTime">
                        <legend class="text-danger">End Current Setup</legend>
                        <button type="button" Class="btn btn-default" (click)="startNextSetup()" [disabled]="readOnly">
                            <i class="fa fa-fw fa-arrow-circle-right"></i>&nbsp;Start Next Setup on List
                        </button>
                        <button type="button" Class="btn btn-default" (click)="chooseFromList(true)" [disabled]="readOnly">
                            <i class="fa fa-fw fa-list"></i>&nbsp;Choose Setup from List
                        </button>
                    </fieldset>
                    <fieldset *ngIf="trimSheet && !trimSheet.StartTime">
                        <legend class="text-danger">Current Setup</legend>
                        <button type="button" Class="btn btn-default" (click)="returnToCurrentSetup()" [disabled]="readOnly">
                            <i class="fa fa-fw fa-arrow-circle-left"></i>&nbsp;Return to Current Setup
                        </button>
                    </fieldset>
                </div>
                <div class="pull-left p-l-md">
                    <fieldset>
                        <legend class="text-danger">View Other Setups</legend>
                        <button type="button" Class="btn btn-default" (click)="chooseFromList(false)" [disabled]="readOnly">
                            <i class="fa fa-fw fa-list"></i>&nbsp;Choose Setup from List
                        </button>
                    </fieldset>
                </div>
                <div class="pull-left p-l-md">
                    <fieldset>
                        <legend class="text-danger">Online Trim</legend>
                        <button type="button" Class="btn btn-default" (click)="navigateToTrimSetupPreview()">
                            <i class="fa fa-fw fa-search"></i>Online Trim
                        </button>
                    </fieldset>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
    </div>
</div>

<modal-wrapper [options]="modalOptions" title="Print Sheet Weight Reports" [autoShow]="false" (ready)="printModal = $event">
    <trim-sheet-tags [setupId]="trimSheet?.SetupId" (cancel)="closePrintTags()"></trim-sheet-tags>
</modal-wrapper>
