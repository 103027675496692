import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IBackhaulInfo } from '../interfaces/backhaul-info';
import { IMillCorpCustomer } from '../interfaces/mill-corp-customer';
import { IMillCorpInvoice } from '../interfaces/mill-corp-invoice';

// tslint:disable:object-literal-sort-keys
export interface IBackhaulInfoDynamicControlsParameters {
    formGroup?: string;
    millCorpInvoices?: IMillCorpInvoice[];
    millCorpCustomers?: IMillCorpCustomer[];
}

export class BackhaulInfoDynamicControls {

    formGroup: string;
    millCorpInvoices?: IMillCorpInvoice[];
    millCorpCustomers?: IMillCorpCustomer[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private backhaulinfo?: IBackhaulInfo, additionalParameters?: IBackhaulInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BackhaulInfo';
        this.millCorpInvoices = additionalParameters && additionalParameters.millCorpInvoices || undefined;
        this.millCorpCustomers = additionalParameters && additionalParameters.millCorpCustomers || undefined;

        this.Form = {
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('Description') && this.backhaulinfo.Description != null ? this.backhaulinfo.Description.toString() : '',
            }),
            DropOffTrailer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Drop Off Trailer',
                name: 'DropOffTrailer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('DropOffTrailer') && this.backhaulinfo.DropOffTrailer != null ? this.backhaulinfo.DropOffTrailer.toString() : '',
            }),
            MillCorpCustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Corp Customer',
                name: 'MillCorpCustomerId',
                options: this.millCorpCustomers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.backhaulinfo && this.backhaulinfo.MillCorpCustomerId || null,
            }),
            MillCorpInvoiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Corp Invoice',
                name: 'MillCorpInvoiceId',
                options: this.millCorpInvoices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.backhaulinfo && this.backhaulinfo.MillCorpInvoiceId || null,
            }),
            PickupTrailer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pickup Trailer',
                name: 'PickupTrailer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('PickupTrailer') && this.backhaulinfo.PickupTrailer != null ? this.backhaulinfo.PickupTrailer.toString() : '',
            }),
        };

        this.View = {
            Description: new DynamicLabel({
                label: 'Description',
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('Description') && this.backhaulinfo.Description != null ? this.backhaulinfo.Description.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DropOffTrailer: new DynamicLabel({
                label: 'Drop Off Trailer',
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('DropOffTrailer') && this.backhaulinfo.DropOffTrailer != null ? this.backhaulinfo.DropOffTrailer.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MillCorpCustomerId: new DynamicLabel({
                label: 'Mill Corp Customer',
                value: getMetaItemValue(this.millCorpCustomers as unknown as IMetaItem[], this.backhaulinfo && this.backhaulinfo.hasOwnProperty('MillCorpCustomerId') ? this.backhaulinfo.MillCorpCustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            MillCorpInvoiceId: new DynamicLabel({
                label: 'Mill Corp Invoice',
                value: getMetaItemValue(this.millCorpInvoices as unknown as IMetaItem[], this.backhaulinfo && this.backhaulinfo.hasOwnProperty('MillCorpInvoiceId') ? this.backhaulinfo.MillCorpInvoiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PickupTrailer: new DynamicLabel({
                label: 'Pickup Trailer',
                value: this.backhaulinfo && this.backhaulinfo.hasOwnProperty('PickupTrailer') && this.backhaulinfo.PickupTrailer != null ? this.backhaulinfo.PickupTrailer.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
