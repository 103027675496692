<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Skid Data Maintenance</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-12 col-lg-9">
    <form class="miles-form modern-css p-md m-b-md row" [formGroup]="form" (ngSubmit)="formSubmitted()">
        <div class="row row-col-align-middle m-b-sm">
            <div class="col-xs-8">
                <label>Skid Number</label>
                <dynamic-field
                    [field]="abstractSkidControls.FinalBarcode"
                    [form]="finalBarcodeForm"
                    [ngClass]="'input-blue'"
                    [overrideForm]="true"
                ></dynamic-field>
                <span *ngIf="skid?.SkidNumber"
                    >Skid {{ skid?.SkidNumber }} of
                    {{ skid?.OrderDetailLine?.NumberOfSkids || skid?.SalesOrder?.ManufacturingOrderDetail?.NumberOfSkids }}</span
                >
            </div>
            <div class="col-xs-4">
                <b>Order #:</b> {{ getFormattedOrderNumber(skid?.SalesOrder) }}
                <br />
            </div>
        </div>

        <div class="row row-col-align-middle m-b-sm mt-0">
            <div class="col-xs-8">
                <button class="btn btn-default btn-sm" type="button" (click)="noBarcodeTag()">No Barcode Tag</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <fieldset class="m-b-md">
                    <legend>Customer</legend>
                    <div class="row">
                        <div class="col-md-2">
                            <br />
                            <b>Ship to:</b>
                        </div>
                        <div class="col-md-10">
                            {{ skid?.SalesOrder?.OrderInfo?.Customer?.CompanyName }}
                            <br />
                            {{ skid?.SalesOrder?.OrderInfo?.Customer?.CustomerName }}
                            <br />
                            {{ customerShippingAddress?.ShipToName }}
                            <br />
                            {{ customerShippingAddress?.Address?.Address1 }}
                            <br />
                            {{ customerShippingAddress?.Address?.Address2 }}
                            <br *ngIf="customerShippingAddress?.Address?.Address2" />
                            {{ customerShippingAddress?.Address?.City }}
                            {{ customerShippingAddress?.Address?.StateCode || customerShippingAddress?.Address?.Province }}
                            {{ customerShippingAddress?.Address?.Zip }}
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3"><b>Size:</b> {{ skid?.Width }} x {{ skid?.Length }}</div>
            <div class="col-md-2"><b>Caliper:</b> {{ skid?.Caliper }}</div>
            <div class="col-md-2"><b>Count:</b> {{ skid?.Count }}</div>
            <div class="col-md-2"><b>Piles:</b> {{ skid?.Piles }}</div>
            <div class="col-md-2"><b>Ply:</b> {{ skid?.Ply }}</div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-4">
                <fieldset class="m-b-md">
                    <legend>Tare Weight</legend>
                    <dynamic-field [field]="abstractSkidControls.TareWeightBottom" [form]="form" [ngClass]="'input-yellow'"></dynamic-field>
                    <br />
                    <dynamic-field [field]="abstractSkidControls.TareWeightTop" [form]="form" [ngClass]="'input-yellow'"></dynamic-field>
                    Scale Weight: {{ scaleWeight }}
                </fieldset>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-4" [ngClass]="'input-yellow'">
                        <dynamic-field [field]="abstractSkidControls.SkidHeight" [form]="form"></dynamic-field>
                        <br />
                    </div>
                    <div class="col-md-4 col-md-offset-4" [ngClass]="'input-red'">
                        <label>Scan Sheets</label
                        ><input
                            class="scan-sheet-input"
                            name="scannedSheets"
                            type="number"
                            [(ngModel)]="scanSheets"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <br />
                        <label>Scale Sheets</label> {{ skid ? (getScaleSheets() | number: '1.2-2') : null }}
                        <br />
                        <label>Adjusted Sheets</label> {{ skid ? (getAdjustedSheets() | number: '1.2-2') : null }}
                        <br />
                        <label>Error</label> {{ skid ? (getScanCountErrorPercent() | percent: '0.2-2') : null }}
                        <br />
                        <label>Scan Bdls</label> {{ skid ? (getScanBdls() | number: '1.2-2') : null }}
                        <br />
                        <label>Actual Bundles</label> 
                        <input  class="actual-bundles-input"
                                type="number" 
                                name="actualBundles"
                                [(ngModel)]="actualBundles"
                                [ngModelOptions]="{ standalone: true }">
                        <br />
                        <label>Adjusted Bdls</label> {{ skid ? (getAdjustedBdls() | number: '1.2-2') : null }}
                        <br />
                        <label>Error</label> {{ skid ? (getScanBdlErrorPercent() | percent: '0.2-2') : null }}
                        <br />
                        <label>Actual Weight</label> {{ (actualBundles * poundsPerBundle) || scaleWeight }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-2">
                <label>&nbsp;</label>
                <br />
                <button type="button" class="btn btn-secondary btn-block font-weight-bold" (click)="weigh()" [disabled]="isWeighing">
                    {{ isWeighing ? 'WEIGHING...' : 'SCALE' }}
                </button>
            </div>
            <div class="col-md-8">
                <div class="row m-t-lg">
                    <div class="col-xs-6">
                        <button
                            type="submit"
                            [disabled]="!form.valid"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-success btn-block font-weight-bold"
                        >
                            Process Skid
                        </button>
                    </div>
                    <div class="col-xs-6">
                        <button type="button" Class="btn btn-default btn-block" (click)="cancelClick()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>



<modal-wrapper title="Add Skid" [options]="noBarcodeModalOptions" customClass="swal-wide" [autoShow]="false" (ready)="noBarcodeModal = $event">
    <div class="m-t-sm">
        <no-barcode-skid [validateOrderQty]="true" (onAddSkid)="onNoBarcodeAddSkid($event)" (onCancel)="closeNoBarcodeModal()"></no-barcode-skid>
    </div>
</modal-wrapper>
