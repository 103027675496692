import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomerShippingAddress } from '../interfaces/customer-shipping-address';
import { IAddress } from '../interfaces/address';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerShippingAddressDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    addresses?: IAddress[];
}

export class CustomerShippingAddressDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    addresses: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customershippingaddress?: ICustomerShippingAddress, additionalParameters?: ICustomerShippingAddressDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerShippingAddress';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customershippingaddress && this.customershippingaddress.AddressId || null,
            }),
            CustomerCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Code',
                name: 'CustomerCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(6) ],
                validators: { 'required': true, 'maxlength': 6 },
                value: this.customershippingaddress && this.customershippingaddress.hasOwnProperty('CustomerCode') && this.customershippingaddress.CustomerCode !== null ? this.customershippingaddress.CustomerCode.toString() : '',
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customershippingaddress && this.customershippingaddress.CustomerId || null,
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershippingaddress && this.customershippingaddress.hasOwnProperty('IsPrimary') && this.customershippingaddress.IsPrimary !== null ? this.customershippingaddress.IsPrimary : false,
            }),
            ShipToCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship To Code',
                name: 'ShipToCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(9) ],
                validators: { 'required': true, 'maxlength': 9 },
                value: this.customershippingaddress && this.customershippingaddress.hasOwnProperty('ShipToCode') && this.customershippingaddress.ShipToCode !== null ? this.customershippingaddress.ShipToCode.toString() : '',
            }),
            ShipToName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship To Name',
                name: 'ShipToName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customershippingaddress && this.customershippingaddress.hasOwnProperty('ShipToName') && this.customershippingaddress.ShipToName !== null ? this.customershippingaddress.ShipToName.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel(
                'Address',
                getMetaItemValue(this.addresses, this.customershippingaddress && this.customershippingaddress.hasOwnProperty('AddressId') && this.customershippingaddress.AddressId !== null ? this.customershippingaddress.AddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerCode: new DynamicLabel(
                'Customer Code',
                this.customershippingaddress && this.customershippingaddress.hasOwnProperty('CustomerCode') && this.customershippingaddress.CustomerCode !== null ? this.customershippingaddress.CustomerCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.customershippingaddress && this.customershippingaddress.hasOwnProperty('CustomerId') && this.customershippingaddress.CustomerId !== null ? this.customershippingaddress.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPrimary: new DynamicLabel(
                'Is Primary',
                this.customershippingaddress && this.customershippingaddress.hasOwnProperty('IsPrimary') && this.customershippingaddress.IsPrimary !== null ? this.customershippingaddress.IsPrimary : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipToCode: new DynamicLabel(
                'Ship To Code',
                this.customershippingaddress && this.customershippingaddress.hasOwnProperty('ShipToCode') && this.customershippingaddress.ShipToCode !== null ? this.customershippingaddress.ShipToCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipToName: new DynamicLabel(
                'Ship To Name',
                this.customershippingaddress && this.customershippingaddress.hasOwnProperty('ShipToName') && this.customershippingaddress.ShipToName !== null ? this.customershippingaddress.ShipToName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
