import { IOrderDetailLine } from '../interfaces/order-detail-line';
import { IOrderDetailLineDynamicControlsParameters } from '../form-controls/order-detail-line.form-controls';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { OrderDetailLineDynamicControlsPartial } from './order-detail-line.form-controls';
import { Validators } from '@angular/forms';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';

export class OrderDetailLineTrimSheetDynamicControlsPartial extends OrderDetailLineDynamicControlsPartial {
    constructor(private _orderDetailLinePartial?: IOrderDetailLine, private additionalParametersPartial?: IOrderDetailLineDynamicControlsParameters) {
        super(_orderDetailLinePartial, additionalParametersPartial);

        this.Form.Width.component = FractionsDynamicFieldComponent;
        (<DynamicField>this.Form.Width).validators = { 'maxlength': 50 };
        (<DynamicField>this.Form.Width).validation = [Validators.maxLength(50)];

        this.Form.Length.component = FractionsDynamicFieldComponent;
        (<DynamicField>this.Form.Length).validators = { 'maxlength': 50 };
        (<DynamicField>this.Form.Length).validation = [Validators.maxLength(50)];
    }
}
