import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ILiningGuillotineLog } from '../interfaces/lining-guillotine-log';
import { ISalesOrder } from '../interfaces/sales-order';

export interface ILiningGuillotineLogDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
}

export class LiningGuillotineLogDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private liningguillotinelog?: ILiningGuillotineLog, additionalParameters?: ILiningGuillotineLogDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'LiningGuillotineLog';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;

        this.Form = {
            BdlSk: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdl Sk',
                name: 'BdlSk',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.BdlSk || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.Caliper || null,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.Count || null,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('CustomerName') && this.liningguillotinelog.CustomerName !== null ? this.liningguillotinelog.CustomerName.toString() : '',
            }),
            Cutter: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cutter',
                name: 'Cutter',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Cutter') && this.liningguillotinelog.Cutter !== null ? this.liningguillotinelog.Cutter.toString() : '',
            }),
            Date: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date',
                name: 'Date',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.liningguillotinelog && this.liningguillotinelog.Date || null,
            }),
            Hours: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hours',
                name: 'Hours',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.Hours || null,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Length') && this.liningguillotinelog.Length !== null ? this.liningguillotinelog.Length.toString() : '',
            }),
            Lining: new DynamicField({
                formGroup: this.formGroup,
                label: 'Lining',
                name: 'Lining',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Lining') && this.liningguillotinelog.Lining !== null ? this.liningguillotinelog.Lining.toString() : '',
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Note') && this.liningguillotinelog.Note !== null ? this.liningguillotinelog.Note.toString() : '',
            }),
            Pieces: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pieces',
                name: 'Pieces',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.Pieces || null,
            }),
            ReportId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report',
                name: 'ReportId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.ReportId || 1,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.liningguillotinelog && this.liningguillotinelog.SalesOrderId || null,
            }),
            ShiftCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Code',
                name: 'ShiftCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('ShiftCode') && this.liningguillotinelog.ShiftCode !== null ? this.liningguillotinelog.ShiftCode.toString() : '',
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Width') && this.liningguillotinelog.Width !== null ? this.liningguillotinelog.Width.toString() : '',
            }),
        };

        this.View = {
            BdlSk: new DynamicLabel(
                'Bdl Sk',
                this.liningguillotinelog && this.liningguillotinelog.BdlSk || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.liningguillotinelog && this.liningguillotinelog.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.liningguillotinelog && this.liningguillotinelog.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CustomerName: new DynamicLabel(
                'Customer Name',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('CustomerName') && this.liningguillotinelog.CustomerName !== null ? this.liningguillotinelog.CustomerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Cutter: new DynamicLabel(
                'Cutter',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Cutter') && this.liningguillotinelog.Cutter !== null ? this.liningguillotinelog.Cutter.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Date: new DynamicLabel(
                'Date',
                this.liningguillotinelog && this.liningguillotinelog.Date || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Hours: new DynamicLabel(
                'Hours',
                this.liningguillotinelog && this.liningguillotinelog.Hours || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Length') && this.liningguillotinelog.Length !== null ? this.liningguillotinelog.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Lining: new DynamicLabel(
                'Lining',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Lining') && this.liningguillotinelog.Lining !== null ? this.liningguillotinelog.Lining.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Note: new DynamicLabel(
                'Note',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Note') && this.liningguillotinelog.Note !== null ? this.liningguillotinelog.Note.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Pieces: new DynamicLabel(
                'Pieces',
                this.liningguillotinelog && this.liningguillotinelog.Pieces || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ReportId: new DynamicLabel(
                'Report',
                this.liningguillotinelog && this.liningguillotinelog.ReportId || 1,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('SalesOrderId') && this.liningguillotinelog.SalesOrderId !== null ? this.liningguillotinelog.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShiftCode: new DynamicLabel(
                'Shift Code',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('ShiftCode') && this.liningguillotinelog.ShiftCode !== null ? this.liningguillotinelog.ShiftCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.liningguillotinelog && this.liningguillotinelog.hasOwnProperty('Width') && this.liningguillotinelog.Width !== null ? this.liningguillotinelog.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
