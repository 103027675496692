import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';

import { MillCorpInvoiceComponent } from './mill-corp-invoice/mill-corp-invoice.component';
import { MillCorpRoutingModule } from './mill-corp-routing.module';
import { MillCorpSettingsComponent } from './mill-corp-settings/mill-corp-settings.component';
import { MillCorpCustomerService } from './services/mill-corp-customer.service';

@NgModule({
    declarations: [MillCorpInvoiceComponent, MillCorpSettingsComponent],
    imports: [SharedModule, MillCorpRoutingModule],
})
export class MillCorpModule {
    static forRoot(): any {
        return {
            ngModule: MillCorpModule,
            providers: [MillCorpCustomerService],
        };
    }
}
