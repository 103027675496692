import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { RunnerDirectionTypeService } from '../runnerdirectiontype.service';
import { CustomerShipOnTypeService } from '../../customers/customershipontype.service';

import { PalletInfoDynamicControlsPartial } from '@model/partials/pallet-info.form-controls';
import { IPalletInfo } from '@model/interfaces/pallet-info';

import { RunnerDirectionTypeIds } from '@model/RunnerDirectionTypes';
import { ManufacturingOrderDetailService } from '../manufacturing-order-detail.service';
import { IManufacturingOrderDetail } from '@model/interfaces/manufacturing-order-detail';
import { PalletInfoService } from '../pallet-info.service';
import { finalize, tap } from 'rxjs/operators';
import { SalesOrderService } from '../sales-order.service';
import { PrintService } from '@common/services/print.service';
import { IPalletInfoDynamicControlsParameters } from '@model/form-controls/pallet-info.form-controls';
import { CustomerService } from '../../customers/customer.service';
import { ICustomer } from '@model/interfaces/customer';

@Component({
    selector: 'pallet-info',
    templateUrl: './pallet-info.component.html',
})
export class PalletInfoComponent implements OnInit {
    // abstract controls
    finalPalletInfoControls: any;
    convertingPalletInfoControls: any;

    salesOrderId: number;
    orderDetailLines: any[];

    manufacturingOrderDetailId: number;
    manufacturingOrderDetail: IManufacturingOrderDetail;
    customer: ICustomer;

    convertingPalletInfoId: number;
    convertingPalletInfo: IPalletInfo;
    finalPalletInfoId: number;
    finalPalletInfo: IPalletInfo;
    customerId: number;
    palletInfoForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private palletInfoService: PalletInfoService,
        private manufacturingOrderDetailService: ManufacturingOrderDetailService,
        private runnerDirectionTypeService: RunnerDirectionTypeService,
        private customerShipOnTypeService: CustomerShipOnTypeService,
        private salesOrderService: SalesOrderService,
        private customerService: CustomerService,
    ) {}

    ngOnInit(): void {
        this.salesOrderId = +this.route.snapshot.params.salesOrderId;
        this.manufacturingOrderDetailId = +this.route.snapshot.params.manufacturingOrderDetailId;
        forkJoin([
            this.manufacturingOrderDetailService.getById(this.manufacturingOrderDetailId),
            this.customerService.getCustomerForOrder(this.salesOrderId),
            this.runnerDirectionTypeService.getItems(),
            this.customerShipOnTypeService.getItems(),
        ]).subscribe(([orderDetail, customer]) => {
            this.manufacturingOrderDetail = orderDetail;
            this.customer = customer;
            this.packPalletInfo().subscribe(() => {
                this.createForm();
                this.checkRunnerDirectionType(
                    <FormGroup>this.palletInfoForm.controls.ConvertingPalletInfo,
                    this.palletInfoForm.value.ConvertingPalletInfo,
                );
                this.checkRunnerDirectionType(<FormGroup>this.palletInfoForm.controls.FinalPalletInfo, this.palletInfoForm.value.FinalPalletInfo);
                this.setInitialFocus();
            });
        });
    }

    private setInitialFocus(): void {
        (<FormGroup>this.palletInfoForm.controls.FinalPalletInfo).controls.PalletSizeWidth.mtFocus();
    }

    packPalletInfo(): Observable<any> {
        this.convertingPalletInfoId = this.manufacturingOrderDetail.ConvertingPalletInfoId || 0;
        this.finalPalletInfoId = this.manufacturingOrderDetail.FinalPalletInfoId || 0;
        this.convertingPalletInfo = this.manufacturingOrderDetail.ConvertingPalletInfo || this.palletInfoService.getEmptyPalletInfo();
        this.finalPalletInfo = this.manufacturingOrderDetail.FinalPalletInfo || this.palletInfoService.getEmptyPalletInfo();

        if (!this.manufacturingOrderDetail.ConvertingPalletInfo || !this.manufacturingOrderDetail.FinalPalletInfo) {
            return this.salesOrderService.getOrderDetailLines(this.salesOrderId).pipe(
                tap((orderDetailLines) => {
                    this.orderDetailLines = orderDetailLines;

                    if (!this.manufacturingOrderDetail.FinalPalletInfo) {
                        this.finalPalletInfo.BoardSizeWidth = this.manufacturingOrderDetail.Width;
                        this.finalPalletInfo.BoardSizeLength = this.manufacturingOrderDetail.Length;
                        this.finalPalletInfo.ShipOnTypeId = this.manufacturingOrderDetail.ShipOnTypeId;
                        this.finalPalletInfo.RunnerDirectionTypeId = this.manufacturingOrderDetail.IsShortWay
                            ? RunnerDirectionTypeIds.ShortWay
                            : RunnerDirectionTypeIds.LongWay;
                        this.finalPalletInfo.Qty = this.manufacturingOrderDetail.NumberOfSkids;
                        this.finalPalletInfo.InchesBetweenRunners = this.customer.InchesBetweenRunners;
                        this.finalPalletInfo.RunnerHeight = this.customer.RunnerHeight;
                        this.finalPalletInfo.SpecialNotes = this.customer.SpecialNotes;
                    }
                    if (!this.manufacturingOrderDetail.ConvertingPalletInfo) {
                        const firstOrderDetailLine = this.orderDetailLines[0];

                        this.convertingPalletInfo.BoardSizeWidth = firstOrderDetailLine?.Width || '';
                        this.convertingPalletInfo.BoardSizeLength = firstOrderDetailLine?.Length || '';
                        this.convertingPalletInfo.ShipOnTypeId = this.manufacturingOrderDetail.ShipOnTypeId;
                        this.convertingPalletInfo.RunnerDirectionTypeId = firstOrderDetailLine?.RunnerDirectionTypeId || null;
                        this.convertingPalletInfo.Qty = firstOrderDetailLine?.NumberOfSkids || 0;
                        this.convertingPalletInfo.InchesBetweenRunners = this.customer.InchesBetweenRunners;
                        this.convertingPalletInfo.RunnerHeight = this.customer.RunnerHeight;
                        this.convertingPalletInfo.SpecialNotes = this.customer.SpecialNotes;
                    }
                }),
            );
        }
        return of(null);
    }

    createForm(): void {
        this.getControls();
        this.palletInfoForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.finalPalletInfoControls = new PalletInfoDynamicControlsPartial(this.finalPalletInfo, {
            formGroup: 'FinalPalletInfo',
            runnerDirectionTypes: this.runnerDirectionTypeService.items,
            shipOnTypes: this.customerShipOnTypeService.items,
        } as IPalletInfoDynamicControlsParameters).Form;
        this.convertingPalletInfoControls = new PalletInfoDynamicControlsPartial(this.convertingPalletInfo, {
            formGroup: 'ConvertingPalletInfo',
            runnerDirectionTypes: this.runnerDirectionTypeService.items,
            shipOnTypes: this.customerShipOnTypeService.items,
        } as IPalletInfoDynamicControlsParameters).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ConvertingPalletInfo: this.fb.group({}),
            FinalPalletInfo: this.fb.group({}),
        });
    }

    checkRunnerDirectionType(formGroup: AbstractControl, formValues: any): void {
        let formControls = (formGroup as FormGroup).controls;

        if (formValues.RunnerDirectionTypeId === RunnerDirectionTypeIds.Junk) {
            formControls.InchesBetweenRunners.disable();
            formControls.InchesBetweenRunners.setValue('');
            formControls.RunnerHeight.disable();
            formControls.RunnerHeight.setValue('');
        } else {
            formControls.InchesBetweenRunners.enable();
            formControls.RunnerHeight.enable();
        }
    }

    printFinalPallets(): void {
        this.formSubmitted(true, true);
    }

    printConvertingPallets(): void {
        this.formSubmitted(true, false);
    }

    printPallets(isFinalPallet: boolean): void {
        if (isFinalPallet) {
            this.salesOrderService.printFinalPalletInfo(this.salesOrderId).subscribe((answer) => {
                PrintService.printPdf(answer);
            });
        } else {
            this.salesOrderService.printConvertingPalletInfo(this.salesOrderId).subscribe((answer) => {
                PrintService.printPdf(answer);
            });
        }
    }

    formSubmitted(needsFormPrint = false, isFinalPallet = false): any {
        if (this.palletInfoForm.valid) {
            // save logic here
            this.manufacturingOrderDetail.FinalPalletInfo = this.palletInfoForm.value.FinalPalletInfo;
            this.manufacturingOrderDetail.FinalPalletInfo.Id = this.finalPalletInfoId;
            this.manufacturingOrderDetail.FinalPalletInfoId = this.finalPalletInfoId;

            this.manufacturingOrderDetail.ConvertingPalletInfo = this.palletInfoForm.value.ConvertingPalletInfo;
            this.manufacturingOrderDetail.ConvertingPalletInfo.Id = this.convertingPalletInfoId;
            this.manufacturingOrderDetail.ConvertingPalletInfoId = this.convertingPalletInfoId;

            if (this.convertingPalletInfoId === 0 || this.finalPalletInfoId === 0) {
                this.palletInfoService
                    .createPalletInfo(this.manufacturingOrderDetail)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.success();
                        if (needsFormPrint) {
                            this.printPallets(isFinalPallet);
                        } else {
                            this.router.navigate(['../../'], { relativeTo: this.route });
                        }
                    });
            } else {
                forkJoin([
                    this.palletInfoService.update(this.manufacturingOrderDetail.FinalPalletInfo),
                    this.palletInfoService.update(this.manufacturingOrderDetail.ConvertingPalletInfo),
                ])
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.success();
                        if (needsFormPrint) {
                            this.printPallets(isFinalPallet);
                        } else {
                            this.router.navigate(['../../'], { relativeTo: this.route });
                        }
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.palletInfoForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Pallet Info Save Failed');
    }

    success(): void {
        this.notificationsService.success('Pallet Info Saved Successfully');
    }
}
