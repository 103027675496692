import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { ICustomer } from '../model/interfaces/customer';
import { INote } from '@mt-ng2/note-control';
import { IHasNotes } from '@mt-ng2/entity-components-notes';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IHasAddresses } from '@mt-ng2/entity-components-addresses';
import { IPhone } from '@mt-ng2/phone-control';
import { SearchParams } from '@mt-ng2/common-classes';
import { Subject } from 'rxjs';
import { IReceiptFormat } from '../model/interfaces/receipt-format';
import { catchError, switchMap } from 'rxjs/operators';
import { ICustomerPricingItem } from '../model/interfaces/customer-pricing-item';
import { ICustomerAddress } from '@model/interfaces/customer-address';
import { ModalService } from '@mt-ng2/modal-module';

@Injectable()
export class CustomerService extends BaseService<ICustomer> implements IHasNotes {
    private emptyCustomer: ICustomer = {
        ApexShippingManifest: false,
        Archived: false,
        Code: null,
        CompanyName: null,
        CreditStatusId: 0,
        CustomerName: null,
        Id: 0,
        InvoiceAddressId: 0,
        InvoiceEmail: null,
        InvoiceName: null,
        InvoiceNote: null,
        PaymentTermId: 0,
        ShipOnTypeId: 0,
        Version: null,
    };

    private emptyAddress: IAddress = {
        Address1: '',
        Address2: '',
        City: '',
        CountryCode: 'US',
        Id: 0,
        Province: '',
        StateCode: 'AL',
        Zip: '',
    };

    private emptyReceiptFormat: IReceiptFormat = {
        CustomerId: 0,
        OmitNewmanHeader: false,
        ReceiptSizeTotalId: 0,
        ShowBundles: false,
        ShowPieces: false,
        ShowSkidIds: false,
        ShowWeight: false,
    };

    constructor(public http: HttpClient, private modalService: ModalService) {
        super('/customers', http);
    }

    getEmptyCustomer(): ICustomer {
        return { ...this.emptyCustomer };
    }

    getEmptyAddress(): IAddress {
        return { ...this.emptyAddress };
    }

    getCustomerDetail(customerId: number): Observable<ICustomer> {
        return this.http.get<ICustomer>(`/customers/${customerId}/detail`);
    }

    getAddresses(CustomerId: number, searchparameters: SearchParams): Observable<HttpResponse<IAddress[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<IAddress[]>(`/customers/${CustomerId}/addresses/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getInvoiceAddress(invoiceAddressId: number): Observable<HttpResponse<IAddress>> {
        return this.http
            .get<IAddress[]>(`/customers/invoice/${invoiceAddressId}`, {
                observe: 'response',
            })
            .pipe(catchError(this.handleError));
    }

    saveInvoiceAddress(address: IAddress): Observable<number> {
        return this.http
            .put<IAddress>(`/customers/invoice`, address, {
                responseType: 'text' as 'json',
            })
            .pipe(catchError(this.handleError));
    }

    saveAddress(CustomerId: number, address: ICustomerAddress): Observable<number> {
        if (!address.AddressId) {
            address.AddressId = 0;
            return this.http.post<number>('/customers/' + CustomerId + '/addresses', address);
        } else {
            return this.modalService
                .showModal({
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    showConfirmButton: true,
                    text: 'Are you sure you want to update this Address? Doing so will update all existing orders associated with this record',
                    title: 'Update Address',
                })
                .pipe(
                    switchMap((res) => {
                        if (res.isConfirmed) {
                            return this.http.put<number>('/customers/' + CustomerId + '/addresses/' + address.AddressId, address, { responseType: 'text' as 'json' });
                        } else {
                            return EMPTY;
                        }
                    }));
                }
    }

    deleteAddress(CustomerId: number, addressId: number): Observable<object> {
        return this.http.delete('/customers/' + CustomerId + '/addresses/' + addressId, { responseType: 'text' as 'json' });
    }

    getShippingAddresses(CustomerId: number, searchparameters: SearchParams): Observable<HttpResponse<IAddress[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<IAddress[]>(`/customers/${CustomerId}/shippingAddresses/_search`, { observe: 'response', params: params })
            .pipe(catchError(this.handleError));
    }

    saveShippingAddress(CustomerId: number, shippingAddress: IAddress): Observable<number> {
        if (!shippingAddress.Id) {
            shippingAddress.Id = 0;
            return this.http.post<number>('/customers/' + CustomerId + '/shippingAddresses', shippingAddress);
        } else {
            return this.http.put<number>('/customers/' + CustomerId + '/shippingAddresses/' + shippingAddress.Id, shippingAddress, {
                responseType: 'text' as 'json',
            });
        }
    }

    deleteShippingAddress(CustomerId: number, addressId: number): Observable<object> {
        return this.http.delete('/customers/' + CustomerId + '/shippingAddresses/' + addressId, { responseType: 'text' as 'json' });
    }

    getNotes(CustomerId: number, searchparameters: SearchParams): Observable<HttpResponse<INote[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<INote[]>(`/customers/${CustomerId}/notes/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    saveNote(CustomerId: number, note: INote): Observable<number> {
        if (!note.Id) {
            note.Id = 0;
            return this.http.post<number>('/customers/' + CustomerId + '/notes', note);
        } else {
            return this.http.put<number>('/customers/' + CustomerId + '/notes', note, { responseType: 'text' as 'json' });
        }
    }

    deleteNote(CustomerId: number, noteId: number): Observable<object> {
        return this.http.delete('/customers/' + CustomerId + '/notes/' + noteId, { responseType: 'text' as 'json' });
    }

    savePhones(CustomerId: number, phones: IPhone): any {
        return this.http.put<number>(`/customers/${CustomerId}/phones`, phones);
    }

    createCustomerPayload(customer: any): Observable<number> {
        return this.http.post<number>('/customers/addPayload', customer);
    }

    getEmptyReceiptFormat(): IReceiptFormat {
        return { ...this.emptyReceiptFormat };
    }

    getReceiptFormat(customerId: number): Observable<IReceiptFormat> {
        return this.http.get<IReceiptFormat>(`/customers/${customerId}/receipt-format`);
    }

    saveReceiptFormat(customerId: number, receiptFormat: IReceiptFormat): Observable<any> {
        receiptFormat.Customer = this.getEmptyCustomer();
        return this.http.post(`/customers/${customerId}/receipt-format`, receiptFormat);
    }

    updateCustomerPricingItems(cpis: ICustomerPricingItem[]): Observable<ICustomerPricingItem[]> {
        return this.http.put<ICustomerPricingItem[]>('/customers/pricing-items/update', cpis);
    }

    getCustomerPricingItemHistory(customerId: number, pricingItemId: number): Observable<ICustomerPricingItem[]> {
        return this.http.get<ICustomerPricingItem[]>(`/customers/${customerId}/pricing-items/${pricingItemId}/history`);
    }

    setArchived(customerId: number, version: number[], archived: boolean): Observable<any> {
        return this.updatePartialVersionable(
            {
                Archived: archived,
                Id: customerId,
                Version: version,
            },
            customerId,
        );
    }

    getCustomerForOrder(salesOrderId: number): Observable<ICustomer> {
        return this.http.get<ICustomer>(`/customers/for-sales-order/${salesOrderId}`);
    }
}
