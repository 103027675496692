<div *ngIf="customPackaging && customPackaging.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-custom-packaging-basic-info [customPackaging]="customPackaging" [canEdit]="canEdit"></app-custom-packaging-basic-info>
            <br />
        </div>
    </div>
    <br />
    <a class="btn btn-default" (click)="close()">Close</a>
</div>
<div *ngIf="customPackaging && customPackaging.Id === 0">
    <div class="row">
        <div class="col-md-6">
            <app-custom-packaging-basic-info [customPackaging]="customPackaging"></app-custom-packaging-basic-info>
        </div>
    </div>
</div>
