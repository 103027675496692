import { Component, OnInit } from '@angular/core';
import { ConvertingDataEntryUserDynamicControls } from '@model/form-controls/converting-data-entry-user.form-controls';
import { ConvertingDataEntryUserService } from '../converting-data-entry-user.service';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-converting-data-settings',
    templateUrl: 'converting-data-settings.component.html',
})

export class ConvertingDataSettingsComponent implements OnInit {
    dynamicForm = new ConvertingDataEntryUserDynamicControls().Form;
    canAdd: boolean;
    canRemove: boolean;

    constructor(
        public convertingDataEntryUserService: ConvertingDataEntryUserService,
        private claimsService: ClaimsService,
    ) { }

    ngOnInit(): void {
        this.canAdd = this.claimsService.hasClaim(ClaimTypes.ConvertingData, [ClaimValues.FullAccess]);
        this.canRemove = this.canAdd;
    }
}
