import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, Subscription } from 'rxjs';
import { ISkid } from '../../model/interfaces/skid';
import { SkidDynamicControlsPartial } from '../../model/partials/skid.form-controls';
import { SkidService } from '../skid.service';
import { PrintService } from '../../common/services/print.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { Scales } from '../../model/Scales';
import { ScalesService } from '../../scales/scales.service';
import { IFormattedSalesOrder } from '../../model/interfaces/custom/formatted-sales-order';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';

@Component({
    selector: 'create-skid',
    styles: [
        `
            .scan-sheet-input, .actual-bundles-input {
                max-width: 70px;
                margin-left: 5px;
                background: #fff;
                color: #000;
            }

            .actual-bundles-input {
                max-width: 60px;
            }
        `,
    ],
    templateUrl: './create.component.html',
})
export class CreateSkidComponent implements OnInit, OnDestroy {
    // abstract controls
    abstractSkidControls: any;
    doubleClickIsDisabled = false;
    finalBarcodeForm = new FormGroup({});
    form: FormGroup;
    formCreated = false;
    newSkid: ISkid = null;
    customerShippingAddress: ICustomerShippingAddress = null;
    scaleSheets: number;
    scaleId: number;
    scaleWeight = 0;
    isWeighing = false;
    skid: ISkid = null;
    targetWeight = 100;
    tonWeight = 2000;

    get netWeight(): number {
        let value = 0;
        if (this.form && this.form.value && this.form.value.Skid.TareWeightBottom && this.form.value.Skid.TareWeightTop) {
            const skid = this.form.value.Skid as ISkid;
            value = this.scaleWeight - (skid.TareWeightBottom + skid.TareWeightTop);
        }
        return value;
    }

    // skid number parts
    salesOrderId: number;
    skidNumber: number;
    sequenceNumber: string;
    ply = 0;
    scanSheets: number;
    // Default to 50 lb bundles if no customer specification
    poundsPerBundle = 50;

    subscriptions = new Subscription();

    noBarcodeModal: IModalWrapperApi;
    noBarcodeModalOptions: IModalOptions = {
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    };

    actualBundles: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private skidService: SkidService,
        private scalesService: ScalesService,
    ) {}

    ngOnInit(): void {
        this.getScale();
        this.createForm();
        setTimeout(() => {
            let finalBarcodeControl = this.finalBarcodeForm.get('FinalBarcode');
            finalBarcodeControl.mtFocus();
            this.subscriptions.add(
                finalBarcodeControl.valueChanges.pipe(debounceTime(350)).subscribe((value) => {
                    this.findSkid(value);
                }),
            );
        }, 0);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getScale(): void {
        this.scaleId = Scales.Shipping;
    }

    findSkid(value: string): void {
        if (!value) {
            this.skid = null;
            return;
        }
        this.skidService.getByFinalBarcode(value).subscribe((answer) => {
            this.skid = answer;
            this.scanSheets = this.skid.ActualCount;
            if (this.skid.SalesOrder.OrderInfo.OrderCustomerSpecification) {
                this.poundsPerBundle = this.skid.SalesOrder.OrderInfo.OrderCustomerSpecification.IsFortyPoundBundle ? 40 : 50;
            }
            this.actualBundles = this.skid.ActualWeight / this.poundsPerBundle;
            if (answer) {
                this.initForm();
                this.initAddress();
            } else {
                this.notificationsService.warning('Skid not found');
                this.finalBarcodeForm.get('FinalBarcode').mtFocus();
            }
        });
    }

    createForm(): void {
        this.getControls();
        this.form = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractSkidControls = new SkidDynamicControlsPartial(this.skid).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Skid: this.fb.group({}),
        });
    }

    initForm(): void {
        this.form.enable();
        this.getFormControls().TareWeightBottom.mtFocus();
    }

    initAddress(): void {
        if (!this.skid || !this.skid.SalesOrder || !this.skid.SalesOrder.OrderInfo || !this.skid.SalesOrder.OrderInfo.CustomerShippingAddress) {
            this.customerShippingAddress = null;
            return;
        }
        this.customerShippingAddress = this.skid.SalesOrder.OrderInfo.CustomerShippingAddress;
    }

    formSubmitted(): void {
        if (this.form.valid) {
            let skid = this.buildSkidToSave();
            let saveSkid = () => {
                let apiPromise: Observable<any> = null;
                if (skid.Id > 0) {
                    apiPromise = this.skidService.updateNoPiles(skid);
                } else {
                    apiPromise = this.skidService.create(skid, null);
                }

                apiPromise.pipe(finalize(() => this.enableDoubleClick())).subscribe((answer) => {
                    this.skid.Id = answer;
                    this.success();
                    this.printFinalTag();
                });
            };
            saveSkid();
        }
        common.markAllFormFieldsAsTouched(this.form);
        this.enableDoubleClick();
    }

    buildSkidToSave(): ISkid {
        let vals = this.getFormValues();
        let skid: ISkid = { ...this.newSkid };
        // TODO: get skidpiles' counts
        let emptyPile = {
            Count: 1,
            Id: 0,
            SkidId: 0,
        };
        skid.SkidHeight = vals.SkidHeight;
        skid.Ply = this.ply || 1;
        skid.TareWeightTop = vals.TareWeightTop;
        skid.TareWeightBottom = vals.TareWeightBottom;
        if (!skid.SkidPiles) {
            skid.SkidPiles = [];
        }
        for (let i = skid.SkidPiles.length; i < skid.Piles; i++) {
            skid.SkidPiles.push({ ...emptyPile });
        }
        skid.ActualCount = this.scanSheets ? this.scanSheets : this.getActualCount(skid);
        skid.ActualWeight = this.actualBundles
                                ? this.actualBundles * this.poundsPerBundle
                                : this.scaleWeight;
        skid.TargetCount = this.skid.TargetCount;
        skid.TargetWeight = this.skid.TargetWeight;
        // static
        skid.ScaleId = this.scaleId;
        skid.DateProcessed = new Date();
        skid.ProcessedBy = 0;
        skid.MillShiftPersonnelId = this.skid.MillShiftPersonnelId;
        skid.OrderDetailLine = null;
        skid.SalesOrder = null;
        skid.BoardGrade = null;
        skid.Finish = null;
        skid.BacktenderBarcode = this.skid.BacktenderBarcode;
        skid.FinalBarcode = this.skid.FinalBarcode;
        skid.Piles = this.skid.Piles;
        skid.Sequence = this.skid.Sequence;
        skid.Width = this.skid.Width;
        skid.Length = this.skid.Length;
        skid.Caliper = this.skid.Caliper;
        skid.SkidNumber = this.skid.SkidNumber;
        skid.SalesOrderId = this.skid.SalesOrderId;
        skid.Id = this.skid.Id;
        skid.IsConverting = this.skid.IsConverting;
        skid.OrderDetailLineId = this.skid.OrderDetailLineId;
        skid.BoardGradeId = this.skid.BoardGradeId;
        skid.FinishId = this.skid.FinishId;
        skid.AvgCount = this.skid.AvgCount;
        return skid;
    }

    getActualCount(skid?: ISkid): number {
        if (!skid) {
            skid = this.skid;
        }
        if (this.skid.SalesOrder?.ManufacturingOrderDetail.IsDressStock) {
            return null;
        }
        if (!skid.SkidPiles) {
            skid.SkidPiles = [];
        }
        let totalCount = 0;
        skid.SkidPiles.forEach((item) => (totalCount += item.Count));
        return totalCount;
    }

    printFinalTag(): void {
        this.skidService
            .getFinalTag(this.skid.Id)
            .pipe(
                finalize(() => {
                    this.reset();
                }),
            )
            .subscribe((pdf) => {
                PrintService.printPdf(pdf);
            });
    }

    reset(): void {
        this.form.reset();
        this.finalBarcodeForm.reset();
        this.skid = null;
        this.salesOrderId = null;
        this.skidNumber = null;
        this.sequenceNumber = null;
        this.ply = 0;
        this.scaleWeight = 0;
        this.customerShippingAddress = null;
        this.actualBundles = null;
        this.finalBarcodeForm.get('FinalBarcode').mtFocus();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    getFormValues(): ISkid {
        return <ISkid>this.form.getRawValue().Skid;
    }

    getFormControls(): FormGroup['controls'] {
        return (this.form.controls.Skid as FormGroup).controls;
    }

    updateSkidHeight(): void {
        // default skidHeight to null
        let skidHeight: number = null;
        // if the skid exists, use its SkidHeight
        if (this.skid) {
            skidHeight = this.skid.SkidHeight;
        }
        let formControls = this.getFormControls();
        formControls.SkidHeight.setValue(skidHeight);
    }

    weigh(): void {
        this.isWeighing = true;
        this.scalesService
            .getWeight(this.scaleId)
            .pipe(finalize(() => (this.isWeighing = false)))
            .subscribe((weight) => {
                this.scaleWeight = Math.round(weight);
                this.actualBundles = this.scaleWeight / this.poundsPerBundle;
            });
    }

    onAddSkid(answer: ISkid): void {
        this.noBarcodeModal.close();
        this.newSkid = answer;
    }

    loadSkid(skid: ISkid): void {
        if (skid === null) {
            return;
        }
        if (skid.Id === -1) {
            this.allSkidsProcessedError();
        }
        this.skid = skid;
        this.ply = skid.Ply;
        let formValues = this.getFormValues();
        this.skid.TareWeightTop = formValues.TareWeightTop;
        this.skid.TareWeightBottom = formValues.TareWeightBottom;
        this.skid.SkidHeight = null;
        this.form.patchValue({ Skid: this.skid });
        if (this.skid.SalesOrder.OrderInfo.OrderCustomerSpecification) {
            this.poundsPerBundle = this.skid.SalesOrder.OrderInfo.OrderCustomerSpecification.IsFortyPoundBundle ? 40 : 50;
        }
        this.actualBundles = this.skid.ActualWeight / this.poundsPerBundle;
    }

    onNoBarcodeAddSkid(answer): void {
        this.closeNoBarcodeModal();
        this.loadSkid(answer);
    }

    closeNoBarcodeModal(): void {
        this.noBarcodeModal.close();
    }

    allSkidsProcessedError(): void {
        this.notificationsService.error('All Skids Processed');
    }

    error(): void {
        this.notificationsService.error('Skid Creation Failed');
    }

    success(): void {
        this.notificationsService.success('Skid Creation Successfull');
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    getScaleSheets(): number {
        return this.scaleWeight ? (this.scaleWeight / this.tonWeight) * this.poundsPerBundle * this.skid.Count : 0;
    }

    getAdjustedSheets(): number {
        return this.scanSheets - this.getScaleSheets();
    }

    getScanCountErrorPercent(): number {
        return this.scanSheets / this.getScaleSheets() - 1;
    }

    getScanBdls(): number {
        return this.scanSheets / this.skid.Count;
    }

    getActualBundles(): number {
        return this.actualBundles
                ? this.actualBundles
                : this.skid?.ActualWeight / this.poundsPerBundle;
    }

    getAdjustedBdls(): number {
        return this.getScanBdls() - this.getActualBundles();
    }

    getScanBdlErrorPercent(): number {
        return this.getScanBdls() / this.getActualBundles() - 1;
    }

    noBarcodeTag(): void {
        this.noBarcodeModal.show();
    }
}
