import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IContact } from '../model/interfaces/contact';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ContactService extends BaseService<IContact> {
    constructor(public http: HttpClient) {
        super('/customers/contacts', http);
    }

    getContactById(contactId: number): Observable<IContact> {
        return this.http.get<IContact>(`/customers/contacts/${contactId}`).pipe(catchError(this.handleError));
    }

    savePhones(customerId: number, contactId: number, phoneCollection: any): any {
        return this.http
            .put(`/customers/${customerId}/contacts/${contactId}/phones`, phoneCollection, { responseType: 'text' as 'json' })
            .pipe(catchError(this.handleError));
    }
}
