import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { ITrimSheet } from '@model/interfaces/trim-sheet';
import { Observable } from 'rxjs';
import { ITrimSheetDetail } from '@model/interfaces/trim-sheet-detail';
import { SearchParams } from '@mt-ng2/common-classes';
import { ISetupSearchParams } from '@model/interfaces/custom/setup-search-params';
import { IVwTrimSetupForecast } from '@model/interfaces/vw-trim-setup-forecast';
import { ITrimSetupConfiguration } from '@model/dto/trim-setup-configuration';
import { IRunningTrimSheetPayload } from '@model/interfaces/custom/running-trim-sheet-payload';
import { IStartTrimSheet } from '@model/interfaces/custom/start-trim-sheet';

export const emptyTrimSheet: ITrimSheet = {
    Archived: false,
    Id: 0,
    SectionHeadingId: null,
    SetupId: null,
    WeekOf: null,
};

export interface ITrimSetupForecast {
    TrimSheetId: number;
    SetupId: number;
    HoursToComplete: number;
    RunningTotalHours: number;
    Position: number;
    EstimatedCompletionTime?: Date;
    TrimSheetDetails: ITrimSheetDetail[];
}

export interface ITrimSheetReportSummary {
    TrimSheetId: number;
    SetupId: number;
    SectionHeading: string;
    GroupConfiguration: string;
    Details: ITrimSheetReportDetail[];
}

export interface ITrimSheetReportDetail {
    TrimSheetDetailId: number;
    SalesOrderId: string;
    Bundles: number;
    Sheets: number;
    Width: string;
    Length: string;
    Msf: number;
    Basis: number;
    Count: number;
    Caliper: number;
    IsPileUp: boolean;
    Note: string;
    IsCutter: boolean;
    IsPaster: boolean;
    IsDressStock: boolean;
    CustomerName: string;
}

@Injectable()
export class TrimSheetService extends BaseService<ITrimSheet> {
    private emptyTrimSheetDetail: ITrimSheetDetail = {
        Basis: 0,
        Bundles: 0,
        Caliper: 0,
        Count: 0,
        Id: 0,
        IsCutter: false,
        IsPaster: false,
        IsPileUp: null,
        IsTiedBundles: false,
        Length: '',
        Note: null,
        OrderDetailLineId: 0,
        SalesOrderId: 0,
        Sheets: 0,
        ShipOnTypeId: 0,
        TrimSheetId: 0,
        Width: '',
    };

    constructor(public http: HttpClient) {
        super('/trim-sheets', http);
    }

    create(trimSheet: ITrimSheet): Observable<number> {
        // overload to not clear our FKs
        return this.http.post<number>('/trim-sheets', trimSheet);
    }

    getEmptyTrimSheet(): ITrimSheet {
        return { ...emptyTrimSheet };
    }

    getNextSetupId(): Observable<ITrimSetupConfiguration> {
        return this.http.get<ITrimSetupConfiguration>('/trim-sheets/next-setup');
    }

    getBySetupId(setupId: number): Observable<ITrimSheet> {
        return this.http.get<ITrimSheet>(`/trim-sheets/setups/${setupId}`);
    }

    getInsertSetupConfig(setupId: number, isBackwardsNav: boolean): Observable<ITrimSetupConfiguration> {
        return this.http.get<ITrimSetupConfiguration>(`/trim-sheets/setup/${setupId}/insert/${isBackwardsNav}`);
    }

    getRunningSetup(): Observable<IRunningTrimSheetPayload> {
        return this.http.get<IRunningTrimSheetPayload>('/trim-sheets/running-setup');
    }

    startNextSetup(setupId: number): Observable<IRunningTrimSheetPayload> {
        const body: IStartTrimSheet = { CurrentSetupId: setupId };
        return this.http.put<IRunningTrimSheetPayload>('/trim-sheets/start-next-setup', body);
    }

    startTargetSetup(setupId: number, targetSetupId: number): Observable<IRunningTrimSheetPayload> {
        const body: IStartTrimSheet = { CurrentSetupId: setupId, TargetSetupId: targetSetupId };
        return this.http.put<IRunningTrimSheetPayload>('/trim-sheets/start-target-setup', body);
    }

    getFirstTrimSheet(date: string = null): Observable<ITrimSheet> {
        let params = this.appendDateParams(date);
        return this.http.get<ITrimSheet>(`/trim-sheets/first`, { params: params });
    }

    getLastTrimSheet(): Observable<ITrimSheet> {
        return this.http.get<ITrimSheet>(`/trim-sheets/last`);
    }

    private appendDateParams(date: string): HttpParams {
        let params = new HttpParams();
        if (date) {
            params = params.append('date', date);
        }
        return params;
    }

    getPending(): Observable<ITrimSheet[]> {
        return this.http.get<ITrimSheet[]>('/trim-sheets/pending');
    }

    getEmptyTrimSheetDetail(): ITrimSheetDetail {
        return { ...this.emptyTrimSheetDetail };
    }

    checkRemainingQuantity(salesOrderId: number, orderDetailLineId?: number): Observable<number> {
        let params = new HttpParams();

        params = params.append('salesOrderId', salesOrderId.toString());
        if (orderDetailLineId) {
            params = params.append('orderDetailLineId', orderDetailLineId.toString());
        }

        return this.http.get<number>('/trim-sheets/remaining-quantity', { params: params });
    }

    getTrimSheetDetail(id: number): Observable<ITrimSheetDetail> {
        return this.http.get<ITrimSheetDetail>('/trim-sheets/details/' + id);
    }

    getTrimSheetPdf(weekOf: Date): any {
        let params = new HttpParams();
        params = params.append('weekOf', weekOf.toISOString().split('T')[0]);
        return this.http.get('/trim-sheets/print', { params: params });
    }

    getPalletSkidOrderSheet(weekOf: Date): any {
        let params = new HttpParams();
        params = params.append('weekOf', weekOf.toISOString().split('T')[0]);
        return this.http.get('/trim-sheets/print/pallet-skid-order-sheet', { params: params });
    }

    getTrimSetupForecast(setupId?: number, orderNumber?: string, searchParams?: SearchParams): Observable<HttpResponse<IVwTrimSetupForecast[]>> {
        let params = new HttpParams();
        if (setupId) {
            params = params.append('setupId', setupId.toString());
        }
        if (orderNumber) {
            params = params.append('orderNumber', orderNumber);
        }
        if (searchParams) {
            if (searchParams.skip) {
                params = params.append('skip', searchParams.skip.toString());
            }
            if (searchParams.take) {
                params = params.append('take', searchParams.take.toString());
            }
        }
        return this.http.get<IVwTrimSetupForecast[]>('/trim-sheets/forecast', { observe: 'response', params: params });
    }

    getTrimSheetSummary(date: Date): Observable<ITrimSheetReportSummary[]> {
        let params = new HttpParams();
        params = params.append('date', date.toISOString().split('T')[0]);

        return this.http.get<ITrimSheetReportSummary[]>('/trim-sheets/summary', { params: params });
    }

    getNextSetup(currentSetupId: number): Observable<ITrimSheet> {
        return this.http.get<ITrimSheet>(`/trim-sheets/setup/${currentSetupId}/next`);
    }

    getPreviousSetup(currentSetupId: number): Observable<ITrimSheet> {
        return this.http.get<ITrimSheet>(`/trim-sheets/setup/${currentSetupId}/previous`);
    }

    updateSetupId(trimSheetId: number, newSetupId: number): any {
        return this.http.put(`/trim-sheets/${trimSheetId}/setup/${newSetupId}`, null);
    }

    getSetupBySearchParams(search: ISetupSearchParams): Observable<ITrimSheet[]> {
        let params = new HttpParams();
        if (search.SetupId) {
            params = params.append('setupId', search.SetupId.toString());
        }
        if (search.IncludeArchived) {
            params = params.append('includeArchived', search.IncludeArchived.toString());
        }
        if (search.OrderId) {
            params = params.append('orderId', search.OrderId.toString());
        }
        if (search.RunWeekOfStart) {
            params = params.append('runweekofstart', search.RunWeekOfStart.toString());
        }
        if (search.RunWeekOfEnd) {
            params = params.append('runweekofend', search.RunWeekOfEnd.toString());
        }
        if (search.Caliper) {
            params = params.append('caliper', search.Caliper.toString());
        }
        if (search.Length) {
            params = params.append('length', search.Length);
        }
        if (search.Width) {
            params = params.append('width', search.Width);
        }

        return this.http.get<ITrimSheet[]>('/trim-sheets/setup/_search', { params: params });
    }

    createBacktenderBarcodes(trimSheetId: number): Observable<any> {
        return this.http.post<any>(`/trim-sheets/${trimSheetId}/backtender-barcodes`, null);
    }
    deleteBacktenderBarcodes(trimSheetId: number): Observable<any> {
        return this.http.delete<any>(`/trim-sheets/${trimSheetId}/backtender-barcodes`);
    }
}
