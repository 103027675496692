import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { OrderRoutingModule } from './sales-orders-routing.module';

import { SalesOrdersSettingsComponent } from './sales-orders-settings/salesorder-settings.component';
import { OrderDetailLinesComponent } from './order-detail-lines/order-detail-lines.component';
import { SalesOrdersListComponent } from './sales-orders-list/sales-orders-list.component';
import { OrderCustomerSpecsComponent } from './order-customer-specs/order-customer-specs.component';

import { BoardGradeService } from './board-grade.service';
import { FinishService } from './finish.service';
import { GeneralLedgerService } from './general-ledger.service';
import { LiningService } from './lining.service';
import { SalesOrderAddComponent } from './sales-orders-add/sales-orders-add.component';
import { SalesOrderService } from './sales-order.service';
import { ManufacturingOrderAddComponent } from './manufacturing-order-add/manufacturing-order-add.component';
import { OrderStatusService } from './order-status.service';
import { OrderPriceTypeService } from './order-pricing-types.service';
import { UnitOfMeasuresService } from './unit-of-measures.service';
import { OrderTypeService } from './order-type.service';
import { OrderCustomerSpecsService } from './order-customer-specs.service';
import { TagSkidTypeService } from './tagskidtype.service';
import { PriceBreakdownComponent } from './price-breakdown/price-breakdown.component';
import { PricingChargePerTypeService } from './pricingchargepertype.service';
import { CustomerOrderPriceInfoService } from './customerorderpriceinfo.service';
import { StockOrderAddComponent } from './stock-order-add/stock-order-add.component';
import { PalletInfoComponent } from './pallet-info/pallet-info.component';
import { RunnerDirectionTypeService } from './runnerdirectiontype.service';
import { StockOrderInventoryComponent } from './stock-order-inventory/stock-order-inventory.component';
import { StockItemsService } from './stockitem.service';
import { OrderInfoService } from './order-info.service';
import { PalletInfoService } from './pallet-info.service';
import { ManufacturingOrderDetailService } from './manufacturing-order-detail.service';
import { OrderDetailLinesService } from './order-detail-lines.service';
import { StockColorsService } from './stock-colors.service';
import { DefectTypesService } from './defect-types.service';
import { StockItemTypesService } from './stock-item-types.service';
import { ShippingStatusComponent } from './shipping-status/shipping-status.component';
import { ShipmentModule } from '../shipments/shipment.module';
import { CustomerModule } from '../customers/customer.module';
import { BandingInstructionService } from './banding-instruction.service';
import { OrderCustomerSpecsMainComponent } from './order-customer-specs/order-customer-specs-main.component';
import { CustomerSpecsComponent } from './order-customer-specs/customer-specs.component';
import { MillComplaintModule } from '../mill-complaints/mill-complaint.module';
import { MillComplaintService } from '../mill-complaints/services/mill-complaint.service';
import { PrintService } from '../common/services/print.service';
import { WarehouseLocationService } from './warehouse-location.service';
import { CreditAuthStatusService } from './credit-auth-status.service';
import { StockInventoryComponent } from './stock-inventory/stock-inventory.component';
import { CommissionSalesPersonService } from './commission-sales-person.service';
import { ViewProductionComponent } from './view-production/view-production.component';
import { MillComplaintCategoryService } from './mill-complaint-category.service';

@NgModule({
    declarations: [
        StockInventoryComponent,
        OrderCustomerSpecsComponent,
        OrderCustomerSpecsMainComponent,
        CustomerSpecsComponent,
        SalesOrdersListComponent,
        SalesOrdersSettingsComponent,
        OrderDetailLinesComponent,
        SalesOrderAddComponent,
        ManufacturingOrderAddComponent,
        StockOrderAddComponent,
        PriceBreakdownComponent,
        PalletInfoComponent,
        StockOrderInventoryComponent,
        ShippingStatusComponent,
        ViewProductionComponent,
    ],
    exports: [OrderCustomerSpecsComponent],
    imports: [SharedModule, OrderRoutingModule, ShipmentModule, CustomerModule, MillComplaintModule],
})
export class SalesOrdersModule {
    static forRoot(): any {
        return {
            ngModule: SalesOrdersModule,
            providers: [
                BoardGradeService,
                FinishService,
                GeneralLedgerService,
                LiningService,
                SalesOrderService,
                OrderCustomerSpecsService,
                OrderStatusService,
                OrderTypeService,
                OrderPriceTypeService,
                PricingChargePerTypeService,
                TagSkidTypeService,
                UnitOfMeasuresService,
                CustomerOrderPriceInfoService,
                RunnerDirectionTypeService,
                StockItemsService,
                OrderInfoService,
                PalletInfoService,
                ManufacturingOrderDetailService,
                OrderDetailLinesService,
                StockColorsService,
                DefectTypesService,
                StockItemTypesService,
                BandingInstructionService,
                MillComplaintService,
                PrintService,
                WarehouseLocationService,
                CreditAuthStatusService,
                CommissionSalesPersonService,
                MillComplaintCategoryService,
            ],
        };
    }
}
