import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';

import { MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class ConvertingDataEntryUserService extends MetaItemService<IConvertingDataEntryUser> {
    constructor(public http: HttpClient) {
        super('ConvertingDataEntryUserService', 'Converting Data Entry User', 'ConvertingDataEntryUserIds', '/converting-data-entry-users', http);
    }
}
