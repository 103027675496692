import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOrderDetailLine } from '../interfaces/order-detail-line';
import { IRunnerDirectionType } from '../interfaces/runner-direction-type';

export interface IOrderDetailLineDynamicControlsParameters {
    formGroup?: string;
    runnerDirectionTypes?: IRunnerDirectionType[];
}

export class OrderDetailLineDynamicControls {

    formGroup: string;
    runnerDirectionTypes: IRunnerDirectionType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private orderdetailline?: IOrderDetailLine, additionalParameters?: IOrderDetailLineDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'OrderDetailLine';
        this.runnerDirectionTypes = additionalParameters && additionalParameters.runnerDirectionTypes || undefined;

        this.Form = {
            Basis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis',
                name: 'Basis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.Basis || null,
            }),
            BdlSk: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bdl Sk',
                name: 'BdlSk',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderdetailline && this.orderdetailline.BdlSk || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.Caliper || 0,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.Count || null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('Height') && this.orderdetailline.Height !== null ? this.orderdetailline.Height.toString() : '',
            }),
            IsConverting: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Converting',
                name: 'IsConverting',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('IsConverting') && this.orderdetailline.IsConverting !== null ? this.orderdetailline.IsConverting : false,
            }),
            IsCutter: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Cutter',
                name: 'IsCutter',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('IsCutter') && this.orderdetailline.IsCutter !== null ? this.orderdetailline.IsCutter : false,
            }),
            IsPaster: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Paster',
                name: 'IsPaster',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('IsPaster') && this.orderdetailline.IsPaster !== null ? this.orderdetailline.IsPaster : false,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('Length') && this.orderdetailline.Length !== null ? this.orderdetailline.Length.toString() : '',
            }),
            NumberOfSkids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Skids',
                name: 'NumberOfSkids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.NumberOfSkids || 0,
            }),
            Piles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Piles',
                name: 'Piles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderdetailline && this.orderdetailline.Piles || null,
            }),
            PileStainWhiteSide: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pile Stain White Side',
                name: 'PileStainWhiteSide',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('PileStainWhiteSide') && this.orderdetailline.PileStainWhiteSide !== null ? this.orderdetailline.PileStainWhiteSide : false,
            }),
            Ply: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ply',
                name: 'Ply',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderdetailline && this.orderdetailline.Ply || null,
            }),
            Quantity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quantity',
                name: 'Quantity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.orderdetailline && this.orderdetailline.Quantity || null,
            }),
            RuleNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rule Number',
                name: 'RuleNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.RuleNumber || null,
            }),
            RunnerDirectionTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Runner Direction Type',
                name: 'RunnerDirectionTypeId',
                options: this.runnerDirectionTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.orderdetailline && this.orderdetailline.RunnerDirectionTypeId || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.orderdetailline && this.orderdetailline.hasOwnProperty('Width') && this.orderdetailline.Width !== null ? this.orderdetailline.Width.toString() : '',
            }),
        };

        this.View = {
            Basis: new DynamicLabel(
                'Basis',
                this.orderdetailline && this.orderdetailline.Basis || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            BdlSk: new DynamicLabel(
                'Bdl Sk',
                this.orderdetailline && this.orderdetailline.BdlSk || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.orderdetailline && this.orderdetailline.Caliper || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.orderdetailline && this.orderdetailline.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('Height') && this.orderdetailline.Height !== null ? this.orderdetailline.Height.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsConverting: new DynamicLabel(
                'Is Converting',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('IsConverting') && this.orderdetailline.IsConverting !== null ? this.orderdetailline.IsConverting : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsCutter: new DynamicLabel(
                'Is Cutter',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('IsCutter') && this.orderdetailline.IsCutter !== null ? this.orderdetailline.IsCutter : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPaster: new DynamicLabel(
                'Is Paster',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('IsPaster') && this.orderdetailline.IsPaster !== null ? this.orderdetailline.IsPaster : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('Length') && this.orderdetailline.Length !== null ? this.orderdetailline.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfSkids: new DynamicLabel(
                'Number Of Skids',
                this.orderdetailline && this.orderdetailline.NumberOfSkids || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Piles: new DynamicLabel(
                'Piles',
                this.orderdetailline && this.orderdetailline.Piles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PileStainWhiteSide: new DynamicLabel(
                'Pile Stain White Side',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('PileStainWhiteSide') && this.orderdetailline.PileStainWhiteSide !== null ? this.orderdetailline.PileStainWhiteSide : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ply: new DynamicLabel(
                'Ply',
                this.orderdetailline && this.orderdetailline.Ply || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Quantity: new DynamicLabel(
                'Quantity',
                this.orderdetailline && this.orderdetailline.Quantity || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RuleNumber: new DynamicLabel(
                'Rule Number',
                this.orderdetailline && this.orderdetailline.RuleNumber || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RunnerDirectionTypeId: new DynamicLabel(
                'Runner Direction Type',
                getMetaItemValue(this.runnerDirectionTypes, this.orderdetailline && this.orderdetailline.hasOwnProperty('RunnerDirectionTypeId') && this.orderdetailline.RunnerDirectionTypeId !== null ? this.orderdetailline.RunnerDirectionTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.orderdetailline && this.orderdetailline.hasOwnProperty('Width') && this.orderdetailline.Width !== null ? this.orderdetailline.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
