<div class="row">
    <div class="col-md-7">
        <h2>
            <route-back-button></route-back-button>
            Mill Complaints: {{ salesOrderId }}
        </h2>
    </div>
</div>

<entity-list
    [entities]="millComplaints"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getMillComplaints()"
    (onItemSelected)="millComplaintSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>

<div *ngIf="canAddMillComplaint" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" (click)="addMillComplaint()">
        <span class="fa fa-plus"></span>
    </a>
</div>
