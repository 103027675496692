import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { TrimSheetRoutingModule } from './trim-sheet-routing.module';
import { TrimSetupForecastComponent } from './trim-setup-forecast/trim-setup-forecast.component';
import { TrimSheetAddComponent } from './trim-sheet-add/trim-sheet-add.component';
import { TrimSheetPrintComponent } from './trim-sheet-print/trim-sheet-print.component';
import { TrimSheetTagsComponent } from './trim-sheet-tags/trim-sheet-tags.component';
import { TrimSheetListComponent } from './trim-sheet-list/trim-sheet-list.component';
import { GroupConfigurationService } from './groupconfiguration.service';
import { SectionHeadingService } from './sectionheading.service';
import { TrimViewComponent } from './trim-view/trim-view.component';
import { TrimSheetService } from './trim-sheet.service';
import { FractionsService } from '../common/services/fractions.service';
import { TrimSheetSettingsComponent } from './trim-sheet-settings/trim-sheet-settings.component';
import { BacktenderService } from './backtender.service';

@NgModule({
    declarations: [
        TrimSheetAddComponent,
        TrimSetupForecastComponent,
        TrimViewComponent,
        TrimSheetPrintComponent,
        TrimSheetSettingsComponent,
        TrimSheetTagsComponent,
        TrimSheetListComponent,
    ],
    imports: [SharedModule, TrimSheetRoutingModule],
})
export class TrimSheetModule {
    static forRoot(): any {
        return {
            ngModule: TrimSheetModule,
            providers: [GroupConfigurationService, SectionHeadingService, TrimSheetService, FractionsService, BacktenderService],
        };
    }
}
