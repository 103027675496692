import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@mt-ng2/modal-module';

import { ICustomerPricingItem } from '../../../model/interfaces/customer-pricing-item';
import { CustomerService } from '../../customer.service';

@Component({
    selector: 'customer-pricing-history',
    templateUrl: './customer-pricing-item-history.component.html',
})
export class CustomerPricingItemHistoryComponent implements OnInit {
    pricingItemId: number;
    customerId: number;
    customerPricingItems: ICustomerPricingItem[] = [];

    constructor(private route: ActivatedRoute, private customerService: CustomerService, private modalService: ModalService) {}

    ngOnInit(): void {
        this.pricingItemId = +this.route.snapshot.paramMap.get('pricingItemId');
        this.customerId = +this.route.snapshot.parent.paramMap.get('customerId');

        this.customerService.getCustomerPricingItemHistory(this.customerId, this.pricingItemId).subscribe((answer) => {
            this.customerPricingItems = answer.sort((a, b) => {
                return new Date(a.DateEffective) < new Date(b.DateEffective) ? 1 : -1;
            });
        });
    }

    viewNote(notes: string): void {
        this.modalService.showModal({ text: notes });
    }
}
