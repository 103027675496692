import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { ShipmentService } from '../shipment.service';

import { ShipmentDynamicControlsPartial } from '@model/partials/shipment-partial.form-controls';

import { IShipmentDetail } from '@model/interfaces/shipment-detail';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { ICustomer } from '@model/interfaces/customer';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';

import { IShipment } from '@model/interfaces/shipment';
import { finalize, switchMap } from 'rxjs/operators';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { IOrderPoMapping } from '../update-po-numbers/update-po-numbers.component';
import { CustomerCreditStatuses } from '@model/enums/customer-credit-statuses.enum';
import { CreditAuthorizationStatuses } from '@model/enums/credit-authorization-statuses.enum';
import { CustomValidators, SortDirection } from '@common/custom-validators';
import { of } from 'rxjs';
import { IShipmentStop } from '@model/interfaces/shipment-stop';
import { OrderTypeIds } from '@model/OrderTypes';

interface IStopCustomer {
    Addresses: ICustomerShippingAddress[];
    Customer: ICustomer;
}

@Component({
    selector: 'shipment-detail',
    styles: [
        `
            .error {
                border: 1px solid red;
            }
            .errorText {
                color: #dd4b39;
                font-size: 11px;
            }
        `,
    ],
    templateUrl: './shipment-detail.component.html',
})
export class ShipmentDetailComponent implements OnInit {
    @ViewChild('firstInput') firstInput: ElementRef;

    takeFromOrderModal: IModalWrapperApi;
    takeFromModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        customClass: {
            popup: 'swal-wide',
        },
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
    };

    updatePoModal: IModalWrapperApi;
    updatePoModalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        width: '50%',
    };

    doubleClickIsDisabled = false;
    defaultNumberOfInputs = 20;
    inputsToAdd = 5;

    shipment: IShipment;
    shipmentForm: FormGroup;
    insertForm: FormGroup;
    abstractShipmentControls: any;

    takeFromOrdersAndQuantities: FormArray;

    insertSalesOrder: ISalesOrder;
    salesOrders: { [key: string]: ISalesOrder } = {};
    stopCustomers: { [key: string]: IStopCustomer } = {};
    orderPoMapping: IOrderPoMapping[];

    selectedIndex: number;
    selectedShipmentDetail: IShipmentDetail;

    get isNewShipment(): boolean {
        return this.shipment.Id === 0;
    }

    get shipmentDetails(): FormArray {
        return this.shipmentForm.controls.ShipmentDetails as FormArray;
    }

    get shipmentStops(): FormArray {
        return this.shipmentForm.controls.ShipmentStops as FormArray;
    }

    readonly MAX_NUM_TAKE_FROM_ORDERS = 5;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private salesOrderService: SalesOrderService,
        private shipmentService: ShipmentService,
        private modalService: ModalService,
    ) {
        this.shipment = this.route.snapshot.data.shipment;
    }

    ngOnInit(): void {
        this.abstractShipmentControls = new ShipmentDynamicControlsPartial(this.shipment).Form;

        this.shipmentForm = this.fb.group({
            Shipment: this.fb.group({}),
            ShipmentDetails: this.fb.array([], [CustomValidators.AtLeastOneValue('SalesOrderId')]),
            ShipmentStops: this.fb.array([], [CustomValidators.AtLeastOneValue('Stop')]),
        });

        this.insertForm = this.fb.group(
            {
                NumberOfSkids: this.fb.control(null),
                Position: this.fb.control(null),
                SalesOrderId: this.fb.control(null),
                Stop: this.fb.control(null),
            },
            { validators: [CustomValidators.AllOrNone] },
        );

        if (!this.isNewShipment) {
            this.shipment.ShipmentDetails.sort((a, b) => (a.Stop === b.Stop ? a.Row - b.Row : b.Stop - a.Stop));
            this.shipment.ShipmentDetails.forEach((sd) => (this.salesOrders[sd.SalesOrderId] = sd.SalesOrder));
            this.addDetailLines(this.shipment.ShipmentDetails.length, true);
            this.shipment.ShipmentDetails.forEach((sd, i) => {
                if (sd.TakeFromDetails.length) {
                    const detail = this.shipmentDetails.at(i) as FormGroup;
                    detail.addControl('TakeFromDetails', this.getEmptyTakeFromOrderFormArray(sd.TakeFromDetails.length));
                }
            });
            this.shipmentForm.reset(this.shipment);
            this.updateShippingStops();
        } else {
            const multiplier = Math.ceil(this.defaultNumberOfInputs / this.inputsToAdd);
            this.addDetailLines(multiplier);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.firstInput) {
                this.firstInput.nativeElement.focus();
            }
        }, 0);
    }

    getEmptyTakeFromOrderFormArray(numberOfOrders = 1): FormArray {
        let takeFromOrderAndQuantityFormArray = this.fb.array([]);
        for (let i = 0; i < numberOfOrders; i++) {
            takeFromOrderAndQuantityFormArray.push(this.getEmptyTakeFromOrderLine());
        }
        return takeFromOrderAndQuantityFormArray;
    }

    getEmptyTakeFromOrderLine(): FormGroup {
        return this.fb.group({
            Id: this.fb.control(0),
            Quantity: this.fb.control(null),
            TakeFromSalesOrderId: this.fb.control(null),
        });
    }

    getHeaderText(): string {
        return this.isNewShipment ? 'Create Load' : 'Edit Load';
    }

    addDetailLines(multiplier = 1, absoluteQty = false): void {
        const quantity = multiplier * (absoluteQty ? 1 : this.inputsToAdd);
        for (let i = 0; i < quantity; i++) {
            this.shipmentDetails.push(this.getEmptyDetailLine());
        }
    }

    getEmptyDetailLine(): FormGroup {
        return this.fb.group(
            {
                Id: this.fb.control({ value: 0, disabled: true }), // Used to track Id of existing details, disabled to avoid validation
                NumberOfSkids: this.fb.control(null),
                SalesOrderId: this.fb.control(null),
                Stop: this.fb.control(null),
            },
            {
                validators: [CustomValidators.AllOrNone, CustomValidators.FollowsSequence(this.shipmentDetails, 'Stop', true, SortDirection.Desc)],
            },
        );
    }

    removeDetailLine(index: number): void {
        this.shipmentDetails.removeAt(index);
        this.updateShippingStops();
    }

    loadOrder(index: number, forInsert = false): void {
        const formGroup = forInsert ? this.insertForm : this.shipmentDetails.controls[index];
        let orderNumber = formGroup.value.SalesOrderId;
        if (!orderNumber) {
            return;
        }

        this.salesOrderService
            .getByOrderNumber(orderNumber, false)
            .pipe(
                switchMap((salesOrder: ISalesOrder) => {
                    if (!salesOrder) {
                        this.notificationsService.warning(`Order number ${orderNumber} not found.`, { timeOut: 10000 });
                        if (!forInsert) {
                            this.removeDetailLine(index);
                        }
                        return of();
                    } else if (
                        salesOrder.OrderInfo.Customer.CreditStatusId === CustomerCreditStatuses.CreditHold &&
                        salesOrder.CreditAuthStatusId !== CreditAuthorizationStatuses.Load &&
                        salesOrder.CreditAuthStatusId !== CreditAuthorizationStatuses.Both
                    ) {
                        this.notificationsService.error(
                            'This customer is on credit hold and this order is not authorized.',
                            `CREDIT HOLD - ORDER #${orderNumber}`,
                            { timeOut: 10000 },
                        );
                        if (!forInsert) {
                            this.removeDetailLine(index);
                        }
                        return of();
                    } else {
                        const salesOrderChanged = forInsert
                            ? this.insertSalesOrder?.Id !== salesOrder.Id
                            : !this.salesOrders[orderNumber];

                        if (forInsert) {
                            this.insertSalesOrder = salesOrder;
                        } else {
                            this.salesOrders[orderNumber] = salesOrder;
                        }

                        const shouldGetSkids = salesOrderChanged || !formGroup.get('NumberOfSkids')?.value;
                        return shouldGetSkids
                                ? this.salesOrderService.getNumberOfSkidsAvailable(salesOrder.Id)
                                : of();
                    }
                }),
            )
            .subscribe((numSkidsAvailable: number) => {
                if (numSkidsAvailable) {
                    formGroup.patchValue({
                        NumberOfSkids: numSkidsAvailable,
                    });
                }
                if (!forInsert) {
                    this.updateShippingStops();
                }
            });
    }

    updateShippingStops(): void {
        this.shipmentStops.value.forEach((ss: IShipmentStop) => {
            if (!this.shipmentDetails.value.some((sd: IShipmentDetail) => ss.Stop === sd.Stop)) {
                this.removeStop(ss.Stop);
            }
        });

        for (let detailGroup of this.shipmentDetails.controls as FormGroup[]) {
            const detail = detailGroup.value as IShipmentDetail;

            if (!detail.SalesOrderId || !detail.NumberOfSkids || !detail.Stop) {
                continue;
            }

            const salesOrder = this.salesOrders[detail.SalesOrderId];
            const customer = salesOrder.OrderInfo.Customer;
            let existingStop = this.shipmentStops.controls.find((ss: FormGroup) => ss.value.Stop === detail.Stop);
            const stopIsShared =
                existingStop != null &&
                this.shipmentDetails.value.some((sd: IShipmentDetail) => sd.Stop === detail.Stop && sd.SalesOrderId !== salesOrder.Id);
            const mismatchedCustomer = existingStop != null && this.stopCustomers[detail.Stop]?.Customer.Id !== salesOrder.OrderInfo.CustomerId;

            if (!stopIsShared && mismatchedCustomer) {
                this.removeStop(detail.Stop);
                existingStop = null;
            }

            if (!existingStop) {
                const stopGroup = this.fb.group(
                    {
                        CustomerShippingAddressId: this.fb.control(salesOrder.OrderInfo.CustomerShippingAddressId, [Validators.required]),
                        Stop: this.fb.control(detail.Stop),
                    },
                    { validators: [CustomValidators.NoDuplicateControlValues(this.shipmentStops, 'CustomerShippingAddressId')] },
                );

                this.stopCustomers[detail.Stop] = { Customer: customer, Addresses: customer.CustomerShippingAddresses };
                this.shipmentStops.push(stopGroup);
            }

            if (stopIsShared && mismatchedCustomer) {
                this.notificationsService.warning(
                    `Different customers are set for stop number ${detail.Stop}. Only one customer is allowed per stop.`,
                );
                detailGroup.setErrors({ mismatchedCustomer: true });
            } else {
                detailGroup.setErrors({ mismatchedCustomer: null });
                detailGroup.updateValueAndValidity(); // Ensures the error is removed from the group
            }
        }

        this.shipmentStops.controls.sort((a: FormGroup, b: FormGroup) => b.value.Stop - a.value.Stop);
        this.updateOrderPoMapping();
    }

    removeStop(stop: number): void {
        const index = this.shipmentStops.controls.findIndex((c) => c.value.Stop === stop);
        this.shipmentStops.removeAt(index);
        delete this.stopCustomers[stop];
        this.updateValidation(this.shipmentStops);
    }

    takeFromOrder(index: number): void {
        this.selectedIndex = index;
        this.selectedShipmentDetail = this.shipmentDetails.value[index];
        if (!this.selectedShipmentDetail) {
            return;
        }
        this.takeFromOrderModal.show();
    }

    closeTakeFromSkids(): void {
        this.selectedShipmentDetail = null;
        this.takeFromOrderModal.close();
    }

    updateTakeFromSkids(ordersAndQuantities: FormArray): void {
        const shipmentDetail = this.shipmentDetails.controls[this.selectedIndex] as FormGroup;

        this.salesOrderService
            .getNumberOfSkidsAvailableToShip(shipmentDetail.value.SalesOrderId as number)
            .subscribe((availableSkidsForOrder) => {
                let skidsToTake = (shipmentDetail.value.NumberOfSkids as number) - availableSkidsForOrder;
                const total: number = ordersAndQuantities.value.map((c) => c.Quantity).reduce((q, currentValue) => q + currentValue, 0);
                if (ordersAndQuantities.value.length === 0) {
                    shipmentDetail.removeControl('TakeFromDetails');
                } else if (total >= skidsToTake) {
                    shipmentDetail.setControl('TakeFromDetails', ordersAndQuantities);
                    const salesOrderNumbersAsString = ordersAndQuantities.value.map((c) => c.TakeFromSalesOrderId).join(', ');
                    this.notificationsService.success(`Successfully took ${skidsToTake} skids from order(s) ${salesOrderNumbersAsString}`);
                } else {
                    this.notificationsService.error('The specified order(s) do not have enough skids available.');
                }
                this.closeTakeFromSkids();
                this.updateShippingStops();
            });

    }

    startFocusShift(index: number): void {
        if (index + 1 === this.shipmentDetails.length) {
            this.addDetailLines();
            this.shiftFocus(index);
        } else {
            this.shiftFocus(index);
        }
    }

    shiftFocus(index: number, field = null): void {
        let focusId = index + 1;
        field = document.getElementById(`shipmentDetail-${focusId}`);
        if (!field) {
            return;
        }
        field.focus();
    }

    buildShipmentModelForSave(): IShipment {
        const shipment = { ...this.shipment };

        let formValues = this.shipmentForm.getRawValue(); // Include disabled fields so we have Ids
        Object.assign(shipment, formValues.Shipment);

        shipment.ShipmentDetails = formValues.ShipmentDetails.filter((item) => item.SalesOrderId && item.NumberOfSkids && item.Stop).map(
            (item, i) => {
                const detail = shipment.ShipmentDetails?.find((sd) => sd.Id === item.Id) ?? this.shipmentService.getEmptyShipmentDetail();

                // filter empty TakeFromOrders
                item.TakeFromDetails = item.TakeFromDetails?.filter((tfd) => tfd.Quantity !== null && tfd.TakeFromSalesOrderId !== null) ?? [];

                detail.Row = i + 1;
                Object.assign(detail, item);
                return detail;
            },
        );

        shipment.ShipmentStops = formValues.ShipmentStops.map((item) => {
            const stop = shipment.ShipmentStops?.find((ss) => ss.Stop === item.Stop) ?? this.shipmentService.getEmptyShipmentStop();
            return Object.assign(stop, item);
        });

        return shipment;
    }

    formSubmitted(): void {
        if (this.shipmentForm.valid) {
            this.modalService
                .showModal({
                    showCancelButton: true,
                    text: `Are you sure you want to ${this.isNewShipment ? 'create' : 'update'} this load?`,
                    title: `Confirm Load ${this.isNewShipment ? 'Creation' : 'Update'}`,
                    type: 'warning',
                })
                .subscribe((result) => {
                    if (!result.value) {
                        this.enableDoubleClick();
                        return;
                    }
                    const save = this.isNewShipment
                        ? this.shipmentService.createWithFks(this.buildShipmentModelForSave())
                        : this.shipmentService.updateWithFks(this.buildShipmentModelForSave());
                    save.pipe(finalize(() => this.enableDoubleClick())).subscribe((answer) => {
                        this.notificationsService.success(`Successfully ${this.isNewShipment ? 'created' : 'updated'} Load #${answer}`);
                        this.router.navigate(['/shipments/list']);
                    });
                });
        } else {
            if (this.shipmentDetails.controls.some((c) => c.errors?.nonSequential)) {
                this.notificationsService.warning('Please make sure all stops are in sequence.');
            } else if (this.shipmentStops.controls.some((fg: FormGroup) => fg.errors?.notUnique)) {
                this.notificationsService.warning('Please make sure all stops have unique addresses.');
            } else {
                this.notificationsService.warning('Please make sure all required fields are filled in and try again.');
            }
            common.markAllFormFieldsAsTouched(this.shipmentForm);
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    success(): void {
        this.notificationsService.success('ShipmentDetail saved successfully.');
    }

    clearInsert(): void {
        this.insertForm.reset();
        this.insertSalesOrder = null;
    }

    saveInsert(): void {
        const insertPosition = this.insertForm.value.Position - 1;
        const insertStop = this.insertForm.value.Stop;
        const previousStop = insertPosition === 0 ? null : this.shipmentDetails.controls[insertPosition - 1].value.Stop;
        if (previousStop && previousStop < insertStop) {
            this.notificationsService.error('Stop # is out of sequence.');
        } else if (this.stopCustomers[insertStop] && this.stopCustomers[insertStop].Customer.Id !== this.insertSalesOrder.OrderInfo.CustomerId) {
            this.notificationsService.error('Only one customer is allowed per stop.');
        } else {
            const newDetail = this.getEmptyDetailLine();
            const existingDetail = this.shipment.ShipmentDetails.find((sd) => sd.SalesOrderId === this.insertForm.value.SalesOrderId);
            newDetail.patchValue({ ...this.insertForm.value, Id: existingDetail?.Id ?? 0 });
            this.shipmentDetails.insert(insertPosition, newDetail);
            this.salesOrders[this.insertSalesOrder.Id] = this.insertSalesOrder;
            this.updateShippingStops();
            this.clearInsert();
        }
    }

    updateOrderPoMapping(): void {
        this.orderPoMapping = this.shipmentDetails.value
            .filter((sd) => sd.SalesOrderId)
            .map((sd) => ({
                orderNumber: sd.SalesOrderId,
                poNumber: this.salesOrders[sd.SalesOrderId].OrderInfo.CustomerPurchaseOrderNumber,
            }));
    }

    showUpdatePoModal(): void {
        if (this.updatePoModal) {
            this.updatePoModal.show();
        }
    }

    closeUpdatePoModal(): void {
        this.updatePoModal.close();
    }

    onPoNumbersUpdated(event: IOrderPoMapping[]): void {
        event.forEach((updated) => {
            this.salesOrders[updated.orderNumber].OrderInfo.CustomerPurchaseOrderNumber = updated.poNumber;
        });
        this.updatePoModal.close();
        this.updateOrderPoMapping();
    }

    detailControlHasTakeFromDetails(shipmentDetailGroup: FormGroup): boolean {
        const detail: IShipmentDetail = shipmentDetailGroup.value;
        return detail.TakeFromDetails?.length > 0 ?? false;
    }

    updateValidation(formArray: FormArray): void {
        formArray.controls.forEach((c) => c.updateValueAndValidity());
    }
}
