import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IMillCorpCustomer } from '../interfaces/mill-corp-customer';

export interface IMillCorpCustomerDynamicControlsParameters {
    formGroup?: string;
}

// tslint:disable:object-literal-sort-keys
export class MillCorpCustomerDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private millcorpcustomer?: IMillCorpCustomer, additionalParameters?: IMillCorpCustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'MillCorpCustomer';

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.millcorpcustomer && this.millcorpcustomer.hasOwnProperty('Name') && this.millcorpcustomer.Name != null ? this.millcorpcustomer.Name.toString() : '',
            }),
        };

        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.millcorpcustomer && this.millcorpcustomer.hasOwnProperty('Name') && this.millcorpcustomer.Name != null ? this.millcorpcustomer.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
