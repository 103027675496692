import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class CustomPackagingsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Width',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Length',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Height',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Caliper',
                    pipes: ['number:1.3-3'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (customPackaging: ICustomPackaging) => customPackaging.BoardGrade?.Name ?? '',
                    name: 'Board Grade',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['MustCount'],
                    name: 'Must Count',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Plus',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Minus',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
