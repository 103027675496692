import { BoardGradeDynamicControls, IBoardGradeDynamicControlsParameters } from '../form-controls/board-grade.form-controls';
import { IBoardGrade } from '../interfaces/board-grade';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { IBoardGradeDynamicControlsParametersPartial } from './board-grade-dynamic-controls-parameters';

export class BoardGradeDynamicControlsPartial extends BoardGradeDynamicControls {
    constructor(boardgrade?: IBoardGrade, additionalParameters?: IBoardGradeDynamicControlsParametersPartial) {
        super(boardgrade, additionalParameters);

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                validation: [Validators.required, Validators.maxLength(250)],
                validators: { required: true, maxlength: 250 },
                value: boardgrade && boardgrade.hasOwnProperty('Name') && boardgrade.Name !== null ? boardgrade.Name.toString() : '',
            }),
            QbInventoryName: new DynamicField({
                formGroup: this.formGroup,
                label: 'QB Inventory Name',
                name: 'QbInventoryName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { required: true, 'maxlength': 250 },
                value: boardgrade && boardgrade.hasOwnProperty('QbInventoryName') && boardgrade.QbInventoryName !== null ? boardgrade.QbInventoryName.toString() : '',
            }),
            // tslint:disable-next-line:object-literal-sort-keys
            Abbreviation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Abbreviation',
                name: 'Abbreviation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                validation: [Validators.maxLength(50)],
                validators: { maxlength: 50 },
                value:
                    boardgrade && boardgrade.hasOwnProperty('Abbreviation') && boardgrade.Abbreviation !== null
                        ? boardgrade.Abbreviation.toString()
                        : '',
            }),
            TrimName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trim Name',
                name: 'TrimName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                validation: [Validators.maxLength(250)],
                validators: { maxlength: 250 },
                value: boardgrade && boardgrade.hasOwnProperty('TrimName') && boardgrade.TrimName !== null ? boardgrade.TrimName.toString() : '',
            }),
        };
    }
}
