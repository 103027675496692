import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IMillShiftPersonnel } from '../interfaces/mill-shift-personnel';
import { IMillShift } from '../interfaces/mill-shift';

export interface IMillShiftPersonnelDynamicControlsParameters {
    formGroup?: string;
    millShifts?: IMillShift[];
}

export class MillShiftPersonnelDynamicControls {

    formGroup: string;
    millShifts?: IMillShift[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private millshiftpersonnel?: IMillShiftPersonnel, additionalParameters?: IMillShiftPersonnelDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'MillShiftPersonnel';
        this.millShifts = additionalParameters && additionalParameters.millShifts || undefined;

        this.Form = {
            Backtender: new DynamicField({
                formGroup: this.formGroup,
                label: 'Backtender',
                name: 'Backtender',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(40) ],
                validators: { 'maxlength': 40 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Backtender') && this.millshiftpersonnel.Backtender != null ? this.millshiftpersonnel.Backtender.toString() : '',
            }),
            Bander1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bander1',
                name: 'Bander1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander1') && this.millshiftpersonnel.Bander1 != null ? this.millshiftpersonnel.Bander1.toString() : '',
            }),
            Bander2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bander2',
                name: 'Bander2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander2') && this.millshiftpersonnel.Bander2 != null ? this.millshiftpersonnel.Bander2.toString() : '',
            }),
            Bander3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bander3',
                name: 'Bander3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander3') && this.millshiftpersonnel.Bander3 != null ? this.millshiftpersonnel.Bander3.toString() : '',
            }),
            Bander4: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bander4',
                name: 'Bander4',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander4') && this.millshiftpersonnel.Bander4 != null ? this.millshiftpersonnel.Bander4.toString() : '',
            }),
            Finisher1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher1',
                name: 'Finisher1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8) ],
                validators: { 'maxlength': 8 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher1') && this.millshiftpersonnel.Finisher1 != null ? this.millshiftpersonnel.Finisher1.toString() : '',
            }),
            Finisher2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher2',
                name: 'Finisher2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8) ],
                validators: { 'maxlength': 8 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher2') && this.millshiftpersonnel.Finisher2 != null ? this.millshiftpersonnel.Finisher2.toString() : '',
            }),
            Finisher3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher3',
                name: 'Finisher3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8) ],
                validators: { 'maxlength': 8 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher3') && this.millshiftpersonnel.Finisher3 != null ? this.millshiftpersonnel.Finisher3.toString() : '',
            }),
            Finisher4: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher4',
                name: 'Finisher4',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8) ],
                validators: { 'maxlength': 8 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher4') && this.millshiftpersonnel.Finisher4 != null ? this.millshiftpersonnel.Finisher4.toString() : '',
            }),
            Finisher5: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finisher5',
                name: 'Finisher5',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8) ],
                validators: { 'maxlength': 8 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher5') && this.millshiftpersonnel.Finisher5 != null ? this.millshiftpersonnel.Finisher5.toString() : '',
            }),
            MillShiftId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Shift',
                name: 'MillShiftId',
                options: this.millShifts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.millshiftpersonnel && this.millshiftpersonnel.MillShiftId || null,
            }),
            Scanner: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scanner',
                name: 'Scanner',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Scanner') && this.millshiftpersonnel.Scanner != null ? this.millshiftpersonnel.Scanner.toString() : '',
            }),
            ShiftStart: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Start',
                name: 'ShiftStart',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millshiftpersonnel && this.millshiftpersonnel.ShiftStart || null,
            }),
            TourBoss: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tour Boss',
                name: 'TourBoss',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(40) ],
                validators: { 'maxlength': 40 },
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('TourBoss') && this.millshiftpersonnel.TourBoss != null ? this.millshiftpersonnel.TourBoss.toString() : '',
            }),
        };

        this.View = {
            Backtender: new DynamicLabel({
                label: 'Backtender',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Backtender') && this.millshiftpersonnel.Backtender != null ? this.millshiftpersonnel.Backtender.toString() : '',
            }),
            Bander1: new DynamicLabel({
                label: 'Bander1',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander1') && this.millshiftpersonnel.Bander1 != null ? this.millshiftpersonnel.Bander1.toString() : '',
            }),
            Bander2: new DynamicLabel({
                label: 'Bander2',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander2') && this.millshiftpersonnel.Bander2 != null ? this.millshiftpersonnel.Bander2.toString() : '',
            }),
            Bander3: new DynamicLabel({
                label: 'Bander3',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander3') && this.millshiftpersonnel.Bander3 != null ? this.millshiftpersonnel.Bander3.toString() : '',
            }),
            Bander4: new DynamicLabel({
                label: 'Bander4',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Bander4') && this.millshiftpersonnel.Bander4 != null ? this.millshiftpersonnel.Bander4.toString() : '',
            }),
            Finisher1: new DynamicLabel({
                label: 'Finisher1',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher1') && this.millshiftpersonnel.Finisher1 != null ? this.millshiftpersonnel.Finisher1.toString() : '',
            }),
            Finisher2: new DynamicLabel({
                label: 'Finisher2',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher2') && this.millshiftpersonnel.Finisher2 != null ? this.millshiftpersonnel.Finisher2.toString() : '',
            }),
            Finisher3: new DynamicLabel({
                label: 'Finisher3',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher3') && this.millshiftpersonnel.Finisher3 != null ? this.millshiftpersonnel.Finisher3.toString() : '',
            }),
            Finisher4: new DynamicLabel({
                label: 'Finisher4',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher4') && this.millshiftpersonnel.Finisher4 != null ? this.millshiftpersonnel.Finisher4.toString() : '',
            }),
            Finisher5: new DynamicLabel({
                label: 'Finisher5',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Finisher5') && this.millshiftpersonnel.Finisher5 != null ? this.millshiftpersonnel.Finisher5.toString() : '',
            }),
            MillShiftId: new DynamicLabel({
                label: 'Mill Shift',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.millShifts as unknown as IMetaItem[], this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('MillShiftId') ? this.millshiftpersonnel.MillShiftId : null) ?? '',
            }),
            Scanner: new DynamicLabel({
                label: 'Scanner',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('Scanner') && this.millshiftpersonnel.Scanner != null ? this.millshiftpersonnel.Scanner.toString() : '',
            }),
            ShiftStart: new DynamicLabel({
                label: 'Shift Start',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.ShiftStart || null,
            }),
            TourBoss: new DynamicLabel({
                label: 'Tour Boss',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.millshiftpersonnel && this.millshiftpersonnel.hasOwnProperty('TourBoss') && this.millshiftpersonnel.TourBoss != null ? this.millshiftpersonnel.TourBoss.toString() : '',
            }),
        };

    }
}
