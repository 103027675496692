import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IRotarySlitDivisionLog } from '@model/interfaces/rotary-slit-division-log';
import { BulkByDateService } from '@common/services/bulk-by-date.service';
import { Observable } from 'rxjs';

export const emptyRotarySlitDivisionLog: IRotarySlitDivisionLog = {
    Break: false,
    Bundles: null,
    Caliper: null,
    Count: null,
    CustomerName: '',
    Date: null,
    Id: 0,
    LastSkid: null,
    Length: '',
    MinBundles: null,
    Operator1: '',
    Operator2: '',
    Remarks: false,
    ReportId: 1,
    RotaryTypeId: null,
    SalesOrderId: 0,
    SetupTime: '',
    SheetsPerSkid: null,
    ShiftCode: '',
    Skids: null,
    TimeOff: null,
    TimeOn: null,
    TotalSheets: null,
    Width: '',
};

@Injectable()
export class RotarySlitDivisionLogService extends BulkByDateService<IRotarySlitDivisionLog> {
    constructor(public http: HttpClient) {
        super('/rotary-slit-division-logs', http);
    }

    getEmptyRotarySlitDivisionLog(): IRotarySlitDivisionLog {
        return { ...emptyRotarySlitDivisionLog };
    }

    deleteAllByDate(date: Date): Observable<void> {
        const params = new HttpParams().set('date', date.toISOString().split('T')[0]);
        return this.http.delete<void>('/rotary-slit-division-logs/', { params });
    }
}
