import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { ITrailer } from '../../model/interfaces/trailer';
import { TrailerService } from '../trailer.service';
import { IHauler } from '../../model/interfaces/hauler';
import { HaulerService } from '../hauler.service';
import { Form, NgForm } from '@angular/forms';

export interface IAssignTrailerOutput {
    HaulerId: number;
    TrailerId: number;
}

@Component({
    selector: 'assign-trailer',
    templateUrl: './assign-trailer.component.html',
})
export class AssignTrailerComponent implements OnInit {
    @Input('shipmentId') shipmentId: number;
    @Input('haulerId') haulerId: number;
    @Input('trailerId') trailerId: number;

    @Input('assign') assign: (shipmentId: number, trailerId: number, haulerId: number, finalizeCallback: () => void) => void;

    trailers: ITrailer[];
    haulers: IHauler[];

    isSubmitting = false;

    constructor(
        private trailerService: TrailerService,
        private haulerService: HaulerService,
    ) {}

    ngOnInit(): void {
        this.bindData();
    }

    bindData(): void {
        this.trailerService.getAll().subscribe((answer) => {
            this.trailers = answer;
        });
        this.haulerService.getItems().subscribe((answer) => {
            this.haulers = answer;
        });
    }

    submit(f: NgForm): void {
        if (!f.form.valid) {
            return;
        }

        this.isSubmitting = true;
        this.assign(this.shipmentId, this.trailerId, this.haulerId, this.enableDoubleClick.bind(this));
    }

    enableDoubleClick(): void {
        this.isSubmitting = false;
    }
}
