import { Component, Injector, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ISelectionChangedEvent, ITypeAheadAPI } from '@mt-ng2/type-ahead-control';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';
import { Subscription } from 'rxjs';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

/**
 * This is a custom TypeAhead component completely based off of the Miles TypeAhead. The only difference is that this one
 * displays all options by default, and we do not pass the global config. We implemented this to allow
 * a scrolling list of all typeahead results.
 */
@Component({
    selector: 'scrollable-type-ahead',
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './scrollable-type-ahead.component.html',
})
export class ScrollableTypeAheadComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit, OnDestroy {
    items: any[];
    selectedItem: any;
    typeAheadApi: ITypeAheadAPI;
    subscriptions = new Subscription();
    matchingStrategy: MatchingStrategy;
    placeholder: string;
    maxToShow = 10;
    isItemDisabled: (item) => boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.items = this.config?.options ?? [];
        this.maxToShow = this.items.length;
        this.placeholder = this.config?.placeholder ?? '';
        this.matchingStrategy = MatchingStrategy.StartsWith;
        if (this.config?.type?.typeAheadOptions?.isItemDisabled) {
            this.isItemDisabled = this.config.type.typeAheadOptions.isItemDisabled;
        }
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                if (!value) {
                    this.typeAheadApi.clearValue();
                }
            }),
        );
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        if (event?.selection) {
            this.getControl().setValue(event.selection.Id);
        } else {
            const control = this.getControl();
            if (control.value) {
                this.getControl().setValue(null);
            }
        }
    }

    typeAheadControlReady(event: ITypeAheadAPI): void {
        const selectedOption = this.config.options.find((option) => {
            return option.Id === this.config.value;
        });
        event.setValue(selectedOption);
        this.getControl().setValue(this.config.value);
        this.typeAheadApi = event;
    }

    focusMe(): void {
        this.typeAheadApi.focus();
    }
}
