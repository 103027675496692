import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ITestScan } from '../interfaces/test-scan';
import { IScanner } from '../interfaces/scanner';
import { IUser } from '../interfaces/user';

export interface ITestScanDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    scanners?: IScanner[];
}

export class TestScanDynamicControls {

    formGroup: string;
    users: IUser[];
    scanners: IScanner[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private testscan?: ITestScan, additionalParameters?: ITestScanDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TestScan';
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.scanners = additionalParameters && additionalParameters.scanners || undefined;

        this.Form = {
            DateCompleted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Completed',
                name: 'DateCompleted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.testscan && this.testscan.DateCompleted || null,
            }),
            EmployeeName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Employee Name',
                name: 'EmployeeName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.testscan && this.testscan.hasOwnProperty('EmployeeName') && this.testscan.EmployeeName !== null ? this.testscan.EmployeeName.toString() : '',
            }),
            ScannerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scanner',
                name: 'ScannerId',
                options: this.scanners,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.testscan && this.testscan.ScannerId || null,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.testscan && this.testscan.UserId || null,
            }),
        };

        this.View = {
            DateCompleted: new DynamicLabel(
                'Date Completed',
                this.testscan && this.testscan.DateCompleted || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EmployeeName: new DynamicLabel(
                'Employee Name',
                this.testscan && this.testscan.hasOwnProperty('EmployeeName') && this.testscan.EmployeeName !== null ? this.testscan.EmployeeName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ScannerId: new DynamicLabel(
                'Scanner',
                getMetaItemValue(this.scanners, this.testscan && this.testscan.hasOwnProperty('ScannerId') && this.testscan.ScannerId !== null ? this.testscan.ScannerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            UserId: new DynamicLabel(
                'User',
                getMetaItemValue(this.users, this.testscan && this.testscan.hasOwnProperty('UserId') && this.testscan.UserId !== null ? this.testscan.UserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
