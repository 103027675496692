import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthGuard } from '@mt-ng2/auth-module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HomePageOverrideGuard extends AuthGuard implements CanActivate {
    constructor(injector: Injector, public router: Router) {
        super(injector);
    }

    canActivate(route): Observable<boolean | UrlTree> {
        return super.canActivate(route).pipe(
            map((response) => {
                if (typeof response === 'boolean' && response) {
                    return this.router.parseUrl('users/my-profile');
                }
                return response;
            }),
        );
    }
}
