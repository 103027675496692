import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IStockColor } from '../model/interfaces/stock-color';

@Injectable()
export class StockColorsService extends MetaItemService<IStockColor> {
    constructor(public http: HttpClient) {
        super('StockColorsService', 'StockColor', 'StockColorIds', '/salesorders/stock-colors', http);
    }
}
