import { NgModule } from '@angular/core';
import { ScalesService } from './scales.service';

@NgModule({})
export class ScalesModule {
    static forRoot(): any {
        return {
            ngModule: ScalesModule,
            providers: [ScalesService],
        };
    }
}
