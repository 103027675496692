import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BoardTypeService } from '../board-type.service';
import { PastingTeamLogService } from './pasting-team-log.service';

import { PastingTeamLogDynamicControlsPartial } from '@model/partials/pasting-team-log-partial.form-controls';
import { IPastingTeamLog } from '@model/interfaces/pasting-team-log';
import { BoardTypeIds } from '@model/BoardTypes';
import { distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { IExpandableObject } from '@model/expandable-object';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { OrderTypeIds } from '@model/OrderTypes';
import { ConvertingDataEntryUserService } from '../converting-data-entry-user.service';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'pasting-team-log-entry',
    styles: [
        `
            hr {
                border-color: #999;
            }
        `,
    ],
    templateUrl: './pasting-team-log-entry.component.html',
})
export class PastingTeamLogEntryComponent implements OnInit {
    abstractPastingTeamLogControls: IExpandableObject;
    pastingTeamLogEntryForm: FormGroup;

    doubleClickIsDisabled = false;
    initialRows = 5;
    hoveredIndex = -1;
    loadingLogs = false;

    isNewReport: boolean;
    selectedDate: Date;
    currentReportId: number;
    maxReportId: number;

    get pastingTeamLogFormArray(): FormArray {
        return <FormArray>this.pastingTeamLogEntryForm.controls.PastingTeamLogs;
    }

    convertingDataEntryUsers: IConvertingDataEntryUser[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private boardTypeService: BoardTypeService,
        private pastingTeamLogService: PastingTeamLogService,
        private salesOrderService: SalesOrderService,
        private convertingDataEntryUserService: ConvertingDataEntryUserService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.boardTypeService.getItems(), this.convertingDataEntryUserService.getItems()]).subscribe(([, users]) => {
            this.convertingDataEntryUsers = users;
            this.createForm();
        });
    }

    createForm(): void {
        this.abstractPastingTeamLogControls = new PastingTeamLogDynamicControlsPartial(this.convertingDataEntryUsers, null, {
            boardTypes: this.boardTypeService.items,
            formGroup: 'PastingTeamLogHeader',
        }).Form;

        this.pastingTeamLogEntryForm = this.fb.group({
            PastingTeamLogHeader: this.fb.group({}),
            PastingTeamLogs: this.fb.array([]),
        });

        this.addDetail(this.initialRows);
    }

    dateControlCreated(dateControl: FormControl): void {
        dateControl.valueChanges
            .pipe(
                filter((date) => date !== null),
                distinctUntilChanged((x: Date, y: Date) => x.getTime() === y.getTime()),
            )
            .subscribe((date) => this.selectDate(date));
    }

    selectDate(date: Date): void {
        this.maxReportId = null;
        this.dateSelected(date);
    }

    dateSelected(date: Date, reportId: number = null): void {
        this.selectedDate = date;
        if (reportId === null || reportId > 0 && this.maxReportId !== null && reportId <= this.maxReportId || this.isNewReport) {
            this.loadingLogs = true;
            this.pastingTeamLogService
                .getByDate(date, reportId)
                .subscribe((logs) => {
                    this.pastingTeamLogFormArray.clear();
                    this.addDetail(logs.length || this.initialRows);
                    // Give added detail lines time to render
                    setTimeout(() => {
                        if (logs.length) {
                            this.currentReportId = logs[0].ReportId;
                            this.maxReportId = this.maxReportId ?? this.currentReportId;
                            this.pastingTeamLogEntryForm.get('PastingTeamLogHeader').patchValue(logs[0]);
                            this.pastingTeamLogEntryForm.get('PastingTeamLogs').patchValue(logs);
                            this.patchConvertingDataEntryUsers(logs[0]);
                        } else {
                            this.maxReportId = null;
                            this.pastingTeamLogEntryForm.get('PastingTeamLogHeader').reset({ Date: date });
                            this.resetConvertingDataEntryUsers();
                        }

                        this.loadingLogs = false;
                    });
                });
            if (!this.isNewReport) {
                this.currentReportId = null;
            }
            this.isNewReport = false;
        } else {
            this.showNoReportsWarning();
        }
    }

    private patchConvertingDataEntryUsers(log: IPastingTeamLog): void {
        (<IDynamicField>this.abstractPastingTeamLogControls.Feeder).value = log.Feeder;
        (<IDynamicField>this.abstractPastingTeamLogControls.Catcher).value = log.Catcher;
        (<IDynamicField>this.abstractPastingTeamLogControls.Pressman).value = log.Pressman;
    }

    private resetConvertingDataEntryUsers(): void {
        (<IDynamicField>this.abstractPastingTeamLogControls.Feeder).value = '';
        (<IDynamicField>this.abstractPastingTeamLogControls.Catcher).value = '';
        (<IDynamicField>this.abstractPastingTeamLogControls.Pressman).value = '';
    }

    showNoReportsWarning(): void {
        this.notificationsService.warning('No more reports exist for this date');
    }

    buildPayload(): IPastingTeamLog[] {
        let logs: IPastingTeamLog[] = [];
        let headerValues = this.pastingTeamLogEntryForm.value.PastingTeamLogHeader;

        // check each detail and only add it to the payload if it's pre-existing or has been edited
        this.pastingTeamLogFormArray.controls.forEach((details: FormGroup) => {
            const value = details.getRawValue();
            if (value.Id > 0 || details.dirty) {
                let log = this.pastingTeamLogService.getEmptyPastingTeamLog();
                log.ReportId = this.currentReportId ?? 1;
                log.Date ??= this.selectedDate;
                Object.assign(log, headerValues);
                Object.assign(log, details.getRawValue());
                log.SalesOrderId = log.SalesOrderId === 0 ? null : log.SalesOrderId;
                logs.push(log);
            }
        });

        return logs;
    }

    addDetail(qty = 1): void {
        for (let i = 1; i <= qty; i++) {
            this.pastingTeamLogFormArray.push(
                this.fb.group({
                    Id: this.fb.control({ value: 0, disabled: true }), // this is a hidden field to track Ids for existing logs
                }),
            );
        }
    }

    removeDetail(index: number): void {
        this.pastingTeamLogFormArray.removeAt(index);
    }

    pullSalesOrderValues(index: any): void {
        if (this.loadingLogs) {
            return;
        }

        let formRow = this.pastingTeamLogFormArray.at(index);
        if (!formRow) {
            return;
        }

        let salesOrderId: number = (formRow as FormGroup).controls.SalesOrderId.value;
        if (!salesOrderId) {
            return;
        }

       // get the sales order
        forkJoin([this.salesOrderService.getById(salesOrderId), this.salesOrderService.getSkids(salesOrderId)]).subscribe(([salesOrder, skids])  => {

        if (!salesOrder) {
            this.notificationsService.error(`Sales Order ${salesOrderId} not found`);
        } else {
            if (skids) {
                salesOrder.Skids = skids;
            }
            this.pullManufacturingOrderDetailData(salesOrder, formRow);
        }
    });
    }

    pullManufacturingOrderDetailData(salesOrder: ISalesOrder, formRow: AbstractControl): void {
        if (!salesOrder.ManufacturingOrderDetail) {
            return;
        }

        // default data to manufacturing order detail data
        const mfod = salesOrder.ManufacturingOrderDetail;
        let width = mfod.Width;
        let length = mfod.Length;
        let caliper = mfod.Caliper;
        let count = mfod.Count;
        let skids = mfod.NumberOfSkids;
        let bps = mfod.BdlSk;
        let bundles = mfod.Quantity;
        let grade = '';
        let boardTypeId = BoardTypeIds.MilledBoard;
        let isTiedBundle = salesOrder.OrderInfo.OrderCustomerSpecification.IsTiedBundles;
        let customerName = salesOrder.OrderInfo.Customer.CompanyName;
        let ply = 0;

        if (salesOrder.OrderDetailLines && salesOrder.OrderDetailLines.length > 0) {
            if (salesOrder.OrderDetailLines.length > 1) {
                let mappedOptions = {};
                salesOrder.OrderDetailLines.forEach((line, i) => {
                    mappedOptions[i] = `Width: ${line.Width}, Length: ${line.Length}, Height: ${line.Height} etc.`;
                });
                this.modalService
                    .showModal({
                        input: 'select',
                        inputOptions: mappedOptions,
                        inputPlaceholder: 'Select a converting line',
                        text: `Order #${salesOrder.Id} has multiple converting lines, please select one:`,
                        title: 'Select a converting line',
                        type: 'warning',
                    })
                    .subscribe((result) => {
                        // result.value represents the index of the selected orderdetail line
                        let convertingLine = salesOrder.OrderDetailLines[result.value];
                        if (convertingLine) {
                            formRow.patchValue({
                                BdlSk: convertingLine.BdlSk,
                                BoardGrade: grade,
                                BoardTypeId: boardTypeId,
                                Bundles: convertingLine.Quantity,
                                Caliper: convertingLine.Caliper,
                                Count: convertingLine.Count,
                                CustomerName: customerName,
                                Length: convertingLine.Length,
                                Ply: convertingLine.Ply,
                                Skids: convertingLine.NumberOfSkids,
                                TiedBundles: isTiedBundle,
                                Width: convertingLine.Width,
                            });
                        }
                    });
            } else {
                let convertingLine = salesOrder.OrderDetailLines[0];
                width = convertingLine.Width;
                length = convertingLine.Length;
                caliper = convertingLine.Caliper;
                count = convertingLine.Count;
                skids = convertingLine.NumberOfSkids;
                bps = convertingLine.BdlSk;
                bundles = convertingLine.Quantity;
                ply = convertingLine.Ply;
            }

        }

        if (salesOrder.OrderTypeId === OrderTypeIds.Stock) {
            boardTypeId = BoardTypeIds.StockBoard;
        } else {
            boardTypeId = BoardTypeIds.MilledBoard;
            grade = salesOrder.ManufacturingOrderDetail.BoardGrade?.Name;
        }

        formRow.patchValue({
            BdlSk: bps,
            BoardGrade: grade,
            BoardTypeId: boardTypeId,
            Bundles: bundles,
            Caliper: caliper,
            Count: count,
            CustomerName: customerName,
            Length: length,
            Ply: ply,
            Skids: skids,
            TiedBundles: isTiedBundle,
            Width: width,
        });
    }

    formSubmitted(): void {
        if (this.pastingTeamLogEntryForm.valid) {
            let payload = this.buildPayload();
            if (payload.length === 0 && this.pastingTeamLogFormArray.length === 0) {
                this.pastingTeamLogService.deleteAllByDate(this.selectedDate)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.notificationsService.success('All Pasting Team Reports deleted successfully');
                        this.resetForm();
                        this.dateSelected(this.selectedDate);
                    });
            } else if (payload.length > 0) {
                this.pastingTeamLogService
                    .saveByDate(payload)
                    .pipe(
                        finalize(() => {
                            this.enableDoubleClick();
                        }),
                    )
                    .subscribe((ids) => {
                        this.pastingTeamLogFormArray.patchValue(ids.map((id) => ({ Id: id })));
                        this.notificationsService.success('Pasting Team Report saved successfully');
                        this.dateSelected(this.selectedDate);
                    });
            } else {
                this.notificationsService.warning('There is no data to save.');
                this.enableDoubleClick();
            }
        } else {
            common.markAllFormFieldsAsTouched(this.pastingTeamLogEntryForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    resetForm(): void {
        this.pastingTeamLogEntryForm.reset();
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    cancelClick(): void {
        this.router.navigate(['/'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    navigateToFirstReportOfTheDay(): void {
        this.dateSelected(this.selectedDate, 1);
    }

    navigateToPreviousReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId - 1);
    }

    navigateToNextReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId + 1);
    }

    navigateToLatestReportOfTheDay(): void {
        this.dateSelected(this.selectedDate);
    }

    getNewReport(): void {
        this.isNewReport = true;
        this.currentReportId = this.maxReportId + 1;
        this.dateSelected(this.selectedDate, this.currentReportId);
    }
}
