import {
    DynamicFieldTypes,
    DynamicFieldType,
    InputTypes,
    NumericInputTypes,
    DynamicField,
    SelectInputTypes,
    noZeroRequiredValidator,
} from '@mt-ng2/dynamic-form';
import { MillComplaintDynamicControls, IMillComplaintDynamicControlsParameters } from '../form-controls/mill-complaint.form-controls';
import { IMillComplaint } from '../interfaces/mill-complaint';
import { Validators } from '@angular/forms';
import { OverrideNumericFunctionsEmptyWhenZero } from '@common/common-functions.form-controls';

export class MillComplaintDynamicControlsPartial extends MillComplaintDynamicControls {
    constructor(private complaint?: IMillComplaint, additionalParameters?: IMillComplaintDynamicControlsParameters) {
        super(complaint, additionalParameters);
        this.Form.Resolution.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textarea,
        });
        this.Form.DescriptionOfProblem.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textarea,
        });
        this.Form.Other.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textarea,
        });
        this.Form.IsBoardKept.labelHtml = '<label>Was board kept by Customer?</label>';
        this.Form.IsBoardRemade.labelHtml = '<label>Was board remade?</label>';
        this.Form.IsBoardReturned.labelHtml = '<label>Was board returned to Newman?</label>';
        this.Form.IsCreditIssued.labelHtml = '<label>Was credit issued to Customer?</label>';
        this.Form.IssueCreditMemo.labelHtml = '<label>Issue a credit memo</label>';
        this.Form.ComplaintRecorded.labelHtml = '<label>Complaint recorded in personnel files</label>';

        this.Form.QuantityAffected = new DynamicField({
            formGroup: this.formGroup,
            label: 'Quantity Affected',
            name: 'QuantityAffected',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                complaint && complaint.hasOwnProperty('QuantityAffected') && complaint.QuantityAffected !== null ? complaint.QuantityAffected : null,
        });

        this.Form.QuantityAffectedTypeId = new DynamicField({
            formGroup: this.formGroup,
            label: ' ',
            name: 'QuantityAffectedTypeId',
            options: [
                { Id: 1, Name: 'Pieces' },
                { Id: 2, Name: 'Bundles' },
                { Id: 3, Name: 'Skids' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            }),
            validation: [noZeroRequiredValidator],
            value:
                complaint && complaint.hasOwnProperty('QuantityAffectedTypeId') && complaint.QuantityAffectedTypeId !== null
                    ? complaint.QuantityAffectedTypeId
                    : null,
        });

        (<DynamicField>this.Form.MillComplaintCategoryId).type.inputType = SelectInputTypes.Dropdown;

        OverrideNumericFunctionsEmptyWhenZero([this.Form.InvoiceNumber]);
    }
}
