import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IReceiptFormat } from '../../model/interfaces/receipt-format';
import { CustomerService } from '../customer.service';
import { ReceiptSizeTotalService } from '../receipt-size-total.service';
import { ReceiptFormatDynamicControlsPartial } from '../../model/partials/receipt-format-partial.form-controls';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'receipt-format',
    templateUrl: './receipt-format.component.html',
})
export class ReceiptFormatComponent implements OnInit {
    @Input('customerId') customerId: number;
    @Input('canEdit') canEdit: boolean;

    receiptFormat: IReceiptFormat;
    abstractReceiptFormatControls: any;

    receiptFormatForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    isHovered = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private receiptSizeTotalService: ReceiptSizeTotalService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        forkJoin(this.customerService.getReceiptFormat(this.customerId), this.receiptSizeTotalService.getItems()).subscribe((answer) => {
            this.receiptFormat = answer[0] || this.customerService.getEmptyReceiptFormat();
            this.createForm();
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    createForm(): void {
        this.getControls();
        this.receiptFormatForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractReceiptFormatControls = new ReceiptFormatDynamicControlsPartial(this.receiptFormat, {
            customers: null,
            formGroup: 'ReceiptFormat',
            receiptSizeTotals: this.receiptSizeTotalService.items,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ReceiptFormat: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.receiptFormatForm.valid) {
            // save logic here
            let value = <IReceiptFormat>this.receiptFormatForm.value.ReceiptFormat;
            value.CustomerId = this.customerId;
            this.customerService
                .saveReceiptFormat(this.customerId, value)
                .pipe(finalize(() => this.enableDoubleClick()))
                .subscribe(() => {
                    Object.assign(this.receiptFormat, value);
                    this.receiptFormat.ReceiptSizeTotal = this.receiptSizeTotalService.items.find(
                        (item) => item.Id === this.receiptFormat.ReceiptSizeTotalId,
                    );
                    this.cancelClick();
                    this.createForm();
                    this.notificationsService.success('Receipt format saved successfully.');
                });
        } else {
            common.markAllFormFieldsAsTouched(this.receiptFormatForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('ReceiptFormat saved successfully.');
    }
}
