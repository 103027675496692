import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PrintService } from '@common/services/print.service';
import { finalize } from 'rxjs/operators';
import { TrimDetailWithPrintParams } from '@model/dto/trim-detail-with-print-params';
import { BacktenderService } from '../backtender.service';

@Component({
    selector: 'trim-sheet-tags',
    styles: [
        `
            .newman-markings-warning {
                color: #ffff60;
                font-size: 12px;
                padding: 7px;
                background-color: red;
            }
            .newman-markings-warning hr {
                border: 1px solid #ffff60;
            }
        `,
    ],
    templateUrl: './trim-sheet-tags.component.html',
})
export class TrimSheetTagsComponent implements OnChanges {
    @Input('setupId') setupId: number;
    @Output('print') print = new EventEmitter();
    @Output('cancel') cancel = new EventEmitter();

    trimDetailWithPrintParams: TrimDetailWithPrintParams[] = [];

    doubleClickIsDisabled = false;

    constructor(private notificationsService: NotificationsService, private backtenderService: BacktenderService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.setupId) {
            this.loadDetails();
        }
    }

    loadDetails(): void {
        if (this.setupId >= 0) {
            this.backtenderService.getDetailsWithPrintParamsFor(this.setupId).subscribe({
                error: () => (this.trimDetailWithPrintParams = []),
                next: (trimDetailWithPrintParams) => {
                    this.trimDetailWithPrintParams = trimDetailWithPrintParams;
                },
            });
        } else {
            this.trimDetailWithPrintParams = [];
            return;
        }

    }

    printTags(isPrintAll = true, index?: number): void {
        const tagsToPrint = isPrintAll
            ? this.trimDetailWithPrintParams.map((item) => item.TagPrintParams)
            : [this.trimDetailWithPrintParams[index].TagPrintParams];
        this.backtenderService
            .getTrimSheetTagPdfs(tagsToPrint)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((pdf) => {
                if (pdf != null) {
                    PrintService.printPdf(pdf);
                    this.print.emit();
                    if (isPrintAll || this.trimDetailWithPrintParams.length === 1) {
                        this.cancel.emit();
                    }
                } else {
                    this.notificationsService.error('No tags to print');
                }
            });
    }

    cancelClick(): void {
        this.cancel.emit();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
