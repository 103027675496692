import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { TestScanRoutingModule } from './test-scan.routing.module';
import { TestStackComponent } from './test-stack/test-stack.component';
import { TestScanService } from './test-scan.service';

@NgModule({
    declarations: [TestStackComponent],
    imports: [SharedModule, TestScanRoutingModule],
})
export class TestScanModule {
    static forRoot(): any {
        return {
            ngModule: TestScanModule,
            providers: [TestScanService],
        };
    }
}
