import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRunnerDirectionType } from '../model/interfaces/runner-direction-type';

@Injectable()
export class RunnerDirectionTypeService extends StaticMetaItemService<IRunnerDirectionType> {
    constructor(public http: HttpClient) {
        super('RunnerDirectionTypeService', 'Type', 'TypeIds', '/salesorders/runner-direction-types', http);
    }
}
