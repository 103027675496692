import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IStockItem } from '../model/interfaces/stock-item';
import { IStockOrderLine } from '@model/interfaces/stock-order-line';
import { finalize } from 'rxjs/operators';

@Injectable()
export class StockItemsService extends BaseService<IStockItem> {
    private emptyStockItem: IStockItem = {
        BoardGradeId: 0,
        Bundles: 0,
        CaliperMax: null,
        CaliperMin: null,
        DateCreated: null,
        DefectTypeId: null,
        FinishId: 0,
        Id: 0,
        IsAvailable: false,
        IsPerfect: false,
        Length: null,
        LotNumber: null,
        MillShiftPersonnelId: 0,
        Name: null,
        NetWeight: null,
        PoundsPerBundle: null,
        Processed: false,
        Qty: null,
        Sides: 0,
        StockIdNumber: null,
        StockItemTypeId: null,
        TareWeight: null,
        TrimSheetDetailId: null,
        WarehouseLocationId: null,
        Width: null,
    };

    constructor(public http: HttpClient) {
        super('/stock-items', http);
    }

    getEmptyStockItem(): IStockItem {
        return { ...this.emptyStockItem };
    }

    getFinalTagPdf(stockItemId: number): any {
        return this.http.get('/stock-items/' + stockItemId + '/final-tag/pdf');
    }

    getByStockIdNumber(stockIdNumber: string): Observable<IStockItem> {
        return this.http.get<IStockItem>('/stock-items/id-number/' + stockIdNumber);
    }

    getStockItemBelongsToStockOrder(stockIdNumber: string): Observable<boolean> {
        return this.http.get<boolean>('/stock-items/id-number/' + stockIdNumber + '/belongs-to-order');
    }

    unloadLineFromShipment(line: IStockOrderLine): Observable<any> {
        return this.http.put<any>(`/stock-items/remove-from-shipment`, line);
    }
}
