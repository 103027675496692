import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IQuantityAffectedType } from '../../model/interfaces/quantity-affected-type';

@Injectable()
export class QuantityAffectedTypeService extends StaticMetaItemService<IQuantityAffectedType> {
    constructor(public http: HttpClient) {
        super('QuantityAffectedTypeService', 'Quantity Affected Type', 'QuantityAffectedTypeIds', '/mill-complaints/quantity-affected-types', http);
    }
}
