import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IShipmentStatus } from '../model/interfaces/shipment-status';

@Injectable()
export class ShipmentStatusService extends StaticMetaItemService<IShipmentStatus> {
    constructor(public http: HttpClient) {
        super('ShipmentStatusService', 'Status', 'StatusIds', '/shipment-statuses', http);
    }
}
