<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Tons and Pricing</h2>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-5 col-lg-4">
        <div class="miles-card padded">
            <h4>Print Reports</h4>
            <div class="row">
                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Month</label>
                        <month-select [(model)]="month" [required]="true" [showEmpty]="false"></month-select>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Year</label>
                        <year-select [(model)]="year" [required]="true" [showEmpty]="false" [fromYear]="fromYear" [toYear]="toYear"></year-select>
                    </div>
                </div>
            </div>
            <button class="btn btn-secondary" (click)="printSummary()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
                <i class="fa fa-fw fa-print"></i> Print Summary
            </button>
            <button class="btn btn-secondary" (click)="printDetail()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
                <i class="fa fa-fw fa-print"></i> Print Detail
            </button>
        </div>
    </div>
</div>
