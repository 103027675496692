import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { IContact } from '../../model/interfaces/contact';
import { Injectable } from '@angular/core';
import { FormatService } from '../../common/services/format.service';

@Injectable()
export class ContactsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor(navigateToCustomer: Function) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.Customers[0].CustomerName}`;
                    },
                    linkFunction: function (contact: IContact): void {
                        navigateToCustomer(contact);
                    },
                    name: 'Customer',
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.FirstName} ${contact.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    name: 'Title',
                }),
                new EntityListColumn({
                    accessors: ['ContactType', 'Name'],
                    name: 'Type',
                }),
                new EntityListColumn({
                    linkFunction: function (contact: IContact): void {
                        window.open(`mailto:${contact.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        let phone = contact.ContactPhones.find((phone) => phone.IsPrimary);
                        if (phone) {
                            return `<a (click)="$event.stopPropagation()" href="tel:${phone.Phone}${
                                phone.Extension ? ';ext=' + phone.Extension : ''
                            }">
                            ${FormatService.phone(phone.Phone, phone.Extension)}
                            </a>`;
                        } else {
                            return '';
                        }
                    },
                    bindToInnerHtml: true,
                    name: 'Phone',
                }),
            ],
        };
        super(listConfig);
    }
}
