import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';

import { IContact } from '../../model/interfaces/contact';
import { catchError } from 'rxjs/operators';
import { IContactWithPhoneDetail } from '@model/interfaces/custom/contact-with-phone-detail';

@Injectable()
export class CustomerContactsService extends SharedEntitiesService<IContactWithPhoneDetail> {
    constructor(public http: HttpClient) {
        super('/customers', '/contacts', http);
    }

    getContactById(contactId: number): Observable<IContact> {
        return this.http.get<IContactWithPhoneDetail>(`/customers/contacts/${contactId}`).pipe(catchError(this.handleError));
    }

    savePhones(customerId: number, contactId: number, phoneCollection: any): any {
        return this.http
            .put(`/customers/${customerId}/contacts/${contactId}/phones`, phoneCollection, { responseType: 'text' as 'json' })
            .pipe(catchError(this.handleError));
    }
}
