import { Component, OnInit } from '@angular/core';
import { Scales } from '../../model/Scales';

@Component({
    selector: 'app-lining',
    templateUrl: './lining.component.html',
})
export class LiningComponent implements OnInit {
    scaleId = Scales.Lining;

    constructor() {}

    ngOnInit(): void {}
}
