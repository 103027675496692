import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { ReceiptDetailsComponent } from './receipt-details/receipt-details.component';
import { ReceiptPrintComponent } from './receipt-print/receipt-print.component';
import { TonsAndPricingComponent } from './tons-and-pricing/tons-and-pricing.component';
import { QuickbooksQueueComponent } from './quickbooks-queue/quickbooks-queue.component';
import { BillingSheetAdjustments } from './billing-sheet-adjustments/billing-sheet-adjustments.component';

const receiptRoleGuard = {
    claimType: ClaimTypes.Receipts,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Receipts',
};

const receiptRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ReceiptDetailsComponent,
        data: receiptRoleGuard,
        path: 'receipts/detail',
        pathMatch: 'full',
    },
    {
        component: ReceiptDetailsComponent,
        data: receiptRoleGuard,
        path: 'receipts/:receiptId/detail',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ReceiptPrintComponent,
        data: receiptRoleGuard,
        path: 'receipts/invoicing',
    },
    {
        canActivate: [AuthGuard],
        component: TonsAndPricingComponent,
        data: receiptRoleGuard,
        path: 'receipts/tons-and-pricing',
    },
    {
        canActivate: [AuthGuard],
        component: QuickbooksQueueComponent,
        data: receiptRoleGuard,
        path: 'receipts/quickbooks-queue',
    },
    {
        canActivate: [AuthGuard],
        component: BillingSheetAdjustments,
        data: receiptRoleGuard,
        path: 'receipts/billing-sheet-adjustments',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(receiptRoutes)],
})
export class ReceiptsRoutingModule { }
