<div *ngIf="customer && customer.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info [customer]="customer" [canEdit]="canEdit"></app-customer-basic-info><br />
            <app-common-phone [PhoneArray]="customer.CustomerPhones" (onSave)="updatePhonesIfNeeded($event)" [canEdit]="canEdit"></app-common-phone><br />
            <app-common-addresses [addressContainerArray]="customer.CustomerAddresses" [canEdit]="canEdit" [canAdd]="canEdit" (onSave)="saveAddress($event)"></app-common-addresses><br />
            <app-entity-list-card [entityListCardOptions]="customPackagingOptions"></app-entity-list-card>
            <br />
        </div>
        <div class="col-md-6">
            <app-common-addresses
                entityName="Invoice Address"
                [address]="invoiceAddress"
                [canEdit]="canEdit"
                [canAdd]="canEdit"
                (onSave)="saveInvoiceAddress($event)"
                [showDelete]="false"
            ></app-common-addresses>
            <br />
            <shared-entities [sharedEntityId]="shippingAddresSharedEntityId" [canEdit]="canEdit" [canAdd]="canAdd"></shared-entities>
            <br />
            <shared-entities [sharedEntityId]="contactsSharedEntityId" [canEdit]="canEditCustomerContacts" [canAdd]="canAddCustomerContacts"></shared-entities>
            <br />
            <app-common-notes [canEdit]="canEdit"></app-common-notes>
            <br />
            <receipt-format [customerId]="customer?.Id" [canEdit]="canEdit"></receipt-format>
        </div>
    </div>

    <a routerLink="/customers" class="btn btn-default">Close</a>
    <a routerLink="/customers/{{ customer.Id }}/pricing" class="btn btn-default">
        <i class="fa fa-dollar p-r-xs"></i>
        Pricing
    </a>
    <a routerLink="/customers/{{ customer.Id }}/specs-info" class="btn btn-default">
        <i class="fa fa-ellipsis-v p-r-xs"></i>
        Specs / Details
    </a>
</div>
<div *ngIf="customer && customer.Id === 0">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info [customer]="customer"></app-customer-basic-info>
        </div>
    </div>
</div>
