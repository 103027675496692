import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRotaryType } from '../model/interfaces/rotary-type';

@Injectable()
export class RotaryTypeService extends StaticMetaItemService<IRotaryType> {
    constructor(public http: HttpClient) {
        super('RotaryTypeService', 'Type', 'TypeIds', '/rotary-types', http);
    }
}
