import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SkidService } from '../../skids/skid.service';
import { ISkid } from '../../model/interfaces/skid';
import { IShipment } from '../../model/interfaces/shipment';
import { IFormattedSalesOrder } from '../../model/interfaces/custom/formatted-sales-order';
import { IStockOrderLine } from '@model/interfaces/stock-order-line';
import { StockItemsService } from '../../sales-orders/stockitem.service';
import { truncateToOneDecimal } from '@common/common-functions.form-controls';

@Component({
    selector: 'shipment-skid',
    styles: [
        `
            .text-left {
                text-align: left;
            }
        `,
    ],
    templateUrl: './shipment-skid.component.html',
})
export class ShipmentSkidComponent implements OnInit {
    skids: ISkid[];
    stockOrderLines: IStockOrderLine[];
    @Input('shipment') shipment: IShipment;
    @Input('canRemoveSkids') canRemoveSkids = false;
    @Output('onSkidRemoved') onSkidRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private skidService: SkidService,
        private shipmentService: ShipmentService,
        private stockItemService: StockItemsService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.shipment.currentValue) {
            if (this.shipment) {
                this.skids = this.shipment.Skids;
                this.getShipmentStockOrderLines();
            }
            if (this.shipment && (!this.skids || !this.skids.length)) {
                this.getShipmentSkids();
            }
        } else {
            this.skids = [];
            this.stockOrderLines = [];
        }
    }

    getShipmentSkids(): void {
        this.shipmentService.getShipmentSkids(this.shipment.Id).subscribe((skids) => (this.skids = skids));
    }

    getShipmentStockOrderLines(): void {
        this.shipmentService.getShipmentStockOrderLines(this.shipment.Id).subscribe((stockOrderLines) => {
            this.stockOrderLines = stockOrderLines;
        });
    }

    get shipmentWeight(): number {
        let value = 0;
        if (this.skids && this.skids.length) {
            value = this.skids.map((s) => s.ActualWeight).reduce((sum, current) => sum + current);
        }
        return value;
    }

    removeSkidFromLoad(skid: ISkid): void {
        if (window.confirm('Remove selected skid from load?')) {
            skid.ShipmentId = null;
            this.skidService.updateNoPiles(skid).subscribe((success) => {
                this.notificationsService.success('Successfully removed skid from load.');
                this.getShipmentSkids();
                this.onSkidRemoved.emit(true);
            });
        }
    }

    removeStockOrderLineFromLoad(line: IStockOrderLine): void {
        if (window.confirm('Remove select skid from load?')) {
            line.ShipmentId = null;
            this.stockItemService.unloadLineFromShipment(line).subscribe(() => {
                this.notificationsService.success('Successfully removed skid from load.');
                this.getShipmentStockOrderLines();
                this.onSkidRemoved.emit(true);
            });
        }
    }

    getBundlesCount(skid: ISkid): number {
        if (skid.Count > 0) {
            return Math.floor((skid.ActualCount / skid.Count) * 10) / 10;
        } else {
            const poundsPerBundle = skid.SalesOrder.OrderInfo.OrderCustomerSpecification.IsFortyPoundBundle ? 40 : 50;
            const netWeight = skid.ActualWeight - skid.TareWeightTop - skid.TareWeightBottom;
            return Math.floor((netWeight / poundsPerBundle) * 10) / 10;
        }
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    getTruncatedBundles(bundles: number): number {
        return truncateToOneDecimal(bundles);
    }
}
