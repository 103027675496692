import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@mt-ng2/auth-module';
import { millCorpInvoicePaths, millCorpInvoiceRoleGuard, millCorpSettingsRoleGuard } from '@routes/mill-corp-paths';

import { MillCorpInvoiceComponent } from './mill-corp-invoice/mill-corp-invoice.component';
import { MillCorpSettingsComponent } from './mill-corp-settings/mill-corp-settings.component';

const millCorpRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: MillCorpInvoiceComponent,
        data: millCorpInvoiceRoleGuard,
        path: `${millCorpInvoicePaths.millCorpInvoice}`,
    },
    {
        canActivate: [AuthGuard],
        component: MillCorpSettingsComponent,
        data: millCorpSettingsRoleGuard,
        path: 'mill-corp/settings',
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(millCorpRoutes)],
})
export class MillCorpRoutingModule {}
