import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPalletInfo } from '../interfaces/pallet-info';
import { IRunnerDirectionType } from '../interfaces/runner-direction-type';
import { IShipOnType } from '../interfaces/ship-on-type';

export interface IPalletInfoDynamicControlsParameters {
    formGroup?: string;
    runnerDirectionTypes?: IRunnerDirectionType[];
    shipOnTypes?: IShipOnType[];
}

export class PalletInfoDynamicControls {

    formGroup: string;
    runnerDirectionTypes: IRunnerDirectionType[];
    shipOnTypes: IShipOnType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private palletinfo?: IPalletInfo, additionalParameters?: IPalletInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PalletInfo';
        this.runnerDirectionTypes = additionalParameters && additionalParameters.runnerDirectionTypes || undefined;
        this.shipOnTypes = additionalParameters && additionalParameters.shipOnTypes || undefined;

        this.Form = {
            BoardSizeLength: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Size Length',
                name: 'BoardSizeLength',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('BoardSizeLength') && this.palletinfo.BoardSizeLength !== null ? this.palletinfo.BoardSizeLength.toString() : '',
            }),
            BoardSizeWidth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Size Width',
                name: 'BoardSizeWidth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('BoardSizeWidth') && this.palletinfo.BoardSizeWidth !== null ? this.palletinfo.BoardSizeWidth.toString() : '',
            }),
            CutSizeLength: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cut Size Length',
                name: 'CutSizeLength',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('CutSizeLength') && this.palletinfo.CutSizeLength !== null ? this.palletinfo.CutSizeLength.toString() : '',
            }),
            CutSizeWidth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cut Size Width',
                name: 'CutSizeWidth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('CutSizeWidth') && this.palletinfo.CutSizeWidth !== null ? this.palletinfo.CutSizeWidth.toString() : '',
            }),
            InchesBetweenRunners: new DynamicField({
                formGroup: this.formGroup,
                label: 'Inches Between Runners',
                name: 'InchesBetweenRunners',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('InchesBetweenRunners') && this.palletinfo.InchesBetweenRunners !== null ? this.palletinfo.InchesBetweenRunners.toString() : '',
            }),
            IsExactLength: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Exact Length',
                name: 'IsExactLength',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('IsExactLength') && this.palletinfo.IsExactLength !== null ? this.palletinfo.IsExactLength : false,
            }),
            IsExactWidth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Exact Width',
                name: 'IsExactWidth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('IsExactWidth') && this.palletinfo.IsExactWidth !== null ? this.palletinfo.IsExactWidth : false,
            }),
            PalletSizeLength: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pallet Size Length',
                name: 'PalletSizeLength',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('PalletSizeLength') && this.palletinfo.PalletSizeLength !== null ? this.palletinfo.PalletSizeLength.toString() : '',
            }),
            PalletSizeWidth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pallet Size Width',
                name: 'PalletSizeWidth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(25) ],
                validators: { 'maxlength': 25 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('PalletSizeWidth') && this.palletinfo.PalletSizeWidth !== null ? this.palletinfo.PalletSizeWidth.toString() : '',
            }),
            Qty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qty',
                name: 'Qty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.palletinfo && this.palletinfo.Qty || null,
            }),
            RunnerDirectionTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Runner Direction Type',
                name: 'RunnerDirectionTypeId',
                options: this.runnerDirectionTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.palletinfo && this.palletinfo.RunnerDirectionTypeId || null,
            }),
            RunnerHeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Runner Height',
                name: 'RunnerHeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('RunnerHeight') && this.palletinfo.RunnerHeight !== null ? this.palletinfo.RunnerHeight.toString() : '',
            }),
            ShipOnTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship On Type',
                name: 'ShipOnTypeId',
                options: this.shipOnTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.palletinfo && this.palletinfo.ShipOnTypeId || null,
            }),
            SpecialNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Special Notes',
                name: 'SpecialNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.palletinfo && this.palletinfo.hasOwnProperty('SpecialNotes') && this.palletinfo.SpecialNotes !== null ? this.palletinfo.SpecialNotes.toString() : '',
            }),
        };

        this.View = {
            BoardSizeLength: new DynamicLabel(
                'Board Size Length',
                this.palletinfo && this.palletinfo.hasOwnProperty('BoardSizeLength') && this.palletinfo.BoardSizeLength !== null ? this.palletinfo.BoardSizeLength.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            BoardSizeWidth: new DynamicLabel(
                'Board Size Width',
                this.palletinfo && this.palletinfo.hasOwnProperty('BoardSizeWidth') && this.palletinfo.BoardSizeWidth !== null ? this.palletinfo.BoardSizeWidth.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CutSizeLength: new DynamicLabel(
                'Cut Size Length',
                this.palletinfo && this.palletinfo.hasOwnProperty('CutSizeLength') && this.palletinfo.CutSizeLength !== null ? this.palletinfo.CutSizeLength.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CutSizeWidth: new DynamicLabel(
                'Cut Size Width',
                this.palletinfo && this.palletinfo.hasOwnProperty('CutSizeWidth') && this.palletinfo.CutSizeWidth !== null ? this.palletinfo.CutSizeWidth.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InchesBetweenRunners: new DynamicLabel(
                'Inches Between Runners',
                this.palletinfo && this.palletinfo.hasOwnProperty('InchesBetweenRunners') && this.palletinfo.InchesBetweenRunners !== null ? this.palletinfo.InchesBetweenRunners.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsExactLength: new DynamicLabel(
                'Is Exact Length',
                this.palletinfo && this.palletinfo.hasOwnProperty('IsExactLength') && this.palletinfo.IsExactLength !== null ? this.palletinfo.IsExactLength : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsExactWidth: new DynamicLabel(
                'Is Exact Width',
                this.palletinfo && this.palletinfo.hasOwnProperty('IsExactWidth') && this.palletinfo.IsExactWidth !== null ? this.palletinfo.IsExactWidth : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PalletSizeLength: new DynamicLabel(
                'Pallet Size Length',
                this.palletinfo && this.palletinfo.hasOwnProperty('PalletSizeLength') && this.palletinfo.PalletSizeLength !== null ? this.palletinfo.PalletSizeLength.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PalletSizeWidth: new DynamicLabel(
                'Pallet Size Width',
                this.palletinfo && this.palletinfo.hasOwnProperty('PalletSizeWidth') && this.palletinfo.PalletSizeWidth !== null ? this.palletinfo.PalletSizeWidth.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Qty: new DynamicLabel(
                'Qty',
                this.palletinfo && this.palletinfo.Qty || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RunnerDirectionTypeId: new DynamicLabel(
                'Runner Direction Type',
                getMetaItemValue(this.runnerDirectionTypes, this.palletinfo && this.palletinfo.hasOwnProperty('RunnerDirectionTypeId') && this.palletinfo.RunnerDirectionTypeId !== null ? this.palletinfo.RunnerDirectionTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RunnerHeight: new DynamicLabel(
                'Runner Height',
                this.palletinfo && this.palletinfo.hasOwnProperty('RunnerHeight') && this.palletinfo.RunnerHeight !== null ? this.palletinfo.RunnerHeight.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipOnTypeId: new DynamicLabel(
                'Ship On Type',
                getMetaItemValue(this.shipOnTypes, this.palletinfo && this.palletinfo.hasOwnProperty('ShipOnTypeId') && this.palletinfo.ShipOnTypeId !== null ? this.palletinfo.ShipOnTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SpecialNotes: new DynamicLabel(
                'Special Notes',
                this.palletinfo && this.palletinfo.hasOwnProperty('SpecialNotes') && this.palletinfo.SpecialNotes !== null ? this.palletinfo.SpecialNotes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
