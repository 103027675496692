import { NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { SkidRoutingModule } from './skid.routing.module';
import { MillShiftPersonnelComponent } from './mill-shift-personnel/mill-shift-personnel.component';
import { PackagingLineScannerComponent } from './packaging-line-scanner/packaging-line-scanner.component';
import { StockSkidProcessingComponent } from './stock-processing/stock-skid-processing.component';
import { NoBarcodeSkidComponent } from './no-barcode-skid/no-barcode-skid.component';
import { LiningComponent } from './lining/lining.component';
import { CreateSkidComponent } from './create/create.component';
import { ReassignSkidComponent } from './reassign-skid/reassign-skid.component';

import { SkidService } from './skid.service';
import { MillShiftService } from './millshift.service';
import { MillShiftPersonnelService } from './millshiftpersonnel.service';
import { SkidsComponent } from './skid-list/skids.component';
import { PrintTagComponent } from './print-tag/print-tag.component';

import { SkidProcessingComponent } from './skid-processing-component/skid-processing.component';
import { PastingComponent } from './pasting/pasting.component';
import { CuttingComponent } from './cutting/cutting.component';
import { RotarySlitComponent } from './rotary-slit/rotary-slit.component';
import { SkidActionsComponent } from './skid-list/skids.entity-list-config';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ScalesModule } from '../scales/scales.module';
import { ScannersModule } from '../scanners/scanners.module';
import { PackagingLineOverrideActualInputComponent } from './packaging-line-scanner/override-actual-input/override-actual-input.component';
import { ScaleOverrideComponent } from './packaging-line-scanner/override-actual-input/scale-override.component';
import { ScannerOverrideComponent } from './packaging-line-scanner/override-actual-input/scanner-override.component';
import { PrintBacktenderComponent } from './print-backtender/print-backtender.component';

@NgModule({
    declarations: [
        CreateSkidComponent,
        LiningComponent,
        PastingComponent,
        CuttingComponent,
        RotarySlitComponent,
        MillShiftPersonnelComponent,
        NoBarcodeSkidComponent,
        PackagingLineScannerComponent,
        PrintTagComponent,
        ReassignSkidComponent,
        SkidsComponent,
        StockSkidProcessingComponent,
        SkidProcessingComponent,
        SkidActionsComponent,
        PackagingLineOverrideActualInputComponent,
        ScaleOverrideComponent,
        ScannerOverrideComponent,
        PrintBacktenderComponent,
    ],
    entryComponents: [SkidActionsComponent],
    exports: [ReassignSkidComponent],
    imports: [SharedModule, SkidRoutingModule, ContextMenuModule, ScalesModule, ScannersModule],
})
export class SkidModule {
    static forRoot(): any {
        return {
            ngModule: SkidModule,
            providers: [MillShiftService, MillShiftPersonnelService, SkidService],
        };
    }
}
