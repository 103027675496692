import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent } from '@mt-ng2/entity-list-module';

import { CustomPackagingService } from '../custom-packaging.service';
import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomPackagingsEntityListConfig } from '../custom-packagings.entity-list-config';

@Component({
    selector: 'app-custom-packagings',
    templateUrl: './custom-packagings.component.html',
})
export class CustomPackagingsComponent implements OnInit {
    customPackagings: ICustomPackaging[];
    parentId: number;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    total: number;
    query = '';
    entityListConfig = new CustomPackagingsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddCustomPackaging = false;

    constructor(
        private customPackagingService: CustomPackagingService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddCustomPackaging = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.parentId = +this.route.snapshot.parent.paramMap.get('customerId');
        this.getCustomPackagings();
    }

    getCustomPackagingsCall(): Observable<HttpResponse<ICustomPackaging[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({
                name: 'ParentId',
                value: this.parentId.toString(),
            }),
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.customPackagingService.get(searchparams);
    }

    getCustomPackagings(): void {
        this.getCustomPackagingsCall().subscribe((answer) => {
            this.customPackagings = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    customPackagingSelected(event: IItemSelectedEvent): void {
        this.router.navigate([event.entity.Id], {relativeTo: this.route});
    }
}
