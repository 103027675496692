<div class="row">
    <div class="col-md-7">
        <h2>Skids</h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <br />
</div>

<entity-list [entities]="skids" [total]="total" [(currentPage)]="currentPage" (onPageChanged)="getSkids()" [entityListConfig]="entityListConfig">
</entity-list>
