import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ICustomerOrderPriceInfo } from '../model/interfaces/customer-order-price-info';

@Injectable()
export class CustomerOrderPriceInfoService extends MetaItemService<ICustomerOrderPriceInfo> {
    constructor(public http: HttpClient) {
        super('CustomerOrderPriceInfoService', 'Info', 'InfoIds', '/salesorders/customerorderpriceinfos', http);
    }

    createCustomerOrderPriceInfo(orderInfoId: number, customerOrderPriceInfo: ICustomerOrderPriceInfo): any {
        return this.http.post('/salesorders/customerorderpriceinfos/' + orderInfoId, customerOrderPriceInfo);
    }
}
