<div *ngIf="!isBillingSheetAdjustment">
    <div class="row">
        <div class="col-md-7">
            <h2>Receipt Item Detail</h2>
        </div>
    </div>
</div>
<div [class.row]="!isBillingSheetAdjustment">
    <div [class.col-md-7]="!isBillingSheetAdjustment">
        <form class="miles-form padded" (ngSubmit)="formSubmitted()">
            <label>Receipt #</label>
            <div class="input-group">
                <input
                    #receiptNumberInput
                    type="text"
                    [(ngModel)]="receipt.Number"
                    (blur)="getReceiptByNumber(receipt.Number)"
                    (keyup.enter)="getReceiptByNumber(receipt.Number)"
                    [attr.disabled]="isBillingSheetAdjustment || receipt?.Id ? '' : null"
                    name="receiptNumber"
                    class="form-control"
                />
                <span class="input-group-btn">
                    <button class="btn btn-default btn-flat" (click)="clearForm()" [disabled]="!canEdit || isBillingSheetAdjustment || isReadOnly" type="button">
                        <i class="fa fa-remove"></i>
                    </button>
                </span>
            </div>
            <br />
            <fieldset>
                <table class="cursor-auto">
                    <tr>
                        <td class="v-align-top font-weight-bold p-r-sm">Sold To:</td>
                        <td class="v-align-top p-b-sm">
                            {{ customer?.CompanyName }}
                            <br />
                            {{ customer?.InvoiceAddress?.Address1 }}
                            <br />
                            <span *ngIf="customer?.InvoiceAddress">{{
                                customer?.InvoiceAddress?.City + ', ' + customer?.InvoiceAddress?.StateCode + ' ' + customer?.InvoiceAddress?.Zip
                            }}</span>
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td class="v-align-top font-weight-bold p-r-sm">Ship To:</td>
                        <td class="v-align-top">
                            {{ shipmentStop?.CustomerShippingAddress?.ShipToName }}
                            <br />
                            {{ shipmentStop?.CustomerShippingAddress?.Address?.Address1 }}
                            <br />
                            <span *ngIf="shipmentStop?.CustomerShippingAddress?.Address">{{
                                shipmentStop?.CustomerShippingAddress?.Address?.City +
                                    ', ' +
                                    shipmentStop?.CustomerShippingAddress?.Address?.StateCode +
                                    ' ' +
                                    shipmentStop?.CustomerShippingAddress?.Address?.Zip
                            }}</span>
                        </td>
                    </tr>
                </table>
            </fieldset>
            <table class="table table-borderless table-condensed middle cursor-auto m-t-sm">
                <tr>
                    <th style="width: 10%" class="text-center">Order #</th>
                    <th style="width: 10%" class="text-right">Quantity</th>
                    <th style="width: 20%" class="text-center">Size</th>
                    <th style="width: 10%" class="text-center">Caliper</th>
                    <th style="width: 25%" class="text-center">Description</th>
                    <th style="width: 12%" class="text-center">Bundles</th>
                    <th style="width: 13%" class="text-center">Pieces</th>
                </tr>
                <tr *ngFor="let detail of receipt.ReceiptDetails; let i = index">
                    <td class="text-center">{{ getFormattedOrderNumber(detail.SalesOrder) }}</td>
                    <td class="text-right">{{ getSkidsPerReceiptDetail(i) }}</td>
                    <td class="text-center">
                        {{ detail.SalesOrder?.ManufacturingOrderDetail?.Width + ' x ' + detail.SalesOrder?.ManufacturingOrderDetail?.Length }}
                    </td>
                    <td class="text-center">{{ detail.SalesOrder?.ManufacturingOrderDetail?.Caliper?.toFixed(3) }}</td>
                    <td class="text-center">{{ detail.SalesOrder?.ManufacturingOrderDetail?.BoardGrade?.Name }}</td>
                    <td class="text-center">
                        <input type="number" [disabled]="!canEdit || isReadOnly" [(ngModel)]="detail.Bundles" [name]="'bundles_' + i" class="form-control" />
                    </td>
                    <td class="text-center">
                        <input type="number" [disabled]="!canEdit || isReadOnly" [(ngModel)]="detail.Pieces" [name]="'pieces_' + i" class="form-control" />
                    </td>
                </tr>
            </table>
            <table class="table table-borderless table-condensed middle cursor-auto">
                <tr>
                    <th style="width: 17%" class="text-center">U/M</th>
                    <th style="width: 15%" class="text-center">Units</th>
                    <th style="width: 30%" class="text-center">Unit Price</th>
                    <th style="width: 18%" class="text-right">Extended Price</th>
                    <th style="width: 20%" class="text-center">Tons &amp; Pricing</th>
                    <th></th>
                </tr>
                <tr *ngFor="let detail of receipt.ReceiptDetails; let i = index">
                    <td class="text-center">
                        <select
                            [disabled]="!canEdit || isReadOnly"
                            [(ngModel)]="detail.OrderPriceTypeId"
                            (ngModelChange)="calculatePricingUnits(detail.OrderPriceTypeId, i)"
                            [name]="'orderPriceTypeId_' + i"
                            class="form-control"
                        >
                            <option *ngFor="let orderPriceType of orderPriceTypes" [ngValue]="orderPriceType.Id">
                                {{ orderPriceType.Name }}
                            </option>
                        </select>
                    </td>
                    <td class="text-center">{{ getUnits(detail) | number: '0.0-4' }}</td>
                    <td class="text-center">
                        <div class="input-group">
                            <span class="input-group-addon">$</span>
                            <input type="number" [disabled]="!canEdit || isReadOnly" [(ngModel)]="detail.PricePer" [name]="'pricePer_' + i" class="form-control" />
                        </div>
                    </td>
                    <td class="text-right">{{ getExtendedPrice(detail) | currency: 'USD': 'symbol': '1.0-4' }}</td>
                    <td class="text-center">
                        <input [disabled]="!canEdit || isReadOnly" [(ngModel)]="detail.QbAccountNumber" [name]="'account_' + i" class="form-control" />
                    </td>
                </tr>
            </table>
            <div class="pull-right">
                <fieldset [disabled]="!canEdit || isReadOnly" class="fieldset-bundles">
                    <label>
                        <input type="radio" name="poundsPerBundle" [value]="50" [(ngModel)]="poundsPerBundle" />
                        50 lb Bundles
                    </label>
                    <br />
                    <label>
                        <input type="radio" name="poundsPerBundle" [value]="40" [(ngModel)]="poundsPerBundle" />
                        40 lb Bundles
                    </label>
                </fieldset>
            </div>
            <div *ngIf="receiptNoteFieldCreated && !isBillingSheetAdjustment">
                <dynamic-field [field]="receiptNoteField" (valueChanges)="receiptNote = $event" (blur)="saveNote()"></dynamic-field>
            </div>
            <div class="clearfix"></div>
            <div class="m-t-sm" *ngIf="!isReadOnly">
                <button
                    type="submit"
                    *ngIf="canEdit && receipt.Id"
                    mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    Class="btn btn-flat btn-success"
                >
                    Save
                </button>
                <button type="button" *ngIf="canEdit" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
            <div class="m-t-sm" *ngIf="isReadOnly">
                <button type="button" class="btn btn-flat btn-default" (click)="printReceipt()">Print</button>
            </div>
        </form>
    </div>
</div>

<modal-wrapper [options]="modalOptions" [autoShow]="false" type="warning" (ready)="auditModal = $event">
    <form #swalForm="ngForm" (ngSubmit)="confirmAuditModal()">
        <h4 class="text-center m-t-0">This receipt has already been invoiced. Please provide a reason for editing the details:</h4>
        <textarea rows="2" class="form-control" required [(ngModel)]="receiptAuditReason" maxlength="200" name="receiptAuditReason"></textarea>
        <div class="m-t-md text-center">
            <button type="submit" class="btn btn-success" [disabled]="!swalForm.form.valid">Confirm</button>
            <button type="button" Class="btn btn-flat btn-default" (click)="closeAuditModal()">Cancel</button>
        </div>
    </form>
</modal-wrapper>
