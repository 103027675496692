import { ChangeDetectorRef, Component } from '@angular/core';
import { FractionsService, IFractionsDynamicFieldOptions } from '@common/services/fractions.service';
import { CustomFormControlComponentBase, DynamicField } from '@mt-ng2/dynamic-form';

@Component({
    template: `
        <dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
            <!-- input element -->
            <input
                #inputElement
                type="text"
                (focus)="onFocus()"
                (blur)="onBlur()"
                [attr.placeholder]="config.placeholder"
                [formControlName]="config.name"
                class="form-control"
                [maxlength]="getMaxLength()"
                [attr.autocomplete]="!config.autoCompleteEnabled ? 'new-password' : null"
            />
            <!-- maxlength elements -->
            <span
                *ngIf="!config.labelLeft && hasMaxLength()"
                [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                class="small pull-right"
                [class.errortext]="hasError('maxlength')"
                [class.inside-box-maxlength]="config.insideBoxValidation"
                >{{ getMaxLengthText() }}</span
            >
            <span
                *ngIf="config.labelLeft"
                [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                class="small pull-right"
                [class.errortext]="hasError('maxlength')"
                [class.inside-box-maxlength]="config.insideBoxValidation"
                >{{ getMaxLengthText() }}</span
            >
            <!-- validation elements -->
            <dynamic-form-validation-message
                [errorMessage]="errorMessage"
                [insideBoxValidation]="config.insideBoxValidation"
            ></dynamic-form-validation-message>
        </dynamic-form-input-wrapper>
    `,
})
export class FractionsDynamicFieldComponent extends CustomFormControlComponentBase {
    get customOptions(): IFractionsDynamicFieldOptions {
        return this.config.customOptions;
    }

    constructor(cdr: ChangeDetectorRef, private fractionsService: FractionsService) {
        super(cdr);
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this._isFocused = false;
            this.convertMeasurementToFraction();
            this.blur.emit();
        }
    }

    onFocus(): void {
        if (this.getControl().enabled) {
            this._isFocused = true;
            this.convertMeasurementToDecimal();
            (this.inputElement?.nativeElement)?.select();
            this.focus.emit();
        }
    }

    convertMeasurementToFraction(): void {
        const control = this.getControl();
        let formValue: number;
        if (this.fractionsService.isValidFraction(control.value)) {
            formValue = +this.fractionsService.FormatAsDecimal(control.value);
        } else {
            formValue = +control.value;
        }

        if (!isNaN(formValue)) {
            control.setValue(this.fractionsService.FormatAsFraction('' + this.fractionsService.roundToNearestFraction(formValue, this.customOptions?.maxFactor)));
        } else {
            control.setValue(0);
        }
    }

    convertMeasurementToDecimal(): void {
        const control = this.getControl();
        const value: string = control.value;
        if (value) {
            control.setValue(this.fractionsService.FormatAsDecimal(value));
        }
    }
}
