import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ITrimSheet } from '../interfaces/trim-sheet';
import { IGroupConfiguration } from '../interfaces/group-configuration';
import { IMillShiftPersonnel } from '../interfaces/mill-shift-personnel';
import { ISectionHeading } from '../interfaces/section-heading';

export interface ITrimSheetDynamicControlsParameters {
    formGroup?: string;
    sectionHeadings?: ISectionHeading[];
    groupConfigurations?: IGroupConfiguration[];
    millShiftPersonnels?: IMillShiftPersonnel[];
}

export class TrimSheetDynamicControls {

    formGroup: string;
    sectionHeadings: ISectionHeading[];
    groupConfigurations: IGroupConfiguration[];
    millShiftPersonnels: IMillShiftPersonnel[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private trimsheet?: ITrimSheet, additionalParameters?: ITrimSheetDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TrimSheet';
        this.sectionHeadings = additionalParameters && additionalParameters.sectionHeadings || undefined;
        this.groupConfigurations = additionalParameters && additionalParameters.groupConfigurations || undefined;
        this.millShiftPersonnels = additionalParameters && additionalParameters.millShiftPersonnels || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.hasOwnProperty('Archived') && this.trimsheet.Archived !== null ? this.trimsheet.Archived : false,
            }),
            Comment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comment',
                name: 'Comment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.trimsheet && this.trimsheet.hasOwnProperty('Comment') && this.trimsheet.Comment !== null ? this.trimsheet.Comment.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.DateCreated || null,
            }),
            EndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time',
                name: 'EndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.EndTime || null,
            }),
            GroupConfigurationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Group Configuration',
                name: 'GroupConfigurationId',
                options: this.groupConfigurations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.GroupConfigurationId || null,
            }),
            MillShiftPersonnelId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Shift Personnel',
                name: 'MillShiftPersonnelId',
                options: this.millShiftPersonnels,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.MillShiftPersonnelId || null,
            }),
            SectionHeadingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Section Heading',
                name: 'SectionHeadingId',
                options: this.sectionHeadings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.SectionHeadingId || null,
            }),
            SetupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Setup',
                name: 'SetupId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.trimsheet && this.trimsheet.SetupId || null,
            }),
            StartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time',
                name: 'StartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheet && this.trimsheet.StartTime || null,
            }),
            WeekOf: new DynamicField({
                formGroup: this.formGroup,
                label: 'Week Of',
                name: 'WeekOf',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.trimsheet && this.trimsheet.WeekOf || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel(
                'Archived',
                this.trimsheet && this.trimsheet.hasOwnProperty('Archived') && this.trimsheet.Archived !== null ? this.trimsheet.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Comment: new DynamicLabel(
                'Comment',
                this.trimsheet && this.trimsheet.hasOwnProperty('Comment') && this.trimsheet.Comment !== null ? this.trimsheet.Comment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.trimsheet && this.trimsheet.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EndTime: new DynamicLabel(
                'End Time',
                this.trimsheet && this.trimsheet.EndTime || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GroupConfigurationId: new DynamicLabel(
                'Group Configuration',
                getMetaItemValue(this.groupConfigurations, this.trimsheet && this.trimsheet.hasOwnProperty('GroupConfigurationId') && this.trimsheet.GroupConfigurationId !== null ? this.trimsheet.GroupConfigurationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MillShiftPersonnelId: new DynamicLabel(
                'Mill Shift Personnel',
                getMetaItemValue(this.millShiftPersonnels, this.trimsheet && this.trimsheet.hasOwnProperty('MillShiftPersonnelId') && this.trimsheet.MillShiftPersonnelId !== null ? this.trimsheet.MillShiftPersonnelId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SectionHeadingId: new DynamicLabel(
                'Section Heading',
                getMetaItemValue(this.sectionHeadings, this.trimsheet && this.trimsheet.hasOwnProperty('SectionHeadingId') && this.trimsheet.SectionHeadingId !== null ? this.trimsheet.SectionHeadingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SetupId: new DynamicLabel(
                'Setup',
                this.trimsheet && this.trimsheet.SetupId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            StartTime: new DynamicLabel(
                'Start Time',
                this.trimsheet && this.trimsheet.StartTime || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            WeekOf: new DynamicLabel(
                'Week Of',
                this.trimsheet && this.trimsheet.WeekOf || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
