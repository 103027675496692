import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ShipmentService } from './shipment.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';

import { OpenShipmentsListComponent } from './open-shipments-list/open-shipments-list.component';
import { ShipmentScheduleComponent } from './shipment-schedule/shipment-schedule.component';
import { ShipmentDetailComponent } from './shipment-detail/shipment-detail.component';
import { ShipmentReceiptComponent } from './shipment-receipt/shipment-receipt.component';

import { ClaimTypes } from '../model/ClaimTypes';
import { ShipmentsListComponent } from './shipments-list/shipments-list.component';
import { TrailerInfoComponent } from './trailer-info/trailer-info.component';
import { TrailersListComponent } from './trailer-list/trailers-list.component';
import { ShipmentSettingsComponent } from './shipment-settings/shipment-settings.component';
import { ShipmentResolver } from '@common/resolvers/shipment.resolver';

const shipmentEntityConfig = {
    claimType: ClaimTypes.Shipments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'shipmentId',
    service: ShipmentService,
    title: 'Shipment Detail',
};

const shipmentRoleGuard = {
    claimType: ClaimTypes.Shipments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Shipments',
};
const shippingSettingsRoleGuard = {
    claimType: ClaimTypes.Shipments_ShippingSettings,
    claimValues: [ClaimValues.FullAccess],
};
const loadPlanningRoleGuard = {
    claimType: ClaimTypes.Shipments_LoadPlanning,
    claimValues: [ClaimValues.FullAccess],
};
const loadFulfillmentRoleGuard = {
    claimType: ClaimTypes.Shipments_LoadFulfillment,
    claimValues: [ClaimValues.FullAccess],
};

const shipmentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        children: [
            {
                canActivate: [AuthGuard],
                component: ShipmentScheduleComponent,
                data: shipmentRoleGuard,
                path: 'schedule',
            },
            {
                canActivate: [AuthGuard],
                component: ShipmentDetailComponent,
                data: shipmentRoleGuard,
                path: 'add',
                resolve: { shipment: ShipmentResolver },
            },
            {
                canActivate: [AuthGuard],
                component: ShipmentDetailComponent,
                data: shipmentRoleGuard,
                path: `edit/:${shipmentEntityConfig.entityIdParam}`,
                resolve: { shipment: ShipmentResolver },
            },
            {
                canActivate: [AuthGuard],
                component: OpenShipmentsListComponent,
                data: loadFulfillmentRoleGuard,
                path: 'active',
            },
            {
                canActivate: [AuthGuard],
                component: ShipmentsListComponent,
                data: loadPlanningRoleGuard,
                path: 'list',
            },
            {
                canActivate: [AuthGuard],
                component: ShipmentReceiptComponent,
                data: shipmentRoleGuard,
                path: 'receipts',
            },
            {
                canActivate: [AuthGuard],
                component: ShipmentSettingsComponent,
                data: shippingSettingsRoleGuard,
                path: 'settings',
            },
        ],
        path: 'shipments',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                canActivate: [AuthGuard],
                component: TrailersListComponent,
                data: shipmentRoleGuard,
                path: '',
            },
            {
                canActivate: [AuthGuard],
                component: TrailerInfoComponent,
                data: shipmentRoleGuard,
                path: ':trailerId',
            },
            {
                canActivate: [AuthGuard],
                component: TrailerInfoComponent,
                data: shipmentRoleGuard,
                path: 'add',
            },
        ],
        path: 'trailers',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(shipmentRoutes)],
})
export class ShipmentRoutingModule {}
