import { OnInit, Component } from '@angular/core';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { HaulerDynamicControls } from '@model/form-controls/hauler.form-controls';
import { HaulerService } from '../hauler.service';

@Component({
    templateUrl: './shipment-settings.component.html',
})
export class ShipmentSettingsComponent implements OnInit {

    haulerDynamicForm = new HaulerDynamicControls().Form;
    canAdd: boolean;
    canRemove: boolean;

    constructor(public haulerService: HaulerService, private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canAdd = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);
        this.canRemove = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);
    }
}
