<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Print Skid Tag</h2>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-5">
        <form class="miles-card padded" (ngSubmit)="formSubmitted()">
            <label for="finalBarcode">Skid ID</label>
            <input
                type="text"
                #finalBarcodeInput
                name="finalBarcode"
                [(ngModel)]="finalBarcode"
                class="form-control"
                required
                placeholder="Scan or enter a skid ID."
            />
            <br />
            <button class="btn btn-secondary" type="submit"><i class="fa fa-fw fa-print"></i> Print Tag</button>
        </form>
    </div>
</div>
