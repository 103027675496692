import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ISalesOrder } from '../interfaces/sales-order';
import { IManufacturingOrderDetail } from '../interfaces/manufacturing-order-detail';
import { IOrderInfo } from '../interfaces/order-info';
import { IOrderStatus } from '../interfaces/order-status';
import { IOrderType } from '../interfaces/order-type';

export interface ISalesOrderDynamicControlsParameters {
    formGroup?: string;
    manufacturingOrderDetails?: IManufacturingOrderDetail[];
    orderInfos?: IOrderInfo[];
    orderTypes?: IOrderType[];
    orderStatuses?: IOrderStatus[];
}

// tslint:disable:object-literal-sort-keys
export class SalesOrderDynamicControls {

    formGroup: string;
    manufacturingOrderDetails?: IManufacturingOrderDetail[];
    orderInfos?: IOrderInfo[];
    orderTypes?: IOrderType[];
    orderStatuses?: IOrderStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private salesorder?: ISalesOrder, additionalParameters?: ISalesOrderDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'SalesOrder';
        this.manufacturingOrderDetails = additionalParameters && additionalParameters.manufacturingOrderDetails || undefined;
        this.orderInfos = additionalParameters && additionalParameters.orderInfos || undefined;
        this.orderTypes = additionalParameters && additionalParameters.orderTypes || undefined;
        this.orderStatuses = additionalParameters && additionalParameters.orderStatuses || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.hasOwnProperty('Archived') && this.salesorder.Archived != null ? this.salesorder.Archived : false,
            }),
            CreditAuthDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Auth Date',
                name: 'CreditAuthDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.CreditAuthDate || null,
            }),
            CreditAuthStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Auth Status',
                name: 'CreditAuthStatusId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.CreditAuthStatusId || null,
            }),
            ManufacturingOrderDetailId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Manufacturing Order Detail',
                name: 'ManufacturingOrderDetailId',
                options: this.manufacturingOrderDetails,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.ManufacturingOrderDetailId || null,
            }),
            OrderInfoId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Info',
                name: 'OrderInfoId',
                options: this.orderInfos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.OrderInfoId || null,
            }),
            OrderStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Status',
                name: 'OrderStatusId',
                options: this.orderStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.OrderStatusId || null,
            }),
            OrderTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Type',
                name: 'OrderTypeId',
                options: this.orderTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.OrderTypeId || null,
            }),
            Total: new DynamicField({
                formGroup: this.formGroup,
                label: 'Total',
                name: 'Total',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.Total || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.salesorder && this.salesorder.hasOwnProperty('Archived') && this.salesorder.Archived != null ? this.salesorder.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreditAuthDate: new DynamicLabel({
                label: 'Credit Auth Date',
                value: this.salesorder && this.salesorder.CreditAuthDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CreditAuthStatusId: new DynamicLabel({
                label: 'Credit Auth Status',
                value: this.salesorder && this.salesorder.CreditAuthStatusId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ManufacturingOrderDetailId: new DynamicLabel({
                label: 'Manufacturing Order Detail',
                value: getMetaItemValue(this.manufacturingOrderDetails as unknown as IMetaItem[], this.salesorder && this.salesorder.hasOwnProperty('ManufacturingOrderDetailId') ? this.salesorder.ManufacturingOrderDetailId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OrderInfoId: new DynamicLabel({
                label: 'Order Info',
                value: getMetaItemValue(this.orderInfos as unknown as IMetaItem[], this.salesorder && this.salesorder.hasOwnProperty('OrderInfoId') ? this.salesorder.OrderInfoId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OrderStatusId: new DynamicLabel({
                label: 'Order Status',
                value: getMetaItemValue(this.orderStatuses as unknown as IMetaItem[], this.salesorder && this.salesorder.hasOwnProperty('OrderStatusId') ? this.salesorder.OrderStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OrderTypeId: new DynamicLabel({
                label: 'Order Type',
                value: getMetaItemValue(this.orderTypes as unknown as IMetaItem[], this.salesorder && this.salesorder.hasOwnProperty('OrderTypeId') ? this.salesorder.OrderTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Total: new DynamicLabel({
                label: 'Total',
                value: this.salesorder && this.salesorder.Total || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
        };

    }
}
