import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';

import { ISalesOrder } from '../../model/interfaces/sales-order';
import { SalesOrdersEntityListConfig } from './sales-orders-list.entity-list-config';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

// local sevices
import { SalesOrderService } from '../sales-order.service';
import { OrderStatusService } from '../order-status.service';
import { OrderTypeService } from '../order-type.service';
import { OrderTypeIds } from '../../model/OrderTypes';
import { BoardGradeService } from '../board-grade.service';
import { LiningService } from '../lining.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { IPagingHotkeyOptions } from '@common/directives/paging-hotkeys.directive';

@Component({
    selector: 'app-sales-orders',
    templateUrl: './sales-orders-list.component.html',
})
export class SalesOrdersListComponent implements OnInit {
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    searchControl = new FormControl();
    salesOrders: ISalesOrder[];
    currentPage = 1;
    total: number;
    orderType: MtSearchFilterItem[] = [];
    status: MtSearchFilterItem[] = [];
    orderDateStart: Date = null;
    orderDateEnd: Date = null;
    orderTypeId: number;
    OrderTypeIds = OrderTypeIds;

    entityListConfig = new SalesOrdersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    ordersPerPage = 25;
    boardGrades: MtSearchFilterItem[] = [];
    linings: MtSearchFilterItem[] = [];
    mainQuery = '';
    shipToNameQuery = '';
    widthQuery = '';
    lengthQuery = '';
    includeLining = false;
    includeConverting = false;
    includeBridgeView = false;
    includeFSC = false;
    minCaliperQuery = null;
    maxCaliperQuery = null;
    liningCount: number;
    isManufacturingOrder = true;
    includeArchived = false;
    canViewArchivedOrders: boolean;

    pagingHotkeyOptions: IPagingHotkeyOptions;

    constructor(
        private salesOrderService: SalesOrderService,
        private orderStatusService: OrderStatusService,
        private orderTypeService: OrderTypeService,
        private location: Location,
        private boardGradeService: BoardGradeService,
        private liningService: LiningService,
        private claimsService: ClaimsService,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.canViewArchivedOrders = this.claimsService.hasClaim(ClaimTypes.Orders_CanViewArchivedOrders, [ClaimValues.FullAccess]);
        this.orderStatusService.getItems().subscribe((answer) => (this.status = this.mapMtSearchFilterItems(answer)));

        this.boardGradeService.getItems().subscribe((boardGrades) => (this.boardGrades = this.mapMtSearchFilterItems(boardGrades)));

        this.liningService.getItems().subscribe((linings) => {
            this.linings = this.mapMtSearchFilterItems(linings);
            this.liningCount = linings.length;
        });

        const path = this.location.path();
        this.orderTypeService.getItems().subscribe((answer) => {
            answer.map((type) => {
                if (path.endsWith('stock') && type.Id === OrderTypeIds.Stock) {
                    this.orderTypeId = OrderTypeIds.Stock;
                    this.isManufacturingOrder = false;
                } else if (path.endsWith('manufacturing') && type.Id === OrderTypeIds.Manufacturing) {
                    this.orderTypeId = OrderTypeIds.Manufacturing;
                    this.isManufacturingOrder = true;
                }
                this.orderType.push(new MtSearchFilterItem(type, true));
            });
            this.getOrders();
        });

        this.buildPagingHotkeyOptions();

        this.cdr.detectChanges();
    }

    private buildPagingHotkeyOptions(): void {
        this.pagingHotkeyOptions = {
            actionOnNext: () => {
                if (this.currentPage < this.total / this.ordersPerPage) {
                    this.currentPage++;
                    this.getOrders();
                }
            },
            actionOnPrev: () => {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.getOrders();
                }
            },
            nextKey: ['alt', 'n'],
            prevKey: ['alt', 'p'],
        };
    }

    ngAfterContentInit(): void {
        if (this.searchBar) {
            const nativeElement = this.searchBar.searchInputElement.nativeElement;

            setTimeout(() => {
                this.renderer.selectRootElement(nativeElement).focus();
            }, 0);
        }
    }

    private mapMtSearchFilterItems(entities: any[]): MtSearchFilterItem[] {
        return entities.map((entity) => new MtSearchFilterItem(entity, false));
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private getDateValueForParams(date: Date): string {
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return `${month}-${day}-${year}`;
    }

    private buildSearch(): ExtraSearchParams[] {
        let includeCustomLinings = false;
        const selectedOrderTypeIds: number[] = this.getSelectedFilters(this.orderType);
        const selectedOrderStatusIds: number[] = this.getSelectedFilters(this.status);
        const selectedBoardGradeIds: number[] = this.getSelectedFilters(this.boardGrades);
        const selectedLiningIds: number[] = this.getSelectedFilters(this.linings);
        if (selectedLiningIds.length === this.liningCount) {
            includeCustomLinings = true;
        }
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.orderDateStart) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'OrderDateStart',
                    value: this.getDateValueForParams(this.orderDateStart),
                }),
            );
        }
        if (this.orderDateEnd) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'OrderDateEnd',
                    value: this.getDateValueForParams(this.orderDateEnd),
                }),
            );
        }
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ShipToName',
                value: this.shipToNameQuery,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'Width',
                value: this.widthQuery,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'Length',
                value: this.lengthQuery,
            }),
        );
        if (this.minCaliperQuery != null && !isNaN(this.minCaliperQuery)) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'MinCaliper',
                    value: this.minCaliperQuery.toFixed(3),
                }),
            );
        }
        if (this.maxCaliperQuery != null && !isNaN(this.maxCaliperQuery)) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'MaxCaliper',
                    value: this.maxCaliperQuery.toFixed(3),
                }),
            );
        }
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeCustomLinings',
                value: includeCustomLinings.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeLining',
                value: this.includeLining.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeConverting',
                value: this.includeConverting.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeBridgeView',
                value: this.includeBridgeView.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeFSC',
                value: this.includeFSC.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'OrderTypeIds',
                valueArray: selectedOrderTypeIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'OrderStatusIds',
                valueArray: selectedOrderStatusIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'BoardGradeIds',
                valueArray: selectedBoardGradeIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'LiningIds',
                valueArray: selectedLiningIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: this.includeArchived.toString(),
            }),
        );
        return _extraSearchParams;
    }

    orderDateSelectionChanged(value: ISearchFilterDaterangeValue): void {
        this.currentPage = 1;
        this.orderDateStart = value.startDate;
        this.orderDateEnd = value.endDate;
        this.getOrders();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getOrders();
    }

    getOrders(): void {
        const search = this.mainQuery;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.ordersPerPage,
            take: this.ordersPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.salesOrderService.get(searchparams).subscribe((answer) => {
            this.salesOrders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.mainQuery = query;
        this.getOrders();
    }

    searchOnShipToName(query: string): void {
        this.currentPage = 1;
        this.shipToNameQuery = query;
        this.getOrders();
    }

    searchOnWidth(query: string): void {
        this.currentPage = 1;
        this.widthQuery = query;
        this.getOrders();
    }

    searchOnLength(query: string): void {
        this.currentPage = 1;
        this.lengthQuery = query;
        this.getOrders();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOrders();
    }

    orderSelected(event: IItemSelectedEvent): void {
        if (event.entity && event.entity.OrderTypeId === OrderTypeIds.Manufacturing) {
            window.open(`/#/salesorders-manufacturing/${event.entity.Id}`);
        } else if (event.entity && event.entity.OrderTypeId === OrderTypeIds.Stock) {
            window.open(`/#/salesorders-stock/${event.entity.Id}`);
        }
    }
}
