import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IGeneralLedgerCode } from '../model/interfaces/general-ledger-code';

@Injectable()
export class GeneralLedgerService extends MetaItemService<IGeneralLedgerCode> {
    constructor(public http: HttpClient) {
        super('GeneralLedgerService', 'General Ledger Code', 'GeneralLedgerIds', '/salesorders/generalledgercodes', http);
    }
}
