import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ILining } from '../model/interfaces/lining';
import { IWarehouseLocation } from '../model/interfaces/warehouse-location';

@Injectable()
export class WarehouseLocationService extends MetaItemService<IWarehouseLocation> {
    constructor(public http: HttpClient) {
        super('WarehouseLocationService', 'WarehouseLocation', 'WarehouseLocationIds', '/salesorders/warehouse-locations', http);
    }
}
