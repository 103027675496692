export enum OrderStatusNames {
    Open = 'Open',
    PendingReview = 'Pending Review',
    Approved = 'Approved',
    CreditHold = 'Credit Hold',
}

export enum OrderStatusIds {
    Open = 1,
    PendingReview = 2,
    Approved = 3,
    CreditHold = 4,
}
