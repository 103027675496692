import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IReceiptDetail } from '@model/interfaces/receipt-detail';
import { IShipmentReceiptOptions } from '../../shipment.service';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { ISkid } from '@model/interfaces/skid';

@Component({
  selector: 'app-shipment-receipt-details',
  styleUrls: ['./shipment-receipt-details.component.css'],
  templateUrl: './shipment-receipt-details.component.html',
})
export class ShipmentReceiptDetailsComponent implements OnInit {
    @Input() newReceiptDetailsText: string;
    @Output() newReceiptDetailsTextChange = new EventEmitter();

    @Input() receiptDetail: IReceiptDetail;
    @Input() printModal: IModalWrapperApi;
    @Input() customerName: string;
    @Input() shipmentReceiptOptions: IShipmentReceiptOptions;
    @Input() currentSkids: ISkid[];

    private currentDefaultText: string;

    constructor() { }

    ngOnInit(): void {
        this.setCurrentDefaultText();
    }

    overwriteTextDefault(): string {
        if (this.receiptDetail.ReceiptTextOverwrite && this.receiptDetail.ReceiptTextOverwrite.length > 0) {
            return this.receiptDetail.ReceiptTextOverwrite;
        }
        return this.currentDefaultText;
    }

    closeReceiptDetailsModal(): void {
        this.printModal.close();
    }

    submitChanges(): void {
        this.closeReceiptDetailsModal();
        let value = (<HTMLInputElement>document.getElementById('textOverwrite')).value;
        let receiptText = this.receiptDetail.ReceiptTextOverwrite ?? '';
        if ((value !== receiptText && !this.isDefaultText(value)) || !value || value === '') {
            this.emitChangesWithConditions(receiptText, this.currentDefaultText, value);
        } else if (this.isDefaultText(value)) {
            this.newReceiptDetailsTextChange.emit('');
        }
    }

    setCurrentDefaultText(): void {
        this.currentDefaultText = this.createDefaultText();
    }

    private emitChangesWithConditions(receiptText: string, defaultText: string, value: string): void {
        if (this.isDefaultText(receiptText)) {
            this.newReceiptDetailsTextChange.emit('');
        } else {
            this.newReceiptDetailsTextChange.emit(value);
        }
        if (!value || value === '') {
            (<HTMLInputElement>document.getElementById('textOverwrite')).value = defaultText;
        }
    }

    private isDefaultText(value: string): boolean {
        return this.currentDefaultText === value;
    }

    private createDefaultText(): string {
        let text = '';
        let total = this.currentSkids.length;
        let currentSkid = 1;
        this.currentSkids.forEach((skid) => {
            text += `#${ skid.SkidNumber }`;
            text += this.receiptDetail.ShowPieces ? '@' + skid.ActualCount + 'PCS' : '';
            text += this.receiptDetail.ShowWeight ? '@' + (skid.ActualWeight - skid.TareWeightBottom - skid.TareWeightTop) + '#' : '';
            text += this.receiptDetail.ShowBundles ? '@' + (skid.Count > 0 ? skid.ActualCount / skid.Count : 0 ).toFixed(2) + 'BDLS' : '';
            text += total === currentSkid ? '' : ',';
            currentSkid++;
        });
        return text;
    }

}
