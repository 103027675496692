import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ManagedListComponent } from '@mt-ng2/managed-list-control';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'custom-mt-managed-list',
    styles: [
        `
            .nopadding {
                padding: 0 !important;
            }
            .sortable-row-margins {
                margin-left: 25px !important;
            }
            .removable-row-margins {
                margin-right: 32px !important;
            }
            .move-handle {
                cursor: move;
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            .remove-button {
                padding-left: 6px !important;
                padding-right: 6px !important;
            }
            .second-ellipsis {
                position: relative;
                right: 17px;
                margin-right: -17px;
            }
        `,
    ],
    template: `
        <div class="miles-card padded" [formGroup]="form">
            <div class="form-group" [formArrayName]="cleanTitle">
                <h4>{{ title }}</h4>
                <div *ngIf="fields.length === 1" [style.margin-top]="'20px'"></div>
                <div
                    *ngIf="fields.length > 1"
                    class="container-fluid nopadding"
                    [class.sortable-row-margins]="sortable"
                    [class.removable-row-margins]="canRemove"
                >
                    <div class="row-fluid">
                        <div *ngFor="let field of fields; let i = index" class="{{ getColClassForIndex(i) }} nopadding">
                            <label [style.padding-left]="'12px'">{{ getLabel(field) }}</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="!sortable">
                    <div *ngFor="let item of currentFormArray.controls; let i = index" [formGroupName]="i">
                        <span class="pull-right" *ngIf="canRemove || !item.value.Id">
                            <span class="btn btn-default btn-flat remove-button" (click)="delete(i)">
                                <span class="fa fa-fw fa-remove"></span>
                            </span>
                        </span>

                        <div class="container-fluid nopadding" [class.removable-row-margins]="canRemove || !item.value.Id">
                            <div class="row-fluid">
                                <div *ngFor="let field of fields; let i = index" class="{{ getColClassForIndex(i) }} nopadding">
                                    <dynamic-field [field]="getField(item, field)" [form]="item" [overrideForm]="true"></dynamic-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasDuplicateItems()" class="small errortext pull-right" [style.position]="'relative'">
                    Duplicate items are not allowed
                </div>
                <div class="fab-wrap" *ngIf="canAdd">
                    <button type="button" class="btn btn-default btn-fab-md btn-fab-center" (click)="create()">
                        <span class="fa fa-plus"></span>
                    </button>
                </div>
                <br />
                <button type="submit" class="btn btn-success" (click)="update()">Save</button>
            </div>
        </div>
    `,
})
export class CustomMtManagedListComponent extends ManagedListComponent {
    @Input() customColWidths: number[] = [];

    constructor(private formbuilder: FormBuilder, private cdr: ChangeDetectorRef) {
        super(formbuilder);
    }

    getColClassForIndex(i: number): string {
        if (this.customColWidths.length <= 0) { return this.getColClass(); }
        if (!this.customColWidths[i]) { return this.getColClass(); }
        return `col-md-${this.customColWidths[i]}`;
    }

    delete(index: number): void {
        this.formArray.removeAt(index);
        this.formArray.updateValueAndValidity();
    }
}
