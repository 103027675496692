import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ShipmentService, IOpenShipment } from '../shipment.service';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';

import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IShipment } from '../../model/interfaces/shipment';
import { PrintService } from '../../common/services/print.service';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { IAssignTrailerOutput } from '../assign-trailer/assign-trailer.component';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'open-shipments-list',
    styles: [
        `
            fieldset {
                display: inline-block;
                vertical-align: top;
                padding-top: 4px;
            }
            ,
            .dropdown-menu > li > a {
                padding: 6px 20px;
            }
        `,
    ],
    templateUrl: './open-shipments-list.component.html',
})
export class OpenShipmentsListComponent implements OnInit {
    openShipments: IOpenShipment[];
    total = 0;
    currentPage = 1;
    pageSize = 10;
    selectedShipmentId: number;
    selectedShipment: IShipment;
    selectedOpenShipment: IOpenShipment;
    salesOrderIds: number[];
    reportModalInputOptions = {
        1: 'Shipping Card - Summary',
        2: 'Shipping Card - Detail',
        3: 'Gather Skids',
    };

    openLoadModal: IModalWrapperApi;
    openLoadModalOptions: IModalOptions = {
        customClass: {
            closeButton: 'popup-btn',
            popup: 'popup-form',
       },
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
    };

    loadSkidModal: IModalWrapperApi;
    loadSkidModalOptions: IModalOptions = {
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
    };

    reassignSkidModal: IModalWrapperApi;
    reassignSkidModalOptions: IModalOptions = {
        allowOutsideClick: false,
        customClass: {
            closeButton: 'popup-btn',
            popup: 'popup-form',
       },
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
    };

    shipmentDetailModal: IModalWrapperApi;
    shipmentDetailModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: true,
        width: 800,
    };

    assignTrailerModal: IModalWrapperApi;
    assignTrailerModalOptions: IModalOptions = {
        allowOutsideClick: true,
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: false,
        title: 'Assign Load To Trailer',
    };

    @ViewChild('shipmentContextMenu') public shipmentContextMenu: ContextMenuComponent;

    constructor(
        private shipmentService: ShipmentService,
        private contextMenuService: ContextMenuService,
        private notificationsService: NotificationsService,
        private vcr: ViewContainerRef,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.bindGrid();
    }

    bindGrid(): void {
        const searchEntity: IEntitySearchParams = {
            extraParams: null,
            order: null,
            orderDirection: null,
            query: null,
            skip: (this.currentPage - 1) * this.pageSize,
            take: this.pageSize,
        };

        const searchParams = new SearchParams(searchEntity);

        this.shipmentService.getOpenShipments(searchParams).subscribe((answer) => {
            this.openShipments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    showOpenLoadDialog(): void {
        this.openLoadModal.show();
    }

    closeOpenLoadDialog(bindGrid = false): void {
        this.openLoadModal.close();
        if (bindGrid) {
            this.bindGrid();
        }
    }

    // pop the swal that allows them to assign a load to a trailer
    showTrailersDialog(shipment: IOpenShipment): void {
        this.selectedOpenShipment = shipment;
        this.selectedShipmentId = shipment.ShipmentId;
        this.assignTrailerModal.show();
    }

    showLoadSkidDialog(shipment: IOpenShipment): void {
        this.selectedShipmentId = shipment.ShipmentId;
        this.loadSkidModal.show();
    }

    closeLoadSkidDialog(): void {
        this.loadSkidModal.close();
    }

    showReassignSkidDialog(shipment: IOpenShipment): void {
        this.salesOrderIds = shipment.SalesOrderIds;
        this.selectedShipmentId = shipment.ShipmentId;
        this.reassignSkidModal.show();
    }

    closeReassignSkidDialog(): void {
        this.reassignSkidModal.close();
    }

    openContextMenu($event: MouseEvent, item: any): void {
        this.contextMenuService.show.next({
            contextMenu: this.shipmentContextMenu,
            event: $event,
            item: item,
        });
        $event.preventDefault();
        $event.stopPropagation();
    }

    closeLoad(shipment: IOpenShipment): void {
        this.modalService
            .showModal({
                html:
                    'Are you sure you want to close this load?<br />' +
                    (shipment.SkidsLoaded
                        ? `${shipment.SkidsLoaded !== shipment.NumberOfSkids ? 'Only ' : ''} <span class='font-weight-bold'>${shipment.SkidsLoaded
                        }</span> out of <span class='font-weight-bold'>${shipment.NumberOfSkids}</span> skids have been loaded.`
                        : '<span class="font-weight-bold font-italic text-danger">No skids have been loaded.</span>'),
                showCancelButton: true,
                title: `Close Load #${shipment.ShipmentId}`,
                type: 'warning',
            })
            .subscribe((result) => {
                if (!result.value) {
                    return;
                }

                this.shipmentService.closeShipment(shipment.ShipmentId).subscribe((answer) => {
                    this.notificationsService.success(`Load #${shipment.ShipmentId} successfully closed.`);

                    this.openShipments.splice(this.openShipments.indexOf(shipment), 1);
                });
            });
    }

    printLoad(shipment: IOpenShipment): void {
        this.shipmentService.printShipment(shipment.ShipmentId).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    allocateToDloc(shipment: IOpenShipment): void {
        this.shipmentService.allocateToDloc(shipment.ShipmentId).subscribe((answer) => {
            this.notificationsService.success(`Successfully allocated load #${shipment.ShipmentId} to DLOC+.`);
            PrintService.printPdf(answer);
            shipment.AllocatedToDloc = true;
        });
    }

    quickLoad(shipment: IOpenShipment): void {
        this.shipmentService.quickLoad(shipment.ShipmentId).subscribe((answer) => {
            this.bindGrid();
            this.notificationsService.success(`Successfully quick loaded #${shipment.ShipmentId}.`);
        });
    }

    shipmentSelected(shipmentId: number): void {
        if (shipmentId) {
            this.shipmentService.getById(shipmentId).subscribe((answer) => {
                this.selectedShipment = answer;
                this.shipmentDetailModal.show();
            });
        }
    }

    shipSkid(skidId: number): void {
        this.shipmentService.addSkidToShipment(this.selectedShipmentId, skidId).subscribe((answer) => {
            this.notificationsService.success(`Skid successfully added to load #${this.selectedShipmentId}.`);
            this.bindGrid();
        });
    }

    isAllocateToDlocEnabled = (item: IOpenShipment): boolean => {
        return item.AllocatedToDloc === false;
    }

    isPrintEnabled = (item: IOpenShipment): boolean => {
        return item.SkidsLoaded > 0;
    }

    assignTrailer(shipmentId: number, trailerId: number, haulerId: number, finalizeCallback: () => void): void {
        forkJoin(
            [this.shipmentService.updatePartial(
                {
                    HaulerId: haulerId,
                    TrailerId: trailerId,
                },
                shipmentId,
            ),
        this.shipmentService.updateShipmentStopTrailerReceipts(shipmentId, trailerId)])
            .pipe(finalize(() => {
                finalizeCallback();
                this.assignTrailerModal.close();
            }))
            .subscribe((answer) => {
                this.notificationsService.success(`Load #${shipmentId} updated successfully.`);
                this.bindGrid();
            });
    }

    showLoadNumberDialog(): void {
        this.modalService.showModal({
            input: 'text',
            showCancelButton: true,
            showCloseButton: true,
            showConfirmButton: true,
            title: 'Enter Load #',
        }).subscribe((res) => {
            if (res.isConfirmed && +res.value) {
                let loadNumber = +res.value;
                this.validateLoadNumber(loadNumber);
            }
            if (!+res.value && !res.isDismissed) {
                this.displayInavlidLoadNumberModal();
            }
        });
    }

    private validateLoadNumber(loadNumber: number): void {
        // Validate that the entered number corresponds to an actual shipment
        this.shipmentService.shipmentExists(loadNumber).subscribe((answer) => {
            if (answer) {
                this.chooseShipmentReportType(loadNumber);
            } else {
                this.displayInavlidLoadNumberModal();
            }
        });
    }

    private chooseShipmentReportType(loadNumber: number): void {
        this.modalService.showModal({
            input: 'select',
            inputOptions: this.reportModalInputOptions,
            showCancelButton: true,
            showCloseButton: true,
            showConfirmButton: true,
            text: 'Select a Report to Print',
            title: 'Select Report',
            type: 'question',
        }).subscribe((res) => {
            if (res.isConfirmed && +res.value) {
                this.printShipmentReport(loadNumber, +res.value);
            }
        });
    }

    private printShipmentReport(loadNumber: number, shipmentReportTypeId: number): void {
        this.shipmentService.getShipmentReportPdf(loadNumber, shipmentReportTypeId).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    private displayInavlidLoadNumberModal(): void {
        this.modalService.showModal({
            text: 'Please enter a valid Load #',
            title: 'Invalid Load #',
            type: 'error',
        }).subscribe((res) => { });
    }
}
