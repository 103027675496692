import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { customPackagingPathSlugs } from '../../../nav/default-routes/custom-packaging-paths';
import { CustomPackagingService } from '../custom-packaging.service';
import { ICustomPackaging } from '@model/interfaces/custom-packaging';

@Component({
    selector: 'app-custom-packaging-detail',
    templateUrl: './custom-packaging-detail.component.html',
})
export class CustomPackagingDetailComponent implements OnInit {
    id: number;
    canEdit: boolean;
    canAdd: boolean;
    customPackaging: ICustomPackaging;

    constructor(
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
        private customPackagingService: CustomPackagingService,
        private notificationsService: NotificationsService,
    ) {    }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.id = +this.route.snapshot.paramMap.get(customPackagingPathSlugs.entityIdParam);
        if (this.id > 0) {
            this.customPackagingService.getById(this.id).subscribe((customPackaging) => {
                if (!customPackaging) {
                    this.notificationsService.error('Invalid Custom Packaging Id.');
                    this.close();
                } else {
                    this.customPackaging = customPackaging;
                }
            });
        } else {
            this.notificationsService.error('Invalid Custom Packaging Id.');
            this.close();
        }
    }

    close(): void {
        this.router.navigate(['../'], {relativeTo: this.route});
    }
}
