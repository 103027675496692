import { Injectable } from '@angular/core';
import { NumberFormatDisplayFunction, NumericControlTypes } from '@mt-ng2/numeric-control';

@Injectable()
export class FormatService {
    public static phone(phone: string, ext: string = null): string {
        let cleaned = phone.replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}` + (ext ? ' Ext ' + ext : '');
        }
        return null;
    }

    public static noCommasNumberFormatDisplayFunction(): NumberFormatDisplayFunction {
        return (value: number, type: NumericControlTypes, scale: number) => {
            return value ? value.toString() : '0';
        };

    }
}
