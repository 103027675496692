import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IPricingChargePerType } from '../model/interfaces/pricing-charge-per-type';

@Injectable()
export class PricingChargePerTypeService extends StaticMetaItemService<IPricingChargePerType> {
    constructor(public http: HttpClient) {
        super('PricingChargePerTypeService', 'Type', 'TypeIds', '/pricingchargepertypes', http);
    }
}
