import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { ITestStack } from '../interfaces/test-stack';

export interface ITestStackDynamicControlsParameters {
    formGroup?: string;
}

export class TestStackDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private teststack?: ITestStack, additionalParameters?: ITestStackDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TestStack';

        this.Form = {
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.teststack && this.teststack.Caliper || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.teststack && this.teststack.hasOwnProperty('Name') && this.teststack.Name !== null ? this.teststack.Name.toString() : '',
            }),
            Ply: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ply',
                name: 'Ply',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.teststack && this.teststack.Ply || null,
            }),
            SkidHeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skid Height',
                name: 'SkidHeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.teststack && this.teststack.SkidHeight || null,
            }),
            Target: new DynamicField({
                formGroup: this.formGroup,
                label: 'Target',
                name: 'Target',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.teststack && this.teststack.Target || null,
            }),
        };

        this.View = {
            Caliper: new DynamicLabel(
                'Caliper',
                this.teststack && this.teststack.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.teststack && this.teststack.hasOwnProperty('Name') && this.teststack.Name !== null ? this.teststack.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ply: new DynamicLabel(
                'Ply',
                this.teststack && this.teststack.Ply || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SkidHeight: new DynamicLabel(
                'Skid Height',
                this.teststack && this.teststack.SkidHeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Target: new DynamicLabel(
                'Target',
                this.teststack && this.teststack.Target || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
