import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPosition,
    LabelPositions,
    IDynamicFieldType,
    ITimepickerOptions,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { CuttingTeamLogDynamicControls, ICuttingTeamLogDynamicControlsParameters } from '../form-controls/cutting-team-log.form-controls';
import { ICuttingTeamLog } from '../interfaces/cutting-team-log';
import { IBoardType } from '../interfaces/board-type';
import { BoardTypeIds } from '@model/BoardTypes';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { FormatService } from '@common/services/format.service';
import { ScrollableTypeAheadComponent } from '@common/components/scrollable-type-ahead/scrollable-type-ahead.component';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';

export class CuttingTeamLogDynamicControlsPartial extends CuttingTeamLogDynamicControls {
    constructor(convertingDataEntryUsers: IConvertingDataEntryUser[], cuttingteamlogPartial?: ICuttingTeamLog, additionalParameters?: ICuttingTeamLogDynamicControlsParameters) {
        super(cuttingteamlogPartial, additionalParameters);

        (<DynamicLabel>this.View.BdlSk).label = 'b/s';
        (<DynamicField>this.Form.BdlSk).labelHtml = '<label>b/s</label>';

        (<DynamicLabel>this.View.BoardGrade).label = 'Grade';
        (<DynamicField>this.Form.BoardGrade).labelHtml = '<label>Grade</label>';

        (<DynamicLabel>this.View.ShiftCode).label = 'Shift';
        (<DynamicField>this.Form.ShiftCode).labelHtml = '<label>Shift</label>';

        (<DynamicLabel>this.View.MachineNumber).label = 'Machine #';
        (<DynamicField>this.Form.MachineNumber).labelHtml = '<label>Machine #</label>';

        (<DynamicField>this.Form.Note).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });

        (<DynamicField>this.Form.Width).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Length).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Skids).component = FractionsDynamicFieldComponent as any;

        (<DynamicField>this.Form.Caliper).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.Caliper).validators.max = 0.999;

        let dynamicFieldTimeConfig: IDynamicFieldType = {
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Timepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        };

        (<DynamicField>this.Form.TimeOff).type = new DynamicFieldType(dynamicFieldTimeConfig);
        (<DynamicField>this.Form.TimeOn).type = new DynamicFieldType(dynamicFieldTimeConfig);

        this.Form.SalesOrderId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sales Order',
            name: 'SalesOrderId',
            options: this.salesOrders,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                numericFunctions: {
                    numberFormatDisplay: FormatService.noCommasNumberFormatDisplayFunction(),
                },
            }),
            validation: null,
            validators: { 'required': false },
            value: cuttingteamlogPartial && cuttingteamlogPartial.SalesOrderId || null,
        });

        (<DynamicField>this.Form.BoardTypeId).value = cuttingteamlogPartial?.BoardTypeId || BoardTypeIds.MilledBoard;

        this.Form.Cutter = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Cutter',
            name: 'Cutter',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id:  user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: cuttingteamlogPartial && cuttingteamlogPartial.hasOwnProperty('Cutter') && cuttingteamlogPartial.Cutter !== null ? cuttingteamlogPartial.Cutter.toString() : '',
        });

        this.Form.Finisher = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Finisher',
            name: 'Finisher',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id: user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: cuttingteamlogPartial && cuttingteamlogPartial.hasOwnProperty('Finisher') && cuttingteamlogPartial.Finisher !== null ? cuttingteamlogPartial.Finisher.toString() : '',
        });
    }
}
