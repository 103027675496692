import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IReceiptSizeTotal } from '../model/interfaces/receipt-size-total';

@Injectable()
export class ReceiptSizeTotalService extends StaticMetaItemService<IReceiptSizeTotal> {
    constructor(public http: HttpClient) {
        super('ReceiptSizeTotalService', 'Size Total', 'Size Total Id', '/receipt-size-totals', http);
    }
}
