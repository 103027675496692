import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IHauler } from '../model/interfaces/hauler';
import { MetaItemService } from '@mt-ng2/base-service';

@Injectable()
export class HaulerService extends MetaItemService<IHauler> {
    constructor(public http: HttpClient) {
        super('HaulerService', 'Hauler', 'HaulerIds', '/haulers', http);
    }
}
