import { Injectable } from '@angular/core';

@Injectable()
export class FileService {
    public static save(data: any, outputFileName: string, mimeType = 'application/txt', dataIsBase64 = false): void {
        if (!mimeType) {
            mimeType = 'application/txt';
        }

        let outputData = data;

        if (dataIsBase64) {
            // convert the base64 string to a byte array
            let byteCharacters = atob(data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            outputData = new Uint8Array(byteNumbers);
        }

        // using a blob of the byte array, force the download by creating a
        // dummy link and simulating a click on it
        let blob = new Blob([outputData], { type: mimeType });
        let downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = (window.URL || (<any>window).webkitURL).createObjectURL(blob);
        downloadLink.download = outputFileName;

        document.body.appendChild(downloadLink); // fix for Firefox

        downloadLink.click();

        setTimeout(() => {
            // fix for Firefox
            document.body.appendChild(downloadLink);
        }, 100);
    }
}
