<div class="row">
    <div class="col-md-5">
        <div class="miles-card padded">
            <h4>Print Forms</h4>
            <dynamic-field [field]="dateInputField" (valueChanges)="dateInputValue = $event"></dynamic-field>
            <br />
            <button class="btn btn-secondary" (click)="printSummary()"><i class="fa fa-fw fa-print"></i> Print Load List</button>
            <button class="btn btn-secondary" (click)="printDetail()"><i class="fa fa-fw fa-print"></i> Print Scheduled Loads</button>
            <button class="btn btn-secondary" (click)="printLoadsForDate()"><i class="fa fa-fw fa-print"></i> Print Loads for Date</button>
        </div>
    </div>
</div>
