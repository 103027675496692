import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateService {

    /**
     * Conversion method to properly format the UTC date string that is
     * returned from the API. NOTE that the argument of type Date is really a string
     * because of the way Breck handles the model generation
     * @param date a UTC string returned by the API
     * @returns a JS Date based on the user's local time
     */
    public static convertUTCStringToLocalDate(date: Date): Date {
        return new Date(date.toString().replace(/-/g, '/').replace(/T.+/, ''));
    }

    /**
     * Adds/subtracts an increment of days to a JS Date object
     * @param date
     * @param days the days to increment/decrement
     * @returns the date with daysadded/subtracted
     */
    public static addDaysToDate(date: Date, days: number): Date {
        return new Date(date.setHours(days * 24));
    }

    /**
     * Conversion method to format a JS Date into the UTC date format returned
     * by the API
     * @param date JS Date
     * @returns UTC formatted date string
     */
    public static convertLocalDateToUTCString(date: Date): string {
        return `${date.toISOString().split('T')[0]}T00:00:00Z`;
    }

    formatRunDate(runDate: string): string {
        const date = new Date(runDate); // Date comes back as midnight UTC, but we need the date to be midnight local time
        date.setHours(24, 0, 0, 0); // 24 bumps to midnight of the next day
        return this.formatDateToYYYYMMDD(date);
    }

    formatDateToYYYYMMDD(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

}
