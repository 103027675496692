import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
    selector: 'year-select',
    template: `
        <select [ngModel]="model" (ngModelChange)="change($event)" class="form-control" [required]="required">
            <option *ngIf="showEmpty"></option>
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
    `,
})
export class YearSelectComponent {
    years: number[];

    @Input() fromYear: number;
    @Input() toYear: number;
    @Input() showEmpty: boolean;
    @Input() required: boolean;
    @Input() model: number;
    @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
        // set defaults
        this.showEmpty = true;
        this.required = false;
    }

    ngOnInit(): void {
        if (!this.fromYear || !this.toYear || this.fromYear > this.toYear) {
            throw new RangeError('"fromYear" must be less than "toYear".');
        }

        this.years = [];
        for (let year = this.fromYear; year <= this.toYear; year++) {
            this.years.push(year);
        }
    }

    change(newValue: number): void {
        this.model = newValue;
        this.modelChange.emit(newValue);
    }
}
