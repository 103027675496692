<div class="row">
    <div class="col-md-7">
        <h2>
            <route-back-button [urlSegmentsToIgnore]="['pallet-info']"></route-back-button>
            Pallet Requisition Form
        </h2>
    </div>
</div>
<div *ngIf="formCreated" class="row">
    <div class="col-md-8">
        <form [formGroup]="palletInfoForm" (ngSubmit)="formSubmitted()">
            <div class="miles-form padded">
                <h4>
                    Final Pallets
                    <a class="pointer" title="Print" (click)="printFinalPallets()"><i class="fa fa-print"></i></a>
                </h4>
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Pallet Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.PalletSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.PalletSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>
                            <label>Board Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.BoardSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.BoardSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>

                            <label>Cut Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.CutSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="finalPalletInfoControls.CutSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>

                            <dynamic-field class="pull-left" [field]="finalPalletInfoControls.IsExactWidth" [form]="palletInfoForm"></dynamic-field>
                            <span class="pull-left">&nbsp;&nbsp;&nbsp;</span>
                            <dynamic-field class="pull-left" [field]="finalPalletInfoControls.IsExactLength" [form]="palletInfoForm"></dynamic-field>
                            <div class="clearfix"></div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-xs-5">
                                    <dynamic-field [field]="finalPalletInfoControls.Qty" [form]="palletInfoForm"></dynamic-field>
                                </div>
                                <div class="col-xs-7">
                                    <dynamic-field [field]="finalPalletInfoControls.ShipOnTypeId" [form]="palletInfoForm"></dynamic-field>
                                </div>
                            </div>
                            <dynamic-field [field]="finalPalletInfoControls.InchesBetweenRunners" [form]="palletInfoForm"></dynamic-field>
                            <dynamic-field
                                [field]="finalPalletInfoControls.RunnerDirectionTypeId"
                                [form]="palletInfoForm"
                                (change)="checkRunnerDirectionType(palletInfoForm.controls.FinalPalletInfo, palletInfoForm.value.FinalPalletInfo)"
                            >
                            </dynamic-field>
                            <dynamic-field [field]="finalPalletInfoControls.RunnerHeight" [form]="palletInfoForm"></dynamic-field>
                        </div>
                    </div>
                    <dynamic-field [field]="finalPalletInfoControls.SpecialNotes" [form]="palletInfoForm"></dynamic-field>
                </div>
                <br />
                <h4>
                    Converting Pallets
                    <a class="pointer" title="Print" (click)="printConvertingPallets()"><i class="fa fa-print"></i></a>
                </h4>
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Pallet Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.PalletSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.PalletSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>

                            <label>Board Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.BoardSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.BoardSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>

                            <label>Cut Size</label>
                            <br />
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.CutSizeWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            &nbsp;<i class="pull-left fa fa-fw fa-close"></i>&nbsp;
                            <dynamic-field
                                style="width: 35%;"
                                class="pull-left"
                                [field]="convertingPalletInfoControls.CutSizeLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>

                            <dynamic-field
                                class="pull-left"
                                [field]="convertingPalletInfoControls.IsExactWidth"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <span class="pull-left">&nbsp;&nbsp;&nbsp;</span>
                            <dynamic-field
                                class="pull-left"
                                [field]="convertingPalletInfoControls.IsExactLength"
                                [form]="palletInfoForm"
                            ></dynamic-field>
                            <div class="clearfix"></div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-xs-5">
                                    <dynamic-field [field]="convertingPalletInfoControls.Qty" [form]="palletInfoForm"></dynamic-field>
                                </div>
                                <div class="col-xs-7">
                                    <dynamic-field [field]="convertingPalletInfoControls.ShipOnTypeId" [form]="palletInfoForm"></dynamic-field>
                                </div>
                            </div>
                            <dynamic-field [field]="convertingPalletInfoControls.InchesBetweenRunners" [form]="palletInfoForm"></dynamic-field>
                            <dynamic-field
                                [field]="convertingPalletInfoControls.RunnerDirectionTypeId"
                                [form]="palletInfoForm"
                                (change)="
                                    checkRunnerDirectionType(palletInfoForm.controls.ConvertingPalletInfo, palletInfoForm.value.ConvertingPalletInfo)
                                "
                            >
                            </dynamic-field>
                            <dynamic-field [field]="convertingPalletInfoControls.RunnerHeight" [form]="palletInfoForm"></dynamic-field>
                        </div>
                    </div>
                    <dynamic-field [field]="convertingPalletInfoControls.SpecialNotes" [form]="palletInfoForm"></dynamic-field>
                </div>
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save & Close
                </button>
            </div>
        </form>
    </div>
</div>
