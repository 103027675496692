import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { MillComplaintService } from '../services/mill-complaint.service';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './mill-complaint-header.component.html',
})
export class MillComplaintHeaderComponent implements OnInit, OnDestroy {
    millComplaint: IMillComplaint;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private millComplaintService: MillComplaintService, private route: ActivatedRoute, private datePipe: DatePipe) {}

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('millComplaintId');

        if (id > 0) {
            this.millComplaintService.getById(id).subscribe((millComplaint) => {
                this.setHeader(millComplaint);
            });
        } else {
            this.setHeader(this.millComplaintService.getEmptyMillComplaint());
        }

        this.subscriptions.add(
            this.millComplaintService.changeEmitted$.subscribe((millComplaint) => {
                this.setHeader(millComplaint);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(millComplaint: IMillComplaint): void {
        this.millComplaint = millComplaint;
        this.header =
            millComplaint && millComplaint.Id
                ? `
                    Mill Complaint for order ${millComplaint.SalesOrderId}
                    taken by ${millComplaint.User.FirstName} ${millComplaint.User.LastName}
                    on ${this.datePipe.transform(millComplaint.ComplaintDate)}
                  `
                : 'Add MillComplaint';
    }
}
