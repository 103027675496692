import { CustomerShippingAddressService } from './shared-entities/customer-shipping-address.service';
import { CustomerShippingSharedEntitiesConfig } from '../model/shared-entities/customer-shipping-address.shared-entities-config';
import { CustomerContactsService } from './shared-entities/customer-contacts.service';
import { ContactsSharedEntitiesConfig } from '../model/shared-entities/contacts.shared-entities-config';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CommonNotesListComponent } from '@mt-ng2/entity-components-notes';
import { CustomerService } from './customer.service';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { CommonAddressesListComponent } from '@mt-ng2/entity-components-addresses';
import { CustomerSharedEntities } from './shared-entities/customer.shared-entities';
import { SharedEntitiesInfoComponent, SharedEntitiesListComponent } from '@mt-ng2/shared-entities-module';
import { ClaimTypes } from '../model/ClaimTypes';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerPricingComponent } from './customer-pricing/customer-pricing.component';
import { CustomerPricingItemHistoryComponent } from './customer-pricing/history/customer-pricing-item-history.component';
import { CustomPackagingsComponent } from './custom-packaging/custom-packaging-list/custom-packagings.component';
import { customPackagingPaths } from '@routes/custom-packaging-paths';
import { CustomPackagingAddComponent } from './custom-packaging/custom-packaging-add/custom-packaging-add.component';
import { CustomPackagingDetailComponent } from './custom-packaging/custom-packaging-detail/custom-packaging-detail.component';

const shippingAddressSharedEntity = {
    config: CustomerShippingSharedEntitiesConfig,
    entityIdParam: 'Id',
    path: 'shippingaddresses',
    service: CustomerShippingAddressService,
    sharedEntity: CustomerSharedEntities.ShippingAddress,
};
const contactsSharedEntity = {
    canEditSubClaimType: ClaimTypes.Customers_CanEditCustomerContacts,
    config: ContactsSharedEntitiesConfig,
    entityIdParam: 'contactId',
    path: 'contacts',
    service: CustomerContactsService,
    sharedEntity: CustomerSharedEntities.Contacts,
};
const customerEntityConfig = {
    addressesPath: 'addresses',
    allowInternationalAddresses: true,
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'customerId',
    notesComponentName: 'Rolo-dex',
    notesPath: 'rolo-dex',
    service: CustomerService,
    sharedEntities: [contactsSharedEntity, shippingAddressSharedEntity],
    title: 'Customer Detail',
};

const customerRoleGuard = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Customers',
};

const customerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.FullAccess],
};

const customerEditContactsRoleGuard = {
    claimType: ClaimTypes.Customers_CanEditCustomerContacts,
    claimValues: [ClaimValues.FullAccess],
    title: 'Customer Rolo-dex',
};

const customerEditPricingRoleGuard = {
    claimType: ClaimTypes.Customers_CanEditCustomerPricing,
    claimValues: [ClaimValues.FullAccess],
    title: 'Customer Pricing',
};

const customerRoutes: Routes = [
    { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: customerRoleGuard },
    { path: 'customers/settings', component: CustomerSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    {
        canActivate: [AuthGuard],
        component: CustomerAddComponent,
        data: customerAddRoleGuard,
        path: 'customers/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CustomerDetailComponent, pathMatch: 'full' },
            { path: customerEntityConfig.notesPath, component: CommonNotesListComponent, pathMatch: 'full', data: customerEditContactsRoleGuard },
            { path: 'pricing', component: CustomerPricingComponent, pathMatch: 'full', data: customerEditPricingRoleGuard },
            {
                component: CustomerPricingItemHistoryComponent,
                data: { title: 'Customer Pricing Item History' },
                path: 'pricing/:pricingItemId',
                pathMatch: 'full',
            },
            {
                component: CommonAddressesListComponent,
                data: { title: 'Customer Addresses' },
                path: customerEntityConfig.addressesPath,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Customer Contacts' },
                path: contactsSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                data: customerEditContactsRoleGuard,
                path: `${contactsSharedEntity.path}/:${contactsSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Customer Shipping Addresses' },
                path: shippingAddressSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${shippingAddressSharedEntity.path}/:${shippingAddressSharedEntity.entityIdParam}`,
                pathMatch: 'full',
            },
            {
                component: CustomPackagingsComponent,
                path: customPackagingPaths.customPackaging,
                pathMatch: 'full',
            },
            {
                component: CustomPackagingAddComponent,
                path: customPackagingPaths.customPackagingAdd,
                pathMatch: 'full',
            },
            {
                component: CustomPackagingDetailComponent,
                path: customPackagingPaths.customPackagingInfo,
                pathMatch: 'full',
            },
        ],
        component: CustomerHeaderComponent,
        data: customerEntityConfig,
        path: `customers/:${customerEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerRoutes)],
})
export class CustomerRoutingModule {}
