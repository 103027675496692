import { DynamicField, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { TrimSheetDetailDynamicControls, ITrimSheetDetailDynamicControlsParameters } from '../form-controls/trim-sheet-detail.form-controls';
import { ITrimSheetDetail } from '../interfaces/trim-sheet-detail';
import { Validators } from '../../../../node_modules/@angular/forms';

export class TrimSheetDetailDynamicControlsPartial extends TrimSheetDetailDynamicControls {
    constructor(trimsheetdetailPartial?: ITrimSheetDetail, additionalParameters?: ITrimSheetDetailDynamicControlsParameters) {
        super(trimsheetdetailPartial, additionalParameters);

        (<DynamicField>this.Form.Sheets).type.fieldType = DynamicFieldTypes.Numeric;
        (<DynamicField>this.Form.Bundles).type.fieldType = DynamicFieldTypes.Numeric;

        (<DynamicField>this.Form.Sheets).validators.max = 5;
        (<DynamicField>this.Form.Sheets).validation.push(Validators.max(5));

        (<DynamicField>this.Form.StockItemTypeId).labelHtml = `<label>Side Sheet Option</label>`;
        (<DynamicField>this.Form.StockItemTypeId).label = `Side Sheet Option`;

        (<DynamicField>this.Form.Bundles).value = trimsheetdetailPartial &&  trimsheetdetailPartial.Bundles || 0;

        (<DynamicField>this.Form.IsTiedBundles).labelHtml = `<label>Tied Bdls</label>`;
        (<DynamicField>this.Form.IsTiedBundles).label = `Tied Bdls`;
    }
}
