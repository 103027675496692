import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IReceipt } from '../model/interfaces/receipt';
import { Observable } from 'rxjs';
import { IReceiptDetail } from '../model/interfaces/receipt-detail';
import { UnitOfMeasures } from '@model/enums/unit-of-measures.enum';
import { OrderTypeIds } from '@model/OrderTypes';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';

export const emptyReceipt: IReceipt = {
    HasAdditionalFeesDeductions1: false,
    HasAdditionalFeesDeductions2: false,
    HasEnergySurchargePerTon: false,
    HasTradeDiscountPercent: false,
    HasTruckingAllowancePerTon: false,
    Id: 0,
    Invoiced: false,
    InvoicedDate: null,
    Note: '',
    Number: '',
    SentToQuickbooks: false,
    ShipmentId: 0,
    Stop: 0,
};

export const emptyReceiptDetail: IReceiptDetail = {
    Bundles: 0,
    Id: 0,
    Pieces: 0,
    PoundsPerBundle: 0,
    PrintPartInfo: true,
    QbAccountNumber: '',
    ReceiptId: 0,
    ReceiptSizeTotalId: null,
    SalesOrderId: 0,
    ShowBundles: false,
    ShowPieces: false,
    ShowSkidIds: false,
    ShowWeight: false,
    Units: 0,
};

export interface IReceiptInvoiceView {
    ReceiptId: number;
    ReceiptNumber: string;
    CustomerName: string;
    InvoiceEmail: string;
    Invoiced: boolean;
    DateSent: Date;
}

export interface IQuickbooksQueueReceipt {
    ReceiptId: number;
    ReceiptNumber: string;
    InvoicedDate: Date;
    OrderNumbers: string[];
}

export const DEFAULT_POUNDS_PER_BUNDLE = 50;
export const POUNDS_PER_TON = 2000;
const PIECES_PER_M = 1000;

@Injectable()
export class ReceiptService extends BaseService<IReceipt> {
    constructor(public http: HttpClient) {
        super('/receipts', http);
    }

    getEmptyReceipt(): IReceipt {
        return { ...emptyReceipt };
    }

    getEmptyReceiptDetail(): IReceiptDetail {
        return { ...emptyReceiptDetail };
    }

    getByReceiptNumber(number: string): Observable<IReceipt> {
        let params = new HttpParams();
        params = params.append('number', number);
        return this.http.get<IReceipt>('/receipts/number', { params: params });
    }

    getDetailsByReceiptNumber(number: string): Observable<IReceiptDetail[]> {
        let params = new HttpParams();
        params = params.append('number', number);
        return this.http.get<IReceiptDetail[]>('/receipts/details/number', { params: params });
    }

    updateDetails(receiptId: number, details: IReceiptDetail[]): Observable<any> {
        return this.http.put<any>(`/receipts/${receiptId}/details`, details);
    }

    updateStockDetails(detail: IReceiptDetail, stockDetails: IReceiptDetail[]): Observable<any> {
        return this.http.put<any>(`/receipts/update-stock-details`, { detail: detail, stockDetails: stockDetails });
    }

    receiptIsInvoiced(receiptId: number): Observable<boolean> {
        return this.http.get<boolean>(`/receipts/${receiptId}/invoiced`);
    }

    createAuditLog(receiptId: number, reason: string): Observable<number> {
        return this.http.post<any>(`/receipts/${receiptId}/audit-log`, { Reason: reason });
    }

    getInvoiceViewInDateRange(from: Date, to: Date): Observable<IReceiptInvoiceView[]> {
        let params = new HttpParams();
        params = params.append('fromDate', from.toISOString().split('T')[0]);
        params = params.append('toDate', to.toISOString().split('T')[0]);
        return this.http.get<IReceiptInvoiceView[]>('/receipts/invoice-view', { params: params });
    }

    getInvoiceViewInNumberRange(from: string, to: string): Observable<IReceiptInvoiceView[]> {
        let params = new HttpParams();
        params = params.append('fromNumber', from);
        params = params.append('toNumber', to);
        return this.http.get<IReceiptInvoiceView[]>('/receipts/invoice-view', { params: params });
    }

    printReceipts(receiptIds: number[], markAsInvoiced = false, resetSentToQuickbooks = true, sortByReceiptNumber = false): Observable<any> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        if (markAsInvoiced) {
            params = params.append('markAsInvoiced', markAsInvoiced.toString());
        }
        params = params.append('resetSentToQuickbooks', resetSentToQuickbooks.toString());
        params = params.append('sortByReceiptNumber', sortByReceiptNumber.toString());
        return this.http.post<any>('/receipts/print', null, { params: params });
    }

    emailReceipts(receiptIds: number[], markAsInvoiced = false): Observable<any> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        if (markAsInvoiced) {
            params = params.append('markAsInvoiced', markAsInvoiced.toString());
        }
        return this.http.post<any>('/receipts/email', null, { params: params });
    }

    voidInvoice(receiptId: number): Observable<any> {
        return this.http.post<any>(`/receipts/${receiptId}/void-invoice`, {});
    }

    getTonsAndPricingSummaryReport(month: number, year: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('month', month.toString());
        params = params.append('year', year.toString());
        return this.http.get<any>('/receipts/tons-and-pricing/summary', { params: params });
    }

    getTonsAndPricingDetailReport(month: number, year: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('month', month.toString());
        params = params.append('year', year.toString());
        return this.http.get<any>('/receipts/tons-and-pricing/detail', { params: params });
    }

    getQuickbooksQueue(skip: number, take: number, order: string, orderDirection: string): Observable<HttpResponse<IQuickbooksQueueReceipt[]>> {
        let params = new HttpParams();
        params = params.append('skip', skip.toString());
        params = params.append('take', take.toString());
        params = params.append('order', order);
        params = params.append('orderDirection', orderDirection);
        return this.http.get<IQuickbooksQueueReceipt[]>('/receipts/quickbooks/queue', { observe: 'response', params: params });
    }

    generateIifFile(receiptIds: number[]): Observable<any> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        let headers = new HttpHeaders();
        headers.set('Accept', 'text/plain');
        return this.http.get<any>('/receipts/quickbooks/iif', { params: params, headers: headers });
    }

    processToQuickbooks(receiptIds: number[]): Observable<any> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        return this.http.post<any>('/receipts/quickbooks/process', null, { params: params });
    }

    generateReleasedInvoicesReport(receiptIds: number[]): Observable<any> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        return this.http.get<any>('/receipts/quickbooks/released', { params: params });
    }

    generateReleasedInvoicesReportByDate(date: Date): Observable<any> {
        let params = new HttpParams();
        params = params.append('date', date.toISOString().split('T')[0]);
        return this.http.get<any>('/receipts/quickbooks/released', { params: params });
    }

    getLastDailyInvoiceNumber(searchNumberFrom: string): Observable<string> {
        let params = new HttpParams();
        params = params.append('searchNumberFrom', searchNumberFrom);
        return this.http.get<string>(`/receipts/last-daily`, { params: params });
    }

    getQuantity(detail: IReceiptDetail): number {
        if (detail.SalesOrder.OrderTypeId === OrderTypeIds.Stock) {
            return this.getStockQuantity(detail);
        }

        const uom =
            detail.SalesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                ? detail.SalesOrder.ManufacturingOrderDetail.UnitOfMeasureId
                : detail.OrderPriceTypeId;
        switch (uom) {
            case UnitOfMeasures.B:
                return detail.Bundles;
            case UnitOfMeasures.M:
                return detail.Pieces / PIECES_PER_M;
            case UnitOfMeasures.P:
                return detail.Pieces;
            case UnitOfMeasures.R:
                return detail.Pieces;
            case UnitOfMeasures.S:
                return detail.NumberOfSkids;
            case UnitOfMeasures.T:
                return detail.Weight / POUNDS_PER_TON;
            default:
                return detail.Pieces;
        }
    }

    getStockQuantity(receiptDetail: IReceiptDetail): number {
        return +((receiptDetail.Bundles * DEFAULT_POUNDS_PER_BUNDLE / POUNDS_PER_TON).toFixed(4));
    }

    printBundlesShippedDailyReport(DateFrom: any, DateTo: any): Observable<string> {
        let params = new HttpParams();
        params = params.append('DateFrom', DateFrom);
        params = params.append('DateTo', DateTo);
        return this.http.get<string>('/receipts/bundles-shipped-daily-report', { params: params });
    }

    updatePaymentNoteOnRangeOfReceipts(paymentNoteId: number, receiptIds: number[]): Observable<any> {
        return this.http.put<any>(`/receipts/payment-note/${paymentNoteId}`, receiptIds);
    }

    generateReceiptPaperwork(receiptId: number): Observable<any> {
        return this.http.get(`/receipts/${receiptId}/all-paperwork`);
    }

    getGeneralLedgerCodes(): Observable<IGeneralLedgerCode[]> {
        return this.http.get<IGeneralLedgerCode[]>(`/receipts/general-ledger-codes`);
    }

    saveReceiptNote(receiptId: number, receiptNote: string): Observable<any> {
        return this.http.put(`/receipts/${receiptId}/notes`, { Note: receiptNote });
    }

    getAllReceiptsProcessed(receiptIds: number[]): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('receiptIds', receiptIds.join(','));
        return this.http.get<boolean>(`/receipts/get-all-receipts-processed`, { params : params});
    }
}
