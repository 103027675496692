import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-order-customer-specs',
    templateUrl: './order-customer-specs-main.component.html',
})
export class OrderCustomerSpecsMainComponent implements OnInit {
    orderInfoId: number;
    salesOrderId: number;

    constructor(private route: ActivatedRoute, private router: Router, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.salesOrderId = +this.route.snapshot.params.salesOrderId;
        this.orderInfoId = +this.route.snapshot.params.orderInfoId;
    }

    cancel(): void {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Order Customer Specifications Save Failed');
    }

    success(): void {
        this.notificationsService.success('Order Customer Specifications Saved Successfully');
        this.router.navigate(['../../'], { relativeTo: this.route });
    }
}
