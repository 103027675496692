<div class="row">
    <div class="col-md-7">
        <h2>Daily Runs</h2>
    </div>
</div>
<div>
    <div class="row">
        <mt-search-filter-daterange entity="Run Date" (onSelectionChanged)="dailyRunDateSelectionChanged($event)"></mt-search-filter-daterange>
        <mt-search-filter-select [items]="millShifts" entity="Shift" (onSelectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
    </div>
    <div class="row">
        <div class="col-md-2">
            <label>Order #</label>
            <mt-search-bar #searchBar (onSearch)="searchOrderNumber($event)"></mt-search-bar>
        </div>
        <div class="col-md-4">
            <label>Customer Name</label>
            <mt-search-bar (onSearch)="searchCustomer($event)"></mt-search-bar>
        </div>
    </div>
    <br />

    <entity-list
        [entities]="dailyRunEntries"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="ordersPerPage"
        (onPageChanged)="getDailyRunEntries()"
        (onItemSelected)="onDailyRunSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>

    <div class="fab-wrap-b-r">
        <a class="btn btn-primary btn-fab-lg" [routerLink]="['/daily-run-entries/add']">
            <span class="fa fa-plus"></span>
        </a>
    </div>
</div>
