import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IOrderDetailLine } from '../model/interfaces/order-detail-line';

@Injectable()
export class OrderDetailLinesService extends BaseService<IOrderDetailLine> {
    constructor(public http: HttpClient) {
        super('/salesorders/orderdetaillines', http);
    }
}
