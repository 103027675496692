import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, NumericInputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IDailyRunSalesOrder } from '@model/interfaces/daily-run-sales-order';
import { DailyRunSalesOrderDynamicControls, IDailyRunSalesOrderDynamicControlsParameters } from '@model/form-controls/daily-run-sales-order.form-controls';
import { FormatService } from '@common/services/format.service';

export class DailyRunSalesOrderDynamicControlsPartial extends DailyRunSalesOrderDynamicControls {

    constructor(dailyRunSalesOrderPartial?: IDailyRunSalesOrder, additionalParameters?: IDailyRunSalesOrderDynamicControlsParameters) {
        super(dailyRunSalesOrderPartial, additionalParameters);

        (<DynamicField>this.Form.Basis).labelHtml = '<label>Basis</label>';
        (<DynamicField>this.Form.BoardGrade).labelHtml = '<label>Board Grade</label>';
        (<DynamicField>this.Form.Finish).labelHtml = '<label>Finish</label>';
        (<DynamicField>this.Form.Bundles).labelHtml = '<label>Bundles</label>';
        (<DynamicField>this.Form.Caliper).labelHtml = '<label>Caliper</label';
        (<DynamicField>this.Form.CustomerName).labelHtml = '<label>Customer Name</label';
        (<DynamicField>this.Form.Detail).labelHtml = '<label>Detail</label';
        (<DynamicField>this.Form.NumberOfPallets).labelHtml = '<label># of Pallets</label';
        (<DynamicField>this.Form.BdlsPerPallet).labelHtml = '<label>Bdls/Pallet</label';
        (<DynamicField>this.Form.BdlsPerPallet).disabled = true;
        this.Form.Detail.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textarea,
        });
        (<DynamicField>this.Form.IsRunSheetStock).labelHtml = '';
        (<DynamicField>this.Form.IsFortyPoundBundle).labelHtml = '';

        this.Form.SalesOrderId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sales Order',
            name: 'SalesOrderId',
            options: this.salesOrders,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                numericFunctions: {
                    numberFormatDisplay: FormatService.noCommasNumberFormatDisplayFunction(),
                },
            }),
            validation: null,
            validators: { 'required': false },
            value: dailyRunSalesOrderPartial && dailyRunSalesOrderPartial.SalesOrderId || null,
        });

        this.Form.IsFortyPoundBundle.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.RadioButtonList,
        });
        this.Form.IsFortyPoundBundle.options = [
            {
                Id: true,
                Name: '40 pound Bundles',
            },
            {
                Id: false,
                Name: '50 pound Bundles',
            },
        ];
        this.Form.IsFortyPoundBundle.hideLabel = true;
        if (dailyRunSalesOrderPartial && dailyRunSalesOrderPartial.IsFortyPoundBundle !== undefined) {
            this.Form.IsFortyPoundBundle.value = dailyRunSalesOrderPartial.IsFortyPoundBundle;
        } else {
            this.Form.IsFortyPoundBundle.value = null;
        }

        this.Form.IsRunSheetStock.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.RadioButtonList,
        });
        this.Form.IsRunSheetStock.options = [
            {
                Id: true,
                Name: 'Run Sheet Stock',
            },
            {
                Id: false,
                Name: 'Other Stock',
            },
        ];

    }
}
