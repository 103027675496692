import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IReceiptDetail } from '../interfaces/receipt-detail';
import { IOrderPriceType } from '../interfaces/order-price-type';
import { IReceipt } from '../interfaces/receipt';
import { IReceiptSizeTotal } from '../interfaces/receipt-size-total';
import { ISalesOrder } from '../interfaces/sales-order';
import { IShipOnType } from '../interfaces/ship-on-type';
import { IStockOrderLine } from '../interfaces/stock-order-line';

export interface IReceiptDetailDynamicControlsParameters {
    formGroup?: string;
    receipts?: IReceipt[];
    salesOrders?: ISalesOrder[];
    shipOnTypes?: IShipOnType[];
    orderPriceTypes?: IOrderPriceType[];
    receiptSizeTotals?: IReceiptSizeTotal[];
    stockOrderLines?: IStockOrderLine[];
}

export class ReceiptDetailDynamicControls {

    formGroup: string;
    receipts: IReceipt[];
    salesOrders: ISalesOrder[];
    shipOnTypes: IShipOnType[];
    orderPriceTypes: IOrderPriceType[];
    receiptSizeTotals: IReceiptSizeTotal[];
    stockOrderLines: IStockOrderLine[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private receiptdetail?: IReceiptDetail, additionalParameters?: IReceiptDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ReceiptDetail';
        this.receipts = additionalParameters && additionalParameters.receipts || undefined;
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.shipOnTypes = additionalParameters && additionalParameters.shipOnTypes || undefined;
        this.orderPriceTypes = additionalParameters && additionalParameters.orderPriceTypes || undefined;
        this.receiptSizeTotals = additionalParameters && additionalParameters.receiptSizeTotals || undefined;
        this.stockOrderLines = additionalParameters && additionalParameters.stockOrderLines || undefined;

        this.Form = {
            Basis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis',
                name: 'Basis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.Basis || null,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.Caliper || null,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.Count || null,
            }),
            CustomerPurchaseOrderNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Purchase Order Number',
                name: 'CustomerPurchaseOrderNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('CustomerPurchaseOrderNumber') && this.receiptdetail.CustomerPurchaseOrderNumber !== null ? this.receiptdetail.CustomerPurchaseOrderNumber.toString() : '',
            }),
            Description: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description',
                name: 'Description',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('Description') && this.receiptdetail.Description !== null ? this.receiptdetail.Description.toString() : '',
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('Length') && this.receiptdetail.Length !== null ? this.receiptdetail.Length.toString() : '',
            }),
            NumberOfSkids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Skids',
                name: 'NumberOfSkids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.NumberOfSkids || null,
            }),
            OrderPriceTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Price Type',
                name: 'OrderPriceTypeId',
                options: this.orderPriceTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.OrderPriceTypeId || null,
            }),
            Pieces: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pieces',
                name: 'Pieces',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.Pieces || null,
            }),
            Piles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Piles',
                name: 'Piles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.Piles || null,
            }),
            PoundsPerBundle: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pounds Per Bundle',
                name: 'PoundsPerBundle',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.PoundsPerBundle || null,
            }),
            PricePer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Price Per',
                name: 'PricePer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.PricePer || null,
            }),
            PrintPartInfo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Print Part Info',
                name: 'PrintPartInfo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('PrintPartInfo') && this.receiptdetail.PrintPartInfo !== null ? this.receiptdetail.PrintPartInfo : true,
            }),
            QbAccountNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qb Account Number',
                name: 'QbAccountNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('QbAccountNumber') && this.receiptdetail.QbAccountNumber !== null ? this.receiptdetail.QbAccountNumber.toString() : '',
            }),
            ReceiptId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt',
                name: 'ReceiptId',
                options: this.receipts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.ReceiptId || null,
            }),
            ReceiptSizeTotalId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Size Total',
                name: 'ReceiptSizeTotalId',
                options: this.receiptSizeTotals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.ReceiptSizeTotalId || null,
            }),
            ReceiptTextOverwrite: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receipt Text Overwrite',
                name: 'ReceiptTextOverwrite',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('ReceiptTextOverwrite') && this.receiptdetail.ReceiptTextOverwrite !== null ? this.receiptdetail.ReceiptTextOverwrite.toString() : '',
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.SalesOrderId || null,
            }),
            ShipOnTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship On Type',
                name: 'ShipOnTypeId',
                options: this.shipOnTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.ShipOnTypeId || null,
            }),
            ShowBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Bundles',
                name: 'ShowBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowBundles') && this.receiptdetail.ShowBundles !== null ? this.receiptdetail.ShowBundles : false,
            }),
            ShowPieces: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Pieces',
                name: 'ShowPieces',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowPieces') && this.receiptdetail.ShowPieces !== null ? this.receiptdetail.ShowPieces : false,
            }),
            ShowSkidIds: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Skid Ids',
                name: 'ShowSkidIds',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowSkidIds') && this.receiptdetail.ShowSkidIds !== null ? this.receiptdetail.ShowSkidIds : true,
            }),
            ShowWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Show Weight',
                name: 'ShowWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowWeight') && this.receiptdetail.ShowWeight !== null ? this.receiptdetail.ShowWeight : false,
            }),
            StockOrderLineId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stock Order Line',
                name: 'StockOrderLineId',
                options: this.stockOrderLines,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.StockOrderLineId || null,
            }),
            TrailerHeavyWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer Heavy Weight',
                name: 'TrailerHeavyWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.TrailerHeavyWeight || null,
            }),
            TrailerLightWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer Light Weight',
                name: 'TrailerLightWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.TrailerLightWeight || null,
            }),
            TrailerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trailer Number',
                name: 'TrailerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('TrailerNumber') && this.receiptdetail.TrailerNumber !== null ? this.receiptdetail.TrailerNumber.toString() : '',
            }),
            Units: new DynamicField({
                formGroup: this.formGroup,
                label: 'Units',
                name: 'Units',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.receiptdetail && this.receiptdetail.Units || null,
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.receiptdetail && this.receiptdetail.Weight || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.receiptdetail && this.receiptdetail.hasOwnProperty('Width') && this.receiptdetail.Width !== null ? this.receiptdetail.Width.toString() : '',
            }),
        };

        this.View = {
            Basis: new DynamicLabel(
                'Basis',
                this.receiptdetail && this.receiptdetail.Basis || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Bundles: new DynamicLabel(
                'Bundles',
                this.receiptdetail && this.receiptdetail.Bundles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.receiptdetail && this.receiptdetail.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            Count: new DynamicLabel(
                'Count',
                this.receiptdetail && this.receiptdetail.Count || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            CustomerPurchaseOrderNumber: new DynamicLabel(
                'Customer Purchase Order Number',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('CustomerPurchaseOrderNumber') && this.receiptdetail.CustomerPurchaseOrderNumber !== null ? this.receiptdetail.CustomerPurchaseOrderNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Description: new DynamicLabel(
                'Description',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('Description') && this.receiptdetail.Description !== null ? this.receiptdetail.Description.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('Length') && this.receiptdetail.Length !== null ? this.receiptdetail.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfSkids: new DynamicLabel(
                'Number Of Skids',
                this.receiptdetail && this.receiptdetail.NumberOfSkids || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OrderPriceTypeId: new DynamicLabel(
                'Order Price Type',
                getMetaItemValue(this.orderPriceTypes, this.receiptdetail && this.receiptdetail.hasOwnProperty('OrderPriceTypeId') && this.receiptdetail.OrderPriceTypeId !== null ? this.receiptdetail.OrderPriceTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Pieces: new DynamicLabel(
                'Pieces',
                this.receiptdetail && this.receiptdetail.Pieces || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Piles: new DynamicLabel(
                'Piles',
                this.receiptdetail && this.receiptdetail.Piles || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PoundsPerBundle: new DynamicLabel(
                'Pounds Per Bundle',
                this.receiptdetail && this.receiptdetail.PoundsPerBundle || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PricePer: new DynamicLabel(
                'Price Per',
                this.receiptdetail && this.receiptdetail.PricePer || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            PrintPartInfo: new DynamicLabel(
                'Print Part Info',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('PrintPartInfo') && this.receiptdetail.PrintPartInfo !== null ? this.receiptdetail.PrintPartInfo : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            QbAccountNumber: new DynamicLabel(
                'Qb Account Number',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('QbAccountNumber') && this.receiptdetail.QbAccountNumber !== null ? this.receiptdetail.QbAccountNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReceiptId: new DynamicLabel(
                'Receipt',
                getMetaItemValue(this.receipts, this.receiptdetail && this.receiptdetail.hasOwnProperty('ReceiptId') && this.receiptdetail.ReceiptId !== null ? this.receiptdetail.ReceiptId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReceiptSizeTotalId: new DynamicLabel(
                'Receipt Size Total',
                getMetaItemValue(this.receiptSizeTotals, this.receiptdetail && this.receiptdetail.hasOwnProperty('ReceiptSizeTotalId') && this.receiptdetail.ReceiptSizeTotalId !== null ? this.receiptdetail.ReceiptSizeTotalId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReceiptTextOverwrite: new DynamicLabel(
                'Receipt Text Overwrite',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('ReceiptTextOverwrite') && this.receiptdetail.ReceiptTextOverwrite !== null ? this.receiptdetail.ReceiptTextOverwrite.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.receiptdetail && this.receiptdetail.hasOwnProperty('SalesOrderId') && this.receiptdetail.SalesOrderId !== null ? this.receiptdetail.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipOnTypeId: new DynamicLabel(
                'Ship On Type',
                getMetaItemValue(this.shipOnTypes, this.receiptdetail && this.receiptdetail.hasOwnProperty('ShipOnTypeId') && this.receiptdetail.ShipOnTypeId !== null ? this.receiptdetail.ShipOnTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowBundles: new DynamicLabel(
                'Show Bundles',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowBundles') && this.receiptdetail.ShowBundles !== null ? this.receiptdetail.ShowBundles : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowPieces: new DynamicLabel(
                'Show Pieces',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowPieces') && this.receiptdetail.ShowPieces !== null ? this.receiptdetail.ShowPieces : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowSkidIds: new DynamicLabel(
                'Show Skid Ids',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowSkidIds') && this.receiptdetail.ShowSkidIds !== null ? this.receiptdetail.ShowSkidIds : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShowWeight: new DynamicLabel(
                'Show Weight',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('ShowWeight') && this.receiptdetail.ShowWeight !== null ? this.receiptdetail.ShowWeight : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            StockOrderLineId: new DynamicLabel(
                'Stock Order Line',
                getMetaItemValue(this.stockOrderLines, this.receiptdetail && this.receiptdetail.hasOwnProperty('StockOrderLineId') && this.receiptdetail.StockOrderLineId !== null ? this.receiptdetail.StockOrderLineId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TrailerHeavyWeight: new DynamicLabel(
                'Trailer Heavy Weight',
                this.receiptdetail && this.receiptdetail.TrailerHeavyWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TrailerLightWeight: new DynamicLabel(
                'Trailer Light Weight',
                this.receiptdetail && this.receiptdetail.TrailerLightWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TrailerNumber: new DynamicLabel(
                'Trailer Number',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('TrailerNumber') && this.receiptdetail.TrailerNumber !== null ? this.receiptdetail.TrailerNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Units: new DynamicLabel(
                'Units',
                this.receiptdetail && this.receiptdetail.Units || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                this.receiptdetail && this.receiptdetail.Weight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.receiptdetail && this.receiptdetail.hasOwnProperty('Width') && this.receiptdetail.Width !== null ? this.receiptdetail.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
