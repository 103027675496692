import { Injectable } from '@angular/core';
import { IReceiptDetail } from '@model/interfaces/receipt-detail';
import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';
import { DEFAULT_POUNDS_PER_BUNDLE, POUNDS_PER_TON, ReceiptService } from '../receipt.service';
import { OrderTypeIds } from '@model/OrderTypes';

@Injectable()
export class ReceiptDetailsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor(receiptService: ReceiptService) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['SalesOrderId'],
                    name: 'Order',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (detail: IReceiptDetail) => {
                        return `${detail.Width} X ${detail.Length}`;
                    },
                    name: 'Size',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Description'],
                    name: 'Grade',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Caliper'],
                    name: 'Calip',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (detail: IReceiptDetail) => {
                        const uom =
                            detail.SalesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                                ? detail.SalesOrder.ManufacturingOrderDetail.UnitOfMeasure.Name
                                : detail.OrderPriceType.Name;
                        return `${receiptService.getQuantity(detail)} ${uom}`;
                    },
                    name: 'Qty',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (detail: IReceiptDetail) => {
                        let price;
                        if (detail.SalesOrder.OrderTypeId === OrderTypeIds.Stock) {
                            price = +(detail.Bundles * DEFAULT_POUNDS_PER_BUNDLE / POUNDS_PER_TON * (detail.PricePer ?? 0)).toFixed(2);
                        } else {
                            price = detail.Units * (detail.PricePer ?? 0);
                        }

                        return price;
                    },
                    name: 'Price',
                    pipes: ['currency:USD:symbol:1.0-4'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (detail: IReceiptDetail) => {
                        const opt =
                            detail.SalesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                                ? detail.SalesOrder.OrderInfo.OrderPriceType
                                : detail.OrderPriceType;
                        return opt.Name ?? '';
                    },
                    name: 'per Amt',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (detail: IReceiptDetail) => {
                        const gl =
                            detail.SalesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                                ? detail.SalesOrder.ManufacturingOrderDetail?.GeneralLedgerCode?.Code
                                : detail.StockOrderLine?.GeneralLedgerCode?.Code;
                        return gl;
                    } ,
                    name: 'GL',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
