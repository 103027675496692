import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPosition,
    LabelPositions,
    IDynamicFieldType,
    ITimepickerOptions,
} from '@mt-ng2/dynamic-form';

import { PastingTeamLogDynamicControls, IPastingTeamLogDynamicControlsParameters } from '../form-controls/pasting-team-log.form-controls';
import { IPastingTeamLog } from '../interfaces/pasting-team-log';
import { IBoardType } from '../interfaces/board-type';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { FormatService } from '@common/services/format.service';
import { ScrollableTypeAheadComponent } from '@common/components/scrollable-type-ahead/scrollable-type-ahead.component';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';

export class PastingTeamLogDynamicControlsPartial extends PastingTeamLogDynamicControls {
    constructor(convertingDataEntryUsers: IConvertingDataEntryUser[], pastingteamlogPartial?: IPastingTeamLog, additionalParameters?: IPastingTeamLogDynamicControlsParameters) {
        super(pastingteamlogPartial, additionalParameters);

        (<DynamicField>this.Form.Width).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Length).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Skids).component = FractionsDynamicFieldComponent as any;

        (<DynamicLabel>this.View.BdlSk).label = 'b/s';
        (<DynamicField>this.Form.BdlSk).labelHtml = '<label>b/s</label>';

        (<DynamicLabel>this.View.BoardGrade).label = 'Grade';
        (<DynamicField>this.Form.BoardGrade).labelHtml = '<label>Grade</label>';

        (<DynamicLabel>this.View.ShiftCode).label = 'Shift';
        (<DynamicField>this.Form.ShiftCode).labelHtml = '<label>Shift</label>';

        (<DynamicLabel>this.View.MachineNumber).label = 'Machine #';
        (<DynamicField>this.Form.MachineNumber).labelHtml = '<label>Machine #</label>';

        (<DynamicField>this.Form.Note).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });

        (<DynamicField>this.Form.Caliper).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.Caliper).validators.max = 0.999;

        let dynamicFieldTimeConfig: IDynamicFieldType = {
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Timepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        };

        (<DynamicField>this.Form.TimeOff).type = new DynamicFieldType(dynamicFieldTimeConfig);
        (<DynamicField>this.Form.TimeOn).type = new DynamicFieldType(dynamicFieldTimeConfig);

        this.Form.SalesOrderId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sales Order',
            name: 'SalesOrderId',
            options: this.salesOrders,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                numericFunctions: {
                    numberFormatDisplay: FormatService.noCommasNumberFormatDisplayFunction(),
                },
            }),
            validation: null,
            validators: { 'required': false },
            value: pastingteamlogPartial && pastingteamlogPartial.SalesOrderId || null,
        });

        this.Form.Feeder = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Feeder',
            name: 'Feeder',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id: user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: pastingteamlogPartial && pastingteamlogPartial.hasOwnProperty('Feeder') && pastingteamlogPartial.Feeder !== null ? pastingteamlogPartial.Feeder.toString() : '',
        });

        this.Form.Catcher = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Catcher',
            name: 'Catcher',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id: user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: pastingteamlogPartial && pastingteamlogPartial.hasOwnProperty('Catcher') && pastingteamlogPartial.Catcher !== null ? pastingteamlogPartial.Catcher.toString() : '',
        });

        this.Form.Pressman = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Pressman',
            name: 'Pressman',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id: user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: pastingteamlogPartial && pastingteamlogPartial.hasOwnProperty('Pressman') && pastingteamlogPartial.Pressman !== null ? pastingteamlogPartial.Pressman.toString() : '',
        });
    }
}
