<div>
    <h2><route-back-button></route-back-button>Daily Run</h2>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="miles-form padded">
            <div class="row">
                <label class="col-md-4">
                    Select the Run Date:
                    <input type="date" class="form-control" [(ngModel)]="selectedDate" (ngModelChange)="onDateOrShiftChange()" />
                </label>

                <label class="col-md-4">
                    Select the Shift:
                    <select class="form-control" [(ngModel)]="selectedShift" (ngModelChange)="onDateOrShiftChange()">
                        <option *ngFor="let shift of millShifts" [value]="shift.Code">{{ shift.Code }}</option>
                    </select>
                </label>

                <button *ngIf="dailyRun" class="btn btn-success col-md-2 mt" (click)="getDailyRunEntries()">Run</button>

                <button *ngIf="resetAllFields" class="btn btn-danger mt ml" (click)="clearForm()">Reset All Fields</button>

                <button *ngIf="dailyRunExists" class="btn btn-danger mt ml" (click)="deleteDailyRun()">Delete Daily Run</button>                
            </div>

            <div *ngIf="showForm">
                <form [formGroup]="dailyRunForm" (ngSubmit)="formSubmitted()">
                    <hr />
                    <div formGroupName="DailyRunEntry">
                        <div class="row">
                            <div class="col-xs-4">
                                <dynamic-field [field]="dailyRunEntryFormControls.ThirdHandValues" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-4">
                                <dynamic-field [field]="dailyRunEntryFormControls.Backtender" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-4">
                                <dynamic-field [field]="dailyRunEntryFormControls.TourBoss" [form]="dailyRunForm"></dynamic-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-6">
                                <dynamic-field [field]="dailyRunEntryFormControls.Finishers" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-6">
                                <dynamic-field [field]="dailyRunEntryFormControls.Banders" [form]="dailyRunForm"></dynamic-field>
                            </div>
                        </div>

                        <hr />
                        <div class="col-md-12 pb">
                            <legend>Sales Order(s)</legend>
                            <fieldset formArrayName="DailyRunSalesOrders">
                                <div *ngFor="let salesOrder of salesOrdersArray.controls; let i = index" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="row">
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.SalesOrderId"
                                                        [form]="salesOrder"
                                                        (valueChanges)="pullSalesOrderValues(i)"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.Bundles"
                                                        [form]="salesOrder"
                                                        (valueChanges)="calculateBdlsPerPallet(i)"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.BoardGrade"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.Finish"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.Caliper"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.Basis"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.NumberOfPallets"
                                                        [form]="salesOrder"
                                                        (valueChanges)="calculateBdlsPerPallet(i)"
                                                    ></dynamic-field>
                                                </div>

                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.BdlsPerPallet"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>

                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.IsFortyPoundBundle"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>

                                                <div class="col-xs-2">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.CustomerName"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>

                                                <div class="col-xs-4">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunSalesOrdersFormControls.Detail"
                                                        [form]="salesOrder"
                                                    ></dynamic-field>
                                                </div>

                                            </div>
                                            <div class="row ml" *ngIf="salesOrdersArray.length > 0 && isSalesOrderIdNullOrZero(i)">
                                                <legend>Stock Board</legend>
                                                <fieldset>
                                                    <div class="col-xs-2">
                                                        <dynamic-field
                                                            [overrideForm]="true"
                                                            [field]="dailyRunSalesOrdersFormControls.IsRunSheetStock"
                                                            [form]="salesOrder"
                                                        ></dynamic-field>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div class="col-md-1">
                                            <i class="fa fa-times center" (click)="removeSalesOrderInput(i)"></i>
                                        </div>
                                    </div>

                                    <hr />
                                </div>
                                <div class="fab-wrap">
                                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addNewSalesOrder()">
                                        <span class="fa fa-plus"></span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>

                        <hr />

                        <div class="col-md-12 pb">
                            <legend>Lost Time</legend>
                            <fieldset formArrayName="DailyRunLostTimes">
                                <div *ngFor="let lostTime of lostTimeArray.controls; let i = index" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="row">
                                                <div class="col-xs-3">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunLostTimeFormControls.Down"
                                                        [form]="lostTime"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-3">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunLostTimeFormControls.Up"
                                                        [form]="lostTime"
                                                    ></dynamic-field>
                                                </div>
                                                <div class="col-xs-5">
                                                    <dynamic-field
                                                        [overrideForm]="true"
                                                        [field]="dailyRunLostTimeFormControls.Reason"
                                                        [form]="lostTime"
                                                    ></dynamic-field>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                        <div class="col-md-1">
                                            <i class="fa fa-times center" (click)="removeLostTimeInput(i)"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="fab-wrap">
                                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addDailyRunLostTimeInput()">
                                        <span class="fa fa-plus"></span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-xs-3">
                                <dynamic-field [field]="dailyRunEntryFormControls.Breaks" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field [field]="dailyRunEntryFormControls.Hoppers" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field [field]="dailyRunEntryFormControls.Setups" [form]="dailyRunForm"></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field [field]="dailyRunEntryFormControls.SuperStock" [form]="dailyRunForm"></dynamic-field>
                            </div>
                        </div>

                        <hr />
                    </div>
                    <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">Save</button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
                </form>
            </div>
        </div>
    </div>
</div>
