<div class="miles-editable miles-card padded" *ngIf="!isEditing" (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false">
    <h4>{{ entityName }}</h4>

    <entity-list [entities]="items" [total]="total" (onItemSelected)="itemSelected($event)"
        [entityListConfig]="entityListConfig"></entity-list>

    <div *ngIf="entityListCardOptions.canAdd" class="fab-wrap">
        <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addItem()">
            <span class="fa fa-plus"></span>
        </button>
    </div>
    <div class="pull-right max-of-total">
        <span *ngIf="showTotalCount">{{ items.length }} of {{ total }}</span>
    </div>
    <div class="show-on-hover">
        <a (click)="seeAll()" class="btn btn-primary btn-flat see-all">Open Search
            <span *ngIf="showTotalCount" class="badge">{{ total }}</span>
        </a>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>{{ entityName }}</h4>
    <app-dynamic-form *ngIf="config" [config]="config.formObject" (submitted)="formSubmitted($event)">
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancel()">
            Cancel
        </button>
    </app-dynamic-form>
</div>