import { OverrideNumericFunctionsEmptyWhenZero, OverrideNumericFunctionsEmptyWhenZeroAndTruncate } from '@common/common-functions.form-controls';
import { ReceiptDetailDynamicControls, IReceiptDetailDynamicControlsParameters } from '../form-controls/receipt-detail.form-controls';
import { IReceiptDetail } from '../interfaces/receipt-detail';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { IFractionsDynamicFieldOptions } from '@common/services/fractions.service';
import { DynamicField, LabelPosition, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class ReceiptDetailDynamicControlsPartial extends ReceiptDetailDynamicControls {
    constructor(receiptdetailPartial?: IReceiptDetail, additionalParameters?: IReceiptDetailDynamicControlsParameters) {
        super(receiptdetailPartial, additionalParameters);

        (<DynamicField>this.Form.Bundles).type.scale = 2;

        (<DynamicField>this.Form.CustomerPurchaseOrderNumber).label = 'Customer Purchase Order #';
        (<DynamicField>this.Form.CustomerPurchaseOrderNumber).labelHtml = '<label>Customer Purchase Order #</label>';

        this.Form.Length.component = FractionsDynamicFieldComponent;
        const lengthOptions: IFractionsDynamicFieldOptions = { maxFactor: 8 };
        (<DynamicField>this.Form.Length).customOptions = lengthOptions;

        (<DynamicField>this.Form.NumberOfSkids).label = '# Skids';
        (<DynamicField>this.Form.NumberOfSkids).labelHtml = '<label># Skids</label>';

        (<DynamicField>this.Form.PrintPartInfo).labelHtml = '<label>&nbsp;Print Part Info</label>';

        (<DynamicField>this.Form.ReceiptSizeTotalId).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });
        (<DynamicField>this.Form.ReceiptSizeTotalId).type.inputType = SelectInputTypes.OldStyleRadioButtonList;

        (<DynamicField>this.Form.ShipOnTypeId).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });

        (<DynamicField>this.Form.ShowBundles).label = 'Bundles';
        (<DynamicField>this.Form.ShowBundles).labelHtml = '<label>&nbsp;Bundles</label>';

        (<DynamicField>this.Form.ShowPieces).label = 'Pieces';
        (<DynamicField>this.Form.ShowPieces).labelHtml = '<label>&nbsp;Pieces</label>';

        (<DynamicField>this.Form.ShowSkidIds).label = 'Skid IDs';
        (<DynamicField>this.Form.ShowSkidIds).labelHtml = '<label>&nbsp;Skid IDs</label>';

        (<DynamicField>this.Form.ShowWeight).label = 'Weight';
        (<DynamicField>this.Form.ShowWeight).labelHtml = '<label>&nbsp;Weight</label>';

        (<DynamicField>this.Form.TrailerHeavyWeight).label = 'Heavy Wgt.';
        (<DynamicField>this.Form.TrailerHeavyWeight).labelHtml = '<label>Heavy Wgt.</label>';

        (<DynamicField>this.Form.TrailerLightWeight).label = 'Light Wgt.';
        (<DynamicField>this.Form.TrailerLightWeight).labelHtml = '<label>Light Wgt.</label>';

        (<DynamicField>this.Form.TrailerNumber).label = 'Trailer #';
        (<DynamicField>this.Form.TrailerNumber).labelHtml = '<label>Trailer #</label>';

        this.Form.Width.component = FractionsDynamicFieldComponent;
        const widthOptions: IFractionsDynamicFieldOptions = { maxFactor: 8 };
        (<DynamicField>this.Form.Width).customOptions = widthOptions;

        OverrideNumericFunctionsEmptyWhenZero([
            this.Form.Count,
            this.Form.Basis,
            this.Form.Caliper,
            this.Form.Weight,
            this.Form.Pieces,
            this.Form.NumberOfSkids,
            this.Form.TrailerLightWeight,
            this.Form.TrailerHeavyWeight,
        ]);

        OverrideNumericFunctionsEmptyWhenZeroAndTruncate([this.Form.Bundles]);
    }
}
