import { Component, OnInit } from '@angular/core';
import { DailyRunEntryEntityListConfig } from './daily-run-entry-list.entity-list-config';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { DailyRunEntryService } from '../services/daily-run-entries.service';
import { IDailyRunEntry } from '@model/interfaces/daily-run-entry';
import { IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { MillShiftService } from '../../skids/millshift.service';
import { map } from 'rxjs/operators';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { DateService } from '@common/services/date.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-daily-run-entry-list',
    templateUrl: './daily-run-entry-list.component.html',
})
export class DailyRunEntryListComponent implements OnInit {
    mainQuery = '';
    entityListConfig = new DailyRunEntryEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';
    ordersPerPage = 25;
    currentPage = 1;
    total: number;
    dailyRunDateStart: Date = null;
    dailyRunDateEnd: Date = null;
    dailyRunEntries: IDailyRunEntry[] = [];
    millShifts: MtSearchFilterItem[] = [];
    customerQuery: string;
    orderNumberquery: string;

    constructor(private dailyRunEntryService: DailyRunEntryService, private millShiftService: MillShiftService, private dateService: DateService, private route: Router) {}

    ngOnInit(): void {
        this.millShiftService
            .getItems()
            .pipe(
                map((entities) => {
                    return entities.map((entity) => {
                        const code = entity.Code;
                        const updatedEntity = {
                            ...entity,
                            Name: code,
                        };
                        return new MtSearchFilterItem(updatedEntity, false);
                    });
                }),
            )
            .subscribe((shifts) => (this.millShifts = shifts));

        this.getDailyRunEntries();
    }

    getDailyRunEntries(): void {
        const search = this.mainQuery;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.ordersPerPage,
            take: this.ordersPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.dailyRunEntryService.get(searchparams).subscribe((answer) => {
            if (answer && answer.body) {
                const formattedEntries = answer.body.map((entry) => {
                    if (entry.RunDate) {
                        const runDate = new Date(entry.RunDate);
                        entry.RunDate = this.dateService.formatRunDate(runDate.toDateString()) as unknown as Date;
                    }
                    return entry;
                });
                this.dailyRunEntries = formattedEntries;
                this.total = +answer.headers.get('X-List-Count');
            } else {
                this.dailyRunEntries = [];
            }
        });
    }

    onDailyRunSelected(event: IItemSelectedEvent): void {
        const queryParams: any = {};

        if (event.entity && event.entity.RunDate) {
            queryParams.RunDate = event.entity.RunDate;
        }

        if (event.entity && event.entity.ShiftCode) {
            queryParams.Shift = event.entity.ShiftCode;
        }

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `#/daily-run-entries/add?${queryString}`;

        window.open(url, '_blank');
    }

    search(query: string): void {
        this.currentPage = 1;
        this.mainQuery = query;
        this.getDailyRunEntries();
    }

    searchOrderNumber(query: string): void {
        this.currentPage = 1;
        this.orderNumberquery = query;
        this.getDailyRunEntries();
    }

    searchCustomer(query: string): void {
        this.currentPage = 1;
        this.customerQuery = query;
        this.getDailyRunEntries();
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedMillShiftIds: number[] = this.getSelectedFilters(this.millShifts);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'MillShiftIds',
                valueArray: selectedMillShiftIds,
            }),
        );
        if (this.dailyRunDateStart) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DailyRunDateStart',
                    value: this.getDateValueForParams(this.dailyRunDateStart),
                }),
            );
        }
        if (this.dailyRunDateEnd) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DailyRunDateEnd',
                    value: this.getDateValueForParams(this.dailyRunDateEnd),
                }),
            );
        }
        if (this.orderNumberquery) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'OrderNumber',
                    value: this.orderNumberquery,
                }),
            );
        }
        if (this.customerQuery) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'Customer',
                    value: this.customerQuery,
                }),
            );
        }

        return _extraSearchParams;
    }

    private getDateValueForParams(date: Date): string {
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return `${month}-${day}-${year}`;
    }

    dailyRunDateSelectionChanged(value: ISearchFilterDaterangeValue): void {
        this.currentPage = 1;
        this.dailyRunDateStart = value.startDate;
        this.dailyRunDateEnd = value.endDate;
        this.getDailyRunEntries();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDailyRunEntries();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getDailyRunEntries();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }
}
