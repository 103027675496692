import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { ITrailer } from '../interfaces/trailer';

export interface ITrailerDynamicControlsParameters {
    formGroup?: string;
}

export class TrailerDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private trailer?: ITrailer, additionalParameters?: ITrailerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Trailer';

        this.Form = {
            HeavyWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Heavy Weight',
                name: 'HeavyWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trailer && this.trailer.HeavyWeight || null,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trailer && this.trailer.Length || null,
            }),
            LightWeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Light Weight',
                name: 'LightWeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trailer && this.trailer.LightWeight || null,
            }),
            Number: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number',
                name: 'Number',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.trailer && this.trailer.hasOwnProperty('Number') && this.trailer.Number !== null ? this.trailer.Number.toString() : '',
            }),
        };

        this.View = {
            HeavyWeight: new DynamicLabel(
                'Heavy Weight',
                this.trailer && this.trailer.HeavyWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.trailer && this.trailer.Length || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            LightWeight: new DynamicLabel(
                'Light Weight',
                this.trailer && this.trailer.LightWeight || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Number: new DynamicLabel(
                'Number',
                this.trailer && this.trailer.hasOwnProperty('Number') && this.trailer.Number !== null ? this.trailer.Number.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
