import { ChangeDetectorRef, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerShippingAddressDynamicControlsPartial } from '../../model/partials/customer-shipping-address.form-controls';
import { ICustomerShippingAddress } from '../../model/interfaces/customer-shipping-address';
import { CustomerShippingAddressService } from '../shared-entities/customer-shipping-address.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'customer-shipping-address-add',
    templateUrl: './customer-shipping-address-add.component.html',
})
export class CustomerShippingAddressAddComponent implements OnInit {
    // abstract controls
    abstractAddressControls: any;
    abstractCustomerShippingAddressControls: any;

    customerShippingAddressAddForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    shippingAddress: ICustomerShippingAddress;

    @Input('customerId') customerId: number;
    @Output('onSave') onSave = new EventEmitter<ICustomerShippingAddress>();
    @Output('onCancel') onCancel = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private customerShippingAddressService: CustomerShippingAddressService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.customerShippingAddressAddForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.shippingAddress = {
            Address: {
                Address1: '',
                Address2: '',
                City: '',
                CountryCode: 'US',
                Id: 0,
                Province: '',
                StateCode: 'AL',
                Zip: '',
            },
            AddressId: 0,
            Archived: false,
            CustomerCode: '',
            CustomerId: 0,
            Id: 0,
            IsPrimary: false,
            ShipToCode: '',
            ShipToName: '',
        };

        this.abstractCustomerShippingAddressControls = new CustomerShippingAddressDynamicControlsPartial(
            this.shippingAddress,
            'CustomerShippingAddress',
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            CustomerShippingAddress: this.fb.group({}),
        });
    }

    createPayload(): ICustomerShippingAddress {
        const val = this.customerShippingAddressAddForm.value.CustomerShippingAddress;

        return {
            Address: {
                Address1: val.Address1,
                Address2: val.Address2,
                City: val.City,
                CountryCode: val.CountryCode,
                Id: 0,
                Province: val.Province,
                StateCode: val.StateCode,
                Zip: val.Zip,
            },
            AddressId: 0,
            Archived: false,
            CustomerCode: '',
            CustomerId: this.customerId,
            Id: 0,
            IsPrimary: val.IsPrimary,
            ShipToCode: '',
            ShipToName: val.ShipToName,
        };
    }

    formSubmitted(): void {
        if (this.customerShippingAddressAddForm.valid) {
            // save logic here
            const newAddress: ICustomerShippingAddress = this.createPayload();

            this.customerShippingAddressService
                .saveEntity(this.customerId, newAddress)
                .pipe(finalize(() => this.enableDoubleClick()))
                .subscribe((answer) => {
                    newAddress.Id = answer;
                    this.onSave.emit(newAddress);
                });
        } else {
            common.markAllFormFieldsAsTouched(this.customerShippingAddressAddForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onCancel.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Shipping address saved successfully.');
    }
}
