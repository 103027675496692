import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel } from '@angular/forms';
import { Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { IMillCorpInvoice } from '@model/interfaces/mill-corp-invoice';
import { IExpandableObject } from '@model/expandable-object';
import { IShipment } from '@model/interfaces/shipment';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { OrderTypeIds } from '@model/OrderTypes';
import { IMillCorpInvoiceDTO } from '@model/dto/mill-corp-invoice-dto';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { CommonService } from '@common/services/common.service';
import { StateMileageDynamicControlsPartial } from '@model/partials/state-mileage-partial.form-controls';
import { MillCorpInvoiceDynamicControlsPartial } from '@model/partials/mill-corp-invoice-partial.form-controls';

import { MillCorpInvoiceService } from '../services/mill-corp-invoice.service';
import { MillCorpCustomerService } from '../services/mill-corp-customer.service';
import { BackhaulInfoDynamicControlsPartial } from '@model/partials/backhaul-info-partial.form-controls';
import { IMillCorpCustomer } from '@model/interfaces/mill-corp-customer';
import { IMillCorpInvoiceLoadNumberDTO } from '@model/dto/mill-corp-invoice-load-number-dto';
import { DynamicField, IDynamicField } from '@mt-ng2/dynamic-form';

interface ISelectedOrderDetails {
    CustomerName: string;
    ShipDate: Date;
    OrderDate: Date;
    ReceiptNumber: string;
    ShipTo: ICustomerShippingAddress;
    ProductSpectOnTruck: string;
    CustomerPONumber: string;
    CustomerProductNumber: string;
    OurOrderNumber: number;
    StopNumberFromLoad: number;
    TrailerNumber: string;
}

@Component({
    selector: 'app-mill-corp-invoice',
    styleUrls: ['mill-corp-invoice.component.less'],
    templateUrl: './mill-corp-invoice.component.html',
})
export class MillCorpInvoiceComponent implements OnInit, OnDestroy {
    canEdit: boolean;
    backhaulInfoCap = 5;

    @ViewChild('shipmentIdInput') shipmentIdInput: NgModel;
    shipmentId$: Subject<number> = new Subject<number>();

    millCorpInvoice: IMillCorpInvoice;
    millCorpInvoiceForm: FormGroup;
    millCorpInvoiceFormControls: IExpandableObject;
    backhaulInfoFormControls: IExpandableObject;
    stateMileageFormControls: IExpandableObject;

    shipment: IShipment;
    salesOrders: ISalesOrder[];
    selectedOrderId: number;
    orderDetails: ISelectedOrderDetails;

    millCorpCustomers: IMillCorpCustomer[];
    isBlankLoad = false;
    deliveryDateInput: Date;
    loadsByDeliveryDate: IMillCorpInvoiceLoadNumberDTO[];
    selectedMillCorpInvoiceId: number;

    millCorpCustomerDynamicFields: IDynamicField[] = [];
    millCorpCustomersLoaded = false;

    get invoiceGroup(): FormGroup {
        return this.millCorpInvoiceForm.get('MillCorpInvoice') as FormGroup;
    }

    get mileage(): number {
        return this.invoiceGroup.value.MileageIn > 0 && this.invoiceGroup.value.MileageOut > 0
            ? this.invoiceGroup.value.MileageIn - this.invoiceGroup.value.MileageOut
            : 0;
    }

    get mpg(): number {
        return this.invoiceGroup.value.Gallons > 0 ? this.mileage / this.invoiceGroup.value.Gallons : 0;
    }

    get mileageTotal(): number {
        return this.stateMileages.value.reduce((acc, sm) => acc + sm.Mileage, 0);
    }

    get stopOffsTotal(): number {
        return this.invoiceGroup.value.StopQty * this.invoiceGroup.value.StopRate;
    }

    get palletsTotal(): number {
        return this.invoiceGroup.value.PalletQty * this.invoiceGroup.value.PalletRate;
    }

    get topsTotal(): number {
        return this.invoiceGroup.value.TopQty * this.invoiceGroup.value.TopRate;
    }

    get waitingTotal(): number {
        return this.invoiceGroup.value.WaitingQty * this.invoiceGroup.value.WaitingRate;
    }

    get freightRevenue(): number {
        return this.invoiceGroup.value.Freight + this.stopOffsTotal;
    }

    get wastePickUp(): number {
        return this.invoiceGroup.value.WastePaperValue || 0;
    }

    get otherMaterial(): number {
        return this.palletsTotal + this.topsTotal + this.waitingTotal + this.invoiceGroup.value.MiscPickUpValue;
    }

    get grandTotal(): number {
        return this.freightRevenue + this.wastePickUp + this.otherMaterial;
    }

    get backhaulInfo(): FormArray {
        return this.millCorpInvoiceForm.get('MillCorpInvoice.BackhaulInfo') as FormArray;
    }

    get stateMileages(): FormArray {
        return this.millCorpInvoiceForm.get('MillCorpInvoice.StateMileages') as FormArray;
    }

    constructor(
        private millCorpInvoiceService: MillCorpInvoiceService,
        private notificationsService: NotificationsService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private millCorpCustomerService: MillCorpCustomerService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.commonService.getStates(),
            this.millCorpCustomerService.getItems(),
        ])
        .subscribe(([states, millCorpCustomers]) => {
            this.millCorpCustomers = millCorpCustomers;
            this.millCorpInvoiceFormControls = new MillCorpInvoiceDynamicControlsPartial(null).Form;
            this.backhaulInfoFormControls = new BackhaulInfoDynamicControlsPartial(null, { millCorpCustomers: millCorpCustomers }).Form;
            this.stateMileageFormControls = new StateMileageDynamicControlsPartial(null, { states }).Form;

            this.millCorpInvoiceForm = this.fb.group({
                MillCorpInvoice: this.fb.group({
                    BackhaulInfo: this.fb.array([]),
                    StateMileages: this.fb.array([]),
                }),
            });

            this.shipmentId$
                .pipe(
                    debounceTime(750),
                    filter((shipmentId) => shipmentId > 0),
                    switchMap((shipmentId) => this.millCorpInvoiceService.getByShipment(shipmentId)),
                    catchError(() => of(null)), // Prevent errors from closing the observable
                )
                .subscribe(this.handleMillCorpInvoiceDTO.bind(this));
        });
    }

    ngOnDestroy(): void {
        this.shipmentId$.unsubscribe();
    }

    private clearForm(): void {
        this.shipment = null;
        this.salesOrders = null;
        this.selectedOrderId = null;
        this.orderDetails = null;
        this.millCorpInvoice = null;
        this.backhaulInfo.clear();
        this.millCorpCustomerDynamicFields = [];
        this.millCorpCustomersLoaded = false;
        this.stateMileages.clear();
    }

    private clearInputs(): void {
        this.isBlankLoad = false;
        this.deliveryDateInput = null;
        this.selectedMillCorpInvoiceId = null;
    }

    private handleMillCorpInvoiceDTO(dto: IMillCorpInvoiceDTO): void {
        this.clearForm();

        if (!dto) {
            this.notificationsService.error('Error retrieving Mill Corp Invoice.');
            this.clearInputs();
            return;
        }

        if (!this.isBlankLoad && !dto.Shipment) {
            this.notificationsService.error('No load found with that number.');
            this.clearInputs();
            return;
        }

        this.shipment = dto.Shipment;
        this.salesOrders = dto.SalesOrders ?? [];
        this.selectedOrderId = this.salesOrders[0]?.Id;
        this.millCorpInvoice = dto.MillCorpInvoice ?? this.millCorpInvoiceService.getEmptyMillCorpInvoice();
        this.millCorpInvoice.ShipmentId = this.shipment?.Id;

        if (!this.isBlankLoad) {
            this.orderChanged();
        }

        this.addBackhaulInfoInputs(this.millCorpInvoice.BackhaulInfo.length);
        this.addStateMileageInputs(this.millCorpInvoice.StateMileages.length);

        setTimeout(() => {
            this.millCorpInvoiceForm.patchValue({ MillCorpInvoice: this.millCorpInvoice });

            this.millCorpCustomersLoaded = false;
            this.millCorpCustomerDynamicFields
                .forEach((field, i) => {
                    field.value = this.millCorpInvoice.BackhaulInfo[i].MillCorpCustomerId;
                });
            this.millCorpCustomersLoaded = true;
        });
    }

    cancel(): void {
        this.clearForm();
        this.clearInputs();
        this.shipmentIdInput?.reset();
    }

    formSubmitted(): void {
        if (this.millCorpInvoiceForm.valid) {
            this.saveMillCorpInvoice();
        } else {
            markAllFormFieldsAsTouched(this.millCorpInvoiceForm);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    private saveMillCorpInvoice(): void {
        Object.assign(this.millCorpInvoice, this.millCorpInvoiceForm.value.MillCorpInvoice);
        this.millCorpInvoiceService.updateWithFks(this.millCorpInvoice).subscribe((mci: IMillCorpInvoiceLoadNumberDTO) => {
            this.millCorpInvoice.Id = mci.MillCorpInvoiceId;
            this.millCorpInvoice.MillCorpLoadNumber = mci.LoadNumber;
            this.success();
        });
    }

    private success(): void {
        this.notificationsService.success('Mill Corp Invoice saved successfully.');
    }

    getOrderDimensions(salesOrder: ISalesOrder): string {
        if (salesOrder.OrderTypeId === OrderTypeIds.Stock) {
            return 'STOCK';
        } else {
            return `${salesOrder.ManufacturingOrderDetail.Width} x ${salesOrder.ManufacturingOrderDetail.Length}`;
        }
    }

    orderChanged(): void {
        const salesOrder = this.salesOrders.find((so) => so.Id === this.selectedOrderId);
        const receipt = this.shipment.Receipts.find((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === salesOrder.Id));
        const receiptDetail = receipt.ReceiptDetails.find((rd) => rd.SalesOrderId === salesOrder.Id);
        const numberOfPallets =
            salesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                ? receiptDetail.NumberOfSkids
                : receipt.ReceiptDetails.reduce((acc, rd) => acc + rd.NumberOfSkids, 0);
        const weight =
            salesOrder.OrderTypeId === OrderTypeIds.Manufacturing
                ? receiptDetail.Weight
                : receipt.ReceiptDetails.reduce((acc, rd) => acc + rd.Weight, 0);

        this.orderDetails = {
            CustomerName: salesOrder.OrderInfo.Customer.CustomerName,
            CustomerPONumber: salesOrder.OrderInfo.CustomerPurchaseOrderNumber,
            CustomerProductNumber: salesOrder.OrderInfo.OrderCustomerSpecification.ReceiptPartInfo,
            OrderDate: salesOrder.OrderInfo.OrderDate,
            OurOrderNumber: salesOrder.Id,
            ProductSpectOnTruck: `${numberOfPallets} | ${this.getOrderDimensions(salesOrder)} | ${weight}`,
            ReceiptNumber: receipt.Number,
            ShipDate: this.shipment.ShipmentDate,
            ShipTo: salesOrder.OrderInfo.CustomerShippingAddress,
            StopNumberFromLoad: receipt.Stop,
            TrailerNumber: receiptDetail.TrailerNumber,
        };
    }

    addBackhaulInfoInputs(qty = 1): void {
        for (let i = 0; i < qty; i++) {
            this.backhaulInfo.push(this.fb.group({
                Description: new FormControl(null),
                DropOffTrailer: new FormControl(null),
                MillCorpCustomerId: new FormControl(null),
                PickupTrailer: new FormControl(null),
            }));

            this.millCorpCustomerDynamicFields.push(
                new DynamicField(this.backhaulInfoFormControls.MillCorpCustomerId),
            );
        }
    }

    removeBackhaulInfoInput(index: number): void {
        this.backhaulInfo.removeAt(index);
        this.millCorpCustomerDynamicFields.splice(index, 1);
    }

    addStateMileageInputs(qty = 1): void {
        for (let i = 0; i < qty; i++) {
            this.stateMileages.push(this.fb.group({ Statecode: new FormControl(null), Mileage: new FormControl(null) }));
        }
    }

    removeStateMileageInput(index: number): void {
        this.stateMileages.removeAt(index);
    }

    createBlankLoad(): void {
        this.cancel();
        this.isBlankLoad = true;
        this.millCorpInvoice = this.millCorpInvoiceService.getEmptyMillCorpInvoice();
        this.millCorpInvoice.ShipmentId = null;
        this.deliveryDateInput = null;
        this.millCorpCustomersLoaded = true;
    }

    getLoadsByDeliveryDate(): void {
        if (this.deliveryDateInput) {
            this.millCorpInvoiceService
                .getLoadNumbersByDeliveryDate(this.deliveryDateInput)
                .subscribe((res) => this.loadsByDeliveryDate = res);
        }

    }

    loadNumberSelected(): void {
        this.clearForm();
        const selectedLoad
            = this.loadsByDeliveryDate.find((load) => load.MillCorpInvoiceId === this.selectedMillCorpInvoiceId);

        if (selectedLoad) {
            // if shipment load go with the regular flow
            if (selectedLoad.LoadNumber.indexOf('MC') === -1) {
                this.shipmentId$.next(+selectedLoad.LoadNumber);
            } else {
                // Mill Corp Load
                this.millCorpInvoiceService
                    .getById(selectedLoad.MillCorpInvoiceId)
                    .subscribe((load) => {
                        this.isBlankLoad = true;
                        this.handleMillCorpInvoiceDTO({
                            MillCorpInvoice: load,
                            SalesOrders: null,
                            Shipment: null,
                        });
                    });
            }
        }
    }
}
