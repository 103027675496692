<div class="miles-card padded">
    <h4>Print Trim Sheet</h4>
    <div class="pull-left datepicker" *ngIf="formCreated">
        <dynamic-field [field]="dateInputField" (valueChanges)="dateInputValue = $event"></dynamic-field>
    </div>
    <div class="pull-left m-l-md">
        <button class="btn btn-secondary" (click)="print()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
            <i class="fa fa-fw fa-print"></i> Print
        </button>
        <button class="btn btn-secondary" (click)="edit()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
            <i class="fa fa-fw fa-edit"></i> View / Edit
        </button>
        <button
            class="btn btn-secondary"
            (click)="printPalletSkidOrderSheet()"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
        >
            <i class="fa fa-fw fa-print"></i> Print Pallet Order Sheet
        </button>
    </div>
    <div class="clearfix"></div>
</div>
