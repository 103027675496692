import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { TrailerService } from '../trailer.service';
import { ITrailer } from '../../model/interfaces/trailer';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { TrailersEntityListConfig } from './trailers-list.entity-list-config';

@Component({
    selector: 'app-trailers',
    templateUrl: './trailers-list.component.html',
})
export class TrailersListComponent implements OnInit {
    trailers: ITrailer[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new TrailersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddTrailer = false;

    constructor(private trailerService: TrailerService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddTrailer = this.claimsService.hasClaim(ClaimTypes.Shipments, [ClaimValues.FullAccess]);
        this.getTrailers();
    }

    getTrailers(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.trailerService.get(searchparams).subscribe((answer) => {
            this.trailers = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.getTrailers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getTrailers();
    }

    trailerSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/trailers', event.entity.Id]);
    }
}
