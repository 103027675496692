import { NgModule } from '@angular/core';

import { ContextMenuModule } from 'ngx-contextmenu';
import { SharedModule } from '../common/shared.module';
import { CommonModule } from '@angular/common';
import { ShipmentRoutingModule } from './shipment-routing.module';
import { SkidModule } from '../skids/skid.module';

import { LoadSkidComponent } from './load-skid/load-skid.component';
import { OpenExistingShipmentComponent } from './open-existing-shipment/open-existing-shipment.component';
import { OpenShipmentsListComponent } from './open-shipments-list/open-shipments-list.component';
import { ShipmentsListComponent } from './shipments-list/shipments-list.component';
import { ShipmentScheduleComponent } from './shipment-schedule/shipment-schedule.component';
import { ShipmentDetailComponent } from './shipment-detail/shipment-detail.component';
import { ShipmentSkidComponent } from './shipment-skids/shipment-skid.component';
import { ShipmentReceiptComponent } from './shipment-receipt/shipment-receipt.component';
import { BillOfLadingComponent } from './bill-of-lading/bill-of-lading.component';
import { TrailerInfoComponent } from './trailer-info/trailer-info.component';
import { TrailersListComponent } from './trailer-list/trailers-list.component';

import { ShipmentService } from './shipment.service';
import { ShipmentStatusService } from './shipmentstatus.service';
import { PaymentNoteService } from './payment-note.service';
import { TrailerService } from './trailer.service';
import { HaulerService } from './hauler.service';
import { SelectShipmentDateComponent } from './select-shipment-date/select-shipment-date.component';
import { FormsModule } from '@angular/forms';
import { TakeFromOrderComponent } from './take-from-order/take-from-order.component';
import { AssignTrailerComponent } from './assign-trailer/assign-trailer.component';
import { ShipmentSettingsComponent } from './shipment-settings/shipment-settings.component';
import { UpdatePoNumbersComponent } from './update-po-numbers/update-po-numbers.component';
import { ShipmentReceiptDetailsComponent } from './shipment-receipt/shipment-receipt-details/shipment-receipt-details.component';
import { StockReceiptDetailsComponent } from './shipment-receipt/stock-receipt-details/stock-receipt-details.component';
import { ReceiptsModule } from '../receipts/receipts.module';

@NgModule({
    declarations: [
        ShipmentsListComponent,
        LoadSkidComponent,
        OpenExistingShipmentComponent,
        OpenShipmentsListComponent,
        ShipmentDetailComponent,
        ShipmentReceiptComponent,
        ShipmentScheduleComponent,
        ShipmentSkidComponent,
        BillOfLadingComponent,
        TrailerInfoComponent,
        TrailersListComponent,
        SelectShipmentDateComponent,
        TakeFromOrderComponent,
        AssignTrailerComponent,
        ShipmentSettingsComponent,
        UpdatePoNumbersComponent,
        ShipmentReceiptDetailsComponent,
        StockReceiptDetailsComponent,
    ],
    exports: [ShipmentSkidComponent],
    imports: [CommonModule, ContextMenuModule, SharedModule, ShipmentRoutingModule, SkidModule, FormsModule],
})
export class ShipmentModule {
    static forRoot(): any {
        return {
            ngModule: ShipmentModule,
            providers: [PaymentNoteService, ShipmentService, ShipmentStatusService, TrailerService, HaulerService],
        };
    }
}
