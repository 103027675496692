import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module-config';

import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    // this section is an example only, and should be configured
    new NavSidebarParentRowItem({
        // with routes from the src/app/app-nav-module.ts getMenu() function
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        link: '',
        claimType: ClaimTypes.AppSettings,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        expanded: false,
        settingsClaimValues: [ClaimValues.FullAccess],
        settingsLink: '/settings',
        children: [
            new NavSidebarRowItem({
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                claimType: ClaimTypes.Users,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
                addClaimValues: [ClaimValues.FullAccess],
                addLink: '/users/add',
            }),
            new NavSidebarRowItem({
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                claimType: ClaimTypes.UserRoles,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
                addClaimValues: [ClaimValues.FullAccess],
                addLink: 'roles/add',
            }),
            new NavSidebarRowItem({
                content: 'Trailers',
                icon: 'fa fa-fw fa-truck',
                link: '/trailers',
                claimType: ClaimTypes.Shipments,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
                addLink: '/trailers/add',
                addClaimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'Customers',
        icon: 'fa fa-fw fa-briefcase',
        link: '/customers',
        claimType: ClaimTypes.Customers,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        settingsLink: '/customers/settings',
        settingsClaimType: ClaimTypes.AppSettings,
        settingsClaimValues: [ClaimValues.FullAccess],
        addLink: '/customers/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Customer Contacts',
        icon: 'fa fa-fw fa-user-circle',
        link: '/contacts',
        claimType: ClaimTypes.Customers,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    }),
    new NavSidebarParentRowItem({
        content: 'Orders',
        icon: 'fa fa-fw fa-briefcase',
        link: '/salesorders',
        claimType: ClaimTypes.Orders,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        expanded: true,
        settingsLink: '/salesorders/settings',
        settingsClaimType: ClaimTypes.AppSettings,
        settingsClaimValues: [ClaimValues.FullAccess],
        children: [
            new NavSidebarRowItem({
                content: 'Sales Orders',
                icon: 'fa fa-fw fa-truck',
                link: '/salesorders-manufacturing',
                claimType: ClaimTypes.Orders,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
                addLink: '/salesorders-manufacturing/add',
                addClaimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Stock Orders',
                icon: 'fa fa-fw fa-briefcase',
                link: '/salesorders-stock',
                claimType: ClaimTypes.Orders,
                claimValues: [ClaimValues.FullAccess],
                addLink: '/salesorders-stock/add',
                addClaimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Stock Inventory',
                icon: 'fa fa-fw fa-cubes',
                link: '/stock-inventory',
                claimType: [ClaimTypes.Orders_CanAccessAvailableInventory, ClaimTypes.Orders_CanAccessAvailableAndUnavailableInventory],
                claimValues: [ClaimValues.FullAccess],
                addClaimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'View Current Production',
        icon: 'fa fa-fw fa-eye',
        link: '/trim-setup-forecast',
        claimType: ClaimTypes.ViewCurrentProduction,
        claimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarParentRowItem({
        content: 'Mill Operations',
        icon: 'fa fa-fw fa-building',
        link: '/mill-operations',
        claimType: ClaimTypes.MillOperations,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        expanded: false,
        settingsLink: '/trim-configurations/settings',
        settingsClaimType: ClaimTypes.AppSettings,
        settingsClaimValues: [ClaimValues.FullAccess],
        children: [
            new NavSidebarRowItem({
                content: 'Trim Configuration',
                icon: 'fa fa-fw fa-scissors',
                link: '/trim-configurations',
                claimType: ClaimTypes.MillOperations_TrimSheets,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Backtender',
                icon: 'fa fa-fw fa-tachometer',
                link: '/trim-view',
                claimType: ClaimTypes.MillOperations_Backtender,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Packaging Line (Dr. J)',
                icon: 'fa fa-fw fa-barcode',
                link: '/packaging-line/drj',
                claimType: ClaimTypes.MillOperations_PackagingLines,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Packaging Line (A.I.)',
                icon: 'fa fa-fw fa-barcode',
                link: '/packaging-line/ai',
                claimType: ClaimTypes.MillOperations_PackagingLines,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Packaging Line (Dry End)',
                icon: 'fa fa-fw fa-barcode',
                link: '/packaging-line/dry-end',
                claimType: ClaimTypes.MillOperations_PackagingLines,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Stock Skids',
                icon: 'fa fa-fw fa-cube',
                link: '/stock-skid-processing',
                claimType: ClaimTypes.MillOperations_StockSkids,
                claimValues: [ClaimValues.FullAccess],
            }),
            // These are hidden for now as of 0.6.13 -- 9/25/20 pallen
            // new NavSidebarRowItem({
            //     content: 'Cutting',
            //     icon: 'fa fa-fw fa-scissors',
            //     link: '/cutting',
            //     claimType: ClaimTypes.ConvertingLines,
            //     claimValues: [ClaimValues.FullAccess],
            // }),
            // new NavSidebarRowItem({
            //     content: 'Pasting',
            //     icon: 'fa fa-fw fa-paste',
            //     link: '/pasting',
            //     claimType: ClaimTypes.ConvertingLines,
            //     claimValues: [ClaimValues.FullAccess],
            // }),
            new NavSidebarRowItem({
                content: 'Lining',
                icon: 'fa fa-fw fa-minus',
                link: '/lining',
                claimType: ClaimTypes.MillOperations_ConvertingLines,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Rotary Slit',
                icon: 'fa fa-fw fa-gears',
                link: '/rotary-slit',
                claimType: ClaimTypes.MillOperations_ConvertingLines,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Print Backtender Tag',
                icon: 'fa fa-fw fa-print',
                link: '/print-backtender-tag',
                claimType: ClaimTypes.MillOperations_PrintBacktenderTag,
                claimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
    new NavSidebarParentRowItem({
        content: 'Shipping',
        icon: 'fa fa-fw fa-truck',
        link: '/shipments',
        claimType: ClaimTypes.Shipments,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        settingsLink: '/shipments/settings',
        settingsClaimType: ClaimTypes.Shipments_ShippingSettings,
        settingsClaimValues: [ClaimValues.FullAccess],
        expanded: false,
        children: [
            new NavSidebarRowItem({
                content: 'Skid Data Maintenance',
                icon: 'fa fa-fw fa-cube',
                link: '/skids/create',
                claimType: ClaimTypes.Shipments_SkidDataMaintenance,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                addLink: '/shipments/add',
                addClaimType: ClaimTypes.Shipments_LoadPlanning,
                addClaimValues: [ClaimValues.FullAccess],
                content: 'Load Planning',
                icon: 'fa fa-fw fa-cubes',
                link: '/shipments/list',
                claimType: ClaimTypes.Shipments_LoadPlanning,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Load Fulfillment',
                icon: 'fa fa-fw fa-cubes',
                link: '/shipments/active',
                claimType: ClaimTypes.Shipments_LoadFulfillment,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Processed Skids',
                icon: 'fa fa-fw fa-th-list',
                link: '/processed',
                claimType: ClaimTypes.Shipments_ProcessedSkids,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Print Tag',
                icon: 'fa fa-fw fa-print',
                link: '/print-tag',
                claimType: ClaimTypes.Shipments_PrintTag,
                claimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
    new NavSidebarParentRowItem({
        content: 'Invoicing',
        icon: 'fa fa-fw fa-dollar',
        link: '/receipts',
        claimType: ClaimTypes.Receipts,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        expanded: false,
        children: [
            new NavSidebarRowItem({
                content: 'Receipt Entry',
                icon: 'fa fa-fw fa-file-o',
                link: '/shipments/receipts',
                claimType: ClaimTypes.Shipments,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Invoice Processing',
                icon: 'fa fa-fw fa-print',
                link: '/receipts/invoicing',
                claimType: ClaimTypes.Receipts,
                claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Receipt Adjustments',
                icon: 'fa fa-fw fa-edit',
                link: '/receipts/detail',
                claimType: ClaimTypes.Receipts,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Quickbooks Queue',
                icon: 'fa fa-fw fa-file-code-o',
                link: '/receipts/quickbooks-queue',
                claimType: ClaimTypes.Receipts,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Tons and Pricing',
                icon: 'fa fa-fw fa-bar-chart',
                link: '/receipts/tons-and-pricing',
                claimType: ClaimTypes.Receipts,
                claimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
    new NavSidebarRowItem({
        content: 'Mill Corp. Invoice',
        icon: 'fa fa-id-card',
        link: '/mill-corp-invoice',
        claimType: ClaimTypes.MillCorpInvoice,
        claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        settingsLink: '/mill-corp/settings',
        settingsClaimType: ClaimTypes.MillCorpInvoice,
        settingsClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarRowItem({
        content: 'Daily Run',
        icon: 'fa fa-book',
        link: '/daily-run-entries',
        claimType: ClaimTypes.DailyRunEntries,
        claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        addLink: '/daily-run-entries/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
    new NavSidebarParentRowItem({
        content: 'Converting Data',
        icon: 'fa fa-fw fa-database',
        link: '/converting-data',
        claimType: ClaimTypes.ConvertingData,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        settingsLink: '/converting-data/settings',
        settingsClaimType: ClaimTypes.ConvertingData,
        settingsClaimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
        expanded: false,
        children: [
            new NavSidebarRowItem({
                content: 'Cutting Entry',
                icon: 'fa fa-fw fa-scissors',
                link: '/converting-data/cutting',
                claimType: ClaimTypes.ConvertingData_CuttingTeamLogs,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Pasting Entry',
                icon: 'fa fa-fw fa-paste',
                link: '/converting-data/pasting',
                claimType: ClaimTypes.ConvertingData_PastingTeamLogs,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Lining - Guillotine',
                icon: 'fa fa-fw fa-angle-double-down',
                link: '/converting-data/lining-guillotine',
                claimType: ClaimTypes.ConvertingData_LiningGuillotineLogs,
                claimValues: [ClaimValues.FullAccess],
            }),
            new NavSidebarRowItem({
                content: 'Rotary Slit Entry',
                icon: 'fa fa-fw fa-gears',
                link: '/converting-data/rotary-slit-division',
                claimType: ClaimTypes.ConvertingData_RotarySlitDivisionLogs,
                claimValues: [ClaimValues.FullAccess],
            }),
        ],
    }),
];
