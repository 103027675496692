import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomerPartial } from '../../model/partials/customer';
import { SalesOrderService } from '../sales-order.service';
import { ISalesOrder } from '../../model/interfaces/sales-order';
import { ShipmentService } from '../../shipments/shipment.service';
import { ISkid } from '../../model/interfaces/skid';
import { IShipment } from '../../model/interfaces/shipment';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { SkidService } from '../../skids/skid.service';
import { IReceipt } from '../../model/interfaces/receipt';
import { PrintService } from '../../common/services/print.service';

@Component({
    selector: 'shipping-status',
    templateUrl: './shipping-status.component.html',
})
export class ShippingStatusComponent implements OnInit {
    @Input('salesOrderId') salesOrderId: number;

    customer: ICustomerPartial;
    salesOrder: ISalesOrder;
    skids: ISkid[];
    ordered: number;
    produced: number;
    converting: number;
    shipped: number;
    shipments: IShipment[] = [];
    selectedShipment: IShipment;
    skidsTaken: number;

    shipmentDetailModal: IModalWrapperApi;
    modalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: true,
        width: 800,
    };

    printedReceiptsExist: boolean;

    constructor(
        private router: Router,
        private notificationsService: NotificationsService,
        private salesOrderService: SalesOrderService,
        private activatedRoute: ActivatedRoute,
        private shipmentService: ShipmentService,
        private skidService: SkidService,
    ) {}

    ngOnInit(): void {
        if (!this.salesOrderId) {
            const salesOrderId = this.activatedRoute.snapshot.paramMap.get('salesOrderId');
            if (salesOrderId) {
                this.salesOrderId = +salesOrderId;
            }
        }
        this.getSalesOrderById(this.salesOrderId);
        this.checkReceiptsExist();
        this.getSkidsTakenFromOrder();
    }

    private getSkidsTakenFromOrder(): void {
        this.salesOrderService
            .getSkidsTakenFromOrder(this.salesOrderId)
            .subscribe((skidsTaken) => {
                this.skidsTaken = skidsTaken;
            });
    }

    private getSkids(): void {
        this.salesOrderService.getSkids(this.salesOrderId).subscribe((skids) => {
            this.skids = skids;
            this.processSkids();
        });
    }

    private checkReceiptsExist(): void {
        this.salesOrderService
            .printedReceiptsExist(this.salesOrderId)
            .subscribe((printedReceiptsExist) => {
                this.printedReceiptsExist = printedReceiptsExist;
            });
    }

    getSalesOrderById(salesOrderId: number): void {
        this.salesOrderService.getById(salesOrderId).subscribe((answer) => {
            this.salesOrder = answer;
            this.customer = null;
            if (this.salesOrder && this.salesOrder.OrderInfo && this.salesOrder.OrderInfo.Customer) {
                this.customer = this.salesOrder.OrderInfo.Customer;
            }
            this.ordered = 0;
            if (answer && answer.ManufacturingOrderDetail && answer.ManufacturingOrderDetail.NumberOfSkids) {
                this.ordered = answer.ManufacturingOrderDetail.NumberOfSkids;
            }
            this.getSkids();
        });
    }

    processSkids(): void {
        this.produced = this.skids.filter((item) => item.FinalBarcode).length;
        this.converting = this.salesOrder?.OrderDetailLines?.reduce((sum, odl) => {
                                    const convertedSkids = odl?.ConvertingSkids?.length;
                                    return sum + (convertedSkids ?? 0);
                                }, 0) ?? 0;
        this.shipped = this.skids.filter((skid) => skid.ShipmentId != null).length;

        const shipmentIds = new Set(this.skids.map((skid) => skid.ShipmentId).filter((id) => id != null));
        this.shipments = [];
        shipmentIds.forEach((shipmentId) => {
            if (shipmentId) {
                this.shipmentService.getShipment(shipmentId).subscribe((shipment) => this.shipments.push(shipment));
            }
        });
    }

    formSubmitted(): void {
        if (this.salesOrderId) {
            this.router.navigate(['salesorders-manufacturing', this.salesOrderId, 'shipping-status']);
            this.ngOnInit();
        } else {
            this.error('Please enter an order number.');
        }
    }

    getBalance(): number {
        return this.ordered - this.shipped;
    }

    getReceipts(shipment: IShipment): IReceipt[] {
        if (shipment.Receipts.length) {
            return shipment.Receipts.filter((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === this.salesOrderId));
        }
        return [];
    }

    getShipmentDate(shipment: IShipment): any {
        return this.shipmentService.getShipmentDate(shipment, this.salesOrderId);
    }

    getTotalGrossWeight(shipment: IShipment): number {
        const skidsForShipment = this.getSkidsForShipment(shipment.Id);
        if (skidsForShipment?.length) {
            let initialTotalGrossWeight = 0;
            const totalGrossWeight: number = skidsForShipment.reduce((sum, skid) => sum + skid.ActualWeight, initialTotalGrossWeight);
            return totalGrossWeight;
        }

        return 0;
    }

    getTotalNetWeight(shipment: IShipment): number {
        const skidsForShipment = this.getSkidsForShipment(shipment.Id);
        if (skidsForShipment?.length) {
            let initialTotalNetWeight = 0;
            const totalNetWeight: number = skidsForShipment.reduce((sum, skid) => {
                const skidTareWeight = skid.TareWeightTop + skid.TareWeightBottom;
                const skidNetWeight = skid.ActualWeight - skidTareWeight;
                return sum + skidNetWeight;
            }, initialTotalNetWeight);
            return totalNetWeight;
        }

        return 0;
    }

    getBilledPieces(shipment: IShipment): number {
        const skidsForShipment = this.getSkidsForShipment(shipment.Id);
        if (skidsForShipment?.length) {
            let initialBilledPieces = 0;
            const billedPieces: number = skidsForShipment.reduce((sum, skid) => sum + skid.ActualCount, initialBilledPieces);
            return billedPieces;
        }

        return 0;
    }

    getSkidsForShipment(shipmentId: number): ISkid[] {
        const skidsForShipment = this.skids.filter((skid) => skid.ShipmentId === shipmentId);
        return skidsForShipment;
    }

    error(message: string): void {
        this.notificationsService.error(message);
    }

    showShipmentDetails(shipment: IShipment): void {
        this.selectedShipment = shipment;
        this.shipmentDetailModal.show();
    }

    closeLoadSkidDialog(): void {
        this.shipmentDetailModal.close();
    }

    getShipmentBundleCount(shipment: IShipment): number {
        let count = 0;
        if (shipment.Skids?.length) {
            for (let index = 0; index < shipment.Skids.length; index++) {
                count += this.skidService.getBundles(shipment.Skids[index]);
            }
        }
        return count;
    }

    printShipment(shipmentId: number): void {
        this.shipmentService.printShipment(shipmentId).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    printProcessedSkids(): void {
        if (this.skidsTaken) {
            this.shipmentService.printProducedAndTakenSkids(this.salesOrderId)
                .subscribe((answer) => {
                    PrintService.printPdf(answer);
                });
        } else {
            this.shipmentService.printProcessedSkids(this.salesOrderId)
                .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
        }
    }

    printConvertingSkids(): void {
        this.shipmentService.printConvertingSkids(this.salesOrderId).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    navigateToViewProduction(): void {
        this.router.navigate(['salesorders-manufacturing', this.salesOrderId, 'view-production']);
    }

    printSkidsTaken(): void {
        if (this.skidsTaken) {
            this.shipmentService.generateSkidsTakenForm(this.salesOrderId)
                .subscribe((form) => {
                    PrintService.printPdf(form);
                });
        }
    }

}
