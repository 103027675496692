import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { TrimSheetService, ITrimSheetReportSummary, ITrimSheetReportDetail } from '../trim-sheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PrintService } from '../../common/services/print.service';
import { FractionsService } from '../../common/services/fractions.service';

interface ITrimSheetGroup {
    SectionHeading: string;
    GroupConfiguration: string;
    Summaries: ITrimSheetReportSummary[];
}

@Component({
    selector: 'trim-sheet-list',
    styles: [
        `
            table th:nth-child(1),
            table td:nth-child(1):not(.heading),
            table th:nth-child(2),
            table td:nth-child(2):not(.heading),
            table th:nth-child(8),
            table td:nth-child(8):not(.heading),
            table th:nth-child(9),
            table td:nth-child(9):not(.heading),
            table th:nth-child(10),
            table td:nth-child(10):not(.heading),
            table th:nth-child(11),
            table td:nth-child(11):not(.heading),
            table th:nth-child(12),
            table td:nth-child(12):not(.heading) {
                border-right: 1px solid #bbb;
            }
            table td.heading {
                text-align: center;
                font-weight: bold;
                padding: 2px;
                font-size: medium;
            }
            table tr.last td {
                border-bottom: 2px solid black;
            }
        `,
    ],
    templateUrl: './trim-sheet-list.component.html',
})
export class TrimSheetListComponent implements OnInit {
    @Input('date') date: Date;

    trimGroups: ITrimSheetGroup[] = [];
    trimSummaries: ITrimSheetReportSummary[] = [];
    editKey: number = null;
    editingSetupId: number = null;

    doubleClickIsDisabled = false;
    trimSheetId: number;

    constructor(
        private notificationsService: NotificationsService,
        private trimSheetService: TrimSheetService,
        private route: ActivatedRoute,
        private router: Router,
        private fractionsService: FractionsService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.date = new Date(params.date + ' 00:00:00');
            this.trimSheetId = params.id;
            this.trimSheetService.getTrimSheetSummary(this.date).subscribe((answer) => {
                this.trimSummaries = answer;
                this.setGroups();
            });
        });
    }

    setGroups(): void {
        this.trimGroups = [];

        let prevHeading: string = null;
        let prevGroup: string = null;
        let curHeading: string = null;
        let curGroup: string = null;

        let curTrimGroup: ITrimSheetGroup;

        this.trimSummaries.sort((a, b) => (a.SetupId < b.SetupId ? -1 : a.SetupId > b.SetupId ? 1 : 0));

        this.trimSummaries.forEach((summary) => {
            curHeading = summary.SectionHeading || '';
            curGroup = summary.GroupConfiguration || '';

            if (curHeading !== prevHeading || curGroup !== prevGroup) {
                curTrimGroup = {
                    GroupConfiguration: curGroup,
                    SectionHeading: curHeading,
                    Summaries: [],
                };

                this.trimGroups.push(curTrimGroup);
            }

            curTrimGroup.Summaries.push(summary);

            prevHeading = curHeading;
            prevGroup = curGroup;
        });
    }

    getEditKey(summary: ITrimSheetReportSummary): number {
        return summary.TrimSheetId;
    }

    edit(summary: ITrimSheetReportSummary): void {
        this.editKey = this.getEditKey(summary);
        this.editingSetupId = summary.SetupId;
    }

    isEditing(summary: ITrimSheetReportSummary): boolean {
        return this.editKey === this.getEditKey(summary);
    }

    closeEdit(): void {
        this.editKey = null;
        this.editingSetupId = null;
    }

    getMachineTrimSum(summary: ITrimSheetReportSummary): number {
        let counter = 0;
        summary.Details.forEach((detail) => {
            let decimalString = this.fractionsService.FormatAsDecimal(detail.Width);
            counter += detail.Sheets * parseFloat(decimalString);
        });
        return counter;
    }

    saveNewSetupId(newSetupId: number): void {
        const trimSheetId: number = this.editKey;

        this.trimSheetService
            .updateSetupId(trimSheetId, newSetupId)
            .pipe(finalize(() => this.closeEdit()))
            .subscribe((answer) => {
                this.notificationsService.success('Setup successfully updated.');

                this.trimSummaries.find((item) => item.TrimSheetId === trimSheetId).SetupId = newSetupId;
                this.setGroups();
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    print(): void {
        this.trimSheetService
            .getTrimSheetPdf(this.date)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }

    close(): void {
        if (!this.trimSheetService) {
            this.router.navigate(['/trim-configurations']);
        } else {
            this.router.navigate([`/trim-configurations/${this.trimSheetId}`]);
        }
    }
}
