import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';

export class UserRolesEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Name'],
                    name: 'Role Name',
                }),
                new EntityListColumn({
                    name: 'Description',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
