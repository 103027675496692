import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StockOrderInventoryListConfig } from './stock-order-inventory-config';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IStockItem } from '@model/interfaces/stock-item';
import { entityListModuleConfig } from '@common/shared.module';
import { StockItemsService } from '../stockitem.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../sales-order.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderInfoDynamicControlsPartial } from '@model/partials/stock-order-info.form-controls';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { truncateToOneDecimal } from '@common/common-functions.form-controls';

@Component({
    templateUrl: './stock-order-inventory.component.html',
})
export class StockOrderInventoryComponent implements OnInit {
    stockItems: IStockItem[];
    currentPage = 1;
    total: number;
    entityListConfig = new StockOrderInventoryListConfig();
    order = '';
    orderDirection = '';
    perfectStockOnly = false;
    stockOrderInventoryForm: FormGroup;
    formCreated = false;
    abstractOrderInfoControls: any;
    lotSearch = '';
    widthSearch = '';
    sizeSearch = '';
    caliperSearch = '';

    salesOrderId: number;
    salesOrder: ISalesOrder;
    stockOrderLines: any[] = [];
    generalLedgerCodes: IGeneralLedgerCode[];
    totalPrice: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private stockItemsService: StockItemsService,
        private salesOrderService: SalesOrderService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.salesOrderId = +this.route.snapshot.params.orderId;
        if (!Number.isNaN(this.salesOrderId)) {
            this.initializeOrder();
        }
    }

    initializeOrder(): void {
        this.getSalesOrder();
        this.getInventory();
        this.getGeneralLedgerCodes();
    }

    getSalesOrder(): void {
        this.salesOrderService.getById(this.salesOrderId).subscribe((answer) => {
            this.salesOrder = answer;
            this.stockOrderLines = this.salesOrderService.sortStockOrderLines(
                                        this.salesOrder.OrderInfo.StockOrderLines);
            this.updateTotalPrice();
            this.createForm();
        });
    }

    getGeneralLedgerCodes(): void {
        this.salesOrderService.getGeneralLedgerCodes().subscribe((answer) => {
            this.generalLedgerCodes = answer;
        });
    }

    searchLot(event): void {
        this.lotSearch = event;
        this.getInventory();
    }

    searchWidth(event): void {
        this.widthSearch = event;
        this.getInventory();
    }

    searchSize(event): void {
        this.sizeSearch = event;
        this.getInventory();
    }

    searchCaliperMax(event): void {
        this.caliperSearch = event;
        this.getInventory();
    }

    getInventory(query = ''): void {
        const search = query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IsPerfect',
                value: this.perfectStockOnly.toString(),
            }),
            new ExtraSearchParams({
                name: 'Processed',
                value: 'true',
            }),
            new ExtraSearchParams({
                name: 'IsAvailable',
                value: 'true',
            }),
            new ExtraSearchParams({
                name: 'HasAcceptableLocation',
                value: 'true',
            }),
            new ExtraSearchParams({
                name: 'Lot',
                value: this.lotSearch,
            }),
            new ExtraSearchParams({
                name: 'Width',
                value: this.widthSearch,
            }),
            new ExtraSearchParams({
                name: 'Size',
                value: this.sizeSearch,
            }),
            new ExtraSearchParams({
                name: 'Caliper',
                value: this.caliperSearch,
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.stockItemsService.get(searchparams).subscribe((answer) => {
            this.stockItems = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    selectionChanged(event): void {
        this.stockOrderLines = this.salesOrderService.sortStockOrderLines(
                                event.selectedEntities);
        this.updateTotalPrice();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInventory();
    }

    filterPerfect(event): void {
        this.perfectStockOnly = event.value;
        this.getInventory();
    }

    createForm(): void {
        this.getControls();
        this.stockOrderInventoryForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getTotalBundles(): number {
        return this.stockOrderLines.reduce((a, b) => {
            return a + (b.StockItem ? this.getTruncatedBundles(b.StockItem.Bundles) : this.getTruncatedBundles(b.Bundles));
        }, 0);
    }

    getSumNetWeight(): number {
        return this.stockOrderLines.reduce((a, b) => {
            return a + (b.StockItem ? b.StockItem.NetWeight : b.NetWeight);
        }, 0);
    }

    getControls(): void {
        this.abstractOrderInfoControls = new OrderInfoDynamicControlsPartial(this.salesOrder.OrderInfo).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            OrderInfo: this.fb.group({}),
        });
    }

    updateTotalPrice(): void {
        if (!this.stockOrderLines || !this.stockOrderLines.length) {
            this.totalPrice = 0;
            return;
        }

        let price = this.stockOrderLines.map((x) => x.UnitPrice || 0).reduce((a, b) => a + b);
        let sumNetWeight = this.getSumNetWeight();
        this.totalPrice = price * (sumNetWeight / 2000.0);
    }

    save(): void {
        if (this.stockOrderInventoryForm.valid) {
            this.salesOrder.OrderInfo.StockOrderLines = this.stockOrderLines.map((sol) => {
                return {
                    GeneralLedgerCodeId: sol.GeneralLedgerCodeId,
                    Id: sol.StockItem ? sol.Id : 0,
                    ShipmentId: sol.ShipmentId,
                    StockItemId: sol.StockItem ? sol.StockItemId : sol.Id,
                    UnitPrice: sol.UnitPrice,
                };
            });
            this.salesOrderService.updateWithForeignKeys(this.salesOrder).subscribe((answer) => {
                this.close();
            });
        }
    }

    close(): void {
        this.notificationsService.success('Stock Order Saved Successfully');
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    formatStockItemColor(line: any): string {
        if (line.TopColor && line.BottomColor) {
            return line.TopColor.Name + ' / ' + line.BottomColor.Name;
        } else if (line.StockItem && line.StockItem.TopColor && line.StockItem.BottomColor) {
            return line.StockItem.TopColor.Name + ' / ' + line.StockItem.BottomColor.Name;
        }
        return '';
    }

    getTruncatedBundles(bundles: number): number {
        return truncateToOneDecimal(bundles);
    }
}
