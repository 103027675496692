import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomerPhone } from '../interfaces/customer-phone';
import { ICustomer } from '../interfaces/customer';
import { IPhoneType } from '../interfaces/phone-type';

export interface ICustomerPhoneDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    phoneTypes?: IPhoneType[];
}

export class CustomerPhoneDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    phoneTypes: IPhoneType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerphone?: ICustomerPhone, additionalParameters?: ICustomerPhoneDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerPhone';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.phoneTypes = additionalParameters && additionalParameters.phoneTypes || undefined;

        this.Form = {
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerphone && this.customerphone.CustomerId || null,
            }),
            Extension: new DynamicField({
                formGroup: this.formGroup,
                label: 'Extension',
                name: 'Extension',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(5) ],
                validators: { 'maxlength': 5 },
                value: this.customerphone && this.customerphone.hasOwnProperty('Extension') && this.customerphone.Extension !== null ? this.customerphone.Extension.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerphone && this.customerphone.hasOwnProperty('IsPrimary') && this.customerphone.IsPrimary !== null ? this.customerphone.IsPrimary : false,
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(20) ],
                validators: { 'required': true, 'maxlength': 20 },
                value: this.customerphone && this.customerphone.hasOwnProperty('Phone') && this.customerphone.Phone !== null ? this.customerphone.Phone.toString() : '',
            }),
            PhoneTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Type',
                name: 'PhoneTypeId',
                options: this.phoneTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerphone && this.customerphone.PhoneTypeId || null,
            }),
        };

        this.View = {
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.customerphone && this.customerphone.hasOwnProperty('CustomerId') && this.customerphone.CustomerId !== null ? this.customerphone.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Extension: new DynamicLabel(
                'Extension',
                this.customerphone && this.customerphone.hasOwnProperty('Extension') && this.customerphone.Extension !== null ? this.customerphone.Extension.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPrimary: new DynamicLabel(
                'Is Primary',
                this.customerphone && this.customerphone.hasOwnProperty('IsPrimary') && this.customerphone.IsPrimary !== null ? this.customerphone.IsPrimary : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Phone: new DynamicLabel(
                'Phone',
                this.customerphone && this.customerphone.hasOwnProperty('Phone') && this.customerphone.Phone !== null ? this.customerphone.Phone.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PhoneTypeId: new DynamicLabel(
                'Phone Type',
                getMetaItemValue(this.phoneTypes, this.customerphone && this.customerphone.hasOwnProperty('PhoneTypeId') && this.customerphone.PhoneTypeId !== null ? this.customerphone.PhoneTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
