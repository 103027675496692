<div>
    <h2>Invoice Processing</h2>
    <div class="row">
        <div class="col-md-6">
            <div class="miles-card miles-form padded">
                <h4>Add by number:</h4>
                <form>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>From</label>
                                <input
                                    type="text"
                                    name="searchNumberFrom"
                                    [(ngModel)]="searchNumberFrom"
                                    (blur)="onSearchNumberFromChange()"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>To</label>
                                <input type="text" name="searchNumberTo" [(ngModel)]="searchNumberTo" class="form-control" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <br />
                            <button class="btn btn-default p-x-md" (click)="addReceiptNumbersByNumber()">Go</button>
                        </div>
                    </div>
                </form>
                <h4 class="m-t-md">Add by load date:</h4>
                <form>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <dynamic-field [field]="searchDateFromInput" (valueChanges)="searchDateFrom = $event"></dynamic-field>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <dynamic-field [field]="searchDateToInput" (valueChanges)="searchDateTo = $event"></dynamic-field>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <br />
                            <button class="btn btn-default p-x-md" (click)="addReceiptNumbersByDate()">Go</button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
            <div class="miles-card padded">
                <h4 class="m-t-md">Bundles Shipped Daily Report</h4>
                <form [formGroup]="bundlesShippedForm" (submit)="printBundlesShippedReport()">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <dynamic-field [form]="bundlesShippedForm" [field]="bundlesShippedFromInput"></dynamic-field>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <dynamic-field [form]="bundlesShippedForm" [field]="bundlesShippedToInput"></dynamic-field>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <br />
                            <button type="submit" class="btn btn-default p-x-md" [disabled]="bundlesShippedForm.invalid">Print</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6">
            <div class="miles-card miles-form padded">
                <h4>Invoices to be processed:</h4>
                <ul class="list-group" style="max-height: 420px; overflow: auto">
                    <li class="list-group-item cursor-auto" *ngFor="let receipt of receipts">
                        <div class="row">
                            <div class="col-xs-2">{{ receipt.ReceiptNumber }}</div>
                            <div class="col-xs-4">
                                {{ receipt.CustomerName }}
                            </div>
                            <div class="col-xs-4">
                                <div *ngIf="receipt.InvoiceEmail" title="Invoice will be sent to {{ receipt.InvoiceEmail }}">
                                    <i class="fa fa-fw fa-envelope"></i>
                                    {{ receipt.InvoiceEmail }}
                                </div>
                            </div>
                            <div class="col-xs-2"><strong>Sent:</strong> {{ receipt.DateSent | date : 'shortDate' }}</div>
                        </div>
                    </li>
                </ul>
                <ng-template [ngIf]="receipts.length">
                    <hr />
                    <div class="text-center">
                        <button
                            *ngIf="showPrintInvoiceButton"
                            type="button"
                            class="btn btn-success"
                            (click)="printReceipts()"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        >
                            {{ isPrinting ? 'Printing...' : 'Print' }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-success"
                            (click)="emailReceipts()"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        >
                            {{ isEmailing ? 'Emailing...' : 'Email' }}
                        </button>
                        <button type="button" class="btn btn-default" (click)="receipts = []">Clear</button>
                    </div>
                </ng-template>
                <div *ngIf="!receipts.length" class="text-center m-t-sm">
                    <span class="medium font-italic">No invoices added.</span>
                </div>
            </div>
        </div>
    </div>
    <br />
    <button type="button" class="btn btn-info" (click)="navigateToBillingSheetAdjustments()">Billing Sheet Adjustments</button>
</div>
