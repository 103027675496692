import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IEntityListConfig, IEntityListColumn, EntityListDeleteColumn, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import {
    SharedEntitiesComponentConfig,
    AdditionalSharedEntityTypes,
    SharedEntitiesEditOptions,
    IAdditionalSharedEntityConfig,
    ISharedEntitiesComponentConfig,
    ISharedEntityListConfig,
    AdditionalSharedEntityConfig,
} from '@mt-ng2/shared-entities-module';
import { getFunctionNameSafe } from '@mt-ng2/common-functions';

import { Observable } from 'rxjs';

import { IContact } from '../interfaces/contact';
import { ContactDynamicControls } from '../form-controls/contact.form-controls';
import { entityListModuleConfig } from '../../common/shared.module';
import { ContactTypeService } from '../../customers/contacttype.service';
import { IContactType } from '../interfaces/contact-type';
import { IPhone } from '../interfaces/base';
import { CustomerContactsService } from '../../customers/shared-entities/customer-contacts.service';
import { IContactWithPhoneDetail } from '@model/interfaces/custom/contact-with-phone-detail';
import { ContactWithPhoneDetailDynamicControls } from '@model/form-controls/contact-with-phone-detail.form-controls';

export class ContactsSharedEntitiesConfig extends SharedEntitiesComponentConfig<IContactWithPhoneDetail> implements ISharedEntitiesComponentConfig<IContactWithPhoneDetail> {
    constructor() {
        super(
            {
                Email: null,
                Extension: '',
                FirstName: '',
                Id: 0,
                LastName: '',
                Notes: '',
                Phone: '',
                Title: '',
                TypeId: 1,
            },
            'Contact',
            'Contacts',
            {
                EntityListConfig: new ContactsSharedEntityListConfig(),
                FilterServices: [ContactTypeService],
            },
            entityListModuleConfig.itemsPerPage,
        );
        this.MetaItemServices = [ContactTypeService];
        const phoneConfig = new AdditionalSharedEntityConfig<IContact, IPhone>(
            AdditionalSharedEntityTypes.Phone,
            (entity: IContact) => (entity.ContactPhones ? entity.ContactPhones : []),
            getFunctionNameSafe(CustomerContactsService, 'savePhones'),
            null,
        );
        this.AdditionalSharedEntities = [phoneConfig];
        this.SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlaceWithAdditionalInfoButton;
    }

    getFormValues(contactWithPhone: IContactWithPhoneDetail, formValue: any): IContactWithPhoneDetail {
        let types = this.getMetaItemValues('ContactTypeService');
        new ContactDynamicConfig<IContactWithPhoneDetail>(contactWithPhone, types).assignFormValues(contactWithPhone, formValue.Contact);
        return contactWithPhone;
    }

    getDynamicFormConfig(contact: IContactWithPhoneDetail): any {
        let types = this.getMetaItemValues('ContactTypeService');
        let formFactory = new ContactDynamicConfig<IContactWithPhoneDetail>(contact, types);
        if (contact.Id === 0) {
            return formFactory.getForCreate();
        } else {
            return formFactory.getForUpdate();
        }
    }

    getRow = (contact: IContactWithPhoneDetail) =>
        `${contact.FirstName} ${contact.LastName}${
            contact.Title.trim().length > 0 ? ' - <i>' + contact.Title + '</i>' : ''
        } <span class="pull-right label label-default">${contact.ContactType.Name}</span>`
}

class ContactDynamicConfig<T extends IContactWithPhoneDetail> extends DynamicConfig<T> implements IDynamicConfig<T> {
    FirstName: string;
    LastName: string;
    Email: string;
    Notes: string;
    TypeId: number;
    Phone: string;
    Extension: string;

    configControls = ['TypeId', 'FirstName', 'LastName', 'Title', 'Email', 'Notes', 'Phone', 'Extension'];

    constructor(private contactWithPhone: T, private types: IContactType[]) {
        super();
        this.contactWithPhone.Phone = '';
        this.contactWithPhone.Extension = '';
        const dynamicControls = new ContactWithPhoneDetailDynamicControls(this.contactWithPhone, {
            contactPhones : this.contactWithPhone?.ContactPhones,
            formGroup: 'Contact',
            types: this.types,
        });
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}

class ContactsSharedEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.FirstName} ${contact.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    name: 'Title',
                }),
                new EntityListColumn({
                    accessors: ['ContactType', 'Name'],
                    name: 'Type',
                }),
                new EntityListColumn({
                    linkFunction: function (contact: IContact): void {
                        window.open(`mailto:${contact.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: ['ContactPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                }),
            ],
        };
        super(listConfig);
    }
}
