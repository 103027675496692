import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DynamicField, IDynamicField, LabelPositions } from '@mt-ng2/dynamic-form';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'newman-horizontal',
    styles: [
        `
            table {
                margin-bottom: 0 !important;
            }
            td {
                border: none !important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            td:first-child {
                padding-left: 0 !important;
            }
            td:last-child {
                padding-right: 0 !important;
            }
            .horizontalSpacing {
                min-width: 80px !important;
            }
            tr {
                cursor: default !important;
            }
        `,
    ],
    template: `
        <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="horizontalSpacing" *ngFor="let field of fields; let i = index">
                            <dynamic-field
                                [field]="getField(field)"
                                [form]="parentForm"
                                [overrideForm]="overrideForm"
                                (valueChanges)="valueChanges.emit($event)"
                                (controlCreated)="onControlCreated.emit($event)"
                            ></dynamic-field>
                        </td>
                        <td *ngIf="showRemove" class="removeButton nopadding">
                            <button type="button" class="btn btn-primary btn-fab-md" (click)="removeRow(rowIndex)">
                                <i class="fa fa-fw fa-minus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    `,
})
export class HorizontalViewComponent {
    @Input('dynamicForm') dynamicForm: any;
    @Input('fields') fields: string[];
    @Input('parentForm') parentForm: FormGroup;
    @Input('hideLabel') hideLabel = false;
    @Input('isValidationInside') isValidationInside = false;
    @Input('overrideForm') overrideForm = false;
    @Input('showRemove') showRemove = false;
    @Input('rowIndex') rowIndex;
    @Output('onRemoveRow') onRemoveRow: EventEmitter<any> = new EventEmitter<any>();
    @Output('valueChanges') valueChanges: EventEmitter<any> = new EventEmitter<any>();
    @Output('onControlCreated') onControlCreated: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    getField(fieldName: string): DynamicField {
        let fieldToCopy = <IDynamicField>{ ...this.dynamicForm[fieldName] };
        let dynamicField: DynamicField = new DynamicField({
            formGroup: fieldToCopy.formGroup,
            label: fieldToCopy.label,
            name: fieldToCopy.name,
            options: fieldToCopy.options,
            placeholder: fieldToCopy.placeholder,
            type: fieldToCopy.type,
            validation: [...fieldToCopy.validation],
            validators: { ...fieldToCopy.validators },
            value: fieldToCopy.value,
        });

        if (this.hideLabel) {
            dynamicField.labelPosition.position = LabelPositions.Hidden;
        }
        dynamicField.insideBoxValidation = this.isValidationInside;
        return dynamicField;
    }

    removeRow(rowIndex: number): void {
        this.onRemoveRow.emit(rowIndex);
    }
}
