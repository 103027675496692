<form (ngSubmit)="formSubmitted()">
    <h5 class="text-center font-italic m-0">Load #{{ shipmentId }}</h5>
    <label for="skidNumber">{{isStockItem !== true ? 'Skid Number': 'Stock Item Number'}}</label>
    <input type="text" #skidNumberInput name="skidNumber" [(ngModel)]="skidNumber" class="form-control" min="0" required />
    <br />
    <button type="submit" class="btn btn-success" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">Process Skid</button>
    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    <br />
    <label class="form-check-label" for="toggle-tag-type" style="margin-top: 5%">Number is From Stock Item
        <input
        type="checkbox"
        class="form-check-input"
        id="toggle-tag-type"
        checked="{{ isStockItem !== true ? null : true }}"
        (change)="toggleTagType()"
        >
    </label>
</form>
