import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IManufacturingOrderDetail } from '../model/interfaces/manufacturing-order-detail';
import { BaseService } from '@mt-ng2/base-service';
import { IPalletInfo } from '../model/interfaces/pallet-info';

@Injectable()
export class PalletInfoService extends BaseService<IPalletInfo> {
    private emptyPalletInfo: IPalletInfo = {
        BoardSizeLength: '',
        BoardSizeWidth: '',
        CutSizeLength: '',
        CutSizeWidth: '',
        Id: 0,
        InchesBetweenRunners: '',
        IsExactLength: null,
        IsExactWidth: null,
        PalletSizeLength: '',
        PalletSizeWidth: '',
        Qty: 0,
        RunnerDirectionTypeId: 0,
        RunnerHeight: '',
        ShipOnTypeId: 0,
        SpecialNotes: '',
    };

    constructor(public http: HttpClient) {
        super('/salesorders/pallet-info', http);
    }

    getEmptyPalletInfo(): IPalletInfo {
        return { ...this.emptyPalletInfo };
    }

    createPalletInfo(maufacturingOrderDetial: IManufacturingOrderDetail): any {
        return this.http.post('/salesorders/pallet-info/create', maufacturingOrderDetial);
    }
}
