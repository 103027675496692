import { ClaimTypes } from '../model/ClaimTypes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { TrimSheetAddComponent } from './trim-sheet-add/trim-sheet-add.component';
import { TrimViewComponent } from './trim-view/trim-view.component';
import { TrimSetupForecastComponent } from './trim-setup-forecast/trim-setup-forecast.component';
import { TrimSheetSettingsComponent } from './trim-sheet-settings/trim-sheet-settings.component';
import { TrimSheetListComponent } from './trim-sheet-list/trim-sheet-list.component';

class TrimSheetRoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.MillOperations_TrimSheets) {}
}

const trimSheetEntityConfig = {
    entityIdParam: 'trimSheetId',
};

class BacktenderRoleGuard implements IRoleGuarded {
    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.MillOperations_Backtender) { }
}

const trimSetupPreviewRoleGuard = {
    claimType: ClaimTypes.ViewCurrentProduction,
    claimValues: [ClaimValues.FullAccess],
};

const orderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: TrimSheetAddComponent,
        data: new TrimSheetRoleGuard('Trim Sheet'),
        path: 'trim-configurations',
        pathMatch: 'full',
    },
    { path: 'trim-configurations/settings', component: TrimSheetSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    {
        canActivate: [AuthGuard],
        component: TrimSheetAddComponent,
        data: new TrimSheetRoleGuard('Trim Sheet'),
        path: `trim-configurations/:${trimSheetEntityConfig.entityIdParam}`,
        pathMatch: 'full',
    },
    { path: 'trim-view', component: TrimViewComponent, canActivate: [AuthGuard], pathMatch: 'full', data: new BacktenderRoleGuard('Trim Sheet') },
    {
        canActivate: [AuthGuard],
        component: TrimSetupForecastComponent,
        data: trimSetupPreviewRoleGuard,
        path: 'trim-setup-forecast',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: TrimSetupForecastComponent,
        data: trimSetupPreviewRoleGuard,
        path: `trim-setup-forecast/:${trimSheetEntityConfig.entityIdParam}`,
        pathMatch: 'full',
    },
    { path: 'trim-list', component: TrimSheetListComponent, canActivate: [AuthGuard], pathMatch: 'full', data: new TrimSheetRoleGuard('Trim Sheet') },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(orderRoutes)],
})
export class TrimSheetRoutingModule { }
