import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IMillShiftPersonnel } from '../model/interfaces/mill-shift-personnel';

export const emptyMillShiftPersonnel: IMillShiftPersonnel = {
    Backtender: null,
    Bander1: null,
    Bander2: null,
    Bander3: null,
    Bander4: null,
    Id: 0,
    MillShiftId: 0,
    Scanner: null,
    ShiftStart: null,
    TourBoss: null,
};

@Injectable()
export class MillShiftPersonnelService extends BaseService<IMillShiftPersonnel> {
    constructor(public http: HttpClient) {
        super('/mill-shift-personnel', http);
    }

    getEmptyMillShiftPersonnel(): IMillShiftPersonnel {
        return { ...emptyMillShiftPersonnel };
    }

    getCurrent(): Observable<IMillShiftPersonnel> {
        return this.http.get<IMillShiftPersonnel>('/mill-shift-personnel/current');
    }
}
