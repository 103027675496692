import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { CustomPackagingService } from '../custom-packaging.service';
import { CustomPackagingDynamicConfig } from '../custom-packaging.dynamic-config';
import { BoardGradeService } from '../../../sales-orders/board-grade.service';

import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { IBoardGrade } from '@model/interfaces/board-grade';

@Component({
    selector: 'app-custom-packaging-basic-info',
    templateUrl: './custom-packaging-basic-info.component.html',
})
export class CustomPackagingBasicInfoComponent implements OnInit {
    @Input() customPackaging: ICustomPackaging;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    doubleClickIsDisabled = false;

    boardGrades: IBoardGrade[];
    config: any = { formObject: [], viewOnly: [] };
    formFactory: CustomPackagingDynamicConfig<ICustomPackaging>;

    get isNewCustomPackaging(): boolean {
        return this.customPackaging && this.customPackaging.Id ? false : true;
    }

    constructor(
        private customPackagingService: CustomPackagingService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private boardGradeService: BoardGradeService,
    ) {}

    ngOnInit(): void {
        this.boardGradeService.getAll().subscribe((boardGrades) => {
            this.boardGrades = boardGrades;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new CustomPackagingDynamicConfig<ICustomPackaging>(this.customPackaging, this.boardGrades);

        if (this.isNewCustomPackaging) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCustomPackaging) {
            this.router.navigate(['../'], { relativeTo: this.route });
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.customPackaging, form.value.CustomPackaging);
            this.saveCustomPackaging();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveCustomPackaging(): void {
        if (this.isNewCustomPackaging) {
            this.customPackagingService
                .create(this.customPackaging)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.customPackaging.Id = answer;
                    this.success(true);
                });
        } else {
            this.customPackagingService
                .update(this.customPackaging)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newCustomPackagingSave?: boolean): void {
        if (newCustomPackagingSave) {
            this.router.navigate(['../', this.customPackaging.Id], { relativeTo: this.route });
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.customPackagingService.emitChange(this.customPackaging);
        this.notificationsService.success('Custom Packaging saved successfully.');
    }
}
