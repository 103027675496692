import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IShipmentStop } from '../interfaces/shipment-stop';
import { ICustomerShippingAddress } from '../interfaces/customer-shipping-address';
import { IShipment } from '../interfaces/shipment';

export interface IShipmentStopDynamicControlsParameters {
    formGroup?: string;
    shipments?: IShipment[];
    customerShippingAddresses?: ICustomerShippingAddress[];
}

export class ShipmentStopDynamicControls {

    formGroup: string;
    shipments: IShipment[];
    customerShippingAddresses: ICustomerShippingAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private shipmentstop?: IShipmentStop, additionalParameters?: IShipmentStopDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ShipmentStop';
        this.shipments = additionalParameters && additionalParameters.shipments || undefined;
        this.customerShippingAddresses = additionalParameters && additionalParameters.customerShippingAddresses || undefined;

        this.Form = {
            CustomerShippingAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Shipping Address',
                name: 'CustomerShippingAddressId',
                options: this.customerShippingAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.shipmentstop && this.shipmentstop.CustomerShippingAddressId || null,
            }),
            ShipmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment',
                name: 'ShipmentId',
                options: this.shipments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.shipmentstop && this.shipmentstop.ShipmentId || null,
            }),
            Stop: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stop',
                name: 'Stop',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.shipmentstop && this.shipmentstop.Stop || null,
            }),
        };

        this.View = {
            CustomerShippingAddressId: new DynamicLabel(
                'Customer Shipping Address',
                getMetaItemValue(this.customerShippingAddresses, this.shipmentstop && this.shipmentstop.hasOwnProperty('CustomerShippingAddressId') && this.shipmentstop.CustomerShippingAddressId !== null ? this.shipmentstop.CustomerShippingAddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipmentId: new DynamicLabel(
                'Shipment',
                getMetaItemValue(this.shipments, this.shipmentstop && this.shipmentstop.hasOwnProperty('ShipmentId') && this.shipmentstop.ShipmentId !== null ? this.shipmentstop.ShipmentId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Stop: new DynamicLabel(
                'Stop',
                this.shipmentstop && this.shipmentstop.Stop || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
