import { SalesOrderDynamicControls } from '../form-controls/sales-order.form-controls';
import { ISalesOrder } from '../interfaces/sales-order';
import { IManufacturingOrderDetail } from '../interfaces/manufacturing-order-detail';
import { IOrderInfo } from '../interfaces/order-info';
import { IOrderStatus } from '../interfaces/order-status';
import { IOrderType } from '../interfaces/order-type';
import { DynamicFieldTypes, DynamicFieldType, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class SalesOrderDynamicControlsPartial extends SalesOrderDynamicControls {
    constructor(
        private salesorderPartial?: ISalesOrder,
        private formGroupPartial = 'SalesOrder',
        private manufacturingorderdetailsPartial?: IManufacturingOrderDetail[],
        private orderinfosPartial?: IOrderInfo[],
        private orderstatusesPartial?: IOrderStatus[],
        private ordertypesPartial?: IOrderType[],
    ) {
        super(salesorderPartial, {
            formGroup: formGroupPartial,
            manufacturingOrderDetails: manufacturingorderdetailsPartial,
            orderInfos: orderinfosPartial,
            orderStatuses: orderstatusesPartial,
            orderTypes: ordertypesPartial,
        });
        this.Form.OrderStatusId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });
        this.Form.Total.label = 'Est. Total';
        this.Form.Total.labelHtml = '<label>Est. Total:</label>';
    }
}
