import { ClaimTypes } from '@model/ClaimTypes';
import { IEntitySlugs } from '@model/interfaces/custom/entity-slugs';
import { ClaimValues } from '@mt-ng2/auth-module';

export const dailyRunEntriesPathSlugs: IEntitySlugs = {
    add: 'add',
    entityIdParam: 'dailyRunEntriesId',
    entityName: 'Daily Run',
    root: 'daily-run-entries',
};

export const dailyRunEntriesPaths = {
    add: `${dailyRunEntriesPathSlugs.add}`,
    dailyRunEntries: dailyRunEntriesPathSlugs.root,
};

export const dailyRunEntriesRoleGuard = {
    claimType: ClaimTypes.DailyRunEntries,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
};
