import { IDailyRunEntry } from '@model/interfaces/daily-run-entry';
import { IEntityListConfig, IEntityListColumn, EntityListColumn, EntityListConfig } from '@mt-ng2/entity-list-module';

function getTotalBundles(dailyRunEntry: IDailyRunEntry): number {
    if (!dailyRunEntry.DailyRunSalesOrders) {
        return 0;
    }
    return dailyRunEntry.DailyRunSalesOrders.reduce((sum, order) => sum + (order.Bundles || 0), 0);
}

export class DailyRunEntryEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['RunDate'],
                    name: 'Run Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['ShiftCode'],
                    name: 'Shift',
                }),
                new EntityListColumn({
                    accessorFunction: getTotalBundles,
                    name: 'Bundles',
                    pipes: ['number'],
                }),
                new EntityListColumn({
                    accessors: ['Backtender'],
                    name: 'Backtender',
                    sort: {disableSort: true},
                }),
                new EntityListColumn({
                    accessors: ['TourBoss'],
                    name: 'Tour Boss',
                    sort: {disableSort: true},
                }),
                new EntityListColumn({
                    accessors: ['Finishers'],
                    name: 'Finishers',
                    sort: {disableSort: true},
                }),
                new EntityListColumn({
                    accessors: ['Banders'],
                    name: 'Banders',
                    sort: {disableSort: true},
                }),
                new EntityListColumn({
                    accessors: ['ThirdHandValues'],
                    name: 'Third Hand',
                    sort: {disableSort: true},
                }),
            ],
        };
        super(listConfig);
    }
}
