import { ClaimTypes } from '../model/ClaimTypes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';

import { SalesOrdersSettingsComponent } from './sales-orders-settings/salesorder-settings.component';
import { SalesOrderAddComponent } from './sales-orders-add/sales-orders-add.component';
import { ManufacturingOrderAddComponent } from './manufacturing-order-add/manufacturing-order-add.component';
import { SalesOrdersListComponent } from './sales-orders-list/sales-orders-list.component';
import { OrderCustomerSpecsMainComponent } from './order-customer-specs/order-customer-specs-main.component';
import { PriceBreakdownComponent } from './price-breakdown/price-breakdown.component';
import { StockOrderAddComponent } from './stock-order-add/stock-order-add.component';
import { PalletInfoComponent } from './pallet-info/pallet-info.component';
import { StockOrderInventoryComponent } from './stock-order-inventory/stock-order-inventory.component';
import { ShippingStatusComponent } from './shipping-status/shipping-status.component';
import { CustomerShippingSharedEntitiesConfig } from '../model/shared-entities/customer-shipping-address.shared-entities-config';
import { CustomerShippingAddressService } from '../customers/shared-entities/customer-shipping-address.service';
import { CustomerSharedEntities } from '../customers/shared-entities/customer.shared-entities';
import { CustomerSpecsComponent } from './order-customer-specs/customer-specs.component';
import { TrimViewComponent } from '../trim-sheets/trim-view/trim-view.component';
import { orderPathSlugs } from '@routes/order-paths';
import { millComplainsAddRoleGuardRoleGuard, millComplainsRoleGuardRoleGuard, millComplaintPathSlugs, millComplaintPaths } from '@routes/mill-complaint-paths';
import { MillComplaintAddComponent } from '../mill-complaints/mill-complaint-add/mill-complaint-add.component';
import { MillComplaintDetailComponent } from '../mill-complaints/mill-complaint-detail/mill-complaint-detail.component';
import { MillComplaintHeaderComponent } from '../mill-complaints/mill-complaint-header/mill-complaint-header.component';
import { MillComplaintsComponent } from '../mill-complaints/mill-complaint-list/mill-complaints.component';
import { StockInventoryComponent } from './stock-inventory/stock-inventory.component';
import { ViewProductionComponent } from './view-production/view-production.component';

const shippingAddressSharedEntity = {
    config: CustomerShippingSharedEntitiesConfig,
    entityIdParam: 'Id',
    path: 'shippingaddresses',
    service: CustomerShippingAddressService,
    sharedEntity: CustomerSharedEntities.ShippingAddress,
};

const salesOrdersRoleGuard = {
    claimType: ClaimTypes.Orders,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    sharedEntities: [shippingAddressSharedEntity],
    title: 'Sales Orders',
};

const customersRoleGuard = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Customers',
};

const stockInventoryRoleGuard = {
    claimType: [ClaimTypes.Orders_CanAccessAvailableInventory, ClaimTypes.Orders_CanAccessAvailableAndUnavailableInventory],
    claimValues: [ClaimValues.FullAccess],
    title: 'Stock Inventory',
};

class OrderRoleGuard implements IRoleGuarded {
    sharedEntities: any[];

    constructor(public title: string, public claimValues = [ClaimValues.FullAccess], public claimType = ClaimTypes.Orders) {
        this.sharedEntities = [];
        this.sharedEntities.push(shippingAddressSharedEntity);
    }
}

const orderRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: SalesOrdersListComponent,
        data: salesOrdersRoleGuard,
        path: 'salesorders-manufacturing',
        pathMatch: 'full',
    },
    { path: 'salesorders-stock', component: SalesOrdersListComponent, canActivate: [AuthGuard], pathMatch: 'full', data: salesOrdersRoleGuard },
    {
        canActivate: [AuthGuard],
        component: SalesOrdersSettingsComponent,
        data: new OrderRoleGuard('Order Settings'),
        path: 'salesorders/settings',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: SalesOrderAddComponent,
        data: new OrderRoleGuard('Order Add'),
        path: 'salesorders-manufacturing/add',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                canActivate: [AuthGuard],
                children: [
                    {
                        canActivate: [AuthGuard],
                        component: MillComplaintsComponent,
                        path: '',
                        pathMatch: 'full',
                    },
                    {
                        canActivate: [AuthGuard],
                        children: [{ component: MillComplaintAddComponent, path: '', pathMatch: 'full' }],
                        component: MillComplaintHeaderComponent,
                        data: millComplainsAddRoleGuardRoleGuard,
                        path: millComplaintPathSlugs.add,
                    },
                    {
                        canActivate: [AuthGuard],
                        children: [{ component: MillComplaintDetailComponent, path: '', pathMatch: 'full' }],
                        component: MillComplaintHeaderComponent,
                        path: `:${millComplaintPathSlugs.entityIdParam}`,
                    },
                ],
                data: millComplainsRoleGuardRoleGuard,
                path: millComplaintPaths.millComplaints,
            },
            {
                canActivate: [AuthGuard],
                component: ManufacturingOrderAddComponent,
                path: '',
                pathMatch: 'full',
            },

        ],
        data: new OrderRoleGuard('Order Detail'),
        path: 'salesorders-manufacturing/:orderId',
    },
    {
        canActivate: [AuthGuard],
        component: OrderCustomerSpecsMainComponent,
        data: new OrderRoleGuard('Order Specs'),
        path: 'salesorders-manufacturing/:salesOrderId/specs-info/:orderInfoId',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PriceBreakdownComponent,
        data: new OrderRoleGuard('Order Price Breakdown'),
        path: 'salesorders-manufacturing/:salesOrderId/specs-info/:orderInfoId/price-info/:orderCustomerSpecId',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: PalletInfoComponent,
        data: new OrderRoleGuard('Order Pallet Info'),
        path: 'salesorders-manufacturing/:salesOrderId/pallet-info/:manufacturingOrderDetailId',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: SalesOrderAddComponent,
        data: new OrderRoleGuard('Order Add'),
        path: 'salesorders-stock/add',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: StockOrderAddComponent,
        data: new OrderRoleGuard('Order Detail'),
        path: 'salesorders-stock/:orderId',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: StockInventoryComponent, path: '', pathMatch: 'full' }],
        component: StockInventoryComponent,
        data: stockInventoryRoleGuard,
        path: 'stock-inventory',
    },
    {
        canActivate: [AuthGuard],
        component: StockOrderInventoryComponent,
        data: new OrderRoleGuard('Order Inventory'),
        path: 'salesorders-stock/:orderId/inventory',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ShippingStatusComponent,
        data: new OrderRoleGuard('Order Shipping Status'),
        path: 'salesorders-manufacturing/:salesOrderId/shipping-status',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: ViewProductionComponent,
        path: 'salesorders-manufacturing/:salesOrderId/view-production',
        pathMatch: 'full',
      },

    {
        canActivate: [AuthGuard],
        component: TrimViewComponent,
        data: new OrderRoleGuard('Current Production'),
        path: orderPathSlugs.currentProd,
        pathMatch: 'full',
    },

    // TODO: find a way to get this route in the customers module, but placing
    // it there causes a circular reference because of the OrderSpecsComponent,
    // and that would require a decent amount of refactoring to fix.
    {
        canActivate: [AuthGuard],
        component: CustomerSpecsComponent,
        data: customersRoleGuard,
        path: 'customers/:customerId/specs-info',
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(orderRoutes)],
})
export class OrderRoutingModule {}
