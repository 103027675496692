import { Component, OnInit } from '@angular/core';
import { MillCorpCustomerService } from '../services/mill-corp-customer.service';
import { MillCorpCustomerDynamicControls } from '@model/form-controls/mill-corp-customer.form-controls';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-mill-corp-settings',
    templateUrl: './mill-corp-settings.component.html',
})

export class MillCorpSettingsComponent implements OnInit {
    millCorpCustomerDynamicForm = new MillCorpCustomerDynamicControls().Form;

    constructor(public millCorpCustomerService: MillCorpCustomerService) { }

    ngOnInit(): void {
        (<DynamicField>this.millCorpCustomerDynamicForm.Name).type.inputType = InputTypes.Textbox;
    }
}
