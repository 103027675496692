<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Test Stack</h2>
        </div>
    </div>
</div>
<div class="col-md-8 col-lg-6">
    <form class="miles-form padded row" [formGroup]="testScanForm" (ngSubmit)="formSubmitted()">
        <div class="row col-md-12">
            <dynamic-field [field]="abstractTestScanControls.EmployeeName" [form]="testScanForm"></dynamic-field>
        </div>
        <div class="row col-md-12" *ngFor="let detail of testScan.TestScanDetails; let i = index">
            <div class="row col-md-12"><div class="b-top"></div></div>
            <div class="p-sm">
                <div class="row">
                    <div class="col-xs-3 col-lg-3">
                        <label>{{ detail.Description }}</label>
                    </div>
                    <div class="col-xs-6 col-lg-4" *ngIf="i === 0">
                        <label>Actual</label>
                    </div>
                    <div class="col-xs-3 col-lg-2" *ngIf="i === 0">
                        <label>Error %</label>
                    </div>
                </div>
                <div class="row row-col-align-middle">
                    <div class="col-xs-3 col-lg-3">
                        <span class="font-weight-bold">Caliper:</span> {{ detail.Caliper }}
                        <br />
                        <span class="font-weight-bold">Ply:</span> {{ detail.Ply }}
                        <br />
                        <span class="font-weight-bold">Skid Height:</span> {{ detail.SkidHeight }}
                        <br />
                        <span class="font-weight-bold">Target:</span> {{ detail.Target }}
                    </div>
                    <div class="col-xs-3 col-lg-2">
                        <h4 class="b-none">
                            <span class="label label-primary">{{ detail.Actual }}</span>
                        </h4>
                    </div>
                    <div class="col-xs-3 col-lg-2">
                        <button type="button" class="btn btn-default" (click)="scan(detail)">Scan</button>
                    </div>
                    <div class="col-xs-3 col-lg-2">
                        <h4 class="b-none" *ngIf="detail.Actual">
                            <span class="label" [ngClass]="errorPercentOkay(detail) ? 'label-success' : 'label-danger'">
                                {{ getErrorPercent(detail) | percent : '0.2-2' }}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <div class="m-t-sm pull-left">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Complete
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
            <div class="pull-right">{{ scannerName }} Scanner</div>
        </div>
    </form>
</div>
