import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ITrailer } from '../model/interfaces/trailer';
import { BaseService } from '@mt-ng2/base-service';

@Injectable()
export class TrailerService extends BaseService<ITrailer> {
    constructor(public http: HttpClient) {
        super('/trailers', http);
    }
}
