import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { IDailyRunEntry } from '../interfaces//daily-run-entry';
import { DailyRunEntryDynamicControls, IDailyRunEntryDynamicControlsParameters } from '@model/form-controls/daily-run-entry.form-controls';

export class DailyRunEntryDynamicControlsPartial extends DailyRunEntryDynamicControls {

    constructor(dailyRunEntryPartial?: IDailyRunEntry, additionalParameters?: IDailyRunEntryDynamicControlsParameters) {
        super(dailyRunEntryPartial, additionalParameters);

        (<DynamicField>this.Form.Backtender).labelHtml = '<label>Backtender</label>';
        (<DynamicField>this.Form.Banders).labelHtml = '<label>Banders</label>';
        (<DynamicField>this.Form.Finishers).labelHtml = '<label>Finishers</label>';
        (<DynamicField>this.Form.RunDate).labelHtml = '<label>RunDate</label';
        (<DynamicField>this.Form.TourBoss).labelHtml = '<label>Tour Boss</label';
        (<DynamicField>this.Form.ShiftCode).labelHtml = '<label>ShiftCode</label';
        (<DynamicField>this.Form.Breaks).labelHtml = '<label>Breaks</label';
        (<DynamicField>this.Form.Hoppers).labelHtml = '<label>Hoppers</label';
        (<DynamicField>this.Form.Setups).labelHtml = '<label>Turnovers</label';
        (<DynamicField>this.Form.SuperStock).labelHtml = '<label>Super Stock</label';
        (<DynamicField>this.Form.ThirdHandValues).labelHtml = '<label>Third Hand</label>';

    }
}
