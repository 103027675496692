import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { IOrderDetailLine } from '@model/interfaces/order-detail-line';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { OrderDetailLineDynamicControlsPartial } from '@model/partials/order-detail-line.form-controls';
import { SalesOrderService } from '../sales-order.service';
import { FractionsService } from '../../common/services/fractions.service';
import { OrderDetailLinesService } from '../order-detail-lines.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'order-detail-lines',
    styles: [
        `
            td {
                border: none !important;
            }
            .horizontalSpacing {
                min-width: 94px !important;
            }
            tr {
                cursor: default !important;
            }
        `,
    ],
    templateUrl: './order-detail-lines.component.html',
})
export class OrderDetailLinesComponent implements OnInit, OnChanges {
    @Input('OrderDetailLines') OrderDetailLines: FormArray;
    @Input('ManufacturingOrderDetail') ManufacturingOrderDetail: FormGroup;
    @Input('values') InputValues: IOrderDetailLine[];
    @Input('salesOrderId') salesOrderId: number;
    @Input('salesOrderStatusId') salesOrderStatusId: number;
    @Input('formIsLocked') formIsLocked: boolean;
    @Input('orderCaliper') orderCaliper: number;

    abstractOrderDetailControls: OrderDetailLineDynamicControlsPartial;
    abstractOrderDetailControlsFromValues: OrderDetailLineDynamicControlsPartial[] = [];

    formattedBasis: string[];
    formattedCalipers: string[];

    private emptyOrderDetailLine: IOrderDetailLine;

    get orderWidth(): string {
        return this.ManufacturingOrderDetail.controls.Width.value;
    }
    get orderLength(): string {
        return this.ManufacturingOrderDetail.controls.Length.value;
    }

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private salesOrderService: SalesOrderService,
        private fractionsService: FractionsService,
        private orderDetailLinesService: OrderDetailLinesService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.emptyOrderDetailLine = this.salesOrderService.getEmptyOrderDetailLine();
        this.abstractOrderDetailControls = new OrderDetailLineDynamicControlsPartial(this.emptyOrderDetailLine);

        this.formattedBasis = [];
        this.formattedCalipers = [];

        if (!this.InputValues.length) {
            this.addRow();
        } else {
            this.loadDetailLineControls();
        }
    }

    private loadDetailLineControls(): void {
        this.formattedBasis = [];
        this.formattedCalipers = [];
        this.abstractOrderDetailControlsFromValues = [];
        this.OrderDetailLines.clear();

        this.InputValues.forEach((line) => {
            this.formattedBasis.push(line.Basis?.toFixed(1));
            this.formattedCalipers.push(line.Caliper.toFixed(3));

            let abstractControl = new OrderDetailLineDynamicControlsPartial(line);
            this.abstractOrderDetailControlsFromValues.push(abstractControl);
            this.OrderDetailLines.push(this.fb.group(line));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.InputValues && !changes.InputValues.firstChange && changes.InputValues.currentValue.every((v) => v.Id > 0)) {
            // Ensure every value of InputValues has an Id so we don't re-render the view twice. The manufacturing-order-add component
            // assigns the form values to the sales order on save so it has the data to send to the api, which fires this method.
            // Once the save is successful, we fetch the updated details so they all have Id properties to prevent duplicates on
            // subsequent saves. That fires this method a second time, at which point everything has an Id and we can re-render the controls.
            this.loadDetailLineControls();
        }
    }

    addRow(): void {
        this.OrderDetailLines.push(
            this.fb.group({
                Basis: this.emptyOrderDetailLine.Basis,
                BdlSk: this.emptyOrderDetailLine.BdlSk,
                Caliper: this.emptyOrderDetailLine.Caliper,
                Count: this.emptyOrderDetailLine.Count,
                Height: this.emptyOrderDetailLine.Height,
                IsCutter: this.emptyOrderDetailLine.IsCutter,
                IsPaster: this.emptyOrderDetailLine.IsPaster,
                Length: this.emptyOrderDetailLine.Length,
                NumberOfSkids: this.emptyOrderDetailLine.NumberOfSkids,
                Piles: this.emptyOrderDetailLine.Piles,
                PileStainWhiteSide: this.emptyOrderDetailLine.PileStainWhiteSide,
                Ply: this.emptyOrderDetailLine.Ply,
                Quantity: this.emptyOrderDetailLine.Quantity,
                RuleNumber: this.emptyOrderDetailLine.RuleNumber,
                RunnerDirectionTypeId : this.emptyOrderDetailLine.RunnerDirectionTypeId,
                Width: this.emptyOrderDetailLine.Width,
            }),
        );
        if (this.InputValues.length) {
            this.abstractOrderDetailControlsFromValues.push(this.abstractOrderDetailControls);
        }
        this.formattedBasis.push(null);
        this.formattedCalipers.push(null);
        this.cdr.detectChanges();
    }

    deleteOrderDetailLine(i: number): void {
        let id = this.OrderDetailLines.controls[i].value.Id;
        if (id) {
            this.salesOrderService
                .deleteOrderDetailLine(id, this.salesOrderId)
                .pipe(
                    catchError((err) => {
                        return '0';
                    }),
                )
                .subscribe((answer) => {
                    if (answer === '0') {
                        this.notificationsService.error('Unable to remove converting line because it is associated to a trim sheet and/or skid.');
                    } else {
                        this.removeRow(i);
                    }
                });
        } else {
            // the line hasn't been saved to the db yet, just remove it from the UI
            this.removeRow(i);
        }
    }

    removeRow(i: number): void {
        if (this.InputValues.length) {
            this.abstractOrderDetailControlsFromValues.splice(i, 1);
            this.InputValues.splice(i, 1);
        }
        this.OrderDetailLines.removeAt(i);
        this.formattedBasis.splice(i, 1);
        this.formattedCalipers.splice(i, 1);
    }

    calculateBundlesPerSkid(index: number): void {
        // Move method execution to bottom of stack to allow (valueChanges) to propagate changes before (blur) event
        setTimeout(() => {
            if (
                (<FormGroup>this.OrderDetailLines.controls[index]).value.Quantity &&
                (<FormGroup>this.OrderDetailLines.controls[index]).value.NumberOfSkids
            ) {
                let bdlPerSkid = this.OrderDetailLines.controls[index].value.Quantity / this.OrderDetailLines.controls[index].value.NumberOfSkids;
                bdlPerSkid = this.fractionsService.roundToNearestFraction(bdlPerSkid, null, 10);
                (<FormGroup>this.OrderDetailLines.controls[index]).controls.BdlSk.setValue(bdlPerSkid);
                // divide quantity by # of skids, round to the nearest tenth, and reassign the value to the control
            }
        }, 0);
    }

    calculateHeight(index: number): void {
        // Move method execution to bottom of stack to allow (valueChanges) to propagate changes before (blur) event
        setTimeout(() => {
            if (
                (<FormGroup>this.OrderDetailLines.controls[index]).value.Quantity &&
                this.formattedCalipers[index] &&
                (<FormGroup>this.OrderDetailLines.controls[index]).value.Count &&
                (<FormGroup>this.OrderDetailLines.controls[index]).value.NumberOfSkids &&
                <FormGroup>this.OrderDetailLines.controls[index].value.Piles
            ) {
                let height: any =
                    (this.OrderDetailLines.controls[index].value.Quantity *
                        Number.parseFloat(this.formattedCalipers[index]) *
                        this.OrderDetailLines.controls[index].value.Count) /
                    this.OrderDetailLines.controls[index].value.Piles /
                    this.OrderDetailLines.controls[index].value.NumberOfSkids;
                height = height === Infinity || isNaN(height) ? '' : height;
                height = this.fractionsService.roundToNearestFraction(height, null, 4);
                height = this.fractionsService.FormatAsFraction(height.toString());
                (<FormGroup>this.OrderDetailLines.controls[index]).controls.Height.setValue(height);
                // multiple quantity, caliper, and count, then divide by # of piles.
                // round to the nearest quarter inch, format the decimal as a fraction, and reassign the value to the control
            }
        }, 0);
    }

    parseBasisFormatting(event: any, i: number): void {
        let basis = event.target.value * 1;
        (<FormGroup>this.OrderDetailLines.controls[i]).controls.Basis.setValue(basis);
        this.formattedBasis[i] = parseFloat(event.target.value).toFixed(1);
    }

    parseCaliperFormatting(event: any, i: number): void {
        let caliper = event.target.value * 1;
        (<FormGroup>this.OrderDetailLines.controls[i]).controls.Caliper.setValue(caliper);
        this.formattedCalipers[i] = parseFloat(event.target.value).toFixed(3);
        this.calculateHeight(i);
        this.calculateRuleNumber(i);
    }

    checkIfCutter(index: number): void {
        let line = this.OrderDetailLines.at(index) as FormGroup;
        let convertingWidth = line.controls.Width.value;
        let convertingLength = line.controls.Length.value;

        if (
            (convertingWidth && this.orderWidth && convertingWidth !== this.orderWidth) ||
            (convertingLength && this.orderLength && convertingLength !== this.orderLength)
        ) {
            line.controls.IsCutter.setValue(true);
        } else {
            line.controls.IsCutter.setValue(false);
        }
    }

    checkIfPaster(index: number): void {
        let line = this.OrderDetailLines.at(index) as FormGroup;
        let convertingCaliper = line.controls.Caliper.value;
        let isMultiple = (convertingCaliper * 1000) % (this.orderCaliper * 1000) === 0;

        if (convertingCaliper && this.orderCaliper && +convertingCaliper !== +this.orderCaliper && isMultiple) {
            line.controls.IsPaster.setValue(true);
        } else {
            line.controls.IsPaster.setValue(false);
        }
    }

    calculateRuleNumber(index: number): void {
        // Timeout ensures form values are set before checking them
        setTimeout(() => {
            const formattedCaliper = this.formattedCalipers[index];
            if (this.OrderDetailLines.value[index].Count && formattedCaliper) {
                let rule: any = Math.round(60 / this.OrderDetailLines.value[index].Count / (+formattedCaliper));
                (<FormGroup>this.OrderDetailLines.controls[index]).controls.RuleNumber.setValue(rule);
            }
        });
    }
}
