import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { OrderPriceTypeService } from '../../sales-orders/order-pricing-types.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DEFAULT_POUNDS_PER_BUNDLE, POUNDS_PER_TON, ReceiptService } from '../receipt.service';

import { IReceipt } from '../../model/interfaces/receipt';
import { IOrderPriceType } from '../../model/interfaces/order-price-type';
import { IShipmentStop } from '../../model/interfaces/shipment-stop';
import { ICustomer } from '../../model/interfaces/customer';
import { IReceiptDetail } from '../../model/interfaces/receipt-detail';
import { finalize } from 'rxjs/operators';
import { OrderPriceTypes } from '../../model/OrderPriceTypes';
import { IFormattedSalesOrder } from '../../model/interfaces/custom/formatted-sales-order';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { PrintService } from '@common/services/print.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, InputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { OrderTypeIds } from '@model/OrderTypes';

@Component({
    selector: 'receipt-details',
    styles: [`
        .fieldset-bundles {
            border: none;
            box-shadow: none;
        }
    `],
    templateUrl: './receipt-details.component.html',
})
export class ReceiptDetailsComponent implements OnInit {
    @Input() isBillingSheetAdjustment = false;
    @Input() receiptId: number;
    @Output() onReceiptUpdated = new EventEmitter<void>();

    receipt: IReceipt;
    receiptAuditReason: string;
    poundsPerBundle: number;
    numberOfSkids = 0;

    isSearchingByReceiptNumber: boolean; // to prevent duplicate calls from blur and keypress
    doubleClickIsDisabled = false;

    isReadOnly = false; // if navigated from Shipping Status page, then read only

    receiptNoteField: IDynamicField;
    receiptNoteFieldCreated = false;
    receiptNote: string;

    get orderPriceTypes(): IOrderPriceType[] {
        return this.orderPriceTypeService.items;
    }
    get customer(): ICustomer {
        return this.receipt.ReceiptDetails && this.receipt.ReceiptDetails.length
            ? this.receipt.ReceiptDetails[0].SalesOrder.OrderInfo.Customer
            : null;
    }
    get shipmentStop(): IShipmentStop {
        return this.receipt ? this.receipt.ShipmentStop : null;
    }

    @ViewChild('receiptNumberInput', { read: ElementRef }) receiptNumberInput: ElementRef;
    auditModal: IModalWrapperApi;
    modalOptions: IModalOptions = {
        focusCancel: true,
        showCancelButton: false,
        showConfirmButton: false,
    };
    canEdit: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private orderPriceTypeService: OrderPriceTypeService,
        private receiptService: ReceiptService,
        private notificationsService: NotificationsService,
        private cdr: ChangeDetectorRef,
        private claimsService: ClaimsService,
    ) { }

    ngOnInit(): void {
        this.clearForm();
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Receipts, [ClaimValues.FullAccess]);
        forkJoin([this.orderPriceTypeService.getItems()]).subscribe((answer) => {
            this.receiptId = this.receiptId || +this.activatedRoute.snapshot.paramMap.get('receiptId') || 0;
            if (this.receiptId !== 0) {
                this.loadReceipt(this.receiptId, true);
            }

            this.isReadOnly = +this.activatedRoute.snapshot.paramMap.get('receiptId')
                                ? true
                                : false;
        });
    }

    createReceiptNoteField(): void {
        this.receiptNoteField = new DynamicField({
            formGroup: 'ReceiptNote',
            hideLabel: true,
            label: 'Receipt Note',
            name: 'Receipt Note',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            } as IDynamicFieldType),
            validation: [Validators.maxLength(250)],
            validators: {'maxlength' : 250},
            value: this.receiptNote ?? null,
        } as IDynamicField);

        this.receiptNoteFieldCreated = true;
    }

    saveNote(): void {
        this.receiptService
            .saveReceiptNote(this.receipt?.Id, this.receiptNote)
            .subscribe(() => {
                this.notificationsService.success('Receipt Note Saved!');
            });
    }

    clearNoteField(): void {
        this.receiptNoteFieldCreated = false;
        this.receiptNoteField = null;
        this.receiptNote = '';
    }

    getReceiptByNumber(number: string): void {
        if (!number || this.isSearchingByReceiptNumber) {
            return;
        }

        this.isSearchingByReceiptNumber = true;
        this.receiptService
            .getByReceiptNumber(number)
            .pipe(finalize(() => (this.isSearchingByReceiptNumber = false)))
            .subscribe((answer) => {
                if (!answer) {
                    this.notificationsService.error(`Receipt ${number} not found.`);
                    this.receiptNumberInputFocus();
                    return;
                }

                this.receipt = answer;
                this.receiptNote = this.receipt?.Note;
                this.createReceiptNoteField();
                this.poundsPerBundle = this.receipt.ReceiptDetails[0].PoundsPerBundle;
                this.cdr.detectChanges();
            });
    }

    loadReceipt(receiptId: number, isInit = false): void {
        this.receiptService.getById(receiptId).subscribe((answer) => {
            this.receipt = answer;
            this.receiptNote = this.receipt?.Note;
            this.createReceiptNoteField();
            this.poundsPerBundle = this.receipt.ReceiptDetails[0].PoundsPerBundle;
            if (isInit) {
                this.cdr.detectChanges();
            }
        });
    }

    receiptNumberInputFocus(): void {
        setTimeout(() => this.receiptNumberInput.nativeElement.focus());
    }

    clearForm(): void {
        this.receipt = this.receiptService.getEmptyReceipt();
        this.receiptAuditReason = '';
        this.poundsPerBundle = null;
        this.receiptNumberInputFocus();
        this.clearNoteField();
    }

    closeAuditModal(): void {
        this.receiptAuditReason = '';
        this.enableDoubleClick();
        this.auditModal.close();
        return;
    }

    confirmAuditModal(): void {
        // update the details then create an audit log
        this.updateReceiptDetails(() => {
            this.receiptService.createAuditLog(this.receipt.Id, this.receiptAuditReason).subscribe((answer) => {
                this.success();
            });
        });
        this.auditModal.close();
    }

    getReceiptDetailsForUpdate(): IReceiptDetail[] {
        let payload: IReceiptDetail[] = [];
        this.receipt.ReceiptDetails.forEach((detail) => {
            let obj = { ...detail };
            obj.OrderPriceType = null;
            obj.Receipt = null;
            obj.PoundsPerBundle = this.poundsPerBundle;
            payload.push(obj);
        });
        return payload;
    }

    getSkidsPerReceiptDetail(index: number): number {
        let skids = 0;
        if (this.receipt.ReceiptDetails[index].SalesOrder) {
            this.receipt.ReceiptDetails[index].SalesOrder.ShipmentDetails.forEach((sd) => {
                skids += sd.NumberOfSkids;
            });
        }
        return skids;
    }

    formSubmitted(): void {
        if (this.isValid()) {
            this.receiptService.receiptIsInvoiced(this.receipt.Id).subscribe((isInvoiced) => {
                if (isInvoiced) {
                    this.auditModal.show();
                } else {
                    this.updateReceiptDetails(() => {
                        this.success();
                    });
                }
            });
        } else {
            this.error();
            this.enableDoubleClick();
        }
    }

    updateReceiptDetails(callback: any = null): void {
        this.receiptService
            .updateDetails(this.receipt.Id, this.getReceiptDetailsForUpdate())
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                if (callback) {
                    callback();
                }
                this.getReceiptByNumber(this.receipt.Number);
            });
    }

    isValid(): boolean {
        return true;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        if (this.isBillingSheetAdjustment) {
            this.loadReceipt(this.receiptId, false);
        } else {
            this.clearForm();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Receipt details saved successfully.');
        this.onReceiptUpdated.emit();
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    calculatePricingUnits(orderPriceTypeId: number, index: number): void {
        let units = 0;
        switch (orderPriceTypeId) {
            case OrderPriceTypes.B:
                units = this.receipt.ReceiptDetails[index].Bundles;
                break;
            case OrderPriceTypes.M:
                units = this.receipt.ReceiptDetails[index].Pieces / 1000;
                break;
            case OrderPriceTypes.P:
                units = this.receipt.ReceiptDetails[index].Pieces;
                break;
            case OrderPriceTypes.R:
            case OrderPriceTypes.S:
                units = this.getSkidsPerReceiptDetail(index);
                break;
            case OrderPriceTypes.T:
                units = (this.receipt.ReceiptDetails[index].Weight ?? 0) / 2000;
                break;
            default:
                break;
        }
        this.receipt.ReceiptDetails[index].Units = units;
    }

    printReceipt(): void {
        this.receiptService.generateReceiptPaperwork(this.receiptId)
            .subscribe((pdf) => {
                PrintService.printPdf(pdf);
            });
    }

    getUnits(receiptDetail: IReceiptDetail): number {
        return receiptDetail.SalesOrder?.OrderTypeId === OrderTypeIds.Stock
                ? this.receiptService.getStockQuantity(receiptDetail)
                : receiptDetail.Units;
    }

    getExtendedPrice(receiptDetail: IReceiptDetail): number {
        return +(this.getUnits(receiptDetail) * receiptDetail.PricePer).toFixed(2);
    }
}
