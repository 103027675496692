import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';
import { ICustomerShippingAddress } from '../../model/interfaces/customer-shipping-address';
import { EMPTY, Observable } from 'rxjs';
import { ModalService } from '@mt-ng2/modal-module';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CustomerShippingAddressService extends SharedEntitiesService<ICustomerShippingAddress> {
    constructor(public http: HttpClient,
        private modalService: ModalService,
    ) {
        super('/customers', '/shippingaddresses', http);
    }

    saveEntity(parentId: number, entity: ICustomerShippingAddress): Observable<number> {
        if (entity.Id !== 0) {
            return this.modalService
                .showModal({
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    showConfirmButton: true,
                    text: 'Are you sure you want to update this Shipping Address? Doing so will update all existing orders associated with this record',
                    title: 'Update Address',
                })
                .pipe(
                    switchMap((res) => {
                        if (res.isConfirmed) {
                            return super.saveEntity(parentId, entity);
                        } else {
                            return EMPTY;
                        }
                    }));
        } else {
            return super.saveEntity(parentId, entity);
        }
    }

    archiveAddress(customerId: number, shippingAddressId: number): Observable<void> {
        return this.http.put<void>(`/customers/${customerId}/shippingaddresses/${shippingAddressId}/archive`, {});
    }
}
