<div class="row">
    <div class="col-md-7">
        <h2>Users</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-10">
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    </div>
    <div class="col-md-2">
        <mt-search-filter-checkbox text="Include Revoked" (onValueChanged)="revoke($event)"></mt-search-filter-checkbox>
    </div>
</div>
<br />
<entity-list
    [entities]="users"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getUsers()"
    (onItemSelected)="userSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>

<div *ngIf="canAddUser" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/users', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
