import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { TestStackDynamicControls, ITestStackDynamicControlsParameters } from '../form-controls/test-stack.form-controls';
import { ITestStack } from '../interfaces/test-stack';

export class TestStackDynamicControlsPartial extends TestStackDynamicControls {

    constructor(testStackPartial?: ITestStack, additionalParameters?: ITestStackDynamicControlsParameters) {
        super(testStackPartial, additionalParameters);

        (<DynamicField>this.Form.Name).type.inputType = InputTypes.Textbox;
    }
}
