import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { ICommissionSalesPerson } from '../interfaces/commission-sales-person';

export interface ICommissionSalesPersonDynamicControlsParameters {
    formGroup?: string;
}

export class CommissionSalesPersonDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private commissionsalesperson?: ICommissionSalesPerson, additionalParameters?: ICommissionSalesPersonDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CommissionSalesPerson';

        this.Form = {
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.commissionsalesperson && this.commissionsalesperson.hasOwnProperty('FirstName') && this.commissionsalesperson.FirstName !== null ? this.commissionsalesperson.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.commissionsalesperson && this.commissionsalesperson.hasOwnProperty('LastName') && this.commissionsalesperson.LastName !== null ? this.commissionsalesperson.LastName.toString() : '',
            }),
        };

        this.View = {
            FirstName: new DynamicLabel(
                'First Name',
                this.commissionsalesperson && this.commissionsalesperson.hasOwnProperty('FirstName') && this.commissionsalesperson.FirstName !== null ? this.commissionsalesperson.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.commissionsalesperson && this.commissionsalesperson.hasOwnProperty('LastName') && this.commissionsalesperson.LastName !== null ? this.commissionsalesperson.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
