<h2>Sales Orders</h2>
<div>
    <div class="row">
        <div class="col-md-12">
            <custom-meta-item-managed-list
                [service]="boardGradeService"
                [dynamicForm]="boardGradeDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></custom-meta-item-managed-list>
            <br />
            <custom-meta-item-managed-list
                [service]="generalLedgerService"
                [dynamicForm]="generalLedgerCodeDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
                [componentTitle]="generalLedgerCodeTitle"
                [fields]="['Code', 'Name', 'TonsAndPricing', 'GlName', 'QbItemName','IsStock']"
                [customColWidths]="[0, 0, 0, 2, 3, 1]"
            ></custom-meta-item-managed-list>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <meta-item-managed-list
                [service]="finishService"
                [dynamicForm]="finishDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="liningService"
                [dynamicForm]="liningDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="stockColorsService"
                [dynamicForm]="stockColorsDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="warehouseLocationsService"
                [dynamicForm]="warehouseLocationsDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
                [componentTitle]="warehouseLocationsTitle"
            ></meta-item-managed-list>
        </div>
        <div class="col-md-6">
            <meta-item-managed-list
                [service]="defectTypesService"
                [dynamicForm]="defectTypesDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="stockItemTypesService"
                [dynamicForm]="stockItemTypesDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="bandingInstructionService"
                [dynamicForm]="bandingInstructionsDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            >
            </meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="commissionSalesPersonService"
                [dynamicForm]="commissionSalesPersonDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            >
            </meta-item-managed-list>
            <br />
            <meta-item-managed-list
                [service]="millComplaintCategoryService"
                [dynamicForm]="millComplaintCategoryDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            >
            </meta-item-managed-list>
        </div>
    </div>
</div>
<br />
<a routerLink="./customers" class="btn btn-default btn-flat">Close</a>
