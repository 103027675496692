import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ICustomerCreditStatus } from '../model/interfaces/customer-credit-status';

@Injectable()
export class CustomerCreditStatusService extends StaticMetaItemService<ICustomerCreditStatus> {
    constructor(public http: HttpClient) {
        super('CustomerCreditStatusService', 'Credit Status', 'CreditStatusIds', '/customers/customercreditstatuses', http);
    }
}
