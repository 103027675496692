import { Directive, HostListener, Input } from '@angular/core';

type ModifierKey = 'alt' | 'ctrl' | 'shift';

export interface IPagingHotkeyOptions {
    actionOnNext: () => void,
    actionOnPrev: () => void,
    nextKey: [ModifierKey, string],
    prevKey: [ModifierKey, string],
}

@Directive({
    selector: '[pagingHotkeys]',
})
export class PagingHotkeysDirective {
    @Input() pagingHotkeysOptions: IPagingHotkeyOptions;

    @HostListener('document:keydown', ['$event'])
    handleHotkeyPress(event: KeyboardEvent): void {
        if (this.keyPressed(this.pagingHotkeysOptions?.nextKey, event)) {
            this.pagingHotkeysOptions?.actionOnNext();
            event.preventDefault();
        }
        if (this.keyPressed(this.pagingHotkeysOptions?.prevKey, event)) {
            this.pagingHotkeysOptions?.actionOnPrev();
            event.preventDefault();
        }
    }

    private keyPressed(key: [ModifierKey, string], event: KeyboardEvent): boolean {
        let modifierKeyPressed = false;
        switch (key[0]) {
            case 'alt':
                modifierKeyPressed = event.altKey;
                break;
            case 'ctrl':
                modifierKeyPressed = event.ctrlKey;
                break;
            case 'shift':
                modifierKeyPressed = event.shiftKey;
                break;
            default:
                return false;
        }

        return modifierKeyPressed
                && key[1].toLowerCase() === event.key.toLowerCase();
    }

}
