<div>
    <h2>Mill Corp Invoice</h2>
</div>
<div class="row">
    <div class="col-md-9">
        <div class="miles-form padded">
            <h4>Mill Corp Invoice Info</h4>

            <div class="row">
                <label *ngIf="!deliveryDateInput" class="col-md-8">
                    Enter the load #:
                    <div class="input-group">
                        <input
                            type="number"
                            class="form-control"
                            ngModel
                            [ngModelOptions]="{ standalone: true }"
                            #shipmentIdInput="ngModel"
                            (ngModelChange)="shipmentId$.next($event)"
                        />
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default btn-flat no-shadow" (click)="cancel()">
                                <i class="fa fa-times"></i>
                            </button>
                        </span>
                    </div>
                </label>
                
                <label *ngIf="deliveryDateInput" class="col-md-8">
                    Select the load #:
                    <div class="input-group">
                        <select
                            class="form-control"
                            [(ngModel)]="selectedMillCorpInvoiceId"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="loadNumberSelected()"
                        >
                            <option value=""></option>
                            <option *ngFor="let load of loadsByDeliveryDate" [ngValue]="load.MillCorpInvoiceId">
                                {{ load.LoadNumber }}
                            </option>
                        </select>
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default btn-flat no-shadow" (click)="cancel()">
                                <i class="fa fa-times"></i>
                            </button>
                        </span>
                    </div>
                </label>

                <label class="col-md-4">
                    Delivery Date:
                    <div class="input-group">
                        <input 
                            type="date"
                            class="form-control"
                            [(ngModel)]="deliveryDateInput"
                            (ngModelChange)="getLoadsByDeliveryDate()"
                        />
                    </div>
                </label>
            </div>

            <div class="row padded">
                <button 
                    class="btn btn-success" 
                    [disabled]="shipment || isBlankLoad"
                    (click)="createBlankLoad()">
                    Blank Load
                </button>
            </div>

            <form *ngIf="shipment || isBlankLoad" [formGroup]="millCorpInvoiceForm" (ngSubmit)="formSubmitted()">
                <div *ngIf="!isBlankLoad">
                    <div class="row">
                        <label class="col-md-8">
                            Order
                            <select
                                class="form-control"
                                [(ngModel)]="this.selectedOrderId"
                                [ngModelOptions]="{ standalone: true }"
                                (change)="orderChanged()"
                            >
                                <option *ngFor="let order of salesOrders" [ngValue]="order.Id">
                                    {{
                                        'Order #' +
                                            order.OrderNumberWithPostFix +
                                            ' | ' +
                                            getOrderDimensions(order) +
                                            ' | ' +
                                            order.OrderInfo.CustomerShippingAddress.ShipToName
                                    }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <br />
                    <fieldset>
                        <legend>Order Info</legend>
                        <table class="order-info-table">
                            <tr>
                                <td class="text-right"><label>Customer Name</label></td>
                                <td>{{ orderDetails.CustomerName }}</td>
                                <td class="text-right"><label>Order Date</label></td>
                                <td>{{ orderDetails.OrderDate | date : 'MM/dd/yyyy' }}</td>
                                <td class="text-right"><label>Our Order #</label></td>
                                <td>{{ orderDetails.OurOrderNumber }}</td>
                            </tr>
                            <tr>
                                <td rowspan="4" class="text-right"><label>Ship To</label></td>
                                <td rowspan="4" [innerHTML]="orderDetails.ShipTo | shippingAddress : 'name' : 'multiline'"></td>
                                <td class="text-right"><label>Ship Date</label></td>
                                <td>{{ orderDetails.ShipDate | date : 'MM/dd/yyyy' }}</td>
                                <td class="text-right"><label>Customer PO #</label></td>
                                <td>{{ orderDetails.CustomerPONumber }}</td>
                            </tr>
                            <tr>
                                <td class="text-right"><label>Stop #</label></td>
                                <td>{{ orderDetails.StopNumberFromLoad }}</td>
                                <td class="text-right"><label>Customer Product #</label></td>
                                <td>{{ orderDetails.CustomerProductNumber }}</td>
                            </tr>
                            <tr>
                                <td class="text-right"><label>Trailer #</label></td>
                                <td>{{ orderDetails.TrailerNumber }}</td>
                                <td class="text-right"><label>Receipt #</label></td>
                                <td>{{ orderDetails.ReceiptNumber }}</td>
                            </tr>
                            <tr>
                                <td class="text-right"><label>Product Spect</label></td>
                                <td>{{ orderDetails.ProductSpectOnTruck }}</td>
                            </tr>
                        </table>
                    </fieldset>
                </div>
                <br />
                <div formGroupName="MillCorpInvoice">
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Date of Delivery</legend>
                                <dynamic-field
                                    [field]="millCorpInvoiceFormControls.DateOfDelivery"
                                    [form]="millCorpInvoiceForm"
                                ></dynamic-field>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset disabled>
                                <legend>Mill Corp Load #</legend>
                                <input 
                                    type="text"
                                    class="form-control"
                                    [value]="millCorpInvoice?.MillCorpLoadNumber || ''">
                            </fieldset>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Invoice Info</legend>
                                <dynamic-field [field]="millCorpInvoiceFormControls.DriversName" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.TractorNumber" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.Notes" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.Freight" [form]="millCorpInvoiceForm"></dynamic-field>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Mileage / Fuel</legend>
                                <dynamic-field [field]="millCorpInvoiceFormControls.MileageOut" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.MileageIn" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.Gallons" [form]="millCorpInvoiceForm"></dynamic-field>
                                <!-- <br /> -->
                                <div class="flex-row">
                                    <div class="center">
                                        <strong>Mileage:</strong>
                                        {{ mileage }}
                                    </div>
                                    <div class="center">
                                        <strong>MPG:</strong>
                                        {{ mileage && mpg > 0 ? (mpg | number : '1.1-1') : '--.--' }}
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-12">
                            <fieldset formArrayName="BackhaulInfo">
                                <legend>Backhaul Info</legend>
                                <div *ngFor="let backhaulInfo of backhaulInfo.controls; let i = index">
                                    <div class="flex-row" [formGroup]="backhaulInfo">
                                        <dynamic-field
                                            *ngIf="millCorpCustomersLoaded"
                                            class="grow-1"
                                            [field]="millCorpCustomerDynamicFields[i]"
                                            [form]="backhaulInfo"
                                            [overrideForm]="true"
                                        ></dynamic-field>
                                        <dynamic-field
                                            class="grow-2"
                                            [field]="backhaulInfoFormControls.Description"
                                            [form]="backhaulInfo"
                                            [overrideForm]="true"
                                        ></dynamic-field>
                                        <dynamic-field
                                            class="grow-1"
                                            [field]="backhaulInfoFormControls.PickupTrailer"
                                            [form]="backhaulInfo"
                                            [overrideForm]="true"
                                        ></dynamic-field>
                                        <dynamic-field
                                            class="grow-1"
                                            [field]="backhaulInfoFormControls.DropOffTrailer"
                                            [form]="backhaulInfo"
                                            [overrideForm]="true"
                                        ></dynamic-field>
                                        <i class="fa fa-times center" (click)="removeBackhaulInfoInput(i)"></i>
                                    </div>
                                </div>
                                <div *ngIf="backhaulInfo.controls.length < backhaulInfoCap" class="fab-wrap">
                                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addBackhaulInfoInputs()">
                                        <span class="fa fa-plus"></span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset formArrayName="StateMileages">
                                <legend>Travel</legend>
                                <div *ngFor="let stateMileages of stateMileages.controls; let i = index">
                                    <div class="flex-row" [formGroup]="stateMileages">
                                        <div class="flex-row grow-1">
                                            <dynamic-field
                                                class="grow-1"
                                                [field]="stateMileageFormControls.Statecode"
                                                [form]="stateMileages"
                                                [overrideForm]="true"
                                            ></dynamic-field>
                                            <dynamic-field
                                                class="grow-1"
                                                [field]="stateMileageFormControls.Mileage"
                                                [form]="stateMileages"
                                                [overrideForm]="true"
                                            ></dynamic-field>
                                        </div>
                                        <i class="fa fa-times center" (click)="removeStateMileageInput(i)"></i>
                                    </div>
                                </div>
                                <div class="">
                                    <label for="mileageTotal">Total:</label> <span id="mileageTotal">{{ mileageTotal }}</span>
                                </div>
                                <div class="fab-wrap">
                                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addStateMileageInputs()">
                                        <span class="fa fa-plus"></span>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Return Loads</legend>
                                <dynamic-field [field]="millCorpInvoiceFormControls.WastePaperValue" [form]="millCorpInvoiceForm"></dynamic-field>
                                <div class="flex-row">
                                    <dynamic-field [field]="millCorpInvoiceFormControls.PalletQty" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <dynamic-field [field]="millCorpInvoiceFormControls.PalletRate" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <div class="flex-col total">
                                        <label for="palletsTotal">Total</label>
                                        <span id="palletsTotal">{{ palletsTotal | currency : 'USD' }}</span>
                                    </div>
                                </div>
                                <div class="flex-row">
                                    <dynamic-field [field]="millCorpInvoiceFormControls.TopQty" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <dynamic-field [field]="millCorpInvoiceFormControls.TopRate" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <div class="flex-col total">
                                        <label for="topsTotal">Total</label>
                                        <span id="topsTotal">{{ topsTotal | currency : 'USD' }}</span>
                                    </div>
                                </div>
                                <div class="flex-row">
                                    <dynamic-field [field]="millCorpInvoiceFormControls.WaitingQty" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <dynamic-field [field]="millCorpInvoiceFormControls.WaitingRate" [form]="millCorpInvoiceForm"></dynamic-field>
                                    <div class="flex-col total">
                                        <label for="waitingTotal">Total</label>
                                        <span id="waitingTotal">{{ waitingTotal | currency : 'USD' }}</span>
                                    </div>
                                </div>
                                <div class="flex-row">
                                    <dynamic-field
                                        class="grow-1"
                                        [field]="millCorpInvoiceFormControls.MiscPickUpValue"
                                        [form]="millCorpInvoiceForm"
                                    ></dynamic-field>
                                    <dynamic-field
                                        class="grow-1"
                                        [field]="millCorpInvoiceFormControls.MiscPickUpDescription"
                                        [form]="millCorpInvoiceForm"
                                    ></dynamic-field>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Stop Offs</legend>
                                <dynamic-field [field]="millCorpInvoiceFormControls.StopQty" [form]="millCorpInvoiceForm"></dynamic-field>
                                <dynamic-field [field]="millCorpInvoiceFormControls.StopRate" [form]="millCorpInvoiceForm"></dynamic-field>
                                <div><label>Total:</label> {{ stopOffsTotal | currency : 'USD' }}</div>
                            </fieldset>
                            <br />
                            <fieldset>
                                <legend>Totals</legend>
                                <div class="flex-row">
                                    <label for="freighRevenue">Freight Revenue</label>
                                    <span id="freighRevenue">{{ freightRevenue | currency : 'USD' }}</span>
                                </div>
                                <div class="flex-row">
                                    <label for="wastePickUp">Waste Pick Up</label>
                                    <span id="wastePickUp">{{ wastePickUp | currency : 'USD' }}</span>
                                </div>
                                <div class="flex-row">
                                    <label for="otherMaterial">Other Material</label>
                                    <span id="otherMaterial">{{ otherMaterial | currency : 'USD' }}</span>
                                </div>
                                <hr />
                                <div class="flex-row">
                                    <label for="otherMaterial">Grand Total</label>
                                    <span id="otherMaterial">{{ grandTotal | currency : 'USD' }}</span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <br />
                <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-success">Save</button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
            </form>
        </div>
    </div>
</div>
