import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ICustomer } from '../../model/interfaces/customer';
import { CustomerService } from '../customer.service';
import { Subscription } from 'rxjs';
import { mergeMap, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-customer-header',
    templateUrl: './customer-header.component.html',
})
export class CustomerHeaderComponent implements OnInit, OnDestroy {
    customer: ICustomer;
    header: string;
    routeSubscription: Subscription;
    basicInfoSubscription: Subscription;

    constructor(private customerService: CustomerService, private route: ActivatedRoute, private router: Router) {
        this.routeSubscription = router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => route))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
            )
            .pipe(filter((route) => route.outlet === 'primary'))
            .pipe(mergeMap((route) => route.data))
            .subscribe((event) => {
                this.ngOnInit();
            });

        this.basicInfoSubscription = customerService.changeEmitted$.subscribe((customer) => {
            this.ngOnInit();
        });
    }

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('customerId');
        if (id > 0) {
            this.getCustomerById(id);
        } else {
            this.header = 'Add Customer';
            this.customer = this.customerService.getEmptyCustomer();
        }
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        this.basicInfoSubscription.unsubscribe();
    }

    getCustomerById(id: number): void {
        this.customerService.getById(id).subscribe((customer) => {
            this.customer = customer;
            this.header = `Customer: ${customer.CompanyName}`;
        });
    }
}
