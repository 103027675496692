import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IStateMileage } from '../interfaces/state-mileage';
import { IMillCorpInvoice } from '../interfaces/mill-corp-invoice';

export interface IStateMileageDynamicControlsParameters {
    formGroup?: string;
    millCorpInvoices?: IMillCorpInvoice[];
}

export class StateMileageDynamicControls {

    formGroup: string;
    millCorpInvoices?: IMillCorpInvoice[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private statemileage?: IStateMileage, additionalParameters?: IStateMileageDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'StateMileage';
        this.millCorpInvoices = additionalParameters && additionalParameters.millCorpInvoices || undefined;

        this.Form = {
            Mileage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mileage',
                name: 'Mileage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.statemileage && this.statemileage.Mileage || null,
            }),
            MillCorpInvoiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Corp Invoice',
                name: 'MillCorpInvoiceId',
                options: this.millCorpInvoices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.statemileage && this.statemileage.MillCorpInvoiceId || null,
            }),
            Statecode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Statecode',
                name: 'Statecode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(2) ],
                validators: { 'maxlength': 2 },
                value: this.statemileage && this.statemileage.hasOwnProperty('Statecode') && this.statemileage.Statecode != null ? this.statemileage.Statecode.toString() : '',
            }),
        };

        this.View = {
            Mileage: new DynamicLabel({
                label: 'Mileage',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.statemileage && this.statemileage.Mileage || null,
            }),
            MillCorpInvoiceId: new DynamicLabel({
                label: 'Mill Corp Invoice',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.millCorpInvoices as unknown as IMetaItem[], this.statemileage && this.statemileage.hasOwnProperty('MillCorpInvoiceId') ? this.statemileage.MillCorpInvoiceId : null) ?? '',
            }),
            Statecode: new DynamicLabel({
                label: 'Statecode',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.statemileage && this.statemileage.hasOwnProperty('Statecode') && this.statemileage.Statecode != null ? this.statemileage.Statecode.toString() : '',
            }),
        };

    }
}
