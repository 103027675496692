<h2>Add Customer</h2>
<div *ngIf="formLoaded">
    <form [formGroup]="addCustomerForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-6">
                <div class="miles-form padded">
                    <h4>Customer Information</h4>
                    <dynamic-field [field]="abstractCustomerControls.PrimarySalesPersonId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.SecondarySalesPersonId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.CustomerName" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.CompanyName" [form]="addCustomerForm"></dynamic-field>
                </div>
                <br />
                <div class="miles-form padded">
                    <h4>Customer Address</h4>
                    <mt-address
                        [allowInternationalAddresses]="true"
                        [showIsPrimary]="false"
                        [parentForm]="addCustomerForm.controls.CustomerAddress"
                        [addressContainer]="emptyAddressContainer"
                    ></mt-address>
                </div>
                <br />
                <div class="miles-form padded">
                    <h4>Customer Phone <span class="errortext ng-star-inserted" style="font-weight: 100;"> *</span></h4>
                    <div formGroupName="CustomerPhone">
                        <div class="input-group">
                            <span class="input-group-btn">
                                <span ngbDropdown class="dropdown">
                                    <button ngbDropdownToggle type="button" class="btn btn-default btn-flat dropdown-toggle">
                                        <i class="fa fa-fw {{ getIconForCustomerPhone() }}"></i>
                                        <span class="caret"></span>
                                    </button>
                                    <ul ngbDropdownMenu class="dropdown-menu">
                                        <li (click)="setCustomerPhoneType(1)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-home"></span>&#160; Home</a
                                            >
                                        </li>
                                        <li (click)="setCustomerPhoneType(2)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-phone"></span>&#160; Work</a
                                            >
                                        </li>
                                        <li (click)="setCustomerPhoneType(3)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-mobile"></span>&#160; Mobile</a
                                            >
                                        </li>
                                        <li (click)="setCustomerPhoneType(4)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-fax"></span>&#160; Fax</a
                                            >
                                        </li>
                                    </ul>
                                </span>
                            </span>
                            <div class="row">
                                <div class="col-md-8" style="padding-right: 0;" [class.has-error]="phoneHasError()">
                                    <input type="tel" class="form-control" formControlName="Phone" phoneMask minlength="10" />
                                </div>
                                <div class="col-md-4" style="padding-left: 0;">
                                    <div class="input-group" style="width: 100%;">
                                        <span class="input-group-addon" id="ext-addon">Ext:</span>
                                        <input
                                            aria-describedby="ext-addon"
                                            type="tel"
                                            class="form-control"
                                            id="inputExt"
                                            formControlName="Extension"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                    Cancel
                </button>
            </div>
            <div class="col-md-6">
                <div class="miles-form padded">
                    <h4>Contact Information</h4>
                    <dynamic-field [field]="abstractContactControls.TypeId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractContactControls.FirstName" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractContactControls.LastName" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractContactControls.Title" [form]="addCustomerForm"></dynamic-field>
                    <div formGroupName="ContactPhone">
                        <label>Phone</label>
                        <div class="input-group" [style.marginBottom.px]="15">
                            <span class="input-group-btn">
                                <span ngbDropdown class="dropdown">
                                    <button ngbDropdownToggle type="button" class="btn btn-default btn-flat dropdown-toggle">
                                        <i class="fa fa-fw {{ getIconForContactPhone() }}"></i>
                                        <span class="caret"></span>
                                    </button>
                                    <ul ngbDropdownMenu class="dropdown-menu">
                                        <li (click)="setContactPhoneType(1)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-home"></span>&#160; Home</a
                                            >
                                        </li>
                                        <li (click)="setContactPhoneType(2)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-phone"></span>&#160; Work</a
                                            >
                                        </li>
                                        <li (click)="setContactPhoneType(3)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-mobile"></span>&#160; Mobile</a
                                            >
                                        </li>
                                        <li (click)="setContactPhoneType(4)">
                                            <a class="dropdown-item">
                                                <i class="fa fa-fw"></i>
                                                <span class="fa fa-no-margin-right fa-fax"></span>&#160; Fax</a
                                            >
                                        </li>
                                    </ul>
                                </span>
                            </span>
                            <div class="row">
                                <div class="col-md-8" style="padding-right: 0;" [class.has-error]="contactPhoneHasError()">
                                    <input type="tel" class="form-control" formControlName="Phone" phoneMask minlength="10" />
                                </div>
                                <div class="col-md-4" style="padding-left: 0;">
                                    <div class="input-group" style="width: 100%;">
                                        <span class="input-group-addon" id="ext-addon">Ext:</span>
                                        <input
                                            aria-describedby="ext-addon"
                                            type="tel"
                                            class="form-control"
                                            id="inputExt"
                                            formControlName="Extension"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <dynamic-field [field]="abstractContactControls.Email" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractContactControls.Notes" [form]="addCustomerForm"></dynamic-field>
                </div>
                <br />
                <div class="miles-form padded">
                    <h4>Shipping Information</h4>
                    <dynamic-field [field]="abstractCustomerControls.ApexShippingManifest" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.ShipVia" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.ShipOnTypeId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.InchesBetweenRunners" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.RunnerDirectionTypeId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.RunnerHeight" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.SpecialNotes" [form]="addCustomerForm"></dynamic-field>
                </div>
                <br />
                <div class="miles-form padded">
                    <h4>Invoicing Information</h4>
                    <dynamic-field [field]="abstractCustomerControls.InvoiceName" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.InvoiceEmail" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.InvoiceNote" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.PaymentTermId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.CreditStatusId" [form]="addCustomerForm"></dynamic-field>
                    <dynamic-field [field]="abstractCustomerControls.IsAuthorizedForCreditCard" [form]="addCustomerForm"></dynamic-field>
                </div>
            </div>
        </div>
    </form>
</div>
