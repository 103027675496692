import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ITagSkidType } from '../model/interfaces/tag-skid-type';

@Injectable()
export class TagSkidTypeService extends StaticMetaItemService<ITagSkidType> {
    constructor(public http: HttpClient) {
        super('TagSkidTypeService', 'Type', 'TypeIds', '/tagskidtypes', http);
    }
}
