import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';

import { IShipment } from '@model/interfaces/shipment';
import { IShipmentDetail } from '@model/interfaces/shipment-detail';
import { IShipmentStop } from '@model/interfaces/shipment-stop';
import { ISkid } from '@model/interfaces/skid';
import { ITrailer } from '@model/interfaces/trailer';
import { ILoadForDate, IUpdateLoadForDatePayload } from '@model/interfaces/custom/update-loads-for-date-payload';
import { IHauler } from '@model/interfaces/hauler';
import { ITrailerInfoPayload } from '@model/interfaces/custom/trailer-info-payload';
import { IStockReceiptDetailsPayload } from '@model/interfaces/custom/stock-receipt-details-payload';
import { IStockOrderLine } from '@model/interfaces/stock-order-line';

export const emptyShipment: IShipment = {
    AllocatedToDloc: false,
    Comments: '',
    DateCreated: new Date(),
    Id: 0,
    IsPickup: null,
    Note: null,
    ShipmentDate: null,
    StatusId: 0,
    StraightTruck: null,
};

export const emptyShipmentDetail: IShipmentDetail = {
    Id: 0,
    NumberOfSkids: null,
    Row: null,
    SalesOrderId: null,
    ShipmentId: 0,
    Stop: null,
};

export const emptyShipmentStop: IShipmentStop = {
    CustomerShippingAddressId: null,
    ShipmentId: 0,
    Stop: null,
};

export const emptyShipmentReceiptOptions: IShipmentReceiptOptions = {
    ShipmentId: null,
    StopOptions: null,
};

export const emptyShipmentBillOfLadingOptions: IShipmentBillOfLadingOptions = {
    Comments: null,
    CustomAddress: null,
    IncludeLoadSheetSummary: false,
    IsApproximateWeight: false,
    MainStop: null,
    NumberOfSkids: null,
    PaymentNoteId: null,
    PrintTotalSkids: false,
    ShipmentId: null,
    ShippersNumber: null,
    Stops: null,
    Weight: null,
};

export interface IOpenShipment {
    AllocatedToDloc: boolean;
    ShipmentId: number;
    Trailer: ITrailer;
    Hauler: IHauler;
    Length: string;
    LightWeight: number;
    TargetWeight: number;
    CurrentWeight: number;
    AvailableWeight: number;
    BayNumber: string;
    NumberOfSkids: number;
    SkidsLoaded: number;
    SalesOrderIds: number[];
}

export enum ReceiptSizeTotal {
    Pieces = 1,
    NetWeight = 2,
    Bundles = 3,
    TiedBundles = 4,
}

export interface IShipmentReceiptOptions {
    ShipmentId: number;
    StopOptions: IStopOptions[];
}

export interface IStopOptions {
    PrintIndividualReceipts: boolean;
    ReceiptOptions: IReceiptOptions[];
    StopNumber: number;
}

export interface IReceiptOptions {
    OmitNewmanHeaderOnCustomerCopy: boolean;
    Print: boolean;
    ReceiptId: number;
}

export interface IShipmentBillOfLadingOptions {
    ShipmentId: number;
    Stops: number[];
    MainStop: number;
    CustomAddress: string;
    ShippersNumber: string;
    PrintTotalSkids: boolean;
    NumberOfSkids: number;
    Weight: number;
    IsApproximateWeight: boolean;
    PaymentNoteId: number;
    Comments: string;
    IncludeLoadSheetSummary: boolean;
}

@Injectable()
export class ShipmentService extends BaseService<IShipment> {
    constructor(public http: HttpClient) {
        super('/shipments', http);
    }

    getEmptyShipment(): IShipment {
        return { ...emptyShipment };
    }

    getEmptyShipmentDetail(): IShipmentDetail {
        return { ...emptyShipmentDetail };
    }

    getEmptyShipmentStop(): IShipmentStop {
        return { ...emptyShipmentStop };
    }

    getEmptyShipmentReceiptOptions(): IShipmentReceiptOptions {
        return { ...emptyShipmentReceiptOptions };
    }

    getEmptyShipmentBillOfLadingOptions(): IShipmentBillOfLadingOptions {
        return { ...emptyShipmentBillOfLadingOptions };
    }

    getShipments(searchParams?: SearchParams): Observable<HttpResponse<IShipment[]>> {
        let params = new HttpParams();
        if (searchParams) {
            if (searchParams.skip) {
                params = params.append('skip', searchParams.skip.toString());
            }
            if (searchParams.take) {
                params = params.append('take', searchParams.take.toString());
            }
        }
        return this.http.get<IShipment[]>('/shipments/list', { observe: 'response', params: params });
    }

    getShipmentSkids(id: number): Observable<ISkid[]> {
        return this.http.get<ISkid[]>('/shipments/' + id + '/skids');
    }

    getShipmentStockOrderLines(id: number): Observable<IStockOrderLine[]> {
        return this.http.get<IStockOrderLine[]>(`/shipments/${id}/stock-order-lines`);
    }

    getShipment(id: number): Observable<IShipment> {
        return this.http.get<IShipment>('/shipments/' + id);
    }

    getOpenShipments(searchParams?: SearchParams): Observable<HttpResponse<IOpenShipment[]>> {
        let params = new HttpParams();
        if (searchParams) {
            if (searchParams.skip) {
                params = params.append('skip', searchParams.skip.toString());
            }
            if (searchParams.take) {
                params = params.append('take', searchParams.take.toString());
            }
        }
        return this.http.get<IOpenShipment[]>('/shipments/opened', { observe: 'response', params: params });
    }

    openShipment(shipmentId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/open', null);
    }

    closeShipment(shipmentId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/close', null);
    }

    cancelShipment(shipmentId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/cancel', null);
    }

    addSkidToShipment(shipmentId: number, skidId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/skids/' + skidId, null);
    }

    addStockItemToShipment(shipmentId: number, stockItemId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/stock-items/' + stockItemId, null);
    }

    allocateToDloc(shipmentId: number): Observable<string> {
        return this.http.post<string>('/shipments/' + shipmentId + '/dloc', null);
    }

    quickLoad(shipmentId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/skids/quick-load', null);
    }

    getReceiptPdf(options: IShipmentReceiptOptions): Observable<any> {
        return this.http.post('/shipments/' + options.ShipmentId + '/reports/receipt/pdf', options);
    }

    getShipmentReportPdf(shipmentId: number, reportTypeId: number): Observable<any> {
        return this.http.get<any>(`/shipments/${shipmentId}/reports/${reportTypeId}`);
    }

    getBillOfLadingPdf(options: IShipmentBillOfLadingOptions): Observable<any> {
        return this.http.post('/shipments/' + options.ShipmentId + '/reports/bill-of-lading/pdf', options);
    }

    assignTrailer(shipmentId: number, trailerId: number): Observable<any> {
        return this.http.post('/shipments/' + shipmentId + '/trailer/' + trailerId, null);
    }

    printShipment(shipmentId: number): any {
        return this.http.get('/shipments/' + shipmentId + '/print');
    }

    printProcessedSkids(salesOrderId: number): any {
        return this.http.get('/shipments/' + salesOrderId + '/print-processed');
    }

    printConvertingSkids(salesOrderId: number): any {
        return this.http.get('/shipments/' + salesOrderId + '/print-converting');
    }

    getShipmentsForDate(date: string): Observable<ILoadForDate[]> {
        let params = new HttpParams();
        params = params.append('date', date);
        return this.http.get<ILoadForDate[]>(`/shipments/loads-for-date`, { params: params });
    }

    updateShipmentsForDate(data: IUpdateLoadForDatePayload): any {
        return this.http.put(`/shipments/loads-for-date`, data);
    }

    updateStockReceipts(data: IStockReceiptDetailsPayload): any {
        return this.http.put(`/shipments/update-stock-receipts`, data);
    }

    getAvailableSkidCount(orderId: number, takeFromOrderId: number): Observable<number> {
        return this.http.get<number>(`/shipments/order/${orderId}/available/${takeFromOrderId}`);
    }

    updateShipmentStopAddress(shipmentId: number, stopNumber: number, cusAddressId: number): Observable<Object> {
        return this.http.put(`/shipments/shipment-stops/${shipmentId}/${stopNumber}`, cusAddressId);
    }

    updateShipmentStopTrailerReceipts(shipmentId: number, trailerId: number): Observable<void> {
        return this.http.put<void>(`/shipments/shipment-stops/trailer-update/${shipmentId}`, trailerId);
    }

    updateReceiptShipmentDetails(data: ITrailerInfoPayload): any {
        return this.http.put(`/shipments/trailer-update`, data);
    }

    shipmentExists(id: number): Observable<boolean> {
        return this.http.get<boolean>(`/shipments/${id}/exists`);
    }

    getShipmentReports(shipmentDate: string, reports: number[]): any {
        let params = new HttpParams();
        params = params.append('date', shipmentDate);
        for (let report of reports) {
            params = params.append('reports', report.toString());
        }
        return this.http.get('/shipments/reports/pdf', { params: params });
    }

    getShipmentDate(shipment: IShipment, salesOrderId: number): any {
        if (shipment.Receipts.length > 0) {
            return shipment.Receipts.find((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === salesOrderId))?.DatePrinted;
        } else {
            return shipment.ShipmentDate;
        }
    }

    generateSkidsTakenForm(takeFromSalesOrderId: number): Observable<any> {
        return this.http.get<any>(`/shipments/${takeFromSalesOrderId}/print-skids-taken`);
    }

    printProducedAndTakenSkids(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/shipments/${salesOrderId}/print-produced-and-taken-skids`);
    }

    getSkidsShipped(salesOrderId: number): Observable<number> {
        return this.http.get<number>(`/shipments/order/${salesOrderId}/shipped`);
    }
}
