import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ISkid } from '../../model/interfaces/skid';
import { PrintService } from '../../common/services/print.service';
import { SkidService } from '../skid.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'print-tag',
    templateUrl: './print-tag.component.html',
})
export class PrintTagComponent {
    skid: ISkid = null;
    finalBarcode: string;

    @ViewChild('finalBarcodeInput', { read: ElementRef }) finalBarcodeInput: ElementRef;

    constructor(private notificationsService: NotificationsService, private skidService: SkidService) {}

    ngAfterViewInit(): void {
        this.finalBarcodeInput.nativeElement.focus();
    }

    formSubmitted(): void {
        if (this.finalBarcode) {
            this.skidService.getByFinalBarcode(this.finalBarcode).subscribe((answer) => {
                if (answer) {
                    this.skid = answer;
                    this.notificationsService.success('Printing skid ' + this.finalBarcode);
                    this.printFinalTag();
                } else {
                    this.skid = null;
                    this.notificationsService.warning('Skid not found');
                    this.finalBarcodeInput.nativeElement.focus();
                }
            });
        }
    }

    printFinalTag(): void {
        this.skidService
            .getFinalTag(this.skid.Id)
            .pipe(
                finalize(() => {
                    this.skid = null;
                }),
            )
            .subscribe((pdf) => {
                PrintService.printPdf(pdf);
            });
    }
}
