<div *ngIf="formCreated">
    <form class="miles-form padded row" [formGroup]="customerShippingAddressAddForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-xs-12">
                <h4>Add New Shipping Address</h4>
                <dynamic-field [field]="abstractCustomerShippingAddressControls.ShipToName" [form]="customerShippingAddressAddForm"></dynamic-field>
                <dynamic-field [field]="abstractCustomerShippingAddressControls.Address" [form]="customerShippingAddressAddForm"></dynamic-field>
            </div>
        </div>
        <div class="row col-xs-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                Cancel
            </button>
        </div>
    </form>
</div>
