import { Injectable } from '@angular/core';
import Fraction from 'fraction.js';

interface IFractionMappings {
    Name: string,
    ConversionFactor: number,
}

export interface IFractionsDynamicFieldOptions {
    maxFactor: number;
}

@Injectable()
export class FractionsService {
    fractionMappings: IFractionMappings[] = [
        {
            ConversionFactor: 2,
            Name: '1/2',
        },
        {
            ConversionFactor: 4,
            Name: '1/4',
        },
        {
            ConversionFactor: 8,
            Name: '1/8',
        },
        {
            ConversionFactor: 16,
            Name: '1/16',
        },
        {
            ConversionFactor: 32,
            Name: '1/32',
        },
        {
            ConversionFactor: 64,
            Name: '1/64',
        },
    ];

    FormatAsFraction(num: string): string {
        if (this.isNullOrEmptyOrInfinity(num)) {
            return num;
        }
        return new Fraction(num).toFraction(true);
    }

    FormatAsDecimal(fraction: string): string {
        if (this.isNullOrEmptyOrInfinity(fraction)) {
            return fraction;
        }
        return new Fraction(fraction).toString();
    }

    /**
     * Rounds a decimal value to the closest incremental value by using the
     * smallest difference between the actual value and the rounded value and using that as the conversion factor
     * @param {number} num the value to round
     * @param {number} maxFactor the highest conversion factor allowed (i.e. if the max factor is 4, the number will
     * be rounded to closest 1/2, 1/4 but not further)
     * @param {number} conversionFactor an explicit number to use as the conversion factor
     * @returns the closest incrementally rounded value (1/2, 1/4, 1/8, etc...)
     */
    roundToNearestFraction(num: number, maxFactor = null, conversionFactor = null): number {
        if (this.isNullOrEmptyOrInfinity(num)) {
            return num;
        }
        return conversionFactor
            ? this.convertToFraction(num, conversionFactor)
            : this.convertToFraction(num, this.getConversionFactor(num, maxFactor));
    }

    private getConversionFactor(num: number, maxFactor: any): number {
        let mappings: IFractionMappings[] = [];
        mappings = maxFactor ? [...this.fractionMappings.filter((fm) => fm.ConversionFactor <= maxFactor)] : [...this.fractionMappings];
        let results = mappings.map((mapping) => {
            return {
                ConversionFactor: mapping.ConversionFactor,
                Result: Math.abs(num - this.convertToFraction(num, mapping.ConversionFactor)),
            };
        });
        return results.sort((a, b) => a.Result - b.Result)[0].ConversionFactor;
    }

    private convertToFraction(num: number, conversionFactor: number): number {
        return Math.ceil(num * conversionFactor) / conversionFactor;
    }

    zeroPad(n: number, buffer: number): string {
        let num = n + '';
        return num.length >= buffer ? num : new Array(buffer - num.length + 1).join('0') + num;
    }

    private isNullOrEmptyOrInfinity(val: any): boolean {
        return val === undefined || val === null || Number.isNaN(val) || val === Infinity || val === '';
    }

    isValidFraction(input: string): boolean {
        try {
            return !this.isNullOrEmptyOrInfinity(this.FormatAsDecimal(input));
        } catch (error) {
            return false;
        }
    }
}
