import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { ReceiptDynamicControls, IReceiptDynamicControlsParameters } from '../form-controls/receipt.form-controls';
import { IReceipt } from '../interfaces/receipt';
import { OverrideNumericFunctionsEmptyWhenZero } from '@common/common-functions.form-controls';

export class ReceiptDynamicControlsPartial extends ReceiptDynamicControls {
    constructor(receiptPartial?: IReceipt, additionalParameters?: IReceiptDynamicControlsParameters) {
        super(receiptPartial, additionalParameters);

        (<DynamicField>this.Form.HasTradeDiscountPercent).label = 'Trade Discount (%)';
        (<DynamicField>this.Form.HasTradeDiscountPercent).labelHtml = '<label>&nbsp;Trade Discount (%)</label>';

        (<DynamicField>this.Form.HasTruckingAllowancePerTon).label = 'Trucking Allowance (Per Ton)';
        (<DynamicField>this.Form.HasTruckingAllowancePerTon).labelHtml = '<label>&nbsp;Trucking Allowance (Per Ton)</label>';
        (<DynamicField>this.Form.TruckingAllowancePerTon).type.scale = 2;

        (<DynamicField>this.Form.HasEnergySurchargePerTon).label = 'Energy Surcharge (Per Ton)';
        (<DynamicField>this.Form.HasEnergySurchargePerTon).labelHtml = '<label>&nbsp;Energy Surcharge (Per Ton)</label>';
        (<DynamicField>this.Form.EnergySurchargePerTon).type.scale = 2;

        (<DynamicField>this.Form.HasAdditionalFeesDeductions1).label = 'Additional Fees/Deductions';
        (<DynamicField>this.Form.HasAdditionalFeesDeductions1).labelHtml = '<label>&nbsp;Additional Fees/Deductions</label>';
        (<DynamicField>this.Form.AdditionalFeesDeductions1).type.scale = 2;

        (<DynamicField>this.Form.AdditionalFeesDeductionsName1).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.AdditionalFeesDeductionsName1).placeholder = 'Fee/Deduction Label';

        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode1).label = 'Code';
        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode1).labelHtml = '<label>Code:</label>';
        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode1).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.HasAdditionalFeesDeductions2).label = 'Additional Fees/Deductions';
        (<DynamicField>this.Form.HasAdditionalFeesDeductions2).labelHtml = '<label>&nbsp;Additional Fees/Deductions</label>';
        (<DynamicField>this.Form.AdditionalFeesDeductions2).type.scale = 2;

        (<DynamicField>this.Form.AdditionalFeesDeductionsName2).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.AdditionalFeesDeductionsName2).placeholder = 'Fee/Deduction Label';

        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode2).label = 'Code';
        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode2).labelHtml = '<label>Code:</label>';
        (<DynamicField>this.Form.AdditionalFeesDeductionsGlCode2).type.inputType = InputTypes.Textbox;

        OverrideNumericFunctionsEmptyWhenZero([
            this.Form.TradeDiscountPercent,
            this.Form.TruckingAllowancePerTon,
            this.Form.EnergySurchargePerTon,
            this.Form.AdditionalFeesDeductions1,
            this.Form.AdditionalFeesDeductions2,
        ]);
    }
}
