import { Component, OnInit } from '@angular/core';
import { ShipmentService } from '../shipment.service';
import { PrintService } from '@common/services/print.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { LoadReportTypes } from '@model/enums/load-report-types.enum';

@Component({
    selector: 'shipment-schedule',
    templateUrl: './shipment-schedule.component.html',
})
export class ShipmentScheduleComponent implements OnInit {
    dateInputField: IDynamicField;
    dateInputValue: Date;

    get inputDate(): string {
        return this.dateInputValue.toISOString().split('T')[0];
    }

    constructor(private shipmentService: ShipmentService) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.dateInputField = new DynamicField({
            formGroup: 'ShipmentSchedule',
            hideLabel: true,
            label: 'Date',
            name: 'Date',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: new Date(),
        } as IDynamicField);
    }

    printSummary(): void {
        this.shipmentService.getShipmentReports(this.inputDate, [LoadReportTypes.LoadList]).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    printDetail(): void {
        this.shipmentService.getShipmentReports(this.inputDate, [LoadReportTypes.LoadSchedule]).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }

    printLoadsForDate(): void {
        this.shipmentService.getShipmentReports(this.inputDate, [LoadReportTypes.LoadsForDate]).subscribe((answer) => {
            PrintService.printPdf(answer);
        });
    }
}
