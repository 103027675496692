import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, ClaimsService, ILoggedIn } from '@mt-ng2/auth-module';
import { MillComplaintDynamicControlsPartial } from '@model/partials/mill-complaint.form-controls';
import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { PrintService } from '@common/services/print.service';

import { QuantityAffectedTypeService } from '../services/quantity-affected-type.service';
import { MillComplaintService } from '../services/mill-complaint.service';
import { MillComplaintCategoryService } from '../../sales-orders/mill-complaint-category.service';
import { IMillComplaintCategory } from '@model/interfaces/mill-complaint-category';
import { PrintFormsOptions } from '@model/dto/PrintFormsOptions';

@Component({
    selector: 'mill-complaint-basic-info',
    styles: [
        `
            .board-status-header {
                display: inline-block;
                margin-bottom: 10px;
            }
            .credit-handling-header {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 700;
            }
            .credit-handling-body {
                border: 1px solid #00000033;
                padding: 20px;
                margin: 10px;
            }
            .credit-issued-input input {
                width: 50%;
            }
        `,
    ],
    templateUrl: './mill-complaint-basic-info.component.html',
})
export class MillComplaintBasicInfoComponent implements OnInit {
    @Input('millComplaint') millComplaint: IMillComplaint;
    @Input('canEdit') canEdit: IMillComplaint;

    millComplaintForm: FormGroup;
    abstractMillComplaintControls: any;

    currentUser: ILoggedIn;
    doubleClickIsDisabled = false;

    millComplaintCategories: IMillComplaintCategory[];

    constructor(
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private quantityAffectedTypeService: QuantityAffectedTypeService,
        private authService: AuthService,
        private millComplaintService: MillComplaintService,
        private router: Router,
        private route: ActivatedRoute,
        private millComplaintCategoryService: MillComplaintCategoryService,
    ) {}

    ngOnInit(): void {
        this.currentUser = this.authService.currentUser.getValue();

        forkJoin([
            this.quantityAffectedTypeService.getItems(),
            this.millComplaintCategoryService.getItems(),
        ]).subscribe(([, millComplaintCategories]) => {
            this.millComplaintCategories = millComplaintCategories;
            this.createForm();
            if (!this.canEdit) {
                setTimeout(() => this.millComplaintForm.disable());
            }
        });
    }

    createForm(): void {
        this.getControls();
        this.millComplaintForm = this.fb.group({
            MillComplaint: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractMillComplaintControls = new MillComplaintDynamicControlsPartial(this.millComplaint, {
            formGroup: 'MillComplaint',
            millComplaintCategories: this.millComplaintCategories,
            quantityAffecteds: this.quantityAffectedTypeService.items,
            salesOrders: null,
        }).Form;
    }

    formSubmitted(): void {
        if (this.millComplaintForm.valid) {
            Object.assign(this.millComplaint, this.millComplaintForm.value.MillComplaint);
            if (!this.millComplaint.Id || this.millComplaint.Id === 0) {
                this.millComplaint.ComplaintTakenBy = this.currentUser.Id;
                this.millComplaintService
                    .create(this.millComplaint)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((newId) => {
                        this.success();
                        void this.router.navigate(['..', newId], { relativeTo: this.route.parent });
                    });
            } else {
                this.millComplaintService
                    .update(this.millComplaint)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(() => {
                        this.success();
                        this.millComplaintService.emitChange(this.millComplaint);
                        this.millComplaintForm.markAsPristine();
                    });
            }
        } else {
            common.markAllFormFieldsAsTouched(this.millComplaintForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    checkBoardKept(boardKept: boolean): void {
        if (boardKept) {
            (<FormGroup>this.millComplaintForm.controls.MillComplaint).controls.IsBoardReturned.setValue(false);
        }
    }

    checkBoardReturned(boardReturned: boolean): void {
        if (boardReturned) {
            (<FormGroup>this.millComplaintForm.controls.MillComplaint).controls.IsBoardKept.setValue(false);
        }
    }

    printComplaint(): void {
        if (!this.millComplaintForm.dirty) {
            this.millComplaintService
                .printComplaint(this.millComplaint.Id, this.getPrintOptions())
                .subscribe((answer) => PrintService.printPdf(answer));
        } else {
            this.notificationsService.error('Please save before printing.');
        }
    }

    private getPrintOptions(): PrintFormsOptions {
        let printOptions = new PrintFormsOptions();
        printOptions.IssueCreditMemo = this.millComplaint && this.millComplaint.Id
                                        ? this.millComplaint.IssueCreditMemo
                                        : false;
        return printOptions;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        void this.router.navigate(['..'], { relativeTo: this.route.parent });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Mill Complaint saved successfully.');
    }
}
