<div>
    <h2>Billing Sheet Adjustments</h2>
</div>
<div>
    <div class="row">
        <div class="col-md-6">
            <div class="miles-form padded">
                <h4>Billing Sheet Adjustments</h4>
                <div>
                    <label>
                        Receipt #:
                        <input type="text" [(ngModel)]="receiptNumber" #receiptNumberInput="ngModel" />
                    </label>
                    <span *ngIf="receipt" class="pull-right"><label>Net Weight:</label> {{netWeight}}</span>
                </div>
                <div *ngIf="receipt">
                    <fieldset>
                        <table class="customer-info">
                            <tr>
                                <td><label>Sold To:</label></td>
                                <td>{{customer.CustomerName}}</td>
                            </tr>
                            <tr>
                                <td><label>Ship To:</label></td>
                                <td>
                                    <span>{{shippingAddress.ShipToName}}</span><br />
                                    <span>{{shippingAddress.Address.Address1}}</span><br />
                                    <div *ngIf="shippingAddress.Address.Address2">
                                        <span>{{shippingAddress.Address.Address2}}</span><br />
                                    </div>
                                    <span>{{shippingAddress.Address.City}}, {{shippingAddress.Address.StateCode}}
                                        {{shippingAddress.Address.Zip}}</span>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                    <div class="m-t-xs">
                        <entity-list
                            [entities]="receiptDetails"
                            [entityListConfig]="receiptDetailsEntityListConfig"
                        ></entity-list>
                    </div>
                    <div *ngIf="form" class="row">
                        <div class="col-md-9">
                            <div class="row row-col-align-middle m-b-xs">
                                <dynamic-field class="col-md-5" [field]="formControls.HasTradeDiscountPercent"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-3" [field]="formControls.TradeDiscountPercent"
                                    [form]="form"></dynamic-field>
                                <span class="col-md-4 text-center">{{tradeDiscount | currency}}</span>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <dynamic-field class="col-md-5" [field]="formControls.HasTruckingAllowancePerTon"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-3" [field]="formControls.TruckingAllowancePerTon"
                                    [form]="form"></dynamic-field>
                                <span class="col-md-4 text-center">{{truckingAllowance| currency}}</span>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <dynamic-field class="col-md-5" [field]="formControls.HasEnergySurchargePerTon"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-3" [field]="formControls.EnergySurchargePerTon"
                                    [form]="form"></dynamic-field>
                                <span class="col-md-4 text-center">{{energySurcharge| currency}}</span>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <dynamic-field class="col-md-5" [field]="formControls.HasAdditionalFeesDeductions1"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-3" [field]="formControls.AdditionalFeesDeductions1"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-4" [field]="formControls.AdditionalFeesDeductionsName1"
                                    [form]="form"></dynamic-field>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <span class="col-md-5 text-right"
                                    [innerHTML]="formControls.AdditionalFeesDeductionsGlCode1.labelHtml"></span>
                                <dynamic-field class="col-md-3" [field]="formControls.AdditionalFeesDeductionsGlCode1"
                                    [form]="form"></dynamic-field>
                                <span class="col-md-4"> {{ tonsAndPricingName1 }} </span>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <dynamic-field class="col-md-5" [field]="formControls.HasAdditionalFeesDeductions2"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-3" [field]="formControls.AdditionalFeesDeductions2"
                                    [form]="form"></dynamic-field>
                                <dynamic-field class="col-md-4" [field]="formControls.AdditionalFeesDeductionsName2"
                                    [form]="form"></dynamic-field>
                            </div>
                            <div class="row row-col-align-middle m-b-xs">
                                <span class="col-md-5 text-right"
                                    [innerHTML]="formControls.AdditionalFeesDeductionsGlCode2.labelHtml"></span>
                                <dynamic-field class="col-md-3" [field]="formControls.AdditionalFeesDeductionsGlCode2"
                                    [form]="form"></dynamic-field>
                                <span class="col-md-4"> {{ tonsAndPricingName2 }} </span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <table class="table text-center">
                                <tr>
                                    <td class="bordered p-xs">
                                        <p><strong>Gross Total Invoice</strong></p>
                                        <span class="highlighted">{{ grossTotalInvoice | currency: 'USD': 'symbol': '1.0-4'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bordered p-xs">
                                        <p><strong>Net Total Invoice</strong></p>
                                        <span class="highlighted">{{ netTotalInvoice | currency: 'USD': 'symbol': '1.0-4'}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="m-t-sm">
                    <div class="row justify-content-around">
                        <button *ngIf="receipt" type="button" class="col-md-2 btn btn-success btn-lg" (click)="print()"
                            mt-doubleClickDisabled [(doubleClickIsDisabled)]="isPrinting">
                            {{ isPrinting ? 'Printing...' : 'Print' }}
                        </button>
                        <button type="button" class="col-md-2 btn btn-default btn-lg" (click)="exit()">Exit</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="receipt" class="col-md-6">
            <receipt-details [isBillingSheetAdjustment]="true" [receiptId]="receipt.Id" (onReceiptUpdated)="loadReceipt(receiptNumber, true)"></receipt-details>
        </div>
    </div>
</div>