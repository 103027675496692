import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IBoardGrade } from '../interfaces/board-grade';

export interface IBoardGradeDynamicControlsParameters {
    formGroup?: string;
}

export class BoardGradeDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private boardgrade?: IBoardGrade, additionalParameters?: IBoardGradeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BoardGrade';

        this.Form = {
            Abbreviation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Abbreviation',
                name: 'Abbreviation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.boardgrade && this.boardgrade.hasOwnProperty('Abbreviation') && this.boardgrade.Abbreviation !== null ? this.boardgrade.Abbreviation.toString() : '',
            }),
            GlCodeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gl Code',
                name: 'GlCodeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.boardgrade && this.boardgrade.GlCodeId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.boardgrade && this.boardgrade.hasOwnProperty('Name') && this.boardgrade.Name !== null ? this.boardgrade.Name.toString() : '',
            }),
            QbInventoryName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qb Inventory Name',
                name: 'QbInventoryName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.boardgrade && this.boardgrade.hasOwnProperty('QbInventoryName') && this.boardgrade.QbInventoryName !== null ? this.boardgrade.QbInventoryName.toString() : '',
            }),
            TrimName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trim Name',
                name: 'TrimName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.boardgrade && this.boardgrade.hasOwnProperty('TrimName') && this.boardgrade.TrimName !== null ? this.boardgrade.TrimName.toString() : '',
            }),
        };

        this.View = {
            Abbreviation: new DynamicLabel(
                'Abbreviation',
                this.boardgrade && this.boardgrade.hasOwnProperty('Abbreviation') && this.boardgrade.Abbreviation !== null ? this.boardgrade.Abbreviation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GlCodeId: new DynamicLabel(
                'Gl Code',
                this.boardgrade && this.boardgrade.GlCodeId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.boardgrade && this.boardgrade.hasOwnProperty('Name') && this.boardgrade.Name !== null ? this.boardgrade.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            QbInventoryName: new DynamicLabel(
                'Qb Inventory Name',
                this.boardgrade && this.boardgrade.hasOwnProperty('QbInventoryName') && this.boardgrade.QbInventoryName !== null ? this.boardgrade.QbInventoryName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TrimName: new DynamicLabel(
                'Trim Name',
                this.boardgrade && this.boardgrade.hasOwnProperty('TrimName') && this.boardgrade.TrimName !== null ? this.boardgrade.TrimName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
