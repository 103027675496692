export class PrintFormsOptions {
    AcknowledgementCopy: boolean;
    OfficeCopy: boolean;
    PapermakersCopy: boolean;
    ShippersCopy: boolean;
    PastingCuttingCopy: boolean;
    ProductionLogSheet: boolean;
    LiningCopy: boolean;
    StockPickingTicket: boolean;
    StockShippersCopy: boolean;
    StockFileCopy: boolean;
    CreditDeptCopy: boolean;
    StockOfficeCopy: boolean;
    StockAcknowledgementCopy: boolean;
    StockItemList: boolean;
    IsPrintAndLock: boolean;
    IsOnCreditHold: boolean;
    IssueCreditMemo: boolean; // for mill complaint forms
}

export class PrintStockFormsOptions {
    Receipt: boolean;
    Invoice: boolean;
    InvoiceIsAvailable: boolean;
}
