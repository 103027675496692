import { DynamicField, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { ITrimSheetDynamicControlsParameters } from '../form-controls/trim-sheet.form-controls';
import { ITrimSheet } from '../interfaces/trim-sheet';
import { TrimSheetDynamicControlsPartial } from './trim-sheet-partial.form-controls';

export class TrimViewDynamicControlsPartial extends TrimSheetDynamicControlsPartial {
    constructor(trimsheetPartial?: ITrimSheet, additionalParameters?: ITrimSheetDynamicControlsParameters) {
        super(trimsheetPartial, additionalParameters);

        (<DynamicField>this.Form.SetupId).disabled = false;
        (<DynamicField>this.Form.WeekOf).disabled = true;
    }
}
