import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ITrimSheetDetail } from '../interfaces/trim-sheet-detail';
import { IBoardGrade } from '../interfaces/board-grade';
import { IOrderDetailLine } from '../interfaces/order-detail-line';
import { ISalesOrder } from '../interfaces/sales-order';
import { IShipOnType } from '../interfaces/ship-on-type';
import { IStockItemType } from '../interfaces/stock-item-type';
import { ITrimSheet } from '../interfaces/trim-sheet';

export interface ITrimSheetDetailDynamicControlsParameters {
    formGroup?: string;
    trimSheets?: ITrimSheet[];
    salesOrders?: ISalesOrder[];
    orderDetailLines?: IOrderDetailLine[];
    shipOnTypes?: IShipOnType[];
    stockItemTypes?: IStockItemType[];
    boardGrades?: IBoardGrade[];
}

export class TrimSheetDetailDynamicControls {

    formGroup: string;
    trimSheets?: ITrimSheet[];
    salesOrders?: ISalesOrder[];
    orderDetailLines?: IOrderDetailLine[];
    shipOnTypes?: IShipOnType[];
    stockItemTypes?: IStockItemType[];
    boardGrades?: IBoardGrade[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private trimsheetdetail?: ITrimSheetDetail, additionalParameters?: ITrimSheetDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TrimSheetDetail';
        this.trimSheets = additionalParameters && additionalParameters.trimSheets || undefined;
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.orderDetailLines = additionalParameters && additionalParameters.orderDetailLines || undefined;
        this.shipOnTypes = additionalParameters && additionalParameters.shipOnTypes || undefined;
        this.stockItemTypes = additionalParameters && additionalParameters.stockItemTypes || undefined;
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;

        this.Form = {
            Basis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Basis',
                name: 'Basis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.Basis || null,
            }),
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.BoardGradeId || null,
            }),
            Bundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bundles',
                name: 'Bundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.trimsheetdetail && this.trimsheetdetail.Bundles || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.Caliper || 0,
            }),
            Count: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count',
                name: 'Count',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.Count || null,
            }),
            IsCutter: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Cutter',
                name: 'IsCutter',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsCutter') && this.trimsheetdetail.IsCutter != null ? this.trimsheetdetail.IsCutter : false,
            }),
            IsPaster: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Paster',
                name: 'IsPaster',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsPaster') && this.trimsheetdetail.IsPaster != null ? this.trimsheetdetail.IsPaster : false,
            }),
            IsPileUp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Pile Up',
                name: 'IsPileUp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsPileUp') && this.trimsheetdetail.IsPileUp != null ? this.trimsheetdetail.IsPileUp : false,
            }),
            IsTiedBundles: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Tied Bundles',
                name: 'IsTiedBundles',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsTiedBundles') && this.trimsheetdetail.IsTiedBundles != null ? this.trimsheetdetail.IsTiedBundles : false,
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Length') && this.trimsheetdetail.Length != null ? this.trimsheetdetail.Length.toString() : '',
            }),
            Note: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note',
                name: 'Note',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Note') && this.trimsheetdetail.Note != null ? this.trimsheetdetail.Note.toString() : '',
            }),
            NumberOfSkids: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Skids',
                name: 'NumberOfSkids',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.NumberOfSkids || null,
            }),
            OrderDetailLineId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Detail Line',
                name: 'OrderDetailLineId',
                options: this.orderDetailLines,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.OrderDetailLineId || null,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.SalesOrderId || null,
            }),
            Sheets: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sheets',
                name: 'Sheets',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.trimsheetdetail && this.trimsheetdetail.Sheets || null,
            }),
            ShipOnTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship On Type',
                name: 'ShipOnTypeId',
                options: this.shipOnTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.ShipOnTypeId || null,
            }),
            StainColor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stain Color',
                name: 'StainColor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('StainColor') && this.trimsheetdetail.StainColor != null ? this.trimsheetdetail.StainColor.toString() : '',
            }),
            StockItemTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Stock Item Type',
                name: 'StockItemTypeId',
                options: this.stockItemTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.trimsheetdetail && this.trimsheetdetail.StockItemTypeId || null,
            }),
            TrimSheetId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trim Sheet',
                name: 'TrimSheetId',
                options: this.trimSheets,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.trimsheetdetail && this.trimsheetdetail.TrimSheetId || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Width') && this.trimsheetdetail.Width != null ? this.trimsheetdetail.Width.toString() : '',
            }),
        };

        this.View = {
            Basis: new DynamicLabel({
                label: 'Basis',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.Basis) || null,
            }),
            BoardGradeId: new DynamicLabel({
                label: 'Board Grade',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.boardGrades as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('BoardGradeId') ? this.trimsheetdetail.BoardGradeId : null,
                    ) ?? '',
            }),
            Bundles: new DynamicLabel({
                label: 'Bundles',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.Bundles) || null,
            }),
            Caliper: new DynamicLabel({
                label: 'Caliper',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.Caliper) || 0,
            }),
            Count: new DynamicLabel({
                label: 'Count',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.Count) || null,
            }),
            IsCutter: new DynamicLabel({
                label: 'Is Cutter',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsCutter') && this.trimsheetdetail.IsCutter != null
                        ? this.trimsheetdetail.IsCutter
                        : false,
            }),
            IsPaster: new DynamicLabel({
                label: 'Is Paster',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsPaster') && this.trimsheetdetail.IsPaster != null
                        ? this.trimsheetdetail.IsPaster
                        : false,
            }),
            IsPileUp: new DynamicLabel({
                label: 'Is Pile Up',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsPileUp') && this.trimsheetdetail.IsPileUp != null
                        ? this.trimsheetdetail.IsPileUp
                        : false,
            }),
            IsTiedBundles: new DynamicLabel({
                label: 'Is Tied Bundles',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('IsTiedBundles') && this.trimsheetdetail.IsTiedBundles != null
                        ? this.trimsheetdetail.IsTiedBundles
                        : false,
            }),
            Length: new DynamicLabel({
                label: 'Length',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Length') && this.trimsheetdetail.Length != null
                        ? this.trimsheetdetail.Length.toString()
                        : '',
            }),
            Note: new DynamicLabel({
                label: 'Note',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Note') && this.trimsheetdetail.Note != null
                        ? this.trimsheetdetail.Note.toString()
                        : '',
            }),
            NumberOfSkids: new DynamicLabel({
                label: 'Number Of Skids',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.NumberOfSkids) || null,
            }),
            OrderDetailLineId: new DynamicLabel({
                label: 'Order Detail Line',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.orderDetailLines as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('OrderDetailLineId')
                            ? this.trimsheetdetail.OrderDetailLineId
                            : null,
                    ) ?? '',
            }),
            SalesOrderId: new DynamicLabel({
                label: 'Sales Order',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.salesOrders as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('SalesOrderId') ? this.trimsheetdetail.SalesOrderId : null,
                    ) ?? '',
            }),
            Sheets: new DynamicLabel({
                label: 'Sheets',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: (this.trimsheetdetail && this.trimsheetdetail.Sheets) || null,
            }),
            ShipOnTypeId: new DynamicLabel({
                label: 'Ship On Type',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.shipOnTypes as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('ShipOnTypeId') ? this.trimsheetdetail.ShipOnTypeId : null,
                    ) ?? '',
            }),
            StainColor: new DynamicLabel({
                label: 'Stain Color',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('StainColor') && this.trimsheetdetail.StainColor != null
                        ? this.trimsheetdetail.StainColor.toString()
                        : '',
            }),
            StockItemTypeId: new DynamicLabel({
                label: 'Stock Item Type',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.stockItemTypes as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('StockItemTypeId') ? this.trimsheetdetail.StockItemTypeId : null,
                    ) ?? '',
            }),
            TrimSheetId: new DynamicLabel({
                label: 'Trim Sheet',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value:
                    getMetaItemValue(
                        this.trimSheets as unknown as IMetaItem[],
                        this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('TrimSheetId') ? this.trimsheetdetail.TrimSheetId : null,
                    ) ?? '',
            }),
            Width: new DynamicLabel({
                label: 'Width',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value:
                    this.trimsheetdetail && this.trimsheetdetail.hasOwnProperty('Width') && this.trimsheetdetail.Width != null
                        ? this.trimsheetdetail.Width.toString()
                        : '',
            }),
        };

    }
}
