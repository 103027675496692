import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IShipment } from '@model/interfaces/shipment';
import { ShipmentService, ReceiptSizeTotal, IShipmentReceiptOptions, IStopOptions, IReceiptOptions } from '../shipment.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { ISkid } from '@model/interfaces/skid';
import { PrintService } from '@common/services/print.service';
import { CustomerShipOnTypeService } from '../../customers/customershipontype.service';
import { forkJoin, of } from 'rxjs';
import { CustomerService } from '../../customers/customer.service';
import { finalize } from 'rxjs/operators';
import { ShippingStatuses } from '@model/ShippingStatuses';
import { IShipOnType } from '@model/interfaces/ship-on-type';
import { IFormattedSalesOrder } from '@model/interfaces/custom/formatted-sales-order';
import { IReceiptDetail } from '@model/interfaces/receipt-detail';
import { ReceiptService } from '../../receipts/receipt.service';
import { HaulerService } from '../hauler.service';
import { IHauler } from '@model/interfaces/hauler';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { OrderCustomerSpecsService } from '../../sales-orders/order-customer-specs.service';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { ShipmentReceiptDetailsComponent } from './shipment-receipt-details/shipment-receipt-details.component';
import { FormGroup } from '@angular/forms';
import { IExpandableObject } from '@model/expandable-object';
import { ReceiptDetailDynamicControlsPartial } from '@model/partials/receipt-detail-partial.form-controls';
import { IShipmentStop } from '@model/interfaces/shipment-stop';
import { ShippingAddressPipe } from '@common/pipes/shipping-address.pipe';
import { ShipmentStopDynamicControlsPartial } from '@model/partials/shipment-stop-partial.form-controls';
import { StockReceiptDetailsComponent } from './stock-receipt-details/stock-receipt-details.component';
import { OrderTypeIds } from '@model/OrderTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

// TODO: Switch to IMetaItem
interface ISimpleEntity {
    Id: number;
    Name: string;
}

@Component({
    selector: 'shipment-receipt',
    styleUrls: ['./shipment-receipt.component.less'],
    templateUrl: './shipment-receipt.component.html',
})
export class ShipmentReceiptComponent implements OnInit {
    @ViewChild(ShipmentReceiptDetailsComponent) shipmentReceiptDetailsComp: ShipmentReceiptDetailsComponent;
    @ViewChild(StockReceiptDetailsComponent) stockReceiptDetailsComp: StockReceiptDetailsComponent;
    @ViewChild('shipmentIdInput', { read: ElementRef }) shipmentIdInput: ElementRef;

    private shippingAddressPipe = new ShippingAddressPipe();

    doubleClickIsDisabled = false;
    isBillOfLading = false;
    toggleReceiptPartInfo = true;
    showShipmentReceiptsDetails = true;
    stockOrderSelected = false;
    canVoidInvoices = false;

    receiptDetail: IReceiptDetail;
    receiptShipmentStop: IShipmentStop;
    receiptDetailForm: FormGroup;
    receiptDetailFormControls: IExpandableObject;
    shipmentStopFormControls: IExpandableObject;

    customerShipOnTypes: IShipOnType[];
    shipmentReceiptSizeTotals: ISimpleEntity[];
    stockOrderReceiptDetails: IReceiptDetail[];

    shipmentReceiptOptions: IShipmentReceiptOptions;
    shipment: IShipment;
    selectedOrderId: number;
    selectedOrder: ISalesOrder;
    haulers: IHauler[];
    currentCustomerId: number;
    currentCustomerAddresses = [];
    currentSkids: ISkid[];
    receiptPartInfo: string;
    orderCustomerSpecsId: number;
    noSkids: number;
    currentLoadDate: string;
    newReceiptDetailsText = '';

    printModal: IModalWrapperApi;

    stockReceiptEntryModal: IModalWrapperApi;

    modalOptions: IModalOptions = {
        showCancelButton: false,
        showConfirmButton: false,
        width: 800,
    };

    stockModalOptions: IModalOptions = {
        showCancelButton: false,
        showConfirmButton: false,
        width: 1600,
    };

    customerShippingAddressId: number;

    constructor(
        private shipmentService: ShipmentService,
        private notificationsService: NotificationsService,
        private customerShipOnTypeService: CustomerShipOnTypeService,
        private orderCustomerSpecsService: OrderCustomerSpecsService,
        private customerService: CustomerService,
        private haulerService: HaulerService,
        private receiptService: ReceiptService,
        private modalService: ModalService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.currentLoadDate = this.getLoadDate();
        this.shipmentReceiptOptions = this.shipmentService.getEmptyShipmentReceiptOptions();
        this.shipmentReceiptOptions.StopOptions = [];
        this.canVoidInvoices = this.claimsService.hasClaim(ClaimTypes.Receipts_CanProcessInvoice, [ClaimValues.FullAccess]);

        forkJoin([this.customerShipOnTypeService.getItems(), this.haulerService.getItems(), of(this.getShipmentReceiptSizeTotals())]).subscribe(
            ([customerShipOnTypes, haulers, receiptSizeTotals]) => {
                this.customerShipOnTypes = customerShipOnTypes;
                this.haulers = haulers;
                this.shipmentReceiptSizeTotals = receiptSizeTotals;

                this.receiptDetailForm = new FormGroup({ ReceiptDetail: new FormGroup({}), ShipmentStop: new FormGroup({}) });
                this.receiptDetailFormControls = new ReceiptDetailDynamicControlsPartial(this.receiptDetail, {
                    receiptSizeTotals: this.shipmentReceiptSizeTotals,
                    shipOnTypes: this.customerShipOnTypes,
                }).Form;
                this.shipmentStopFormControls = new ShipmentStopDynamicControlsPartial().Form;
            },
        );
    }

    clearForm(): void {
        this.shipment = null;
        this.selectedOrder = null;
        this.noSkids = null;
        this.receiptPartInfo = '';
        this.shipmentReceiptOptions = this.shipmentService.getEmptyShipmentReceiptOptions();
        this.shipmentReceiptOptions.StopOptions = [];
        this.currentCustomerAddresses = [];
        this.receiptDetail = null;
        this.receiptDetailForm.reset();
        this.customerShippingAddressId = null;
        this.stockOrderSelected = false;
        setTimeout(() => {
            this.shipmentIdInput.nativeElement.focus();
        });
    }

    loadShipment(shipmentId: number, selectOrder?: number): void {
        if (!shipmentId) {
            return;
        }

        this.shipmentService.getById(shipmentId).subscribe((answer) => {
            if (!answer) {
                this.notificationsService.error(`Load #${shipmentId} not found.`);
                this.shipmentIdInput.nativeElement.focus();
                return;
            }
            this.shipment = answer;

            if (this.shipment.StatusId !== ShippingStatuses.Closed) {
                this.notificationsService.error(`Load ${shipmentId} not closed`);
                this.clearForm();
                return;
            }

            // map the shipment details to the stops (reduces a include statements on the server side)
            this.shipment.ShipmentStops.forEach((stop) => {
                let details = [];
                stop.ShipmentDetails.forEach((stopDetail) => {
                    details.push(this.shipment.ShipmentDetails.find((detail) => stopDetail.Id === detail.Id));
                });
                stop.ShipmentDetails = details;
            });

            this.noSkids = this.shipment.Receipts.reduce((total, r) => total + r.ReceiptDetails.reduce((sum, rd) => sum + rd.NumberOfSkids, 0), 0);

            // default the printing options for each stop
            this.shipmentReceiptOptions.StopOptions = [];
            this.shipment.ShipmentStops.forEach((stop) => {
                const stopOption = {
                    PrintIndividualReceipts: false,
                    ReceiptOptions: [],
                    StopNumber: stop.Stop,
                };
                stop.Receipts.forEach((receipt) => {
                    stopOption.ReceiptOptions.push({
                        OmitNewmanHeaderOnCustomerCopy: false,
                        Print: true,
                        ReceiptId: receipt.Id,
                    });
                });
                this.shipmentReceiptOptions.StopOptions.push(stopOption);
            });

            let customerIds = this.shipment.ShipmentStops.map((item) => item.CustomerShippingAddress.CustomerId).filter(
                (item, index, self) => self.indexOf(item) === index,
            );

            forkJoin(customerIds.map((customerId) => this.customerService.getReceiptFormat(customerId))).subscribe((receiptFormats) => {
                this.shipment.ShipmentStops.forEach((shipmentStop) => {
                    let formatForThisStop = receiptFormats.find((rf) => rf && rf.CustomerId === shipmentStop.CustomerShippingAddress.CustomerId);
                    shipmentStop.Receipts.forEach((receipt) =>
                        this.omitNewmanHeaderChanged(shipmentStop.Stop, receipt.Id, formatForThisStop ? formatForThisStop.OmitNewmanHeader : false),
                    );
                    if (shipmentStop.Receipts.length > 1) {
                        this.printIndividualReceiptsChanged(shipmentStop.Stop, true);
                    }
                });
            });

            const orderToSelect = selectOrder ?? this.shipment.ShipmentDetails[0].SalesOrderId;
            this.selectedOrderId = orderToSelect;
            this.orderChanged(orderToSelect);

            this.currentLoadDate = this.getLoadDate();
        });
    }

    orderChanged(salesOrderId: number): void {
        const shipmentDetail = this.shipment.ShipmentDetails.find((item) => item.SalesOrderId === +salesOrderId);
        this.selectedOrder = shipmentDetail.SalesOrder;
        this.stockOrderSelected = shipmentDetail.SalesOrder.OrderTypeId === OrderTypeIds.Stock;

        this.receiptShipmentStop = this.shipment.ShipmentStops.find((stop) => stop.Stop === shipmentDetail.Stop);
        this.receiptDetailForm.controls.ShipmentStop.reset(this.receiptShipmentStop);
        this.customerShippingAddressId = this.receiptShipmentStop.CustomerShippingAddressId;

        const receipt = this.shipment.Receipts.find((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === +salesOrderId));

        this.receiptDetail = receipt.ReceiptDetails.find((rd) => rd.SalesOrderId === +salesOrderId);

        if (this.stockOrderSelected) {
            this.stockOrderReceiptDetails = receipt.ReceiptDetails.filter((rd) => rd.SalesOrderId === +salesOrderId);
        } else {
            this.stockOrderReceiptDetails = null;
        }

        this.receiptDetailForm.controls.ReceiptDetail.reset(this.receiptDetail);

        this.currentSkids = this.shipment.Skids.filter((s) => s.SalesOrderId === +salesOrderId);

        const orderCustomerSpecs = this.selectedOrder.OrderInfo.OrderCustomerSpecification;
        this.receiptPartInfo = orderCustomerSpecs?.ReceiptPartInfo ?? '';
        this.orderCustomerSpecsId = orderCustomerSpecs?.Id;

        this.getShippingAddresses();
    }

    haulerChanged(haulerId: number): void {
        let hauler = this.haulers.find((h) => h.Id === +haulerId);
        this.shipment.Hauler = hauler ?? null;
        this.shipment.HaulerId = haulerId;
    }

    isShipOnType(shipOnTypeId: number): boolean {
        return (
            this.selectedOrder &&
            this.selectedOrder.ManufacturingOrderDetail.ShipOnTypeId &&
            this.selectedOrder.ManufacturingOrderDetail.ShipOnTypeId === shipOnTypeId
        );
    }

    printReceiptChanged(stopNumber: number, receiptId: number, checked: boolean): void {
        let receipt = this.getReceiptOptionsForReceipt(stopNumber, receiptId);
        receipt.Print = checked;
    }

    omitNewmanHeaderChanged(stopNumber: number, receiptId: number, checked: boolean): void {
        let receipt = this.getReceiptOptionsForReceipt(stopNumber, receiptId);
        receipt.OmitNewmanHeaderOnCustomerCopy = checked;
    }

    printIndividualReceiptsChanged(stopNumber: number, checked: boolean): void {
        let option = this.getStopOptionsForStopNumber(stopNumber);
        option.PrintIndividualReceipts = checked;
    }

    getStopOptionsForStopNumber(stopNumber: number): IStopOptions {
        return this.shipmentReceiptOptions.StopOptions.find((opt) => opt.StopNumber === stopNumber);
    }

    getReceiptOptionsForReceipt(stopNumber: number, receiptId: number): IReceiptOptions {
        const option = this.getStopOptionsForStopNumber(stopNumber);
        return option.ReceiptOptions.find((opt) => opt.ReceiptId === receiptId);
    }

    getLoadDate(): string {
        if (!this.shipment || (!this.shipment.ShipmentDate && !this.shipment.ReceiptLoadDate)) {
            return '';
        }
        const dateToBeFormatted = this.shipment.ReceiptLoadDate ?? this.shipment.ShipmentDate;
        return (<string>(<any>dateToBeFormatted)).split('T')[0];
    }

    getTotalOrders(): number {
        if (!this.shipment) {
            return 0;
        }

        return this.shipment.ShipmentDetails.length;
    }

    getShippingAddresses(): void {
        if (!this.selectedOrder) {
            return;
        }

        const searchEntity: IEntitySearchParams = {
            extraParams: null,
            order: 'AddressId',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 999,
        };
        const searchParams = new SearchParams(searchEntity);

        this.customerService.getShippingAddresses(this.selectedOrder.OrderInfo.CustomerId, searchParams).subscribe((answer) => {
            this.currentCustomerAddresses = answer.body;
            this.shipmentStopFormControls.CustomerShippingAddressId.options = this.currentCustomerAddresses?.map((a) => ({
                Archived: a.Archived,
                Id: a.Id,
                Name: this.shippingAddressPipe.transform(a, 'archived'),
            }));
        });
    }

    getSkidsForStop(stopNumber: number): ISkid[] {
        if (!this.shipment) {
            return null;
        }

        let salesOrderIds = this.shipment.ShipmentStops.find((stop) => stop.Stop === stopNumber).ShipmentDetails.map((detail) => detail.SalesOrderId);

        let skids = this.shipment.Skids.filter((skid) => salesOrderIds.indexOf(skid.SalesOrderId) !== -1);

        return skids;
    }

    getActualWeightForStop(stopNumber: number): number {
        let skids = this.getSkidsForStop(stopNumber);
        return !skids.length
            ? 0
            : skids.map((skid) => skid.ActualWeight - skid.TareWeightBottom - skid.TareWeightTop).reduce((prev, curr) => prev + curr);
    }

    getShipmentReceiptSizeTotals(): ISimpleEntity[] {
        // TODO: pull from database
        return [
            { Id: ReceiptSizeTotal.Pieces, Name: 'Pieces' },
            { Id: ReceiptSizeTotal.NetWeight, Name: 'Net Weight' },
            { Id: ReceiptSizeTotal.Bundles, Name: 'Bundles' },
            { Id: ReceiptSizeTotal.TiedBundles, Name: 'Tied Bundles' },
        ];
    }

    isValid(): boolean {
        if (!this.shipmentReceiptOptions.StopOptions.some((opt) => opt.ReceiptOptions.some((ro) => ro.Print))) {
            this.notificationsService.warning('You must select at least one stop to print.');
            return false;
        }

        const stopsToPrint = this.shipmentReceiptOptions.StopOptions.filter((opt) => opt.ReceiptOptions.some((ro) => ro.Print)).map(
            (opt) => opt.StopNumber,
        );
        const missingFormatDetails = this.shipment.Receipts.some(
            (r) =>
                stopsToPrint.some((num) => num === r.Stop) &&
                r.ReceiptDetails.some((rd) => !rd.ShowBundles && !rd.ShowPieces && !rd.ShowSkidIds && !rd.ShowWeight),
        );

        if (missingFormatDetails) {
            this.notificationsService.warning('You must select at least one receipt format detail option for each order in the stops being printed.');
            return false;
        }

        return true;
    }

    formSubmitted(): void {
        if (this.isValid()) {
            this.shipmentService
                .getReceiptPdf(this.shipmentReceiptOptions)
                .pipe(finalize(() => this.enableDoubleClick()))
                .subscribe((pdfBase64) => {
                    PrintService.printPdf(pdfBase64);
                    this.loadShipment(this.shipmentReceiptOptions.ShipmentId);
                });
        } else {
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancel(): void {
        this.clearForm();
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    getOrderDimensions(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder.ManufacturingOrderDetail?.Width && salesOrder.ManufacturingOrderDetail?.Length) {
            return ' | ' + salesOrder.ManufacturingOrderDetail?.Width + ' x ' + salesOrder?.ManufacturingOrderDetail?.Length;
        } else {
            return '';
        }
    }
    showReceiptDetailsModal(): void {
        if (this.printModal) {
            this.printModal.show();
            this.updateReceiptDetailsDefaultText();
        }
    }

    showStockReceiptDetailsButton(): void {
        if (this.stockReceiptEntryModal) {
            this.stockReceiptEntryModal.show();
        }
    }

    updateReceiptDetailsDefaultText(): void {
        this.shipmentReceiptDetailsComp.setCurrentDefaultText();
    }

    returnToReceiptEntry(): void {
        this.isBillOfLading = false;
        this.shipment.Hauler = null;
        this.shipment.HaulerId = null;
        this.loadShipment(this.shipmentReceiptOptions.ShipmentId);
    }

    voidInvoiceClicked(receiptId: number): void {
        this.doubleClickIsDisabled = true;
        this.modalService
            .showModal({
                title: 'Are you sure you want to void this invoice?',
                type: 'warning',
            })
            .subscribe((result) => {
                if (!result.value) {
                    this.doubleClickIsDisabled = false;
                    return;
                }

                this.receiptService.voidInvoice(receiptId).subscribe(
                    (result) => {
                        this.loadShipment(this.shipment.Id);
                        this.notificationsService.success('Invoice Voided');
                        this.doubleClickIsDisabled = false;
                    },
                    (error) => {
                        if (error.status && error.status === 401) {
                            this.notificationsService.error('You do not have permission to void this invoice.');
                        } else {
                            this.notificationsService.error('There was an issue voiding the invoice.  Please try again.');
                        }
                        this.doubleClickIsDisabled = false;
                    },
                );
            });
    }

    updateReceiptDetail(): void {
        Object.assign(this.receiptDetail, this.receiptDetailForm.value.ReceiptDetail as IReceiptDetail);
        Object.assign(this.receiptShipmentStop, this.receiptDetailForm.value.ShipmentStop);
        this.receiptDetail.ReceiptTextOverwrite = this.newReceiptDetailsText ?? '';
        forkJoin([
            this.receiptService.updateDetails(this.receiptDetail.ReceiptId, [this.receiptDetail]),
            this.receiptService.updateStockDetails(this.receiptDetail, this.stockOrderReceiptDetails),
            this.receiptService.updatePartial({ Invoiced: false, SentToQuickbooks: false }, this.receiptDetail.ReceiptId),
            this.orderCustomerSpecsService.updatePartial({ ReceiptPartInfo: this.receiptPartInfo }, this.orderCustomerSpecsId),
            this.shipmentService.updatePartial(
                { HaulerId: this.shipment.HaulerId, ReceiptLoadDate: new Date(this.currentLoadDate) },
                this.shipment.Id,
            ),
            this.shipmentService.updateShipmentStopAddress(
                this.shipment.Id,
                this.receiptShipmentStop.Stop,
                this.customerShippingAddressId,
            ),
            this.shipmentService.updateReceiptShipmentDetails({
                ShipmentId: this.shipment.Id,
                TrailerHeavyWeight: this.receiptDetail.TrailerHeavyWeight,
                TrailerLightWeight: this.receiptDetail.TrailerLightWeight,
                TrailerNumber: this.receiptDetail.TrailerNumber,
            }),
        ]).subscribe(() => {
            this.selectedOrder.OrderInfo.OrderCustomerSpecification.ReceiptPartInfo = this.receiptPartInfo ?? '';
            this.loadShipment(this.shipmentReceiptOptions.ShipmentId, this.selectedOrder.Id);
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.notificationsService.success('Successfully updated Receipt');
        });
    }
}
