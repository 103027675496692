<h5 class="text-center font-italic m-t-0 m-b-md">Load #{{ shipmentId }}</h5>
<form #f="ngForm" (ngSubmit)="submit(f)">
    <div class="row m-b-md">
        <div class="col-xs-6">
            <label for="trailerId">Trailer</label>
            <select [(ngModel)]="trailerId" name="trailerId" class="form-control" required>
                <option></option>
                <option *ngFor="let item of trailers" [ngValue]="item.Id">{{ item.Number }}</option>
            </select>
        </div>
        <div class="col-xs-6">
            <label for="haulerId">Hauler</label>
            <select [(ngModel)]="haulerId" name="haulerId" class="form-control" required>
                <option></option>
                <option *ngFor="let item of haulers" [ngValue]="item.Id">{{ item.Name }}</option>
            </select>
        </div>
    </div>

    <button type="submit" [disabled]="isSubmitting" class="swal2-confirm swal2-styled">
        Assign Load
    </button>
</form>
