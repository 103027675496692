import { ISkidDynamicControlsParameters, SkidDynamicControls } from '../form-controls/skid.form-controls';
import { ISkid } from '../interfaces/skid';
import { DynamicField, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { OverrideNumericFunctionsEmptyWhenZero } from '@common/common-functions.form-controls';

export class SkidDynamicControlsPartial extends SkidDynamicControls {
    constructor(skid?: ISkid, additionalParameters?: ISkidDynamicControlsParameters) {
        super(skid, additionalParameters);

        (<DynamicField>this.Form.BacktenderBarcode).autoFocus = true;
        this.Form.BacktenderBarcode.labelHtml = '<label>Skid Number</label>';
        this.Form.TareWeightTop.labelHtml = '<label>Top</label>';
        this.Form.TareWeightBottom.labelHtml = '<label>Bottom</label>';
        this.Form.FinalBarcode.labelHTML = '<label>Skid ID</label>';
        this.Form.SkidHeight.validation.push(Validators.max(99.9));
        this.Form.SkidHeight.validators.max = 99.9;
        this.Form.Caliper.validation.push(Validators.max(0.999));
        this.Form.Caliper.validators.max = 0.999;

        this.Form.SalesOrderId.type.fieldType = DynamicFieldTypes.Input;
        this.Form.SalesOrderId.validation.push(Validators.required);

        OverrideNumericFunctionsEmptyWhenZero([this.Form.Caliper]);
    }
}
