import { Component, OnInit } from '@angular/core';
import { PrintService } from '../../common/services/print.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType, IDynamicField } from '@mt-ng2/dynamic-form';
import { ReceiptService } from '../receipt.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'released-invoices-print',
    templateUrl: './released-invoices-print.component.html',
})
export class ReleasedInvoicesPrintComponent implements OnInit {
    dateInputField: IDynamicField;
    dateInputValue: Date;

    doubleClickIsDisabled = false;

    constructor(private receiptService: ReceiptService) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.dateInputField = new DynamicField({
            formGroup: 'ReleasedInvoicesPrint',
            hideLabel: true,
            label: 'Date',
            name: 'Date',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: new Date(),
        } as IDynamicField);
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    print(): void {
        this.receiptService
            .generateReleasedInvoicesReportByDate(this.dateInputValue)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }
}
