<h2>Customer Settings</h2>
<div>
    <div class="row">
        <div class="col-md-6">
            <meta-item-managed-list
                [service]="customerPaymentTermService"
                [dynamicForm]="customerPaymentTermDynamicForm"
                [canAdd]="true"
                [canRemove]="true"
            ></meta-item-managed-list>
        </div>
    </div>
</div>
<br />
<a routerLink="./customers" class="btn btn-default btn-flat">Close</a>
