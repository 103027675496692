import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IMillCorpInvoice } from '@model/interfaces/mill-corp-invoice';
import { Observable } from 'rxjs';
import { IMillCorpInvoiceDTO } from '@model/dto/mill-corp-invoice-dto';
import { IMillCorpInvoiceLoadNumberDTO } from '@model/dto/mill-corp-invoice-load-number-dto';

export const emptyMillCorpInvoice: IMillCorpInvoice = {
    BackhaulInfo: [],
    Id: 0,
    PalletRate: 1.85,
    ShipmentId: 0,
    StateMileages: [],
    StopRate: 80,
    TopRate: 0.92,
};

@Injectable({
    providedIn: 'root',
})
export class MillCorpInvoiceService extends BaseService<IMillCorpInvoice> {

    constructor(public http: HttpClient) {
        super('/mill-corp-invoices', http);
    }

    getEmptyMillCorpInvoice(): IMillCorpInvoice {
        return { ...emptyMillCorpInvoice };
    }

    getByShipment(shipmentId: number): Observable<IMillCorpInvoiceDTO> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('shipmentId', shipmentId.toString());
        return this.http.get<IMillCorpInvoiceDTO>(`/mill-corp-invoices/by-shipment`, { params: httpParams });
    }

    getLoadNumbersByDeliveryDate(date: Date): Observable<IMillCorpInvoiceLoadNumberDTO[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('deliveryDate', date.toString());
        return this.http.get<IMillCorpInvoiceLoadNumberDTO[]>(`/mill-corp-invoices/by-delivery-date`, { params: httpParams });
    }
}
