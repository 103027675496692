import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../model/ClaimTypes';
import { ContactsComponent } from './contact-list/contacts.component';
import { SharedEntitiesInfoComponent } from '@mt-ng2/shared-entities-module';
import { ContactHeaderComponent } from './contact-header/contact-header.component';
import { getCustomerSharedEntityConfig, CustomerSharedEntities } from '../customers/shared-entities/customer.shared-entities';
import { IContact } from '../model/interfaces/contact';

const contactsSharedEntity = getCustomerSharedEntityConfig(CustomerSharedEntities.Contacts);
const globalContactEntityConfig = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    sharedEntities: [contactsSharedEntity],
    title: 'Contact Detail',
};

const contactsRoleGuard = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Contacts',
};

const contactRoutes: Routes = [
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard], data: contactsRoleGuard },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: SharedEntitiesInfoComponent, pathMatch: 'full' }],
        component: ContactHeaderComponent,
        data: globalContactEntityConfig,
        path: `contacts/:contactId`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(contactRoutes)],
})
export class ContactRoutingModule {}
