import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICornerCutType } from '../model/interfaces/corner-cut-type';

import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable(
    {
        providedIn: 'root',
    },
)
export class CornerCutTypesService extends StaticMetaItemService<ICornerCutType> {
    constructor(public http: HttpClient) {
        super('CornerCutTypesService', 'Type', 'TypeIds', '/cornercuttypes', http);
    }
}
