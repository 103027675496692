import { Component, OnInit } from '@angular/core';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { CustomerShippingAddressService } from '../shared-entities/customer-shipping-address.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-customer-shipping-address-actions',
    templateUrl: './customer-shipping-address-actions.component.html',
})
export class CustomerShippingAddressActionsComponent implements OnInit, IEntityListDynamicCellComponentGeneric<ICustomerShippingAddress> {
    entity: ICustomerShippingAddress;
    entityListComponentMembers: IEntityListComponentMembers;

    canEdit: boolean;

    constructor(
        private customerShippingAddressService: CustomerShippingAddressService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
    }

    canArchive(): boolean {
        return !this.entity.IsPrimary && !this.entity.Archived;
    }

    archiveAddress(): void {
        if (this.canArchive()) {
            this.customerShippingAddressService
                .archiveAddress(this.entity.CustomerId, this.entity.Id)
                .subscribe(() => {
                    this.notificationsService.success('Address Archived Successfully');
                    this.entityListComponentMembers.getEntities();
                });
        } else {
            this.notificationsService.info('Cannot Archive Address');
        }
    }
}
