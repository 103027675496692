<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Lining Guillotine Report {{currentReportId > 0 ? "#" + currentReportId : ""}}</h2>
        </div>
    </div>
</div>
<div *ngIf="liningGuillotineLogEntryForm">
    <form class="miles-form p-md form-sm" style="border: 3px solid #4a4aff" [formGroup]="liningGuillotineLogEntryForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-xs-4">
                <dynamic-field
                    [field]="abstractLiningGuillotineLogControls.Date"
                    [form]="liningGuillotineLogEntryForm"
                    (controlCreated)="dateControlCreated($event)"
                ></dynamic-field>
            </div>
            <div class="col-xs-1">
                <dynamic-field [field]="abstractLiningGuillotineLogControls.ShiftCode" [form]="liningGuillotineLogEntryForm"></dynamic-field>
            </div>
            <div class="col-xs-2 col-xs-offset-4" *ngIf="maxReportId > 1">
                <div class="pull-right">
                    <button type="button" class="btn btn-default" (click)="navigateToFirstReportOfTheDay()">
                        <i class="fa fa-fw fa-angle-double-left"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToPreviousReport()">
                        <i class="fa fa-fw fa-angle-left"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToNextReport()">
                        <i class="fa fa-fw fa-angle-right"></i>
                    </button>
                    <button type="button" class="btn btn-default" (click)="navigateToLatestReportOfTheDay()">
                        <i class="fa fa-fw fa-angle-double-right"></i>
                    </button>
                </div>
            </div>
            <div [ngClass]="maxReportId > 1 ? 'col-xs-1' : 'col-xs-1 col-xs-offset-6'">
                <button
                    type="button"
                    class="btn btn-default btn-insert"
                    (click)="getNewReport()"
                    [disabled]="!maxReportId"
                >
                    Create New
                </button>
            </div>
        </div>
        <div *ngIf="!loadingLogs" class="row">
            <div class="col-xs-4">
                <dynamic-field [field]="abstractLiningGuillotineLogControls.Cutter" [form]="liningGuillotineLogEntryForm"></dynamic-field>
            </div>
        </div>
        <hr />
        <div *ngFor="let group of liningGuillotineLogEntryFormArray.controls; let i = index">
            <div (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = null">
                <div style="position: absolute; right: 40px; z-index: 1;" *ngIf="hoveredIndex === i">
                    <i class="fa fa-fw fa-lg fa-close pointer" style="position: relative; top: -6px;" (click)="removeDetail(i)"></i>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="row">
                            <div class="col-xs-2">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [(field)]="abstractLiningGuillotineLogControls.SalesOrderId"
                                    [form]="group"
                                    (valueChanges)="pullSalesOrderValues(i)"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.CustomerName"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Pieces"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Width"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Length"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Caliper"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-3">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Lining"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.BdlSk"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Count"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                            <div class="col-xs-1">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Hours"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="row row-col-align-middle">
                            <div class="col-xs-4">
                                <dynamic-field
                                    [overrideForm]="true"
                                    [field]="abstractLiningGuillotineLogControls.Note"
                                    [form]="group"
                                ></dynamic-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addDetail()">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
        <div class="row m-t-lg">
            <div class="col-xs-3 col-xs-offset-3">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-success btn-block">
                    Save
                </button>
            </div>
            <div class="col-xs-3">
                <button type="button" Class="btn btn-default btn-block" (click)="cancelClick()">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>
