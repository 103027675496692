import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { OrderStatusService } from '../order-status.service';
import { CustomerService } from '../../customers/customer.service';
import { ICustomerPartial } from '../../model/partials/customer';
import { format } from '@mt-ng2/format-functions';
import { ICustomerShippingAddress } from '../../model/interfaces/customer-shipping-address';
import { IOrderInfo } from '../../model/interfaces/order-info';
import { SalesOrderService } from '../sales-order.service';
import { ISalesOrder } from '../../model/interfaces/sales-order';

import { SalesOrderDynamicControlsPartial } from '../../model/partials/sales-order.form-controls';
import { OrderInfoDynamicControlsPartial } from '../../model/partials/stock-order-info.form-controls';

import { ClaimsService, ClaimValues, ILoggedIn, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { IStockOrderLine } from '../../model/interfaces/stock-order-line';
import { OrderStatusIds, OrderStatusNames } from '../../model/OrderStatuses';
import { OrderTypeIds, OrderTypeNames } from '../../model/OrderTypes';
import { OrderInfoService } from '../order-info.service';
import { PrintService } from '../../common/services/print.service';
import { PrintFormsOptions, PrintStockFormsOptions } from '../../model/dto/PrintFormsOptions';
import { finalize, tap } from 'rxjs/operators';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { IFormattedSalesOrder } from '@model/interfaces/custom/formatted-sales-order';
import { truncateToOneDecimal } from '@common/common-functions.form-controls';
import { CustomerCreditStatuses } from '@model/enums/customer-credit-statuses.enum';
import { CreditAuthorizationStatuses } from '@model/enums/credit-authorization-statuses.enum';
import { ShipmentService } from '../../shipments/shipment.service';
import { IShipment } from '@model/interfaces/shipment';
import { IReceipt } from '@model/interfaces/receipt';
import { ShippingStatuses } from '@model/ShippingStatuses';

@Component({
    selector: 'stock-order-add',
    styles: [
        `
            tr {
                cursor: default !important;
            }
        `,
    ],
    templateUrl: './stock-order-add.component.html',
})
export class StockOrderAddComponent implements OnInit {
    @Input('customerId') customerId: number;
    @Input('customerShippingAddressId') customerShippingAddressId: number;

    printModal: IModalWrapperApi;
    printModalOptions: IModalOptions = {
        confirmButtonText: 'Print',
        focusCancel: true,
        showCancelButton: true,
    };
    printFormsOptions: PrintFormsOptions;

    printStockModal: IModalWrapperApi;
    printStockModalOptions: IModalOptions = {
        confirmButtonText: 'Print',
        focusCancel: true,
        showCancelButton: true,
    };
    printStockFormsOptions: PrintStockFormsOptions;

    // abstract controls
    abstractSalesOrderControls: any;
    abstractOrderInfoControls: any;

    customer: ICustomerPartial;
    salesOrder: IFormattedSalesOrder;
    addressString: string;
    customerShippingAddress: ICustomerShippingAddress;
    stockOrderAddForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    formIsLocked: boolean;
    canSetOrderStatus: boolean;
    emptyOrderInfo: IOrderInfo;
    salesOrderId: number;
    stockOrderPayload: any;
    stockOrderLines: IStockOrderLine[];
    currentUser: ILoggedIn;
    canArchive: boolean;
    customerShippingAddresses: ICustomerShippingAddress[];
    loadedOnShipment: boolean;

    customerIsCreditHold: boolean;
    canProvideCreditAuthorization: boolean;
    creditAuthModal: IModalWrapperApi;
    creditAuthModalOptions: IModalOptions = {
        confirmButtonText: 'Authorize',
        showCancelButton: true,
        showConfirmButton: true,
    };
    currentShipmentId: number;

    get authorized(): boolean {
        return (
            this.salesOrder.CreditAuthStatusId === CreditAuthorizationStatuses.Order ||
            this.salesOrder.CreditAuthStatusId === CreditAuthorizationStatuses.Both
        );
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private orderStatusService: OrderStatusService,
        private customerService: CustomerService,
        private salesOrderService: SalesOrderService,
        private orderInfoService: OrderInfoService,
        private activatedRoute: ActivatedRoute,
        private claimsService: ClaimsService,
        private authService: AuthService,
        private modalService: ModalService,
        private shipmentService: ShipmentService,
    ) {}

    ngOnInit(): void {
        this.emptyOrderInfo = this.orderInfoService.getEmptyOrderInfo();
        this.currentUser = this.authService.currentUser.getValue();
        this.canArchive = this.claimsService.hasClaim(ClaimTypes.Orders_CanArchiveOrders, [ClaimValues.FullAccess]);
        this.printFormsOptions = new PrintFormsOptions();
        this.printStockFormsOptions = new PrintStockFormsOptions();

        const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
        if (orderId) {
            this.salesOrderId = +orderId;
            if (this.claimsService.hasClaim(ClaimTypes.SetOrderStatus, [ClaimValues.FullAccess])) {
                this.canSetOrderStatus = true;
            }
        }
        forkJoin(
            this.salesOrderId ? this.getSalesOrderById(this.salesOrderId) : this.getCustomerById(this.customerId),
            this.orderStatusService.getItems(),
        ).subscribe((answer) => {
            this.createForm();
        });
    }

    getCustomerById(customerId: number): Observable<ICustomerPartial> {
        return this.customerService.getById(customerId).pipe(
            tap((answer) => {
                this.customer = answer;
                const primaryCustomerAddress = this.customer.CustomerAddresses.find((address) => address.IsPrimary).Address;
                this.addressString = primaryCustomerAddress ? format.addressVertical(primaryCustomerAddress) : '';
                this.customerShippingAddresses = this.customer.CustomerShippingAddresses;
                this.customerShippingAddress = this.customer.CustomerShippingAddresses.find(
                    (shippingAddress) => shippingAddress.Id === this.customerShippingAddressId,
                );
                this.emptyOrderInfo.CustomerTerms = this.customer.CustomerPaymentTerm.Name;
                this.emptyOrderInfo.CustomerShipVia = this.customer.ShipVia;
                if (this.customer.OrderCustomerSpecification) {
                    this.emptyOrderInfo.SpecialInstructions = this.customer.OrderCustomerSpecification.SpecialInstructions;
                }
            }),
        );
    }

    getSalesOrderById(salesOrderId: number): Observable<ISalesOrder> {
        return this.salesOrderService.getById(salesOrderId).pipe(
            tap((answer) => {
                this.salesOrder = answer as IFormattedSalesOrder;
                this.customer = this.salesOrder.OrderInfo.Customer;
                this.customerShippingAddresses = this.customer.CustomerShippingAddresses;
                this.customerShippingAddressId = this.salesOrder.OrderInfo.CustomerShippingAddressId;
                const primaryCustomerAddress = this.customer.CustomerAddresses.find((address) => address.IsPrimary).Address;
                this.addressString = primaryCustomerAddress ? format.addressVertical(primaryCustomerAddress) : '';
                this.stockOrderLines = this.salesOrderService.sortStockOrderLines(
                                            this.salesOrder.OrderInfo.StockOrderLines);
                this.loadedOnShipment = this.stockOrderLines.some((sol) => sol.ShipmentId > 0);
                this.customerIsCreditHold = this.customer.CreditStatusId === CustomerCreditStatuses.CreditHold;
                this.canProvideCreditAuthorization = this.claimsService.hasClaim(ClaimTypes.Orders_CanProvideCreditAuthorization, [ClaimValues.FullAccess]);
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.stockOrderAddForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        if (this.salesOrder && this.salesOrder.OrderStatusId === OrderStatusIds.PendingReview) {
            common.markAllFormFieldsAsDisabled(this.stockOrderAddForm);
            this.formIsLocked = true;
            this.toggleOrderStatusField(false);
        } else {
            this.toggleOrderStatusField(true);
        }
    }

    toggleOrderStatusField(enabled: boolean): void {
        let salesOrderControl = this.stockOrderAddForm.controls.SalesOrder;
        let field = (salesOrderControl as FormGroup).controls.OrderStatusId;
        if (enabled && !(this.salesOrder && this.salesOrder.OrderStatusId === OrderStatusIds.Approved)) {
            field?.enable();
        } else {
            field?.disable();
        }
    }

    getControls(): void {
        let isCreate = this.salesOrder === undefined;
        this.abstractSalesOrderControls = new SalesOrderDynamicControlsPartial(
            isCreate ? null : this.salesOrder,
            'SalesOrder',
            null,
            null,
            this.orderStatusService.items,
            null,
        ).Form;
        this.abstractOrderInfoControls = new OrderInfoDynamicControlsPartial(
            isCreate ? this.emptyOrderInfo : this.salesOrder.OrderInfo,
            'OrderInfo',
            null,
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            OrderInfo: this.fb.group({}),
            SalesOrder: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.stockOrderAddForm.valid) {
            this.stockOrderPayload = this.stockOrderAddForm.value;
            if (this.salesOrder) {
                this.updateSalesOrder();
            } else {
                this.createStockOrder();
            }
        } else {
            common.markAllFormFieldsAsTouched(this.stockOrderAddForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    updateSalesOrder(): void {
        Object.assign(this.salesOrder.OrderInfo, this.stockOrderPayload.OrderInfo);
        Object.assign(this.salesOrder, this.stockOrderPayload.SalesOrder);
        this.salesOrder.OrderInfo.CustomerShippingAddressId = this.customerShippingAddressId;
        this.salesOrder.OrderInfo.StockOrderLines = this.stockOrderLines;
        if (this.salesOrder.OrderStatusId === OrderStatusIds.PendingReview) {
            common.markAllFormFieldsAsDisabled(this.stockOrderAddForm);
            this.formIsLocked = true;
        }
        this.salesOrder.OrderStatus = null;
        this.salesOrderService
            .updateWithForeignKeys(this.salesOrder)
            .pipe(
                finalize(() => {
                    this.enableDoubleClick();
                }),
            )
            .subscribe((answer) => {
                this.enableDoubleClick();
                this.notificationsService.success('Order Saved Successfully');
                this.toggleOrderStatusField(true);
            });
    }

    toggleSalesOrderArchived(): void {
        const setArchived = !this.salesOrder.Archived;
        const archiveText = this.salesOrder.Archived ? 'Un-Archive' : 'Archive';
        this.modalService
            .showModal({
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                confirmButtonText: `Yes, ${archiveText.toLowerCase()} it!`,
                showCancelButton: true,
                text: `${archiveText} this Stock Order?`,
                title: 'Are you sure?',
                type: 'warning',
            })
            .subscribe((result) => {
                if (result.value) {
                    this.salesOrderService.toggleSalesOrderArchived(this.salesOrder.Id, setArchived).subscribe(() => {
                        this.notificationsService.success(`Stock Order ${archiveText}d Successfully`);
                        this.salesOrder.Archived = setArchived;
                        if (setArchived) {
                            void this.router.navigate([`/salesorders-stock`]);
                        }
                    });
                }
            });
    }

    createStockOrder(): void {
        this.stockOrderPayload.OrderInfo.OrderTakenBy = this.currentUser.Id;
        this.stockOrderPayload.OrderInfo.CustomerId = this.customerId;
        this.stockOrderPayload.OrderInfo.CustomerShippingAddressId = this.customerShippingAddressId;
        this.stockOrderPayload.OrderInfo.CustomerOrderSpecificationId = null;
        this.stockOrderPayload.OrderInfo.OrderCustomerSpecification = null;
        this.stockOrderPayload.OrderStatus = {
            Id: OrderStatusIds.Open,
            Name: OrderStatusNames.Open,
        };
        this.stockOrderPayload.OrderStatusId = 1;
        this.stockOrderPayload.OrderType = {
            Id: OrderTypeIds.Stock,
            Name: OrderTypeNames.Stock,
        };
        this.stockOrderPayload.OrderTypeId = 2;
        this.stockOrderPayload.OrderInfoId = 0;
        this.salesOrderService
            .create(this.stockOrderPayload)
            .pipe(
                finalize(() => {
                    this.enableDoubleClick();
                }),
            )
            .subscribe((answer) => {
                this.notificationsService.success('Order Saved Successfully');
                this.router.navigate(['salesorders-stock/' + answer]);
            });
    }

    printForms(): void {
        if (this.customer.CreditStatusId === CustomerCreditStatuses.CreditHold && !this.authorized) {
            this.printFormsOptions.IsOnCreditHold = true;
        }

        this.salesOrderService
            .printForms(this.salesOrderId, this.printFormsOptions)
            .pipe(
                finalize(() => {
                    this.enableDoubleClick();
                    this.printFormsOptions = new PrintFormsOptions(); // clear selections
                }),
            )
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
        this.printModal.close();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    availableInventory(): void {
        this.router.navigate(['inventory'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Order Save Failed');
    }

    success(): void {
        this.notificationsService.success('Order Saved Successfully');
    }

    formatStockItemColor(line: any): string {
        if (line.StockItem.TopColor && line.StockItem.BottomColor) {
            return line.StockItem.TopColor.Name + ' / ' + line.StockItem.BottomColor.Name;
        }
        return '';
    }

    showPrintModal(): void {
        this.printModal.show();
    }

    closePrintModal(): void {
        this.printModal.close();
    }

    deleteStockOrderLine(line: IStockOrderLine, index: number): void {
        this.salesOrderService.deleteStockOrderLineById(line.Id).subscribe(() => {
            this.stockOrderLines.splice(index, 1);
        });
    }

    getTruncatedBundles(bundles: number): number {
        return truncateToOneDecimal(bundles);
    }

    showCreditAuthModal(): void {
        if (this.creditAuthModal) {
            this.creditAuthModal.show();
        }
    }

    closeCreditAuthModal(): void {
        if (this.creditAuthModal) {
            this.creditAuthModal.close();
        }
    }

    authorizeOrder(): void {
        const date = new Date();
        this.salesOrderService
            .updatePartial(
                { OrderStatusId: OrderStatusIds.Approved, CreditAuthStatusId: CreditAuthorizationStatuses.Both, CreditAuthDate: date },
                this.salesOrderId,
            )
            .subscribe(() => {
                this.notificationsService.success('Credit Authorization updated successfully.');
                this.salesOrder.CreditAuthStatusId = CreditAuthorizationStatuses.Both;
                this.salesOrder.CreditAuthDate = date;
                this.salesOrder.OrderStatusId = OrderStatusIds.Approved;
                let salesOrderControl = this.stockOrderAddForm.controls.SalesOrder;
                (salesOrderControl as FormGroup).controls.OrderStatusId.setValue(this.salesOrder.OrderStatusId);
            });
    }

    getShipmentDate(shipment: IShipment): any {
        return this.shipmentService.getShipmentDate(shipment, this.salesOrderId);
    }

    showPrintStockLinePopup(shipment: IShipment): void {
        this.printStockFormsOptions.InvoiceIsAvailable = shipment.Receipts.find((r) => r.ReceiptDetails.some((rd) => rd.SalesOrderId === this.salesOrderId))?.Invoiced;
        this.currentShipmentId = shipment.Id;
        this.printStockModal.show();
    }

    closeStockPrintModal(): void {
        this.printStockModal.close();
        this.printStockFormsOptions.Invoice = false;
        this.printStockFormsOptions.Receipt = false;
    }

    printStockForms(): void {
        if (this.printStockFormsOptions.Invoice || this.printStockFormsOptions.Receipt) {
            this.salesOrderService
            .printStockForms(this.currentShipmentId, this.salesOrderId, this.printStockFormsOptions)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((pdfBase64) => {
                PrintService.printPdf(pdfBase64);
            });
            this.printStockModal.close();
        } else {
            this.notificationsService.warning('You must select at least one form to print.');
        }
    }
}
