import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IHasDate } from '@model/interfaces/custom/has-date';
import { BaseService, IEntity } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class BulkByDateService<T extends IEntity & IHasDate> extends BaseService<T> {
    constructor(protected baseRoute: string, protected http: HttpClient) {
        super(baseRoute, http);
    }

    saveByDate(records: T[]): Observable<number[]> {
        return this.http.put<number[]>(`${this.baseRoute}/by-date`, records);
    }

    getByDate(date: Date, reportId: number = null): Observable<T[]> {
        let params = new HttpParams();
        params = params.append('date', formatDate(date, 'yyyy-MM-dd', 'en'));
        if (reportId !== null) {
            params = params.append('reportId', reportId.toString());
        }
        return this.http.get<T[]>(`${this.baseRoute}/by-date`, { params }).pipe(
            map((logs) => {
                logs.forEach((log) => {
                    // Date comes back as midnight UTC, but we need the date to be midnight local time
                    log.Date = new Date(log.Date);
                    log.Date.setHours(24, 0, 0, 0); // 24 bumps to midnight of the next day
                });
                return logs;
            }),
        );
    }
}
