<div *ngIf="!initialized">
    <h2>Select a Customer</h2>
    <ng-template #rt let-r="result" class="btn-success" let-t="term">
        {{ r.CustomerName }}
    </ng-template>
    <div class="row" [hidden]="isAddingShippingAddress">
        <div class="col-sm-6 col-sm-offset-3 input-group input-group-lg">
            <label for="typeahead-customer">
                <h2>Search for a Customer:</h2>
            </label>
            <input
                id="typeahead-customer"
                type="text"
                #input
                class="form-control"
                [(ngModel)]="customerName"
                [ngbTypeahead]="search"
                [resultTemplate]="rt"
                [inputFormatter]="formatter"
                (selectItem)="selectedCustomer($event, input)"
                autofocus
                #focusInput
            />
            <p>Please enter the customer name.</p>
        </div>
        <div class="row">
            <div class="col-sm-6 col-sm-offset-3 input-group input-group-lg">
                <div *ngIf="customerShippingAddresses">
                    <label for="select-address">
                        <h3>Select a Shipping Address:</h3>
                    </label>
                    <select [(ngModel)]="customerShippingAddressId" id="select-address" class="form-control">
                        <option *ngFor="let address of customerShippingAddresses" [ngValue]="address.Id" [selected]="address.IsPrimary ? true : null" [disabled]="address.Archived">
                            <span>{{ address | shippingAddress:'name':'primary':'archived' }}</span>
                        </option>
                    </select>
                    <div class="pull-left">Click Next to continue.</div>
                    <div class="pull-right">
                        <a (click)="isAddingShippingAddress = true" class="pointer">Add New</a>
                    </div>
                    <div class="clearfix"></div>
                    <div *ngIf="customerShippingAddressId && customerId" class="m-t-sm">
                        <button (click)="initialized = true" class="btn btn-secondary btn-lg btn-block">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isAddingShippingAddress">
        <div class="col-sm-6 col-sm-offset-3">
            <customer-shipping-address-add
                [customerId]="customerId"
                (onCancel)="isAddingShippingAddress = false"
                (onSave)="onShippingAddressAdded($event)"
            ></customer-shipping-address-add>
        </div>
    </div>
</div>

<manufacturing-order-add
    *ngIf="initialized && isManufacturing"
    [customerId]="customerId"
    [customerShippingAddressId]="customerShippingAddressId"
></manufacturing-order-add>
<stock-order-add *ngIf="initialized && isStock" [customerId]="customerId" [customerShippingAddressId]="customerShippingAddressId"></stock-order-add>
