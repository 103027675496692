<dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <br />
    <!-- input element -->
    <input type="date" class="hidden" [formControlName]="config.name" [value]="config.value" />
    <label
        #input
        [tabindex]="isDisabled ? -1 : 0"
        class="btn-primary-inverse selected-date-label"
        ngbButtonLabel
        (click)="toggleDatePicker()"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [attr.disabled]="isDisabled ? true : null"
    >
        {{ selectedDateText }}
        <i class="fa fa-fw fa-calendar"></i>
    </label>
    <br />
    <ngb-datepicker
        class="date-picker-styles"
        #datePicker
        [minDate]="datepickerMinDate"
        [maxDate]="datepickerMaxDate"
        [class.hidden]="!showDatePicker"
        (select)="dateSelected($event)"
        [dayTemplate]="customDay"
        [firstDayOfWeek]="firstDayOfTheWeek"
    ></ngb-datepicker>
    <!-- validation elements -->
    <div *ngIf="config.validators">
        <div
            *ngIf="!showRequired() && hasError('required')"
            class="small errortext"
            [style.position]="'absolute'"
            [class.inside-box-validation]="config.insideBoxValidation"
        >
            {{ config.label }} is required
        </div>
    </div>
</dynamic-form-input-wrapper>
<ng-template #customDay let-date="date" let-selected="selected">
    <div class="date-picker-day-view" [class.selected-date]="selected">
        <span>{{ date.day }}</span>
    </div>
</ng-template>
