<div class="miles-card padded">
    <h4>Print Released Invoices Report</h4>
    <div class="pull-left">
        <dynamic-field [field]="dateInputField" (valueChanges)="dateInputValue = $event"></dynamic-field>
    </div>
    <div class="pull-left m-l-md">
        <button class="btn btn-secondary" (click)="print()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
            <i class="fa fa-fw fa-print"></i> Print
        </button>
    </div>
    <div class="clearfix"></div>
</div>
