<div *ngIf="millComplaintForm">
    <form class="miles-form padded" [formGroup]="millComplaintForm" (ngSubmit)="formSubmitted()" style="text-align: left">
        <h4>Mill Complaint</h4>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="cd">Complaint Date:</label>
                    <span>{{ millComplaint.ComplaintDate | date }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="cd">Complaint Taken By:</label>
                    <span *ngIf="millComplaint.Id === 0">{{ currentUser.Name }}</span>
                    <span *ngIf="millComplaint.Id > 0">{{ millComplaint.User.FirstName }} {{ millComplaint.User.LastName }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dynamic-field [field]="abstractMillComplaintControls.MillComplaintCategoryId" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dynamic-field [field]="abstractMillComplaintControls.ComplaintReportedBy" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <dynamic-field [field]="abstractMillComplaintControls.QuantityAffected" [form]="millComplaintForm"> </dynamic-field>
            </div>
            <div class="col-md-3" style="margin-top: 4.3px">
                <dynamic-field [field]="abstractMillComplaintControls.QuantityAffectedTypeId" [form]="millComplaintForm"></dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <dynamic-field [field]="abstractMillComplaintControls.DescriptionOfProblem" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <span class="board-status-header">Check box below if "Yes"</span>
        <div class="row">
            <div class="col-md-6">
                <dynamic-field
                    [field]="abstractMillComplaintControls.IsBoardReturned"
                    (valueChanges)="checkBoardReturned($event)"
                    [form]="millComplaintForm"
                ></dynamic-field>
                <dynamic-field
                    [field]="abstractMillComplaintControls.IsBoardKept"
                    (valueChanges)="checkBoardKept($event)"
                    [form]="millComplaintForm"
                ></dynamic-field>
                <dynamic-field [field]="abstractMillComplaintControls.IsBoardRemade" [form]="millComplaintForm"> </dynamic-field>
            </div>
            <div class="col-md-6">
                <dynamic-field [field]="abstractMillComplaintControls.Other" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <span class="credit-handling-header">Credit Handling:</span><br />
        <div class="row credit-handling-body">
            <div class="col-md-5">
                <dynamic-field [field]="abstractMillComplaintControls.IsCreditIssued" [form]="millComplaintForm"> </dynamic-field>
            </div>
            <div class="col-md-12">
                <dynamic-field class="credit-issued-input" [field]="abstractMillComplaintControls.CreditIssued" [form]="millComplaintForm">
                </dynamic-field>
            </div>
            <div class="col-md-3">
                <dynamic-field [field]="abstractMillComplaintControls.IssueCreditMemo" [form]="millComplaintForm"> </dynamic-field>
            </div>
            <div class="col-md-3">
                <dynamic-field [field]="abstractMillComplaintControls.InvoiceNumber" [form]="millComplaintForm"> </dynamic-field>
            </div>
            <div class="col-md-3">
                <dynamic-field [field]="abstractMillComplaintControls.InvoiceDate" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dynamic-field [field]="abstractMillComplaintControls.Resolution" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dynamic-field [field]="abstractMillComplaintControls.ComplaintRecorded" [form]="millComplaintForm"> </dynamic-field>
            </div>
        </div>
        <br />
        <button
            type="submit"
            *ngIf="canEdit"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            Class="btn btn-flat btn-success"
        >
            Save
        </button>
        <button type="button" class="btn btn-flat btn-default" (click)="cancelClick()">
            {{ millComplaintForm.dirty || !millComplaint.Id ? 'Cancel' : 'Close' }}
        </button>
        <button type="button" *ngIf="millComplaint.Id" class="btn btn-flat btn-default" (click)="printComplaint()">Print</button>
    </form>
</div>
