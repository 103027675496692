import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { ICustomer } from '../../model/interfaces/customer';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerService } from '../customer.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { IAddress } from '../../model/interfaces/address';
import { CustomerSharedEntities } from '../shared-entities/customer.shared-entities';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { CustomPackagingService } from '../custom-packaging/custom-packaging.service';
import { CustomPackagingsEntityListConfig } from '../custom-packaging/custom-packagings.entity-list-config';
import { CustomPackagingDynamicConfig } from '../custom-packaging/custom-packaging.dynamic-config';
import { customPackagingPathSlugs } from '@routes/custom-packaging-paths';
import { IEntityListCardOptions } from '@common/components/entity-list-card/entity-list-card.component';
import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { BoardGradeService } from '../../sales-orders/board-grade.service';
import { IBoardGrade } from '@model/interfaces/board-grade';
import { ICustomerAddress } from '@model/interfaces/customer-address';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    customer: ICustomer;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    canEditCustomerContacts: boolean;
    canAddCustomerContacts: boolean;
    canEditCustomerPricing: boolean;
    id: number;
    routeSubscription: Subscription;

    shippingAddresSharedEntityId = CustomerSharedEntities.ShippingAddress;
    invoiceAddress: IAddress;
    contactsSharedEntityId = CustomerSharedEntities.Contacts;

    customPackagingOptions: IEntityListCardOptions<ICustomPackaging>;
    boardGrades: IBoardGrade[];

    constructor(
        private customerService: CustomerService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        public customPackagingService: CustomPackagingService,
        public boardGradeService: BoardGradeService,
        private modalService: ModalService,
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => route))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
            )
            .pipe(filter((route) => route.outlet === 'primary'))
            .pipe(mergeMap((route) => route.data))
            .subscribe((event) => {
                this.ngOnInit();
            });
    }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Customers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.canEditCustomerContacts = this.claimsService.hasClaim(ClaimTypes.Customers_CanEditCustomerContacts, [ClaimValues.FullAccess]);
        this.canAddCustomerContacts = this.canEditCustomerContacts;
        this.canEditCustomerPricing = this.claimsService.hasClaim(ClaimTypes.Customers_CanEditCustomerPricing, [ClaimValues.FullAccess]);

        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('customerId');
        // try load if id > 0
        if (this.id > 0) {
            this.boardGradeService.getAll().subscribe((boardGrades) => {
                this.boardGrades = boardGrades;
                this.setEntityListCardOptions();
                this.getCustomerById(this.id);
            });
        } else {
            // set customer to emptyCustomer
            this.customer = this.customerService.getEmptyCustomer();
            this.invoiceAddress = this.customerService.getEmptyAddress();
        }
        this.editingComponent.next('');
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
    }

    setEntityListCardOptions(): void {
        let emptyItem = this.customPackagingService.getEmptyCustomPackaging();
        emptyItem.CustomerId = this.id;

        this.customPackagingOptions = {
            additionalConfigOptions: [this.boardGrades],
            canAdd: this.canAdd,
            canEdit: this.canEdit,
            dynamicConfig: CustomPackagingDynamicConfig,
            emptyItem: emptyItem,
            entityFormGroup: 'CustomPackaging',
            entityListConfig: new CustomPackagingsEntityListConfig(),
            entitySlugs: customPackagingPathSlugs,
            parentId: this.id,
            service: this.customPackagingService,
        };
    }

    getCustomerById(id: number): void {
        this.customerService.getById(id).subscribe((customer) => {
            this.customer = customer;
            this.getInvoiceAddressById(this.customer.InvoiceAddressId);
        });
    }

    getInvoiceAddressById(id: number): void {
        this.customerService.getInvoiceAddress(id).subscribe((response) => {
            this.invoiceAddress = response.body;
        });
    }

    savePhones(phoneCollection: any): void {
        this.customerService.savePhones(this.customer.Id, phoneCollection).subscribe((success) => {
            this.notificationsService.success('Phones Saved Successfully');
            this.customer.CustomerPhones = phoneCollection.Phones;
            this.editingComponent.next('');
        });
    }

    updatePhonesIfNeeded(phoneCollection: any): void {
        const initialPhones = [...this.customer.CustomerPhones];

        // Check for additions
        const areAllPhonesIncluded = initialPhones.every((initialPhone) =>
          phoneCollection.Phones.some((newPhone) => this.arePhonesEqual(newPhone, initialPhone)),
        );

        if (areAllPhonesIncluded) {
          this.savePhones(phoneCollection);
        } else {
          const updatePhones = this.modalService.showModal({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            showConfirmButton: true,
            text: 'Are you sure you want to update this Phone? Doing so will update all existing orders associated with this record',
            title: 'Update Phone',
          }).pipe(
            map((res) => res.isConfirmed),
            tap((isConfirmed) => {
              if (isConfirmed) {
                this.savePhones(phoneCollection);
              }
            }),
          );
          updatePhones.subscribe();
        }
      }

    arePhonesEqual(phone1: any, phone2: any): boolean {
        return (
            phone1.Phone === phone2.Phone &&
            phone1.Extension === phone2.Extension &&
            phone1.PhoneTypeId === phone2.PhoneTypeId &&
            phone1.IsPrimary === phone2.IsPrimary
        );
    }

    saveAddress(address: any): void {
        let customerAddress: ICustomerAddress = {
            Address: { ...address } as IAddress,
            AddressId: address.AddressId ?? address.Id,
            CustomerId: this.customer.Id,
            IsPrimary: address.IsPrimary,
        };

        this.customerService.saveAddress(this.customer.Id, customerAddress).subscribe(
            (response) => {
                const isNewAddress = !this.customer.CustomerAddresses.some(
                    (addr) => addr.AddressId === customerAddress.AddressId,
                );

                if (isNewAddress) {
                    customerAddress.AddressId = response;
                    this.customer.CustomerAddresses.push(customerAddress);
                } else {
                    const existingAddressIndex = this.customer.CustomerAddresses.findIndex(
                        (addr) => addr.AddressId === customerAddress.AddressId,
                    );

                    if (existingAddressIndex !== -1) {
                        this.customer.CustomerAddresses[existingAddressIndex] = customerAddress;
                    }
                }

                // Update other addresses to set IsPrimary to false
                if (customerAddress.IsPrimary) {
                    this.customer.CustomerAddresses
                        .filter((addr) => addr.AddressId !== customerAddress.AddressId)
                        .forEach((addr) => (addr.IsPrimary = false));
                }

                this.notificationsService.success('Address Saved Successfully');
                this.editingComponent.next('');
            },
            (error) => {
                this.notificationsService.error('Address Save Failed');
            },
        );
    }

    saveInvoiceAddress(address: IAddress): void {
        this.customerService.saveInvoiceAddress(address).subscribe(
            (success) => {
                this.notificationsService.success('Invoice Address Saved Successfully');
                this.invoiceAddress = address;
            },
            (error) => this.notificationsService.error('Customer Save Failed'),
        );
    }
}
