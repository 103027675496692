<div>
    <div class="row">
        <div class="col-md-7">
            <h2>{{ getHeaderText() }}</h2>
        </div>
    </div>
</div>
<div class="row">
    <form [formGroup]="shipmentForm" (ngSubmit)="formSubmitted()">
        <div class="col-md-6">
            <div class="miles-form padded">
                <h4>Enter the item numbers and skids to ship for this load:</h4>
                <div class="row m-t-md" formGroupName="Shipment">
                    <div class="col-xs-4">
                        <dynamic-field [field]="abstractShipmentControls.IsPickup" [form]="shipmentForm"></dynamic-field>
                        <dynamic-field [field]="abstractShipmentControls.StraightTruck" [form]="shipmentForm"></dynamic-field>
                    </div>
                    <div class="col-xs-5">
                        <dynamic-field [field]="abstractShipmentControls.Note" [form]="shipmentForm"></dynamic-field>
                    </div>
                </div>
                <div formArrayName="ShipmentDetails">
                    <table class="table table-borderless middle cursor-auto m-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th width="50%" class="text-center">Order #</th>
                                <th width="25%" class="text-center">Qty</th>
                                <th width="25%" class="text-center">Stop</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let detail of shipmentDetails.controls; let i = index" [ngClass]="{ error: detail.errors }">
                                <ng-container [formGroup]="detail">
                                    <td>{{ i + 1 | number : '2.0' }}</td>
                                    <td>
                                        <input
                                            type="number"
                                            decimalField
                                            [scale]="0"
                                            (blur)="loadOrder(i)"
                                            (keydown.enter)="startFocusShift(i); $event.preventDefault()"
                                            id="shipmentDetail-{{ i }}"
                                            class="form-control"
                                            formControlName="SalesOrderId"
                                            #firstInput
                                            *ngIf="i === 0"
                                        />
                                        <input
                                            *ngIf="i !== 0"
                                            type="number"
                                            decimalField
                                            [scale]="0"
                                            (blur)="loadOrder(i)"
                                            (keydown.enter)="startFocusShift(i); $event.preventDefault()"
                                            id="shipmentDetail-{{ i }}"
                                            class="form-control"
                                            formControlName="SalesOrderId"
                                        />
                                    </td>
                                    
                                    <td>
                                        <input type="number" decimalField [scale]="0" class="form-control" formControlName="NumberOfSkids" />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            decimalField
                                            [scale]="0"
                                            class="form-control"
                                            formControlName="Stop"
                                            (blur)="updateShippingStops()"
                                        />
                                    </td>
                                    <td>
                                        <i class="fa fa-fw fa-close pointer" (click)="removeDetailLine(i)"></i>
                                    </td>
                                    <td>
                                        <button
                                            [ngClass]="detailControlHasTakeFromDetails(detail) ? 'btn-success' : 'btn-default'"
                                            class="btn"
                                            (click)="takeFromOrder(i); $event.preventDefault()"
                                        >
                                            Take From
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                    <div class="fab-wrap">
                        <button type="button" (click)="addDetailLines()" class="btn btn-primary btn-fab-md btn-fab-center">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="miles-form padded" formArrayName="ShipmentStops">
                <h4>Shipping Address Confirmation:</h4>
                <table class="table table-borderless middle cursor-auto m-0">
                    <thead>
                        <tr>
                            <th width="1%">Stop</th>
                            <th width="40%">Customer</th>
                            <th width="59%">Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let stop of shipmentStops.controls; let i = index"  [ngClass]="{ error: stop.errors }">
                            <ng-container [formGroup]="stop">
                                <td class="text-center">
                                    {{ stop.value.Stop }}
                                </td>
                                <td>
                                    {{ stopCustomers[stop.value.Stop]?.Customer.CustomerName }}
                                </td>
                                <td>
                                    <select class="form-control" formControlName="CustomerShippingAddressId" (ngModelChange)="updateValidation(shipmentStops)">
                                        <option *ngFor="let shipAddress of stopCustomers[stop.value.Stop].Addresses" [ngValue]="shipAddress.Id" [disabled]="shipAddress.Archived">
                                            <span>{{ shipAddress | shippingAddress : 'name' : 'code' : 'archived' }}</span>
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <div class="pull-right">
                    <button type="button" class="btn btn-info font-weight-bold" (click)="showUpdatePoModal()" [disabled]="shipmentStops.length === 0">
                        Update PO
                    </button>
                    <button
                        type="submit"
                        mt-doubleClickDisabled
                        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        class="btn btn-success font-weight-bold"
                    >
                        {{ shipment?.Id > 0 ? 'Update' : 'Create' }} Load
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
            <br />
            <div class="miles-form padded">
                <h4>Insert Row</h4>
                <table class="table table-borderless middle cursor-auto m-0">
                    <thead>
                        <tr>
                            <th width="10%"></th>
                            <th class="text-center">Order #</th>
                            <th class="text-center">Qty</th>
                            <th class="text-center">Stop</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [formGroup]="insertForm">
                            <td>
                                <input type="number" class="form-control" decimalField [scale]="0" min="1" formControlName="Position" />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control"
                                    decimalField
                                    [scale]="0"
                                    (blur)="loadOrder(null, true)"
                                    formControlName="SalesOrderId"
                                />
                            </td>
                            <td>
                                <input type="number" class="form-control" decimalField [scale]="0" formControlName="NumberOfSkids" />
                            </td>
                            <td>
                                <input type="number" class="form-control" decimalField [scale]="0" formControlName="Stop" />
                            </td>
                            <td>
                                <button type="button" class="btn btn-default" (click)="clearInsert()">Clear</button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-success" (click)="saveInsert()">Insert</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>

<br />

<modal-wrapper
    [options]="takeFromModalOptions"
    title="Take From Order"
    [autoShow]="false"
    (cancelClick)="closeTakeFromSkids()"
    (ready)="takeFromOrderModal = $event"
>
    <take-from-order [shipmentDetail]="selectedShipmentDetail" (onGetAvailableSkids)="updateTakeFromSkids($event)"></take-from-order>
</modal-wrapper>

<modal-wrapper *ngIf="orderPoMapping" [options]="updatePoModalOptions" title="Update PO Numbers" [autoShow]="false" (ready)="updatePoModal = $event">
    <update-po-numbers [orderPoMapping]="orderPoMapping" (onSave)="onPoNumbersUpdated($event)" (onCancel)="closeUpdatePoModal()"></update-po-numbers>
</modal-wrapper>
