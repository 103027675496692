import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomPackaging } from '@model/interfaces/custom-packaging';

export const emptyCustomPackaging: ICustomPackaging = {
    BoardGradeId: null,
    Caliper: null,
    CustomerId: 0,
    Height: null,
    Id: 0,
    Length: null,
    Minus: null,
    MustCount: null,
    Plus: null,
    Width: null,
};

@Injectable({
    providedIn: 'root',
})
export class CustomPackagingService extends BaseService<ICustomPackaging> {
    constructor(public http: HttpClient) {
        super('/custom-packagings', http);
    }

    getEmptyCustomPackaging(): ICustomPackaging {
        return { ...emptyCustomPackaging };
    }

    getCustomPackagingByCustomerId(customerId: number): any {
        return this.http.get(`/custom-packagings/customer/${customerId}`);
    }
}
