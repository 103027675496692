import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomPackaging } from '../interfaces/custom-packaging';
import { IBoardGrade } from '../interfaces/board-grade';
import { ICustomer } from '../interfaces/customer';

export interface ICustomPackagingDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    boardGrades?: IBoardGrade[];
}

export class CustomPackagingDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    boardGrades: IBoardGrade[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private custompackaging?: ICustomPackaging, additionalParameters?: ICustomPackagingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomPackaging';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;

        this.Form = {
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.custompackaging && this.custompackaging.BoardGradeId || null,
            }),
            Caliper: new DynamicField({
                formGroup: this.formGroup,
                label: 'Caliper',
                name: 'Caliper',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.custompackaging && this.custompackaging.Caliper || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.custompackaging && this.custompackaging.CustomerId || null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.custompackaging && this.custompackaging.hasOwnProperty('Height') && this.custompackaging.Height !== null ? this.custompackaging.Height.toString() : '',
            }),
            Length: new DynamicField({
                formGroup: this.formGroup,
                label: 'Length',
                name: 'Length',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.custompackaging && this.custompackaging.hasOwnProperty('Length') && this.custompackaging.Length !== null ? this.custompackaging.Length.toString() : '',
            }),
            Minus: new DynamicField({
                formGroup: this.formGroup,
                label: 'Minus',
                name: 'Minus',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.custompackaging && this.custompackaging.Minus || null,
            }),
            MustCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Must Count',
                name: 'MustCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.custompackaging && this.custompackaging.MustCount || null,
            }),
            Plus: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plus',
                name: 'Plus',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.custompackaging && this.custompackaging.Plus || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.custompackaging && this.custompackaging.hasOwnProperty('Width') && this.custompackaging.Width !== null ? this.custompackaging.Width.toString() : '',
            }),
        };

        this.View = {
            BoardGradeId: new DynamicLabel(
                'Board Grade',
                getMetaItemValue(this.boardGrades, this.custompackaging && this.custompackaging.hasOwnProperty('BoardGradeId') && this.custompackaging.BoardGradeId !== null ? this.custompackaging.BoardGradeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Caliper: new DynamicLabel(
                'Caliper',
                this.custompackaging && this.custompackaging.Caliper || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.custompackaging && this.custompackaging.hasOwnProperty('CustomerId') && this.custompackaging.CustomerId !== null ? this.custompackaging.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                this.custompackaging && this.custompackaging.hasOwnProperty('Height') && this.custompackaging.Height !== null ? this.custompackaging.Height.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Length: new DynamicLabel(
                'Length',
                this.custompackaging && this.custompackaging.hasOwnProperty('Length') && this.custompackaging.Length !== null ? this.custompackaging.Length.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Minus: new DynamicLabel(
                'Minus',
                this.custompackaging && this.custompackaging.Minus || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            MustCount: new DynamicLabel(
                'Must Count',
                this.custompackaging && this.custompackaging.MustCount || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Plus: new DynamicLabel(
                'Plus',
                this.custompackaging && this.custompackaging.Plus || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Width: new DynamicLabel(
                'Width',
                this.custompackaging && this.custompackaging.hasOwnProperty('Width') && this.custompackaging.Width !== null ? this.custompackaging.Width.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
