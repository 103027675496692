import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { MillComplaintService } from '../services/mill-complaint.service';
import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { MillComplaintsEntityListConfig } from './mill-complaints.entity-list-config';
import { millComplaintPathSlugs } from '@routes/mill-complaint-paths';

@Component({
    selector: 'app-mill-complaints',
    templateUrl: './mill-complaints.component.html',
})
export class MillComplaintsComponent implements OnInit {
    millComplaints: IMillComplaint[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    total: number;
    entityListConfig = new MillComplaintsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddMillComplaint = false;
    salesOrderId: number;

    constructor(
        private millComplaintService: MillComplaintService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddMillComplaint = this.claimsService.hasClaim(ClaimTypes.MillComplaints, [ClaimValues.FullAccess]);
        this.salesOrderId = +this.route.snapshot.paramMap.get('orderId');

        this.getMillComplaints();
    }

    search(): void {
        this.currentPage = 1;
        this.getMillComplaints();
    }

    getMillComplaintsCall(): Observable<HttpResponse<IMillComplaint[]>> {
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({
                name: 'SalesOrderId',
                value: this.salesOrderId.toString(),
            }),
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.millComplaintService.get(searchparams);
    }

    getMillComplaints(): void {
        this.getMillComplaintsCall().subscribe((answer) => {
            this.millComplaints = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getMillComplaints();
    }

    millComplaintSelected(event: IItemSelectedEvent): void {
        void this.router.navigate([event.entity.Id], {relativeTo: this.route});
    }

    addMillComplaint(): void {
        void this.router.navigate([millComplaintPathSlugs.add], {relativeTo: this.route});
    }
}
