<form (ngSubmit)="formSubmitted()">
    <p class="text-center">This process is only to be used to reassign a skid from one order to another order.</p>
    <div class="form-group">
        <label for="skidNumber">Skid ID:</label>
        <input type="text" #skidNumberInput name="skidNumber" [(ngModel)]="skidNumber" (blur)="loadSkid()" class="form-control" min="0" required />
    </div>
    <table class="cursor-auto">
        <tr>
            <td>Customer:</td>
            <td>{{ skid?.SalesOrder?.OrderInfo?.Customer?.CustomerName }}</td>
        </tr>
        <tr>
            <td>Ship To:</td>
            <td>{{ skid?.SalesOrder?.OrderInfo?.OrderShippingAddress?.ShipToName }}</td>
        </tr>
        <tr>
            <td>Grade:</td>
            <td>{{ skid?.BoardGrade?.Name }}</td>
        </tr>
        <tr>
            <td>Size:</td>
            <td>{{ skid ? skid.Width + ' x ' + skid.Length : '' }}</td>
        </tr>
    </table>
    <hr />
    <div class="form-group">
        <label for="orderNumber">Reassign to Order:</label>
        <select [(ngModel)]="orderNumber" (ngModelChange)="loadOrder()" class="form-control" #orderNumberInput name="orderNumber">
            <option *ngFor="let id of salesOrderIds">{{ id }}</option>
        </select>
    </div>
    <table class="cursor-auto">
        <tr>
            <td>Customer:</td>
            <td>{{ order?.OrderInfo?.Customer?.CustomerName }}</td>
        </tr>
        <tr>
            <td>Ship To:</td>
            <td>{{ order?.OrderInfo?.OrderShippingAddress?.ShipToName }}</td>
        </tr>
    </table>
    <div *ngIf="differInSize">
        <span class="warning">Note: Skids from this order do not match in size</span>
    </div>
    <div class="m-t-md">
        <label>
            <input type="checkbox" name="printNewSkidTag" [(ngModel)]="printNewSkidTag" />
            Print New Skid Tag
        </label>
    </div>
    <div class="text-center m-t-md">
        <button
            type="submit"
            class="btn btn-success"
            mt-doubleClickDisabled
            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            [disabled]="!skid || !order"
        >
            Process
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </div>
</form>
