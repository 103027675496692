import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPosition,
    LabelPositions,
} from '@mt-ng2/dynamic-form';

import {
    LiningGuillotineLogDynamicControls,
    ILiningGuillotineLogDynamicControlsParameters,
} from '../form-controls/lining-guillotine-log.form-controls';
import { ILiningGuillotineLog } from '../interfaces/lining-guillotine-log';
import { IBoardType } from '../interfaces/board-type';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { FormatService } from '@common/services/format.service';
import { ScrollableTypeAheadComponent } from '@common/components/scrollable-type-ahead/scrollable-type-ahead.component';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';

export class LiningGuillotineLogDynamicControlsPartial extends LiningGuillotineLogDynamicControls {
    constructor(convertingDataEntryUsers: IConvertingDataEntryUser[], liningGuillotineLogPartial?: ILiningGuillotineLog, additionalParameters?: ILiningGuillotineLogDynamicControlsParameters) {
        super(liningGuillotineLogPartial, additionalParameters);

        (<DynamicField>this.Form.Width).component = FractionsDynamicFieldComponent as any;
        (<DynamicField>this.Form.Length).component = FractionsDynamicFieldComponent as any;

        (<DynamicLabel>this.View.BdlSk).label = 'b/s';
        (<DynamicField>this.Form.BdlSk).labelHtml = '<label>b/s</label>';

        (<DynamicLabel>this.View.ShiftCode).label = 'Shift';
        (<DynamicField>this.Form.ShiftCode).labelHtml = '<label>Shift</label>';

        (<DynamicField>this.Form.Note).labelPosition = new LabelPosition({ position: LabelPositions.Hidden });

        (<DynamicField>this.Form.Caliper).validation.push(Validators.max(0.999));
        (<DynamicField>this.Form.Caliper).validators.max = 0.999;

        this.Form.SalesOrderId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sales Order',
            name: 'SalesOrderId',
            options: this.salesOrders,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                numericFunctions: {
                    numberFormatDisplay: FormatService.noCommasNumberFormatDisplayFunction(),
                },
            }),
            validation: null,
            validators: { 'required': false },
            value: liningGuillotineLogPartial && liningGuillotineLogPartial.SalesOrderId || null,
        });

        this.Form.Cutter = new DynamicField({
            component: ScrollableTypeAheadComponent,
            formGroup: this.formGroup,
            label: 'Cutter',
            name: 'Cutter',
            options: convertingDataEntryUsers.map((user) => {
                return {
                    Id: user.Name,
                    Name: user.Name,
                };
            }),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [ Validators.required ],
            validators: { 'required': true },
            value: liningGuillotineLogPartial && liningGuillotineLogPartial.hasOwnProperty('Cutter') && liningGuillotineLogPartial.Cutter !== null ? liningGuillotineLogPartial.Cutter.toString() : '',
        });
    }
}
