import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomerPricingItem } from '../interfaces/customer-pricing-item';
import { IBoardGrade } from '../interfaces/board-grade';
import { ICustomer } from '../interfaces/customer';

export interface ICustomerPricingItemDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
    boardGrades?: IBoardGrade[];
}

export class CustomerPricingItemDynamicControls {

    formGroup: string;
    customers: ICustomer[];
    boardGrades: IBoardGrade[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerpricingitem?: ICustomerPricingItem, additionalParameters?: ICustomerPricingItemDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerPricingItem';
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.boardGrades = additionalParameters && additionalParameters.boardGrades || undefined;

        this.Form = {
            BoardGradeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Grade',
                name: 'BoardGradeId',
                options: this.boardGrades,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerpricingitem && this.customerpricingitem.BoardGradeId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customerpricingitem && this.customerpricingitem.CustomerId || null,
            }),
            DateEffective: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Effective',
                name: 'DateEffective',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerpricingitem && this.customerpricingitem.DateEffective || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.customerpricingitem && this.customerpricingitem.hasOwnProperty('Notes') && this.customerpricingitem.Notes !== null ? this.customerpricingitem.Notes.toString() : '',
            }),
            Price: new DynamicField({
                formGroup: this.formGroup,
                label: 'Price',
                name: 'Price',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customerpricingitem && this.customerpricingitem.Price || null,
            }),
        };

        this.View = {
            BoardGradeId: new DynamicLabel(
                'Board Grade',
                getMetaItemValue(this.boardGrades, this.customerpricingitem && this.customerpricingitem.hasOwnProperty('BoardGradeId') && this.customerpricingitem.BoardGradeId !== null ? this.customerpricingitem.BoardGradeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerId: new DynamicLabel(
                'Customer',
                getMetaItemValue(this.customers, this.customerpricingitem && this.customerpricingitem.hasOwnProperty('CustomerId') && this.customerpricingitem.CustomerId !== null ? this.customerpricingitem.CustomerId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateEffective: new DynamicLabel(
                'Date Effective',
                this.customerpricingitem && this.customerpricingitem.DateEffective || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.customerpricingitem && this.customerpricingitem.hasOwnProperty('Notes') && this.customerpricingitem.Notes !== null ? this.customerpricingitem.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Price: new DynamicLabel(
                'Price',
                this.customerpricingitem && this.customerpricingitem.Price || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            ),
        };

    }
}
