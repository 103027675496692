<h1 class="col">Stock Selector for Order: {{ salesOrder.Id }}</h1>

<form [formGroup]="receiptDetailsForm">
    <fieldset formArrayName="receiptDetails">
        <legend>Receipt Details</legend>
        <div class="container-fluid">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Sales Order Number</th>
                        <th scope="col">Customer Order Number</th>
                        <th scope="col">Lot Number</th>
                        <th scope="col">Width</th>
                        <th scope="col">Length</th>
                        <th scope="col">Caliper</th>
                        <th scope="col">Grade</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Bundles</th>
                        <th scope="col">Price</th>
                        <th scopoe="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rd of receiptDetailsForPaging.controls; let i = index" [formGroup]="rd">
                        <td>
                            <input type="text" class="form-control" formControlName="SalesOrderNumber" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="CustomerOrderNumber" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="LotNumber" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="Width" [fractionField]="{ maxFactor: 32 }" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="Length" [fractionField]="{ maxFactor: 32 }" />
                        </td>
                        <td>
                            <input type="number" class="form-control" formControlName="Caliper" />
                        </td>
                        <td>
                            <input type="text" class="form-control" formControlName="Grade" />
                        </td>
                        <td>
                            <input type="number" class="form-control" formControlName="Weight" />
                        </td>
                        <td>
                            <input type="number" class="form-control" formControlName="Bundles" (blur)="truncateBundles(i)" />
                        </td>
                        <td>
                            <input type="number" style="display: inline" class="form-control" formControlName="Price" step="any" />
                        </td>

                        <td>
                            <i class="fa fa-fw fa-close pointer" (click)="removeDetailLine(i)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <entity-list-pagination
                [total]="total"
                [(currentPage)]="currentPage"
                [itemsPerPage]="itemsPerPage"
                (pageChange)="pageReceiptDetails()"
            ></entity-list-pagination>
        </div>
    </fieldset>
    <br />
    <div>
        <button type="button" class="btn btn-success" (click)="submitChanges()">Save</button>

        <button type="button" class="btn btn-default" (click)="cancelChanges()">Close</button>
    </div>
</form>
