import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ITestStack } from '@model/interfaces/test-stack';

@Injectable({
    providedIn: 'root',
})
export class TestStackService extends MetaItemService<ITestStack> {
    constructor(public http: HttpClient) {
        super('TestStackService', 'Test Stack', 'TestStackIds', '/test-stacks', http);
    }
}
