import { CustomerPricingItemDynamicControls } from '../form-controls/customer-pricing-item.form-controls';
import { Validators } from '@angular/forms';
import { IExpandableObject } from '../expandable-object';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { ICustomerPricingItem } from '../interfaces/customer-pricing-item';

export class CustomerPricingItemDynamicControlsPartial extends CustomerPricingItemDynamicControls {
    constructor(public customerPricingItemPartial: ICustomerPricingItem) {
        super(customerPricingItemPartial);
        this.Form.DateEffective.insideBoxValidation = true;
        this.Form.DateEffective.hideLabel = true;
        this.Form.Price.hideLabel = true;
        this.Form.Notes.hideLabel = true;
        this.Form.Notes.insideBoxValidation = true;
        this.Form.Price.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 4,
        });
        this.Form.Price.insideBoxValidation = true;
        this.View.Price.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 4,
        });
    }
}
