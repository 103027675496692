import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IDefectType } from '../model/interfaces/defect-type';

@Injectable()
export class DefectTypesService extends MetaItemService<IDefectType> {
    constructor(public http: HttpClient) {
        super('DefectTypesService', 'DefectType', 'DefectTypeIds', '/salesorders/defect-types', http);
    }
}
