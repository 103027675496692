import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { CustomerService } from '../customer.service';
import { ICustomer } from '@model/interfaces/customer';
import { CustomerDynamicConfig } from '../customer.dynamic-config';
import { CustomerPaymentTermService } from '../customerpaymentterm.service';
import { CustomerCreditStatusService } from '../customercreditstatus.service';
import { CustomerShipOnTypeService } from '../customershipontype.service';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../users/user.service';
import { EMPTY, forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { ICustomerPaymentTerm } from '@model/interfaces/customer-payment-term';
import { RunnerDirectionTypeService } from '../../sales-orders/runnerdirectiontype.service';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-customer-basic-info',
    templateUrl: './customer-basic-info.component.html',
})
export class CustomerBasicInfoComponent implements OnInit {
    @Input() customer: ICustomer;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    customerForm: any;
    formFactory: CustomerDynamicConfig<ICustomer>;
    doubleClickIsDisabled = false;
    salesPersonItems: any;
    customerPaymentTermItems: ICustomerPaymentTerm[];

    initialFormState: any = {};

    constructor(
        private customerPaymentTermService: CustomerPaymentTermService,
        private customerCreditStatusService: CustomerCreditStatusService,
        private customerShipOnTypeService: CustomerShipOnTypeService,
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private router: Router,
        private userService: UserService,
        private runnerDirectionTypeService: RunnerDirectionTypeService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        forkJoin([
            this.customerPaymentTermService.getItems(),
            this.userService.getUserSalesPersons(),
            this.customerCreditStatusService.getItems(),
            this.customerShipOnTypeService.getItems(),
            this.runnerDirectionTypeService.getItems(),
        ]).subscribe(([customerPaymentTermItems, userSalesPersonItems]: [ICustomerPaymentTerm[], any]) => {
            this.customerPaymentTermItems = customerPaymentTermItems;
            this.salesPersonItems = userSalesPersonItems;
            this.setConfig();

            this.config.formObject.forEach((dynamicField) => {
                this.initialFormState[dynamicField.name] = dynamicField.value;
            });
        });
    }

    setConfig(): void {
        this.formFactory = new CustomerDynamicConfig<ICustomer>(
            this.customer,
            this.customerPaymentTermItems,
            this.customerCreditStatusService.items,
            this.mapSalesPersonItems(this.salesPersonItems),
            this.customerShipOnTypeService.items,
            this.runnerDirectionTypeService.items,
        );
        if (this.customer.Id === 0) {
            // new customer
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            // existing customer
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    mapSalesPersonItems(salespersons: IUser[]): any {
        return salespersons.map((sp) => {
            return {
                Id: sp.Id,
                Name: sp.FirstName + ' ' + sp.LastName,
            };
        });
    }

    cancelClick(): void {
        if (this.customer.Id === 0) {
            this.router.navigate(['/customers']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.customer, form.value.Customer);
            if (!this.customer.Id || this.customer.Id === 0) {
                // handle new customer save
                this.customerService
                    .create(this.customer)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        this.router.navigate(['/customers/' + answer]);
                        this.success();
                        this.customerService.emitChange(this.customer);
                    });
            } else {
                // handle existing customer save
                this.handleExistingCustomerSave();
            }
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    handleExistingCustomerSave(): void {
        const fieldsToCheck = ['CompanyName', 'CustomerName', 'InvoiceEmail', 'ShipVia', 'ShipOnTypeId', 'RunnerHeight'];

        const anyFieldChanged = fieldsToCheck.some((fieldName) => {
            return this.initialFormState[fieldName] !== this.customer[fieldName];
        });

        if (anyFieldChanged) {
            this.showUpdateModal();
        } else {
            this.updateCustomer();
        }
    }

    showUpdateModal(): void {
        this.modalService
            .showModal({
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                showConfirmButton: true,
                text: 'Are you sure you want to update this Customer Information? Doing so will update all existing orders associated with this record.',
                title: 'Update Customer Info',
            })
            .pipe(
                switchMap((res) => (res.isConfirmed ? this.customerService.updateVersion(this.customer) : EMPTY)),
                finalize(() => (this.doubleClickIsDisabled = false)),
            )
            .subscribe((answer) => this.subscribeAndUpdate(answer));
    }

    updateCustomer(): void {
        this.customerService
            .updateVersion(this.customer)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => this.subscribeAndUpdate(answer));
    }

    subscribeAndUpdate(answer: any): void {
        this.customer.Version = answer;
        this.isEditing = false;
        this.success();
        this.customerService.emitChange(this.customer);
        this.setConfig();

        // Update initialFormState with the latest form values
        const fieldsToCheck = ['CompanyName', 'CustomerName', 'InvoiceEmail', 'ShipVia', 'ShipOnTypeId', 'RunnerHeight'];
        fieldsToCheck.forEach((fieldName) => {
            this.initialFormState[fieldName] = this.customer[fieldName];
        });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Customer saved successfully.');
    }
}
