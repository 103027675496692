<div class="row">
    <div class="col-md-7">
        <h2>Contacts</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <mt-search-bar #searchBar (onSearch)="search($event)"></mt-search-bar>
        <mt-search-filter-select
            *ngIf="typefilter"
            [items]="typefilter.FilterItems"
            [entity]="typefilter.Name"
            (onSelectionChanged)="getContacts()"
        ></mt-search-filter-select>
    </div>
</div>
<entity-list
    [entities]="contacts"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getContacts()"
    (onItemSelected)="contactSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>
