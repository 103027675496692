import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { PrintService } from '../../common/services/print.service';
import { ReceiptService, IReceiptInvoiceView } from '../receipt.service';
import { DynamicField, DynamicFieldType, IDynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicField } from '@mt-ng2/dynamic-form';
import { finalize } from 'rxjs/operators';
import { ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'receipt-print',
    templateUrl: './receipt-print.component.html',
})
export class ReceiptPrintComponent implements OnInit {
    searchNumberFrom: string;
    searchNumberTo: string;

    searchDateFromInput: DynamicField;
    searchDateFrom: Date;
    searchDateToInput: DynamicField;
    searchDateTo: Date;

    bundlesShippedForm: FormGroup;
    bundlesShippedFromInput: DynamicField;
    bundlesShippedToInput: DynamicField;

    receipts: IReceiptInvoiceView[] = [];

    doubleClickIsDisabled: false;
    isEmailing = false;
    isPrinting = false;
    userCanPrint = false;
    userCanReprint = false;
    addedByDateRange = false;

    get showPrintInvoiceButton(): boolean {
        if (this.userCanPrint) {
            return true;
        }
        if (this.userCanReprint) {
            return !this.receipts.some((receipt) => !receipt.Invoiced);
        }
        return false;
    }

    constructor(
        private notificationsService: NotificationsService,
        private receiptService: ReceiptService,
        private modalService: ModalService,
        private router: Router,
        private datePipe: DatePipe,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.clearForm();
        this.setDateValues();
        this.setBundlesShippedConfig();
        this.userCanPrint = this.claimsService.hasClaim(ClaimTypes.Receipts_CanProcessInvoice, [ClaimValues.FullAccess]);
        this.userCanReprint = this.claimsService.hasClaim(ClaimTypes.Receipts_CanReprintInvoice, [ClaimValues.FullAccess]);
    }

    clearForm(): void {
        this.receipts = [];
        this.searchNumberFrom = '';
        this.searchNumberTo = '';
        this.searchDateFrom = null;
        this.searchDateTo = null;
        this.addedByDateRange = false;

        this.searchDateFromInput = new DynamicField({
            formGroup: 'PrintReceipt',
            hideLabel: true,
            label: 'Date',
            name: 'Date',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: null,
        } as IDynamicField);

        this.searchDateToInput = new DynamicField({
            formGroup: 'PrintReceipt',
            hideLabel: true,
            label: 'To',
            name: 'DateTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            } as IDynamicFieldType),
            value: null,
        } as IDynamicField);
    }

    setBundlesShippedConfig(): void {
        this.bundlesShippedForm = new FormGroup({});
        this.bundlesShippedFromInput = new DynamicField({
            formGroup: '',
            label: 'From',
            name: 'DateFrom',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: new Date(),
        });

        this.bundlesShippedToInput = new DynamicField({
            formGroup: '',
            label: 'To',
            name: 'DateTo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: new Date(),
        });
    }

    printBundlesShippedReport(): void {
        const value = this.bundlesShippedForm.value;
        const DateFrom = this.datePipe.transform(value.DateFrom, 'MM/dd/yyyy');
        const DateTo = this.datePipe.transform(value.DateTo, 'MM/dd/yyyy');
        this.receiptService.printBundlesShippedDailyReport(DateFrom, DateTo).subscribe((pdf) => {
            PrintService.printPdf(pdf);
        });
    }

    isValid(): boolean {
        if (!this.receipts.length) {
            this.notificationsService.error('You must add at least one invoice.');
            return false;
        }

        return true;
    }

    storeDateValues(): void {
        if (this.searchDateTo && this.searchDateFrom) {
            sessionStorage.setItem('DateTo', this.searchDateTo.toString());
            sessionStorage.setItem('DateFrom', this.searchDateFrom.toString());
            sessionStorage.setItem('KeepDates', 'true');
        }
    }

    setDateValues(): void {
        if (sessionStorage.getItem('DateTo')) {
            this.searchDateTo = new Date(sessionStorage.getItem('DateTo'));
        } else {
            this.searchDateTo = null;
        }
        if (sessionStorage.getItem('DateFrom')) {
            this.searchDateFrom = new Date(sessionStorage.getItem('DateFrom'));
        } else {
            this.searchDateFrom = null;
        }
        this.searchDateFromInput.value = this.searchDateFrom;
        this.searchDateToInput.value = this.searchDateTo;
        this.addReceiptNumbersByDate();
    }

    onSearchNumberFromChange(): void {
        this.receiptService.getLastDailyInvoiceNumber(this.searchNumberFrom).subscribe((number) => {
            if (number) {
                this.searchNumberTo = number;
            }
        });
    }

    addReceiptNumbersByNumber(): void {
        if (!this.searchNumberFrom || !this.searchNumberTo) {
            return;
        }

        this.receiptService.getInvoiceViewInNumberRange(this.searchNumberFrom, this.searchNumberTo).subscribe((answer) => {
            this.addReceipts(answer);
            this.addedByDateRange = false;
        });
    }

    addReceiptNumbersByDate(): void {
        if (!this.searchDateFrom || !this.searchDateTo) {
            return;
        }

        this.receiptService.getInvoiceViewInDateRange(this.searchDateFrom, this.searchDateTo).subscribe((answer) => {
            this.addReceipts(answer);
            this.addedByDateRange = true;
        });
    }

    addReceipts(receipts: IReceiptInvoiceView[]): void {
        receipts.forEach((receipt) => {
            if (!this.receipts.some((item) => item.ReceiptId === receipt.ReceiptId)) {
                this.receipts.push(receipt);
            }
        });
    }

    updateReceipts(): void {
        this.receiptService.getAll().subscribe((answer) => {
            this.receipts.forEach((receipt) => {
                let index = answer.findIndex((x) => (x.Id = receipt.ReceiptId));
                if (index >= 0) {
                    receipt.DateSent = answer[index].DateSentUtc;
                }
            });
        });
    }

    printReceipts(): void {
        if (!this.isValid()) {
            this.enableDoubleClick();
            return;
        }
        let receiptsToPrint = this.receipts;

        this.receiptService
            .getAllReceiptsProcessed(receiptsToPrint.map((receipt) => receipt.ReceiptId))
            .subscribe((allReceiptsProcessed) => {
                if (!allReceiptsProcessed) {
                    this.modalService
                        .showModal({
                            html: `
                            ${receiptsToPrint.length ? '<p>' + receiptsToPrint.length + ' invoice(s) will be printed.</p>' : ''}
                        `,
                            showCancelButton: true,
                            title: `Are you sure you want to process these invoices?`,
                            type: 'warning',
                        })
                        .subscribe((result) => {
                            if (!result.value) {
                                this.enableDoubleClick();
                                return;
                            }

                            this.printUtil(receiptsToPrint);
                        });
                } else {
                    this.printUtil(receiptsToPrint);
                }
            });
    }

    private printUtil(receiptsToPrint: IReceiptInvoiceView[]): void {
        if (!receiptsToPrint.length) {
            this.enableDoubleClick();
            return;
        }
        if (this.searchDateFromInput.value || this.searchDateToInput.value) {
            this.storeDateValues();
        }

        this.isPrinting = true;
        this.receiptService
            .printReceipts(
                receiptsToPrint.map((receipt) => receipt.ReceiptId),
                true,
                false,
                true,
            )
            .pipe(
                finalize(() => {
                    this.isPrinting = false;
                    this.enableDoubleClick();
                }),
            )
            .subscribe((pdfBase64) => {
                this.notificationsService.success(`Successfully printed ${receiptsToPrint.length} invoice(s).`);
                PrintService.printPdf(pdfBase64);
            });
    }

    emailReceipts(): void {
        let receiptsToEmail = this.receipts;
        this.storeDateValues();
        if (!receiptsToEmail.length) {
            this.enableDoubleClick();
            return;
        }
        this.isEmailing = true;
        this.receiptService
            .emailReceipts(
                receiptsToEmail.map((receipt) => receipt.ReceiptId),
                true,
            )
            .pipe(
                finalize(() => {
                    this.isEmailing = false;
                    this.enableDoubleClick();
                }),
            )
            .subscribe((answer) => {
                this.updateReceipts();
                this.notificationsService.success(`Successfully emailed ${receiptsToEmail.length} invoice(s).`);
            });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancel(): void {
        this.clearForm();
    }

    navigateToBillingSheetAdjustments(): void {
        void this.router.navigate(['receipts', 'billing-sheet-adjustments']);
    }
}
