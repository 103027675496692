import { Directive, HostListener, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { round } from '@mt-ng2/numeric-control';

@Directive({
    selector: '[decimalField]',
})
export class DecimalFieldDirective {
    @Input() scale = 0;
    @Input() doNotAllowZero = false;

    get control(): FormControl {
        return this.ngControl.control as FormControl;
    }

    constructor(private ngControl: NgControl) {}

    @HostListener('blur')
    onBlur(): void {
        this.control.setValue(this._validateValueFromInputAndRound(this.control.value));
    }

    private _validateValueFromInputAndRound(value: number): number {
        if (isNaN(value)) {
            value = null;
        } else if (typeof value === 'string') {
            const isEmptyString = (value as string).trim() === '';
            if (isEmptyString) {
                value = null;
            } else {
                value = +value; // convert to number
            }
        }
        if (value) {
            value = round(value, this.scale);
        }
        if ((!value && value !== 0) || (this.doNotAllowZero && value === 0)) {
            value = null;
        }
        return value;
    }
}
