<div>
    <div class="row">
        <div class="col-md-7">
            <h2>{{ isAdding ? 'Add New Trailer' : 'Trailer Info' }}</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="row">
    <div class="col-md-6">
        <form class="miles-form padded" [formGroup]="trailerInfoForm" (ngSubmit)="formSubmitted()">
            <h4>Trailer</h4>
            <dynamic-field [field]="abstractTrailerControls.Number" [form]="trailerInfoForm"></dynamic-field>
            <div class="row">
                <div class="col-md-4">
                    <dynamic-field [field]="abstractTrailerControls.LightWeight" [form]="trailerInfoForm"></dynamic-field>
                </div>
                <div class="col-md-4">
                    <dynamic-field [field]="abstractTrailerControls.HeavyWeight" [form]="trailerInfoForm"></dynamic-field>
                </div>
                <div class="col-md-4">
                    <dynamic-field [field]="abstractTrailerControls.Length" [form]="trailerInfoForm"></dynamic-field>
                </div>
            </div>
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                Cancel
            </button>
        </form>
    </div>
</div>
