import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class MillComplaintsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Id',
                }),
                new EntityListColumn({
                    accessorFunction: (millComplaint: IMillComplaint) => {
                        return millComplaint.MillComplaintCategory?.Name ?? 'N/A';
                    },
                    name: 'Complaint Category',
                    sort: {
                        sortProperty: 'MillComplaintCategory.Name',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ComplaintDate'],
                    name: 'Complaint Date',
                    pipes: ['date'],
                    sort: {
                        defaultDirection: SortDirection.Desc,
                        isDefaultForSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (millComplaint: IMillComplaint) => {
                        return `${millComplaint.User.FirstName} ${millComplaint.User.LastName}`;
                    },
                    name: 'Taken By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ComplaintReportedBy'],
                    name: 'Reported By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DescriptionOfProblem'],
                    name: 'Description',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (millComplaint: IMillComplaint) => {
                        return millComplaint.IsCreditIssued ? 'Yes' : 'No';
                    },
                    name: 'Was Credit Issued?',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CreditIssued'],
                    name: 'Credit Issued',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    name: 'Resolution',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
