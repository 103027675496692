import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { LoginOldStyleComponent, ForgotPasswordOldStyleComponent, ResetPasswordOldStyleComponent, AdminAccessComponent } from '@mt-ng2/login-module';
import { AppSettingsComponent } from './app-settings..component';
import { ClaimTypes } from './model/ClaimTypes';
import { UserService } from './users/user.service';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { appPaths } from '@routes/app-paths.library';
import { RouteToLandingPageGuard } from '@common/guards/landing-page-redirect';
import { HomePageOverrideGuard } from '@common/guards/home-redirect';

const userEntityConfig: IEntityRouteConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Users,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: '',
    service: UserService,
};

const appRoutes: Routes = [
    { path: 'login', component: LoginOldStyleComponent },
    { path: 'forgotpassword', component: ForgotPasswordOldStyleComponent },
    { path: 'resetpassword', component: ResetPasswordOldStyleComponent },
    { path: 'adminaccess', component: AdminAccessComponent },
    {
        canActivate: [RouteToLandingPageGuard],
        component: UserDetailComponent,
        path: appPaths.home,
    },
    { path: 'settings', component: AppSettingsComponent, canActivate: [AuthGuard] },
    { path: '**', component: UserDetailComponent, canActivate: [HomePageOverrideGuard], data: userEntityConfig },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRoutingModule {}
