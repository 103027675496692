import { Component, OnInit } from '@angular/core';
import { SalesOrderService } from '../sales-order.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PrintService } from '@common/services/print.service';
import { IDailyRunSalesOrder } from '@model/interfaces/daily-run-sales-order';
import { DateService } from '@common/services/date.service';

interface IProductionData  {
    DailyRunSalesOrders: IDailyRunSalesOrder[];
    ConvertingData: any[];
}

@Component({
    selector: 'app-view-production',
    styles: [
        `
            .centered-container {
                align-items: center;
                display: flex;
                height: 10vh;
                justify-content: center;
            }

            .centered-container i {
                font-size: 22px;
            }

            .custom-button-container {
                display: flex;
                justify-content: flex-end;
            }
        `,
    ],
    templateUrl: './view-production.component.html',

})
export class ViewProductionComponent implements OnInit {
    productionData: IProductionData = { DailyRunSalesOrders : [], ConvertingData: [] };
    salesOrderId: number;

    doubleClickIsDisabled = false;

    constructor(
        private salesOrderService: SalesOrderService,
        private activatedRoute: ActivatedRoute,
        private dateService: DateService) {}

    ngOnInit(): void {
        const salesOrderId = this.activatedRoute.snapshot.paramMap.get('salesOrderId');
        if (salesOrderId) {
            this.salesOrderId = +salesOrderId;
        }

        this.salesOrderService.getProductionDataForOrder(this.salesOrderId).subscribe(
            (data: any) => {
                this.productionData = {
                    ...data,
                    ConvertingData: data.ConvertingData.map((item) => ({
                        ...item,
                        Date: this.dateService.formatRunDate(item.Date),
                    })),
                    DailyRunSalesOrders: data.DailyRunSalesOrders.map((order) => ({
                        ...order,
                       DailyRunEntry: {
                        ...order.DailyRunEntry,
                        RunDate: this.dateService.formatRunDate(order.DailyRunEntry.RunDate),
                       },
                    })),
                };
            },
            (error) => {
                console.error('Error fetching production data:', error);
            },
        );
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }
    print(): void {
        this.salesOrderService
            .generateProductionDataForOrder(this.salesOrderId)
            .pipe(finalize(() => this.enableDoubleClick()))
            .subscribe((answer) => {
                PrintService.printPdf(answer);
            });
    }

}
