import { ClaimTypes } from '@model/ClaimTypes';
import { IEntitySlugs } from '@model/interfaces/custom/entity-slugs';
import { ClaimValues } from '@mt-ng2/auth-module';

export const millCorpInvoicePathSlugs: IEntitySlugs = {
    add: 'add',
    entityIdParam: 'millCorpInvoiceId',
    entityName: 'Mill Corp Invoice',
    root: 'mill-corp-invoice',
};

export const millCorpInvoicePaths = {
    millCorpInvoice: millCorpInvoicePathSlugs.root,
};

export const millCorpInvoiceRoleGuard = {
    claimType: ClaimTypes.MillCorpInvoice,
    claimValues: [ClaimValues.FullAccess, ClaimValues.ReadOnly],
};

export const millCorpSettingsRoleGuard = {
    claimType: ClaimTypes.MillCorpInvoice,
    claimValues: [ClaimValues.FullAccess],
};
