import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IGeneralLedgerCode } from '../interfaces/general-ledger-code';

export interface IGeneralLedgerCodeDynamicControlsParameters {
    formGroup?: string;
}

export class GeneralLedgerCodeDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private generalledgercode?: IGeneralLedgerCode, additionalParameters?: IGeneralLedgerCodeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GeneralLedgerCode';

        this.Form = {
            Code: new DynamicField({
                formGroup: this.formGroup,
                label: 'Code',
                name: 'Code',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('Code') && this.generalledgercode.Code != null ? this.generalledgercode.Code.toString() : '',
            }),
            GlName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gl Name',
                name: 'GlName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('GlName') && this.generalledgercode.GlName != null ? this.generalledgercode.GlName.toString() : '',
            }),
            IsStock: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Stock',
                name: 'IsStock',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('IsStock') && this.generalledgercode.IsStock != null ? this.generalledgercode.IsStock : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('Name') && this.generalledgercode.Name != null ? this.generalledgercode.Name.toString() : '',
            }),
            QbItemName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qb Item Name',
                name: 'QbItemName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('QbItemName') && this.generalledgercode.QbItemName != null ? this.generalledgercode.QbItemName.toString() : '',
            }),
            TonsAndPricing: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tons And Pricing',
                name: 'TonsAndPricing',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.generalledgercode && this.generalledgercode.TonsAndPricing || null,
            }),
        };

        this.View = {
            Code: new DynamicLabel({
                label: 'Code',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('Code') && this.generalledgercode.Code != null ? this.generalledgercode.Code.toString() : '',
            }),
            GlName: new DynamicLabel({
                label: 'Gl Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('GlName') && this.generalledgercode.GlName != null ? this.generalledgercode.GlName.toString() : '',
            }),
            IsStock: new DynamicLabel({
                label: 'Is Stock',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('IsStock') && this.generalledgercode.IsStock != null ? this.generalledgercode.IsStock : false,
            }),
            Name: new DynamicLabel({
                label: 'Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('Name') && this.generalledgercode.Name != null ? this.generalledgercode.Name.toString() : '',
            }),
            QbItemName: new DynamicLabel({
                label: 'Qb Item Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.hasOwnProperty('QbItemName') && this.generalledgercode.QbItemName != null ? this.generalledgercode.QbItemName.toString() : '',
            }),
            TonsAndPricing: new DynamicLabel({
                label: 'Tons And Pricing',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.generalledgercode && this.generalledgercode.TonsAndPricing || null,
            }),
        };

    }
}
