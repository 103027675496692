import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { ContextMenuModule } from 'ngx-contextmenu';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';

import { CustomerModule } from './customers/customer.module';
import { SalesOrdersModule } from './sales-orders/sales-order.module';
import { UserModule } from './users/user.module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';
import { UserRoleModule } from './user-roles/user-roles.module';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { AppSettingsComponent } from './app-settings..component';
import { AppSettingsService } from './app-settings.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ContactModule } from './contacts/contact.module';
import { TrimSheetModule } from './trim-sheets/trim-sheet.module';
import { TestScanModule } from './test-scans/test-scan.module';
import { SkidModule } from './skids/skid.module';
import { ShipmentModule } from './shipments/shipment.module';
import { ReceiptsModule } from './receipts/receipts.module';
import { ConvertingDataModule } from './converting-data/converting-data.module';
import { LoginConfigOverride } from './common/configs/login.config';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AppNavModule } from './nav/app-nav.module';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { ScalesModule } from './scales/scales.module';
import { ScannersModule } from './scanners/scanners.module';
import { MillCorpModule } from './mill-corp/mill-corp.module';
import { DailyRunEntryModule } from './daily-run-entry/daily-run-entry.module';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, AppSettingsComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule.forRoot(),
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule.forRoot(environment),
        AuthModule.forRoot(),
        CustomerModule.forRoot(),
        SalesOrdersModule.forRoot(),
        TrimSheetModule.forRoot(),
        ScalesModule.forRoot(),
        ScannersModule.forRoot(),
        TestScanModule.forRoot(),
        SkidModule.forRoot(),
        ShipmentModule.forRoot(),
        ReceiptsModule.forRoot(),
        ConvertingDataModule.forRoot(),
        UserModule.forRoot(),
        UserRoleModule.forRoot(),
        ContactModule.forRoot(),
        MillCorpModule.forRoot(),
        DailyRunEntryModule.forRoot(),
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        KeyboardShortcutModule,
        ContextMenuModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        AppSettingsService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
    ],
})
export class AppModule {}
