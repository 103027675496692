import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { ICustomerCreditStatus } from '../interfaces/customer-credit-status';
import { IAddress } from '../interfaces/address';
import { IOrderCustomerSpecification } from '../interfaces/order-customer-specification';
import { ICustomerPaymentTerm } from '../interfaces/customer-payment-term';
import { IUser } from '../interfaces/user';
import { IRunnerDirectionType } from '../interfaces/runner-direction-type';
import { IShipOnType } from '../interfaces/ship-on-type';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    invoiceAddresses?: IAddress[];
    paymentTerms?: ICustomerPaymentTerm[];
    creditStatuses?: ICustomerCreditStatus[];
    primarySalesPeople?: IUser[];
    secondarySalesPeople?: IUser[];
    shipOnTypes?: IShipOnType[];
    runnerDirectionTypes?: IRunnerDirectionType[];
    orderCustomerSpecifications?: IOrderCustomerSpecification[];
}

export class CustomerDynamicControls {

    formGroup: string;
    invoiceAddresses: IAddress[];
    paymentTerms: ICustomerPaymentTerm[];
    creditStatuses: ICustomerCreditStatus[];
    primarySalesPeople: IUser[];
    secondarySalesPeople: IUser[];
    shipOnTypes: IShipOnType[];
    runnerDirectionTypes: IRunnerDirectionType[];
    orderCustomerSpecifications: IOrderCustomerSpecification[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Customer';
        this.invoiceAddresses = additionalParameters && additionalParameters.invoiceAddresses || undefined;
        this.paymentTerms = additionalParameters && additionalParameters.paymentTerms || undefined;
        this.creditStatuses = additionalParameters && additionalParameters.creditStatuses || undefined;
        this.primarySalesPeople = additionalParameters && additionalParameters.primarySalesPeople || undefined;
        this.secondarySalesPeople = additionalParameters && additionalParameters.secondarySalesPeople || undefined;
        this.shipOnTypes = additionalParameters && additionalParameters.shipOnTypes || undefined;
        this.runnerDirectionTypes = additionalParameters && additionalParameters.runnerDirectionTypes || undefined;
        this.orderCustomerSpecifications = additionalParameters && additionalParameters.orderCustomerSpecifications || undefined;

        this.Form = {
            ApexShippingManifest: new DynamicField({
                formGroup: this.formGroup,
                label: 'Apex Shipping Manifest',
                name: 'ApexShippingManifest',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('ApexShippingManifest') && this.customer.ApexShippingManifest !== null ? this.customer.ApexShippingManifest : false,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived !== null ? this.customer.Archived : false,
            }),
            Code: new DynamicField({
                formGroup: this.formGroup,
                label: 'Code',
                name: 'Code',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(6) ],
                validators: { 'maxlength': 6 },
                value: this.customer && this.customer.hasOwnProperty('Code') && this.customer.Code !== null ? this.customer.Code.toString() : '',
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('CompanyName') && this.customer.CompanyName !== null ? this.customer.CompanyName.toString() : '',
            }),
            CreditStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Status',
                name: 'CreditStatusId',
                options: this.creditStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CreditStatusId || 1,
            }),
            CustomerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer Name',
                name: 'CustomerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('CustomerName') && this.customer.CustomerName !== null ? this.customer.CustomerName.toString() : '',
            }),
            InchesBetweenRunners: new DynamicField({
                formGroup: this.formGroup,
                label: 'Inches Between Runners',
                name: 'InchesBetweenRunners',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('InchesBetweenRunners') && this.customer.InchesBetweenRunners !== null ? this.customer.InchesBetweenRunners.toString() : '',
            }),
            InvoiceAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Address',
                name: 'InvoiceAddressId',
                options: this.invoiceAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customer && this.customer.InvoiceAddressId || null,
            }),
            InvoiceEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Email',
                name: 'InvoiceEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.customer && this.customer.hasOwnProperty('InvoiceEmail') && this.customer.InvoiceEmail !== null ? this.customer.InvoiceEmail.toString() : '',
            }),
            InvoiceName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Name',
                name: 'InvoiceName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('InvoiceName') && this.customer.InvoiceName !== null ? this.customer.InvoiceName.toString() : '',
            }),
            InvoiceNote: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Note',
                name: 'InvoiceNote',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('InvoiceNote') && this.customer.InvoiceNote !== null ? this.customer.InvoiceNote.toString() : '',
            }),
            IsAuthorizedForCreditCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Authorized For Credit Card',
                name: 'IsAuthorizedForCreditCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('IsAuthorizedForCreditCard') && this.customer.IsAuthorizedForCreditCard !== null ? this.customer.IsAuthorizedForCreditCard : false,
            }),
            OrderCustomerSpecificationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Customer Specification',
                name: 'OrderCustomerSpecificationId',
                options: this.orderCustomerSpecifications,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.OrderCustomerSpecificationId || null,
            }),
            PaymentTermId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Payment Term',
                name: 'PaymentTermId',
                options: this.paymentTerms,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customer && this.customer.PaymentTermId || null,
            }),
            PrimarySalesPersonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Primary Sales Person',
                name: 'PrimarySalesPersonId',
                options: this.primarySalesPeople,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.PrimarySalesPersonId || null,
            }),
            RunnerDirectionTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Runner Direction Type',
                name: 'RunnerDirectionTypeId',
                options: this.runnerDirectionTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.RunnerDirectionTypeId || null,
            }),
            RunnerHeight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Runner Height',
                name: 'RunnerHeight',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('RunnerHeight') && this.customer.RunnerHeight !== null ? this.customer.RunnerHeight.toString() : '',
            }),
            SecondarySalesPersonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Secondary Sales Person',
                name: 'SecondarySalesPersonId',
                options: this.secondarySalesPeople,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.SecondarySalesPersonId || null,
            }),
            ShipOnTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship On Type',
                name: 'ShipOnTypeId',
                options: this.shipOnTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customer && this.customer.ShipOnTypeId || null,
            }),
            ShipVia: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship Via',
                name: 'ShipVia',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.customer && this.customer.hasOwnProperty('ShipVia') && this.customer.ShipVia !== null ? this.customer.ShipVia.toString() : '',
            }),
            SpecialNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Special Notes',
                name: 'SpecialNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.customer && this.customer.hasOwnProperty('SpecialNotes') && this.customer.SpecialNotes !== null ? this.customer.SpecialNotes.toString() : '',
            }),
        };

        this.View = {
            ApexShippingManifest: new DynamicLabel(
                'Apex Shipping Manifest',
                this.customer && this.customer.hasOwnProperty('ApexShippingManifest') && this.customer.ApexShippingManifest !== null ? this.customer.ApexShippingManifest : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Archived: new DynamicLabel(
                'Archived',
                this.customer && this.customer.hasOwnProperty('Archived') && this.customer.Archived !== null ? this.customer.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Code: new DynamicLabel(
                'Code',
                this.customer && this.customer.hasOwnProperty('Code') && this.customer.Code !== null ? this.customer.Code.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CompanyName: new DynamicLabel(
                'Company Name',
                this.customer && this.customer.hasOwnProperty('CompanyName') && this.customer.CompanyName !== null ? this.customer.CompanyName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CreditStatusId: new DynamicLabel(
                'Credit Status',
                getMetaItemValue(this.creditStatuses, this.customer && this.customer.hasOwnProperty('CreditStatusId') && this.customer.CreditStatusId !== null ? this.customer.CreditStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CustomerName: new DynamicLabel(
                'Customer Name',
                this.customer && this.customer.hasOwnProperty('CustomerName') && this.customer.CustomerName !== null ? this.customer.CustomerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InchesBetweenRunners: new DynamicLabel(
                'Inches Between Runners',
                this.customer && this.customer.hasOwnProperty('InchesBetweenRunners') && this.customer.InchesBetweenRunners !== null ? this.customer.InchesBetweenRunners.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoiceAddressId: new DynamicLabel(
                'Invoice Address',
                getMetaItemValue(this.invoiceAddresses, this.customer && this.customer.hasOwnProperty('InvoiceAddressId') && this.customer.InvoiceAddressId !== null ? this.customer.InvoiceAddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoiceEmail: new DynamicLabel(
                'Invoice Email',
                this.customer && this.customer.hasOwnProperty('InvoiceEmail') && this.customer.InvoiceEmail !== null ? this.customer.InvoiceEmail.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoiceName: new DynamicLabel(
                'Invoice Name',
                this.customer && this.customer.hasOwnProperty('InvoiceName') && this.customer.InvoiceName !== null ? this.customer.InvoiceName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InvoiceNote: new DynamicLabel(
                'Invoice Note',
                this.customer && this.customer.hasOwnProperty('InvoiceNote') && this.customer.InvoiceNote !== null ? this.customer.InvoiceNote.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            ),
            IsAuthorizedForCreditCard: new DynamicLabel(
                'Is Authorized For Credit Card',
                this.customer && this.customer.hasOwnProperty('IsAuthorizedForCreditCard') && this.customer.IsAuthorizedForCreditCard !== null ? this.customer.IsAuthorizedForCreditCard : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OrderCustomerSpecificationId: new DynamicLabel(
                'Order Customer Specification',
                getMetaItemValue(this.orderCustomerSpecifications, this.customer && this.customer.hasOwnProperty('OrderCustomerSpecificationId') && this.customer.OrderCustomerSpecificationId !== null ? this.customer.OrderCustomerSpecificationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaymentTermId: new DynamicLabel(
                'Payment Term',
                getMetaItemValue(this.paymentTerms, this.customer && this.customer.hasOwnProperty('PaymentTermId') && this.customer.PaymentTermId !== null ? this.customer.PaymentTermId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PrimarySalesPersonId: new DynamicLabel(
                'Primary Sales Person',
                getMetaItemValue(this.primarySalesPeople, this.customer && this.customer.hasOwnProperty('PrimarySalesPersonId') && this.customer.PrimarySalesPersonId !== null ? this.customer.PrimarySalesPersonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RunnerDirectionTypeId: new DynamicLabel(
                'Runner Direction Type',
                getMetaItemValue(this.runnerDirectionTypes, this.customer && this.customer.hasOwnProperty('RunnerDirectionTypeId') && this.customer.RunnerDirectionTypeId !== null ? this.customer.RunnerDirectionTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RunnerHeight: new DynamicLabel(
                'Runner Height',
                this.customer && this.customer.hasOwnProperty('RunnerHeight') && this.customer.RunnerHeight !== null ? this.customer.RunnerHeight.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SecondarySalesPersonId: new DynamicLabel(
                'Secondary Sales Person',
                getMetaItemValue(this.secondarySalesPeople, this.customer && this.customer.hasOwnProperty('SecondarySalesPersonId') && this.customer.SecondarySalesPersonId !== null ? this.customer.SecondarySalesPersonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipOnTypeId: new DynamicLabel(
                'Ship On Type',
                getMetaItemValue(this.shipOnTypes, this.customer && this.customer.hasOwnProperty('ShipOnTypeId') && this.customer.ShipOnTypeId !== null ? this.customer.ShipOnTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipVia: new DynamicLabel(
                'Ship Via',
                this.customer && this.customer.hasOwnProperty('ShipVia') && this.customer.ShipVia !== null ? this.customer.ShipVia.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SpecialNotes: new DynamicLabel(
                'Special Notes',
                this.customer && this.customer.hasOwnProperty('SpecialNotes') && this.customer.SpecialNotes !== null ? this.customer.SpecialNotes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
