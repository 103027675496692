<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button></route-back-button>
                Stock Order
                <span *ngIf="salesOrder">#{{ salesOrder.OrderNumberWithPostFix }}</span>
                <span *ngIf="salesOrder?.Archived" class="text-danger">*ARCHIVED*</span>
            </h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated" class="col-md-12">
    <form class="miles-form padded row" [formGroup]="stockOrderAddForm" (ngSubmit)="formSubmitted()">
        <h4>Stock Order Info</h4>
        <div class="row">
            <div class="col-md-6">
                <div class="col-md-6">
                    <dynamic-field [field]="abstractOrderInfoControls.OrderDate" [form]="stockOrderAddForm"></dynamic-field>
                </div>
                <div class="col-md-12">
                    <div style="font-size: 24px">
                        <span>
                            <b>Customer:</b>
                        </span>
                        <br />
                        <span>{{ customer.CompanyName }}</span>
                        <br />
                        <span [innerHTML]="addressString"></span>
                    </div>
                    <br />
                    <dynamic-field [field]="abstractOrderInfoControls.CustomerPurchaseOrderNumber" [form]="stockOrderAddForm"></dynamic-field>
                    <dynamic-field [field]="abstractOrderInfoControls.CustomerTerms" [form]="stockOrderAddForm"></dynamic-field>
                </div>
                <div class="col-md-6">
                    <dynamic-field [field]="abstractOrderInfoControls.IsCreditCardPurchase" [form]="stockOrderAddForm"></dynamic-field>
                </div>
                <div class="col-md-6">
                    <label>Est. Total</label>
                    <br />
                    <input type="text" class="form-control" [value]="salesOrder?.Total" [disabled]="true" />
                </div>
                <div class="col-md-12">
                    <dynamic-field [field]="abstractOrderInfoControls.Notes" [form]="stockOrderAddForm"></dynamic-field>
                </div>
            </div>
            <div class="col-md-6">
                <div class="col-md-6">
                    <dynamic-field
                        *ngIf="canSetOrderStatus"
                        [field]="abstractSalesOrderControls.OrderStatusId"
                        [form]="stockOrderAddForm"
                    ></dynamic-field>
                </div>
                <div class="col-md-12">
                    <div>
                        <div *ngIf="customerShippingAddresses">
                            <label for="select-address">
                                <h3>Select a Shipping Address:</h3>
                            </label>
                            <select
                                [(ngModel)]="customerShippingAddressId"
                                id="select-address"
                                class="form-control"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option
                                    *ngFor="let address of customerShippingAddresses"
                                    [ngValue]="address.Id"
                                    [selected]="address.IsPrimary ? true : null"
                                    [disabled]="address.Archived"
                                >
                                    <span>{{ address | shippingAddress : 'name' : 'primary' : 'archived' }}</span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <dynamic-field [field]="abstractOrderInfoControls.CustomerShipVia" [form]="stockOrderAddForm"></dynamic-field>
                    <dynamic-field [field]="abstractOrderInfoControls.SpecialInstructions" [form]="stockOrderAddForm"></dynamic-field>
                    <dynamic-field [field]="abstractOrderInfoControls.RunWeekOf" [form]="stockOrderAddForm"></dynamic-field>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <hr />
            <button
                *ngIf="!formIsLocked"
                type="submit"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success"
            >
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            <button
                *ngIf="salesOrder && canArchive"
                [disabled]="loadedOnShipment"
                title="{{ loadedOnShipment ? 'already shipped' : '' }}"
                type="button"
                class="btn btn-flat"
                [ngClass]="salesOrder.Archived ? 'btn-success' : 'btn-danger'"
                (click)="toggleSalesOrderArchived()"
            >
                {{ salesOrder.Archived ? 'Un-Archive' : 'Archive' }}
            </button>
            <button type="button" *ngIf="salesOrder && !formIsLocked" class="btn btn-flat btn-default" (click)="availableInventory()">
                View Available Inventory
            </button>
            <button type="button" *ngIf="salesOrder" class="btn btn-flat btn-default" (click)="showPrintModal()">
                <i class="fa fa-fw fa-print"></i>
                &nbsp;Print Forms
            </button>
            <button type="button" *ngIf="customerIsCreditHold && canProvideCreditAuthorization" class="btn btn-flat btn-default" (click)="showCreditAuthModal()">
                Credit Authorization
            </button>
        </div>
        <div *ngIf="stockOrderLines && stockOrderLines.length" class="col-md-12">
            <h4>Items in this Order:</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th>Stock Id</th>
                        <th>Lot #</th>
                        <th>Qty</th>
                        <th>Width</th>
                        <th>Length</th>
                        <th>Caliper</th>
                        <th>Type</th>
                        <th>Color<br />(Top/Bottom)</th>
                        <th>Location</th>
                        <th>Price</th>
                        <th>General Ledger Code</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of stockOrderLines; let i = index">
                        <td>{{ line.StockItem.StockIdNumber }}</td>
                        <td>{{ line.StockItem.LotNumber }}</td>
                        <td>{{ getTruncatedBundles(line.StockItem.Bundles) }}</td>
                        <td>{{ line.StockItem.Width }}</td>
                        <td>{{ line.StockItem.Length }}</td>
                        <td>{{ line.StockItem.CaliperMax }}</td>
                        <td>{{ line.StockItem.BoardGrade?.Abbreviation }}</td>
                        <td>{{ formatStockItemColor(line) }}</td>
                        <td>{{ line.StockItem.WarehouseLocation?.Name }}</td>
                        <td>{{ line.UnitPrice | currency }}</td>
                        <td>{{ line.GeneralLedgerCode?.Code }} - {{ line.GeneralLedgerCode?.Name }}</td>
                        <td>
                            <button *ngIf="!line.ShipmentId" type="button" class="btn btn-danger" (click)="deleteStockOrderLine(line, i)">
                                Remove
                            </button>
                            <i *ngIf="line.ShipmentId" class="m-l-sm">
                                <a *ngIf="getShipmentDate(line.Shipment)" (click)="showPrintStockLinePopup(line.Shipment)">
                                    {{ getShipmentDate(line.Shipment) | date : 'MM/dd/yyyy' + " - " + line.Shipment.Id }}
                                </a>
                                <span *ngIf="!getShipmentDate(line.Shipment)">{{ line.Shipment.Id }}</span>
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</div>

<modal-wrapper
    [options]="printModalOptions"
    [autoShow]="false"
    title="Printed Forms"
    (ready)="printModal = $event"
    (okClick)="printForms()"
    (cancelClick)="closePrintModal()"
>
    <div class="text-left">
        <h5>Which form(s) would you like to print?</h5>
        <input id="pickTicketCopy" type="checkbox" [(ngModel)]="printFormsOptions.StockPickingTicket" />
        <label for="pickTicketCopy" class="font-weight-normal small">Pick Ticket</label>
        <br />
        <input id="fileCopy" type="checkbox" [(ngModel)]="printFormsOptions.StockFileCopy" />
        <label for="fileCopy" class="font-weight-normal small">File Copy</label>
        <br />
        <input id="shippersCopy" type="checkbox" [(ngModel)]="printFormsOptions.StockShippersCopy" />
        <label for="shippersCopy" class="font-weight-normal small">Shipping</label>
        <br />
        <input id="officeCopy" type="checkbox" [(ngModel)]="printFormsOptions.StockOfficeCopy" />
        <label for="officeCopy" class="font-weight-normal small">Office</label>
        <br />
        <input id="acknowledgementCopy" type="checkbox" [(ngModel)]="printFormsOptions.StockAcknowledgementCopy" />
        <label for="acknowledgementCopy" class="font-weight-normal small">Acknowledgement</label>
        <br />
        <input id="stockItemList" type="checkbox" [(ngModel)]="printFormsOptions.StockItemList" />
        <label for="stockItemList" class="font-weight-normal small">Stock Item List</label>
    </div>
</modal-wrapper>

<modal-wrapper
    *ngIf="salesOrder"
    [options]="creditAuthModalOptions"
    [autoShow]="false"
    (ready)="creditAuthModal = $event"
    title="Credit Authorization"
    (okClick)="authorizeOrder()"
    (cancelClick)="closeCreditAuthModal()"
>
    <div class="text-left">
        <h4>Authorization Date: {{ salesOrder.CreditAuthDate | date : 'short' }}</h4>
    </div>
</modal-wrapper>

<modal-wrapper
    [options]="printStockModalOptions"
    [autoShow]="false"
    title="Printed Forms"
    (ready)="printStockModal = $event"
    (okClick)="printStockForms()"
    (cancelClick)="closeStockPrintModal()"
>
    <div class="text-left">
        <h5>Which form(s) would you like to print?</h5>
        <input id="pickTicketCopy" type="checkbox" [(ngModel)]="printStockFormsOptions.Receipt" />
        <label for="pickTicketCopy" class="font-weight-normal small">Receipt</label>
        <br />
        <input id="fileCopy" type="checkbox" [(ngModel)]="printStockFormsOptions.Invoice" [disabled]="!printStockFormsOptions.InvoiceIsAvailable" />
        <label for="fileCopy" class="font-weight-normal small">Invoice</label>
    </div>
</modal-wrapper>
