import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { MillComplaintService } from '../services/mill-complaint.service';
import { IMillComplaint } from '@model/interfaces/mill-complaint';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    templateUrl: './mill-complaint-detail.component.html',
})
export class MillComplaintDetailComponent implements OnInit {
    millComplaint: IMillComplaint;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private millComplaintService: MillComplaintService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.MillComplaints, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;

        const id = +this.route.snapshot.paramMap.get('millComplaintId');

        if (id) {
            this.getMillComplaintById(id);
        } else {
            void this.router.navigate(['..'], { relativeTo: this.route.parent });
        }
    }

    getMillComplaintById(id: number): void {
        this.millComplaintService.getById(id).subscribe((millComplaint) => {
            if (millComplaint === null) {
                this.notificationsService.error('MillComplaint not found');
                void this.router.navigate(['..'], { relativeTo: this.route.parent });
            }
            this.millComplaint = millComplaint;
        });
    }
}
