<div *ngIf="customerPricingItems.length">
    <h2>Pricing Item: {{ customerPricingItems[0].BoardGrade.Name }}</h2>
    <div class="miles-card padded">
        <h4>History</h4>
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Date Effective</th>
                    <th>View Note</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of customerPricingItems; let i = index">
                    <td>{{ item.BoardGrade.Name }}</td>
                    <td>{{ item.Price | currency }}</td>
                    <td>{{ item.DateEffective | date }}</td>
                    <td (click)="viewNote(item.Notes)">Note</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="!customerPricingItems.length">
    <h4 style="text-align: center;">No Data to display</h4>
</div>
<br />
<a routerLink="/customers/{{ customerId }}/pricing" class="btn btn-default">Back</a>
