import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IMillComplaint } from '../interfaces/mill-complaint';
import { IMillComplaintCategory } from '../interfaces/mill-complaint-category';
import { IQuantityAffectedType } from '../interfaces/quantity-affected-type';
import { ISalesOrder } from '../interfaces/sales-order';

export interface IMillComplaintDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    quantityAffecteds?: IQuantityAffectedType[];
    quantityAffectedTypes?: IQuantityAffectedType[];
    millComplaintCategories?: IMillComplaintCategory[];
}

export class MillComplaintDynamicControls {

    formGroup: string;
    salesOrders?: ISalesOrder[];
    quantityAffecteds?: IQuantityAffectedType[];
    quantityAffectedTypes?: IQuantityAffectedType[];
    millComplaintCategories?: IMillComplaintCategory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private millcomplaint?: IMillComplaint, additionalParameters?: IMillComplaintDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'MillComplaint';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.quantityAffecteds = additionalParameters && additionalParameters.quantityAffecteds || undefined;
        this.quantityAffectedTypes = additionalParameters && additionalParameters.quantityAffectedTypes || undefined;
        this.millComplaintCategories = additionalParameters && additionalParameters.millComplaintCategories || undefined;

        this.Form = {
            ComplaintDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complaint Date',
                name: 'ComplaintDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.ComplaintDate || null,
            }),
            ComplaintRecorded: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complaint Recorded',
                name: 'ComplaintRecorded',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('ComplaintRecorded') && this.millcomplaint.ComplaintRecorded != null ? this.millcomplaint.ComplaintRecorded : false,
            }),
            ComplaintReportedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complaint Reported By',
                name: 'ComplaintReportedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('ComplaintReportedBy') && this.millcomplaint.ComplaintReportedBy != null ? this.millcomplaint.ComplaintReportedBy.toString() : '',
            }),
            ComplaintTakenBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complaint Taken By',
                name: 'ComplaintTakenBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.ComplaintTakenBy || null,
            }),
            CreditIssued: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Issued',
                name: 'CreditIssued',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('CreditIssued') && this.millcomplaint.CreditIssued != null ? this.millcomplaint.CreditIssued.toString() : '',
            }),
            DescriptionOfProblem: new DynamicField({
                formGroup: this.formGroup,
                label: 'Description Of Problem',
                name: 'DescriptionOfProblem',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('DescriptionOfProblem') && this.millcomplaint.DescriptionOfProblem != null ? this.millcomplaint.DescriptionOfProblem.toString() : '',
            }),
            InvoiceDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Date',
                name: 'InvoiceDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.InvoiceDate || null,
            }),
            InvoiceNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice Number',
                name: 'InvoiceNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.InvoiceNumber || null,
            }),
            IsBoardKept: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Board Kept',
                name: 'IsBoardKept',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardKept') && this.millcomplaint.IsBoardKept != null ? this.millcomplaint.IsBoardKept : false,
            }),
            IsBoardRemade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Board Remade',
                name: 'IsBoardRemade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardRemade') && this.millcomplaint.IsBoardRemade != null ? this.millcomplaint.IsBoardRemade : false,
            }),
            IsBoardReturned: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Board Returned',
                name: 'IsBoardReturned',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardReturned') && this.millcomplaint.IsBoardReturned != null ? this.millcomplaint.IsBoardReturned : false,
            }),
            IsCreditIssued: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Credit Issued',
                name: 'IsCreditIssued',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsCreditIssued') && this.millcomplaint.IsCreditIssued != null ? this.millcomplaint.IsCreditIssued : false,
            }),
            IssueCreditMemo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Issue Credit Memo',
                name: 'IssueCreditMemo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IssueCreditMemo') && this.millcomplaint.IssueCreditMemo != null ? this.millcomplaint.IssueCreditMemo : false,
            }),
            MillComplaintCategoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mill Complaint Category',
                name: 'MillComplaintCategoryId',
                options: this.millComplaintCategories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.MillComplaintCategoryId || null,
            }),
            Other: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other',
                name: 'Other',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('Other') && this.millcomplaint.Other != null ? this.millcomplaint.Other.toString() : '',
            }),
            QuantityAffected: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quantity Affected',
                name: 'QuantityAffected',
                options: this.quantityAffecteds,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.QuantityAffected || null,
            }),
            QuantityAffectedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quantity Affected Type',
                name: 'QuantityAffectedTypeId',
                options: this.quantityAffectedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.QuantityAffectedTypeId || null,
            }),
            Resolution: new DynamicField({
                formGroup: this.formGroup,
                label: 'Resolution',
                name: 'Resolution',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('Resolution') && this.millcomplaint.Resolution != null ? this.millcomplaint.Resolution.toString() : '',
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.millcomplaint && this.millcomplaint.SalesOrderId || null,
            }),
        };

        this.View = {
            ComplaintDate: new DynamicLabel({
                label: 'Complaint Date',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.ComplaintDate || null,
            }),
            ComplaintRecorded: new DynamicLabel({
                label: 'Complaint Recorded',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('ComplaintRecorded') && this.millcomplaint.ComplaintRecorded != null ? this.millcomplaint.ComplaintRecorded : false,
            }),
            ComplaintReportedBy: new DynamicLabel({
                label: 'Complaint Reported By',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('ComplaintReportedBy') && this.millcomplaint.ComplaintReportedBy != null ? this.millcomplaint.ComplaintReportedBy.toString() : '',
            }),
            ComplaintTakenBy: new DynamicLabel({
                label: 'Complaint Taken By',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.ComplaintTakenBy || null,
            }),
            CreditIssued: new DynamicLabel({
                label: 'Credit Issued',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('CreditIssued') && this.millcomplaint.CreditIssued != null ? this.millcomplaint.CreditIssued.toString() : '',
            }),
            DescriptionOfProblem: new DynamicLabel({
                label: 'Description Of Problem',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('DescriptionOfProblem') && this.millcomplaint.DescriptionOfProblem != null ? this.millcomplaint.DescriptionOfProblem.toString() : '',
            }),
            InvoiceDate: new DynamicLabel({
                label: 'Invoice Date',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.InvoiceDate || null,
            }),
            InvoiceNumber: new DynamicLabel({
                label: 'Invoice Number',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.InvoiceNumber || null,
            }),
            IsBoardKept: new DynamicLabel({
                label: 'Is Board Kept',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardKept') && this.millcomplaint.IsBoardKept != null ? this.millcomplaint.IsBoardKept : false,
            }),
            IsBoardRemade: new DynamicLabel({
                label: 'Is Board Remade',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardRemade') && this.millcomplaint.IsBoardRemade != null ? this.millcomplaint.IsBoardRemade : false,
            }),
            IsBoardReturned: new DynamicLabel({
                label: 'Is Board Returned',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsBoardReturned') && this.millcomplaint.IsBoardReturned != null ? this.millcomplaint.IsBoardReturned : false,
            }),
            IsCreditIssued: new DynamicLabel({
                label: 'Is Credit Issued',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IsCreditIssued') && this.millcomplaint.IsCreditIssued != null ? this.millcomplaint.IsCreditIssued : false,
            }),
            IssueCreditMemo: new DynamicLabel({
                label: 'Issue Credit Memo',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('IssueCreditMemo') && this.millcomplaint.IssueCreditMemo != null ? this.millcomplaint.IssueCreditMemo : false,
            }),
            MillComplaintCategoryId: new DynamicLabel({
                label: 'Mill Complaint Category',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.millComplaintCategories as unknown as IMetaItem[], this.millcomplaint && this.millcomplaint.hasOwnProperty('MillComplaintCategoryId') ? this.millcomplaint.MillComplaintCategoryId : null) ?? '',
            }),
            Other: new DynamicLabel({
                label: 'Other',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('Other') && this.millcomplaint.Other != null ? this.millcomplaint.Other.toString() : '',
            }),
            QuantityAffected: new DynamicLabel({
                label: 'Quantity Affected',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.quantityAffecteds as unknown as IMetaItem[], this.millcomplaint && this.millcomplaint.hasOwnProperty('QuantityAffected') ? this.millcomplaint.QuantityAffected : null) ?? '',
            }),
            QuantityAffectedTypeId: new DynamicLabel({
                label: 'Quantity Affected Type',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.quantityAffectedTypes as unknown as IMetaItem[], this.millcomplaint && this.millcomplaint.hasOwnProperty('QuantityAffectedTypeId') ? this.millcomplaint.QuantityAffectedTypeId : null) ?? '',
            }),
            Resolution: new DynamicLabel({
                label: 'Resolution',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                value: this.millcomplaint && this.millcomplaint.hasOwnProperty('Resolution') && this.millcomplaint.Resolution != null ? this.millcomplaint.Resolution.toString() : '',
            }),
            SalesOrderId: new DynamicLabel({
                label: 'Sales Order',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                value: getMetaItemValue(this.salesOrders as unknown as IMetaItem[], this.millcomplaint && this.millcomplaint.hasOwnProperty('SalesOrderId') ? this.millcomplaint.SalesOrderId : null) ?? '',
            }),
        };

    }
}
