<div>
    <div class="row">
        <div class="col-sm-3 col-xs-3"></div>
        <div class="col-sm-6 col-xs-6">Order #</div>
        <div class="col-sm-3 col-xs-3" style="text-align: left"># Skids</div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="ordersForm" (ngSubmit)="formSubmitted()">
                <div formArrayName="OrdersAndQuantities">
                    <div
                        class="row order-and-quantity-row"
                        *ngFor="let oaq of ordersAndQuantities.controls; let i = index"
                        [ngClass]="{ error: oaq.errors || oaq.controls.TakeFromSalesOrderId.errors || oaq.controls.Quantity.errors }"
                    >
                        <ng-container [formGroup]="oaq">
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <button type="button" class="btn" (click)="clear(i)">Clear</button>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <input type="number" class="form-control" formControlName="TakeFromSalesOrderId" (ngModelChange)="updateValidation(ordersAndQuantities); checkDifferenceInSize(oaq, i);"/>
                                <span *ngIf="oaq.errors?.notUnique" class="error-text-container">Duplicate</span>
                                <span *ngIf="differInSizeErrors[i]" class="warning">Note: Skids from this order do not match in size</span>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <input type="number" class="form-control" formControlName="Quantity" />
                                <span *ngIf="oaq.errors?.notEnoughSkids" class="error-text-container"> {{ oaq.errors?.message }} </span>
                                <span *ngIf="oaq.controls.Quantity.errors?.min" class="error-text-container">Enter a positive integer</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="fab-wrap">
                    <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="addInput()">
                        <span class="fa fa-plus"></span>
                    </button>
                </div>
                <br />
                <br />
                <br />
                <button class="btn btn-default" type="submit">Take From</button>
            </form>
        </div>
    </div>
</div>
