import { CustomerDynamicControls } from '../form-controls/customer.form-controls';

import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

import { ICustomer } from '../interfaces/customer';
import { ICustomerCreditStatus } from '../interfaces/customer-credit-status';
import { ICustomerPaymentTerm } from '../interfaces/customer-payment-term';
import { IShipOnType } from '../interfaces/ship-on-type';
import { IUser } from '../interfaces/user';
import { IRunnerDirectionType } from '@model/interfaces/runner-direction-type';

export class CustomerDynamicControlsPartial extends CustomerDynamicControls {
    constructor(
        private customerPartial?: ICustomer,
        private creditstatusesPartial?: ICustomerCreditStatus[],
        private paymenttermsPartial?: ICustomerPaymentTerm[],
        private salespeoplePartial?: IUser[],
        private shipontypesPartial?: IShipOnType[],
        private runnerDirectionTypesPartial?: IRunnerDirectionType[],
    ) {
        super(customerPartial, {
            creditStatuses: creditstatusesPartial,
            formGroup: 'Customer',
            paymentTerms: paymenttermsPartial,
            primarySalesPeople: salespeoplePartial,
            runnerDirectionTypes: runnerDirectionTypesPartial,
            secondarySalesPeople: salespeoplePartial,
            shipOnTypes: shipontypesPartial,
        });

        this.Form.Code.formGroup = 'Customer';
        this.Form.IsAuthorizedForCreditCard.forGroup = 'Customer';
        this.Form.IsAuthorizedForCreditCard.value =
            this.customerPartial &&
            this.customerPartial.hasOwnProperty('IsAuthorizedForCreditCard') &&
            this.customerPartial.IsAuthorizedForCreditCard !== null
                ? this.customerPartial.IsAuthorizedForCreditCard.toString()
                : '';
        this.Form.IsAuthorizedForCreditCard.labelHtml = '<label>Authorized for Credit Card</label>';
        this.View.IsAuthorizedForCreditCard.label = 'Authorized for Credit Card';
        this.Form.CompanyName.labelHtml = '<label>Company Name (Must match with Quickbooks)</label>';
        this.Form.CreditStatusId.value =
            this.customerPartial && this.customerPartial.hasOwnProperty('CreditStatusId') && this.customerPartial.CreditStatusId !== null
                ? this.customerPartial.CreditStatusId
                : 1;
        this.Form.CreditStatusId.name = 'CreditStatusId';
        (<DynamicField>this.Form.CustomerName).setMinLength(3);
        (<DynamicField>this.Form.InvoiceNote).type.inputType = InputTypes.Textarea;
        this.Form.InvoiceName.labelHtml = '<label>Invoice Company Name</label>';
    }
}
