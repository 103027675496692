import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDailyRunEntry } from '../interfaces/daily-run-entry';

export interface IDailyRunEntryDynamicControlsParameters {
    formGroup?: string;
}

export class DailyRunEntryDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private dailyrunentry?: IDailyRunEntry, additionalParameters?: IDailyRunEntryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DailyRunEntry';

        this.Form = {
            Backtender: new DynamicField({
                formGroup: this.formGroup,
                label: 'Backtender',
                name: 'Backtender',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Backtender') && this.dailyrunentry.Backtender != null ? this.dailyrunentry.Backtender.toString() : '',
            }),
            Banders: new DynamicField({
                formGroup: this.formGroup,
                label: 'Banders',
                name: 'Banders',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Banders') && this.dailyrunentry.Banders != null ? this.dailyrunentry.Banders.toString() : '',
            }),
            Breaks: new DynamicField({
                formGroup: this.formGroup,
                label: 'Breaks',
                name: 'Breaks',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunentry && this.dailyrunentry.Breaks || null,
            }),
            Finishers: new DynamicField({
                formGroup: this.formGroup,
                label: 'Finishers',
                name: 'Finishers',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Finishers') && this.dailyrunentry.Finishers != null ? this.dailyrunentry.Finishers.toString() : '',
            }),
            Hoppers: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hoppers',
                name: 'Hoppers',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunentry && this.dailyrunentry.Hoppers || null,
            }),
            RunDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Run Date',
                name: 'RunDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunentry && this.dailyrunentry.RunDate || null,
            }),
            Setups: new DynamicField({
                formGroup: this.formGroup,
                label: 'Setups',
                name: 'Setups',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunentry && this.dailyrunentry.Setups || null,
            }),
            ShiftCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shift Code',
                name: 'ShiftCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('ShiftCode') && this.dailyrunentry.ShiftCode != null ? this.dailyrunentry.ShiftCode.toString() : '',
            }),
            SuperStock: new DynamicField({
                formGroup: this.formGroup,
                label: 'Super Stock',
                name: 'SuperStock',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.dailyrunentry && this.dailyrunentry.SuperStock || null,
            }),
            ThirdHandValues: new DynamicField({
                formGroup: this.formGroup,
                label: 'Third Hand Values',
                name: 'ThirdHandValues',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('ThirdHandValues') && this.dailyrunentry.ThirdHandValues != null ? this.dailyrunentry.ThirdHandValues.toString() : '',
            }),
            TourBoss: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tour Boss',
                name: 'TourBoss',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('TourBoss') && this.dailyrunentry.TourBoss != null ? this.dailyrunentry.TourBoss.toString() : '',
            }),
        };

        this.View = {
            Backtender: new DynamicLabel({
                label: 'Backtender',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Backtender') && this.dailyrunentry.Backtender != null ? this.dailyrunentry.Backtender.toString() : '',
            }),
            Banders: new DynamicLabel({
                label: 'Banders',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Banders') && this.dailyrunentry.Banders != null ? this.dailyrunentry.Banders.toString() : '',
            }),
            Breaks: new DynamicLabel({
                label: 'Breaks',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.Breaks || null,
            }),
            Finishers: new DynamicLabel({
                label: 'Finishers',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('Finishers') && this.dailyrunentry.Finishers != null ? this.dailyrunentry.Finishers.toString() : '',
            }),
            Hoppers: new DynamicLabel({
                label: 'Hoppers',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.Hoppers || null,
            }),
            RunDate: new DynamicLabel({
                label: 'Run Date',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.RunDate || null,
            }),
            Setups: new DynamicLabel({
                label: 'Setups',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.Setups || null,
            }),
            ShiftCode: new DynamicLabel({
                label: 'Shift Code',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('ShiftCode') && this.dailyrunentry.ShiftCode != null ? this.dailyrunentry.ShiftCode.toString() : '',
            }),
            SuperStock: new DynamicLabel({
                label: 'Super Stock',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.SuperStock || null,
            }),
            ThirdHandValues: new DynamicLabel({
                label: 'Third Hand Values',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('ThirdHandValues') && this.dailyrunentry.ThirdHandValues != null ? this.dailyrunentry.ThirdHandValues.toString() : '',
            }),
            TourBoss: new DynamicLabel({
                label: 'Tour Boss',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                value: this.dailyrunentry && this.dailyrunentry.hasOwnProperty('TourBoss') && this.dailyrunentry.TourBoss != null ? this.dailyrunentry.TourBoss.toString() : '',
            }),
        };

    }
}
