import { Component, OnInit } from '@angular/core';
import { Scales } from '../../model/Scales';

@Component({
    selector: 'app-pasting',
    templateUrl: './pasting.component.html',
})
export class PastingComponent implements OnInit {
    scaleId = Scales.Pasting;

    constructor() {}

    ngOnInit(): void {}
}
