<h2>Trim Setup Preview</h2>

<form>
    <div class="row">
        <div class="col-sm-3 col-xs-6 form-group">
            <label for="setupId">Setup ID</label>
            <input type="number" name="setupId" #setupId [(ngModel)]="filters.SetupId" class="form-control" />
        </div>
        <div class="col-sm-3 col-xs-6 form-group">
            <label for="orderNumber">Order Number</label>
            <input type="text" name="orderNumber" [(ngModel)]="filters.OrderNumber" class="form-control" />
        </div>
        <div class="col-sm-6 col-xs-12">
            <label>&nbsp;</label>
            <br />
            <button type="submit" class="btn btn-default" (click)="bindGrid()"><i class="fa fa-fw fa-search"></i> Search</button>
            <button type="button" class="btn btn-default" (click)="clearFilters(); bindGrid()">Reset</button>
        </div>
    </div>
</form>
<table class="table table-borderless table-condensed cursor-auto">
    <thead>
        <tr>
            <th>Setup ID</th>
            <th>Order #</th>
            <th>Trim</th>
            <th>Bundles</th>
            <th>Size</th>
            <th>Basis</th>
            <th>Count</th>
            <th>Caliper</th>
        </tr>
    </thead>
    <tbody>
        <ng-template ngFor let-trimSheet [ngForOf]="trimSetupForecasts">
            <ng-template ngFor let-detail let-i="index" let-last="last" [ngForOf]="trimSheet.Details">
                <tr [ngClass]="{ 'bg-success': trimSheet.IsCurrentSetup }">
                    <td>{{ i === 0 ? trimSheet.SetupId : '' }}</td>
                    <td>{{ detail.OrderNumber }}</td>
                    <td>{{ detail.Trim }}</td>
                    <td>{{ detail.Bundles }}</td>
                    <td>{{ detail.Width + ' x ' + detail.Length }}</td>
                    <td>{{ detail.Basis }}</td>
                    <td>{{ detail.Count }}</td>
                    <td>{{ detail.Caliper }}</td>
                </tr>
                <tr *ngIf="last" [ngClass]="{ 'bg-success': trimSheet.IsCurrentSetup }" style="border-bottom: 1px solid #ccc">
                    <td colspan="8" class="small">
                        <div class="row">
                            <div class="col-sm-5">
                                <table>
                                    <tr *ngIf="trimSheet.IsCurrentSetup">
                                        <td class="p-r-sm"><span class="font-italic">Start time: </span></td>
                                        <td>
                                            <span class="">{{ trimSheet.StartTime | date: 'short' }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-r-sm"><span class="font-italic">Estimated run time: </span></td>
                                        <td>
                                            <span class="">{{ getRelativeTime(trimSheet.EstimatedRunTime) }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="font-italic">Estimated finish: </span></td>
                                        <td>
                                            <span class="font-weight-bold">{{ getRelativeTime(trimSheet.RunningTotalHours) }} from now</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-sm-7">
                                <h5 *ngIf="trimSheet.IsCurrentSetup">
                                    <span class="label label-default">Currently in production</span>
                                </h5>
                            </div>
                            <div class="col-sm-7">
                                <h5 *ngIf="!trimSheet.IsCurrentSetup && trimSheet.IsFinished">
                                    <span class="label label-danger">This setup has run to completion on {{ trimSheet.EndTime | date: 'shortDate'}} at {{ trimSheet.EndTime | date : 'mediumTime'}}</span>
                                </h5>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
        <tr *ngIf="!trimSetupForecasts || !trimSetupForecasts.length">
            <td colspan="8">
                <h3 class="text-center">No data to display.</h3>
            </td>
        </tr>
    </tbody>
</table>

<entity-list-pagination [total]="total" [itemsPerPage]="pageSize" [(currentPage)]="currentPage" (pageChange)="bindGrid()"></entity-list-pagination>
