import { NgModule } from '@angular/core';
import { ScannersService } from './scanners.service';

@NgModule({})
export class ScannersModule {
    static forRoot(): any {
        return {
            ngModule: ScannersModule,
            providers: [ScannersService],
        };
    }
}
