import { Component, OnInit } from '@angular/core';
import { IUserRole } from '../../model/interfaces/user-role';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { UserRoleService } from '../user-role.service';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { entityListModuleConfig } from '../../common/shared.module';
import { UserRolesEntityListConfig } from './user-roles.entity-list.config';
import { IColumnSortedEvent, SortDirection, IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-roles',
    templateUrl: './user-roles.component.html',
})
export class UserRolesComponent implements OnInit {
    userRoles: IUserRole[];
    currentPage = 1;
    query: string;
    total: number;
    canAddRole = false;
    itemsPerPage = entityListModuleConfig.itemsPerPage;

    entityListConfig = new UserRolesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private userRoleService: UserRoleService, private claimService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddRole = this.claimService.hasClaim(ClaimTypes.UserRoles, [ClaimValues.FullAccess]);
        this.getUserRoles();
    }

    getUserRoles(): void {
        const search = this.query;
        const extrasearchparams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: extrasearchparams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.userRoleService.get(searchparams).subscribe((answer) => {
            this.userRoles = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getUserRoles();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUserRoles();
    }

    userSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/roles', event.entity.Id]);
    }
}
