import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, ITimepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';
import { IDailyRunLostTime } from '@model/interfaces/daily-run-lost-time';
import { DailyRunLostTimeDynamicControls, IDailyRunLostTimeDynamicControlsParameters } from '@model/form-controls/daily-run-lost-time.form-controls';

export class DailyRunLostTimeDynamicControlsPartial extends DailyRunLostTimeDynamicControls {

    constructor(dailyRunEntryPartial?: IDailyRunLostTime, additionalParameters?: IDailyRunLostTimeDynamicControlsParameters) {
        super(dailyRunEntryPartial, additionalParameters);

        this.Form.Reason.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Textarea,
        });

        let dynamicFieldTimeConfig: IDynamicFieldType = {
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Timepicker,
            timepickerOptions: { meridian: true } as ITimepickerOptions,
        };

        (<DynamicField>this.Form.Down).type = new DynamicFieldType(dynamicFieldTimeConfig);
        (<DynamicField>this.Form.Up).type = new DynamicFieldType(dynamicFieldTimeConfig);

    }
}
