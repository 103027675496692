import { ManufacturingOrderDetailDynamicControls } from '../form-controls/manufacturing-order-detail.form-controls';
import { IManufacturingOrderDetail } from '../interfaces/manufacturing-order-detail';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { IBoardGrade } from '../interfaces/board-grade';
import { ILining } from '../interfaces/lining';
import { IPalletInfo } from '../interfaces/pallet-info';
import { IFinish } from '../interfaces/finish';
import { IShipOnType } from '../interfaces/ship-on-type';
import { IGeneralLedgerCode } from '../interfaces/general-ledger-code';
import { IUnitOfMeasure } from '../interfaces/unit-of-measure';
import { OverrideNumericFunctionsEmptyWhenZero } from '../../common/common-functions.form-controls';
import { FractionsDynamicFieldComponent } from '@common/components/fractions-dynamic-field.component';
import { IFractionsDynamicFieldOptions } from '@common/services/fractions.service';
import { ScrollableTypeAheadComponent } from '@common/components/scrollable-type-ahead/scrollable-type-ahead.component';

export class ManufacturingOrderDetailDynamicControlsPartial extends ManufacturingOrderDetailDynamicControls {
    constructor(
        manufacturingOrderDetail: IManufacturingOrderDetail,
        boardGrades: IBoardGrade[],
        linings: ILining[],
        convertingPalletInfos: IPalletInfo[],
        finalPalletInfos: IPalletInfo[],
        finishes: IFinish[],
        shipOnTypes: IShipOnType[],
        generalLedgerCodes: IGeneralLedgerCode[],
        unitOfMeasures: IUnitOfMeasure[],
    ) {
        super(manufacturingOrderDetail, {
            boardGrades: boardGrades,
            finishes: finishes,
            generalLedgerCodes: generalLedgerCodes,
            linings: linings,
            shipOnTypes: shipOnTypes,
            unitOfMeasures: unitOfMeasures,
        });

        this.Form.Length.component = FractionsDynamicFieldComponent;
        this.Form.Width.component = FractionsDynamicFieldComponent;
        this.Form.Height.component = FractionsDynamicFieldComponent;
        this.Form.LiningId.component = ScrollableTypeAheadComponent;
        this.Form.FinishId.component = ScrollableTypeAheadComponent;
        this.Form.BoardGradeId.component = ScrollableTypeAheadComponent;

        const heightOptions: IFractionsDynamicFieldOptions = { maxFactor: 4 };
        const lengthOptions: IFractionsDynamicFieldOptions = { maxFactor: 32 };
        const widthOptions: IFractionsDynamicFieldOptions = { maxFactor: 32 };
        (<DynamicField>this.Form.Height).customOptions = heightOptions;
        (<DynamicField>this.Form.Width).customOptions = widthOptions;
        (<DynamicField>this.Form.Length).customOptions = lengthOptions;

        this.Form.BdlSk.label = 'Bdl/Sk';
        this.Form.BdlSk.labelHtml = '<label>Bdl/Sk</label>';

        this.Form.GeneralLedgerCodeId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });
        this.Form.UnitOfMeasureId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });
        this.Form.ShipOnTypeId.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.OldStyleRadioButtonList,
        });
        (<DynamicField>this.Form.ShipOnTypeId).labelHtml = '<label></label>';
        (<DynamicField>this.Form.ShipOnTypeId).label = '';
        this.Form.UnitOfMeasureId.label = 'U/M';
        this.Form.UnitOfMeasureId.labelHtml = '<label>U/M</label>';
        this.Form.Quantity.label = 'Qty';
        this.Form.Quantity.labelHtml = '<label>Qty</label>';
        this.Form.Caliper.validation.push(Validators.max(0.999));
        this.Form.Caliper.validators.max = 0.999;
        this.Form.Basis.validation.push(Validators.max(99999.9));
        this.Form.Basis.validators.max = 99999.9;
        this.Form.BdlSk.validation.push(Validators.max(99999.9));
        this.Form.BdlSk.validators.max = 99999.9;
        this.Form.Count.validation.push(Validators.max(999999.9));
        this.Form.Count.validators.max = 999999.9;
        this.Form.NumberOfSkids.label = 'Skids';
        this.Form.NumberOfSkids.labelHtml = '<label>Skids</label>';
        this.Form.GeneralLedgerCodeId.setRequired(true);
        this.Form.IsConverting.labelHtml = '<label>Converting</label>';
        this.Form.IsCommission.labelHtml = '<label>Commission</label>';
        this.Form.IsLiningDept.labelHtml = '<label>Lining Dept</label>';
        this.Form.IsBridgeView.labelHtml = '<label>Bridge View</label>';
        this.Form.IsFscCertified.labelHtml = '<label>Fsc Certified</label>';
        this.Form.IsConsignment.labelHtml = '<label>Consignment</label>';
        this.Form.IsDressStock.labelHtml = '<label>Dress Stock</label>';
        this.Form.LiningCustom.labelHtml = '<label>Lining (Custom)</label>';
        this.Form.FinishCustom.labelHtml = '<label>Finish (Custom)</label>';
        this.Form.IsShortWay = new DynamicField({
            formGroup: this.formGroup,
            label: '',
            name: 'IsShortWay',
            options: [
                { Id: true, Name: 'Short Way' },
                { Id: false, Name: 'Long Way' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.OldStyleRadioButtonList,
            }),
            value: manufacturingOrderDetail && manufacturingOrderDetail.hasOwnProperty('IsShortWay') ? manufacturingOrderDetail.IsShortWay : null,
        });

        // Default Unit of Measure on new orders
        if (manufacturingOrderDetail.Id === 0) {
            this.Form.UnitOfMeasureId.value = UnitsOfMeasure.B;
        }

        // Force fields to be empty instead of showing '0' when value is null.
        OverrideNumericFunctionsEmptyWhenZero([this.Form.Quantity, this.Form.BdlSk, this.Form.Count, this.Form.NumberOfSkids, this.Form.RuleNumber]);

        (<DynamicField>this.Form.BoardGradeId).validation = [ Validators.required ];
        (<DynamicField>this.Form.BoardGradeId).validators = { 'required': true };

        (<DynamicField>this.Form.FinishId).validation = [ Validators.required ];
        (<DynamicField>this.Form.FinishId).validators = { 'required': true };
    }
}

enum UnitsOfMeasure {
    B = 1,
    P = 2,
    S = 3,
}
