import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BoardTypeService } from '../board-type.service';
import { LiningGuillotineLogService } from './lining-guillotine-log.service';

import { LiningGuillotineLogDynamicControlsPartial } from '@model/partials/lining-guillotine-log-partial.form-controls';
import { ILiningGuillotineLog } from '@model/interfaces/lining-guillotine-log';
import { distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import { IExpandableObject } from '@model/expandable-object';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../../sales-orders/sales-order.service';
import { BoardTypeIds } from '@model/BoardTypes';
import { OrderTypeIds } from '@model/OrderTypes';
import { formatDate } from '@angular/common';
import { IConvertingDataEntryUser } from '@model/interfaces/converting-data-entry-user';
import { ConvertingDataEntryUserService } from '../converting-data-entry-user.service';
import { IDynamicField } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'lining-guillotine-log-entry',
    styles: [
        `
            hr {
                border-color: #999;
            }
        `,
    ],
    templateUrl: './lining-guillotine-log-entry.component.html',
})
export class LiningGuillotineLogEntryComponent implements OnInit {
    abstractLiningGuillotineLogControls: IExpandableObject;
    liningGuillotineLogEntryForm: FormGroup;

    doubleClickIsDisabled = false;
    initialRows = 5;
    hoveredIndex = -1;
    loadingLogs = false;

    isNewReport: boolean;
    selectedDate: Date;
    currentReportId: number;
    maxReportId: number;

    get liningGuillotineLogEntryFormArray(): FormArray {
        return <FormArray>this.liningGuillotineLogEntryForm.controls.LiningGuillotineLogs;
    }

    convertingDataEntryUsers: IConvertingDataEntryUser[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private boardTypeService: BoardTypeService,
        private liningGuillotineLogService: LiningGuillotineLogService,
        private salesOrderService: SalesOrderService,
        private convertingDataEntryUserService: ConvertingDataEntryUserService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.boardTypeService.getItems(), this.convertingDataEntryUserService.getItems()]).subscribe(([, users]) => {
            this.convertingDataEntryUsers = users;
            this.createForm();
        });
    }

    createForm(): void {
        this.abstractLiningGuillotineLogControls = new LiningGuillotineLogDynamicControlsPartial(this.convertingDataEntryUsers, null, {
            formGroup: 'LiningGuillotineLogHeader',
        }).Form;

        this.liningGuillotineLogEntryForm = this.fb.group({
            LiningGuillotineLogHeader: this.fb.group({}),
            LiningGuillotineLogs: this.fb.array([]),
        });

        this.addDetail(this.initialRows);
    }

    dateControlCreated(dateControl: FormControl): void {
        dateControl.valueChanges
            .pipe(
                filter((date) => date !== null),
                distinctUntilChanged((x: Date, y: Date) => x.getTime() === y.getTime()),
            )
            .subscribe((date) => this.selectDate(date));
    }

    selectDate(date: Date): void {
        this.maxReportId = null;
        this.dateSelected(date);
    }

    dateSelected(date: Date, reportId: number = null): void {
        this.selectedDate = date;
        if (reportId === null || reportId > 0 && this.maxReportId !== null && reportId <= this.maxReportId || this.isNewReport) {
            this.loadingLogs = true;
            this.liningGuillotineLogService
                .getByDate(date, reportId)
                .subscribe((logs) => {
                    this.liningGuillotineLogEntryFormArray.clear();
                    this.addDetail(logs.length || this.initialRows);
                    // Give added detail lines time to render
                    setTimeout(() => {
                        if (logs.length) {
                            this.currentReportId = logs[0].ReportId;
                            this.maxReportId = this.maxReportId ?? this.currentReportId;
                            this.liningGuillotineLogEntryForm.get('LiningGuillotineLogHeader').patchValue(logs[0]);
                            this.liningGuillotineLogEntryForm.get('LiningGuillotineLogs').patchValue(logs);
                            this.patchConvertingDataEntryUsers(logs[0]);
                        } else {
                            this.maxReportId = null;
                            this.liningGuillotineLogEntryForm.get('LiningGuillotineLogHeader').reset({ Date: date });
                            this.resetConvertingDataEntryUsers();
                        }

                        this.loadingLogs = false;
                    });
                });
            if (!this.isNewReport) {
                this.currentReportId = null;
            }
            this.isNewReport = false;
        } else {
            this.showNoReportsWarning();
        }
    }

    private patchConvertingDataEntryUsers(log: ILiningGuillotineLog): void {
        (<IDynamicField>this.abstractLiningGuillotineLogControls.Cutter).value = log.Cutter;
    }

    private resetConvertingDataEntryUsers(): void {
        (<IDynamicField>this.abstractLiningGuillotineLogControls.Cutter).value = '';
    }

    showNoReportsWarning(): void {
        this.notificationsService.warning('No more reports exist for this date');
    }

    buildPayload(): ILiningGuillotineLog[] {
        let logs: ILiningGuillotineLog[] = [];
        let headerValues = this.liningGuillotineLogEntryForm.value.LiningGuillotineLogHeader;

        // check each detail and only add it to the payload if it's pre-existing or has been edited
        this.liningGuillotineLogEntryFormArray.controls.forEach((details: FormGroup) => {
            const value = details.getRawValue();
            if (value.Id > 0 || details.dirty) {
                let log = this.liningGuillotineLogService.getEmptyLiningGuillotineLog();
                log.ReportId = this.currentReportId ?? 1;
                log.Date ??= this.selectedDate;
                Object.assign(log, headerValues);
                Object.assign(log, details.getRawValue());
                log.SalesOrderId = log.SalesOrderId === 0 ? null : log.SalesOrderId;
                logs.push(log);
            }
        });
        return logs;
    }

    addDetail(qty = 1): void {
        for (let i = 1; i <= qty; i++) {
            this.liningGuillotineLogEntryFormArray.push(
                this.fb.group({
                    Id: this.fb.control({ value: 0, disabled: true }), // this is a hidden field to track Ids for existing logs
                }),
            );
        }
    }

    removeDetail(index: number): void {
        this.liningGuillotineLogEntryFormArray.removeAt(index);
    }

    formSubmitted(): void {
        if (this.liningGuillotineLogEntryForm.valid) {
            let payload = this.buildPayload();
            if (payload.length === 0 && this.liningGuillotineLogEntryFormArray.length === 0) {
                this.liningGuillotineLogService.deleteAllByDate(this.selectedDate)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe(() => {
                        this.notificationsService.success('All Lining Guillotine Reports deleted successfully');
                        this.resetForm();
                        this.dateSelected(this.selectedDate);
                    });
            } else if (payload.length > 0) {
                this.liningGuillotineLogService
                    .saveByDate(payload)
                    .pipe(finalize(() => this.enableDoubleClick()))
                    .subscribe((ids) => {
                        this.liningGuillotineLogEntryFormArray.patchValue(ids.map((id) => ({ Id: id })));
                        this.notificationsService.success('Lining Guillotine Report saved successfully');
                        this.dateSelected(this.selectedDate);
                    });
            } else {
                this.notificationsService.warning('There is no data to save.');
                this.enableDoubleClick();
            }
        } else {
            common.markAllFormFieldsAsTouched(this.liningGuillotineLogEntryForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    resetForm(): void {
        this.liningGuillotineLogEntryForm.reset();
    }

    pullSalesOrderValues(index: any): void {
        if (this.loadingLogs) {
            return;
        }

        let formRow = this.liningGuillotineLogEntryFormArray.at(index);
        if (!formRow) {
            return;
        }

        let salesOrderId: number = (formRow as FormGroup).controls.SalesOrderId.value;
        if (!salesOrderId) {
            return;
        }

        // get the sales order
        forkJoin([this.salesOrderService.getById(salesOrderId), this.salesOrderService.getSkids(salesOrderId)]).subscribe(([salesOrder, skids])  => {

            if (!salesOrder) {
                this.notificationsService.error(`Sales Order ${salesOrderId} not found`);
            } else {
                if (skids) {
                    salesOrder.Skids = skids;
                }
                this.pullManufacturingOrderDetailData(salesOrder, formRow);
            }
        });
    }

    pullManufacturingOrderDetailData(salesOrder: ISalesOrder, formRow: AbstractControl): void {
        if (!salesOrder.ManufacturingOrderDetail) {
            return;
        }

        // default data to manufacturing order detail data
        const mfod = salesOrder.ManufacturingOrderDetail;

        let width = mfod.Width;
        let length = mfod.Length;
        let caliper = mfod.Caliper;
        let count = mfod.Count;
        let skids = mfod.NumberOfSkids;
        let bps = mfod.BdlSk;
        let bundles = mfod.Quantity;
        let grade = '';
        let boardTypeId = BoardTypeIds.MilledBoard;
        let isTiedBundle = salesOrder.OrderInfo.OrderCustomerSpecification.IsTiedBundles;

        let pieces = salesOrder.Skids.reduce((sum, sk) => sum + sk.ActualCount, 0);
        let lining = salesOrder.ManufacturingOrderDetail.Lining?.Name ?? salesOrder.ManufacturingOrderDetail.LiningCustom ?? '';
        let customerName = salesOrder.OrderInfo.Customer.CompanyName;

        if (salesOrder.OrderTypeId === OrderTypeIds.Stock) {
            boardTypeId = BoardTypeIds.StockBoard;
        } else {
            boardTypeId = BoardTypeIds.MilledBoard;
            grade = salesOrder.ManufacturingOrderDetail.BoardGrade?.Name;
        }

        formRow.patchValue({
            BdlSk: bps,
            BoardGrade: grade,
            BoardTypeId: boardTypeId,
            Bundles: bundles,
            Caliper: caliper,
            Count: count,
            CustomerName: customerName,
            Length: length,
            Lining: lining,
            Pieces: pieces,
            Skids: skids,
            TiedBundles: isTiedBundle,
            Width: width,
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['/'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    navigateToFirstReportOfTheDay(): void {
        this.dateSelected(this.selectedDate, 1);
    }

    navigateToPreviousReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId - 1);
    }

    navigateToNextReport(): void {
        this.dateSelected(this.selectedDate, this.currentReportId + 1);
    }

    navigateToLatestReportOfTheDay(): void {
        this.dateSelected(this.selectedDate);
    }

    getNewReport(): void {
        this.isNewReport = true;
        this.currentReportId = this.maxReportId + 1;
        this.dateSelected(this.selectedDate, this.currentReportId);
    }
}
