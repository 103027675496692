import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ISkid } from '@model/interfaces/skid';

@Component({
    selector: 'override-actual-input',
    templateUrl: 'override-actual-input.component.html',
})

export class PackagingLineOverrideActualInputComponent implements OnChanges {
    @Input() skid: ISkid;
    @Input() label: string;
    @Input() onSave: boolean;
    @Input() disabled: boolean;
    @Output() onOverride: EventEmitter<number> = new EventEmitter<number>();
    override: number = null;
    isOverridingActualValue = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.onSave) {
            this.override = null;
            this.isOverridingActualValue = false;
        }
    }

    /**
     * Base method declarations allows child components to override
     * @returns null
     */
    getActualValue(): any {
        return null;
    }

    /**
     * Base method declarations allows child components to override
     * @returns null
     */
    getTargetValue(): any {
        return null;
    }

    toggleOverride(): void {
        this.isOverridingActualValue = !this.isOverridingActualValue;
    }

    saveOverride(): void {
        this.toggleOverride();
        this.onOverride.emit(this.override);
    }

    clearOverride(): void {
        this.override = 0;
        this.onOverride.emit(this.override);
    }
}
