import { CustomerShippingAddressService } from '../../customers/shared-entities/customer-shipping-address.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SearchParams, ExtraSearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { CustomerService } from '../../customers/customer.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ICustomerPartial } from '../../model/partials/customer';
import { ICustomerShippingAddress } from '../../model/interfaces/customer-shipping-address';
import { ActivatedRoute } from '@angular/router';
import { CustomerSharedEntities } from '../../customers/shared-entities/customer.shared-entities';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'sales-order-add',
    templateUrl: './sales-orders-add.component.html',
})
export class SalesOrderAddComponent implements OnInit {
    @ViewChild('focusInput') focusInput: ElementRef;

    customers: ICustomerPartial[];
    customer: ICustomerPartial;
    customerId: number;
    customerName: string;
    customerShippingAddressId: number;
    customerShippingAddresses: ICustomerShippingAddress[];
    initialized = false;
    isManufacturing: boolean;
    isStock: boolean;
    isAddingShippingAddress: boolean;

    shippingAddresSharedEntityId = CustomerSharedEntities.ShippingAddress;

    constructor(
        private customerService: CustomerService,
        private customerShippingAddressService: CustomerShippingAddressService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.isManufacturing = this.route.snapshot.routeConfig.path && this.route.snapshot.routeConfig.path.includes('manufacturing') ? true : false;
        this.isStock = this.route.snapshot.routeConfig.path && this.route.snapshot.routeConfig.path.includes('stock') ? true : false;
        this.customer = this.customerService.getEmptyCustomer();
        this.getCustomers();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.focusInput) {
                this.focusInput.nativeElement.focus();
            }
        }, 0);
    }

    getCustomers(query?: string): void {
        const search = query;

        const extraSearchParams: ExtraSearchParams[] = [];
        extraSearchParams.push(
            new ExtraSearchParams({
                name: 'IncludeArchived',
                value: false.toString(),
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: extraSearchParams,
            query: search && search.length > 0 ? search : '',
            skip: (1 - 1) * 100,
            take: 100,
        };

        const searchparams = new SearchParams(searchEntity);

        this.customerService.get(searchparams).subscribe((answer) => {
            this.customers = answer.body;
        });
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((term) => {
                if (term.length < 2) {
                    return this.customerService.getEmptyCustomer;
                } else {
                    this.getCustomers(term);
                    return this.customers.filter((v) => v.CustomerName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
                }
            }),
        )

    formatter = (x: { name: string }) => x.name;

    onShippingAddressAdded(newAddress: ICustomerShippingAddress): void {
        this.notificationsService.success('New address added successfully.');
        this.getCustomerShippingAddresses(newAddress.Id);
        this.isAddingShippingAddress = false;
    }

    selectedCustomer(event: any, input: any): void {
        event.preventDefault();

        this.customer = event.item;

        input.value = this.customer.CustomerName;

        this.getCustomerShippingAddresses();
    }

    getCustomerShippingAddresses(selectedCustomerShippingAddressId: number = null): void {
        const searchEntity: IEntitySearchParams = {
            extraParams: [],
            query: '',
            skip: (1 - 1) * 100,
            take: 100,
        };

        const searchparams = new SearchParams(searchEntity);

        this.customerShippingAddressService.getEntities(this.customer.Id, searchparams).subscribe((answer) => {
            this.customerShippingAddresses = answer.body;
            this.customerId = this.customerShippingAddresses[0].CustomerId;

            if (selectedCustomerShippingAddressId) {
                this.customerShippingAddressId = this.customerShippingAddresses.find((p) => p.Id === selectedCustomerShippingAddressId).Id;
            } else {
                this.customerShippingAddressId = this.customerShippingAddresses.find((p) => p.IsPrimary).Id;
            }
        });
    }
}
