import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ICustomPackaging } from '@model/interfaces/custom-packaging';
import { CustomPackagingService } from '../custom-packaging.service';

@Component({
    templateUrl: './custom-packaging-add.component.html',
})
export class CustomPackagingAddComponent implements OnInit {
    customPackaging: ICustomPackaging;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private customPackagingService: CustomPackagingService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const parentId = +this.route.parent.snapshot.paramMap.get('customerId');
        this.customPackaging = this.customPackagingService.getEmptyCustomPackaging();
        this.customPackaging.CustomerId = parentId;
    }
}
