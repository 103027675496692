import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IShipOnType } from '../model/interfaces/ship-on-type';

@Injectable()
export class CustomerShipOnTypeService extends StaticMetaItemService<IShipOnType> {
    constructor(public http: HttpClient) {
        super('CustomerShipOnTypeService', 'Ship On Type', 'ShipOnTypeIds', '/customers/customershipontypes', http);
    }
}
