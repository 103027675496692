import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILiningGuillotineLog } from '@model/interfaces/lining-guillotine-log';
import { BulkByDateService } from '@common/services/bulk-by-date.service';
import { Observable } from 'rxjs';

export const emptyLiningGuillotineLog: ILiningGuillotineLog = {
    BdlSk: null,
    Caliper: null,
    Count: null,
    CustomerName: '',
    Cutter: '',
    Date: null,
    Hours: null,
    Id: 0,
    Length: '',
    Lining: '',
    Note: '',
    Pieces: null,
    ReportId: 1,
    SalesOrderId: 0,
    ShiftCode: '',
    Width: '',
};

@Injectable()
export class LiningGuillotineLogService extends BulkByDateService<ILiningGuillotineLog> {
    constructor(public http: HttpClient) {
        super('/lining-guillotine-logs', http);
    }

    getEmptyLiningGuillotineLog(): ILiningGuillotineLog {
        return { ...emptyLiningGuillotineLog };
    }

    deleteAllByDate(date: Date): Observable<void> {
        const params = new HttpParams().set('date', date.toISOString().split('T')[0]);
        return this.http.delete<void>('/lining-guillotine-logs/', { params });
    }
}
