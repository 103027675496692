import { ChangeDetectorRef, Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { interval } from 'rxjs';

import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { MillShiftPersonnelService } from '../millshiftpersonnel.service';

import { IMillShift } from '../../model/interfaces/mill-shift';
import { IMillShiftPersonnel } from '../../model/interfaces/mill-shift-personnel';
import { MillShiftPersonnelDynamicControlsPartial } from '../../model/partials/mill-shift-personnel-partial.form-controls';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'mill-shift-personnel',
    styles: [
        `
            .font-small {
                font-size: 14px !important;
            }
        `,
    ],
    templateUrl: './mill-shift-personnel.component.html',
})
export class MillShiftPersonnelComponent implements OnInit {
    // abstract controls
    abstractMillShiftPersonnelControls: any;
    millShiftPersonnel: IMillShiftPersonnel;

    millShiftPersonnelForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    currentTime: Date = null;

    @Input('millShift') millShift: IMillShift;
    @Output('onSave') onSave: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private millShiftPersonnelService: MillShiftPersonnelService,
    ) {}

    ngOnInit(): void {
        this.createForm();
        this.setDateTimer();
    }

    createForm(): void {
        this.millShiftPersonnel = this.millShiftPersonnelService.getEmptyMillShiftPersonnel();
        this.getControls();
        this.millShiftPersonnelForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractMillShiftPersonnelControls = new MillShiftPersonnelDynamicControlsPartial(this.millShiftPersonnel, null).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            MillShiftPersonnel: this.fb.group({}),
        });
    }

    setDateTimer(): void {
        this.currentTime = new Date();

        interval(1000).subscribe(() => {
            this.currentTime = new Date();
        });
    }

    formSubmitted(): void {
        if (this.millShiftPersonnelForm.valid) {
            this.millShiftPersonnel = this.millShiftPersonnelForm.getRawValue().MillShiftPersonnel;
            this.millShiftPersonnel.MillShiftId = this.millShift.Id;

            this.millShiftPersonnelService
                .create(this.millShiftPersonnel)
                .pipe(
                    finalize(() => {
                        this.enableDoubleClick();
                    }),
                )
                .subscribe((answer) => {
                    this.millShiftPersonnel.Id = answer;
                    this.notificationsService.success('Personnel saved successfully');
                    this.onSave.emit(this.millShiftPersonnel);
                });
        } else {
            common.markAllFormFieldsAsTouched(this.millShiftPersonnelForm);
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
