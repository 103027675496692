<h2>Trim Sheet - {{ date | date: 'M/d/yyyy' }}</h2>

<table class="table table-borderless cursor-auto">
    <thead>
        <tr>
            <th style="width: 4%;" class="text-center"># of<br />Bdls</th>
            <th style="width: 4%;" class="text-center"># of<br />Shts</th>
            <th style="width: 11%;">Size</th>
            <th style="width: 7%;" class="text-center">MSF</th>
            <th style="width: 7%;">Bas/Ct</th>
            <th style="width: 7%;">Cal.</th>
            <th style="width: 7%;">Order #</th>
            <th style="width: 22%;">Setup ID</th>
            <th style="width: 4%;" class="text-center">Pile<br />Down</th>
            <th style="width: 4%;" class="text-center">Tie<br />On</th>
            <th style="width: 4%;" class="text-center">To<br />Cutter</th>
            <th style="width: 5%;" class="text-center">Pasted</th>
            <th style="width: 7%;" class="text-center"></th>
            <th style="width: 7%;" class="text-center">Machine Trim</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let group of trimGroups; let iGroup = index">
            <tr *ngIf="group.SectionHeading">
                <td colspan="14" class="heading">{{ group.SectionHeading }}</td>
            </tr>
            <tr *ngIf="group.GroupConfiguration">
                <td colspan="14" class="heading">{{ group.GroupConfiguration }}</td>
            </tr>
            <ng-container *ngFor="let summary of group.Summaries; let iSummary = index">
                <tr *ngFor="let detail of summary.Details; let first = first; let last = last; let iDetail = index" [class]="last ? 'last' : ''">
                    <td class="text-center">{{ detail.Bundles }}</td>
                    <td class="text-center">{{ detail.Sheets }}</td>
                    <td>{{ detail.Width + ' x ' + detail.Length }}</td>
                    <td class="text-center">{{ detail.Msf | number: '1.0-1' }}</td>
                    <td>{{ detail.Basis + ' / ' + detail.Count }}</td>
                    <td>{{ detail.Caliper.toFixed(3) }}</td>
                    <td>{{ detail.IsDressStock ? detail.CustomerName : detail.SalesOrderId }}</td>
                    <td>
                        <a *ngIf="first && !isEditing(summary)" (click)="edit(summary)" title="Edit Setup" class="pointer">{{ summary.SetupId }}</a>

                        <form *ngIf="first && isEditing(summary)" (ngSubmit)="saveNewSetupId(editingSetupId)" class="input-group form-sm">
                            <input
                                type="number"
                                id="{{ getEditKey(summary) }}"
                                name="{{ 'setup_' + getEditKey(summary) }}"
                                [(ngModel)]="editingSetupId"
                                class="form-control"
                            />
                            <span class="input-group-btn">
                                <button type="submit" class="btn btn-success btn-sm">Update</button>
                            </span>
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-default btn-sm" (click)="closeEdit()">Cancel</button>
                            </span>
                        </form>
                    </td>
                    <td class="text-center">{{ detail.IsPileUp !== null && !detail.IsPileUp ? 'X' : '' }}</td>
                    <td class="text-center">{{ detail.IsTiedBundles ? 'X' : '' }}</td>
                    <td class="text-center">{{ detail.IsCutter ? 'X' : '' }}</td>
                    <td class="text-center">{{ detail.IsPaster ? 'X' : '' }}</td>
                    <td class="text-center"></td>
                    <td class="text-center">{{ iDetail === summary.Details.length - 1 ? getMachineTrimSum(summary) :  ""}}</td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>

<button class="btn btn-default" (click)="close()">Close</button>
<button class="btn btn-default" (click)="print()" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">
    <i class="fa fa-fw fa-print"></i> Print
</button>
