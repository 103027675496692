<h2>Mill Corp Invoices</h2>
<div class="row">
    <div class="col-md-6">
        <meta-item-managed-list
            [service]="millCorpCustomerService"
            [dynamicForm]="millCorpCustomerDynamicForm"
            [canAdd]="true"
            [canRemove]="true"
        ></meta-item-managed-list>
    </div>
</div>