import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { common } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, Subscription } from 'rxjs';
import { FractionsService } from '../../common/services/fractions.service';
import { ISkid } from '../../model/interfaces/skid';
import { SkidDynamicControlsPartial } from '../../model/partials/skid.form-controls';
import { SkidService } from '../skid.service';
import { PrintService } from '../../common/services/print.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { ScalesService } from '../../scales/scales.service';
import { IFormattedSalesOrder } from '../../model/interfaces/custom/formatted-sales-order';
import { MillShiftPersonnelService } from '../millshiftpersonnel.service';
import { IMillShiftPersonnel } from '../../model/interfaces/mill-shift-personnel';
import { ICustomerShippingAddress } from '@model/interfaces/customer-shipping-address';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'skid-processing',
    templateUrl: './skid-processing.component.html',
})
export class SkidProcessingComponent implements OnInit, OnDestroy {
    @Input() scaleId: number;

    abstractSkidControls: any;
    form: FormGroup;
    backtenderBarcodeControl: FormControl;
    doubleClickIsDisabled = false;
    formCreated = false;

    skid: ISkid;
    customerShippingAddress: ICustomerShippingAddress;
    scaleSheets: number;
    scaleWeight = 0;
    targetWeight = 100;
    copies = 1;
    isWeighing = false;
    currentMillShiftPersonnel: IMillShiftPersonnel;

    subscriptions = new Subscription();

    get netWeight(): number {
        let value = 0;
        if (this.form && this.form.value && this.form.value.Skid.TareWeightBottom && this.form.value.Skid.TareWeightTop) {
            const skid = this.form.value.Skid as ISkid;
            value = this.skid.ActualWeight - (skid.TareWeightBottom + skid.TareWeightTop);
        }
        return value;
    }

    noBarcodeModal: IModalWrapperApi;
    noBarcodeModalOptions: IModalOptions = {
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private millShiftPersonnelService: MillShiftPersonnelService,
        private notificationsService: NotificationsService,
        private fractionsService: FractionsService,
        private skidService: SkidService,
        private scalesService: ScalesService,
    ) {}

    ngOnInit(): void {
        this.millShiftPersonnelService.getCurrent().subscribe((person) => {
            this.currentMillShiftPersonnel = person;
            this.createForm();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    salesOrderIdControlCreated(control: FormControl): void {
        this.subscriptions.add(
            control.valueChanges.pipe(debounceTime(600)).subscribe((value) => {
                this.backtenderBarcodeControl.reset(null, { emitEvent: false });
                this.backtenderBarcodeControl.mtSetRequired(false);
                this.findSkid(value, this.skidService.buildFromOrderDetails(+value));
            }),
        );
    }

    backtenderBarcodeControlCreated(control: FormControl): void {
        this.backtenderBarcodeControl = control;
        this.subscriptions.add(
            control.valueChanges.pipe(debounceTime(600)).subscribe((value) => {
                this.backtenderBarcodeControl.mtSetRequired(true);
                this.findSkid(value, this.skidService.getByBacktenderBarcode(value));
            }),
        );
    }

    findSkid(value: string, observable: Observable<ISkid>): void {
        if (!value) {
            this.skid = null;
            return;
        }

        observable.subscribe((answer) => {
            this.skid = answer;
            if (answer) {
                this.initSkid();
                this.initAddress();
            } else {
                this.notificationsService.warning('Skid not found');
            }
        });
    }

    createForm(): void {
        this.getControls();
        this.form = this.assignFormGroups();
        this.formCreated = true;
    }

    getControls(): void {
        this.abstractSkidControls = new SkidDynamicControlsPartial(this.skid).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Skid: this.fb.group({}),
        });
    }

    initSkid(): void {
        this.form.enable();
        let formControls = this.getFormControls();
        formControls.TareWeightBottom.mtFocus();
    }

    initAddress(): void {
        if (!this.skid || !this.skid.SalesOrder || !this.skid.SalesOrder.OrderInfo || !this.skid.SalesOrder.OrderInfo.CustomerShippingAddress) {
            this.customerShippingAddress = null;
        } else {
            this.customerShippingAddress = this.skid.SalesOrder.OrderInfo.CustomerShippingAddress;
        }
    }

    formSubmitted(): void {
        if (this.form.valid) {
            let formValues = this.getFormValues();

            // keep the existing fields from the scanned stock item that aren't on this form
            Object.assign(this.skid, formValues);
            let skid = { ...this.skid };

            // clear FK entities
            skid.BoardGrade = null;
            skid.Finish = null;
            skid.MillShiftPersonnel = null;
            skid.OrderDetailLine = null;
            skid.SalesOrder = null;
            skid.Scale = null;
            skid.Shipment = null;
            skid.TrimSheetDetail = null;
            skid.User = null;

            skid.ScaleId = this.scaleId;
            skid.MillShiftPersonnelId = this.currentMillShiftPersonnel.Id;
            skid.ActualCount = skid.ActualCount || 0;

            let apiPromise: Observable<any>;
            if (this.skid.Id !== 0) {
                apiPromise = this.skidService.updateNoPiles(skid, true);
            } else {
                apiPromise = this.skidService.create(skid, undefined, false, true);
            }

            apiPromise
                .pipe(
                    finalize(() => {
                        this.enableDoubleClick();
                    }),
                )
                .subscribe((answer) => {
                    this.notificationsService.success('Skid saved successfully');
                    this.skid.Id = answer;
                    this.printFinalTag();
                });
        } else {
            common.markAllFormFieldsAsTouched(this.form);
            this.enableDoubleClick();
        }
    }

    printFinalTag(): void {
        this.skidService.getFinalTag(this.skid.Id).subscribe((pdf) => {
            PrintService.printPdf(pdf);
            this.clearForm();
        });
    }

    clearForm(): void {
        this.backtenderBarcodeControl.reset();
        this.form.reset();
        this.skid = null;
        this.customerShippingAddress = null;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    getFormValues(): ISkid {
        return <ISkid>this.form.getRawValue().Skid;
    }

    getFormControls(): any {
        return (this.form.controls.Skid as FormGroup).controls;
    }

    weigh(): void {
        this.isWeighing = true;
        this.scalesService
            .getWeight(this.scaleId)
            .pipe(finalize(() => (this.isWeighing = false)))
            .subscribe((weight) => {
                this.skid.ActualWeight = Math.round(weight);
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    getFormattedOrderNumber(salesOrder: IFormattedSalesOrder): string {
        if (salesOrder) {
            return salesOrder.OrderNumberWithPostFix;
        }
        return '';
    }

    onNoBarcodeAddSkid(answer): void {
        this.closeNoBarcodeModal();
        this.skid = answer;
        if (this.skid === null) {
            return;
        }

        if (this.skid.Id === -1) {
            this.allSkidsProcessedError();
        }

        if (answer) {
            this.initSkid();
            this.initAddress();
        }
    }

    closeNoBarcodeModal(): void {
        this.noBarcodeModal.close();
    }

    allSkidsProcessedError(): void {
        this.notificationsService.error('All Skids Processed');
    }

    noBarcodeTag(): void {
        this.noBarcodeModal.show();
    }
}
