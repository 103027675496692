import { OnInit, Component } from '@angular/core';
import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';

import { CustomerPaymentTermDynamicControls } from '../../model/form-controls/customer-payment-term.form-controls';

import { CustomerPaymentTermService } from '../customerpaymentterm.service';

@Component({
    selector: 'app-customer-settings',
    templateUrl: './customer-settings.component.html',
})
export class CustomerSettingsComponent implements OnInit {
    customerPaymentTermDynamicForm = new CustomerPaymentTermDynamicControls().Form;

    constructor(public customerPaymentTermService: CustomerPaymentTermService) {}

    ngOnInit(): void {
        (<DynamicField>this.customerPaymentTermDynamicForm.Name).type.inputType = InputTypes.Textbox;
    }
}
