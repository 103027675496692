<div class="row">
    <div class="col-md-7">
        <h2>User Roles</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-10">
        <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    </div>
</div>
<br />
<entity-list
    [entities]="userRoles"
    [total]="total"
    [(currentPage)]="currentPage"
    (onPageChanged)="getUserRoles()"
    (onItemSelected)="userSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>
<div *ngIf="canAddRole" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/roles', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
